<template>
  <section
    class="w-full flex justify-center bg-primary py-8"
    :class="{ 'feedback-other-recommendation': CONFIG.feedback === 'Palmira' }"
  >
    <div class="w-full max-w-desktop flex flex-col md:items-center">
      <!-- Title -->
      <h1
        class="bg-white rounded-md px-1 text-gray text-xl"
        :class="{ 'feedback-other-recommendation__title': CONFIG.feedback === 'Palmira' }"
      >
        <span
          v-t="'feedback.other_alternatives.title_one_name'"
          :class="{
            'feedback-other-recommendation__title__text': CONFIG.feedback === 'Palmira',
          }"
        />
        <span
          v-if="CONFIG.feedback !== 'Palmira'"
          style="font-weight: bold"
          v-t="'feedback.other_alternatives.title_bold'"
        />
        <!-- eslint-disable max-len -->
        <span
          v-t="{
            path: 'feedback.other_alternatives.title_two_name',
            args: { nameStudent: name },
          }"
          :class="{
            'feedback-other-recommendation__title__text feedback-other-recommendation__title__text--inline':
              CONFIG.feedback === 'Palmira',
          }"
        />
      </h1>
      <!--<h1
        class="
          flex
          bg-white
          w-36
          rounded-md
          px-1
          ml-11
          mt-1
          text-gray text-xl
          md:ml-0 md:text-center
        "
      />-->
      <!-- recommendations-->
      <div class="flex flex-col justify-center">
        <!--<picture class="w-full flex justify-center">
          <img src="@/assets/svg/marker.svg" />
        </picture>
        <label
          class="text-white text-xs text-center font-normal"
          v-t="{
            path: 'feedback.other_alternatives.institutions_applications',
            args: { institutions: applications.length },
          }"
        />-->
        <picture class="w-full flex justify-center mt-8">
          <img src="@/assets/svg/total-marker.svg" />
        </picture>
        <label
          class="text-white text-xs text-center font-normal"
          :class="{ 'px-10': CONFIG.feedback === 'Palmira' }"
          v-t="{
            path: 'feedback.other_alternatives.institutions_applications',
            args: { institutions: totalApplications },
          }"
        />
        <picture class="w-full flex justify-center mt-8">
          <img src="@/assets/svg/marker-2km.svg" />
        </picture>
        <label
          class="text-white text-xs text-center font-normal"
          :class="{ 'px-10': CONFIG.feedback === 'Palmira' }"
          v-t="{
            path: 'feedback.other_alternatives.institutions_in_two_km',
            args: { institutions: institutionsAroud2km },
          }"
        />
        <picture class="w-full flex justify-center mt-8">
          <img src="@/assets/svg/marker-performance.svg" />
        </picture>
        <label
          class="text-white text-xs text-center font-normal"
          :class="{ 'px-10': CONFIG.feedback === 'Palmira' }"
          v-t="{
            path: 'feedback.other_alternatives.letter_performance_institutions',
            args: { institutions: institutionsCopayLowHighPerformance },
          }"
        />
      </div>
    </div>
  </section>
</template>

<script>
import CONFIG from "../../config";

export default {
  name: "OtherRecommendation",
  data() {
    return {
      institutionsAroud2km: 0,
      totalApplications: 0,
      institutionsCopayLowHighPerformance: 0,
      CONFIG,
    };
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    others: {
      type: Array,
      default: Array,
    },
    recommendations: {
      type: Array,
      default: Array,
    },
    applications: {
      type: Array,
      default: Array,
    },
  },
  mounted() {
    this.recommendations.forEach((institution) => {
      if (
        (institution.payment === "Gratuito" || institution.payment === "$1.000 a $10.000") &&
        (institution.performance[0].qualitycateglevel.qualitycateglevel_name === "A+" ||
          institution.performance[0].qualitycateglevel.qualitycateglevel_name === "A")
      ) {
        this.institutionsCopayLowHighPerformance += 1;
      }
    });
    this.totalApplications = this.applications.length;
    this.institutionsAroud2km = this.recommendations.length;
  },
};
</script>
