<template>
  <div class="address-information_container">
    <div class="address-information__title">
      {{ $t("register.location.address_information.title") }}
    </div>
    <div class="address-information__subtitle-container">
      <p class="required-field address-information__subtitle">
        {{ $t("register.location.address_information.subtitle") }}
      </p>
    </div>
    <v-container class="address-information__question-container">
      <div
        :class="!mobile ? 'address-information__question' : 'address-information__question-mobile'"
        :style="
          sameAddress
            ? 'background: #2D10B4; margin-right: 5px;'
            : 'background: #FFFFFF; margin-right: 5px;'
        "
        @click="changeAddressOpt(true)"
      >
        <p :style="sameAddress ? 'color: #FFFFFF;' : 'color: #2D10B4;'">
          {{ $t("register.location.address_information.yes_opt") }}
        </p>
      </div>
      <div
        :class="!mobile ? 'address-information__question' : 'address-information__question-mobile'"
        :style="
          sameAddress === false
            ? 'background: #2D10B4; margin-left: 5px;'
            : 'background: #FFFFFF; margin-left: 5px;'
        "
        @click="changeAddressOpt(false)"
      >
        <p :style="sameAddress === false ? 'color: #FFFFFF;' : 'color: #2D10B4;'">
          {{ $t("register.location.address_information.no_opt") }}
        </p>
      </div>
    </v-container>
    <div v-if="this.sameAddress === false" class="location-modal">
      <div class="location-modal__content" style="margin: 0">
        <div class="location-modal__content__title">
          <h3
            class="location-modal__content__title__text"
            v-t="'registrationFlow.location.location_set_btn1'"
          />
          <div class="question-btn">
            <img src="@/assets/iconsPalmira/registration/question.svg" />
          </div>
        </div>
        <div class="location-modal__content__form">
          <div class="location-modal__content__form__inputs">
            <div class="location-modal__content__form__inputs__div">
              <div class="location-modal__content__form__inputs__div__title">
                <p
                  class="location-modal__content__form__inputs__div__title__text"
                  v-t="'registrationFlow.location.department'"
                />
                <p class="location-modal__content__form__inputs__div__title__asterisk">*</p>
              </div>
              <v-autocomplete
                class="registration-input mr-2"
                :items="regionLabels"
                item-text="name"
                v-model="department"
                :placeholder="$t('registrationFlow.location.department')"
                dense
                outlined
                :dark="department ? true : false"
                @change="changeMunicipalityOptions"
                :background-color="department ? '#2D10B4' : '#FFFFFF'"
              />
            </div>
            <div class="location-modal__content__form__inputs__div">
              <div class="location-modal__content__form__inputs__div__title">
                <p
                  class="location-modal__content__form__inputs__div__title__text"
                  v-t="'registrationFlow.location.municipality'"
                />
                <p class="location-modal__content__form__inputs__div__title__asterisk">*</p>
              </div>
              <v-autocomplete
                class="registration-input"
                :items="municipalityLabels"
                item-text="name"
                v-model="municipality"
                :placeholder="$t('registrationFlow.location.municipality')"
                dense
                outlined
                :dark="municipality ? true : false"
                :background-color="municipality ? '#2D10B4' : '#FFFFFF'"
                :disabled="municipalityLabels.length === 0"
              />
            </div>
          </div>
          <div class="location-modal__content__form__inputs">
            <div class="location-modal__content__form__inputs__div">
              <div class="location-modal__content__form__inputs__div__title">
                <p
                  class="location-modal__content__form__inputs__div__title__text"
                  v-t="'registrationFlow.location.road_type_title'"
                />
                <p class="location-modal__content__form__inputs__div__title__asterisk">*</p>
              </div>
              <v-autocomplete
                class="location-modal__content__form__inputs__div__select mr-2"
                :items="roadTypeLabels"
                item-text="name"
                v-model="roadType"
                :placeholder="$t('registrationFlow.location.road_type_title')"
                dense
                outlined
                :dark="roadType ? true : false"
                :background-color="roadType ? '#2D10B4' : '#FFFFFF'"
              />
            </div>
            <div class="location-modal__content__form__inputs__div mr-2">
              <div class="location-modal__content__form__inputs__div__title">
                <p
                  class="location-modal__content__form__inputs__div__title__text"
                  v-t="'registrationFlow.location.road_number_title'"
                />
                <p class="location-modal__content__form__inputs__div__title__asterisk">*</p>
              </div>
              <input
                v-model="roadNumber"
                type="text"
                style="max-width: 76px"
                class="location-modal__content__form__inputs__div__input"
                :class="{
                  'location-modal__content__form__inputs__div__input--selected': roadNumber,
                }"
              />
            </div>
            <div class="location-modal__content__form__inputs__div">
              <div class="location-modal__content__form__inputs__div__title">
                <p
                  class="location-modal__content__form__inputs__div__title__text"
                  v-t="'registrationFlow.location.road_letter_title'"
                />
              </div>
              <input
                v-model="roadlLetter"
                type="text"
                style="max-width: 76px"
                class="location-modal__content__form__inputs__div__input"
                :class="{
                  'location-modal__content__form__inputs__div__input--selected': roadlLetter,
                }"
              />
            </div>
          </div>
          <div
            class="location-modal__content__form__inputs location-modal__content__form__inputs--checkbox"
          >
            <div
              class="location-modal__content__form__inputs__div location-modal__content__form__inputs__div--checkbox"
            >
              <div class="location-modal__content__form__inputs__div__title">
                <p
                  class="location-modal__content__form__inputs__div__title__text mb-1"
                  v-t="'registrationFlow.location.prefix'"
                />
              </div>
              <div class="d-flex align-center">
                <input
                  v-model="bisPrefix1"
                  type="checkbox"
                  style="height: 20px !important"
                  class="location-modal__content__form__inputs__div__input mr-2"
                  :class="{
                    'location-modal__content__form__inputs__div__input--selected': bisPrefix1,
                  }"
                />
                <p
                  class="location-modal__content__form__inputs__div__bis"
                  v-t="'registrationFlow.location.bis'"
                />
              </div>
            </div>
            <div class="location-modal__content__form__inputs__div">
              <div class="location-modal__content__form__inputs__div__title">
                <p
                  class="location-modal__content__form__inputs__div__title__text"
                  v-t="'registrationFlow.location.quadrant'"
                />
              </div>
              <v-autocomplete
                class="location-modal__content__form__inputs__div__select mr-2"
                :items="quadrantList"
                item-text="name"
                v-model="quadrant"
                :placeholder="$t('registrationFlow.location.quadrant')"
                dense
                outlined
                :dark="quadrant ? true : false"
                :background-color="quadrant ? '#2D10B4' : '#FFFFFF'"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="location-modal__content" style="margin: 0">
        <div class="location-modal__content__title">
          <h3
            class="location-modal__content__title__text"
            v-t="'registrationFlow.location.generating_path'"
          />
          <div class="question-btn">
            <img src="@/assets/iconsPalmira/registration/question.svg" />
          </div>
        </div>
        <div class="location-modal__content__form">
          <div class="location-modal__content__form__inputs">
            <div class="location-modal__content__form__inputs__div mr-2">
              <div class="location-modal__content__form__inputs__div__title">
                <p
                  class="location-modal__content__form__inputs__div__title__text"
                  v-t="'registrationFlow.location.road_number_title'"
                />
                <p class="location-modal__content__form__inputs__div__title__asterisk">*</p>
              </div>
              <input
                v-model="generatingRoadNumber"
                type="text"
                style="max-width: 76px"
                class="location-modal__content__form__inputs__div__input"
                :class="{
                  'location-modal__content__form__inputs__div__input--selected':
                    generatingRoadNumber,
                }"
              />
            </div>
            <div class="location-modal__content__form__inputs__div mr-8">
              <div class="location-modal__content__form__inputs__div__title">
                <p
                  class="location-modal__content__form__inputs__div__title__text"
                  v-t="'registrationFlow.location.road_letter_title'"
                />
              </div>
              <input
                v-model="generationRoadlLetter"
                type="text"
                style="max-width: 76px"
                class="location-modal__content__form__inputs__div__input"
                :class="{
                  'location-modal__content__form__inputs__div__input--selected':
                    generationRoadlLetter,
                }"
              />
            </div>
            <div
              class="location-modal__content__form__inputs__div location-modal__content__form__inputs__div--checkbox"
            >
              <div class="location-modal__content__form__inputs__div__title">
                <p
                  class="location-modal__content__form__inputs__div__title__text mb-1"
                  v-t="'registrationFlow.location.prefix'"
                />
              </div>
              <div class="d-flex align-center">
                <input
                  v-model="bisPrefix2"
                  type="checkbox"
                  style="height: 20px !important"
                  class="location-modal__content__form__inputs__div__input mr-2"
                  :class="{
                    'location-modal__content__form__inputs__div__input--selected': bisPrefix2,
                  }"
                />
                <p
                  class="location-modal__content__form__inputs__div__bis"
                  v-t="'registrationFlow.location.bis'"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="location-modal__content__form mt-6">
          <div class="location-modal__content__form__inputs">
            <div class="location-modal__content__form__inputs__div mr-2">
              <div class="location-modal__content__form__inputs__div__title">
                <p
                  class="location-modal__content__form__inputs__div__title__text"
                  v-t="'registrationFlow.location.home_plate'"
                />
                <p class="location-modal__content__form__inputs__div__title__asterisk">*</p>
              </div>
              <input
                v-model="homePlate"
                type="text"
                style="max-width: 121px"
                class="location-modal__content__form__inputs__div__input"
                :class="{
                  'location-modal__content__form__inputs__div__input--selected': homePlate,
                }"
              />
            </div>
            <div class="location-modal__content__form__inputs__div">
              <div class="location-modal__content__form__inputs__div__title">
                <p
                  class="location-modal__content__form__inputs__div__title__text"
                  v-t="'registrationFlow.location.quadrant'"
                />
              </div>
              <v-autocomplete
                class="location-modal__content__form__inputs__div__select mr-2"
                :items="generatingQudarantList"
                item-text="name"
                v-model="generatingQuadrant"
                :placeholder="$t('registrationFlow.location.quadrant')"
                dense
                outlined
                :dark="generatingQuadrant ? true : false"
                :background-color="generatingQuadrant ? '#2D10B4' : '#FFFFFF'"
              />
            </div>
          </div>
        </div>
        <!-- eslint-disable max-len -->
        <div
          v-if="roadType"
          class="location-modal__content__summary"
          :class="{ 'location-modal__content__summary--background': roadType }"
        >
          {{ roadType }} {{ roadNumber }}{{ roadlLetter }} {{ isbis1() }} {{ quadrant }}
          {{ haveGeneratingRoad() }}
        </div>
      </div>
    </div>
    <div class="btn_container">
      <v-btn icon @click="backMethod">
        <v-img src="@/assets/iconsPalmira/student_register/icons/back.svg" />
      </v-btn>
      <base-button v-if="isDisabled" class="btn-skip" @click="skipStep">
        {{ $t("register.location.address_information.skip_btn") }}
      </base-button>
      <base-button v-else class="btn-continue" @click="continueMethod">
        {{ $t("register.location.address_information.continue_btn") }}
      </base-button>
    </div>
    <v-dialog
      content-class="elevation-0"
      overlay-color="transparent"
      v-model="showSkipModal"
      max-width="370"
    >
      <warning-modal
        @skip="skipConfirmMethod"
        @complete="completeMethod"
        :title="$t('register.location.address_information.warning_modal.skip.title')"
        :text="$t('register.location.address_information.warning_modal.skip.text')"
        color="#FC010A"
        skipModal
      />
    </v-dialog>
    <v-dialog
      content-class="elevation-0"
      overlay-color="transparent"
      v-model="showLocationAlertModal"
      max-width="370"
    >
      <warning-modal
        @skip="skipConfirmMethod"
        @complete="completeMethod"
        :title="$t('register.location.address_information.warning_modal.location_alert.title')"
        :text="$t('register.location.address_information.warning_modal.location_alert.text')"
        color="#FC010A"
        skipModal
        :continueBtnText="
          $t('register.location.address_information.warning_modal.location_alert.continue_btn')
        "
      />
    </v-dialog>
    <v-dialog
      content-class="elevation-0"
      overlay-color="transparent"
      v-model="showLocationNotFoundAlertModal"
      max-width="370"
    >
      <!-- eslint-disable max-len -->
      <warning-modal
        @skip="skipConfirmMethod"
        @complete="completeMethod"
        :title="
          $t('register.location.address_information.warning_modal.location_not_found_alert.title')
        "
        :text="selectText"
        color="#FC010A"
        skipModal
        :continueBtnText="
          $t(
            'register.location.address_information.warning_modal.location_not_found_alert.continue_btn'
          )
        "
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/explorer/base/BaseButton.vue";
import WarningModal from "../WarningModal.vue";

export default {
  name: "AddressInformation",
  components: {
    BaseButton,
    WarningModal,
  },
  data() {
    return {
      sameAddress: null,
      firstUpdate: true,
      showSkipModal: false,
      error: false,
      department: "",
      departmentId: "",
      municipality: "",
      municipalityId: "",
      roadType: "",
      roadNumber: "",
      roadlLetter: "",
      quadrantList: ["Norte", "Sur", "Este", "Oeste"],
      quadrant: "",
      bisPrefix1: false,
      bisPrefix2: false,
      generatingRoadNumber: "",
      generationRoadlLetter: "",
      homePlate: "",
      generatingQudarantList: ["Norte", "Sur", "Este", "Oeste"],
      generatingQuadrant: "",
      isNotStandar: false,
      showLocationAlertModal: false,
      showLocationNotFoundAlertModal: false,
    };
  },
  async mounted() {
    this.sameAddress = this.sameAddressStore;
    if (this.addressStore !== {} && this.sameAddress === false) {
      this.department = this.regions.find(
        (e) => e.id === this.addressStore.location.upper_location
      ).name;
      this.departmentId = this.addressStore.location.upper_location;
      this.municipality = this.addressStore.location.name;
      this.municipalityId = this.addressStore.location.id;
      this.roadType = this.addressStore.vp_tipo_via.tipo_via;
      this.roadNumber = this.addressStore.vp_number;
      this.roadlLetter = this.addressStore.vp_letra;
      this.quadrant = this.addressStore.vp_cuadrante;
      this.bisPrefix1 = this.addressStore.vp_prefijo !== "0";
      this.bisPrefix2 = this.addressStore.vg_prefijo !== "0";
      this.generatingRoadNumber = this.addressStore.vg_numero_via;
      this.generationRoadlLetter = this.addressStore.vg_letra;
      this.homePlate = this.addressStore.vg_placa;
      this.generatingQuadrant = this.addressStore.vg_cuadrante;
    }
  },
  methods: {
    ...mapActions({
      setLocationStepOne: "newStudentRegister/setLocationStepOne",
      setMunicipalityOptions: "options/setMunicipalityOptions",
    }),
    continueMethod() {
      let mainBis = "0";
      let generatingRoadBis = "0";
      this.getMunicipalityId();
      if (this.bisPrefix1) {
        mainBis = "1";
      }
      if (this.bisPrefix2) {
        generatingRoadBis = "1";
      }
      const address = {
        main_address: true,
        address_details:
          this.roadType +
          this.roadNumber +
          this.roadlLetter +
          this.isbis1() +
          this.quadrant +
          this.haveGeneratingRoad(),
        uuid: this.uuid,
        department: this.departmentId,
        municipality: this.municipalityId,
        VP_tipo_via: this.roadType,
        area_label: 1,
        VP_numero: this.roadNumber,
        VP_letra: this.roadlLetter,
        VP_prefijo: mainBis,
        VP_cuadrante: this.quadrant,
        VG_numero_via: this.generatingRoadNumber,
        VG_letra: this.generationRoadlLetter,
        VG_prefijo: generatingRoadBis,
        VG_placa: this.homePlate,
        VG_cuadrante: this.generatingQuadrant,
        address_lat: null,
        address_lon: null,
      };
      if (this.sameAddress === false) {
        const data = {
          opt: this.sameAddress,
          address,
        };
        // BACK => ESTA GUARDANDO MAL EL DEPARTMENT
        // BACK => NO SETEA EL SAME_LOCATION EN FALSE SI LO PONGO EN NO
        this.setLocationStepOne({
          data,
          callback: ({ found }) => {
            if (found) {
              this.$emit("nextStep");
            } else {
              this.showLocationNotFoundAlertModal = true;
            }
          },
        });
      } else if (this.legalGuardianLocationComplete) {
        const data = {
          opt: this.sameAddress,
          address: false,
        };
        this.setLocationStepOne({
          data,
          callback: () => this.$emit("nextStep"),
        });
      } else {
        this.showLocationAlertModal = true;
      }
    },
    changeAddressOpt(value) {
      this.sameAddress = value;
    },
    backMethod() {
      this.$emit("backStep");
    },
    skipStep() {
      this.showSkipModal = true;
    },
    skipConfirmMethod() {
      this.$emit("skipStep");
    },
    completeMethod() {
      this.showSkipModal = false;
      this.showLocationAlertModal = false;
      this.showLocationNotFoundAlertModal = false;
    },
    haveGeneratingRoad() {
      if (this.generatingRoadNumber) {
        let bis = "";
        if (this.bisPrefix2) {
          bis = " Bis ";
        }
        const addresString = `# ${this.generatingRoadNumber}${this.generationRoadlLetter} - ${this.homePlate}${bis}${this.generatingQuadrant}`;
        return addresString;
      }
      return "";
    },
    changeMunicipalityOptions(newRegion) {
      const region = this.regions.find((r) => r.name === newRegion);
      this.departmentId = region.id;
      const municipalityList = [];
      if (this.departmentId) {
        this.municipalities.forEach((municipality) => {
          if (municipality.upper_location === this.departmentId) {
            municipalityList.push(municipality.name);
          }
        });
      }
      this.setMunicipalityOptions(municipalityList);
    },
    addressSet() {
      if (
        this.department.length > 0 &&
        this.municipality.length > 0 &&
        this.roadType.length > 0 &&
        this.roadNumber.length > 0 &&
        this.generatingRoadNumber.length > 0 &&
        this.homePlate.length > 0
      ) {
        return true;
      }
      return false;
    },
    isbis1() {
      if (this.bisPrefix1) {
        return "Bis";
      }
      return "";
    },
    getMunicipalityId() {
      this.municipalities.forEach((municipality) => {
        if (this.municipality === municipality.name) {
          this.municipalityId = municipality.id;
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      sameAddressStore: "newStudentRegister/sameAddress",
      addressStore: "newStudentRegister/address",
      regionLabels: "options/regionLabels",
      regions: "options/regions",
      municipalityLabels: "options/municipalityLabels",
      municipalities: "options/municipalities",
      roadTypeLabels: "options/roadTypeLabels",
      uuid: "newStudentRegister/uuid",
      legalGuardianLocationComplete: "userRegister/locationComplete",
    }),
    isDisabled() {
      return this.sameAddress === null;
    },
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
    selectText() {
      if (this.legalGuardianLocationComplete) {
        return this.$t(
          "register.location.address_information.warning_modal.location_not_found_alert.text_1"
        );
      }
      return this.$t(
        "register.location.address_information.warning_modal.location_not_found_alert.text_2"
      );
    },
  },
};
</script>
