<template>
  <section class="mime-menu">
    <button class="mt-5" @click="closeMenu" type="button">
      <v-icon class="header-menu__logo__close"> $vuetify.icons.closeMapCard </v-icon>
    </button>
    <p class="mime-header__title" v-t="'menu.mime-about-us.welcome'" />

    <p class="mime-header__main_text" v-t="'menu.mime-about-us.main-text'" />
    <p class="mime-header__main_text" v-t="'menu.mime-about-us.list-title'" />

    <ul class="mime-header__main_list">
      <li
        class="mime-header__main_li"
        v-for="(item, index) in $t('menu.mime-about-us.list-content')"
        :key="index"
      >
        {{ item }}
      </li>
    </ul>
    <div>
      <p class="mime-header__main_text">
        {{ $t("menu.mime-about-us.final-text") }}
      </p>
    </div>
    <div class="mime-header__main_text">
      <div>
        {{ $t("menu.mime-about-us.question1") }}
      </div>
      <div class="mime-header__main_text__finale">
        {{ $t("menu.mime-about-us.question2") }}
      </div>
    </div>
    <div class="mime-header__contact">
      <a class="mime-header__contact__btn" :href="CONFIG.contactGoogleFom" target="_blank">
        {{ $t("campus-details.pride_point.contact") }}
      </a>
    </div>
  </section>
</template>

<script>
import CONFIG from "@/config";

export default {
  name: "MimeAboutUs",
  data() {
    return {
      CONFIG,
    };
  },
  methods: {
    closeMenu() {
      this.$emit("close");
    },
  },
};
</script>
