<template>
  <section class="full-width d-flex flex-column bg-white align-center py-10">
    <div class="w-full feedback-max-width feedback-padding-x">
      <div class="w-full d-flex items-start flex-column">
        <h1
          v-t="'feedback.applications.title_results'"
          class="feedback-title-bg-white feedback-bg-gray"
        />
      </div>

      <p class="mt-5 mb-10 feedback-end__text">
        {{ textDescription }}
      </p>

      <section v-if="treatment === 1" class="feedback-applied-school mb-10">
        <div
          class="feedback-applied-school__text-no-assigned feedback-applied-school__text-aqua"
          v-t="'feedback.applications.assigned'"
        />
        <div class="d-flex flex-row feedback-applied-school__border">
          <span class="feedback-applied-school__index"> </span>
          <h3 class="feedback-applied-school__name">
            {{ campusAdmission.campus.campus_name }}
          </h3>
        </div>
        <div class="feedback-applied-school__information">
          <div>{{ campusAdmission.campus.program.shift_label.shift_name }}</div>
          <div>{{ campusAdmission.campus.program.gender_label.gender_name }}</div>
        </div>
        <div class="mt-1 mb-2 mx-1 pb-3">
        </div>
      </section>

      <section
        v-for="(school, index) in campusNoAdmission"
        :key="index"
        class="feedback-applied-school mb-4"
      >
        <div
          class="feedback-applied-school__text-no-assigned"
          v-t="'feedback.applications.no-assigned'"
        />
        <div class="d-flex flex-row feedback-applied-school__border">
          <span class="feedback-applied-school__index feedback-applied-school__no-assigned">
            N° {{ index + 1 }}
          </span>
          <h3 class="feedback-applied-school__name">
            {{ school.campus.campus_name }}
          </h3>
        </div>
        <div class="feedback-applied-school__information">
          <div>{{ school.campus.program.shift_label.shift_name }}</div>
          <div>{{ school.campus.program.gender_label.gender_name }}</div>
        </div>
        <div class="mt-1 mb-2 mx-1 pb-3">
          <ApplicationsToDate
            v-if="school.showApplicationsToDate"
            :applicantId="applicantId"
            :school="school.applicationsToDate"
          />
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import ApplicationsToDate from "./ApplicationsToDate.vue";

export default {
  name: "SchoolsYouApplied.vue",
  components: {
    ApplicationsToDate,
  },
  props: {
    schools: {
      type: Array,
      default: () => [],
    },
    applicantId: {
      type: Number,
    },
    treatment: {
      type: Number,
      required: true,
    },
    noAdmission: {
      type: Array,
      default: () => [],
    },
    admission: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      campusNoAdmission: [],
      campusAdmission: {},
    };
  },
  computed: {
    textDescription() {
      return this.treatment === 1
        ? this.$t("feedback.applications.description_results")
        : this.$t("feedback.applications.description_results_others");
    },
  },
  mounted() {
    this.campusNoAdmission = Object.assign([], this.noAdmission);
    this.campusAdmission = { ...this.admission };

    if (this.treatment === 1) {
      const campusRiskAdmission =
        this.schools.filter((school) => school.campus.uuid === this.admission.campus.campus_uuid) ??
        [];
      this.campusAdmission = {
        ...this.campusAdmission,
        showApplicationsToDate: campusRiskAdmission.length > 0,
        applicationsToDate: { ...campusRiskAdmission[0] },
      };
    }

    this.campusNoAdmission.forEach((campus) => {
      const campusRisk =
        this.schools.filter((school) => school.campus.uuid === campus.campus.campus_uuid) ?? [];
      campus.showApplicationsToDate = campusRisk.length > 0;
      campus.applicationsToDate = { ...campusRisk[0] };
    });
  },
};
</script>
