<template>
  <div class="dashboard d-flex" :class="mobile ? 'flex-column' : 'flex-row'" data-app>
    <transition name="fade">
      <div class="dashboard__overlay" @click="showProfile = ''" v-if="showProfile == 'profile'" />
    </transition>
    <v-dialog
      v-if="mobile"
      v-model="showNavigationBar"
      max-width="150"
      transition="slide-x-transition"
      content-class="main-conatiner__row__dialog"
    >
      <!-- sidebar (mobile) -->
      <custom-menu :inDashboard="true" @closeMenu="showRegistrationModal" />
      <!-- end sidebar (mobile) -->
    </v-dialog>
    <!-- sidebar (desktop) -->
    <custom-menu :inDashboard="true" v-if="!mobile" @closeMenu="showRegistrationModal" />
    <!-- end sidebar (desktop) -->

    <!-- container -->
    <div class="d-flex flex-column w-100 dashboard__container">
      <div
        class="d-flex flex-row"
        :class="mobile ? 'dashboard__navbar__mobile' : 'dashboard__navbar__desktop'"
      >
        <div class="dashboard__navbar__content" :style="{ width: mobile ? '100%' : '32%' }">
          <!-- icon -->
          <img
            v-if="mobile"
            class="dashboard__header__icon"
            @click="showMenu"
            src="@/assets/iconsChile/header/menu.svg"
          />
          <!-- end icon -->
          <!-- avatar -->
          <div class="dashboard__avatar-container">
            <v-avatar
              color="white"
              v-if="mobile"
              @click="toggleProfile"
              size="29"
              style="padding-bottom: 2px"
            >
              {{ getProfileLetter }}
            </v-avatar>
          </div>
          <!-- end avatar -->
          <!-- profile -->
          <Section
            :title="getProfileTitle"
            v-if="mobile ? showProfile == 'profile' : true"
            style="position: absolute; z-index: 200; width: 100%; background-color: #deebff"
            :class="mobile ? 'dashboard__navbar__profile__mobile' : ''"
            name="profile"
            :showStudentModals="showStudentModals"
            :role="role"
            height="50vh"
            @toggle="toggleProfile"
            toggableDirection="down"
            :currentSection="showProfile"
          />
          <!-- end profile -->
          <!-- search -->
          <div class="dashboard__navbar__search-container">
            <v-text-field
              v-if="mobile"
              class="search-in-all-schools__container__btn__text-field"
              v-model="searchInput"
              label="buscar"
              solo
              dense
              height="10"
              min-height="10"
              max-height="10"
              color="#828282"
              prepend-inner-icon="mdi-magnify"
            />
          </div>
          <!-- end search -->
        </div>
        <div style="width: 20px"></div>
        <!-- search (desktop) -->
        <div v-if="!mobile" class="dashboard__navbar__search">
          <!--<div v-if="role=='parent' || role == 'student'" style="display:flex;flex-direction:row;gap:10px;margin-left:10px;">
          <div style="padding:2px 5px 2px 5px;border-radius:2px;border: 1px solid #2B5BFF;cursor: pointer;" v-for="(student, index) in students" @click="selectStudent(student)" :style="getStudentStyle(student)" :key="index">
            {{student.first_name}} {{student.last_name}}
          </div>
        </div>-->
          <div class="dashboard__navbar__search__input">
            <v-text-field
              class="search-in-all-schools__container__btn__text-field"
              v-model="searchInput"
              label="buscar"
              solo
              dense
              height="10"
              min-height="10"
              max-height="10"
              color="#828282"
              prepend-inner-icon="mdi-magnify"
            />
          </div>
        </div>
      </div>
      <!-- end navbar -->
      <div
        class="sections-container"
        :style="{
          'background-color': 'white',
          'margin-top': mobile ? '0px' : '30px',
        }"
      >
        <Section
          v-for="(section, index) in filteredSections"
          :key="index"
          :title="section.title"
          :toggableDirection="section.toggableDirection"
          :height="section.height ? section.height : getSectionHeight()"
          :width="getSectionWidth()"
          :name="section.section"
          @toggle="toggle"
          :showStudentModals="showStudentModals"
          :background="section.background"
          :currentSection="toggledSection"
          :fullSpace="section.fullSpace"
          :role="role"
        />
      </div>
    </div>
    <!-- end container -->

    <!--<v-dialog
     persistent
     v-model="showWarningModal"
     max-width="400"
     transition="slide-x-transition"
     content-class="elevation-0 mx-12"
   >
       <WarningModal
         @close="closeWarningModal"
         @go-login="signIn"
         :pathTo="pathTo"
       />
   </v-dialog> -->

    <!--<registration-flow
    class="dashborad-modal-flow"
    :isGuest="isGuest"
    @showStudent="showFirstStudentModal"
  />-->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import ResetPassword from '@/components/dashboard/modals/ResetPassword.vue';
// import LoginModal from '@/components/explorer/guest/LoginModal.vue';
import Section from "../../components/dashboard/Section.vue";
// import WarningModal from '../../components/explorer/guest/WarninigModal.vue';
// import Reports from '../../components/dashboard/Reports.vue';
import CustomMenu from "../../layouts/CustomMenu.vue";
// import MobileHeader from '../../layouts/MobileHeader.vue';
// import DashboardMap from '../../components/dashboard/DashboardMap.vue';
// import RegistrationFlow from '../../components/dashboard/RegistrationFlow.vue';
export default {
  name: "Dashboard",
  components: {
    // Reports,
    CustomMenu,
    // MobileHeader,
    // ResetPassword,
    // DashboardMap,
    // LoginModal,
    Section,
    // WarningModal,
  },
  data() {
    return {
      showProfile: "",
      role: "parent",
      searchInput: "",
      toggledSection: "profile",
      sections: [
        {
          section: "profile",
          title: "Perfil estudiante",
          permissions: ["student", "guest"],
          toggableDirection: "down",
          height: "48%",
        },
        {
          section: "tools",
          title: "Herramientas",
          permissions: ["headmaster", "parent", "student"],
        },
        /* { section: 'analytics', title: 'Analytics', permissions: ['student', 'guest'], height: '38%' },
        { section: 'analytics', title: 'Analytics', permissions: ['headmaster', 'parent'] }, */
        {
          section: "messages",
          title: "Notificaciones",
          permissions: ["student"],
          toggableDirection: "up",
          height: "48%",
        },
        {
          section: "messages",
          title: "Notificaciones",
          permissions: ["headmaster", "parent"],
        },

        {
          section: "admissions",
          title: "Admisiones y postulaciones",
          permissions: ["headmaster"],
        },
        {
          section: "simulation",
          title: "Admisión Histórica",
          permissions: ["parent", "student"],
        },
        {
          section: "digitalprofile",
          title: "Perfil digital",
          permissions: ["headmaster"],
        },
        { section: "dfm", title: "DFM", permissions: ["headmaster"] },

        /* { section: 'graph', title: 'Graph', permissions: ['headmaster', 'student', 'parent']},
        { section: 'reportlist', title: 'Analytics', permissions: ['headmaster', 'student', 'parent']}, */
        {
          section: "schoollist",
          title: "Mis establecimientos",
          permissions: ["student", "parent"],
        },
        {
          section: "dashboardmap",
          title: "Establecimientos en tu lista",
          permissions: ["student", "parent"],
          fullSpace: true,
        },
      ],
      dialogClosed: false,
      showNavigationBar: false,
      showFirstLogin: false,
      showStudentModals: false,
      showFirstModal: false,
      showUserRegister: false,
      showWarningModal: false,
      pathTo: false,
    };
  },
  async created() {
    this.retrieveRegionsGenderAndGrades();
    await this.retrieveRegistrationOptions();
  },
  mounted() {
    if (!this.isGuest) {
      if (this.currentStep !== 5) {
        if (!this.preCheckStatus.is_verified) {
          this.logout();
        }
      }
      this.retrieveFavorites();
      if (Object.keys(this.currentStudent).length !== 0) {
        this.retrieveExploredCampuses();
        this.retrieveExploredSchoolCard();
      }
    }
    if (this.artificial && !this.userFirstLogin) {
      this.showFirstLogin = true;
    }
    if (this.isGuest) {
      this.setCurrentStep({
        nextStep: 1,
      });
      this.showUserRegister = true;
    }
    if (!this.isGuest && !this.identificationComplete) {
      this.setCurrentStep({
        nextStep: 5,
      });
    }
  },
  computed: {
    ...mapGetters({
      userUsername: "authentication/userUsername",
      showCampusDetail: "institutions/showCampusDetail",
      isGuest: "authentication/isGuest",
      artificial: "authentication/artificial",
      userFirstLogin: "authentication/userFirstLogin",
      identificationComplete: "userRegister/identificationComplete",
      currentStudent: "authentication/currentStudent",
      students: "authentication/students",
      preCheckStatus: "authentication/preCheckStatus",
      currentStep: "userRegister/currentStep",
    }),
    mobile() {
      // return screen.width < 960;
      return this.$vuetify.breakpoint.smAndDown;
    },
    getProfileLetter() {
      if (this.userUsername && this.userUsername.length > 0) {
        return this.userUsername[0];
      }
      return "";
    },
    getProfileTitle() {
      if (this.mobile) {
        return "";
      }
      if (this.userUsername) {
        return `${this.userUsername} <span style='font-weight: normal'> | apoderada/o </span>`;
      }
      return "Perfil <span style='font-weight: normal'> | apoderada/o </span>";
    },
    filteredSections() {
      return this.sections.filter((section) => {
        if (this.searchInput == "") {
          return this.hasPermissions(section.permissions);
        }

        return (
          this.hasPermissions(section.permissions) &&
          section.title.normalize("NFD").toLowerCase().includes(this.searchInput.toLowerCase())
        );
      });
    },
  },

  methods: {
    ...mapActions({
      retrieveFavorites: "institutions/retrieveFavorites",
      retrieveRegistrationOptions: "options/retrieveRegistrationOptions",
      setCurrentStep: "userRegister/setCurrentStep",
      retrieveExploredCampuses: "institutions/retrieveExploredCampuses",
      retrieveExploredSchoolCard: "institutions/retrieveExploredSchoolCard",
      logoutSuccess: "authentication/logoutSuccess",
      retrieveSchoolNames: "institutions/retrieveSchoolNames",
      retrieveRegionsGenderAndGrades: "options/retrieveOptions",
      getPrecheckData: "authentication/getPrecheckData",
    }),
    toggle(section) {
      this.toggledSection = section;
    },
    toggleProfile() {
      if (this.showProfile == "") {
        this.showProfile = "profile";
      } else {
        this.showProfile = "";
      }
    },
    selectStudent(student) {
      if (this.currentStudent == student) {
        this.currentStudent = null;
        this.role = "parent";
      } else {
        this.toggledSection = "messages";
        this.currentStudent = student;
        this.role = "student";
      }
    },
    getStudentStyle(student) {
      if (this.currentStudent == student) {
        return { "background-color": "#2B5BFF", color: "white" };
      }
      return { "background-color": "transparent", color: "#2B5BFF" };
    },
    getSectionHeight() {
      if (this.filteredSections.length <= 7) {
        return "48%";
      }
      if (this.filteredSections.length <= 16) {
        return "31%";
      }
      return "23%";
    },
    getSectionWidth() {
      const sectionLength = this.filteredSections.length;
      if (this.mobile) {
        return "100%";
      }
      if (sectionLength <= 4) {
        return "49%";
      }
      if (sectionLength <= 9) {
        return "32%";
      }
      if (sectionLength <= 12) {
        return "24%";
      }
      if (sectionLength <= 19) {
        return "18%";
      }
      return "15%";
    },
    hasPermissions(permissions) {
      return permissions.includes(this.role);
    },
    showMenu() {
      this.showNavigationBar = true;
    },
    closeWarningModal() {
      this.showWarningModal = false;
    },
    /* openWarningModal(evt) {
      this.pathTo = evt;
      this.showWarningModal = true;
    }, */
    signIn() {
      this.showWarningModal = false;
      this.$router.push({ name: "Login" });
    },
    CloseResetModal() {
      this.showFirstLogin = false;
    },
    resetSteps() {
      this.showFirstModal = false;
    },
    showFirstStudentModal() {
      this.showStudentModals = true;
    },
    showRegistrationModal() {
      this.showNavigationBar = false;
      this.showFirstModal = true;
      this.setCurrentStep({
        nextStep: 1,
      });
    },
    logout() {
      this.$gAuth.signOut()
      this.logoutSuccess({
        callback: () => {
          this.showUserRegister = false;
          this.$router.push("/");
        },
      });
    },
  },
};
</script>
<style>
/* sections styles */
.sections {
  padding: 2rem;
  max-height: 100vh;
  height: 100vh;
}

.sections > ul {
  display: grid;
  grid-template-rows: fit-content(40%);
  max-width: 50%;

  grid-gap: 1rem;
}

.sections > ul > li {
  border: 1px solid #e2e2e2;
  border-radius: 0.5rem;
}

.sections-container {
  display: flex;
  flex-flow: column wrap;
  margin-top: 20px;
  max-height: calc(100vh - 100px);
  height: calc(100vh - 100px);
  min-height: calc(100vh - 100px);
  background: #deebff;
  gap: 15px;
}

@media (max-width: 960px) {
  .sections-container {
    float: left;
    display: block;
    overflow: auto;
    max-height: none;
    height: 100vh;
  }
  .sections-container::-webkit-scrollbar {
    display: none;
  }
  .area {
    max-width: 100%;
    margin-top: 15px;
  }
  .section {
    margin-top: 10px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
  min-height: 30px;
}
</style>
