<template>
  <div>
    <div
      v-if="CONFIG.feedbackType !== 'Results'"
      class="bg-gradient-primary w-full min-h-1/2 flex justify-center"
      :class="{ 'feedback-footer': CONFIG.feedback === 'Palmira' }"
    >
      <div class="w-full max-w-desktop px-10 py-8">
        <picture>
          <img src="@/assets/svg/blue-ring.svg" />
        </picture>
        <p
          class="mt-1 text-base"
          v-t="'feedback.end_feedback.title'"
          :class="{ 'feedback-footer__title': CONFIG.feedback === 'Palmira' }"
        />
        <div class="flex justify-center mt-6">
          <div
            @click="openPostulation('/postulate')"
            class="bg-white text-primary text-center border border-primary hover:bg-primary hover:text-white hover:border-white py-7 px-3 w-60 rounded-full"
            :class="{ 'feedback-footer__btn': CONFIG.feedback === 'Palmira' }"
            v-t="'feedback.end_feedback.modify_application'"
          />
        </div>
        <p
          class="mt-9 font-medium text-sm"
          :class="{ 'feedback-footer__text': CONFIG.feedback === 'Palmira' }"
          v-t="'feedback.end_feedback.warning'"
        />
        <p
          class="mt-3 text-sm"
          :class="{
            'feedback-footer__text feedback-footer__text--light': CONFIG.feedback === 'Palmira',
          }"
          v-t="'feedback.end_feedback.text'"
        />
      </div>
    </div>
    <div class="feedback-results-footer pt-8" v-if="CONFIG.feedbackType === 'Results'">
      <h1 v-t="'feedback.end_feedback.results_title'" class="feedback-results-footer__title" />
      <div class="feedback-results-footer__content">
        <img class="mr-4" src="@/assets/iconsPalmira/feedback/sheet.svg" />
        <p
          class="feedback-results-footer__content__text"
          v-t="'feedback.end_feedback.results_content'"
        />
      </div>
      <p
        class="feedback-results-footer__text"
        v-t="'feedback.end_feedback.results_content_footer'"
      />
      <button
        v-t="'feedback.end_feedback.explore_btn'"
        class="feedback-results-footer__btn"
        @click="explore('/explorer')"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CONFIG from "../../config";

export default {
  name: "CloseFeedback",
  data() {
    return {
      CONFIG,
    };
  },
  computed: {
    ...mapGetters({
      legalGuardianUuid: "authentication/legalGuardianUUID",
    }),
  },
  methods: {
    openPostulation(path) {
      this.$mixpanel.track("modify_application", {
        distinct_id: this.legalGuardianUuid,
        env: process.env.VUE_APP_ENV,
      });
      this.$router.push(path);
    },
    explore(path) {
      this.$mixpanel.track("feedback_explore", {
        distinct_id: this.legalGuardianUuid,
        env: process.env.VUE_APP_ENV,
      });
      this.$router.push(path);
    },
  },
};
</script>
