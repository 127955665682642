<template>
  <section class="login">
    <div class="login__container">
      <picture class="mt-7 login__picture">
        <img src="@/assets/iconsChile/header/mime-side-logo.svg" width="120px" />
      </picture>
      <h1 class="login__title mt-10" v-t="'views.login.reset-password'" />

      <div class="login__form">
        <label class="login__form--label mt-7" for="password">
          {{ $t("views.reset_password.new_password") }}
          <span class="login__form--dot">*</span>
        </label>

        <div>
          <input
            class="login__form--input"
            id="password"
            v-model="newPassword"
            :type="showPassword ? 'text' : 'password'"
            @input="$v.newPassword.$touch()"
            @blur="$v.newPassword.$touch()"
          />
          <v-btn
            @click="showPassword = !showPassword"
            class="login__btn--password"
            icon
            color="#2D10B4"
            type="button"
          >
            <v-icon>{{ showPassword ? "mdi-eye" : "mdi-eye-off" }} </v-icon>
          </v-btn>
        </div>
        <p v-for="(error, index) in newPasswordErrors" :key="index" class="login__form--error">
          {{ error }}
        </p>
        <label class="login__form--label mt-7" for="password">
          {{ $t("views.reset_password.repeat_password") }}
          <span class="login__form--dot">*</span>
        </label>

        <div>
          <input
            class="login__form--input"
            id="password"
            :type="showPassword ? 'text' : 'password'"
            v-model="repeatNewPassword"
            @input="$v.repeatNewPassword.$touch()"
            @blur="$v.repeatNewPassword.$touch()"
          />
          <v-btn
            @click="showPassword = !showPassword"
            class="login__btn--password"
            icon
            color="#2D10B4"
            type="button"
          >
            <v-icon>{{ showPassword ? "mdi-eye" : "mdi-eye-off" }} </v-icon>
          </v-btn>
        </div>
        <p
          v-for="(error, index) in repeatNewPasswordErrors"
          :key="index"
          class="login__form--error"
        >
          {{ error }}
        </p>

        <button
          class="login__reset mt-7"
          type="button"
          @click="changePassword"
          v-t="'views.reset_password.send'"
        />
      </div>
    </div>
  </section>
  <!-- <v-container class="reset_password auth px-0 pb-0">
    <v-row no-gutters justify="center" class="fill-width">
      <div class="reset_password-container">
        <div class="reset_password__title-container">
          <auth-header/>
        </div>
        <div class="d-inline-flex shadow-container mt-12 fill-width">
          <input
            type="password"
            class="reset_password__input"
            :placeholder="$t('views.reset_password.new_password')"
            v-model="newPassword"
            @input="$v.newPassword.$touch()"
            @blur="$v.newPassword.$touch()"
          />
        </div>
        <div class="pl-2">
          <p
            v-for="(error, index) in newPasswordErrors"
            :key="index"
            class="text text--small-01 text--error"
          >
            {{ error }}
          </p>
        </div>
        <div class="d-inline-flex shadow-container mt-4 fill-width">
          <input
            type="password"
            class="reset_password__input"
            :placeholder="$t('views.reset_password.repeat_password')"
            v-model="repeatNewPassword"
            @input="$v.repeatNewPassword.$touch()"
            @blur="$v.repeatNewPassword.$touch()"
          />
        </div>
        <div class="pl-2">
          <p
            v-for="(error, index) in repeatNewPasswordErrors"
            :key="index"
            class="text text--small-01 text--error"
          >
            {{ error }}
          </p>
        </div>
        <div class="d-inline-flex justify-center mt-8 fill-width">
          <base-button
            class="reset_password__btn btn--primary-01 py-4"
            @click="changePassword"
          >
            {{$t('views.reset_password.send')}}
          </base-button>
        </div>
      </div>
    </v-row>
  </v-container> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "ResetPassword",
  data() {
    return {
      showPassword: false,
      newPassword: null,
      repeatNewPassword: null,
    };
  },
  validations: {
    newPassword: {
      required,
    },
    repeatNewPassword: {
      required,
      equal(repeatNewPassword) {
        return repeatNewPassword === this.newPassword;
      },
    },
  },
  computed: {
    ...mapGetters({
      resetPasswordStatus: "authentication/resetPasswordStatus",
    }),
    newPasswordErrors() {
      const errors = [];
      if (!this.$v.newPassword.$dirty) {
        return errors;
      }
      if (!this.$v.newPassword.required) {
        errors.push(this.$t("views.reset_password.error.required"));
      }
      return errors;
    },
    repeatNewPasswordErrors() {
      const errors = [];
      if (!this.$v.repeatNewPassword.$dirty) {
        return errors;
      }
      if (!this.$v.repeatNewPassword.required) {
        errors.push(this.$t("views.reset_password.error.required"));
      }
      if (!this.$v.repeatNewPassword.equal) {
        errors.push(this.$t("views.reset_password.error.not_equals"));
      }
      return errors;
    },
  },
  methods: {
    ...mapActions({
      resetPassword: "authentication/resetPassword",
      success: "snackbar/success",
      error: "snackbar/error",
    }),
    raiseSnackbar() {
      if (this.resetPasswordStatus === "OK") {
        this.success(this.$t("views.reset_password.password_changed"));
        this.$router.push({ name: "Login" });
      } else {
        this.error(this.$t("views.reset_password.password_not_changed"));
      }
    },
    changePassword() {
      const params = this.$route.query;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.resetPassword({
          token: params.token,
          password: this.newPassword,
          callback: () => {
            this.raiseSnackbar();
          },
          // errorCallback: (error) => {
          //   console.error(error);
          //   this.error(this.$t('views.reset_password.password_not_changed'));
          // },
          // callback: () => {
          //   console.log('entry');
          //   this.success(this.$t('views.reset_password.password_changed'));
          //   this.$router.push({ name: 'Login' });
          // },
        });
      }
      // this.raiseSnackbar();
    },
  },
};
</script>
