<template>
  <div>
    Lista de reportes
    <div v-for="(report, index) in reports" :key="index">Hola</div>
  </div>
</template>
<script>
export default {
  name: "ReportList",
  props: {
    reports: {
      type: Array,
      required: true,
    },
  },
};
</script>
