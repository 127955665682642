var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pridePoints.length > 0)?_c('section',{staticClass:"campus-detail__pride__point"},[_vm._l((_vm.sortPridePoints),function(ref,index){
var pridepoint_description = ref.pridepoint_description;
var pridepoint_title = ref.pridepoint_title;
var images_pridepoint = ref.images_pridepoint;
return [(pridepoint_title)?_c('div',{staticClass:"w-full mt-1"},[_c('h3',{staticClass:"campus-detail__pride__point--subtitle"},[_vm._v(" "+_vm._s(pridepoint_title)+" ")])]):_vm._e(),_c('p',{staticClass:"campus-detail__pride__point--text mt-1",class:{ 'mt-0 mb-3': !_vm.exitsImagesPridePoint(images_pridepoint) }},[_vm._v(" "+_vm._s(pridepoint_description)+" ")]),(_vm.exitsImagesPridePoint(images_pridepoint))?_c('v-carousel',{staticClass:"mt-2 mb-5 campus-detail__pride__point--carrousel",attrs:{"hide-delimiter-background":"","show-arrows-on-hover":""}},_vm._l((images_pridepoint),function(ref){
var image = ref.image;
var index = ref.index;
return _c('v-carousel-item',{key:index,attrs:{"reverse-transition":"fade-transition","transition":"fade-transition"}},[_c('picture',[_c('img',{staticClass:"campus-detail__pride__point--image",attrs:{"src":image}})])])}),1):_vm._e(),(_vm.showDivisor(index))?_c('hr',{staticClass:"mb-5"}):_vm._e()]})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }