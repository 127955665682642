<template>
  <section class="campus-detail__focus campus-detail__module mt-2">
    <div class="w-full d-flex flex-row align-center">
      <div
        class="campus-detail__focus__information"
        :class="{ 'campus-detail__focus__information--mobile': isMobile }"
      >
        <h1 v-t="'campus-details.education_focus.title'" />

        <button @click="downloadPdf" type="button">
          <span v-t="'campus-details.education_focus.summary'" />
          <img class="ml-2" src="@/assets/icons/direct-download.svg" />
        </button>
      </div>
      <div
        class="campus-detail__focus__multimedia"
        :class="{ 'campus-detail__focus__multimedia--mobile': isMobile }"
      >
        <template v-if="educationalFocusVideo">
          <div
            class="campus-detail__focus__multimedia--video"
            :class="{
              'campus-detail__focus__multimedia--video--mobile': isMobile,
            }"
          >
            <v-carousel height="auto" show-arrows-on-hover hide-delimiters>
              <v-carousel-item
                v-for="(url, index) in educationalFocusVideo"
                :key="index"
              >
                <CampusDetailVideo :media="true" :video="url" />
              </v-carousel-item>
            </v-carousel>
          </div>
        </template>
        <template v-else>
          <div
            class="campus-detail__focus__multimedia--no-video"
            :class="{
              'campus-detail__focus__multimedia--no-video--mobile': isMobile,
            }"
          >
            <img
              src="@/assets/iconsNewHaven/campusDetail/exclamation-mark.svg"
            />
            <span v-t="'campus-details.education_focus.no-video'" />
          </div>
        </template>
      </div>
    </div>
    <div
      v-if="!educationalFocusVideo"
      class="campus-detail__focus__message"
      :class="{ 'campus-detail__focus__message--mobile': isMobile }"
    >
      <span>
        {{ $t("campus-details.education_focus.message-one") }}
        <button
          class="mx-1 font-semibold"
          @click="goToContactUs"
          v-t="'campus-details.education_focus.contact'"
        />
      </span>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CampusDetailVideo from "@/components/explorer/general/campus_detail/CampusDetailVideo.vue";

export default {
  name: "CampusDetailEducationalFocus",
  components: {
    CampusDetailVideo,
  },
  props: {
    educationalFocusVideo: {
      type: Array,
      default: () => [],
    },
    pdf: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      campusDetail: "institutions/campusDetails",
      currentStudent: "authentication/currentStudent",
    }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapGetters({
      legalGuardianUUID: "authentication/legalGuardianUUID",
      environment: "authentication/environment",
    }),
    ...mapActions({
      setGoToFormContactFrom: "contactForm/setGoToFormContactFrom",
    }),
    downloadPdf() {
      this.$mixpanel.track("click_school_project_download", {
        distinct_id: this.legalGuardianUUID(),
        student_id: this.currentStudent.uuid,
        school_id: this.campusDetail.uuid,
        env: this.environment(),
      });
      window.open(this.pdf);
    },
    goToContactUs() {
      document
        .getElementById("contact-us")
        .scrollIntoView({ behavior: "smooth" });
      this.setGoToFormContactFrom({ from: "educational-proyect" });
    },
  },
};
</script>
