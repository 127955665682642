<template>
  <section class="d-flex align-center flex-column contact" v-if="!successSendMail">
    <form @submit.prevent="sendContact" class="contact__form">
      <p class="mt-5 contact__title">
        {{ $t("contact.title") }} <span class="text-red font-medium">*</span>
      </p>

      <!-- name -->
      <label class="mt-5 contact__title text-left d-block" for="name">
        {{ $t("contact.name.title") }}
        <span class="text-red font-medium">*</span>
      </label>
      <input
        v-model="form.username"
        :placeholder="$t('contact.name.placeholder')"
        class="contact__input mt-2"
        id="name"
        type="text"
      />
      <p v-if="error.username" class="font-normal text-red text-xss" v-t="'contact.error'" />

      <!-- position -->
      <template v-if="isContactPlataformForm">
        <label class="mt-5 contact__title text-left d-block" for="school-contact">
          {{ $t("contact.position.title") }}
          <span class="text-red font-medium">*</span>
        </label>
        <select class="contact__select mt-2" id="school-contact" v-model="selectedPosition">
          <option value="empty" disabled selected v-t="'contact.position.placeholder'" />
          <option
            v-for="(position, index) in $t('contact.position-list')"
            :key="index"
            :value="position"
          >
            {{ position }}
          </option>
        </select>
      </template>

      <!-- email -->
      <label class="mt-5 contact__title text-left d-block" for="email">
        {{ $t("contact.email.title") }}
        <span class="text-red font-medium">*</span>
      </label>
      <input
        class="contact__input mt-2"
        id="email"
        v-model="form.mail_from"
        type="email"
        :placeholder="$t('contact.email.placeholder')"
      />
      <p v-if="error.email" class="font-normal text-red text-xss" v-t="'contact.error'" />

      <!-- school contact -->
      <template v-if="!isContactPlataformForm">
        <label class="mt-5 contact__title text-left d-block" for="school-contact">
          {{ $t("contact.school_contact.title") }}
          <span class="text-red font-medium">*</span>
        </label>
        <select class="contact__select mt-2" id="school-contact" v-model="selectedContact">
          <option value="empty" disabled selected v-t="'contact.school_contact.placeholder'" />
          <option
            v-for="({ name, email }, index) in listContacts"
            :key="index"
            :value="{ name, email }"
            :disabled="!email"
          >
            {{ name }}
          </option>
        </select>
        <p v-if="error.contact" class="font-normal text-red text-xss" v-t="'contact.error'" />
      </template>

      <!-- default message -->
      <p class="mt-5 contact__title" v-t="'contact.select_default_message'" />
      <div class="w-full d-flex flex-row flex-wrap justify-between">
        <div
          class="contact__option__radio--button"
          v-for="(text, index) in listRadioButtons"
          :key="index"
        >
          <input
            v-model="form.message_title"
            class="mt-2"
            type="radio"
            :id="'radio' + text"
            :value="text"
          />
          <label class="mt-2" :for="'radio' + text">
            {{ text }}
          </label>
        </div>
      </div>

      <!-- Personalize message -->
      <label
        v-t="'contact.personalized_message.title'"
        class="mt-7 contact__title text-left d-block"
        for="email"
      />
      <textarea
        class="contact__text-area mt-3"
        v-model="form.message"
        :placeholder="$t('contact.personalized_message.placeholder')"
      />

      <!-- button send message -->
      <div class="d-flex justify-end">
        <button class="mt-7 mb-6 contact__send" type="submit" :disabled="loading">
          <span v-if="!loading" v-t="'contact.send'" />
          <v-progress-circular v-else indeterminate color="white" />
        </button>
      </div>

      <!-- terms -->
      <p class="my-3 contact__title font-medium text-center" v-t="'contact.terms'" />
    </form>
  </section>
  <section class="contact__sucess-email mt-4" v-else>
    <v-icon large color="white"> mdi-check </v-icon>
    <p v-t="'contact.message_sent'" />
  </section>
</template>

<script>
import { mapActions } from "vuex";
import CONFIG from "@/config";

export default {
  name: "Contact",
  props: {
    isContactPlataformForm: {
      type: Boolean,
      required: true,
    },
    schoolName: {
      type: String,
      required: true,
    },
    schoolId: {
      required: true,
    },
    schoolUuid: {
      required: true,
    },
    schoolEmail: {
      type: String,
      required: true,
    },
    campusName: {
      type: String,
      required: true,
    },
    contacts: {
      type: Array,
    },
  },
  data() {
    return {
      CONFIG,
      optionSelected: null,
      email: null,
      loading: false,
      successSendMail: false,
      selectedContact: "empty",
      selectedPosition: "empty",
      form: {
        school_email: null,
        school_id: null,
        school_uuid: null,
        school_name: null,
        school_contact: "empty",
        contact_type: null,
        position: null,
        message_title: null,
        message: null,
        user_id: null,
        profile_id: null,
        username: null,
        mail_from: null,
        phone: null,
        country: "chile",
      },
      error: {
        email: false,
        username: false,
        position: false,
        contact: false,
      },
    };
  },
  computed: {
    listRadioButtons() {
      const radioButtons = {
        parent: [
          this.$t("contact.interested_parent"),
          this.$t("contact.have_a_question"),
          this.$t("contact.other"),
        ],
        staff: [
          this.$t("contact.want_to_update"),
          this.$t("contact.need_help"),
          this.$t("contact.want_to_report"),
        ],
      };

      return radioButtons[this.isContactPlataformForm ? "staff" : "parent"];
    },
    listContacts() {
      const list = [];

      if (this.isContactPlataformForm) {
        const name = this.$t("contact.plataform_administrators");
        const email = "contact@consiliumbots.com";

        list.push({ name, email });
      } else {
        // eslint-disable-next-line
        for (const { email, contact_label } of this.contacts) {
          // eslint-disable-next-line
          const name = contact_label.contact_name;
          // eslint-disable-next-line camelcase
          const { id } = contact_label;
          const hasEmailContact = id < 5 && email;

          if (hasEmailContact) {
            list.push({ name, email });
          }
        }
      }

      return list;
    },
  },
  methods: {
    ...mapActions({
      sendMail: "contactForm/sendMail",
      errorSnackbar: "snackbar/error",
      successSnackbar: "snackbar/success",
      setCurrentStudentWithUUID: "authentication/setCurrentStudentWithUUID",
    }),
    sendContact() {
      if (!this.validForm()) {
        return;
      }
      // eslint-disable-next-line prefer-destructuring
      const form = this.form;
      this.loading = true;
      this.form.school_contact = this.selectedContact.name;
      this.form.school_uuid = String(this.schoolUuid);
      this.form.school_id = String(this.schoolId);
      this.form.school_name = this.campusName;
      this.form.contact_type = this.isContactPlataformForm ? "staff" : "parent";
      this.selectedContact = this.isContactPlataformForm
        ? "contact@consiliumbots.com"
        : this.selectedContact.email;
      this.form.school_email = this.selectedContact;
      // this.form.school_email = this.form.mail_from;

      this.sendMail({
        form,
        callback: () => {
          this.loading = false;
          this.successSendMail = true;
          this.$mixpanel.track("click_campus_contact_send", {
            distinct_id: this.setCurrentStudentWithUUID,
            env: CONFIG.environment,
            typeForm: this.form.contact_type,
          });
        },
        callbackError: () => {
          this.loading = false;
          this.errorSnackbar(this.$t("contact.snackbar.error"));
        },
      });
    },
    validForm() {
      let validateForm = true;

      if (this.form.username === null) {
        validateForm = false;
        this.error.username = true;
      } else {
        this.error.username = false;
      }

      if (!this.validEmail() || this.form.email === "") {
        validateForm = false;
        this.error.email = true;
      } else {
        this.error.email = false;
      }

      if (this.selectedContact === "empty" && !this.isContactPlataformForm) {
        validateForm = false;
        this.error.contact = true;
      } else {
        this.error.contact = false;
      }

      if (this.selectedPosition === "empty" && this.isContactPlataformForm) {
        validateForm = false;
        this.error.position = true;
      } else {
        this.error.position = false;
      }

      return validateForm;
    },
    validEmail() {
      // eslint-disable-next-line operator-linebreak
      const re =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(this.form.mail_from)) {
        return true;
      }
      this.errorRegister = "Email inválido";
      return false;
    },
  },
};
</script>
