<template>
  <div></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  async mounted() {
    const { params, query } = this.$route;

    await this.loginWithUUID({
      uuid: params.uuid,
      isFeedback: true,
    });

    this.showFeedback(query.feedback, params.uuid);
  },
  computed: {
    ...mapGetters({
      environment: "authentication/environment",
      legalGuardianUUID: "authentication/legalGuardianUUID",
    }),
  },
  methods: {
    ...mapActions({
      loginWithUUID: "authentication/doLoginWithUUID",
      cleanFilters: "institutions/cleanFilters",
    }),
    showFeedback(queryParams, uuid) {
      const route = queryParams === "application" ? "Feedback" : "LoginApp";
      this.$router.push({ name: route, params: { uuid } });
    },
    // async doLogin({ uuid }) {
    //   const payload = {
    //     uuid,
    //     callback: this.goToWelcome,
    //     hotjar: this.$hj,
    //   };
    //   this.loginWithUUID(payload);
    // },
    // // eslint-disable-next-line camelcase
    // goToWelcome({ register_complete }) {
    //   console.log("entry here, go welcome");
    //   const { legalGuardianUUID } = this;
    //   // this.$mixpanel.track('login-pilot', {
    //   //   distinct_id: legalGuardianUUID,
    //   //   env: environment,
    //   // });
    //   this.$mixpanel.identify(legalGuardianUUID);
    //   this.$mixpanel.people.set_once({
    //     $email: this.email,
    //   });
    //   // eslint-disable-next-line camelcase
    //   console.log("entry here we", register_complete);
    //   if (register_complete) {
    //     this.$router.push({ name: "Explorer" });
    //   } else {
    //     this.$router.push({ name: "Register Student" });
    //   }
    // },
  },
};
</script>

<style></style>
