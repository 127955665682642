<template>
  <div class="nationality_container">
    <div class="nationality_title">
      {{ $t("register.identification.nationality.title") }}
    </div>
    <div class="nationality_subtitle">
      {{ $t("register.identification.nationality.subtitle") }}
    </div>
    <v-container
      class="nationality_question_container"
      :style="mobile ? 'flex-direction: column;' : ''"
    >
      <div class="nationality_question_title_container">
        <div class="nationality_question_title required-field">
          {{ $t("register.identification.nationality.nationality") }}
        </div>
        <v-select
          class="custom-select custom-select nationality_question_input"
          :background-color="nationality !== null ? '#2D10B4' : '#FFFFFF'"
          :dark="nationality !== null ? true : false"
          outlined
          :items="nationalities"
          item-text="nationality_name"
          item-value="id"
          v-model="nationality"
        />
      </div>
      <div class="document_question_container">
        <div class="document_question_title required-field">
          {{ $t("register.identification.nationality.document_type") }}
        </div>
        <v-select
          :items="typeOfDocuments"
          class="custom-select custom-select document_question_input"
          :background-color="typeOfDocument !== null ? '#2D10B4' : '#FFFFFF'"
          :dark="typeOfDocument !== null ? true : false"
          outlined
          item-text="identification_type_name"
          item-value="id"
          v-model="typeOfDocument"
        />
      </div>
    </v-container>
    <div class="btn_container" style="margin-top: 0px !important">
      <v-btn icon @click="backMethod">
        <v-img src="@/assets/iconsPalmira/student_register/icons/back.svg" />
      </v-btn>
      <base-button v-if="isDisabled" class="btn-skip" @click="skipStep">
        {{ $t("register.identification.nationality.skip_btn") }}
      </base-button>
      <base-button v-else class="btn-continue" @click="continueMethod">
        {{ $t("register.identification.nationality.continue_btn") }}
      </base-button>
    </div>
    <v-dialog
      content-class="elevation-0"
      overlay-color="transparent"
      v-model="showSkipModal"
      max-width="370"
    >
      <warning-modal
        @skip="skipConfirmMethod"
        @complete="completeMethod"
        :title="$t('register.identification.nationality.warning_modal.title')"
        :text="$t('register.identification.nationality.warning_modal.text')"
        color="#FC010A"
        skipModal
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/explorer/base/BaseButton.vue";
import WarningModal from "../WarningModal.vue";

export default {
  name: "Nationality",
  components: {
    BaseButton,
    WarningModal,
  },
  data() {
    return {
      typeOfDocument: null,
      nationality: null,
      showSkipModal: false,
      firstUpdate: true,
    };
  },
  mounted() {
    const { id, nationality } = this.documentStore;
    if (id) {
      this.nationality = nationality.id;
      this.typeOfDocument = id;
    } else {
      this.firstUpdate = false;
    }
  },
  methods: {
    ...mapActions({
      setIdentificationStepFive: "newStudentRegister/setIdentificationStepFive",
    }),
    continueMethod() {
      const document = this.typeOfDocuments.find((elem) => elem.id === this.typeOfDocument);
      const data = {
        document,
      };
      this.setIdentificationStepFive(data);
      this.$emit("nextStep");
    },
    backMethod() {
      this.$emit("backStep");
    },
    skipStep() {
      this.showSkipModal = true;
    },
    skipConfirmMethod() {
      this.$emit("skipStep");
    },
    completeMethod() {
      this.showSkipModal = false;
    },
  },
  computed: {
    ...mapGetters({
      types: "options/documentTypes",
      documentStore: "newStudentRegister/document",
      nationalities: "options/nationalities",
    }),
    typeOfDocuments() {
      if (this.nationality === null) {
        return [];
      }
      const documents = this.types[this.nationality];
      if (this.nationality === 1) {
        return [documents[0]];
      }
      return documents;
    },
    isDisabled() {
      return this.typeOfDocument === null;
    },
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
  },
  watch: {
    nationality: {
      handler(newVal) {
        if (!this.firstUpdate) {
          if (newVal === 1) {
            this.typeOfDocument = this.typeOfDocuments[0].id;
          } else {
            this.typeOfDocument = null;
          }
        } else {
          this.firstUpdate = false;
        }
      },
    },
  },
};
</script>
