<template>
  <v-container class="map-modal-container">
    <v-row no-gutters class="map-modal" justify="center">
      <!-- BACKGROUND ICONS-->
      <div class="map-modal__icon1" />
      <div class="map-modal__icon2" />
      <div class="map-modal__icon3" />
      <div class="map-modal__icon4" />
      <div class="map-modal__icon5" />
      <div class="map-modal__icon6" />
      <div class="map-modal__div map-modal__div--level" id="data-form">
        <base-button
          v-if="!filter"
          class="map-modal__guest__btn map-modal__guest__btn--back"
          @click="back()"
        >
          <img
            :src="require('@/assets/iconsChile/login/arrow.svg')"
            class="school-filter__profile__div__icon mr-1"
          />
          VOLVER
        </base-button>
        <div class="map-modal__div map-modal__div--text3">
          {{ $t("explorer.map_modal.location") }}
        </div>
        <div v-if="!filter" class="map-modal__div map-modal__div--text2 mb-5">
          {{ $t("explorer.map_modal.location-question") }}
        </div>
        <div class="map-modal__div map-modal__div--text2 mb-5" v-else>
          Selecciona tu nueva ubación
        </div>
        <div class="custom-input--white" :class="calculateClassOfSelect('regionName')">
          <v-select
            hide-details
            class="registration-input registration-input--wide"
            :items="regionOptions"
            item-text="label"
            item-value="uuid"
            v-model="regionName"
            dense
            outlined
            :background-color="'#FFFFFF'"
            @change="changeCommuneOptions"
            @blur="$v.regionName.$touch()"
            :placeholder="filter ? getRegionName : $t('explorer.map_modal.region_placeholder')"
            disabled
          />
        </div>
        <div class="pl-2 mt-2">
          <p
            v-for="(error, index) in regionNameErrors"
            :key="index"
            class="text text--small-01 text--error"
          >
            {{ error }}
          </p>
        </div>
        <div class="fill-width" :class="calculateClassOfSelect('municipalityName')">
          <v-select
            hide-details
            class="registration-input registration-input--wide"
            :items="['Palmira']"
            item-text="label"
            item-value="uuid"
            v-model="municipalityName"
            dense
            outlined
            :background-color="'#FFFFFF'"
            :placeholder="filter ? getCommuneName : $t('explorer.map_modal.commune_placeholder')"
            @change="updateCommune"
            :disabled="true"
            @blur="$v.municipalityName.$touch()"
          />
        </div>
        <div class="fill-width mt-5" v-if="true">
          <textarea
            class="fill-width custom-textarea"
            :class="calculateClassOf('address')"
            type="textarea"
            v-model="address"
            :placeholder="$t('explorer.map_modal.address_placeholder')"
            @input="$v.address.$touch()"
            @change="$v.address.$touch()"
            @blur="$v.address.$touch()"
            :disabled="municipalityName.length === 0"
          />
        </div>
        <div class="d-inline-flex justify-center mt-5 fill-width">
          <base-button
            class="map-modal__guest__btn map-modal__guest__btn--level"
            :loading="loadingBtn"
            @click="sendData()"
          >
            {{ $t("student_register.data.start") }}
          </base-button>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import CONFIG from "@/config";
import BaseButton from "@/components/explorer/base/BaseButton.vue";

export default {
  name: "MapModalLevel",
  components: {
    BaseButton,
  },
  props: {
    filter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      region: "",
      regionId: "",
      regionName: "Valle Del Cauca",
      municipalityName: "Palmira",
      address: "",
      lat: null,
      lng: null,
      defaltLat: null,
      defaltLng: null,
      googleAddressComponents: [],
      cancelNextUpdate: false,
      loadingBtn: false,
    };
  },
  validations: {
    address: {},
    regionName: {
      required,
    },
    municipalityName: {
      required,
    },
  },
  watch: {
    address() {
      // Timer to delay until user stop to write 3 segs to search
      if (this.address !== "") {
        this.loadingBtn = true;
        const oldTimer = this.addressTriggerTimer;
        clearTimeout(oldTimer);
        const newTimer = setTimeout(this.search, 3000);
        this.addressTriggerTimer = newTimer;
      }
    },
  },
  computed: {
    ...mapGetters({
      regionOptions: "options/regionLabels",
      regions: "options/regions",
      municipalities: "options/municipalities",
      guestLevel: "authentication/guestLevel",
      guestGrades: "authentication/guestGrades",
      guestLocation: "authentication/guestLocation",
      municipalityOptions: "options/municipalityOptions",
    }),
    regionNameErrors() {
      const errors = [];
      if (!this.$v.regionName.$dirty) {
        return errors;
      }
      if (!this.$v.regionName.required) {
        errors.push(this.$t("explorer.map_modal.error.region.required"));
      }
      return errors;
    },
    municipalityNameErrors() {
      const errors = [];
      if (!this.$v.municipalityName.$dirty) {
        return errors;
      }
      if (!this.$v.municipalityName.required) {
        errors.push(this.$t("explorer.map_modal.error.commune.required"));
      }
      return errors;
    },
    getRegionName() {
      if (this.guestLocation.regionName) {
        return this.guestLocation.regionName;
      }
      return "";
    },
    getCommuneName() {
      if (this.guestLocation.communeName) {
        return this.guestLocation.communeName;
      }
      return "";
    },
    addressToSearchInGMaps() {
      if (!this.municipalityName || !this.regionName) {
        return null;
      }
      const regionLabel = this.regionName;
      const municiaplityLabel = this.municipalityName;
      return `${this.address}, ${municiaplityLabel}, ${regionLabel}`;
    },
  },
  methods: {
    ...mapActions({
      setGuestData: "authentication/setGuestData",
      setMunicipalityOptions: "options/setMunicipalityOptions",
    }),
    calculateClassOf(input) {
      if (!this.$v[input].$dirty) return "";
      return this.$v[input].$invalid
        ? "student-register__input--invalid"
        : "student-register__input--valid";
    },
    calculateClassOfSelect(input) {
      if (!this.$v[input].$dirty) return "";
      return this.$v[input].$invalid
        ? "student-register__select-container--invalid"
        : "student-register__select-container--valid";
    },
    changeCommuneOptions(newRegion) {
      const region = this.regions.find((r) => r.name === newRegion).id;
      this.region = region;
      this.regionName = region.name;
      this.regionId = region.id;
      const municipalityList = [];
      if (this.regionId) {
        this.municipalities.forEach((municipality) => {
          if (municipality.upper_location === this.regionId) {
            municipalityList.push(municipality.name);
          }
        });
      }
      this.setMunicipalityOptions(municipalityList);
    },
    updateCommune(newVal) {
      this.municipalities.forEach((municipality) => {
        if (newVal === municipality.name) {
          this.lat = municipality.default_coordinate.lat;
          this.lng = municipality.default_coordinate.lon;
        }
      });
    },
    search() {
      if (!this.addressToSearchInGMaps || this.cancelNextUpdate) {
        this.cancelNextUpdate = false;
        this.loadingBtn = false;
        return null;
      }
      if (this.address !== "") {
        return axios
          .get("https://maps.googleapis.com/maps/api/geocode/json?region=cl", {
            params: { address: this.addressToSearchInGMaps, key: CONFIG.gMapKey },
          })
          .then(async (response) => {
            const { results } = response.data;
            const resultsFiltered = results[0].formatted_address;
            if (resultsFiltered.length > 0) {
              const betterResult = results[0];
              this.lat = `${betterResult.geometry.location.lat}`;
              this.lng = `${betterResult.geometry.location.lng}`;
              this.cancelNextUpdate = true;
              this.$nextTick(() => {
                this.address = resultsFiltered;
              });
              this.googleAddressComponents = betterResult.address_components;
              return true;
            }
            return false;
          })
          .finally(() => {
            this.loadingBtn = false;
          });
      }
      this.loadingBtn = false;
      return true;
    },
    sendData() {
      this.municipalities.forEach((municipality) => {
        if (this.municipalityName === municipality.name) {
          this.lat = municipality.default_coordinate.lat;
          this.lng = municipality.default_coordinate.lon;
        }
      });
      const region = this.regions.find((r) => r.name === this.regionName);
      const location = {
        coordinate: {
          lat: this.lat,
          lng: this.lng,
        },
        regionId: region.id,
        municipalityName: this.municipalityName,
      };
      let stage;
      if (this.guestGrades[0] <= 14) {
        stage = 2;
      } else {
        stage = 3;
      }
      if (this.filter) {
        this.setGuestData({
          location,
          grades: this.guestGrades,
          stage: stage,
          level: this.guestLevel,
        });
        this.$emit("ready");
      } else {
        this.$emit("ready", location);
      }
    },
    back() {
      this.$emit("back", "location");
    },
  },
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #2d10b4;
}
</style>
