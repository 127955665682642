var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"feedback-map"},[(_vm.loading)?_c('div',{staticClass:"feedback-map__loading"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1):_vm._e(),(!_vm.loading)?_c('GmapMap',{staticClass:"map",attrs:{"id":"map","center":{ lat: _vm.markerStudent.lat, lng: _vm.markerStudent.lng },"zoom":14,"options":{
      disableDefaultUI: true,
      clickableIcons: false,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      scrollwheel: false,
      draggable: false,
      styles: _vm.stylesGoogleMap,
      gestureHandling: 'greedy',
    }}},[_c('div',[_c('gmap-custom-marker',{attrs:{"marker":{
          lat: _vm.markerStudent.lat !== null ? _vm.markerStudent.lat : _vm.CONFIG.defaultLocation.lat,
          lng: _vm.markerStudent.lng !== null ? _vm.markerStudent.lng : _vm.CONFIG.defaultLocation.lng,
        }}},[_c('img',{staticClass:"home-icon",attrs:{"src":require("@/assets/markers/home.svg")}})]),_c('GmapCircle',{attrs:{"center":_vm.studentHome(),"radius":2000,"visible":true,"options":_vm.mapCircle}}),_c('div',_vm._l((_vm.applications),function(institution,index){return _c('gmap-custom-marker',{key:index,attrs:{"marker":_vm.setApplicantCenter(institution)}},[_c('img',{staticStyle:{"z-index":"5000 !important"},attrs:{"src":_vm.setApplications(institution)}})])}),1),(_vm.admission.admission_status.id !== 1)?_c('div',_vm._l((_vm.recommendations),function(institution,index){return _c('gmap-custom-marker',{key:index,attrs:{"marker":_vm.setRecommendationCenter(institution)}},[_c('img',{attrs:{"src":_vm.setIconInstitution(institution)},on:{"click":function($event){return _vm.changeSelected(institution)}}})])}),1):_vm._e()],1)]):_vm._e(),(false)?_c('section',{staticClass:"w-full flex justify-center bg-thirdy py-8"},[_c('div',{staticClass:"w-full max-w-desktop flex flex-col px-10 text-white font-light"},[_c('div',{staticClass:"w-full flex items-center",class:{ 'feedback-map__examples': _vm.CONFIG.feedback === 'Palmira' }},[_vm._m(0),_vm._v("   "),_c('span',{directives:[{name:"t",rawName:"v-t",value:('feedback.map.neighborhood'),expression:"'feedback.map.neighborhood'"}],staticClass:"text-white text-xs ml-2"})]),_c('div',{staticClass:"w-full flex items-center mt-3",class:{
          'feedback-map__examples feedback-map__examples--institution':
            _vm.CONFIG.feedback === 'Palmira',
        }},[_vm._m(1),_vm._v("   "),_c('span',{directives:[{name:"t",rawName:"v-t",value:('feedback.map.institutions_in_two_km'),expression:"'feedback.map.institutions_in_two_km'"}],staticClass:"text-white text-xs ml-1"})]),_c('div',{staticClass:"w-full flex items-center mt-3",class:{
          'feedback-map__examples feedback-map__examples--heart': _vm.CONFIG.feedback === 'Palmira',
        }},[_vm._m(2),_vm._v("   "),_c('span',{directives:[{name:"t",rawName:"v-t",value:('feedback.map.institutions_applications'),expression:"'feedback.map.institutions_applications'"}],staticClass:"text-white text-xs ml-2"})]),_c('div',{staticClass:"w-full flex items-center mt-3",class:{
          'feedback-map__examples feedback-map__examples--recommended':
            _vm.CONFIG.feedback === 'Palmira',
        }},[_vm._m(3),_vm._v("   "),_c('span',{directives:[{name:"t",rawName:"v-t",value:('feedback.map.performance_and_copay_institutions'),expression:"'feedback.map.performance_and_copay_institutions'"}],staticClass:"text-white text-xs -ml-2 -mt-2",staticStyle:{"text-align":"left"}})])])]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('picture',[_c('img',{attrs:{"src":require("@/assets/svg/marker-student.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('picture',[_c('img',{attrs:{"src":require("@/assets/svg/marker-gray.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('picture',[_c('img',{attrs:{"src":require("@/assets/svg/marker-like.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('picture',{staticClass:"mr-1",staticStyle:{"width":"80px"}},[_c('img',{attrs:{"src":require("@/assets/svg/marker-recommendation.svg")}})])}]

export { render, staticRenderFns }