<template>
  <div style="width: 100%; height: 100%"></div>
</template>
<script>
import CONFIG from "../../../config";
import mapStyle from "../../../assets/mapStyle.json";

export default {
  name: "MapPlaceholder",
  data() {
    return {
      zoom: 14,
      google: {},
      mapStyle,
      center: CONFIG.defaultLocation,
    };
  },
};
</script>
