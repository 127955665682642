<template>
  <div class="basic-information_container">
    <div class="basic-information_title">
      {{ $t("register.identification.basic_information.title") }}
    </div>
    <div class="basic-information_subtitle">
      {{ $t("register.identification.basic_information.subtitle") }}
    </div>
    <v-container class="names_questions_container" :style="mobile ? 'flex-direction: column;' : ''">
      <div class="first-name_container">
        <div class="first-name_title_container">
          <p class="required-field first-name_title">
            {{ $t("register.identification.basic_information.first_name") }}
          </p>
        </div>
        <input
          v-model="firstName"
          type="text"
          class="register__content__input__div__input first-name_input"
          :class="{ 'register__content__input__div__input--selected': firstName.length > 0 }"
        />
      </div>
      <div class="second-name_container" :style="mobile ? 'margin-top: 10px;' : ''">
        <div class="second-name_title_container">
          <p class="second-name_title">
            {{ $t("register.identification.basic_information.second_name") }}
          </p>
        </div>
        <input
          v-model="secondName"
          type="text"
          class="register__content__input__div__input second-name_input"
          :class="{ 'register__content__input__div__input--selected': secondName.length > 0 }"
        />
      </div>
    </v-container>
    <v-container
      class="lastnames_questions_container"
      :style="mobile ? 'flex-direction: column;' : ''"
    >
      <div class="first-lastname_container">
        <div class="first-lastname_title_container">
          <p class="required-field first-lastname_title">
            {{ $t("register.identification.basic_information.father_lastname") }}
          </p>
        </div>
        <input
          v-model="fatherLastName"
          type="text"
          class="register__content__input__div__input first-lastname_input"
          :class="{ 'register__content__input__div__input--selected': fatherLastName.length > 0 }"
        />
      </div>
      <div class="second-lastname_container" :style="mobile ? 'margin-top: 10px;' : ''">
        <div class="second-lastname_title_container">
          <p class="required-field second-lastname_title">
            {{ $t("register.identification.basic_information.mother_lastname") }}
          </p>
        </div>
        <input
          v-model="motherLastName"
          type="text"
          class="register__content__input__div__input second-lastname_input"
          :class="{ 'register__content__input__div__input--selected': motherLastName.length > 0 }"
        />
      </div>
    </v-container>
    <v-container class="birth_gender_container" :style="mobile ? 'flex-direction: column;' : ''">
      <div class="gender_container">
        <div class="gender_title_container">
          <p class="required-field gender_title">
            {{ $t("register.identification.basic_information.gender") }}
          </p>
        </div>
        <div class="gender_opt_container">
          <div
            v-for="opt in genders"
            :key="opt.id"
            class="gender_opt"
            :style="gender === opt.id ? 'background: #2D10B4;' : 'background: white;'"
            @click="changeGender(opt.id)"
          >
            <p :style="gender === opt.id ? 'color: white;' : 'color: #2D10B4;'">
              {{ opt.gender_label }}
            </p>
          </div>
        </div>
      </div>
      <div class="birthday_container" :style="mobile ? 'margin-top: 10px;' : ''">
        <div class="birthday_title_container">
          <p class="required-field birthday_title">
            {{ $t("register.identification.basic_information.birthday") }}
          </p>
        </div>
        <div class="date_container">
          <v-select
            class="custom-select custom-select day_input"
            outlined
            :items="days"
            item-text="day"
            v-model="day"
            :background-color="day !== null ? '#2D10B4' : '#FFFFFF'"
            :dark="day !== null ? true : false"
          />
          <v-select
            class="custom-select custom-select month_input"
            :items="months"
            outlined
            item-text="month"
            v-model="month"
            :background-color="month !== null ? '#2D10B4' : '#FFFFFF'"
            :dark="month !== null ? true : false"
          />
          <v-select
            class="custom-select custom-select year_input"
            outlined
            :items="years"
            item-text="year"
            v-model="year"
            :background-color="year !== null ? '#2D10B4' : '#FFFFFF'"
            :dark="year !== null ? true : false"
          />
        </div>
      </div>
    </v-container>
    <div v-if="showBirthplace" class="birthplace_title_container">
      <p class="required-field birthplace_title">
        {{ $t("register.identification.basic_information.birthplace") }}
      </p>
    </div>
    <v-container
      v-if="showBirthplace"
      class="birthplace_container"
      :style="mobile ? 'flex-direction: column;' : ''"
    >
      <v-select
        class="custom-select custom-select department_input"
        :style="mobile ? 'padding: 0px !important;' : ''"
        :background-color="department !== null ? '#2D10B4' : '#FFFFFF'"
        :dark="department !== null ? true : false"
        :items="departments"
        outlined
        item-text="name"
        item-value="id"
        v-model="department"
      />
      <v-select
        class="custom-select custom-select muni_input"
        :style="mobile ? 'padding: 0px !important;' : ''"
        :background-color="muni !== null ? '#2D10B4' : '#FFFFFF'"
        :dark="muni !== null ? true : false"
        outlined
        :items="muniList"
        item-text="name"
        item-value="id"
        v-model="muni"
      />
    </v-container>
    <div class="btn_container">
      <v-btn icon @click="backMethod">
        <v-img src="@/assets/iconsPalmira/student_register/icons/back.svg" />
      </v-btn>
      <base-button v-if="isDisabled" class="btn-skip" @click="skipStep">
        {{ $t("register.identification.basic_information.skip_btn") }}
      </base-button>
      <base-button v-else class="btn-continue" @click="continueMethod">
        {{ $t("register.identification.basic_information.continue_btn") }}
      </base-button>
    </div>
    <v-dialog
      content-class="elevation-0"
      overlay-color="transparent"
      v-model="showSkipModal"
      max-width="370"
    >
      <warning-modal
        @skip="skipConfirmMethod"
        @complete="completeMethod"
        :title="$t('register.identification.basic_information.warning_modal.title')"
        :text="$t('register.identification.basic_information.warning_modal.text')"
        color="#FC010A"
        skipModal
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import services from "@/services";
import BaseButton from "@/components/explorer/base/BaseButton.vue";
import WarningModal from "../WarningModal.vue";

export default {
  name: "BasicInformation",
  components: {
    BaseButton,
    WarningModal,
  },
  data() {
    return {
      day: null,
      month: null,
      year: null,
      firstName: "",
      secondName: "",
      fatherLastName: "",
      motherLastName: "",
      gender: null,
      department: null,
      muni: null,
      firstUpdate: true,
      showSkipModal: false,
      modalText: "",
      modalColor: "",
      modalTitle: "",
    };
  },
  mounted() {
    this.firstName = this.firstNameStore;
    this.secondName = this.secondNameStore;
    this.fatherLastName = this.fatherLastNameStore;
    this.motherLastName = this.motherLastNameStore;
    this.gender = this.genderStore;
    this.day = this.dayStore;
    this.month = this.monthStore;
    this.year = this.yearStore;
    const { department, muni } = this.birthplaceStore;
    if (department) {
      this.department = department;
      this.muni = muni;
    } else {
      this.firstUpdate = false;
    }
  },
  methods: {
    ...mapActions({
      setIdentificationStepEight: "newStudentRegister/setIdentificationStepEight",
    }),
    async continueMethod() {
      const address = {
        department: this.department,
        muni: this.muni,
      };
      let valid = false;
      await services.authenticationService
        .validateDate(this.year, this.month, this.day)
        .then((response) => {
          valid = response.data.valid;
        })
        .catch((error) => {
          console.error(error);
          valid = false;
        });
      if (!valid) {
        this.modalTitle = this.$t("register.identification.basic_information.modal.title");
        this.modalColor = "#FC010A";
        this.showModal = true;
      } else {
        const data = {
          day: this.day,
          month: this.month,
          year: this.year,
          firstName: this.firstName,
          secondName: this.secondName,
          fatherLastName: this.fatherLastName,
          motherLastName: this.motherLastName,
          gender: this.gender,
          address,
        };
        this.setIdentificationStepEight(data);
        this.$emit("nextStep");
      }
    },
    changeGender(value) {
      this.gender = value;
    },
    backMethod() {
      this.$emit("backStep");
    },
    skipStep() {
      this.showSkipModal = true;
    },
    skipConfirmMethod() {
      this.$emit("skipStep");
    },
    completeMethod() {
      this.showSkipModal = false;
    },
    range(start, end, reverse) {
      const length = end - start + 1;
      if (reverse) {
        return Array.from({ length }, (_, i) => end - i);
      }
      return Array.from({ length }, (_, i) => start + i);
    },
  },
  computed: {
    ...mapGetters({
      departments: "options/regions",
      genders: "options/genders",
      firstNameStore: "newStudentRegister/firstName",
      secondNameStore: "newStudentRegister/secondName",
      fatherLastNameStore: "newStudentRegister/fatherLastName",
      motherLastNameStore: "newStudentRegister/motherLastName",
      genderStore: "newStudentRegister/gender",
      birthplaceStore: "newStudentRegister/birthplace",
      dayStore: "newStudentRegister/day",
      monthStore: "newStudentRegister/month",
      yearStore: "newStudentRegister/year",
      document: "newStudentRegister/document",
    }),
    muniList() {
      if (!this.department) {
        return [];
      }
      return this.departments.find((dep) => dep.id === this.department).commune_options;
    },
    nameReady() {
      return this.firstName !== "" && this.fatherLastName !== "" && this.motherLastName !== "";
    },
    birthdayReady() {
      return this.day !== null && this.month !== null && this.year !== null;
    },
    genderReady() {
      return this.gender !== null;
    },
    locationReady() {
      if (this.showBirthplace) {
        return this.department !== null && this.muni !== null;
      }
      return true;
    },
    isDisabled() {
      return !this.nameReady || !this.birthdayReady || !this.genderReady || !this.locationReady;
    },
    years() {
      const year = new Date().getFullYear();
      return this.range(1950, year, true);
    },
    days() {
      return this.range(1, 31, false);
    },
    months() {
      return this.range(1, 12, false);
    },
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
    showBirthplace() {
      if ("nationality" in this.document) {
        return this.document.nationality.id === 1;
      }
      return false;
    },
  },
  watch: {
    department: {
      handler() {
        if (!this.firstUpdate) {
          this.muni = null;
        } else {
          this.firstUpdate = false;
        }
      },
    },
  },
};
</script>
