<template>
  <div class="confirm-applicant confirm-applicant--add">
    <div>
      <div class="confirm-applicant__title">
        <h1 v-t="'student_register.title'" class="confirm-applicant__title__text" />
        <img src="@/assets/iconsNewHaven/user_register/icons/face.svg" />
      </div>
      <div class="confirm-applicant__student">
        <div class="d-flex justify-space-between mb-5">
          <div class="confirm-applicant__student__input-div">
            <div class="d-flex">
              <h6
                class="confirm-applicant__student__input-div__title"
                v-t="'user_register.confirm_applicant.first_name'"
              />
              <p class="pt-1 ml-1" style="color: #f90e1f" v-t="'*'" />
            </div>
            <div v-if="showErrors">
              <p
                v-for="(error, index) in firstNameErrors"
                :key="index"
                class="text text--small-01 text--error"
              >
                {{ error }}
              </p>
            </div>
            <input
              v-model="firstName"
              class="confirm-applicant__student__input-div__input"
              type="text"
              :placeholder="$t('user_register.register_data.form.name')"
            />
          </div>
          <div class="confirm-applicant__student__input-div">
            <div class="d-flex align-center">
              <h6
                v-t="'user_register.confirm_applicant.last_name'"
                class="confirm-applicant__student__input-div__title"
              />
              <p class="pt-1 ml-1" style="color: #f90e1f" v-t="'*'" />
            </div>
            <div v-if="showErrors">
              <p
                v-for="(error, index) in lastNameErrors"
                :key="index"
                class="text text--small-01 text--error"
              >
                {{ error }}
              </p>
            </div>
            <input
              v-model="lastName"
              class="confirm-applicant__student__input-div__input"
              type="text"
              :placeholder="$t('user_register.confirm_applicant.last_name')"
            />
          </div>
        </div>
        <div v-if="showErrors">
          <p
            v-for="(error, index) in gradeErrors"
            :key="index"
            class="text text--small-01 text--error"
          >
            {{ error }}
          </p>
        </div>

        <register-grade
          :confirmStudent="false"
          :userRegister="true"
          :userRegisterInDashboard="true"
          :isCurrentGrade="true"
          @selected-grade="setGrades"
          :isInProfile="isInProfile"
        />

        <register-grade
          :confirmStudent="true"
          :userRegister="true"
          :userRegisterInDashboard="true"
          @selected-grade="setGradeInterest"
          :isInProfile="isInProfile"
          :gradesOfInterest="true"
        />
        <p
          class="confirm-applicant__current-institution mt-3 mb-2"
          v-t="'user_register.register_student.current_institution'"
        />
        <v-autocomplete
          class="confirm-applicant__student__select mb-5 mt-2"
          hide-details
          :items="municipalities"
          v-model="municipalityName"
          item-text="name"
          item-value="name"
          placeholder="Selecciona la comuna"
          solo
          dark
          height="31"
          :no-data-text="$t('errors.no-data')"
          background-color="#2b5bff"
          :menu-props="{ bottom: true, offsetY: true }"
        >
        </v-autocomplete>
        <v-autocomplete
          :disabled="schoolList.length === 0"
          class="confirm-applicant__student__select"
          hide-details
          :items="schoolList"
          v-model="schoolId"
          item-text="campus_name"
          item-value="id"
          placeholder="Selecciona tu establecimiento actual"
          solo
          dark
          height="31"
          :no-data-text="$t('errors.no-data')"
          background-color="#2b5bff"
          :menu-props="{ bottom: true, offsetY: true }"
        >
        </v-autocomplete>
        <div class="confirm-applicant__student__confirm d-flex">
          <img
            src="@/assets/iconsNewHaven/user_register/icons/blue-back.svg"
            style="cursor: pointer"
            class="mr-2"
            @click="closeModal"
          />
          <base-button
            class="btn-continue"
            @click="addStudent"
            :class="{ 'btn-continue--active': isFormCompleted }"
          >
            {{ $t("user_register.register_code.btn") }}
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import BaseButton from "@/components/explorer/base/BaseButton.vue";
import RegisterGrade from "./Grade.vue";
import elasticSearchsService from "../../services/elasticSearchs.services";

export default {
  name: "AddStudent",
  components: {
    BaseButton,
    RegisterGrade,
  },
  data() {
    return {
      elasticSearchsService,
      firstName: null,
      lastName: null,
      grade: null,
      gradeInterest: null,
      schoolId: null,
      showGrades: false,
      showErrors: false,
      schoolId: null,
      selectedStage: null,
      municipalityName: null,
      schoolList: [],
    };
  },
  props: {
    isInProfile: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    grade: {
      required,
    },
    gradeInterest: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      students: "authentication/students",
      legalGuardianUUID: "authentication/legalGuardianUUID",
      loading: "newStudentRegister/loading",
      currentStudent: "authentication/currentStudent",
      municipalities: "options/municipalities",
    }),
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.firstName) {
        errors.push(this.$t("student_register.data.general_error"));
      }
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.lastName) {
        errors.push(this.$t("student_register.data.general_error"));
      }
      return errors;
    },
    gradeErrors() {
      const errors = [];
      if (!this.grade) {
        errors.push(this.$t("student_register.data.general_error"));
      }
      return errors;
    },
    isFormCompleted() {
      if (this.lastName && this.grade && this.firstName) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      error: "snackbar/error",
      success: "snackbar/success",
      setStudents: "newStudentRegister/setStudents",
      getStudents: "authentication/getStudents",
      setStateGrades: "userRegister/setGrades",
    }),
    async searchSchools() {
      const filter = [
        {
          fieldname: "commune",
          fieldvalue: this.municipalityName,
        },
      ];
      const from = 0;
      await this.elasticSearchsService.elasticSchoolList(from, filter).then((response) => {
        this.schoolList = response.data.results;
      });
    },
    backMethod() {
      this.$emit("backStep");
    },
    changeConfirm() {
      this.$emit("setStudents", this.studentsForm);
    },
    setGrades(gradeIds, stage) {
      this.selectedStage = [stage];
      this.grade = [gradeIds[0]];
    },
    setGradeInterest(gradeIds) {
      this.gradeInterest = gradeIds;
    },
    async addStudent() {
      if (!this.firstName || !this.lastName || !this.grade || !this.gradeInterest) {
        this.showErrors = true;
      } else {
        await this.setStudents([
          {
            firstName: this.firstName,
            lastName: this.lastName,
            currentSchool: this.schoolId,
            gradeId: this.grade[0],
            gradeInterest: this.gradeInterest,
            stagesInteres: this.selectedStage[0],
          },
          true,
        ])
          .then(() => {
            this.$emit("closeModal");
            this.getStudents({});
            this.success(this.$t("student_register.data.student.success"));
            // eslint-disable-next-line no-unused-expressions
            (this.firstName = null),
              (this.lastName = null),
              (this.grade = null),
              (this.setSchool = null),
              (this.showGrades = false),
              (this.showErrors = false),
              (this.schoolId = null);
          })
          .catch((error) => {
            this.error(this.$getError(error).reason);
          });
      }
    },
    closeModal() {
      this.$emit("closeModal");
      // eslint-disable-next-line no-unused-expressions
      (this.firstName = null),
        (this.lastName = null),
        (this.grade = null),
        (this.gradeInterest = null),
        (this.setSchool = null),
        (this.showGrades = false),
        (this.showErrors = false),
        (this.schoolId = null);
    },
  },
  watch: {
    municipalityName: {
      handler() {
        this.searchSchools();
      },
    },
  },
};
</script>
<style>
.v-application .primary--text {
  font-size: 10px;
}
.theme--light.v-list {
  background: #eef5ff;
  color: #2b5bff;
  font-size: 10px;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #1976d2;
}
</style>
