<template>
  <v-app class="full-width bg-white">
    <LoadingPage v-if="loadingFeedback" />
    <FeedbackWelcome v-else class="feedback" />
  </v-app>
</template>

<script>
import LoadingPage from "@/components/feedback/LoadingPage.vue";
import FeedbackWelcome from "@/components/feedback/FeedbackWelcome.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FeedbackApplication",
  components: {
    LoadingPage,
    FeedbackWelcome,
  },
  created() {
    document.title = "Admisión Escolar 2023";
  },
  async mounted() {
    const { params } = this.$route;
    // const noDataFeedback = JSON.stringify(this.feedbackApplication) === "{}";
    await this.retrieveFeedback(params.uuid);
    // if (noDataFeedback) {
    // }
  },
  computed: {
    ...mapGetters({
      loadingFeedback: "feedback/loadingFeedback",
      feedbackApplication: "feedback/feedbackApplication",
    }),
  },
  methods: {
    ...mapActions({
      retrieveFeedback: "feedback/retriveFeedbackApplications",
    }),
  },
};
</script>