var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-content",staticStyle:{"width":"100%","max-width":"100%"}},[_c('GmapMap',{ref:"dashboardMapRef",class:_vm.$vuetify.breakpoint.mobile ? 'vue-map-container-mobile' : 'vue-map-container',staticStyle:{"width":"100%","height":"100%"},attrs:{"center":_vm.homeLocation ? _vm.homeLocation : _vm.CONFIG.defaultLocation,"zoom":_vm.zoom,"options":{
      disableDefaultUI: true,
      clickableIcons: false,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      styles: _vm.mapStyle,
      gestureHandling: 'greedy',
    }}},[(_vm.allFavoriteSchools.length > 0)?_c('div',_vm._l((_vm.allFavoriteSchools),function(school,i){return _c('gmap-custom-marker',{key:i,attrs:{"id":("id-markermap-" + (school.uuid)),"marker":{
          lat: school.location.latitud,
          lng: school.location.longitud,
        },"alignment":"top"}},[_c('img',{attrs:{"width":"20px","src":require("@/assets/svg/save-institution.svg")}})])}),1):_vm._e(),_c('gmap-custom-marker',{attrs:{"marker":_vm.homeLocation ? _vm.homeLocation : _vm.CONFIG.defaultLocation,"alignment":"top"}},[_c('img',{attrs:{"src":require("@/assets/dashboard/icons/home.svg")}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }