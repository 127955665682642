var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-width mt-5"},[_c('div',{staticClass:"simulation-result__section pb-5",class:{
      'simulation-result__section--desktop ': !_vm.mobile,
      'ml-1': _vm.mobile,
    }},[_c('v-row',{class:{ 'fill-width': !_vm.mobile },attrs:{"no-gutters":"","justify":_vm.mobile ? 'center' : 'start'}},[_c('div',{staticClass:"simulation-result__simulate-title text--light",class:{ 'simulation-result__simulate-btn--desktop': !_vm.mobile }},[_vm._v(" "+_vm._s(_vm.$t("simulation.results.btn"))+" "+_vm._s(_vm.simulation.year)+" ")])]),_c('div',{class:{
        'd-inline-flex align-start justify-space-between': !_vm.mobile,
        'pr-10': !_vm.mobile,
      }},[_c('div',{class:{
          'simulation-result__chart-section--desktop pa-3 pt-4': !_vm.mobile,
        }},[(_vm.simulation && _vm.simulation.portfolio)?_c('simulation-chart',{attrs:{"sortedChartData":_vm.sortedData,"unmatched":_vm.simulation.unmatched,"colors":_vm.colors}}):_vm._e()],1),(Object.keys(_vm.simulation).includes('unmatched'))?_c('div',{staticClass:"simulation-result__probability mt-2 d-flex flex-row px-10",class:{
          'simulation-result__probability--low': _vm.formatRatex(1 - _vm.simulation.unmatched) < 30,
          'simulation-result__probability--desktop': !_vm.mobile,
        }},[(_vm.formatRatex(1 - _vm.simulation.unmatched) < 30)?_c('v-icon',{staticClass:"mb-2",attrs:{"color":"white","size":"15"}},[_vm._v(" mdi-alert ")]):_vm._e(),_c('p',{staticClass:"text text--small-01 text-center d-inline",class:{
            'text--white': _vm.formatRatex(1 - _vm.simulation.unmatched) < 30,
          },staticStyle:{"max-width":"200px"},domProps:{"innerHTML":_vm._s(_vm.getProbability(_vm.simulation.year, _vm.simulation.unmatched))}})],1):_c('div',{staticClass:"pb-1"},[_c('p',{staticClass:"text text--small-01 text--center"},[_vm._v(" "+_vm._s(_vm.$t("simulation.results.no-data"))+" ")])])])],1),(Object.keys(_vm.simulation).includes('unmatched'))?_c('div',{staticClass:"fill-width mb-10",class:{
      'desktop-expansion-panel': !_vm.mobile,
      'ml-1 mobile-expansion-panel': _vm.mobile,
    }},[_c('v-expansion-panels',{staticClass:"fill-width",attrs:{"accordion":"","flat":""},model:{value:(_vm.showingDetail),callback:function ($$v) {_vm.showingDetail=$$v},expression:"showingDetail"}},[_c('v-expansion-panel',{staticClass:"fill-width"},[_c('v-expansion-panel-header',{staticClass:"fill-width anual-header bg-gray",class:{ 'custom-border': _vm.showingDetail },on:{"click":_vm.trackingSimulateInfo},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"fill-width d-flex align-center pb-3"},[(_vm.showingDetail === 0)?_c('p',{staticClass:"text--light text--white",class:{ 'text--small-01': _vm.mobile }},[_vm._v(" "+_vm._s(_vm.$t("simulation.results.detail-btn.hide"))+"   ")]):_c('p',{staticClass:"text--light text--white",class:{ 'text--small-01': _vm.mobile }},[_vm._v(" "+_vm._s(_vm.$t("simulation.results.detail-btn.show"))+"   ")]),(_vm.showingDetail !== 0)?_c('img',{staticClass:"school-card__info__icon",attrs:{"src":require("@/assets/iconsChile/more.svg")}}):_c('img',{staticClass:"campus-detail__section__header__div__icon__close",attrs:{"width":"20","src":require("@/assets/icons/close.svg")}})])]},proxy:true}],null,false,3179543067)}),_c('v-expansion-panel-content',{class:{ 'pa-6 pl-10': !_vm.mobile },style:(("width: " + (_vm.mobile ? '90' : '100') + "%; background-color: #fbf9f9"))},_vm._l((_vm.sortedData),function(element){return _c('simulation-detail',{key:element.programUUID,staticClass:"mb-3",attrs:{"year":_vm.simulation.year,"detailData":element,"datacollection":_vm.getDataCollection(element.rank)}})}),1)],1)],1)],1):_c('div',{staticClass:"pb-4"},[_c('hr')])])}
var staticRenderFns = []

export { render, staticRenderFns }