<template>
  <div class="maintenance">
    <div class="maintenance_main">
      <h1 class="maintenance_main__title" v-t="'maintenance.title'" />
      <h1 class="maintenance_main__title" v-t="'maintenance.title2'" />
      <p class="maintenance_main__text" v-t="'maintenance.subTitle'" />
      <p class="maintenance_main__text" v-t="'maintenance.subTitle2'" />
    </div>
    <img
      class="top-0 absolute mb-7 ml-1"
      style="left: 171px;"
      contain
      width="120px"
      :src="require('@/assets/iconsChile/header/logo.svg')"
    />
    <img
      class="top-10 absolute mb-7 ml-1"
      style="left: 171px;"
      src="@/assets/iconsChile/header/mime-side-logo-white.svg"
      width="150px"
      alt="ConsiliumBots"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'Maintenance',
  props: {},
  data() {
    return {};
  },
  created() {
    this.resetStores();
  },
  methods: {
    ...mapActions({
      resetStores: 'authentication/resetStores',
    }),
  },
};
</script>
