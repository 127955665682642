<template>
  <div class="feedback-card">
    <div class="bg-white shadow-card rounded-md mt-4 w-full">
      <!-- header -->
      <div class="w-full flex py-2 px-3 border-b-2 border-light">
        <div class="w-4/5 flex items-center text-sm text-gray">
          {{ currentSchool.campus_name }}
        </div>
        <picture class="w-1/5 flex items-center justify-end">
          <img v-if="currentSchool.multimedia_options.has_tour" src="@/assets/svg/360.svg" />
          <img
            v-if="currentSchool.multimedia_options.has_drone_flight"
            src="@/assets/svg/drone.svg"
            class="ml-1"
          />
        </picture>
      </div>
      <!-- title table -->
      <div class="w-full flex h-8 text-gray text-xss">
        <label
          class="w-1/4 flex justify-center items-center border-r border-white"
          v-t="'feedback.recommendation.copay'"
        />
        <label
          class="w-1/4 bg-light flex justify-center items-center border-r border-white"
          v-t="'feedback.recommendation.performance'"
        />
        <label
          class="w-1/4 flex justify-center items-center border-r border-white"
          v-t="'feedback.recommendation.distance'"
        />
        <label
          v-if="false"
          class="w-1/4 bg-light flex justify-center items-center"
          v-t="'feedback.recommendation.ratex'"
        />
      </div>
      <!-- categories -->
      <div class="w-full flex" style="height: 74px">
        <div
          class="w-1/4 flex flex-col justify-center border-r border-white"
          :class="classPrice(currentSchool.payment)"
        >
          <picture
            v-if="currentSchool.payment !== null || currentSchool.payment !== undefined"
            class="w-full flex justify-center items-center"
          >
            <img :src="iconPrice(currentSchool.payment)" />
          </picture>
          <label
            class="text-xss text-white text-center font-normal mb-2"
            :class="{ 'text-gray': currentSchool.payment === 'Sin Información' }"
            >{{ currentSchool.payment }}</label
          >
        </div>
        <div
          class="w-1/4 flex flex-col justify-center border-r border-white"
          :class="classPerformance(currentSchool.performance[0].qualitycateglevel.id)"
        >
          <picture
            v-if="
              currentSchool.performance[0] !== null || currentSchool.performance[0] !== undefined
            "
            class="w-full flex justify-center items-center"
          >
            <img :src="iconPerformance(currentSchool.performance[0].qualitycateglevel.id)" />
          </picture>
          <label
            class="text-xss text-white text-center font-normal mt-1 mb-2"
            :class="{
              'text-gray':
                currentSchool.performance[0] === null || currentSchool.performance[0] === undefined,
            }"
            >{{ textPerformance(currentSchool.performance[0].qualitycateglevel.id) }}</label
          >
        </div>
        <div
          v-if="true"
          class="w-1/4 flex flex-col justify-center border-white"
          :class="{
            'bg-aqua': shortDistance(currentSchool),
            'bg-dark-blue': !shortDistance(currentSchool),
          }"
        >
          <picture class="w-full flex justify-center items-center">
            <img src="@/assets/svg/distance.svg" v-if="shortDistance(currentSchool)" />
            <img src="@/assets/svg/distance-middle.svg" v-else />
          </picture>
          <label class="text-xss text-white text-center font-normal mb-2">
            {{ currentSchoolDistance + " Kms" }}
          </label>
        </div>
        <!-- color con ratex
            'bg-aqua': +ratex === 1,
            'bg-dark-blue': +ratex === 2,
            'bg-dark-purple': +ratex === 3,
            'bg-purple': +ratex === 4,

            color text
            'text-gray': ratex === null || ratex === undefined,
            'text-white': ratex > 0,
        -->
        <div v-if="false" class="w-1/4 flex bg-gray-light flex-col justify-center">
          <picture v-if="false" class="flex flex-col justify-center items-center">
            <img :src="iconRatex(ratex)" />
            <span
              class="year"
              :class="{
                'bg-aqua': ratex === 1,
                'bg-dark-blue': ratex === 2,
                'bg-dark-purple': ratex === 3,
                'bg-purple': ratex === 4,
              }"
              >SAE 2020</span
            >
          </picture>
          <label class="text-xss text-gray text-center font-normal mt-1">
            {{ "SIN INFORMACIÓN" }}</label
          >
        </div>
      </div>
      <div class="feedback-recommendation__profile">
        <!-- gif -->
        <div>
          <picture
            class="w-full flex justify-center mb-1"
            v-if="
              currentSchool.multimedia_options.has_tour ||
              currentSchool.multimedia_options.has_drone_flight
            "
          >
            <img :src="setGif()" />
          </picture>
        </div>
        <button
          type="button"
          @click="openProfile(currentSchool)"
          class="bg-primary w-full h-full text-white text-xss rounded-bl-md rounded-br-md"
          :class="{
            'feedback-recommendation__profile__btn': CONFIG.feedback === 'Palmira',
            'feedback-card__profile__btn': CONFIG.feedbackType === 'Results',
          }"
          v-t="'feedback.generic.view_digital_profile'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import utils from "@/utils/";
import CONFIG from "../../config";

export default {
  name: "schoolCards",
  props: {
    studentLocation: {
      type: Object,
      default: Object,
    },
    studentId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      CONFIG,
      currentSchoolDistance: null,
    };
  },
  computed: {
    ...mapGetters({
      currentSchool: "institutions/currentSchoolLite",
      legalGuardianUuid: "authentication/legalGuardianUUID",
    }),
    classPrice() {
      return (price) => ({
        "bg-aqua": price === "Gratuito",
        "bg-dark-blue": price === "$1.000 a $10.000" || price === "$1.000 a $50.000",
        "bg-dark-purple": price === "$50.001 a $100.000" || price === "10.001 a $100.000",
        "bg-purple": price === "Más de $100.000",
        "bg-gray-light": price === undefined || price === null || price === "Sin Información",
      });
    },
    classPerformance() {
      return (performance) => ({
        "bg-aqua": performance === 1,
        "bg-dark-blue": performance === 2,
        "bg-dark-purple": performance === 3,
        "bg-purple": performance === 4,
        "bg-gray-light": performance === null || performance === undefined,
      });
    },
    iconPrice() {
      return (price) => {
        // eslint-disable-next-line global-require
        if (price === "Gratuito") return require("@/assets/svg/copay-free.svg");
        // eslint-disable-next-line global-require
        if (price === "$1.000 a $10.000" || price === "$1.000 a $50.000")
          return require("@/assets/svg/copay-low.svg");
        if (price === "$50.001 a $100.000" || price === "10.001 a $100.000")
          return require("@/assets/svg/copay-middle.svg"); // eslint-disable-line global-require
        // eslint-disable-next-line global-require
        if (price === "Más de $100.000") return require("@/assets/svg/copay-high.svg");
        return "";
      };
    },
    iconPerformance() {
      return (performance) => {
        // eslint-disable-next-line global-require
        if (performance === 1) return require("@/assets/svg/performance-high.svg");
        // eslint-disable-next-line global-require
        if (performance === 2) return require("@/assets/svg/performance-middle.svg");
        // eslint-disable-next-line global-require
        if (performance === 3) return require("@/assets/svg/performance-low.svg");
        // eslint-disable-next-line global-require
        if (performance === 4) return require("@/assets/svg/performance-insufficient.svg");
        return "";
      };
    },
    textPerformance() {
      return (performance) => {
        if (performance === 1) return "Alto";
        if (performance === 2) return "Medio";
        if (performance === 3) return "Medio-Bajo";
        if (performance === 4) return "Insuficiente";
        return "SIN INFORMACIÓN";
      };
    },
    iconRatex() {
      return (ratex) => {
        // eslint-disable-next-line global-require
        if (+ratex === 1) return require("@/assets/svg/pie.svg");
        // eslint-disable-next-line global-require
        if (+ratex === 2) return require("@/assets/svg/pie-middle.svg");
        // eslint-disable-next-line global-require
        if (+ratex === 3) return require("@/assets/svg/pie-middle-low.svg");
        // eslint-disable-next-line global-require
        if (+ratex === 4) return require("@/assets/svg/pie-low.svg");
        return "";
      };
    },
    textRatex() {
      return (ratex) => {
        if (+ratex === 1) return "Alta";
        if (+ratex === 2) return "Media";
        if (+ratex === 3) return "Media-Baja";
        if (+ratex === 4) return "Baja";
        return "SIN INFORMACIÓN";
      };
    },
    setGif() {
      return () => {
        const randNum = Math.floor(Math.random() * 5);
        // eslint-disable-next-line global-require
        if (randNum === 0) return require("@/assets/gif/gif-1.gif");
        // eslint-disable-next-line global-require
        if (randNum === 1) return require("@/assets/gif/gif-2.gif");
        // eslint-disable-next-line global-require
        if (randNum === 2) return require("@/assets/gif/gif-3.gif");
        // eslint-disable-next-line global-require
        if (randNum === 3) return require("@/assets/gif/gif-4.gif");
        // eslint-disable-next-line global-require
        if (randNum === 3) return require("@/assets/gif/gif-5.gif");
        return "";
      };
    },
  },
  methods: {
    shortDistance(currentSchool) {
      const campusLocation = {
        lat: currentSchool.campus_location[0].latitud,
        lng: currentSchool.campus_location[0].longitud,
      };
      if (campusLocation && this.studentLocation) {
        const distance = utils.getDistanceInKm(campusLocation, this.studentLocation);
        this.currentSchoolDistance = distance;
        if (distance <= 1) return true;
        return false;
      }
      return false;
    },
    openProfile(currentSchool) {
      this.$mixpanel.track("open_recommendation_digital_profile", {
        distinct_id: this.legalGuardianUuid,
        student_id: this.studentId,
        env: CONFIG.environment,
      });
      this.$emit("openProfile", currentSchool.uuid);
    },
  },
};
</script>
