<template>
  <div class="public-institution_container">
    <div class="public-institution_title">
      {{ $t("register.priority.public_institution.title") }}
    </div>
    <div class="public-institution_subtitle">
      {{ $t("register.priority.public_institution.subtitle") }}
    </div>
    <div class="public-institution_question_title_container">
      <p class="required-field public-institution_question_title">
        {{ $t("register.priority.public_institution.question") }}
      </p>
    </div>
    <v-container class="public-institution_question_container">
      <div
        :class="mobile ? 'public-institution_question-mobile' : 'public-institution_question'"
        :style="
          opt
            ? 'background: #2D10B4; margin-right: 5px;'
            : 'background: #FFFFFF; margin-right: 5px;'
        "
        @click="changeOpt(true)"
      >
        <p :style="opt ? 'color: #FFFFFF;' : 'color: #2D10B4;'">
          {{ $t("register.priority.public_institution.yes_opt") }}
        </p>
      </div>
      <div
        :class="mobile ? 'public-institution_question-mobile' : 'public-institution_question'"
        :style="
          opt === false
            ? 'background: #2D10B4; margin-left: 5px;'
            : 'background: #FFFFFF; margin-left: 5px;'
        "
        @click="changeOpt(false)"
      >
        <p :style="opt === false ? 'color: #FFFFFF;' : 'color: #2D10B4;'">
          {{ $t("register.priority.public_institution.no_opt") }}
        </p>
      </div>
    </v-container>
    <div class="btn_container">
      <v-btn icon @click="backMethod">
        <v-img src="@/assets/iconsPalmira/student_register/icons/back.svg" />
      </v-btn>
      <base-button v-if="isDisabled" class="btn-skip" @click="skipStep">
        {{ $t("register.priority.public_institution.skip_btn") }}
      </base-button>
      <base-button v-else class="btn-continue" @click="continueMethod">
        {{ $t("register.priority.public_institution.continue_btn") }}
      </base-button>
    </div>
    <v-dialog
      content-class="elevation-0"
      overlay-color="transparent"
      v-model="showSkipModal"
      max-width="370"
    >
      <warning-modal
        @skip="skipConfirmMethod"
        @complete="completeMethod"
        :title="$t('register.priority.public_institution.warning_modal.title')"
        :text="$t('register.priority.public_institution.warning_modal.text')"
        color="#FC010A"
        skipModal
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/explorer/base/BaseButton.vue";
import WarningModal from "../WarningModal.vue";

export default {
  name: "PublicInstitution",
  components: {
    BaseButton,
    WarningModal,
  },
  data() {
    return {
      opt: null,
      showSkipModal: false,
    };
  },
  mounted() {
    this.opt = this.optionStore;
  },
  methods: {
    ...mapActions({
      setPriorityStepOne: "newStudentRegister/setPriorityStepOne",
    }),
    continueMethod() {
      const data = {
        opt: this.opt,
      };
      this.setPriorityStepOne(data);
      this.$emit("nextStep");
    },
    backMethod() {
      this.$emit("backStep");
    },
    changeOpt(value) {
      this.opt = value;
    },
    skipStep() {
      this.showSkipModal = true;
    },
    skipConfirmMethod() {
      this.$emit("skipStep");
    },
    completeMethod() {
      this.showSkipModal = false;
    },
  },
  computed: {
    ...mapGetters({
      optionStore: "newStudentRegister/publicInstitutionPriority",
    }),
    isDisabled() {
      return this.opt === null;
    },
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
  },
};
</script>
