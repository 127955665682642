var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section",style:({
    '--height': _vm.getHeight(),
    'background-color': _vm.background,
    'z-index': 1,
    '--width': _vm.width,
    '--padding': _vm.fullSpace ? '0px' : '10px',
  })},[(_vm.toggableDirection)?_c('div',{staticStyle:{"cursor":"pointer","width":"100%","position":"relative","z-index":"3"},on:{"click":function($event){return _vm.toggle()}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.title)}}),(!_vm.mobile)?_c('div',{staticStyle:{"float":"right","position":"absolute","top":"10px","right":"5px"}},[_c('img',{attrs:{"src":require("../../assets/dashboard/icons/down_arrow.svg")}})]):_vm._e()]):_c('div',{staticStyle:{"white-space":"nowrap","z-index":"3"},style:({
      width: _vm.fullSpace ? 'auto' : '60%',
      position: _vm.fullSpace ? 'absolute' : 'relative',
      'background-color': _vm.fullSpace ? 'rgba(255,255,255,0.7)' : 'transparent',
      'border-radius': '5px',
      margin: _vm.fullSpace ? '10px' : '0px',
      padding: _vm.fullSpace ? '5px 10px 5px 10px' : '0px',
    })},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.title)}})]),(_vm.showContents)?_c('div',{staticClass:"section-content",style:({ position: 'relative', 'z-index': 2, '--height': _vm.height, 'max-width': '100%' })},[_c('div',{staticStyle:{"width":"100%","max-width":"100%"},style:({ height: _vm.fullSpace ? '100%' : 'calc(100% - 30px)' })},[(_vm.name == 'dashboardmap')?_c('DashboardMap',{attrs:{"role":_vm.role}}):_vm._e(),(_vm.name == 'dfm')?_c('DFM',{attrs:{"role":_vm.role}}):(_vm.name == 'analytics')?_c('Analytics',{attrs:{"role":_vm.role}}):(_vm.name == 'digitalprofile')?_c('DigitalProfile',{attrs:{"role":_vm.role}}):(_vm.name == 'profile')?_c('Profile',{attrs:{"role":_vm.role,"showStudentModals":_vm.showStudentModals}}):(_vm.name == 'notifications')?_c('Notifications',{attrs:{"role":_vm.role}}):(_vm.name == 'tools')?_c('Tools2',{attrs:{"role":_vm.role}}):(_vm.name == 'favorites')?_c('Favorites',{attrs:{"role":_vm.role}}):(_vm.name == 'messages')?_c('Messages',{attrs:{"role":_vm.role}}):(_vm.name == 'applications')?_c('Applications',{attrs:{"role":_vm.role}}):(_vm.name == 'admissions')?_c('Admissions',{attrs:{"role":_vm.role}}):(_vm.name == 'schoollist')?_c('SchoolList',{attrs:{"role":_vm.role}}):(_vm.name == 'simulation')?_c('Simulation',{staticStyle:{"z-index":"1 !important"},attrs:{"role":_vm.role}}):(_vm.name == 'graph')?_c('Graph'):(_vm.name == 'report-list')?_c('ReportList'):_vm._e()],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }