<template>
  <div id="panels" class="campus-detail__panels mx-0">
    <v-expansion-panels accordion flat>
      <v-expansion-panel
        class="mt-2 pl-0 pr-0"
        v-for="({ name, title }, index) in panels"
        :key="index"
      >
        <v-expansion-panel-header
          v-if="showPanel(name)"
          @click="trackMixPanel(name)"
          class="campus-detail__panels__panel-header ml-0 pl-0"
        >
          <picture class="d-flex align-center">
            <h3 class="panels__performance-title ml-6">
              {{ title }}
            </h3>
          </picture>
          <template v-slot:actions>
            <v-icon color="#0D22A8"> $expand </v-icon>
          </template>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <Simce v-if="name === 'simce'" />
          <PSU v-if="name === 'psu'" />
          <Enrollment v-if="name === 'matricula'" />
          <Achievements v-if="name === 'logros'" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Simce from "../panels/mini_panels/Simce.vue";
import PSU from "../panels/mini_panels/PSU.vue";
import Enrollment from "../panels/mini_panels/Enrollment.vue";
import Achievements from "../panels/mini_panels/Achievments.vue";
export default {
  name: "SimcePanels",
  components: {
    Simce,
    PSU,
    Enrollment,
    Achievements,
  },
  data() {
    return {
      panels: [],
    };
  },
  created() {
    this.setPanelsCampusDetail();
  },
  computed: {
    ...mapGetters({
      campusDetail: "institutions/campusDetails",
      currentStudent: "authentication/currentStudent",
      isGuest: "authentication/isGuest",
    }),
  },
  methods: {
    ...mapGetters({
      legalGuardianUUID: "authentication/legalGuardianUUID",
      environment: "authentication/environment",
    }),
    isAdmissionPanel(panelName) {
      return panelName === "simce";
    },
    setPanelsCampusDetail() {
      this.panels = [
        {
          name: "simce",
          title: "SIMCE 2016-2019",
          isEnable: this.hasScores("simce"),
        },
        {
          name: "psu",
          title: "PSU 2016-2019",
          isEnable: this.hasScores("psu"),
        },
        {
          name: "matricula",
          title: "Matrícula educación superior",
          isEnable: this.hasEnrollmentInfo(),
        },
        {
          name: "logros",
          title: "Logros destacados",
          isEnable: true,
        },
      ];
      this.panels = this.panels.filter((panel) => panel.isEnable);
    },
    getNationalScores() {
      const studentsInUniversity = this.campusDetail.higheredstats_campus.find(
        (stats) => stats.highered_stats === 3
      );
      if (studentsInUniversity !== undefined) {
        return studentsInUniversity.number;
      }
      return false;
    },
    trackMixPanel(nameTrack) {
      if (nameTrack === this.showingPanel) {
        this.showingPanel = "";
      } else {
        const track = `click_school_${nameTrack}`;
        this.$mixpanel.track(track, {
          distinct_id: this.legalGuardianUUID(),
          student_id: this.currentStudent.uuid,
          school_id: this.campusDetail.uuid,
          env: this.environment(),
        });
        this.showingPanel = nameTrack;
      }
    },
    showPanel(name) {
      if (name === "logros" && this.getNationalScores() === false) {
        return false;
      }
      return true;
    },
    hasScores(scoreCategory) {
      let hasScores = false;
      if (this.campusDetail.testscore_campus.length > 0) {
        this.campusDetail.testscore_campus.forEach((score) => {
          if (scoreCategory === "simce") {
            if (score.testscore_category.testscore_variable_label < 5) {
              hasScores = true;
            }
          }
          if (scoreCategory === "psu") {
            if (score.testscore_category.testscore_variable_label === 5) {
              hasScores = true;
            }
          }
        });
      }
      return hasScores;
    },
    hasEnrollmentInfo() {
      if (this.campusDetail.higheredstats_campus.length) {
        return true;
      }
      return false;
    },
  },
};
</script>
