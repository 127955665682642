<template>
  <v-expansion-panels v-model="panelsContact" class="contact_panel__container">
    <v-expansion-panel>
      <v-expansion-panel-header
        class="p-0 d-flex flex-column justify-start align-start"
        disable-icon-rotate
        expand-icon=""
      >
        <template v-slot="{ open }">
          <p
            v-if="buttonsContact[0].isDisabled && !open"
            class="contact_panel__container__buttons--error pl-8"
            v-t="'contact_panel.contact.school-error'"
          />
          <div class="contact_panel__container__buttons" v-if="!open">
            <button
              type="button"
              v-for="{ name, isDisabled } in buttonsContact"
              :key="name"
              @click="selectContact(name)"
              :disabled="isDisabled"
              :class="{
                'contact_panel__container__buttons--plataform':
                  name === $t('contact_panel.contact.plataform'),
                'contact_panel__container__buttons--school':
                  name === $t('contact_panel.contact.school'),
              }"
            >
              <img width="30" src="@/assets/icons/messageLight.svg" />
              <span> {{ name }} </span>
            </button>
          </div>

          <div v-else class="contact_panel__container__header--white px-6">
            <div class="d-flex flex-row">
              <img width="30" src="@/assets/icons/messageBlue.svg" />
              <p
                class="contact_panel__container__header--white--title"
                v-if="isSelectedContactPlataform"
                v-t="'contact_panel.contact.plataform'"
              />
              <p
                class="contact_panel__container__header--white--title"
                v-else
                v-t="'contact_panel.contact.school'"
              />

              <button class="contact_panel__container__close" type="button">
                <v-icon color="#F90E1F" size="24"> mdi-close-circle </v-icon>
              </button>
            </div>

            <p v-if="!isSelectedContactPlataform" class="contact_panel__container__text">
              {{ campusDetail.campus_name }}
            </p>
          </div>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <Contact
          :schoolName="this.campusDetail.campus_name"
          :schoolUuid="this.campusDetail.uuid"
          :schoolId="String(this.campusDetail.id)"
          :schoolEmail="this.campusDetail.institutioncontact_campus[0].email"
          :contacts="campusDetail.institutioncontact_campus"
          :campusName="campusDetail.campus_name"
          :isContactPlataformForm="isSelectedContactPlataform"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapGetters } from "vuex";
import Contact from "@/components/explorer/contact/Contact.vue";

export default {
  name: "ContactPanel",
  components: {
    Contact,
  },
  data() {
    return {
      isSelectedContactPlataform: false,
      panelsContact: [0],
    };
  },
  watch: {
    panelsContact(value) {
      if (value === 0) {
        if (!this.isSelectedContactPlataform && this.buttonsContact[0].isDisabled) {
          this.panelsContact = [undefined];
        }
      } else {
        this.isSelectedContactPlataform = false;
      }
    },
    goToFormContactFrom({ from }) {
      this.isSelectedContactPlataform = from !== "contact-institution";
      this.panelsContact = 0;
    },
  },
  computed: {
    ...mapGetters({
      campusDetail: "institutions/campusDetails",
      goToFormContactFrom: "contactForm/goToFormContactFrom",
    }),
    buttonsContact() {
      const buttons = [
        {
          name: this.$t("contact_panel.contact.school"),
          isDisabled: !this.hasContactsSchool(),
        },
        {
          name: this.$t("contact_panel.contact.plataform"),
          isDisabled: false,
        },
      ];

      return buttons;
    },
  },
  methods: {
    selectContact(contact) {
      this.isSelectedContactPlataform = contact === this.$t("contact_panel.contact.plataform");
    },
    hasContactsSchool() {
      const list = [];
      // eslint-disable-next-line
      for (const { email, contact_label } of this.campusDetail.institutioncontact_campus) {
        // eslint-disable-next-line
        const name = contact_label.contact_name;
        // eslint-disable-next-line camelcase
        const { id } = contact_label;
        const hasEmailContact = id < 5 && email;

        if (hasEmailContact) {
          list.push({ name, email });
        }
      }
      return list.length > 0;
    },
  },
};
</script>
