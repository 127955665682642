<template>
  <section class="campus-detail__panels__container">
    <v-tooltip v-model="showTooltip" class="" bottom content-class="custom-tooltip">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="panels__tooltip panels__tooltip--right"
          icon
          dark
          small
          color="#1E2D4C"
          style="margin-top: -4px !important"
        >
          <v-icon dark> mdi-information </v-icon>
        </v-btn>
      </template>
      <span v-t="'campus-details.tooltip.students'" />
    </v-tooltip>
    <p
      class="campus-detail__panels__title text-left mb-2 font-weight-medium"
      v-for="({ name, total }, index) in getGrades"
      :key="index"
    >
      <span class="font-bold">
        {{ total }}
      </span>

      <span
        class="ml-1"
        v-t="{
          path: 'campus-details.student',
          args: { grade: name },
        }"
      />
    </p>
    <MessageInPanel from="students-panel" />
  </section>
</template>

<script>
import MessageInPanel from "./MessageInPanel.vue";

export default {
  // name: 'StudentsPanel',
  components: {
    MessageInPanel,
  },
  data() {
    return {
      showTooltip: false,
    };
  },
  props: {
    members: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    getGrades() {
      const formatArray = this.members.map((obj) => {
        if (obj.stage_label !== null) {
          const grade = {};
          grade.name = obj.stage_label;
          grade.total = obj.total_members;
          return grade;
        }
        return {};
      });

      const removeEmptyObject = formatArray.filter((grade) => grade.name !== undefined);

      const removeDuplicateGrades = removeEmptyObject.reduce((previousValue, currentValue) => {
        const existGrade = previousValue.find((grade) => grade.name === currentValue.name);

        if (existGrade) {
          return previousValue.map((grade) => {
            if (grade.name === currentValue.name) {
              return {
                ...grade,
                total: grade.total + currentValue.total,
              };
            }

            return grade;
          });
        }
        return [...previousValue, currentValue];
      }, []);

      return removeDuplicateGrades;
    },
  },
};
</script>
