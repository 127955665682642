<template>
  <div class="search-in-all-schools" :class="{ 'search-in-all-schools--mobile': mobile }">
    <div
      class="search-in-all-schools__container search-in-all-schools__container--center"
      :class="{ 'search-in-all-schools__container--mobile': mobile }"
    >
      <div
        class="search-in-all-schools__container__btn"
        :class="{ 'search-in-all-schools__container__btn--mobile': mobile }"
      >
        <v-text-field
          class="search-in-all-schools__container__btn__text-field"
          v-model="schoolToSearch"
          :label="loading ? $t('views.schools.load-schools') : $t('views.schools.search')"
          @input="retrieveElasticSearch"
          solo
          dense
          height="31"
          color="#828282"
          prepend-inner-icon="mdi-magnify"
        />
        <transition name="fade">
          <div
            v-if="mainSearch.length > 0"
            class="search-in-all-schools__container__div"
            v-closable="{
              handler: 'closeList',
            }"
          >
            <div v-if="!loading">
              <div
                v-for="(school, index) in mainSearch"
                :key="index"
                class="search-in-all-schools__container__div__list"
              >
                <div
                  @click="changeSchool(school)"
                  class="search-in-all-schools__container__div__list__item"
                >
                  {{ setSchoolInfo(school) }}
                </div>
              </div>
            </div>
            <div class="search-in-all-schools__container__div__loading">
              <v-progress-circular indeterminate v-if="loading" />
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";

let handleOutsideClick;
Vue.directive("closable", {
  bind(el, binding, vnode) {
    handleOutsideClick = (e) => {
      e.stopPropagation();
      const { handler } = binding.value;
      if (!el.contains(e.target)) {
        vnode.context[handler]();
      }
    };
    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);
  },
  unbind() {
    document.removeEventListener("click", handleOutsideClick);
    document.removeEventListener("touchstart", handleOutsideClick);
  },
});

export default {
  name: "SearchInAllSchools",
  data() {
    return {
      schoolToSearch: "",
    };
  },
  props: {
    inFilter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      mainSearch: "elasticSearch/mainSearch",
      loading: "elasticSearch/loading",
      gradesOptions: "options/grades",
      isGuest: "authentication/isGuest",
      currentStudent: "authentication/currentStudent",
      guestLevel: "authentication/guestLevel",
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapActions({
      setMainSearch: "elasticSearch/setMainSearch",
      cleanMainSearch: "elasticSearch/cleanMainSearch",
      selectSchoolFromSearchBar: "institutions/selectSchoolFromSearchBar",
      warningSnackbar: "snackbar/warn",
    }),
    schoolCommuneAndName(school) {
      return `(${school.commune}) ${school.name}`;
    },
    setSchoolInfo(school) {
      const search = `${school.institution_name} - ${school.commune} - RBD:${
        school.institution_code ?? ""
      }`;
      return search;
    },
    changeSchool(school) {
      if (school) {
        this.selectSchoolFromSearchBar({ uuid: school.uuid });
        this.schoolToSearch = null;
        this.cleanMainSearch();
        this.$emit("clicked");
      } else {
        this.warningSnackbar(this.$t("errors.guestGradeMatchError"));
        this.schoolToSearch = null;
        this.cleanMainSearch();
        this.$emit("clicked");
      }
    },
    retrieveElasticSearch() {
      if (this.schoolToSearch.length !== 0) {
        if (this.schoolToSearch.length >= 3) {
          this.setMainSearch({ mainSearch: this.schoolToSearch });
        }
      }
    },
    closeList() {
      this.cleanMainSearch();
    },
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
