<template>
  <v-app class="main-container">
    <div v-if="CONFIG.maintenance">
      <maintenance />
    </div>

    <!--<v-layout v-resize="onResize">-->
    <div v-if="!CONFIG.maintenance">
      <main-layout
        v-show="!primerShow"
        :useLoginBackground="useLoginBackground"
      />
      <v-main v-if="primerShow" class="main-container">
        <v-container fluid class="ma-0 pa-0 fill-height">
          <Primer />
        </v-container>
      </v-main>
      <!--    FOOTER    -->
      <loading :overlay="loading" />
      <loading :overlay="loadingMarkers" />
      <v-dialog
        v-model="chooseProgramModal"
        max-width="700px"
        persistent
        @click:outside="closeChooseProgramModal(false)"
      >
        <choose-program-modal @close="closeChooseProgramModal(false)" />
      </v-dialog>
      <v-dialog
        v-model="campusDetailModal"
        transition="dialog-bottom-transition"
        content-class="favorites__dialog"
        max-width="800px"
        min-width="350px"
        @click:outside="closePanels()"
      >
        <campus-detail
          v-if="openCampusDetail"
          :outsideClose="dialogClosed"
          @close="closeCampusDetail"
        />
      </v-dialog>
      <base-snackbar />
      <!--</v-layout>-->
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import BaseSnackbar from "@/components/explorer/base/BaseSnackbar.vue";
import ChooseProgramModal from "@/components/explorer/general/ChooseProgramModal.vue";
import Loading from "@/components/explorer/base/Loading.vue";
import MainLayout from "@/layouts/MainLayout.vue";
import Maintenance from "@/layouts/Maintenance.vue";
import Primer from "@/views/Primer.vue";
import CampusDetail from "@/components/explorer/general/campus_detail/CampusDetail.vue";
import CONFIG from "@/config";

export default {
  name: "MainExplorer",
  components: {
    BaseSnackbar,
    Loading,
    MainLayout,
    Primer,
    ChooseProgramModal,
    CampusDetail,
    Maintenance,
  },
  updated() {
    // eslint-disable-next-line
    this.$nextTick(function () {
      this.dialogClosed = false;
    });
  },
  data() {
    return {
      CONFIG,
      windowSize: {
        x: 0,
        y: 0,
      },
      dialogClosed: false,
    };
  },
  computed: {
    ...mapGetters({
      primerShow: "authentication/primerShow",
      locale: "locale/locale",
      loading: "loading/loading",
      loadingMarkers: "loading/loadingMarkers",
      chooseProgramModal: "institutions/chooseProgramModal",
      showCampusDetail: "institutions/showCampusDetail",
      guestLocationLastUpdated: "authentication/guestLocationLastUpdated",
      resetStore: "authentication/resetStore",
      isGuest: "authentication/isGuest",
    }),
    noFooter() {
      return this.$route.meta.noFooter;
    },
    campusDetailModal: {
      get() {
        return this.showCampusDetail;
      },
      set(newVal) {
        this.$store.commit("institutions/setShowCampusDetail", newVal);
      },
    },
    openCampusDetail() {
      if (this.campusDetailModal === true) {
        return true;
      }
      return false;
    },
    useLoginBackground() {
      const loginViews = [
        "Login",
        "LoginApp",
        "ResetPassword",
        "ResendVerificationEmail",
      ];
      return loginViews.includes(this.$route.name);
    },
    currentRoute() {
      return this.$route.name;
    },
  },
  created() {
    let differenceDays;
    if (this.isGuest) {
      this.setFakeToken();
    }
    if (this.resetStore.timestamp !== undefined) {
      const dateChangeUrl = moment(this.resetStore.timestamp);
      const today = moment().format("YYYY-MM-DD");
      differenceDays = dateChangeUrl.diff(today, "days");
    } else {
      const resetInfo = {
        timestamp: moment().format("YYYY-MM-DD"),
      };
      this.setResetStoreInfo({ data: resetInfo });
    }
    if (differenceDays < 0) {
      const resetInfo = {
        timestamp: moment().format("YYYY-MM-DD"),
      };
      this.resetStores({}).then(() => {
        this.setResetStoreInfo({ data: resetInfo });
      });
      this.retrieveRegionsGenderAndGrades();
      this.setCommuneList();
    } else {
      this.retrieveRegionsGenderAndGrades();
      this.setCommuneList();
    }
  },
  mounted() {
    this.onResize();
    // eslint-disable-next-line prefer-arrow-callback
    // eslint-disable-next-line func-names
    window.addEventListener("message", (e) => {
      // eslint-disable-next-line prefer-destructuring
      const data = e.data;
      if (data === "true" || data === "feedback") {
        this.startFromPrimer();
        this.hidePrimer();
      }
    });
    const now = moment();
    const lastUpdate = moment(this.guestLocationLastUpdated);
    const timeDifferenceInDays = now.diff(lastUpdate, "days");
    if (timeDifferenceInDays >= 1) {
      this.cleanGuestData();
    }
  },
  // async created() {
  //   this.$i18n.locale = this.locale;
  // },
  methods: {
    ...mapActions({
      setCurrentStudent: "authentication/setCurrentStudent",
      setLegalGuardian: "authentication/setLegalGuardian",
      setReferenceLocation: "authentication/setReferenceLocation",
      cleanFilters: "institutions/cleanFilters",
      closeChooseProgramModal: "institutions/closeChooseProgramModal",
      startFromPrimer: "authentication/startFromPrimer",
      hidePrimer: "authentication/hidePrimer",
      cleanGuestData: "authentication/cleanGuestData",
      stopActualVideo: "options/stopActualVideo",
      retrieveSchoolNames: "institutions/retrieveSchoolNames",
      retrieveRegionsGenderAndGrades: "options/retrieveOptions",
      resetStores: "authentication/resetStores",
      setResetStoreInfo: "authentication/setResetStore",
      setCommuneList: "institutions/setCommuneList",
      updateCurrentSchool: "institutions/updateCurrentSchool",
      setFakeToken: "authentication/setFakeToken",
    }),
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    closePanels() {
      this.dialogClosed = true;
    },
    closeCampusDetail() {
      this.campusDetailModal = false;
      this.stopActualVideo();
    },
  },
  // watch: {
  //   locale(newVal) {
  //     this.$i18n.locale = newVal;
  //   },
  // },
};
</script>
