<template>
  <div class="simulate-card">
    <div v-if="showRanking" class="simulate-card__rank">
      <span class="simulate-card__rank__card">
        {{ index + 1 }}
      </span>
    </div>
    <div class="simulate-card__info">
      <div class="simulate-card__text-container">
        <p class="text text--small-01 font-bold text--word-break simulate-card__text">
          {{ campus.campus_name }}
        </p>
        <!-- <p class="text text--small-01 text--word-break simulate-card__text">
          {{gender}}
        <p class="text text--small-01 text--word-break simulate-card__text">
          {{shift}}
        </p> -->
      </div>
      <div class="simulate-card__avatar-container">
        <favorite-button
          :isFavorite="campus.isFavorite"
          class="simulate-card__avatar-container__fav"
          float
          @click-favorite="onFavoriteClicked(campus)"
          size="28"
          inSimulateView
        />
        <v-avatar tile size="85">
          <v-img :src="getSchoolImageSrc(campus)"> </v-img>
        </v-avatar>
      </div>
    </div>
    <div v-if="totalCards > 1 && showButtons" class="simulate-card__order">
      <div
        v-if="showDownRank"
        @click="downRank({ program: programId, ranking: programRank })"
        class="simulate-card__order__button simulate-card__order__button--up"
      >
        <v-icon color="#0F69B4" size="40" style="height: 20px"> mdi-menu-up </v-icon>
      </div>
      <div
        v-if="showUpRank"
        @click="upRank({ program: programId, ranking: programRank })"
        class="simulate-card__order__button simulate-card__order__button--down"
      >
        <v-icon color="#9CE3E3" size="40" style="height: 20px"> mdi-menu-down </v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FavoriteButton from "@/components/explorer/favorite/FavoriteButton.vue";
import SchoolCardMixin from "@/components/explorer/mixins/SchoolCardMixin.vue";

export default {
  name: "PreSimulatedCard",
  mixins: [SchoolCardMixin],
  components: {
    FavoriteButton,
  },
  data() {
    return {
      programRank: null,
      programId: null,
    };
  },
  props: {
    campus: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    program: {
      type: Number,
      default: 0,
    },
    ranking: {
      type: Number,
      default: 0,
    },
    totalCards: {
      type: Number,
      default: 0,
    },
    showRanking: {
      type: Boolean,
      default: true,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    //this.setProgramGender();
    //this.setProgramShift();
    this.setProgramRank();
    this.setProgramId();
  },
  computed: {
    ...mapGetters({
      favoriteSchools: "institutions/favoriteSchools",
    }),
    showDownRank() {
      const show = this.index !== 0;
      return show;
    },
    showUpRank() {
      const show = this.index + 1 !== this.totalCards;
      return show;
    },
  },
  methods: {
    ...mapActions({
      downRank: "institutions/downFavoriteRank",
      upRank: "institutions/upFavoriteRank",
      removeFavoriteByUUID: "institutions/removeFavoriteByUUID",
    }),
    // TODO: REFACTORIZAR
    getSchoolImageSrc(campus) {
      let imglink = "";
      if (campus.image_thumb.length > 0) {
        imglink = campus.image_thumb[0].image_link;
        return imglink;
      }
      // eslint-disable-next-line global-require
      return require("@/assets/icons/school.svg");
    },
    onFavoriteClicked(campus) {
      this.removeFavoriteByUUID({ programId: campus.id });
    },
    setProgramRank() {
      this.favoriteSchools.forEach((program) => {
        if (this.campus.uuid === program.campus) {
          this.programRank = program.rank;
        }
      });
    },
    setProgramId() {
      this.favoriteSchools.forEach((program) => {
        if (this.campus.uuid === program.campus) {
          this.programId = program.program;
        }
      });
    },
    // deprecated
    setProgramGender() {
      this.favoriteSchools.forEach((program) => {
        if (this.campus.uuid === program.campus) {
          this.gender = program.gender.gender_name;
        }
      });
    },
    // deprecated
    setProgramShift() {
      const programId = this.campus.program;
      this.campus.campus.program_campus.forEach((program) => {
        if (programId === program.id) {
          this.shift = program.shift_label.shift_name;
        }
      });
    },
  },
};
</script>
