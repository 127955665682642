<template>
  <div class="applications section-content">
    <h3 class="applications__title">
      {{ $t("dashboard.applications.title") }}
    </h3>
    <p class="applications__subtitle" v-t="'dashboard.applications.subtitle'" />
    <div class="applications--empty" v-if="allFavoriteSchools.length === 0">
      <div class="d-flex justify-center align-center">
        <span class="applications--empty--text" v-t="'dashboard.applications.no-favorites'" />
        &nbsp;
        <img src="@/assets/dashboard/icons/like.svg" class="applications--empty--img" />
      </div>
      <button
        class="applications--empty--btn mt-3"
        type="button"
        @click="toExplorer()"
        v-t="'dashboard.favorites.to-explorer'"
      />
    </div>
    <div
      class="applications--empty"
      v-if="applications.length === 0 && allFavoriteSchools.length > 0"
    >
      <div class="d-flex justify-center align-center">
        <span class="applications--empty--text" v-t="'dashboard.applications.no-postulation'" />
        <!-- &nbsp; -->
        <!-- <img src="@/assets/dashboard/icons/like.svg" class="" /> -->
      </div>
      <button
        class="applications--empty--btn mt-3"
        type="button"
        @click="toApplication()"
        v-t="'dashboard.applications.to-application'"
      />
    </div>
    <div v-for="(app, i) in applications" :key="i" class="applications__students">
      <div class="applications__student_name">
        {{ getStudentName(app) }}
      </div>
      <!-- TODO: Add transition -->
      <div v-if="false" class="applications__student_show-detail mt-1 pb-1">
        <div class="applications__student_show-detail-campus">
          {{ $t("dashboard.applications.campus", [app.admission_school[0].program.campus]) }}
        </div>
        <div class="applications__student_show-detail-shift">
          {{ $t("dashboard.applications.shift", [app.admission_school[0].program.shift]) }}
        </div>
        <div class="applications__student_show-detail-sex_type">
          {{ app.admission_school[0].program.sex_type }}
        </div>
        <div class="applications__student_show-detail-report">
          <span v-html="$t('dashboard.applications.report')" />
        </div>
      </div>
      <div class="applications__student_detail">
        <div class="applications__student_icon">
          <img
            v-if="app.actual_status_application == 'S'"
            src="@/assets/iconsNewHaven/dashboard/check.svg"
            style="margin-right: 5px"
          />
          <img v-else src="@/assets/dashboard/icons/disabled-check.svg" style="margin-right: 5px" />
        </div>
        <div class="applications__student_detail_text">
          <div class="applications__student_detail_text-title">
            <span v-if="app.actual_status_application == 'S'">
              {{ $t("dashboard.applications.application-complete") }}
            </span>
            <span v-else style="color: #fc2451">
              {{ $t("dashboard.applications.application-incomplete") }}
            </span>
          </div>
          <div class="applications__student_detail_text-subtitle">
            {{ $t("dashboard.applications.total-schools", [app.total_campus]) }}
          </div>
          <div
            class="applications__student_detail_text-reasons"
            v-if="app.actual_status_application == 'C'"
            v-t="{
              path: 'dashboard.applications.reasons.incomplete_profile',
              args: { number: favoritesNumber(app) },
            }"
          />
        </div>
      </div>
      <div class="applications__student_actions">
        <base-button
          class="applications__student_actions-detail"
          @click="showDetailFn(app)"
          :disabled="app.actual_status_application == 'C'"
        >
          {{ $t("dashboard.applications.show-detail") }}
        </base-button>
        <base-button
          class="applications__student_actions-explore"
          @click="goExplorer(app.applicant)"
        >
          {{ $t("dashboard.applications.apply") }}
        </base-button>
      </div>
    </div>
    <v-dialog
      content-class="elevation-0"
      overlay-color="transparent"
      v-model="showDetail"
      max-width="600"
    >
      <detail-modal @close="closeModal" :application="application" />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/explorer/base/BaseButton.vue";
import DetailModal from "@/components/dashboard/DetailModal.vue";
import CONFIG from "@/config";

export default {
  name: "Applications",
  components: {
    BaseButton,
    DetailModal,
  },
  data() {
    return {
      CONFIG,
      showDetail: false,
      application: {},
    };
  },
  mounted() {
    this.$nextTick(() => {
      // 'uuid', 'application_status', 'risk_from_loading', 'risk_from_api', 'round', 'applicant'
      // this.getApplications();
    });
  },
  computed: {
    ...mapGetters({
      applications: "institutions/applications",
      allFavoriteSchools: "institutions/allFavoriteSchools",
      students: "authentication/students",
      thereAreStudents: "authentication/students",
    }),
  },
  methods: {
    ...mapActions({
      getApplications: "institutions/getApplications",
      setCurrentStudentWithUUID: "authentication/setCurrentStudentWithUUID",
      errorSnackbar: "snackbar/error",
    }),
    toExplorer() {
      if (this.thereAreStudents.length === 0) {
        this.errorSnackbar(this.$t("dashboard.favorites.there-are-students"));
      } else {
        this.$router.push({ name: "Explorer" });
      }
    },
    toApplication() {
      this.$router.push({ name: "Postulate" });
    },
    closeModal() {
      this.showDetail = false;
    },
    showDetailFn(app) {
      this.tagMixPanel("click_show_details_postulation");
      this.application = app;
      this.showDetail = true;
    },
    goExplorer(uuid) {
      this.tagMixPanel("click_edit_postulation");

      this.setCurrentStudentWithUUID({ uuid });
      this.$router.push("/postulate");
    },
    getStudentName(app) {
      let studentName;
      this.students.forEach((student) => {
        if (app.applicant === student.id) {
          studentName = student.name;
        }
      });
      return studentName;
    },
    tagMixPanel(tag) {
      this.$mixpanel.track(tag, {
        distinct_id: this.setCurrentStudentWithUUID,
        env: CONFIG.environment,
      });
    },
    favoritesNumber(app) {
      let favoritesSchools = 0;
      let studentUuid;
      this.students.forEach((student) => {
        if (app.applicant === student.id) {
          studentUuid = student.uuid;
        }
      });
      this.allFavoriteSchools.forEach((school) => {
        if (studentUuid === school.applicant_uuid) {
          favoritesSchools += 1;
        }
      });
      return favoritesSchools;
    },
  },
};
</script>
