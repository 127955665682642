var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panels',{staticClass:"contact_panel__container",model:{value:(_vm.panelsContact),callback:function ($$v) {_vm.panelsContact=$$v},expression:"panelsContact"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"p-0 d-flex flex-column justify-start align-start",attrs:{"disable-icon-rotate":"","expand-icon":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [(_vm.buttonsContact[0].isDisabled && !open)?_c('p',{directives:[{name:"t",rawName:"v-t",value:('contact_panel.contact.school-error'),expression:"'contact_panel.contact.school-error'"}],staticClass:"contact_panel__container__buttons--error pl-8"}):_vm._e(),(!open)?_c('div',{staticClass:"contact_panel__container__buttons"},_vm._l((_vm.buttonsContact),function(ref){
var name = ref.name;
var isDisabled = ref.isDisabled;
return _c('button',{key:name,class:{
              'contact_panel__container__buttons--plataform':
                name === _vm.$t('contact_panel.contact.plataform'),
              'contact_panel__container__buttons--school':
                name === _vm.$t('contact_panel.contact.school'),
            },attrs:{"type":"button","disabled":isDisabled},on:{"click":function($event){return _vm.selectContact(name)}}},[_c('img',{attrs:{"width":"30","src":require("@/assets/icons/messageLight.svg")}}),_c('span',[_vm._v(" "+_vm._s(name)+" ")])])}),0):_c('div',{staticClass:"contact_panel__container__header--white px-6"},[_c('div',{staticClass:"d-flex flex-row"},[_c('img',{attrs:{"width":"30","src":require("@/assets/icons/messageBlue.svg")}}),(_vm.isSelectedContactPlataform)?_c('p',{directives:[{name:"t",rawName:"v-t",value:('contact_panel.contact.plataform'),expression:"'contact_panel.contact.plataform'"}],staticClass:"contact_panel__container__header--white--title"}):_c('p',{directives:[{name:"t",rawName:"v-t",value:('contact_panel.contact.school'),expression:"'contact_panel.contact.school'"}],staticClass:"contact_panel__container__header--white--title"}),_c('button',{staticClass:"contact_panel__container__close",attrs:{"type":"button"}},[_c('v-icon',{attrs:{"color":"#F90E1F","size":"24"}},[_vm._v(" mdi-close-circle ")])],1)]),(!_vm.isSelectedContactPlataform)?_c('p',{staticClass:"contact_panel__container__text"},[_vm._v(" "+_vm._s(_vm.campusDetail.campus_name)+" ")]):_vm._e()])]}}])}),_c('v-expansion-panel-content',[_c('Contact',{attrs:{"schoolName":this.campusDetail.campus_name,"schoolUuid":this.campusDetail.uuid,"schoolId":String(this.campusDetail.id),"schoolEmail":this.campusDetail.institutioncontact_campus[0].email,"contacts":_vm.campusDetail.institutioncontact_campus,"campusName":_vm.campusDetail.campus_name,"isContactPlataformForm":_vm.isSelectedContactPlataform}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }