var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loadingSchoolCard)?_c('div',{staticClass:"skeleton-school-card"},[_c('v-skeleton-loader',_vm._b({staticClass:"pt-2",attrs:{"height":"244","type":"list-item-avatar, divider, image"}},'v-skeleton-loader',_vm.attrs,false))],1):_vm._e(),(!_vm.loadingSchoolCard)?_c('div',{staticClass:"school-card",class:_vm.isGuest ? 'school-card--guest' : ''},[_c('div',{staticClass:"school-card__main"},[_c('div',{staticClass:"school-card__main__text-container",on:{"click":function($event){return _vm.explore(false)}}},[_c('p',{staticClass:"text school-card__main__name"},[_vm._v(" "+_vm._s(_vm.campusNameShort(_vm.campus.campus_name, 25))+" ")]),_c('div',{staticClass:"school-card__main__label text-left"},[_vm._v(" "+_vm._s(_vm.getSector)+" "),_c('span',{},[_vm._v("|")]),_vm._v(" "+_vm._s(_vm.getGrades)+" ")]),(_vm.hasVacancies())?_c('div',{staticClass:"school-card__available d-flex align-center justify-start"},[_c('img',{staticClass:"mr-2",attrs:{"width":"10","height":"10","src":require("@/assets/iconsChile/filter/white-face.svg")}}),_vm._v(" "+_vm._s(this.$t("campus-details.vacancies.with-vacancies"))+" ")]):_vm._e()]),_c('div',{staticClass:"school-card__main__image-container"},[_c('v-avatar',{staticClass:"school-card__main__image",attrs:{"size":"107","tile":""}},[_c('v-img',{staticStyle:{"border-top-right-radius":"5px"},attrs:{"src":_vm.campus.image_thumb.length > 0
                ? _vm.campus.image_thumb[0].image_link
                : require('@/assets/icons/school.svg')}})],1),_c('div',{staticClass:"school-card__main__fav"},[_c('favorite-button',{staticClass:"school-card__main__fav__btn",attrs:{"isFavorite":_vm.campus.isFavorite,"float":"","opaque":""},on:{"click-favorite":function($event){return _vm.onFavoriteClicked()}}})],1),(false)?_c('div',{class:("school-card__main__priorities " + (_vm.isGuest ? 'school-card__guest' : ''))},[(
              _vm.studentHasPriorities && _vm.getStudentPriorities.has_sae_priority
            )?_c('div',{staticClass:"school-card__main__priorities__priority"},[_c('v-icon',{attrs:{"size":"17"}},[_vm._v(" $prioritySEP ")])],1):_vm._e(),(
              _vm.studentHasPriorities &&
              _vm.getStudentPriorities.has_sibling_priority
            )?_c('div',{staticClass:"school-card__main__priorities__priority"},[_c('v-icon',{attrs:{"size":"17"}},[_vm._v(" $prioritySibling ")])],1):_vm._e(),(_vm.getOtherPriorities.length > 0)?_c('div',{staticClass:"school-card__main__priorities__priority"},[_c('v-icon',{attrs:{"size":"17"}},[_vm._v(" $priorityOther ")])],1):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"school-card__info"},[(_vm.loadingLite)?_c('div',{staticClass:"school-card__info__container"},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_vm._e(),(!_vm.loadingLite)?_c('div',{staticClass:"school-card__info__container d-flex"},[_c('div',{staticClass:"\n            school-card__info__container school-card__info__container--c1\n          ",class:{ 'school-card__info__container--no-info': false }},[_c('div',[_c('v-tooltip',{attrs:{"bottom":"","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"school-card__info__container__title w-100",attrs:{"type":"button"},on:{"click":function($event){_vm.showTooltipPrice = !_vm.showTooltipPrice}}},'button',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("campus-details.price"))+" ")])]}}],null,false,3247493989),model:{value:(_vm.showTooltipPrice),callback:function ($$v) {_vm.showTooltipPrice=$$v},expression:"showTooltipPrice"}},[_c('span',[_vm._v(_vm._s(_vm.$t("campus-details.tooltip.price")))])]),_c('div',{staticClass:"\n                school-card__info__container__inner_div\n                school-card__info__container__inner_div--v3\n              ",class:_vm.campusPriceBackgroundColor(_vm.campus.payment.default)},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.campus.payment.default !== 'Sin Información'),expression:"campus.payment.default !== 'Sin Información'"}],staticClass:"school-card__info__icon",class:_vm.classPriceSelection(_vm.campus.payment.default),attrs:{"src":_vm.iconPriceSelection(_vm.campus.payment.default)}}),(_vm.campus.payment.default !== 'Sin Información')?_c('p',{staticClass:"school-card__info__container__text",class:_vm.isGuest ? '' : ''},[_vm._v(" "+_vm._s(_vm.campus.payment.default)+" ")]):_c('p',{staticClass:"school-card__info__container__text"},[_vm._v(" "+_vm._s(_vm.$t("map.card.no-info"))+" ")])])],1)]),_c('div',{staticClass:"school-card__info__container"},[_c('div',[_c('v-tooltip',{attrs:{"bottom":"","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"school-card__info__container__title w-100",attrs:{"type":"button"},on:{"click":function($event){_vm.showTooltipPerformance = !_vm.showTooltipPerformance}}},'button',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("campus-details.performance"))+" ")])]}}],null,false,3910260656),model:{value:(_vm.showTooltipPerformance),callback:function ($$v) {_vm.showTooltipPerformance=$$v},expression:"showTooltipPerformance"}},[_c('span',[_vm._v(_vm._s(_vm.$t("campus-details.tooltip.performance")))])]),_c('div',{staticClass:"\n                school-card__info__container__inner_div\n                school-card__info__container__inner_div--v2\n              ",class:_vm.campusPerformanceBackgroundColor(_vm.getPerformanceCategory)},[(_vm.getPerformanceCategory.value !== -1)?_c('img',{staticClass:"school-card__info__icon",class:_vm.isGuest ? '' : '',attrs:{"src":_vm.iconPerformanceSelection(_vm.getPerformanceCategory)}}):_vm._e(),_c('div',{staticClass:"school-card__info__container__text",class:{
                  'school-card__info__container__text--no-info':
                    _vm.getPerformanceCategory.value === -1,
                }},[_c('span',[_vm._v(" "+_vm._s(_vm.getPerformanceName(_vm.getPerformanceCategory))+" ")])])])],1)]),_c('div',{staticClass:"\n            school-card__info__container school-card__info__container--c3\n          ",class:{ 'school-card__info__container--no-info': !_vm.distance }},[_c('div',[_c('v-tooltip',{attrs:{"bottom":"","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({staticClass:"school-card__info__container__title w-100",attrs:{"type":"button"},on:{"click":function($event){_vm.showTooltipDistance = !_vm.showTooltipDistance}}},'button',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("campus-details.distance-km"))+" ")])]}}],null,false,262554560),model:{value:(_vm.showTooltipDistance),callback:function ($$v) {_vm.showTooltipDistance=$$v},expression:"showTooltipDistance"}},[_c('span',[_vm._v(_vm._s(_vm.$t("campus-details.tooltip.distance-km")))])]),_c('div',{staticClass:"\n                school-card__info__container__inner_div\n                school-card__info__container__inner_div--v3\n              ",class:_vm.campusCarDistanceBackgroundColor(_vm.distance)},[_c('img',{staticClass:"\n                  school-card__info__icon school-card__info__icon--distance\n                ",attrs:{"src":_vm.iconDistanceSelection(_vm.distance)}}),_c('div',[_c('p',{staticClass:"school-card__info__container__text"},[_vm._v(" "+_vm._s(_vm.distance)+" ")])])])],1)]),_c('div',{staticClass:"\n            school-card__info__container school-card__info__container--c4\n            mr-2\n          ",class:{ 'school-card__info__container--no-info': true }},[_c('div',[_c('button',{staticClass:"\n                school-card__info__container__title\n                school-card__info__container__title--v2\n                w-100\n              "},[_vm._v(" "+_vm._s(_vm.$t("campus-details.admision"))+" ")]),(_vm.isInFavorites)?_c('div',{staticClass:"\n                school-card__info__container__inner_div\n                school-card__info__container__inner_div--v2\n              ",style:(_vm.campusRatexBackgroundColor(_vm.ratex))},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasRatex()),expression:"hasRatex()"}],staticClass:"school-card__info__icon",attrs:{"src":_vm.iconRatexSelection(_vm.ratex)}}),(_vm.hasRatex())?_c('p',{staticClass:"school-card__info__container__text",class:[
                  { 'school-card__info__container--no-info': true },
                  _vm.isGuest ? 'school-card__info__container__text--guest' : '' ]},[_vm._v(" "+_vm._s(_vm.getRatexConfidence(_vm.ratex))+" ")]):_c('p',{staticClass:"\n                  school-card__info__container__text\n                  school-card__info__container__text--no-info\n                ",class:[
                  { 'school-card__info__container--no-info': true },
                  _vm.isGuest ? 'school-card__info__container__text--guest' : '' ]},[(_vm.getIsSAE)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("campus-details.admision").toUpperCase())+" "+_vm._s(_vm.$t("campus-details.text-sae").toUpperCase())+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("campus-details.no-ratex").toUpperCase())+" ")])])]):_c('div',{staticClass:"\n                school-card__info__container__inner_div\n                school-card__info__container__inner_div--v2\n              ",style:(_vm.campusRatexBackgroundColor(_vm.reratex))},[(_vm.ratexLoader && !_vm.isGuest)?_c('div',[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_vm._e(),(!_vm.ratexLoader || _vm.isGuest)?_c('div',{staticClass:"d-flex align-center justify-center flex-column"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasRatex()),expression:"hasRatex()"}],staticClass:"school-card__info__icon",attrs:{"src":_vm.iconRatexSelection(_vm.reratex)}}),(_vm.hasRatex() && !_vm.isGuest)?_c('p',{staticClass:"\n                    school-card__info__container__text\n                    school-card__info__container__text--ratex\n                  ",class:[
                    _vm.isGuest
                      ? 'school-card__info__container__text--guest'
                      : '' ]},[_vm._v(" "+_vm._s(_vm.getRatexConfidence(this.reratex))+" ")]):_vm._e(),(_vm.isGuest || !_vm.hasRatex())?_c('p',{staticClass:"\n                    school-card__info__container__text\n                    school-card__info__container__text--no-info\n                  ",class:[{ 'school-card__info__container--no-info': true }]},[(_vm.getIsSAE)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("campus-details.admision").toUpperCase())+" "+_vm._s(_vm.$t("campus-details.text-sae").toUpperCase())+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("campus-details.no-ratex").toUpperCase())+" ")])]):_vm._e()]):_vm._e()])])])]):_vm._e()]),(_vm.isGuest)?_c('div',{staticClass:"school-card__guest__popup"},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/icons/exclamation-circle.svg"),"width":"25px"}}),_c('p',{staticClass:"school-card__guest__popup__text"},[_c('button',{directives:[{name:"t",rawName:"v-t",value:('campus-details.card-popup-guest.login'),expression:"'campus-details.card-popup-guest.login'"}],staticClass:"mr-1",attrs:{"type":"button"},on:{"click":_vm.toLogin}}),_vm._v(" "+_vm._s(_vm.$t("campus-details.card-popup-guest.text"))+" ")])]):_vm._e(),_c('div',{staticClass:"school-card__show__profile",class:{
        'school-card__show__profile--blur': !_vm.campus.grades_info.grade_min,
      },on:{"click":function($event){return _vm.explore(true)}}},[_c('div',{staticClass:"school-card__profile__container"},[_c('div',{staticClass:"school-card__show__profile__text mr-1"},[_vm._v(" "+_vm._s(_vm.$t("map.card.show-profile"))+" ")]),_c('div',{staticClass:"school-card__show__profile__icon"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.campus.multimedia_options.has_tour),expression:"campus.multimedia_options.has_tour"}],staticClass:"school-card__info__icon mr-1",attrs:{"src":require('@/assets/iconsChile/campusDetail/360-tour.svg')}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.campus.multimedia_options.has_tour &&
              _vm.campus.multimedia_options.has_drone_flight
            ),expression:"\n              campus.multimedia_options.has_tour &&\n              campus.multimedia_options.has_drone_flight\n            "}],staticClass:"school-card__show__profile__line"},[_vm._v(" | ")]),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.campus.multimedia_options.has_drone_flight),expression:"campus.multimedia_options.has_drone_flight"}],staticClass:"school-card__info__icon ml-1",attrs:{"src":require('@/assets/iconsChile/campusDetail/drone.svg'),"width":"28"}})])])]),_c('v-dialog',{attrs:{"content-class":"elevation-0","overlay-color":"transparent","max-width":"400"},model:{value:(_vm.showLoginDialog),callback:function ($$v) {_vm.showLoginDialog=$$v},expression:"showLoginDialog"}},[_c('WarninigModal',{attrs:{"hasFavorites":_vm.thereAreFavorites,"inSchoolCard":true},on:{"close":_vm.closeWarningModal,"go-register":_vm.signUp}})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }