<template>
  <section class="full-width d-flex flex-column bg-white align-center py-10">
    <div class="w-full feedback-max-width feedback-padding-x">
      <div class="w-full d-flex items-start flex-column mb-2">
        <h1
          v-t="'feedback.indications.title'"
          class="feedback-title-bg-white feedback-bg-gray"
        />
      </div>

      <p v-if="treatment === 1" v-t="'feedback.indications.description'" class="feedback-end__text mt-5" />
      <v-timeline class="mt-5" align-top dense>
        <v-timeline-item v-for="(text, index) in timeLine" :key="index" color="#15E3CE" small>
          <div class="feedback-important-dates" v-html="text"></div>
        </v-timeline-item>
      </v-timeline>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeedbackImportantDates',
  props: {
    treatment: {
      type: Number,
      required: true,
    },
  },
  computed: {
    timeLine() {
      return this.$t('feedback.indications.time_line');
    },
  },
};
</script>
