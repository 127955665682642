<template>
  <div>
    <v-tooltip bottom content-class="custom-tooltip">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="panels__tooltip panels__tooltip--right"
          icon
          dark
          small
          color="#1E2D4C"
          style="margin-top: -4px !important"
        >
          <v-icon dark> mdi-information </v-icon>
        </v-btn>
      </template>
      <span v-t="'campus-details.tooltip.performance'" />
    </v-tooltip>
    <div class="fill-width performance">
      <div v-if="showBasicBtn() || showMiddleBtn()" class="panel_container_row">
        <button
          v-if="showBasicBtn()"
          :class="
            this.current_level == 'basica'
              ? 'panel_button__gradient panel_button__gradient--title'
              : 'panel_button__gradient--white panel_button__gradient--white--title'
          "
          v-t="$t('campus-details.current_performance.basic_btn')"
          @click="choose_level('basica')"
        />
        <button
          v-if="showMiddleBtn()"
          :class="
            this.current_level == 'media'
              ? 'panel_button__gradient panel_button__gradient--title'
              : 'panel_button__gradient--white panel_button__gradient--white--title'
          "
          v-t="$t('campus-details.current_performance.media_btn')"
          @click="choose_level('media')"
        />
      </div>

      <div class="panel_container_row">
        <img :src="isActive(1)" class="panel_container_row__first" />
        <img :src="isActive(2)" class="panel_container_row__second" />
        <img :src="isActive(3)" class="panel_container_row__third" />
        <img :src="isActive(4)" class="panel_container_row__fourth" />
      </div>

      <section class="campus-detail__panels__container ml-0">
        <template>
          <p class="campus-detail__panels__title text-left mb-2">
            {{ $t("campus-details.panels.categories") }}
          </p>

          <p class="campus-detail__panels__title text-left mb-2 font-weight-medium">
            {{ $t("campus-details.panels.evaluation") }}
          </p>

          <div v-if="false" class="fill-width performance">
            <div
              class="graph_panel__v1 panel_container_row--no-margin panel_container_row--no-margin--top panel_button__color__turqoise"
            >
              <img
                :src="require('@/assets/iconsChile/campusDetail/performance-high.svg')"
                class="panel_container_row__abs_img panel_container_row__abs_img--v2"
              />

              <div
                class="graph_panel__v1 graph_panel__fix_width panel_container_row--text panel_container_row--text__white"
              >
                {{ $t("campus-details.panels.high") }}
              </div>

              <div class="graph_panel__v1 panel_container_row panel_container_row__white">
                <img
                  :src="require('@/assets/iconsChile/campusDetail/performance-high.svg')"
                  class=""
                />
                <img
                  :src="require('@/assets/iconsChile/campusDetail/performance-high.svg')"
                  class=""
                />
              </div>

              <div
                class="graph_panel__v1 panel_container panel_container_row--text panel_container_row--text__white"
              >
                10
              </div>
            </div>

            <div class="graph_panel__v1 panel_container_row--no-margin panel_button__color__mid">
              <img
                :src="require('@/assets/iconsChile/campusDetail/performance-middle.svg')"
                class="panel_container_row__abs_img panel_container_row__abs_img--v2"
              />

              <div
                class="graph_panel__v1 graph_panel__fix_width panel_container_row--text panel_container_row--text__medium panel_container_row--text__white"
              >
                {{ $t("campus-details.panels.mid") }}
              </div>

              <div class="graph_panel__v1 panel_container_row panel_container_row__white">
                <img
                  :src="require('@/assets/iconsChile/campusDetail/performance-middle.svg')"
                  class=""
                />
                <img
                  :src="require('@/assets/iconsChile/campusDetail/performance-middle.svg')"
                  class=""
                />
              </div>

              <div
                class="graph_panel__v1 panel_container panel_container_row--text panel_container_row--text__white"
              >
                10
              </div>
            </div>

            <div class="graph_panel__v1 panel_container_row--no-margin panel_button__color__midlow">
              <img
                :src="require('@/assets/iconsChile/campusDetail/performance-middle-low.svg')"
                class="panel_container_row__abs_img panel_container_row__abs_img--v2"
              />

              <div
                class="graph_panel__v1 graph_panel__fix_width panel_container_row--text panel_container_row--text__white"
              >
                {{ $t("campus-details.panels.midlow") }}
              </div>

              <div class="graph_panel__v1 panel_container_row panel_container_row__white">
                <img
                  :src="require('@/assets/iconsChile/campusDetail/performance-middle-low.svg')"
                />
                <img
                  :src="require('@/assets/iconsChile/campusDetail/performance-middle-low.svg')"
                />
              </div>

              <div
                class="graph_panel__v1 panel_container panel_container_row--text panel_container_row--text__white"
              >
                10
              </div>
            </div>

            <div
              class="graph_panel__v1 panel_container_row--no-margin panel_container_row--no-margin--bottom panel_button__color__low"
            >
              <img
                :src="require('@/assets/iconsChile/campusDetail/performance-insufficient.svg')"
                class="panel_container_row__abs_img panel_container_row__abs_img--v2"
              />

              <div
                class="graph_panel__v1 graph_panel__fix_width panel_container_row--text panel_container_row--text__white"
              >
                {{ $t("campus-details.panels.low") }}
              </div>

              <div class="graph_panel__v1 panel_container_row panel_container_row__white">
                <img
                  :src="require('@/assets/iconsChile/campusDetail/performance-insufficient.svg')"
                />
                <img
                  :src="require('@/assets/iconsChile/campusDetail/performance-insufficient.svg')"
                />
              </div>

              <div
                class="graph_panel__v1 panel_container panel_container_row--text panel_container_row--text__white"
              >
                10
              </div>
            </div>
          </div>
        </template>
      </section>
      <SimcePanels />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SimcePanels from "@/components/explorer/general/campus_detail/panels/SimcePanels.vue";
export default {
  name: "Performance",
  components: {
    SimcePanels,
  },
  data() {
    return {
      levels: ["A+", "A", "B", "C-D"],
      current_level: null,
    };
  },
  mounted() {
    this.current_level = "basica";
    if (this.campusDetail.performance.length === 1) {
      const stageId = this.campusDetail.performance[0].qualitycategory_label.stage_label;
      if (stageId === 3) {
        this.current_level = "media";
      }
      if (stageId === 2) {
        this.current_level = "basica";
      }
    }
  },
  methods: {
    iconPerformanceSelection(campusPerformance) {
      let imgFile = "";
      if (campusPerformance === 1) {
        imgFile = "performance-high.svg";
      } else if (campusPerformance === 2) {
        imgFile = "performance-middle.svg";
      } else if (campusPerformance === 3) {
        imgFile = "performance-middle-low.svg";
      } else if (campusPerformance === 4) {
        imgFile = "performance-insufficient.svg";
      } else {
        imgFile = "performance-insufficient.svg";
      }
      // eslint-disable-next-line
      const images = require("@/assets/iconsChile/campusDetail/" + imgFile);
      return images;
    },
    opacityLevel(i) {
      let style = "";
      if (i !== this.level) {
        style = {
          // eslint-disable-next-line
          opacity: 0.3,
        };
      }
      return style;
    },
    isSelected(i) {
      let style = "";
      if (i === this.level) {
        style = {
          "font-weight": "600",
          // eslint-disable-next-line
          color: "black",
        };
      }
      return style;
    },
    choose_level(level) {
      this.current_level = level;
    },
    isActive(index) {
      if (this.campusDetail.performance.length > 0) {
        if (this.current_level === "basica") {
          const perfomanceSet = this.campusDetail.performance.find(
            (performance) => performance.qualitycategory_label.stage_label === 2,
          );
          if (index === 1) {
            if (perfomanceSet === undefined) {
              return require("@/assets/iconsChile/campusDetail/performance_chart_high_disabled.svg");
            }
            if (perfomanceSet.qualitycategory_level_label.id === 4) {
              return require("@/assets/iconsChile/campusDetail/performance_chart_high_active.svg");
            }
            return require("@/assets/iconsChile/campusDetail/performance_chart_high_disabled.svg");
          }
          if (index === 2) {
            if (perfomanceSet === undefined) {
              return require("@/assets/iconsChile/campusDetail/performance_chart_mid_disabled.svg");
            }
            if (perfomanceSet.qualitycategory_level_label.id === 3) {
              return require("@/assets/iconsChile/campusDetail/performance_chart_mid_active.svg");
            }
            return require("@/assets/iconsChile/campusDetail/performance_chart_mid_disabled.svg");
          }
          if (index === 3) {
            if (perfomanceSet === undefined) {
              return require("@/assets/iconsChile/campusDetail/performance_chart_midlow_disabled.svg");
            }
            if (perfomanceSet.qualitycategory_level_label.id === 2) {
              return require("@/assets/iconsChile/campusDetail/performance_chart_midlow_active.svg");
            }
            return require("@/assets/iconsChile/campusDetail/performance_chart_midlow_disabled.svg");
          }
          if (index === 4) {
            if (perfomanceSet === undefined) {
              return require("@/assets/iconsChile/campusDetail/performance_chart_low_disabled.svg");
            }
            if (perfomanceSet.qualitycategory_level_label.id === 1) {
              return require("@/assets/iconsChile/campusDetail/performance_chart_low_active.svg");
            }
            return require("@/assets/iconsChile/campusDetail/performance_chart_low_disabled.svg");
          }
        }
        if (this.current_level === "media") {
          const perfomanceSet = this.campusDetail.performance.find(
            (performance) => performance.qualitycategory_label.stage_label === 3,
          );
          if (index === 1) {
            if (perfomanceSet === undefined) {
              return require("@/assets/iconsChile/campusDetail/performance_chart_high_disabled.svg");
            }
            if (perfomanceSet.qualitycategory_level_label.id === 4) {
              return require("@/assets/iconsChile/campusDetail/performance_chart_high_active.svg");
            }
            return require("@/assets/iconsChile/campusDetail/performance_chart_high_disabled.svg");
          }
          if (index === 2) {
            if (perfomanceSet === undefined) {
              return require("@/assets/iconsChile/campusDetail/performance_chart_mid_disabled.svg");
            }
            if (perfomanceSet.qualitycategory_level_label.id === 3) {
              return require("@/assets/iconsChile/campusDetail/performance_chart_mid_active.svg");
            }
            return require("@/assets/iconsChile/campusDetail/performance_chart_mid_disabled.svg");
          }
          if (index === 3) {
            if (perfomanceSet === undefined) {
              return require("@/assets/iconsChile/campusDetail/performance_chart_midlow_disabled.svg");
            }
            if (perfomanceSet.qualitycategory_level_label.id === 2) {
              return require("@/assets/iconsChile/campusDetail/performance_chart_midlow_active.svg");
            }
            return require("@/assets/iconsChile/campusDetail/performance_chart_midlow_disabled.svg");
          }
          if (index === 4) {
            if (perfomanceSet === undefined) {
              return require("@/assets/iconsChile/campusDetail/performance_chart_low_disabled.svg");
            }
            if (perfomanceSet.qualitycategory_level_label.id === 1) {
              return require("@/assets/iconsChile/campusDetail/performance_chart_low_active.svg");
            }
            return require("@/assets/iconsChile/campusDetail/performance_chart_low_disabled.svg");
          }
        }
      }
      return null;
    },
    showBasicBtn() {
      const perfomanceSet = this.campusDetail.performance.find(
        (performance) => performance.qualitycategory_label.stage_label === 2,
      );
      if (perfomanceSet !== undefined) {
        return true;
      }
      return false;
    },
    showMiddleBtn() {
      const perfomanceSet = this.campusDetail.performance.find(
        (performance) => performance.qualitycategory_label.stage_label === 3,
      );
      if (perfomanceSet !== undefined) {
        return true;
      }
      return false;
    },
  },
  computed: {
    ...mapGetters({
      campusDetail: "institutions/campusDetails",
      currentStudent: "authentication/currentStudent",
      isGuest: "authentication/isGuest",
      gradeOptions: "options/grades",
    }),
  },
};
</script>
