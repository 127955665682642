<template>
  <div class="feedback">
    <!-- feedback -->
    <section v-if="!loading" class="w-full h-full" style="background-color: #3684ef">
      <div class="bg-custom">
        <div class="bg-custom--image" :class="{ feedback_intro: CONFIG.feedback === 'Palmira' }">
          <Introduction />
          <Postulation />
        </div>
      </div>
      <CloseFeedback />
    </section>
    <!-- modal -->
    <!-- loading -->
    <LoadingPage v-if="loading" style="width: 360px !important" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Introduction from "../../components/feedback/Introduction.vue";
import CloseFeedback from "../../components/feedback/CloseFeedback.vue";
import LoadingPage from "../../components/feedback/LoadingPage.vue";
import Postulation from "../../components/feedback/Postulation.vue";
import CONFIG from "../../config";

export default {
  name: "Feedback",
  components: {
    Introduction,
    CloseFeedback,
    Postulation,
    LoadingPage,
  },
  setup() {
    const { showModal } = this;
    return {
      showModal,
    };
  },
  mounted() {
    if (CONFIG.feedbackType === "Recommendations") {
      this.retrieveRecommendation();
      this.mixpanel.track("open_feedback", {
        distinct_id: this.legalGuardianUUID,
        env: CONFIG.environment,
      });
    }
    if (CONFIG.feedbackType === "Results") {
      this.retrieveResults();
      this.mixpanel.track("open_feedback", {
        distinct_id: this.legalGuardianUUID,
        env: CONFIG.environment,
      });
    }
  },
  data() {
    return {
      CONFIG,
    };
  },
  computed: {
    ...mapGetters({
      legalGuardianUUID: "authentication/legalGuardianUUID",
      showModal: "authentication/legalGuardianUUID",
      loading: "feedback/loading",
    }),
  },
  methods: {
    ...mapActions({
      retrieveRecommendation: "feedback/retrieveRecommendation",
      retrieveResults: "feedback/retrieveResults",
    }),
  },
};
</script>
