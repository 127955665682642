<template>
  <v-container class="px-12 py-6 detail">
    <v-row no-gutters>
      <div class="detail__title">
        <v-row no-gutters class="mb-2">
          <v-col cols="11" class="detail__title-name">
            {{ $t("dashboard.applications.detail-modal.title", [currentStudent.name]) }}
          </v-col>
          <v-col cols="1">
            <!-- Close icon -->
            <img
              style="cursor: pointer"
              width="20"
              @click="closeModal"
              src="@/assets/dashboard/icons/close.svg"
            />
          </v-col>
        </v-row>
      </div>
      <div class="mt-5">
        <p class="text detail__subtitle">
          {{ $t("dashboard.applications.detail-modal.subtitle") }}
        </p>
        <v-row
          no-gutters
          class="mt-5"
          v-for="(school, i) in application.appranking_application"
          :key="i"
        >
          <v-col cols="10.5">
            <v-row v-if="true" no-gutters class="pa-2 detail__list">
              <v-col cols="2">
                <v-row no-gutters class="detail__list-number">
                  <v-col cols="8" class="mr-0 pr-0 detail__list-number_text">
                    N°{{ school.application_program_ranking }}
                  </v-col>
                  <v-col cols="4" class="ml-0 pl-0">
                    <v-divider light vertical />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6" class="detail__list-school">
                {{ school.program.campus_code }}
              </v-col>
              <v-col cols="4" class="detail__list-fav" v-if="false">
                <v-avatar size="50" tile style="width: 80px !important">
                  <v-img
                    style="border-radius: 3px"
                    :src="school.program.card_image || require('@/assets/icons/school.svg')"
                  >
                  </v-img>
                </v-avatar>
                <div class="detail__list-fav_container">
                  <v-btn
                    icon
                    disabled
                    class="over-picture favorite-button--opaque detail__list-fav_btn"
                  >
                    <v-img src="@/assets/iconsChile/campusDetail/button2.png"> </v-img>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="pa-2 detail__info">
              <p class="text detail__info-text">
                {{ school.program.shift_label.shift_name }} |
                {{ school.program.gender.gender_name }}
              </p>
            </v-row>
          </v-col>
          <v-col v-if="false" cols="1" class="ml-1 detail__arrows">
            <img
              width="20"
              class="detail__arrows-img_top"
              src="@/assets/dashboard/icons/disabled-arrow.svg"
            />
            <img
              width="20"
              class="detail__arrows-img_bottom"
              src="@/assets/dashboard/icons/disabled-arrow.svg"
            />
          </v-col>
        </v-row>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
// import FavoriteButton from '@/components/explorer/favorite/FavoriteButton.vue';

export default {
  name: "DetailModal",
  components: {
    // FavoriteButton,
  },
  props: {
    application: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentStudent: "authentication/currentStudent",
    }),
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
