<template>
  <div>
    <v-tooltip v-model="showTooltip" class="" bottom content-class="custom-tooltip">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="panels__tooltip panels__tooltip--right"
          icon
          dark
          small
          color="#1E2D4C"
          style="margin-top: -4px !important"
        >
          <v-icon dark> mdi-information </v-icon>
        </v-btn>
      </template>
      <span v-t="'campus-details.tooltip.infrastructureInfo'" />
    </v-tooltip>

    <section class="campus-detail__panels__container">
      <div
        v-for="(label, index) in infrastructures"
        :key="index"
      >
        <p 
          class="campus-detail__panels__title text-left mb-2 font-weight-medium bullet-point"
          v-if="isOther(label)"
        >
          {{ getLabel(label) }}
        </p>
      </div>
      <div  v-if="otherInfraesttructure">
        <p
          class="campus-detail__panels__title text-left mb-2 font-weight-medium bullet-point"
          v-for="(label, index) in otherInfraesttructure"
          :key="index"
        >
          {{ getOtherLabel(label) }}
        </p>
      </div>
      <MessageInPanel from="infrastructure-panel" />
    </section>
  </div>
</template>
<script>
import MessageInPanel from "./MessageInPanel.vue";

export default {
  name: "Infrastructure",
  components: {
    MessageInPanel,
  },
  data() {
    return {
      showTooltip: false,
      otherInfraesttructure: null
    };
  },
  props: {
    infrastructures: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getLabel(label) {
      if (label.infra_label !== 'Otro') {
        return label.infra_label;
      }
    },
    isOther(label) {
      if (label.infra_label === 'Otro') {
        const otherInfra = label.descrip.split(',');
        if (otherInfra.length === 1) {
          this.otherInfraesttructure = otherInfra;
          return false;
        } else {
          this.otherInfraesttructure = otherInfra;
          return false;
        }
      } else {
        return true;
      }
    },
    getOtherLabel(label) {
      const trimedLabel = label.trim();
      return trimedLabel.charAt(0).toUpperCase() + trimedLabel.slice(1).toLowerCase();
    }
  }
};
</script>
