<template>
  <div class="tools-container">
    <div class="single-tool-container">
      <div @click="goTo('explorer')">
        <img
          class="single-tools-icon"
          src="../../assets/iconsNewHaven/dashboard/tools/compass.svg"
        />
        <span>Explora</span>
      </div>
    </div>
    <div class="single-tool-container">
      <div @click="goTo('favorites')">
        <img
          style="width: 25px; height: 25px"
          class="single-tools-icon ml-2"
          src="@/assets/iconsChile/campusDetail/button2.svg"
        />
        <span>Tu lista</span>
      </div>
    </div>
    <div class="single-tool-container">
      <div @click="goTo('postulate')">
        <img
          class="single-tools-icon"
          src="../../assets/iconsNewHaven/dashboard/tools/simulate.svg"
        />
        <span>Admisiones Históricas</span>
      </div>
    </div>
    <div class="single-tool-container">
      <div @click="externalGoTo('https://www.sistemadeadmisionescolar.cl/')">
        <img
          class="single-tools-icon"
          style="width: 30px; height: 30px"
          src="../../assets/iconsNewHaven/dashboard/tools/admission.svg"
        />
        <span>Admisión por SAE</span>
      </div>
    </div>

    <!--<div class="single-tool-container">
        <div class="single-tool-content">
          <span>Extra</span>
        </div>
      </div>-->
    <!-- Tool Components -->
    <!--  -->

    <!-- pair one -->
    <!--<div class="tools-pair">
           <div class="tools-tool tools-tool-1 tools-pair-1" @click="goTo('explorer')">
            <img class="tools-icon-1"
            src="../../assets/iconsNewHaven/dashboard/tools/compass.svg" alt="" />
            <p style="top: 30px;" class="text-white tools-text">Explora</p>
            </div>
           <div class="tools-tool tools-tool-1 tools-pair-2" @click="goTo('favorites')">
            <img class="tools-icon-1"
            src="../../assets/iconsNewHaven/dashboard/tools/heart.png" alt="" />
            <p style="top: 30px;" class="text-white tools-text">Tu lista</p>
            </div>-->
    <!-- Image Gallery -->
    <!--<div class="tools-tool tools-tool-1 tools-pair-1">
              <img class="tools-icon-1" src="../../assets/dashboard/icons/galery_icon.svg" alt=""/>
                <p class="text-white tools-text">Editar galería de imágenes</p>
            </div>-->
    <!-- Agenda -->
    <!--div class="tools-tool tools-tool-1 tools-pair-2">
            <img class="tools-icon-1"
            src="../../assets/dashboard/icons/agend_school_community_icon.svg" alt="" />
            <p style="top: 30px;" class="text-white tools-text">Reúnete con la comunidad escolar</p>
            </!--div>-->
    <!--</div>-->
    <!--<br />-->
    <!-- pair two -->
    <!--<div class="tools-pair">
            <div class="tools-tool tools-tool-1 " @click="goTo('postulate')">
            <img class="tools-icon-1"
            src="../../assets/iconsNewHaven/dashboard/tools/simulate.svg" alt="" />
            <p style="top: 30px;" class="text-white tools-text">Simular postulaciones</p>
            </div>-->
    <!-- SIGE -->
    <!--div class="tools-tool" @click="clickSige()">
                <img class="tools-icon-2" src="../../assets/dashboard/icons/sige_icon.svg" alt="" />
                <p class="text-blue-1 tools-text">Edita contenido de tu perfil</p>
            </div>
            <v-dialog        content-class="elevation-0 mx-12"
                overlay-color="black" v-model="toggleSigePopup">
                <Sige class="zindex100"
                v-if="toggleSigePopup"
                :sigeText="'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum ex pariatur accusamus omnis sapiente eaque doloremque suscipit ipsum nam quidem aspernatur necessitatibus, laudantium soluta vitae? Ab voluptatem quaerat non necessitatibus.'"
                @closePopup="closePopup"
                />
            </v-dialog>-->
    <!-- School Community -->
    <!--<div class="tools-tool" @click="clickSchoolCommunity()">
                <img
                class="tools-icon-2"
                src="../../assets/dashboard/icons/school_community_icon.svg"
                alt=""
                />
                <p class="text-blue-1 tools-text">Ir a la comunidad escolar</p>
            </div>
            <v-dialog        content-class="elevation-0 mx-12"
                overlay-color="black" v-model="toggleSchoolCommunity">
                <SchoolCommunity
                v-if="toggleSchoolCommunity"
                :schoolcommunityText="'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum ex pariatur accusamus omnis sapiente eaque doloremque suscipit ipsum nam quidem aspernatur necessitatibus, laudantium soluta vitae? Ab voluptatem quaerat non necessitatibus.'"
                @closePopup="closePopup"
                />
            </v-dialog>-->
    <!--</div>-->
    <!--<br/> -->
  </div>
</template>

<script>
import Sige from "./modals/Sige.vue";
import SchoolCommunity from "./modals/SchoolCommunity.vue";

export default {
  name: "Tools2",
  components: { Sige, SchoolCommunity },
  data() {
    return {
      toggleSigePopup: false,
      toggleSchoolCommunity: false,
    };
  },
  methods: {
    externalGoTo(page) {
      window.open(page, "_blank").focus();
    },
    goTo(page) {
      this.$router.push(page);
    },
    clickSige() {
      this.toggleSigePopup = true;
    },
    clickSchoolCommunity() {
      this.toggleSchoolCommunity = true;
    },
    closePopup() {
      this.toggleSigePopup = false;
      this.toggleSchoolCommunity = false;
    },
  },
  mounted() {},
  // data() { return { components_div: ['sige'] }; },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url("https://fonts.googleapis.com/css?family=Montserrat");

.text-white {
  color: white;
}
.text-blue-1 {
  color: #2b5bff;
}
.zindex100 {
  z-index: 100 !important;
}
.tools-container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}
.single-tool-container {
  width: 100%;
  height: 100%;
  margin: auto;
  cursor: pointer;
}

.single-tool-container > div {
  border-radius: 5px;
  background-color: #426cfb;
  height: 100%;
  max-height: 100%;
  width: 100%;
  text-align: right;
  padding: 20px 5px 10px 10px;
  display: flex;
  flex-direction: row;
  position: relative;
  flex-wrap: nowrap;
}
.single-tools-icon {
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  position: absolute;
  top: 5px;
  left: 0px;
}
.single-tool-container > div > span {
  text-align: left;
  align-self: flex-end;
  color: white;
}

.tools-pair {
  display: flex;
  gap: 7%;
}
.tools-title {
  position: relative;
  left: 18px;
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 12px;
  line-height: 22px;
  color: #0d22a8;
}
.tools-tool {
  width: 45%;
  height: 117px;
  background: #eef5ff;
  box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.tools-tool-1 {
  background: linear-gradient(180deg, #426cfb 0%, #9f81f3 139.26%);
}
.tools-text {
  font-family: "Roboto";
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  position: relative;
  top: 40px;
  left: 10px;
  width: 100px;
  text-align: justify;
}
.tools-icon-1 {
  position: relative;
  left: 20px;
  top: 15px;
}
.tools-icon-2 {
  position: relative;
  height: 14px;
  left: 20px;
  top: 15px;
}

.tools-tool:hover {
  width: 49%;
  height: 130px;
}
</style>
