<template>
  <div class="profile section-content">
    <div v-if="false">
      <div v-if="isGuest" class="profile__info">
        <strong>
          {{ $t("dashboard.profile.guest-info-title") }}
        </strong>
        {{ $t("dashboard.profile.guest-info-content") }}
      </div>
      <div v-if="isGuest" class="profile__info">
        <strong>
          {{ $t("dashboard.profile.guest-info-student-title") }}
        </strong>
        {{ $t("dashboard.profile.guest-info-student-content") }}
      </div>
    </div>
    <div>
      <general-flow-chart />
    </div>
    <div
      class="pb-2"
      style="background-color: #ffffff; border-radius: 4px 4px 4px 4px; padding-top: 15px"
    >
      <div class="profile__registration__steps">
        <div class="profile__registration__steps__div">
          <img
            v-if="!isGuest"
            src="@/assets/iconsNewHaven/dashboard/check.svg"
            style="margin-right: 5px"
          />
          <img v-else src="@/assets/dashboard/icons/disabled-check.svg" style="margin-right: 5px" />
          <h3
            class="profile__registration__steps__div__title"
            :class="{
              'profile__registration__steps__div__title--completed': !isGuest,
            }"
            v-t="'dashboard.profile.identification'"
          />
        </div>
        <div v-if="!isGuest">
          <div
            class="profile__registration__steps__complete"
            v-t="'dashboard.profile.edit'"
            @click="showIdentifactionModal"
          />
        </div>
      </div>
      <div class="profile__registration__steps">
        <div class="profile__registration__steps__div">
          <img
            v-if="!isGuest"
            src="@/assets/iconsNewHaven/dashboard/check.svg"
            style="margin-right: 5px"
          />
          <img v-else src="@/assets/dashboard/icons/disabled-check.svg" style="margin-right: 5px" />
          <h3
            class="profile__registration__steps__div__title"
            :class="{
              'profile__registration__steps__div__title--completed': !isGuest,
            }"
            v-t="'dashboard.profile.Location'"
          />
        </div>
        <div v-if="!isGuest">
          <div
            class="profile__registration__steps__complete"
            v-t="'dashboard.profile.edit'"
            @click="showLocationModal"
          />
          <div
            v-if="false"
            class="profile__registration__steps__complete"
            v-t="'dashboard.profile.complete'"
            @click="showLocationModal"
          />
        </div>
      </div>
      <div v-if="false" class="profile__registration__steps">
        <div class="profile__registration__steps__div">
          <img
            v-if="contactComplete && !isGuest"
            src="@/assets/iconsNewHaven/dashboard/check.svg"
            style="margin-right: 5px"
          />
          <img v-else src="@/assets/dashboard/icons/disabled-check.svg" style="margin-right: 5px" />
          <h3
            class="profile__registration__steps__div__title"
            :class="{
              'profile__registration__steps__div__title--completed': contactComplete && !isGuest,
            }"
            v-t="'dashboard.profile.Contact'"
          />
        </div>
        <div v-if="!isGuest">
          <div
            v-if="contactComplete"
            class="profile__registration__steps__complete"
            v-t="'dashboard.profile.edit'"
            @click="showContactModal"
          />
          <div
            v-else
            class="profile__registration__steps__complete"
            v-t="'dashboard.profile.complete'"
            @click="showContactModal"
          />
        </div>
      </div>
    </div>
    <div class="profile__students" :class="{ 'profile__students--guest': isGuest }">
      <div
        class="profile__students__div"
        :class="{ 'profile__students__div--underline': !isGuest }"
      >
        <div class="profile__students__div__header">
          <img
            v-if="!isGuest && students.length > 0"
            src="@/assets/iconsNewHaven/dashboard/check.svg"
            style="margin-right: 5px"
          />
          <img v-else src="@/assets/dashboard/icons/disabled-check.svg" style="margin-right: 5px" />
          <h3
            class="profile__students__div__header__title"
            :class="{
              'profile__students__div__header__title--completed': students.length > 0,
            }"
            v-t="'dashboard.profile.students'"
          />
        </div>
        <img
          v-if="!isGuest"
          src="@/assets/iconsNewHaven/dashboard/add.svg"
          class="profile__students__div__icon"
          @click="addStudent"
        />
      </div>
      <div
        class="profile__students__div profile__students__div--list"
        v-for="(student, index) in students"
        :key="index"
      >
        <div class="profile__students__div__wrap" @click="editStudent(student)">
          <div class="profile__students__div__header">
            <img
              v-if="true"
              src="@/assets/iconsNewHaven/dashboard/check.svg"
              style="margin-right: 5px"
            />
            <img
              v-else
              src="@/assets/dashboard/icons/disabled-check.svg"
              style="margin-right: 5px"
            />
            <h3
              class="profile__students__div__header__student"
              :class="{
                'profile__students__div__header__student--completed': true,
              }"
            >
              {{ student.name }}
            </h3>
          </div>
          <div v-if="false" class="profile__students__div__selected" style="margin-right: 35px" />
        </div>
        <v-btn
          class="profile__students__div__delete"
          type="button"
          icon
          color="red"
          @click="removeStudent(student)"
        >
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </div>
    </div>
    <!-- TODO HACER CUANDO TENGAMOS DATOS DE TUS ESTABLECIMIENTOS (NUEVO REGISTRO)
    <div
      class="profile__students
             profile__students--institutions"
      :style="{'padding-bottom: 0;': (schools_array).length === 0}"
    >
      <div class="profile__students__div profile__students__div--underline ">
        <div class="profile__students__div__wrap">
          <div class="profile__students__div__header">
            <img
              v-if="true"
              src="@/assets/dashboard/icons/check.svg"
              style="margin-right: 5px;"
            >
            <img
              v-else
              src="@/assets/dashboard/icons/disabled-check.svg"
              style="margin-right: 5px;"
            >
            <h3 class="profile__students__div__header__title
                      profile__students__div__header__title--institutions">
              Tus establecimientos
            </h3>
          </div>
          <img
            src="@/assets/dashboard/icons/purple-more.svg"
            class="profile__students__div__icon"
            @click="addStudent"
          />
        </div>
      </div>
      <div
        class="profile__students__div profile__students__div--list"
        v-for="school, index in (schools_array)" :key="index"
      >
        <div
          class="profile__students__div__wrap"
        >
          <div class="profile__students__div__header">
            <img
              v-if="true"
              src="@/assets/dashboard/icons/check.svg"
              style="margin-right: 5px;"
            >
            <img
              v-else
              src="@/assets/dashboard/icons/disabled-check.svg"
              style="margin-right: 5px;"
            >
            <h3 class="profile__students__div__header__student
                      profile__students__div__header__student--institutions">
              {{school.name}}
            </h3>
          </div>
          <div
            class="profile__students__div__selected"
            style="margin-right: 35px;"
          />
        </div>
      </div>
    </div>
    -->
    <v-dialog
      content-class="dashborad-modal-flow"
      overlay-color="transparent"
      v-model="showStudentRegisterModal"
      max-width="600px"
    >
      <register
        v-if="showStudentRegisterModal && step !== null && subStep !== null"
        :step="step"
        :sectionStep="subStep"
        @closeModal="closeModal"
      />
    </v-dialog>
    <v-dialog
      content-class="dashborad-modal-flow"
      overlay-color="transparent"
      v-model="showAddModal"
      max-width="380px"
    >
      <add-student @closeModal="closeModal" />
    </v-dialog>
    <v-dialog
      content-class="dashborad-modal-flow"
      overlay-color="transparent"
      v-model="showIdentificationModal"
      max-width="380px"
    >
      <add-identification-data @closeModal="closeIdentificationModal" />
    </v-dialog>
    <v-dialog
      content-class="dashborad-modal-flow"
      overlay-color="transparent"
      v-model="showEditLocation"
      max-width="380px"
    >
      <div v-if="loadingLite" style="height: 412px">
        <v-progress-circular indeterminate />
      </div>
      <div v-if="!loadingLite">
        <confirm-location class="pa-5" @locationInfo="setFastLocation" :editModal="true" />
        <div class="btn_container pa-5">
          <v-btn icon @click="closeModal">
            <img width="8" src="@/assets/iconsNewHaven/user_register/icons/blue-back.svg" />
          </v-btn>
          <base-button
            class="btn-continue"
            :class="{ 'btn-continue--active': location }"
            @click="setLocation"
            :disabled="!locationDone"
          >
            {{ $t("user_register.register_code.btn") }}
          </base-button>
        </div>
      </div>
    </v-dialog>
    <v-dialog
      content-class="dashborad-modal-flow"
      overlay-color="transparent"
      v-model="showEditStudents"
      max-width="380px"
    >
      <div v-if="students.length > 0" class="pa-5">
        <div class="user_register_container__grade-title">
          <h1
            v-t="'user_register.register_grade.edit_title'"
            class="user_register_container__grade-title__text"
          />
          <img src="@/assets/iconsNewHaven/user_register/icons/face.svg" />
        </div>
        <confirm-students
          @setStudents="setStudent"
          :editStudents="true"
          :idStudent="currentStudent.id"
          :editCurrentStudent="true"
          :oneStudent="studentToEdit"
          :cleanGrades="cleanGrades"
          :studentSet="true"
        />
        <div class="btn_container pt-3">
          <v-btn icon @click="closeModal">
            <img width="8" src="@/assets/iconsNewHaven/user_register/icons/blue-back.svg" />
          </v-btn>
          <base-button
            class="btn-continue"
            @click="setStudentForm"
            :disabled="!studentsDone"
            :class="{ 'btn-continue--active': studentsDone }"
          >
            {{ $t("user_register.register_code.btn") }}
          </base-button>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Register from "../student_register/Register.vue";
import GeneralFlowChart from "./modals/utils/GeneralFlowChart.vue";
import AddStudent from "../shared/AddStudent.vue";
import AddIdentificationData from "../shared/AddIdentificationData.vue";
import ConfirmLocation from "../authentication/user_register/steps/ConfirmLocation.vue";
import ConfirmStudents from "../authentication/user_register/steps/ConfirmStudents.vue";
import CONFIG from "@/config";
import BaseButton from "@/components/explorer/base/BaseButton.vue";

export default {
  name: "Profile",
  components: {
    Register,
    GeneralFlowChart,
    AddStudent,
    AddIdentificationData,
    ConfirmLocation,
    BaseButton,
    ConfirmStudents,
  },
  data() {
    return {
      showingDetail: null,
      showStudentRegisterModal: false,
      step: null,
      subStep: null,
      CONFIG,
      showAddModal: false,
      showIdentificationModal: false,
      showEditLocation: false,
      location: null,
      locationDone: false,
      studentsDone: false,
      showEditStudents: false,
      studentsForm: null,
      studentToEdit: null,
      cleanGrades: false,
    };
  },
  props: {
    showStudentModals: {
      type: Boolean,
      default: false,
    },
    role: {
      type: String,
      default: "legalguardian",
    },
  },
  computed: {
    ...mapGetters({
      students: "authentication/students",
      identificationComplete: "userRegister/identificationComplete",
      locationComplete: "userRegister/locationComplete",
      isGuest: "authentication/isGuest",
      currentStep: "userRegister/currentStep",
      contactComplete: "userRegister/contactComplete",
      cornerBounds: "institutions/cornerBounds",
      legalGuardianUUID: "authentication/legalGuardianUUID",
      token: "authentication/token",
      currentStudent: "authentication/currentStudent",
      loadingLite: "userRegister/loadingLite",
    }),
  },
  methods: {
    ...mapActions({
      resetNewStudentStore: "newStudentRegister/resetStore",
      getStudents: "authentication/getStudents",
      setStudentsProfile: "newStudentRegister/setStudents",
      getStudentData: "newStudentRegister/getStudentData",
      setSteps: "newStudentRegister/setSteps",
      setCurrentStep: "userRegister/setCurrentStep",
      setCurrentStudent: "authentication/setCurrentStudent",
      deleteStudent: "authentication/deleteStudent",
      retrieveFavorites: "institutions/retrieveFavorites",
      retrieveExploredCampuses: "institutions/retrieveExploredCampuses",
      retrieveExploredSchoolCard: "institutions/retrieveExploredSchoolCard",
      retrieveCampuses: "institutions/retrieveCampuses",
      raiseError: "snackbar/error",
      setFastRegisterLocation: "userRegister/setFastRegisterLocation",
      getUserDetails: "authentication/getUserDetails",
    }),
    setLocation() {
      const { location } = this;
      this.setFastRegisterLocation({ location }).then(() => {
        this.showEditLocation = false;
      });
      this.getUserDetails({
        legalGuardianUUID: this.legalGuardianUUID,
        token: this.token,
        location: location.addressDetail,
      });
    },
    setStudentForm() {
      const { studentsForm } = this;
      const setStudent = this.students.find((student) => student.id === studentsForm[0].id);
      if (studentsForm[0].gradeId === null) {
        if (this.currentStudent.current_enrollment !== null) {
          studentsForm[0].gradeId = setStudent.current_enrollment.grade_label;
        }
      }
      if (studentsForm[0].gradeInterest === null) {
        if (setStudent.grades.length > 0) {
          studentsForm[0].gradeInterest = setStudent.grades;
        }
      }
      if (studentsForm[0].stagesInteres === null) {
        if (setStudent.stages.length > 0) {
          studentsForm[0].stagesInteres = setStudent.stages[0];
        }
      }
      this.setStudentsProfile(studentsForm);
      this.cleanGrades = true;
      this.closeModal();
    },
    removeStudent(data) {
      this.tagMixPanel("remove_student_dashboard");
      this.deleteStudent({
        uuid: data.uuid,
      }).then((student) => {
        if (student) {
          this.changeCurrentStudent(student.uuid);
        }
      });
    },
    changeCurrentStudent(studentUUID) {
      const student = this.students.find((st) => st.uuid === studentUUID);
      this.setCurrentStudent({ student, hotjar: this.$hj })
        .then(() => this.retrieveFavorites())
        .then(() => this.retrieveExploredCampuses())
        .then(() => this.retrieveExploredSchoolCard())
        .then(() => this.retrieveCampuses({ callback: null, bounds: this.cornerBounds }))
        .catch((error) => this.raiseError(error));
    },
    tagMixPanel(tag) {
      this.$mixpanel.track(tag, {
        distinct_id: this.legalGuardianUUID,
        student_id: this.currentStudent.uuid,
        env: CONFIG.environment,
      });
    },
    addStudent() {
      this.tagMixPanel("click_add_student_dashboard");
      this.showAddModal = true;
    },
    editStudent(student) {
      this.tagMixPanel("click_edit_student_dashboard");
      /*
      this.getStudentData({
        uuid: student,
        callback: (data) => {
          this.step = data.last_step_complete;
          this.subStep = data.last_substep_complete;
          this.showStudentRegisterModal = true;
        },
      });
      */
      this.studentToEdit = student;
      this.showEditStudents = true;
    },
    signUp() {
      this.$router.push({ name: "User Register" });
    },
    closeModal() {
      this.showAddModal = false;
      this.showStudentRegisterModal = false;
      this.showEditLocation = false;
      this.showEditStudents = false;
      this.getStudents({});
    },
    setStudent(studentForm, confirmed) {
      this.studentsForm = studentForm;
      if (confirmed) {
        this.studentsDone = true;
      } else {
        this.studentsDone = false;
      }
    },
    showIdentifactionModal() {
      this.tagMixPanel("click_edit_identification_dashboard/profile");
      /*
      this.setCurrentStep({
        nextStep: 5,
      });
      */
      this.showIdentificationModal = true;
    },
    closeIdentificationModal() {
      this.showIdentificationModal = false;
    },
    showLocationModal() {
      this.tagMixPanel("click_edit_location_dashboard/profile");
      /*
      this.setCurrentStep({
        nextStep: 12,
      });
      */
      this.showEditLocation = true;
    },
    setFastLocation(location) {
      this.location = location;
      this.locationDone = true;
    },
    showContactModal() {
      this.tagMixPanel("click_edit_contact_dashboard/profile");
      this.setCurrentStep({
        nextStep: 19,
      });
    },
  },
  watch: {
    showStudentRegisterModal: {
      handler(newValue) {
        if (newValue === false) {
          this.setSteps({
            callback: () =>
              this.getStudents({
                callback: () => this.resetNewStudentStore({}),
              }),
          });
        }
      },
    },
    showStudentModals: {
      handler(newValue) {
        if (newValue === true) {
          this.step = 1;
          this.subStep = 1;
          this.showStudentRegisterModal = true;
        }
      },
    },
  },
};
</script>
