<template>
  <div class="simulation_chart">
    <div class="simulation_chart__chart" :class="{ 'simulation_chart__chart--desktop': !mobile }">
      <pie-chart v-if="datacollection" :datacollection="datacollection" />
    </div>
    <div class="simulation_chart__legend" :class="{ 'simulation_chart__legend--desktop': !mobile }">
      <div v-for="(label, index) in labels" :key="index" :class="index === 0 ? 'mb-1' : 'mb-2'">
        <div class="simulation_chart__legend__line">
          <v-avatar
            v-if="backgroundColor[index] !== '#FFFFFF'"
            :size="index === mostLikelyIndex ? 14 : 8"
            :color="backgroundColor[index]"
            class="mr-3"
          />
          <div v-else class="simulation_chart__legend__none mr-3" />
          <p class="text" :class="mobile ? 'text--small-02' : 'text--small-01 text--light'">
            {{ label }} &nbsp;
            <v-tooltip
              v-if="label === 'Sin asignación'"
              v-model="showTooltip.noAssignment"
              bottom
              content-class="custom-tooltip"
            >
              <template v-slot:activator="{ on, attrs }">
                <button
                  type="button"
                  v-bind="attrs"
                  v-on="on"
                  @click="showTooltip.noAssignment = !showTooltip.noAssignment"
                  :class="{ 'btn--tooltip-question--active': showTooltip.noAssignment }"
                  class="btn--tooltip-question -mr-1_5"
                >
                  ?
                </button>
              </template>
              <span>{{ $t("simulation.tooltip.noAssignment") }}</span>
            </v-tooltip>
          </p>
        </div>
        <v-row
          v-if="index === mostLikelyIndex"
          no-gutters
          class="simulation_chart__most-likely"
          align="end"
        >
          <img height="27" contain src="@/assets/iconsChile/simulation/LegendArrow.svg" />
          <p class="text text--bold pl-2" :class="mobile ? 'text--small-02' : 'text--small-01'">
            {{ $t("simulation.results.chart.most_likely") }} &nbsp;
            <v-tooltip v-model="showTooltip.chartMostLikely" bottom content-class="custom-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <button
                  type="button"
                  v-bind="attrs"
                  v-on="on"
                  @click="showTooltip.chartMostLikely = !showTooltip.chartMostLikely"
                  :class="{ 'btn--tooltip-question--active': showTooltip.chartMostLikely }"
                  class="btn--tooltip-question -mr-1_5"
                >
                  ?
                </button>
              </template>
              <span>{{ $t("simulation.tooltip.chartMostLikely") }}</span>
            </v-tooltip>
          </p>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import PieChart from "./PieChart.vue";

export default {
  name: "SimulationChart",
  data() {
    return {
      showTooltip: {
        chartMostLikely: false,
        noAssignment: false,
      },
    };
  },
  components: {
    PieChart,
  },
  props: {
    sortedChartData: {
      type: Array,
      required: true,
    },
    unmatched: {
      type: Number,
      required: true,
    },
    colors: {
      type: Array,
      required: true,
    },
  },
  computed: {
    info() {
      // Format chartData
      const info = this.sortedChartData
        .slice(0, 4)
        .map((element) => ({ name: element.campus.campus_name, prob: element.ratex * 100 }));
      if (this.sortedChartData.length === 5) {
        const lastElement = this.sortedChartData[this.sortedChartData.length - 1];
        info.push({ name: lastElement.campus.campus_name, prob: lastElement.ratex * 100 });
      } else if (this.sortedChartData.length > 5) {
        const otherProb = this.sortedChartData
          .slice(4, this.sortedChartData.length)
          .reduce((total, element) => total + element.ratex * 100, 0);
        info.push({
          name: this.$t("simulation.results.chart.other"),
          prob: otherProb,
        });
      }
      info.push({
        name: this.$t("simulation.results.chart.no_assigned"),
        prob: this.unmatched * 100,
      });
      return info;
    },
    datacollection() {
      return { datasets: [this.datasets] };
    },
    datasets() {
      return {
        data: this.data,
        backgroundColor: this.backgroundColor,
        borderWidth: 0,
      };
    },
    labels() {
      return this.info.map((element) => element.name);
    },
    data() {
      return this.info.map((element) => element.prob);
    },
    backgroundColor() {
      const backgroundColor = this.colors.slice(0, this.info.length - 1);
      backgroundColor.push(this.colors[this.colors.length - 1]);
      return backgroundColor;
    },
    mostLikelyIndex() {
      const ratex = Math.max(...this.sortedChartData.map((element) => element.ratex));
      const index = this.sortedChartData.find((element) => element.ratex === ratex).rank - 1;
      return index < 4 ? index : 4;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>
