<template>
  <section v-if="admission.admission_status.id !== 1" class="feedback-schoolcard">
    <div>
      <div class="feedback-schoolcard__div">
        <h1 class="feedback-schoolcard__div__title">
          {{ $t("feedback.school_card.title1") }}
          <strong>{{ recommendations.length }}</strong>
          {{ $t("feedback.school_card.title2") }}
        </h1>
        <h1 class="feedback-schoolcard__div__title">
          {{ $t("feedback.school_card.title3") }}
          <strong v-t="'feedback.school_card.title4'" />
          {{ $t("feedback.school_card.title5") }}
        </h1>
        <h1 class="feedback-schoolcard__div__title">
          <strong v-t="'feedback.school_card.title6'" />
          {{ $t("feedback.school_card.title7") }}
        </h1>
      </div>
      <div>
        <p class="feedback-schoolcard__text" v-t="'feedback.school_card.map_instructions'" />
      </div>
    </div>
    <school-card
      :studentLocation="studentLocation"
      :studentId="studentId"
      @openProfile="openProfile"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CONFIG from "../../config";
import schoolCard from "./schoolCardFeedback.vue";

export default {
  name: "schoolCards",
  components: {
    schoolCard,
  },
  props: {
    admission: {
      type: [Object, Array],
      default: () => {},
    },
    recommendations: {
      type: [Object, Array],
      default: Object,
    },
    markerStudent: {
      type: Object,
      default: Object,
    },
    studentId: {
      type: String,
      default: "",
    },
  },
  created() {
    window.addEventListener("beforeunload", () => {
      this.setDefaultCurrentSchool();
    });
  },
  mounted() {
    if (
      (this.markerStudent.lat === null && this.markerStudent.lng === null) ||
      this.markerStudent.lat === undefined
    ) {
      this.studentLocation = this.CONFIG.defaultLocation;
    } else {
      this.studentLocation = this.markerStudent;
    }
    this.updateCurrentSchool({ uuid: this.recommendations[0].uuid });
  },
  data() {
    return {
      CONFIG,
      studentLocation: null,
    };
  },
  computed: {
    ...mapGetters({
      currentSchool: "institutions/currentSchoolLite",
    }),
  },
  methods: {
    ...mapActions({
      updateCurrentSchool: "institutions/updateCurrentSchool",
      setDefaultCurrentSchool: "institutions/setDefaultCurrentSchool",
    }),
    openProfile(evt) {
      this.$emit("openProfile", evt);
    },
  },
};
</script>
