<template>
  <v-container v-show="stepAux && sectionStepAux" class="register_container">
    <identification
      v-if="stepAux === 1"
      :step="sectionStepAux"
      @backRegisterStep="backStep"
      @nextRegisterStep="nextStep"
    />
    <location
      v-if="stepAux === 2"
      :step="sectionStepAux"
      @backRegisterStep="backStep"
      @nextRegisterStep="nextStep"
    />
    <priority
      v-if="stepAux === 3"
      :step="sectionStepAux"
      @backRegisterStep="backStep"
      @nextRegisterStep="nextStep"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Identification from "./Identification.vue";
import Location from "./Location.vue";
import Priority from "./Priority.vue";

export default {
  name: "StudentRegister",
  components: {
    Identification,
    Location,
    Priority,
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
    sectionStep: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      stepAux: null,
      sectionStepAux: null,
    };
  },
  mounted() {
    this.stepAux = this.step;
    this.sectionStepAux = this.sectionStep;
  },
  methods: {
    ...mapActions({
      setCurrentStep: "newStudentRegister/setCurrentStep",
      setCurrentSubSteps: "newStudentRegister/setCurrentSubSteps",
    }),
    nextStep() {
      if (this.stepAux < 3) {
        this.stepAux += 1;
        this.sectionStepAux = 1;
        this.setCurrentStep(this.stepAux);
      } else {
        this.$emit("closeModal");
      }
    },
    backStep() {
      if (this.stepAux > 1) {
        this.stepAux -= 1;
        this.sectionStepAux = this.maxSectionsNumber[this.stepAux - 1];
        this.setCurrentStep(this.stepAux);
        this.setCurrentSubSteps({ step: this.stepAux, subStep: this.sectionStepAux });
      } else {
        this.$emit("closeModal");
      }
    },
  },
  computed: {
    ...mapGetters({
      maxSectionsNumber: "newStudentRegister/totalSubSteps",
    }),
  },
};
</script>
