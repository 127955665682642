<template>
  <section class="w-full bg-white py-20 d-flex justify-center">
    <div
      class="w-full feedback-max-width feedback-padding-x d-flex flex-column justify-start align-start"
    >
      <div v-if="false" class="d-flex w-full flex-row align-center justify-center">
        <img src="@/assets/feedback/ring.svg" alt="ring" width="30" />
        <p class="feedback-end__text ml-2" v-t="'feedback.end.information'" />
      </div>

      <div class="feedback-end__button-container mt-5 mb-10">
        <!-- <v-btn
          v-if="false"
          color="#3A0AC0"
          dark
          class="mb-2 feedback-end__button"
          v-t="'feedback.end.go_sae'"
        />
        <v-btn
          color="#3A0AC0"
          dark
          class="feedback-end__button"
          @click="toSae()"
          v-t="'feedback.end.go_mineduc'"
        /> -->
        <v-btn
          v-for="(button, index) in $t('feedback.end.buttons')"
          :key="index"
          color="#3A0AC0"
          dark
          class="feedback-end__button mt-2"
          @click="actionButton(button)"
        >
          {{ button.text }}
        </v-btn>
      </div>

      <!-- Logos -->
      <picture class="d-flex w-full justify-space-between">
        <img src="@/assets/svg/logo-ministry.svg" alt="ministerio de educación" />
        <img src="@/assets/feedback/logo_sae.png" alt="sae" width="80px" />
      </picture>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeedbackEnd',
  props: {
    uuid: {
      type: String,
    },
    firstname: {
      type: String,
    },
  },
  methods: {
    actionButton(button) {
      this.$mixpanel.track(button.track, {
        uuid: this.uuid,
        firstname: this.firstname,
      });
      window.open(button.url, '_blank');
    },
  },
};
</script>
