<template>
  <div>
    <div class="campus-detail" v-if="loadingProfile">
      <v-sheet
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-skeleton-loader
          v-bind="attrs"
          type="table-heading, image, list-item-three-line,
                list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line,
                list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"
        ></v-skeleton-loader>
      </v-sheet>
    </div>
    <section class="campus-detail" v-if="!loadingProfile">
      <!-- Close button -->
      <div class="campus-detail__close sticky">
        <button type="button" class="">
          <v-icon color="#F90E1F" size="24" @click="goBack">
            mdi-close-circle
          </v-icon>
        </button>
      </div>

      <!-- Information school -->
      <CampusDetailHeader
        class="px-2 mb-3 campus-detail__margin"
        :nameSchool="campusDetail.campus_name"
        :typeSchool="getSector"
        :gradesSchool="getGrades"
        :views="campusDetail.num_views"
        :applications="campusDetail.num_applications"
        :risk="campusDetail.risk_stats"
        :vacancies="campusDetail.vacancies"
        @close-modal="isCloseCampusDetail"
      />

      <!-- Pictures of the school -->
      <CampusDetailPhotos
        :style="{ height: mobile ? '230px' : '450px' }"
        v-if="campusDetail.campus_images"
        :images="campusDetail.campus_images"
      />

      <!-- Favorite button -->
      <div class="campus-detail__favorite">
        <FavoriteButton
          v-if="inDashboard"
          style="width: 42px; height: 42px"
          :isFavorite="true"
          :buttonInCampusDetail="true"
          @close-campus-detail="isCloseCampusDetail"
        />
        <FavoriteButton
          v-else
          style="width: 42px; height: 42px"
          @click-favorite="favoriteOnClick"
          :isFavorite="campusDetail.isFavorite"
          :buttonInCampusDetail="true"
          @close-campus-detail="isCloseCampusDetail"
        />
      </div>

      <!-- Navbar -->
      <CampusDetailNavbar
        :isMobile="mobile"
        :exitsInTheirOwnWords="getTestimonials"
        :exitsVirtualTour="getVirtualTour"
        :exitsVideoDrone="getVideoDrone"
        :exitsInfrastructure="campusDetail.infraestructure_campus"
        :exitsSports="campusDetail.sport_campus"
        :exitsExtracurriculars="campusDetail.extraactivity_campus"
        :team="campusDetail.campus_stats"
        :others="campusDetail.support_campus"
        :members="campusDetail.institutionmember_campus"
      />
      <!-- Summary -->
      <div
        id="summary"
        class="campus-detail__summary campus-detail__module mt-2"
      >
        <CampusDetailAbout
          :about="getInstitutionText()"
          :agreements="campusDetail.institutionagreement_campus"
          :contacts="campusDetail.institutioncontact_campus"
          :educationCode="getEducationCode()"
          :campusStreet="getCampusStreet()"
          :isMobile="mobile"
        />
        <PridePoints :pridePoints="campusDetail.pridepoint_campus" />
      </div>

      <!-- Educational Focus -->
      <CampusDetailEducationalFocus
        id="educational-focus"
        :educationalFocusVideo="getEducationalFocusVideo"
        :pdf="campusDetail.pei_pdf"
      />

      <!-- In their own words -->
      <CampusDetailInTheirOwnWords
        id="in-their-own-words"
        :testimonials="getTestimonials"
        :isMobile="mobile"
      />

      <!-- 360 virtual tour -->
      <CampusDetailVirtualTour
        id="360-virtual"
        :virtualTour="getVirtualTour"
        :isMobile="mobile"
      />

      <!-- Drone footage -->
      <CampusDetailDrone
        id="drone-footage"
        :videoDrone="getVideoDrone"
        :isMobile="mobile"
        :key="keyVideoDron"
      />

      <!-- Travel Time -->
      <TravelTime
        id="travel-time"
        :institution="getInfoTravelTime"
        @close-modal="goBack"
      />

      <!-- Panels -->
      <CampusDetailPanels @goBack="goBack" />

      <!-- Contact form -->
      <div class="w-full d-flex mt-2 px-2 pb-7">
        <ContactPanel id="contact-us" />
      </div>
    </section>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CampusDetailHeader from "@/components/explorer/general/campus_detail/CampusDetailHeader.vue";
import FavoriteButton from "@/components/explorer/favorite/FavoriteButton.vue";
import CampusDetailNavbar from "@/components/explorer/general/campus_detail/CampusDetailNavbar.vue";
import CampusDetailAbout from "@/components/explorer/general/campus_detail/CampusDetailAbout.vue";
import CampusDetailEducationalFocus from "@/components/explorer/general/campus_detail/CampusDetailEducationalFocus.vue";
import CampusDetailInTheirOwnWords from "@/components/explorer/general/campus_detail/CampusDetailInTheirOwnWords.vue";
import CampusDetailVirtualTour from "@/components/explorer/general/campus_detail/CampusDetailVirtualTour.vue";
import CampusDetailDrone from "@/components/explorer/general/campus_detail/CampusDetailDrone.vue";
import CampusDetailPhotos from "@/components/explorer/general/campus_detail/CampusDetailPhotos.vue";
import TravelTime from "@/components/explorer/general/campus_detail/TravelTime.vue";
import ContactPanel from "@/components/explorer/contact/ContactPanel.vue";
import CampusDetailPanels from "@/components/explorer/general/campus_detail/CampusDetailPanels.vue";
import utils from "@/utils/";
import CONFIG from "@/config";
import PridePoints from "./PridePoints.vue";

export default {
  name: "CampusDetail",
  components: {
    CampusDetailHeader,
    CampusDetailNavbar,
    CampusDetailAbout,
    CampusDetailEducationalFocus,
    PridePoints,
    CampusDetailInTheirOwnWords,
    CampusDetailVirtualTour,
    CampusDetailDrone,
    CampusDetailPhotos,
    FavoriteButton,
    ContactPanel,
    CampusDetailPanels,
    TravelTime,
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  props: {
    outsideClose: {
      type: [Boolean, Function],
      default: null,
    },
    inDashboard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scrollY: 0,
      panels: [],
      showingPanel: "",
      closePanels: false,
      keyVideoDron: 0,
      CONFIG,
      modalClosed: false,
      attrs: {
        class: "mb-6",
        boilerplate: false,
        elevation: 2,
      },
    };
  },
  created() {
    window.addEventListener("beforeunload", () => {
      this.goBack();
    });
  },
  watch: {
    outsideClose() {
      if (this.outsideClose === true) {
        this.$mixpanel.track("close_school_profile", {
          distinct_id: this.legalGuardianUUID(),
          student_id: this.currentStudent.uuid,
          school_id: this.campusDetail.uuid,
          env: this.environment(),
        });
        this.closePanels = true;
        this.modalClosed = true;
      }
    },
  },
  computed: {
    ...mapGetters({
      campusDetail: "institutions/campusDetails",
      currentStudent: "authentication/currentStudent",
      isGuest: "authentication/isGuest",
      homeLocation: "authentication/homeLocation",
      guestLevel: "authentication/guestLevel",
      loadingProfile: "institutions/loadingProfile",
      favoriteSchools: "institutions/favoriteSchools",
      legalGuardianUUID: "authentication/legalGuardianUUID",
      environment: "authentication/environment",
    }),
    getInfoTravelTime() {
      const institution = {};
      institution.name = this.campusDetail.campus_name;
      institution.location = {
        lat: this.campusDetail.campus_location[0].latitud,
        lng: this.campusDetail.campus_location[0].longitud,
      };
      return institution;
    },
    getSector() {
      const sector = this.campusDetail.sector_label;
      if (sector === null) {
        return "";
      }
      if (sector.sector_name === null || sector.sector_name === undefined) {
        return "";
      }
      return sector.sector_name;
    },
    getGrades() {
      const grades = `${this.campusDetail.grade_min} - ${this.campusDetail.grade_max}`;

      return grades;
    },
    getEducationalFocusVideo() {
      // // eslint-disable-next-line camelcase
      // const { audiovisual_link } =
      //   this.campusDetail.audiovisual_campus.find(
      //     (audiovisual) => audiovisual.audiovisual_label.id === 3
      //   ) || {};

      let { audiovisual_campus: listAudiovisual } = this.campusDetail;

      listAudiovisual = listAudiovisual
        .filter(
          (item) =>
            item.audiovisual_label.id === 3 || item.audiovisual_label.id === 4
        )
        .sort((a, b) => b.audiovisual_label.id - a.audiovisual_label.id)
        .map((item) => item.audiovisual_link);

      return listAudiovisual ?? [];
    },
    getTestimonials() {
      let { audiovisual_campus: listAudiovisual } = this.campusDetail;

      listAudiovisual ??= [];

      const listTestimonials = listAudiovisual
        .filter((item) => item.audiovisual_label.id > 5)
        .map((item) => ({
          link: item.audiovisual_link,
          name: item.name ?? item.audiovisual_label.audiovisual_name,
          order: item.audiovisual_order,
        }))
        .sort((a, b) => a.order - b.order);

      return listTestimonials;
    },
    getVideoDrone() {
      let url = "";
      // eslint-disable-next-line no-restricted-syntax
      for (const { audiovisual_label, audiovisual_link } of this.campusDetail
        .audiovisual_campus) {
        // eslint-disable-next-line camelcase
        const { id } = audiovisual_label;

        if (id === 1) {
          // eslint-disable-next-line camelcase
          url = audiovisual_link;
          break;
        }
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.keyVideoDron += 1;
      return url;
    },
    getVirtualTour() {
      let url = "";
      // eslint-disable-next-line no-restricted-syntax
      for (const { audiovisual_label, audiovisual_link } of this.campusDetail
        .audiovisual_campus) {
        // eslint-disable-next-line camelcase
        const { id } = audiovisual_label;

        if (id === 2) {
          // eslint-disable-next-line camelcase
          url = audiovisual_link;
          break;
        }
      }
      return url;
    },
    distance() {
      const campusLocation = {
        lat: this.campusDetail.campus_location[0].latitud,
        lng: this.campusDetail.campus_location[0].longitud,
      };
      let homeLocation;
      if (this.homeLocation) {
        homeLocation = this.homeLocation;
      }
      if (campusLocation && homeLocation) {
        const distance = utils.getDistanceInKm(campusLocation, homeLocation);
        return distance;
      }
      return null;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown || false;
    },
  },
  methods: {
    ...mapActions({
      openChooseProgramModal: "institutions/openChooseProgramModal",
      removeFavoriteByCampusUUID: "institutions/removeFavoriteByCampusUUID",
      addMultipleFavoriteByUUID: "institutions/addMultipleFavoriteByUUID",
      errorSnackbar: "snackbar/error",
      infoSnackbar: "snackbar/info",
      setLoadingProfile: "institutions/setLoadingProfile",
      removeFavoriteByUUID: "institutions/removeFavoriteByUUID",
      updateCampusDetailFavorite: "institutions/updateCampusDetailFavorite",
    }),
    isCloseCampusDetail() {
      this.goBack();
    },
    getEducationCode() {
      const programEduCodes = [];
      this.campusDetail.program_campus.forEach((program) => {
        if (
          program.gradetrack.modality_label.modalitycodes_modality.length > 0
        ) {
          if (
            !programEduCodes.includes(
              program.gradetrack.modality_label.modalitycodes_modality[0]
                .cod_ense
            )
          ) {
            programEduCodes.push(
              program.gradetrack.modality_label.modalitycodes_modality[0]
                .cod_ense
            );
          }
        }
      });
      return programEduCodes;
    },
    getCampusStreet() {
      let address;
      let municipality;

      if (this.campusDetail.campus_location.length > 0) {
        address = this.campusDetail.campus_location[0].address_street;
        municipality =
          this.campusDetail.campus_location[0].plocation.name || "";

        return `${address}, ${municipality}`;
      }
      return "Sin información";
    },
    goBack() {
      this.$mixpanel.track("close_school_profile", {
        distinct_id: this.legalGuardianUUID,
        student_id: this.currentStudent.uuid,
        school_id: this.campusDetail.uuid,
        env: this.environment,
      });
      this.closePanels = true;
      this.modalClosed = true;
      return this.$emit("close");
    },
    favoriteOnClick() {
      if (this.campusDetail.isFavorite) {
        const programsIds = [];
        let favoriteProgram;
        if (this.campusDetail.program_campus.length > 0) {
          this.campusDetail.program_campus.forEach((program) => {
            programsIds.push(program.id);
          });
          this.favoriteSchools.forEach((school) => {
            programsIds.forEach((id) => {
              if (id === school.program) {
                favoriteProgram = school.id;
              }
            });
          });
        }
        this.removeFavoriteByUUID({ programId: favoriteProgram }).then(() => {
          this.$mixpanel.track("remove_favorite_school_from_details", {
            distinct_id: this.legalGuardianUUID,
            student_id: this.currentStudent.uuid,
            school_id: this.campusDetail.uuid,
            env: this.environment,
          });
          this.updateCampusDetailFavorite(false);
        });
      } else if (this.searchGrade().isUnique) {
        this.addMultipleFavoriteByUUID({
          programsUUIDs: [this.searchGrade().uuid],
        })
          .then(() => {
            this.$mixpanel.track("favorite_school_from_profile", {
              distinct_id: this.legalGuardianUUID,
              student_id: this.currentStudent.uuid,
              school_id: this.campusDetail.uuid,
              env: this.environment,
            });
            this.updateCampusDetailFavorite(true);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        const programsOptions = this.searchGrade().totalDegrees;
        this.openChooseProgramModal({
          programsOptions,
          inCampusDetail: true,
        });
      }
    },
    searchGrade() {
      const { grades } = this.currentStudent;
      // eslint-disable-next-line camelcase
      const { program_campus } = this.campusDetail;
      const totalDegrees = program_campus.filter(
        (program) => program.gradetrack.grade_label.id === grades[0]
      );
      return {
        isUnique: totalDegrees.length === 1,
        uuid: totalDegrees[0].uuid,
        totalDegrees,
      };
    },
    getInstitutionText() {
      if (this.campusDetail.institutiontext_campus.length > 0) {
        return this.campusDetail.institutiontext_campus[0].profile_summary;
      }
      return "";
    },
  },
};
</script>
