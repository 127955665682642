<template>
  <v-container
    class="warning-modal_container px-8 pt-1"
    :class="{ 'warning-modal_container--id-modal': numberModal }"
    :style="`background-color: ${color};`"
  >
    <div class="warning-modal_title mt-5">
      {{ title }}
    </div>
    <div v-if="text !== ''" class="warning-modal_text">
      {{ text }}
    </div>
    <div v-if="!skipModal" class="py-6 warning-modal_btn_container">
      <base-button class="ok-btn" @click="closeModal">
        {{ $t("register.warning_modal.ok_btn") }}
      </base-button>
    </div>
    <div v-else class="my-6 warning-modal_skip-btn_container">
      <base-button v-if="!numberModal" class="complete-btn mr-2" @click="completeMethod">
        {{ btnText }}
      </base-button>
      <base-button class="skip-btn" @click="skipConfirmMethod">
        {{ selectText() }}
      </base-button>
    </div>
  </v-container>
</template>

<script>
import BaseButton from "@/components/explorer/base/BaseButton.vue";

export default {
  name: "WarningModal",
  props: {
    title: {
      required: true,
    },
    text: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "#2D10B4",
    },
    skipModal: {
      type: Boolean,
      default: false,
    },
    continueBtnText: {
      type: String,
      default: "",
    },
    numberModal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseButton,
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    completeMethod() {
      this.$emit("complete");
    },
    skipConfirmMethod() {
      if (!this.numberModal) {
        return this.$emit("skip");
      }
      return this.$emit("close");
    },
    selectText() {
      if (!this.numberModal) {
        return "Saltar";
      }
      return "Entendido";
    },
  },
  computed: {
    btnText() {
      if (this.continueBtnText === "") {
        return this.$t("register.warning_modal.complete_btn");
      }
      return this.continueBtnText;
    },
  },
};
</script>
