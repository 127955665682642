<template>
  <div class="simulation-container">
    <img
      class="simulation-background"
      src="@/assets/dashboard/icons/simulation/simulation_background.svg"
      alt=""
    />
    <br />
    <br />
    <br />
    <div class="simulation-text">
      Esta herramienta te permite ver las posibilidades de ser asignado en cada establecimiento como
      si hubieras postulado en años anteriores (2019,2020 y 2021)
    </div>
    <div @click="goPostulate" class="simulation-button clickable">
      Simular admisiones históricas
    </div>
  </div>
</template>

<script>
export default {
  name: "Simulation",
  methods: {
    goPostulate() {
      this.$router.push("/postulate");
    },
  },
};
</script>

<style scoped>
.simulation-container {
  width: 100%;
  height: 100%;
  z-index: 1 !important;
}
.simulation-background {
  width: 78%;
  position: absolute;
  top: -32px;
  left: 35px;
  z-index: -10 !important;
  mix-blend-mode: normal;
}
.simulation-text {
  height: 50%;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #0d22a8;
  display: flex;
  align-items: center;
}
.simulation-button {
  color: #ffffff;
  background: linear-gradient(180deg, #426cfb 0%, #9f81f3 139.26%);
  box-shadow: -4px 4px 10px rgba(41, 56, 149, 0.2);
  border-radius: 4px;
  width: 100%;
  height: 18%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
