<template>
  <v-card>
    <div class="about-simulation__main-container">
      <img :src="require('@/assets/iconsChile/simulation/ExclamationMark.svg')" />
      <template v-if="canSimulate">
        <v-row class="mb-8" no-gutters justify="center">
          <p class="text about-simulation__title">
            {{ $t("simulation.about.title") }}
          </p>
        </v-row>
        <p class="text text--small-01 text--word-break mt-2">
          {{ $t("simulation.about.header", [currentStudent.name]) }}
        </p>
        <p class="text text--small-01 text--word-break mt-2">
          {{ $t("simulation.about.body", [currentStudent.name]) }}
        </p>
        <p class="text text--small-01 text--bold mt-2">
          {{ $t("simulation.about.footer", [currentStudent.name]) }}
        </p>
        <v-row no-gutters justify="center" class="mt-6">
          <base-button class="about-simulation__btn" @click="$emit('simulate')">
            {{ $t("simulation.simulate.continue") }}
          </base-button>
        </v-row>
      </template>
      <template v-else>
        <v-row class="mb-8" no-gutters justify="center">
          <p class="text about-simulation__title">
            {{ $t("simulation.cannot-simulate.title") }}
          </p>
        </v-row>
        <p
          class="text text--small-01 text--word-break mt-2"
          v-html="$t('simulation.cannot-simulate.text')"
        />
        <p
          v-for="school in schoolsToRemove"
          :key="`school_to_remove_${school.uuid}`"
          class="text text--word-break mt-2 text-center"
        >
          - {{ school.name }}
        </p>
        <v-row no-gutters justify="center" class="mt-6">
          <base-button class="btn--primary-01--outlined py-4 px-10" @click="$emit('close')">
            {{ $t("simulation.cannot-simulate.button") }}
          </base-button>
        </v-row>
      </template>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import BaseButton from "@/components/explorer/base/BaseButton.vue";

export default {
  name: "AboutSimulation",
  components: {
    BaseButton,
  },
  props: {
    canSimulate: {
      type: Boolean,
      default: false,
    },
    schoolsToRemove: {
      type: Array,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      currentStudent: "authentication/currentStudent",
    }),
  },
};
</script>
