<template>
  <div class="section-content" style="width: 100%; max-width: 100%">
    <GmapMap
      ref="dashboardMapRef"
      :center="homeLocation ? homeLocation : CONFIG.defaultLocation"
      :zoom="zoom"
      :class="$vuetify.breakpoint.mobile ? 'vue-map-container-mobile' : 'vue-map-container'"
      :options="{
        disableDefaultUI: true,
        clickableIcons: false,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        styles: mapStyle,
        gestureHandling: 'greedy',
      }"
      style="width: 100%; height: 100%"
    >
      <div v-if="allFavoriteSchools.length > 0">
        <gmap-custom-marker
          v-for="(school, i) in allFavoriteSchools"
          :id="`id-markermap-${school.uuid}`"
          :key="i"
          :marker="{
            lat: school.location.latitud,
            lng: school.location.longitud,
          }"
          alignment="top"
        >
          <img width="20px" src="@/assets/svg/save-institution.svg" />
        </gmap-custom-marker>
      </div>
      <gmap-custom-marker
        :marker="homeLocation ? homeLocation : CONFIG.defaultLocation"
        alignment="top"
      >
        <img src="@/assets/dashboard/icons/home.svg" />
      </gmap-custom-marker>
    </GmapMap>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GmapCustomMarker from "vue2-gmap-custom-marker";
// import MarkerIcon from '@/components/explorer/general/MarkerIcon.vue';
import mapStyle from "../../assets/mapStyle.json";
import CONFIG from "../../config";

export default {
  name: "DashboardMap",
  components: {
    // MarkerIcon,
    GmapCustomMarker,
  },
  data() {
    return {
      zoom: 14,
      mapStyle,
      homeCoordinates: null,
      CONFIG,
    };
  },
  props: {
    isGuest: {
      type: Boolean,
      default: false,
    },
  },
  created() {},
  mounted() {
    if (this.isGuest) {
      this.homeCoordinates = {
        lat: Number(this.homeLocation.lat),
        lng: Number(this.homeLocation.lng),
      };
    } else {
      this.homeCoordinates = this.homeLocation;
      this.retrieveAllFavorites();
    }
  },
  computed: {
    ...mapGetters({
      allFavoriteSchools: "institutions/allFavoriteSchools",
      homeLocation: "authentication/homeLocation",
    }),
  },
  methods: {
    ...mapActions({
      retrieveAllFavorites: "institutions/retrieveAllFavorites",
    }),
  },
};
</script>
