<template>
  <div>
    <v-tooltip v-model="showTooltip" class="" bottom content-class="custom-tooltip">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="panels__tooltip panels__tooltip--right"
          icon
          dark
          small
          color="#1E2D4C"
          style="margin-top: -4px !important"
        >
          <v-icon dark> mdi-information </v-icon>
        </v-btn>
      </template>
      <span v-t="'campus-details.tooltip.teamInfo'" />
    </v-tooltip>
    <div class="d-inline-flex flex-nowrap fill-width align-center">
      <v-expansion-panels accordion flat class="panels__expansions">
        <v-expansion-panel
          v-for="(language, i) in languagesLabels"
          :key="i"
          :disabled="isNativeLanguage(language)"
          :style="isNativeLanguage(language) ? 'background-color: white !important;' : ''"
        >
          <v-expansion-panel-header class="panels__expansions__headers">
            {{ language }}
            <template v-slot:actions>
              <v-icon color="#2B5BFF"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="panels__expansions__title-table">
              <div />
              <div class="panels__expansions__title">
                <div v-t="'campus-details.panels.language.level'" />
                <v-tooltip bottom content-class="custom-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <button
                      type="button"
                      v-bind="attrs"
                      v-on="on"
                      class="panels__language-tooltip ml-4"
                    >
                      ?
                    </button>
                  </template>
                  <span v-t="'campus-details.tooltip.languageLevel'" />
                </v-tooltip>
              </div>
            </div>
            <div
              :key="i"
              v-for="(languageArray, i) in setLanguageArray(language)"
              class="panels__expansions__table"
            >
              <div
                v-if="languageArray.language_label.language_name === language"
                class="panels__expansions__grades"
              >
                {{ "Ed. " + getStageName(languageArray.stage_label) }}
              </div>
              <div
                v-if="languageArray.language_label.language_name === language"
                class="panels__expansions__levels"
              >
                {{ getLevelName(languageArray.language_level_label) }}
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>
<script>
export default {
  name: "Language",
  data() {
    return {
      showTooltip: false,
      languagesLabels: [],
      showLanguageTooltip: false,
    };
  },
  props: {
    languages: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.languages.forEach((languageLabel) => {
      if (!this.languagesLabels.includes(languageLabel.language_label.language_name)) {
        this.languagesLabels.push(languageLabel.language_label.language_name);
      }
    });
  },
  methods: {
    setLanguageArray(language) {
      let languageArray = [];
      this.languages.forEach((languageObj) => {
        if (languageObj.language_label.language_name === language) {
          languageArray.push(languageObj);
        }
      });
      languageArray.sort((a, b) => a.stage_label.id - b.stage_label.id);
      return languageArray;
    },
    showTooltipLevel() {
      if (this.showLanguageTooltip) {
        this.showLanguageTooltip = false;
        return this.showLanguageTooltip;
      }
      this.showLanguageTooltip = true;
    },
    getStageName(language) {
      if (language !== null) {
        return language.stage_name;
      }
      return "";
    },
    getLevelName(language) {
      if (language !== null) {
        return language.language_level_name;
      }
      return "";
    },
    isNativeLanguage(lang) {
      if (lang === "Aymara" || lang === "Mapudungun" || lang === "Quechua" || lang === "Rapanui") {
        return true;
      }
      return false;
    },
  },
  computed: {},
};
</script>
