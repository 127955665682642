<template>
  <div class="feedback-postulation-details">
    <div class="feedback-postulation-details__stats">
      <div class="feedback-postulation-details__stats__div">
        <div class="feedback-postulation-details__stats__div__numb">
          {{ this.stats.num_campus }}
        </div>
        <p
          v-t="'feedback.postulation.stats_campus'"
          class="feedback-postulation-details__stats__div__text"
        />
      </div>
      <div class="feedback-postulation-details__stats__div">
        <div class="feedback-postulation-details__stats__div__numb">
          {{ this.stats.num_other_students }}
        </div>
        <p
          v-t="'feedback.postulation.stats_other_students'"
          class="feedback-postulation-details__stats__div__text"
        />
      </div>
    </div>
    <section
      class="w-full flex justify-center bg-white"
      :class="{
        '-mt-5 pt-5': isRisk(riskAlert),
        slideInDown: !isRisk(riskAlert),
      }"
      :style="{
        'background-color: none; !important display: flex;': CONFIG.feedbackType === 'Results',
      }"
    >
      <div class="feedback-postulation-details__title-div pb-4">
        <!-- -----------------------Title ------------------------->
        <!-- -----------------------Title ------------------------->
        <div v-if="CONFIG.feedbackType !== 'Results'">
          <div
            v-t="'feedback.postulation.title_one'"
            class="text-gray text-xl text-left leading-6 bg-light"
            :class="{
              'feedback-postulation-details__title-div__title': CONFIG.feedback === 'Palmira',
            }"
            style="width: 120px"
          />
          <h1
            v-t="'feedback.postulation.title_two'"
            class="text-gray text-xl text-left leading-6 mx-10 bg-light mt-1"
            :class="{ 'feedback-postulation-details__title': CONFIG.feedback === 'Palmira' }"
            style="width: 200px"
          />
          <h1
            v-t="'feedback.postulation.title_three'"
            class="text-gray text-xl text-left leading-6 mx-10 bg-light mt-1"
            :class="{ 'feedback-postulation-details__title': CONFIG.feedback === 'Palmira' }"
            style="width: 135px"
          />
          <label
            v-t="{ path: 'feedback.postulation.date', args: { date: datePostulations } }"
            class="text-gray text-xs mt-3 mx-10 font-semibold mb-6"
            :class="{ 'feedback-postulation-details__date': CONFIG.feedback === 'Palmira' }"
          />
        </div>
        <div
          class="feedback-postulation-details__title-div__content"
          v-if="CONFIG.feedbackType === 'Results'"
        >
          <h1 v-t="'feedback.postulation.results_title'" />
        </div>
        <!-- ----------- Institution-------------- -->
        <!-- ----------- Institution-------------- -->
        <section
          v-for="(institution, index) in institutions"
          :key="index"
          class="w-full flex flex-column align-center justify-center"
        >
          <!------------- Card Institution-------------- -->
          <!------------- Card Institution-------------- -->
          <div
            class="w-full rounded-md shadow-card mt-2 overflow-hidden"
            :class="{ 'feedback-postulation-details__card': CONFIG.feedback === 'Palmira' }"
          >
            <div
              v-if="CONFIG.feedbackType === 'Results' && assignedSchool === institution.campus_name"
              class="feedback-postulation-details__title-div__assigned"
            >
              <div class="feedback-postulation-details__title-div__assigned__dot mr-2" />
              <p
                class="feedback-postulation-details__title-div__assigned__text"
                v-t="'feedback.postulation.accepted_alert'"
              />
            </div>
            <!-- Card title -->
            <div
              class="w-full flex h-16 border-b border-light"
              :class="{
                'feedback-postulation-details__card__div': CONFIG.feedback === 'Palmira',
              }"
            >
              <div
                v-t="{ path: 'feedback.postulation.number', args: { number: index + 1 } }"
                class="w-12 flex items-center justify-center bg-dark text-white"
                :class="{
                  'feedback-postulation-details__card__div__number': CONFIG.feedback === 'Palmira',
                }"
              />
              <div
                class="flex w-full items-center justify-center px-1 text-dark"
                :class="{
                  'feedback-postulation-details__card__div__title': CONFIG.feedback === 'Palmira',
                }"
              >
                {{ institution.campus_name }}
              </div>
            </div>
            <div class="feedback-postulation-details__card__shift">
              {{ institution.shift_name }}
            </div>
            <!-- Card content -->
            <div
              class="w-full px-1 py-1"
              :class="{ 'feedback-postulation-details__content': CONFIG.feedback === 'Palmira' }"
            >
              <button
                type="button"
                @click="openApplication(index)"
                v-t="{
                  path: 'feedback.postulation.view_applications_no_date',
                  args: { date: datePostulations },
                }"
                class="w-full text-xs bg-secondary text-white pt-1 pb-1 cursor-pointer"
                :class="{
                  'feedback-postulation-details__content_btn': CONFIG.feedback === 'Palmira',
                }"
              />
              <div v-if="showChairs === index">
                <div class="flex items-center flex-row py-2 px-3">
                  <picture class="w-2/12">
                    <img
                      :src="
                        CONFIG.feedback === 'Palmira'
                          ? require('@/assets/svg/chair-dark-blue.svg')
                          : require('@/assets/svg/chair-black.svg')
                      "
                    />
                  </picture>
                  <label
                    class="text-xs ml-0 w-10/12"
                    :class="{
                      'feedback-postulation-details__content_text': CONFIG.feedback === 'Palmira',
                    }"
                    v-t="{
                      path: 'feedback.postulation.vacancies_available_short',
                      args: setVacancies(institution),
                    }"
                  />
                </div>
                <div class="flex items-center flex-row py-2 px-3">
                  <picture class="-ml-1 w-2/12">
                    <img src="@/assets/svg/chair-gray.svg" />
                  </picture>
                  <label
                    class="text-xs ml-1 w-10/12"
                    :class="{
                      'feedback-postulation-details__content_text': CONFIG.feedback === 'Palmira',
                    }"
                    v-t="{
                      path: 'feedback.postulation.priority_applicants_short',
                      args: {
                        assigned: setAssignedHigher(institution),
                      },
                    }"
                  />
                </div>
                <div class="flex items-center flex-row py-2 px-3">
                  <picture class="w-2/12">
                    <img src="@/assets/svg/chair-red.svg" />
                  </picture>
                  <label
                    class="text-xs w-10/12"
                    :class="{
                      'feedback-postulation-details__content_text': CONFIG.feedback === 'Palmira',
                    }"
                    v-t="{
                      path: 'feedback.postulation.applicants_with_the_same_priority_short',
                      args: {
                        assigned: setAssignedEqual(institution),
                      },
                    }"
                  />
                </div>
                <div class="flex items-center flex-row py-2 px-3">
                  <picture class="w-2/12">
                    <img src="@/assets/svg/chair-blue.svg" />
                  </picture>
                  <label
                    class="text-xs w-10/12"
                    :class="{
                      'feedback-postulation-details__content_text': CONFIG.feedback === 'Palmira',
                    }"
                    v-t="{
                      path: 'feedback.postulation.assigned_with_lower_priority_short',
                      args: {
                        assigned: setAssignedLower(institution),
                      },
                    }"
                  />
                </div>
                <div class="flex items-center flex-row py-2 px-3">
                  <picture class="w-2/12">
                    <img
                      :src="
                        CONFIG.feedback === 'Palmira'
                          ? require('@/assets/svg/blue-person.svg')
                          : require('@/assets/svg/person.svg')
                      "
                    />
                  </picture>
                  <label
                    class="text-xs w-10/12"
                    :class="{
                      'feedback-postulation-details__content_text': CONFIG.feedback === 'Palmira',
                    }"
                    v-t="{
                      path: 'feedback.postulation.unassigned_applicants',
                      args: {
                        non_assigned: setNonAssigned(institution),
                      },
                    }"
                  />
                </div>
                <!--<label
                  class="text-xss line-h mt-1"
                  :class="{
                    'feedback-postulation-details__content_footer'
                    : CONFIG.feedback === 'Palmira'
                  }"
                  v-t="'feedback.postulation.vacancies_foot_note_short'"
                />-->
              </div>
              <button
                type="button"
                @click="openProfile(index, institution.campus_uuid)"
                v-t="'feedback.generic.view_digital_profile'"
                class="w-full text-xs bg-primary text-white pt-1 pb-1 mt-1 rounded-br-md rounded-bl-md cursor-pointer"
                :class="{
                  'feedback-postulation-details__content_profile': CONFIG.feedback === 'Palmira',
                }"
              />
            </div>
          </div>
        </section>
        <div
          v-if="admission.admission_status.id !== 1"
          class="feedback-postulation-details__subtitle-div"
        >
          <h1
            v-t="'feedback.postulation.nonassigned_title1'"
            class="feedback-postulation-details__subtitle-div__text"
          />
          <h1
            v-t="'feedback.postulation.nonassigned_title2'"
            class="feedback-postulation-details__subtitle-div__text"
          />
          <h1
            v-t="'feedback.postulation.nonassigned_title3'"
            class="feedback-postulation-details__subtitle-div__text"
          />
        </div>
        <section class="w-full flex flex-column align-center justify-center">
          <div
            v-if="admission.admission_status.id !== 1"
            class="w-full rounded-md shadow-card mt-2 overflow-hidden"
            :class="{ 'feedback-postulation-details__card': CONFIG.feedback === 'Palmira' }"
          >
            <!-- Card title -->
            <div
              class="w-full flex h-16 border-b border-light"
              :class="{
                'feedback-postulation-details__card__div': CONFIG.feedback === 'Palmira',
              }"
            >
              <div
                class="w-12 flex items-center justify-center text-white"
                :class="{
                  'feedback-postulation-details__card__div__home': CONFIG.feedback === 'Palmira',
                }"
              >
                <img :src="require('@/assets/svg/marker-red.svg')" />
              </div>
              <div
                class="flex w-full items-center justify-center px-1 text-dark"
                :class="{
                  'feedback-postulation-details__card__div__title': CONFIG.feedback === 'Palmira',
                }"
              >
                {{ admission.program.campus_code }}
              </div>
            </div>
            <div class="feedback-postulation-details__card__shift">
              {{ admission.program.shift_label.shift_name }}
            </div>
            <!-- Card content -->
            <div
              class="w-full px-1 py-1"
              :class="{ 'feedback-postulation-details__content': CONFIG.feedback === 'Palmira' }"
            >
              <button
                type="button"
                @click="openAssignedSchool()"
                v-t="{
                  path: 'feedback.postulation.view_applications_no_date',
                  args: { date: datePostulations },
                }"
                class="w-full text-xs bg-secondary text-white pt-1 pb-1 cursor-pointer"
                :class="{
                  'feedback-postulation-details__content_btn': CONFIG.feedback === 'Palmira',
                }"
              />
              <div v-if="showAssignedInfo">
                <div class="flex items-center flex-row py-2 px-3">
                  <picture class="w-2/12">
                    <img
                      :src="
                        CONFIG.feedback === 'Palmira'
                          ? require('@/assets/svg/chair-dark-blue.svg')
                          : require('@/assets/svg/chair-black.svg')
                      "
                    />
                  </picture>
                  <label
                    class="text-xs ml-0 w-10/12"
                    :class="{
                      'feedback-postulation-details__content_text': CONFIG.feedback === 'Palmira',
                    }"
                    v-t="{
                      path: 'feedback.postulation.vacancies_available_short',
                      args: (vacancies = {
                        vacancies: admissionStats.vacancies,
                        date: this.datePostulations,
                      }),
                    }"
                  />
                </div>
                <div class="flex items-center flex-row py-2 px-3">
                  <picture class="-ml-1 w-2/12">
                    <img src="@/assets/svg/chair-gray.svg" />
                  </picture>
                  <label
                    class="text-xs ml-1 w-10/12"
                    :class="{
                      'feedback-postulation-details__content_text': CONFIG.feedback === 'Palmira',
                    }"
                    v-t="{
                      path: 'feedback.postulation.priority_applicants_short',
                      args: {
                        assigned: admissionStats.assigned_higher,
                      },
                    }"
                  />
                </div>
                <div class="flex items-center flex-row py-2 px-3">
                  <picture class="w-2/12">
                    <img src="@/assets/svg/chair-red.svg" />
                  </picture>
                  <label
                    class="text-xs w-10/12"
                    :class="{
                      'feedback-postulation-details__content_text': CONFIG.feedback === 'Palmira',
                    }"
                    v-t="{
                      path: 'feedback.postulation.applicants_with_the_same_priority_short',
                      args: {
                        assigned: admissionStats.assigned_equal,
                      },
                    }"
                  />
                </div>
                <div class="flex items-center flex-row py-2 px-3">
                  <picture class="w-2/12">
                    <img src="@/assets/svg/chair-blue.svg" />
                  </picture>
                  <label
                    class="text-xs w-10/12"
                    :class="{
                      'feedback-postulation-details__content_text': CONFIG.feedback === 'Palmira',
                    }"
                    v-t="{
                      path: 'feedback.postulation.assigned_with_lower_priority_short',
                      args: {
                        assigned: admissionStats.assigned_lower,
                      },
                    }"
                  />
                </div>
                <div class="flex items-center flex-row py-2 px-3">
                  <picture class="w-2/12">
                    <img
                      :src="
                        CONFIG.feedback === 'Palmira'
                          ? require('@/assets/svg/blue-person.svg')
                          : require('@/assets/svg/person.svg')
                      "
                    />
                  </picture>
                  <label
                    class="text-xs w-10/12"
                    :class="{
                      'feedback-postulation-details__content_text': CONFIG.feedback === 'Palmira',
                    }"
                    v-t="{
                      path: 'feedback.postulation.unassigned_applicants',
                      args: {
                        non_assigned: admissionStats.nonassigned,
                      },
                    }"
                  />
                </div>
                <!--<label
                  class="text-xss line-h mt-1"
                  :class="{
                    'feedback-postulation-details__content_footer'
                    : CONFIG.feedback === 'Palmira'
                  }"
                  v-t="'feedback.postulation.vacancies_foot_note_short'"
                />-->
              </div>
              <button
                type="button"
                @click="openRecommendationProfile(admission.program.campus_code, true)"
                v-t="'feedback.generic.view_digital_profile'"
                class="w-full text-xs bg-primary text-white pt-1 pb-1 mt-1 rounded-br-md rounded-bl-md cursor-pointer"
                :class="{
                  'feedback-postulation-details__content_profile': CONFIG.feedback === 'Palmira',
                }"
              />
            </div>
          </div>
        </section>
      </div>
    </section>
    <div>
      <RiskAlert
        v-if="isRisk(riskAlert) && CONFIG.feedbackType !== 'Results'"
        :riskAlert="riskAlert"
      />
      <OtherRecommendation
        v-if="CONFIG.feedbackType !== 'Results'"
        :name="nameStudent"
        :recommendations="recommendations.recommendations"
        :applications="institutions"
      />
      <school-cards
        :admission="admission"
        :recommendations="resultsSortedRecomendations"
        :markerStudent="markerStudent(studentId)"
        :studentId="studentId"
        @openProfile="openRecommendationProfile"
      />
      <Map
        :recommendations="
          CONFIG.feedbackType !== 'Results'
            ? recommendations.recommendations
            : resultsSortedRecomendations
        "
        :applications="institutions"
        :markerStudent="studentLocation"
        :admission="admission"
        :admissionStats="admissionStats"
        :assignedSchool="assignedSchool"
      />
      <Recommendation
        v-if="CONFIG.feedbackType !== 'Results'"
        :recommendations="recommendations"
        :studentId="studentId"
        @openProfile="openRecommendationProfile"
      />
      <map-info :admission="admission" />
    </div>
    <v-dialog
      v-model="campusDetailModal"
      transition="dialog-bottom-transition"
      max-width="800px"
      content-class="favorites__dialog"
    >
      <campus-detail
        v-if="campusDetailModal"
        style="z-index: 1000"
        :outsideClose="closeCampusDetail"
        @close="closeCampusDetail"
        :inDashboard="true"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CampusDetail from "@/components/explorer/general/campus_detail/CampusDetail.vue";
import utils from "@/utils/";
import RiskAlert from "./RiskAlert.vue";
import OtherRecommendation from "./OtherRecommendation.vue";
import Map from "./Map.vue";
import Recommendation from "./Recommendation.vue";
import CONFIG from "../../config";
import MapInfo from "./ApplicationsMapInfo.vue";
import schoolCards from "./schoolCards.vue";

export default {
  name: "PostulationDetail",
  components: {
    RiskAlert,
    OtherRecommendation,
    Map,
    Recommendation,
    CampusDetail,
    MapInfo,
    schoolCards,
  },
  props: {
    riskAlert: {
      type: Number,
      default: Number,
    },
    institutions: {
      type: [Object, Array],
      default: Array,
    },
    nameStudent: {
      type: String,
      default: "",
    },
    recommendations: {
      type: [Object, Array],
      default: Object,
    },
    studentId: {
      type: String,
      default: "",
    },
    legalGuardianUuid: {
      type: String,
      default: "",
    },
    stats: {
      type: [Object, Array],
      default: () => {},
    },
    admissionStats: {
      type: [Object, Array],
      default: () => {},
    },
    institutionStats: {
      type: [Object, Array],
      default: () => {},
    },
    admission: {
      type: [Object, Array],
      default: () => {},
    },
  },
  data() {
    return {
      showChairs: -1,
      datePostulations: "02/12/2021",
      CONFIG,
      campusDetailModal: false,
      assignedSchool: null,
      resultsSortedRecomendations: null,
      studentLocation: null,
      showAssignedInfo: false,
    };
  },
  mounted() {
    if (this.CONFIG.feedbackType === "Results") {
      if (
        (this.markerStudent(this.studentId).lat === null &&
          this.markerStudent(this.studentId).lng === null) ||
        this.markerStudent(this.studentId).lat === undefined
      ) {
        this.studentLocation = this.CONFIG.defaultLocation;
      } else {
        this.studentLocation = this.markerStudent(this.studentId);
      }
      this.institutions.forEach((application) => {
        if (this.admission.program.campus_code === application.campus_name) {
          this.assignedSchool = application.campus_name;
        }
      });
      const campusWithDistance = [];
      this.recommendations.forEach((campus) => {
        const campusLocation = {
          lat: campus.campus_location[0].latitud,
          lng: campus.campus_location[0].longitud,
        };
        if (campusLocation && this.studentLocation) {
          const distance = utils.getDistanceInKm(campusLocation, this.studentLocation);
          // eslint-disable-next-line no-param-reassign
          campus.distance = distance;
          if (campus.distance < 2) {
            campusWithDistance.push(campus);
          }
        }
      });
      campusWithDistance.sort((a, b) => a.distance - b.distance);
      this.resultsSortedRecomendations = campusWithDistance;
    }
    if (this.CONFIG.feedbackType !== "Results") {
      this.recommendations = this.resultsSortedRecomendations;
    }
  },
  computed: {
    ...mapGetters({
      students: "authentication/students",
      schoolList: "institutions/schoolList",
    }),
    isRisk() {
      return (admissionProbabiliy) => {
        if (+admissionProbabiliy <= 0.7) return true;
        return false;
      };
    },
  },
  methods: {
    ...mapActions({
      retrieveCampusDetails: "institutions/retrieveCampusDetails",
      stopActualVideo: "options/stopActualVideo",
    }),
    openApplication(index) {
      if (this.showChairs === index) {
        this.showChairs = -1;
        return;
      }
      const programId = this.institutions[index].program_id;
      this.$mixpanel.track("open_applications_to_date", {
        distinct_id: this.legalGuardianUuid,
        student_id: this.studentId,
        program_id: programId,
        env: CONFIG.environment,
      });
      this.showChairs = index;
    },
    openAssignedSchool() {
      if (this.showAssignedInfo) {
        this.showAssignedInfo = false;
      } else {
        this.showAssignedInfo = true;
      }
    },
    openProfile(index, schoolId) {
      const programId = this.institutions[index].program_id;
      this.$mixpanel.track("open_application_digital_profile", {
        distinct_id: this.legalGuardianUuid,
        student_id: this.studentId,
        program_id: programId,
        env: process.env.VUE_APP_ENV,
      });
      this.retrieveCampusDetails({ campusUuid: schoolId, applicantUuid: this.studentId });
      this.campusDetailModal = true;
    },
    openRecommendationProfile(event, isAssigned) {
      if (isAssigned) {
        const campusUuid = this.schoolList.find((school) => school.name === event).uuid;
        this.retrieveCampusDetails({ campusUuid, applicantUuid: this.studentId });
        this.campusDetailModal = true;
      } else {
        this.retrieveCampusDetails({ campusUuid: event, applicantUuid: this.studentId });
        this.campusDetailModal = true;
      }
    },
    closeCampusDetail() {
      this.campusDetailModal = false;
      this.stopActualVideo();
    },
    markerStudent(studentId) {
      const student = this.students.find(
        (s) => s.uuid === studentId // eslint-disable-line no-param-reassign
      );
      const location = {
        lat: student.address ? student.address[0]?.address_lat : null,
        lng: student.address ? student.address[0]?.address_lon : null,
      };
      return location;
    },
    setVacancies(institution) {
      let vacancies;
      if (this.CONFIG.feedbackType !== "Results") {
        vacancies = {
          vacancies: institution.vacancies,
          date: this.datePostulations,
        };
      } else {
        const campusStats = this.institutionStats.find(
          (institutionStats) => institutionStats.campus_uuid === institution.campus_uuid
        );
        vacancies = {
          vacancies: campusStats.vacancies,
          date: this.datePostulations,
        };
      }
      return vacancies;
    },
    setAssignedHigher(institution) {
      let AssignedHigher;
      if (this.CONFIG.feedbackType !== "Results") {
        AssignedHigher = institution.assigned_higher;
      } else {
        const campusStats = this.institutionStats.find(
          (institutionStats) => institutionStats.campus_uuid === institution.campus_uuid
        );
        AssignedHigher = campusStats.assigned_higher;
      }
      return AssignedHigher;
    },
    setAssignedEqual(institution) {
      let AssignedEqual;
      if (this.CONFIG.feedbackType !== "Results") {
        AssignedEqual = institution.assigned_equal;
      } else {
        const campusStats = this.institutionStats.find(
          (institutionStats) => institutionStats.campus_uuid === institution.campus_uuid
        );
        AssignedEqual = campusStats.assigned_equal;
      }
      return AssignedEqual;
    },
    setAssignedLower(institution) {
      let AssignedLower;
      if (this.CONFIG.feedbackType !== "Results") {
        AssignedLower = institution.assigned_lower;
      } else {
        const campusStats = this.institutionStats.find(
          (institutionStats) => institutionStats.campus_uuid === institution.campus_uuid
        );
        AssignedLower = campusStats.assigned_lower;
      }
      return AssignedLower;
    },
    setNonAssigned(institution) {
      let NonAssigned;
      if (this.CONFIG.feedbackType !== "Results") {
        NonAssigned = institution.nonassigned;
      } else {
        const campusStats = this.institutionStats.find(
          (institutionStats) => institutionStats.campus_uuid === institution.campus_uuid
        );
        NonAssigned = campusStats.nonassigned;
      }
      return NonAssigned;
    },
  },
};
</script>
