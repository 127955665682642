<template>
  <v-expansion-panels accordion flat v-model="showingPanels" style="display: unset">
    <v-expansion-panel class="institutions-info__select">
      <v-expansion-panel-header
        class="institutions-info__header"
        :class="{ 'institutions-info__header--border': showingPanels !== null }"
        height="31"
        @click="leftItems()"
      >
        <div class="d-flex justify-center align-center" v-if="selectedInfo === null">
          <img class="mb-1" src="@/assets/svg/list-institution.svg" />
          <strong
            class="institutions-info__select__text institutions-info__select__text--strong ml-2"
          >
            {{ favoritesLengthStart() }}
          </strong>
          <p class="institutions-info__select__text ml-1" v-t="$t('map.info.favorites')" />
        </div>
        <div v-else class="d-flex justify-center align-center">
          <img
            class="mb-1"
            v-if="selectedInfo.name === $t('map.info.favorites')"
            :src="require('@/assets/svg/list-institution.svg')"
          />
          <img
            class="mb-1"
            v-if="selectedInfo.name === $t('map.info.explored')"
            :src="require('@/assets/iconsNewHaven/icons/explored.svg')"
          />
          <strong
            class="institutions-info__select__text institutions-info__select__text--strong ml-2"
          >
            {{ selectedInfoLength0(selectedInfo) }}
          </strong>
          <p class="institutions-info__select__text ml-1" v-t="selectedInfo.name" />
        </div>
        <template v-slot:actions>
          <v-icon class="ml-1" color="white"> mdi-menu-down </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="institution-info-slot">
          <div
            class="d-flex mb-2"
            v-for="(item, i) in leftItems()"
            :key="i"
            @click="selectInfoLeft(item)"
          >
            <img
              class="mb-1"
              v-if="item.name === $t('map.info.favorites')"
              :src="require('@/assets/svg/list-institution.svg')"
            />
            <img
              class="mb-1"
              v-if="item.name === $t('map.info.explored')"
              :src="require('@/assets/iconsNewHaven/icons/explored.svg')"
            />
            <strong
              class="institutions-info__select__text institutions-info__select__text--strong ml-2"
            >
              {{ item.lengthValue }}
            </strong>
            <div
              style="font-weight: 300"
              class="institutions-info__select__text ml-1"
              v-t="item.name"
            />
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "SelectLeftBar",
  components: {},
  props: {},
  data() {
    return {
      selectedInfo: null,
      showingPanels: null,
    };
  },
  methods: {
    leftItems() {
      const leftItems = [
        {
          lengthValue: this.favoritesLength,
          name: this.$t("map.info.favorites"),
        },
        {
          lengthValue: this.exploredLength,
          name: this.$t("map.info.explored"),
        },
      ];
      return leftItems;
    },
    selectInfoLeft(index) {
      this.showingPanels = null;
      this.selectedInfo = index;
    },
    selectedInfoLength0(info) {
      if (info.name === this.$t("map.info.favorites")) {
        return this.favoritesLength;
      }
      return this.exploredLength;
    },
    favoritesLengthStart() {
      let favLength = 0;
      this.schools.forEach((school) => {
        if (school.isFavorite) {
          favLength += 1;
        }
      });
      return favLength;
    },
  },
  computed: {
    ...mapGetters({
      schools: "institutions/schools",
      favorites: "institutions/favorites",
    }),
    favoritesLength() {
      let favLength = 0;
      this.schools.forEach((school) => {
        if (school.isFavorite) {
          favLength += 1;
        }
      });
      return favLength;
    },
    exploredLength() {
      let exploredLength = 0;
      this.schools.forEach((school) => {
        if (school.isExplored) {
          exploredLength += 1;
        }
      });
      return exploredLength;
    },
  },
};
</script>
