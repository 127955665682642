<template>
  <div class="student-name_container">
    <div class="student-name_title">
      {{ $t("register.identification.student_name.title") }}
    </div>
    <div class="student-name_subtitle">
      {{ $t("register.identification.student_name.subtitle") }}
    </div>
    <input v-model="name" class="student-name_input" type="text" placeholder="Nombre" />
    <div class="btn_container">
      <v-btn icon @click="backMethod">
        <v-img src="@/assets/iconsPalmira/student_register/icons/back.svg" />
      </v-btn>
      <base-button class="btn-continue" @click="continueMethod" :disabled="isDisabled">
        {{ $t("register.identification.student_name.continue_btn") }}
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/explorer/base/BaseButton.vue";

export default {
  name: "StudentName",
  components: {
    BaseButton,
  },
  data() {
    return {
      name: "",
    };
  },
  mounted() {
    this.name = this.nickname;
  },
  methods: {
    ...mapActions({
      setIdentificationStepOne: "newStudentRegister/setIdentificationStepOne",
    }),
    continueMethod() {
      this.setIdentificationStepOne({ nickname: this.name });
      this.$emit("nextStep");
    },
    backMethod() {
      this.$emit("backStep");
    },
  },
  computed: {
    ...mapGetters({
      nickname: "newStudentRegister/nickname",
    }),
    isDisabled() {
      return this.name === "";
    },
  },
};
</script>
