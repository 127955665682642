<template>
  <section class="bg-gradient-secondary" id="risk">
    <div
      class="bg-gradient-secondary--image"
      :class="{ 'feedback-risk': CONFIG.feedback === 'Palmira' }"
    >
      <div
        class="w-full justify-center max-w-desktop pt-8 pb-4"
        :class="{ 'feedback-risk__div': CONFIG.feedback === 'Palmira' }"
      >
        <h1
          class="flex bg-white w-48 rounded-md px-1 ml-11 text-gray text-xl"
          :class="{ 'feedback-risk__div__title': CONFIG.feedback === 'Palmira' }"
          v-t="'feedback.risk_alert.title_one'"
        />
        <h1
          class="flex bg-white w-52 rounded-md px-1 mt-1 ml-11 text-gray text-xl"
          :class="{ 'feedback-risk__div__title': CONFIG.feedback === 'Palmira' }"
          v-t="'feedback.risk_alert.title_two'"
        />
        <h1
          class="flex bg-white w-36 rounded-md px-1 mt-1 ml-11 text-gray text-xl"
          :class="{ 'feedback-risk__div__title': CONFIG.feedback === 'Palmira' }"
          v-t="'feedback.risk_alert.title_tree'"
        />
        <p
          class="text-dark mt-8 ml-3 text-base md:ml-11"
          :class="{ 'feedback-risk__div__text': CONFIG.feedback === 'Palmira' }"
          v-t="'feedback.risk_alert.text'"
        />
        <div
          class="flex w-full mt-5 md:ml-11"
          :class="{ 'feedback-risk__img-div': CONFIG.feedback === 'Palmira' }"
        >
          <picture
            class="w-1/2 md:w-3/12"
            :class="{ 'feedback-risk__img-div__picture': CONFIG.feedback === 'Palmira' }"
          >
            <img src="@/assets/svg/risk-high.svg" v-if="isRedRisk(riskAlert)" />
            <img src="@/assets/svg/risk-middle.svg" v-else />
          </picture>
          <p
            class="w-1/2 flex justify-center items-center p-3 rounded-md text-xs text-white bg-red-high"
            :class="{ 'feedback-risk__img-div__text': CONFIG.feedback === 'Palmira' }"
            v-t="'feedback.risk_alert.alert'"
          />
        </div>
      </div>
      <p
        class="w-full bg-white mt-7 p-7 text-primary text-center font-medium"
        :class="{ 'feedback-risk__footer': CONFIG.feedback === 'Palmira' }"
        v-t="'feedback.risk_alert.recommendation'"
      />
    </div>
  </section>
</template>

<script>
import CONFIG from "../../config";

export default {
  name: "RiskAlert",
  props: {
    riskAlert: {
      type: Number,
      default: Number,
    },
  },
  data() {
    return {
      CONFIG,
    };
  },
  computed: {
    isRedRisk() {
      return (admissionProbabiliy) => {
        if (+admissionProbabiliy < 0.3) return true;
        return false;
      };
    },
  },
};
</script>
