<template>
  <v-container fluid class="ma-0 pa-0 fill-height">
    <iframe
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      width="100%"
      height="100%"
      scrolling="auto"
      :src="iframeLink"
    >
    </iframe>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import CONFIG from "@/config";

export default {
  name: "Primer",
  computed: {
    ...mapGetters({
      legalGuardianUUID: "authentication/legalGuardianUUID",
      primerShow: "authentication/primerShow",
      feedbackShow: "authentication/feedbackShow",
    }),
    iframeLink() {
      return CONFIG.feedbackApp + this.legalGuardianUUID;
    },
  },
};
</script>
