<template>
  <div>
    <campus-detail toPrimer v-if="!error" />
    <div v-else>{{ error }}</div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CampusDetail from "@/components/explorer/general/campus_detail/CampusDetail.vue";

export default {
  components: { CampusDetail },
  name: "SchoolDetails",
  methods: {
    ...mapActions({
      retrieveCampusDetails: "institutions/retrieveCampusDetails",
      doLoginWithUUID: "authentication/doLoginWithUUID",
      setCurrentStudentWithUUID: "authentication/setCurrentStudentWithUUID",
      hidePrimer: "authentication/hidePrimer",
    }),
  },
  data() {
    return {
      error: "",
    };
  },
  mounted() {
    this.hidePrimer();
    const { uuid, legalguardian, student } = this.$route.params;
    if (legalguardian && student) {
      const callback = () => {
        const callback2 = () => this.hidePrimer();
        this.setCurrentStudentWithUUID({ uuid: student, callback: callback2 });
      };
      this.doLoginWithUUID({ uuid: legalguardian, callback });
    } else if (uuid) {
      /*
      this.retrieveCampusDetails({ campusUuid: uuid })
        .catch((error) => {
          console.error(error);
          this.error = this.$t('error.cant_show_detail 1');
        });
      */
    } else {
      this.error = this.$t("error.cant_show_detail 2");
    }
  },
};
</script>
