<template>
  <section
    v-if="virtualTour"
    class="campus-detail__virtual-tour campus-detail__module mt-2"
    :class="{ 'campus-detail__virtual-tour--mobile': isMobile }"
  >
    <picture class="d-flex flex-row align-center">
      <img src="@/assets/svg/360.svg" width="25px" />
      <h3 class="campus-detail__module__title ml-2" v-t="'campus-details.virtual_tour'" />
    </picture>
    <iframe
      class="fill-width"
      :src="virtualTour"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write;
      encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    />
  </section>
</template>

<script>
export default {
  name: "CampusDetailTour",
  props: {
    virtualTour: {
      type: String,
      default: "",
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
