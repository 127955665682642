<template>
  <div class="cdi-or-icbf_container">
    <div v-if="priorityDetected" class="cdi-or-icbf_title-has_priority_container">
      <p
        class="cdi-or-icbf_title-has_priority"
        v-html="$t('register.priority.cdi_or_icbf.has_priority')"
      />
    </div>
    <div v-else>
      <div class="cdi-or-icbf_title-no_priority_container">
        <p
          class="cdi-or-icbf_title-no_priority"
          v-html="$t('register.priority.cdi_or_icbf.no_priority')"
        />
      </div>
      <div class="cdi-or-icbf_question_title_container">
        <p class="required-field cdi-or-icbf_question_title">
          {{ $t("register.priority.cdi_or_icbf.question") }}
        </p>
      </div>
      <div v-if="opt" class="cdi-or-icbf_warning">
        {{ $t("register.priority.cdi_or_icbf.warning") }}
      </div>
      <v-container class="cdi-or-icbf_question_container">
        <div
          :class="mobile ? 'cdi-or-icbf_question-mobile' : 'cdi-or-icbf_question'"
          :style="
            opt
              ? 'background: #2D10B4; margin-right: 5px;'
              : 'background: white; margin-right: 5px;'
          "
          @click="changeOpt(true)"
        >
          <p :style="opt ? 'color: white;' : 'color: #2D10B4;'">
            {{ $t("register.priority.cdi_or_icbf.yes_opt") }}
          </p>
        </div>
        <div
          :class="mobile ? 'cdi-or-icbf_question-mobile' : 'cdi-or-icbf_question'"
          :style="
            opt === false
              ? 'background: #2D10B4; margin-left: 5px;'
              : 'background: white; margin-left: 5px;'
          "
          @click="changeOpt(false)"
        >
          <p :style="opt === false ? 'color: white;' : 'color: #2D10B4;'">
            {{ $t("register.priority.cdi_or_icbf.no_opt") }}
          </p>
        </div>
      </v-container>
    </div>
    <div class="btn_container">
      <v-btn icon @click="backMethod">
        <v-img src="@/assets/iconsPalmira/student_register/icons/back.svg" />
      </v-btn>
      <base-button v-if="isDisabled" class="btn-skip" @click="skipStep">
        {{ $t("register.priority.cdi_or_icbf.skip_btn") }}
      </base-button>
      <base-button v-else class="btn-continue" @click="continueMethod">
        {{ $t("register.priority.cdi_or_icbf.continue_btn") }}
      </base-button>
    </div>
    <v-dialog
      content-class="elevation-0"
      overlay-color="transparent"
      v-model="showSkipModal"
      max-width="370"
    >
      <warning-modal
        @skip="skipConfirmMethod"
        @complete="completeMethod"
        :title="$t('register.priority.cdi_or_icbf.warning_modal.title')"
        :text="$t('register.priority.cdi_or_icbf.warning_modal.text')"
        color="#FC010A"
        skipModal
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/explorer/base/BaseButton.vue";
import WarningModal from "../WarningModal.vue";

export default {
  name: "CdiOrIcbf",
  components: {
    BaseButton,
    WarningModal,
  },
  data() {
    return {
      opt: null,
      showSkipModal: false,
    };
  },
  props: {
    priorityDetected: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.priorityDetected === false) {
      this.opt = this.optionStore;
    } else {
      this.opt = true;
    }
  },
  methods: {
    ...mapActions({
      setPriorityStepThree: "newStudentRegister/setPriorityStepThree",
    }),
    continueMethod() {
      const data = {
        opt: this.opt,
      };
      this.setPriorityStepThree(data);
      this.$emit("nextStep");
    },
    backMethod() {
      this.$emit("backStep");
    },
    changeOpt(value) {
      this.opt = value;
    },
    skipStep() {
      this.showSkipModal = true;
    },
    skipConfirmMethod() {
      this.$emit("skipStep");
    },
    completeMethod() {
      this.showSkipModal = false;
    },
  },
  computed: {
    ...mapGetters({
      optionStore: "newStudentRegister/cdiOrIcbfPriority",
    }),
    isDisabled() {
      return this.opt === null && this.priorityDetected === false;
    },
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
  },
};
</script>
