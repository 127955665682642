<template>
  <div
    class="campus-detail__panels__container campus-detail__panels__container__3 panel_container_column fill-width"
  >
    <section class="panel_container_column fill-width">
      <span
        v-t="'campus-details.current_performance.enrollment_title'"
        class="campus-detail__panels__performance-title text-left mb-2"
      />
    </section>

    <hr />

    <div
      v-if="getStudentsInUniversity() !== false"
      class="panel_container_row justify-start fill-width"
    >
      <img
        class="ml-0 panel_container_row__abs_img--v5"
        :src="require('@/assets/iconsChile/campusDetail/face_icon_dark_green.svg')"
      />
      <span
        class="panel_container_row__white panel_container_row--text ma-0 panel_container_row--text__green"
      >
        {{ getStudentsInUniversity() }}
      </span>

      <span
        class="campus-detail__panels__performance-title--green-enrollment text-left font-weight-medium"
      >
        {{ $t("campus-details.panels.university") }}
      </span>
    </div>
    <hr />
    <div
      v-if="getStudentsInTechniks() !== false"
      class="panel_container_row justify-start fill-width"
    >
      <img
        class="ml-0 panel_container_row__abs_img--v5"
        :src="require('@/assets/iconsChile/campusDetail/face_icon.svg')"
      />
      <span
        class="panel_container_row__white panel_container_row--text ma-0 panel_container_row--text__salmon"
      >
        {{ getStudentsInTechniks() }}
      </span>

      <span
        class="campus-detail__panels__performance-title--salmon-enrollment text-left font-weight-medium"
      >
        {{ $t("campus-details.panels.cft-ip") }}
      </span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Enrollment",
  computed: {
    ...mapGetters({
      campusDetail: "institutions/campusDetails",
      isGuest: "authentication/isGuest",
    }),
  },
  data() {
    return {
      showIcons: false,
    };
  },
  methods: {
    getStudentsInUniversity() {
      const studentsInUniversity = this.campusDetail.higheredstats_campus.find(
        (stats) => stats.highered_stats_label === 1,
      );
      if (studentsInUniversity !== undefined) {
        return studentsInUniversity.number;
      }
      return false;
    },
    getStudentsInTechniks() {
      const studentsInTechniks = this.campusDetail.higheredstats_campus.find(
        (stats) => stats.highered_stats_label === 2,
      );
      if (studentsInTechniks !== undefined) {
        return studentsInTechniks.number;
      }
      return false;
    },
  },
};
</script>
