<template>
  <div class="confirm-applicant confirm-applicant--add">
    <!--<div v-if="loading">
      <v-progress-circular indeterminate/>
    </div>-->
    <div>
      <!-- v-if="!loading" -->
      <div class="confirm-applicant__title">
        <h1 v-t="'registrationFlow.username_instruction'" class="confirm-applicant__title__text" />
        <img src="@/assets/iconsNewHaven/user_register/icons/face.svg" />
      </div>
      <div class="confirm-applicant__student">
        <div class="confirm-applicant__student__input-div">
          <div class="d-flex mb-3">
            <h6
              class="confirm-applicant__student__input-div__title"
              v-t="'user_register.confirm_applicant.first_name'"
            />
            <p class="pt-1 ml-1" style="color: #f90e1f" v-t="'*'" />
          </div>
          <div v-if="showErrors">
            <p
              v-for="(error, index) in usernameErrors"
              :key="index"
              class="text text--small-01 text--error"
            >
              {{ error }}
            </p>
          </div>
          <input
            v-model="username"
            class="confirm-applicant__student__input-div__input confirm-applicant__student__input-div__input--wide"
            type="text"
            :placeholder="$t('user_register.register_data.form.name')"
          />
        </div>
      </div>
      <div class="justify-space-between d-flex mt-5">
        <img
          src="@/assets/iconsNewHaven/user_register/icons/blue-back.svg"
          style="cursor: pointer"
          class="mr-2"
          @click="closeModal"
        />
        <base-button
          class="btn-continue"
          @click="addUsername"
          :class="{ 'btn-continue--active': isFormCompleted }"
        >
          {{ $t("user_register.register_code.btn") }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/explorer/base/BaseButton.vue";

export default {
  name: "AddIdentificationData",
  components: {
    BaseButton,
  },
  data() {
    return {
      username: null,
      showErrors: false,
    };
  },
  created() {
    this.username = this.userUsername ? this.userUsername : "";
  },
  methods: {
    ...mapActions({
      error: "snackbar/error",
      success: "snackbar/success",
      setStudents: "newStudentRegister/setStudents",
      getStudents: "authentication/getStudents",
      setIdentificationName: "authentication/setIdentificationName",
      setUsername: "userRegister/setUsername",
    }),
    addUsername() {
      if (!this.username) {
        this.showErrors = true;
      } else {
        this.setIdentificationName({
          username: this.username,
        }).then(() => {
          this.setUsername({ username: this.username });
          this.success(this.$t("registrationFlow.success"));
          this.$emit("closeModal");
        });
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
  computed: {
    ...mapGetters({
      userUsername: "authentication/userUsername",
      schoolList: "institutions/schoolList",
      legalGuardianUUID: "authentication/legalGuardianUUID",
      /*loading: 'newStudentRegister/loading',*/
    }),
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
    usernameErrors() {
      const errors = [];
      if (!this.username) {
        errors.push(this.$t("student_register.data.general_error"));
      }
      return errors;
    },
    isFormCompleted() {
      if (this.username) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style>
.v-application .primary--text {
  font-size: 10px;
}
.theme--light.v-list {
  background: #eef5ff;
  color: #2b5bff;
  font-size: 10px;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #1976d2;
}
</style>
