<template>
  <div class="school-filter">
    <div class="school-filter__logo">
      <!-- Close icon -->
      <v-icon @click="closeFilter" class="school-filter__close">
        $vuetify.icons.closeFilter
      </v-icon>
    </div>
    <div class="school-filter__btns_div">
      <base-button class="school-filter__cancel mr-5" @click="clean">
        {{ $t("map.filter.clean") }}
      </base-button>
      <div class="school-filter__apply" @click="applyFilters">
        {{ $t("map.filter.apply") }}
      </div>
    </div>
    <div class="school-filter__profile">
      <div class="school-filter__profile__main">
        <p class="school-filter__profile__text">
          {{ $t("map.filter.admission_title") }}
        </p>
        <div class="school-filter__profile__div mt-2 mb-2">
          <button
            class="school-filter__profile__div__btn"
            :class="{ 'school-filter__profile__div__btn__selected': selectedVacancies === 1 }"
            @click="filterSelect('vacancies')"
          >
            <img
              class="mr-2"
              :src="
                selectedVacancies === 1
                  ? require('@/assets/iconsChile/filter/white-face.svg')
                  : require('@/assets/iconsChile/filter/face.svg')
              "
            />
            {{ $t("map.filter.vacancies_btn") }}
          </button>
          <button class="school-filter__profile__div__btn" v-if="false">
            <img class="mr-2" src="@/assets/iconsChile/filter/list.svg" />
            {{ $t("map.filter.list_btn") }}
          </button>
          <button class="school-filter__profile__div__btn" v-if="false">
            <img class="mr-2" src="@/assets/iconsChile/filter/arrow.svg" />
            {{ $t("map.filter.applications_btn") }}
          </button>
        </div>
      </div>
      <div class="school-filter__slider">
        <div class="school-filter__slider-div">
          <p class="school-filter__slider-div__text">
            {{ $t("map.filter.payment") }}
          </p>
          <v-switch color="#2B5BFF" v-model="addPayment" inset></v-switch>
        </div>
        <div v-if="addPayment" class="school-filter__profile__div">
          <div class="school-filter__profile__div__slider">
            <img class="mr-2" :src="getIconClassPayment" width="20" />
            <p class="school-filter__profile__div__slider__text">{{ getPaymentText }}</p>
          </div>
          <v-slider
            v-model="paymentFilterValue"
            :thumb-color="'#ffffff'"
            :color="'linear-gradient(90deg, #11D3D9 0.97%, #1595E9 49.68%, #8E227F 99.19%);'"
          ></v-slider>
        </div>
      </div>
      <div class="school-filter__slider">
        <div class="school-filter__slider-div">
          <p class="school-filter__slider-div__text">
            {{ $t("map.filter.distance") }}
          </p>
          <v-switch color="#2B5BFF" v-model="addDistance" inset></v-switch>
        </div>
        <div v-if="addDistance" class="school-filter__profile__div">
          <div class="school-filter__profile__div__slider">
            <img class="mr-2" :src="getDistanceIcon" width="20" />
            <p class="school-filter__profile__div__slider__text">{{ getDistanceText }}</p>
          </div>
          <v-slider
            v-model="distanceFilterValue"
            :thumb-color="'#ffffff'"
            :color="'linear-gradient(90deg, #11D3D9 0.97%, #1595E9 49.68%, #8E227F 99.19%);'"
          ></v-slider>
        </div>
      </div>
      <div class="school-filter__slider" style="height: 120px !important">
        <div class="school-filter__slider-div flex-column" style="align-items: flex-start">
          <p class="school-filter__slider-div__text">
            {{ $t("map.filter.performance") }}
          </p>
          <div class="d-flex mt-5">
            <button
              class="school-filter__performance-btn"
              :class="{
                'school-filter__performance-btn--selected': selectedPerformanceFilter === 'Alto',
              }"
              @click="setPerformanceSchool(1)"
            >
              <img src="@/assets/iconsChile/campusDetail/performance-high.svg" />
              <p
                v-t="'map.filter.high_level'"
                class="school-filter__performance-btn--text"
                :class="{
                  'school-filter__performance-btn--text-white':
                    selectedPerformanceFilter === 'Alto',
                }"
              />
            </button>
            <button
              class="school-filter__performance-btn"
              :class="{
                'school-filter__performance-btn--selected': selectedPerformanceFilter === 'Medio',
              }"
              @click="setPerformanceSchool(2)"
            >
              <img src="@/assets/iconsChile/campusDetail/performance-middle.svg" />
              <p
                v-t="'map.filter.medium_level'"
                class="school-filter__performance-btn--text"
                :class="{
                  'school-filter__performance-btn--text-white':
                    selectedPerformanceFilter === 'Medio',
                }"
              />
            </button>
            <button
              class="school-filter__performance-btn"
              :class="{
                'school-filter__performance-btn--selected':
                  selectedPerformanceFilter === 'Medio-Bajo',
              }"
              @click="setPerformanceSchool(3)"
            >
              <img src="@/assets/iconsChile/campusDetail/performance-middle-low.svg" />
              <p
                v-t="'map.filter.medium_low_level'"
                class="school-filter__performance-btn--text"
                :class="{
                  'school-filter__performance-btn--text-white':
                    selectedPerformanceFilter === 'Medio-Bajo',
                }"
              />
            </button>
            <button
              class="school-filter__performance-btn"
              :class="{
                'school-filter__performance-btn--selected':
                  selectedPerformanceFilter === 'Insuficiente',
              }"
              @click="setPerformanceSchool(4)"
            >
              <img src="@/assets/iconsChile/campusDetail/performance-insufficient.svg" />
              <p
                v-t="'map.filter.low_level'"
                class="school-filter__performance-btn--text"
                :class="{
                  'school-filter__performance-btn--text-white':
                    selectedPerformanceFilter === 'Insuficiente',
                }"
              />
            </button>
          </div>
        </div>
      </div>
      <div class="school-filter__slider d-flex align-center">
        <button
          style="height: 38px"
          class="school-filter__profile__div__btn mt-0"
          :class="{ 'school-filter__profile__div__btn__selected': pieSelected === true }"
          @click="prioritySelected('pie')"
        >
          <img width="30" height="24" src="@/assets/iconsChile/filter/pie.svg/" class="mr-2" />
          {{ $t("map.filter.pie_long") }}
        </button>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <button class="school-filter__profile__div__tooltip" v-bind="attrs" v-on="on">!</button>
          </template>
          <span> {{ $t("map.filter.tooltips.pie") }}</span>
        </v-tooltip>
      </div>
      <div class="school-filter__slider d-flex align-center">
        <button
          style="height: 38px"
          class="school-filter__profile__div__btn mt-0"
          :class="{ 'school-filter__profile__div__btn__selected': sepSelected === true }"
          @click="prioritySelected('sep')"
        >
          <img width="30" height="24" src="@/assets/iconsChile/filter/sep.svg/" class="mr-2" />
          {{ $t("map.filter.sep_long") }}
        </button>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <button class="school-filter__profile__div__tooltip" v-bind="attrs" v-on="on">!</button>
          </template>
          <span>{{ $t("map.filter.tooltips.sep") }}</span>
        </v-tooltip>
      </div>
      <div class="school-filter__profile__main mt-2">
        <p class="school-filter__profile__text">
          {{ $t("map.filter.dependency_title") }}
        </p>
        <div class="school-filter__profile__div">
          <button
            style="width: 144px"
            class="school-filter__profile__div__btn mr-2"
            :class="{ 'school-filter__profile__div__btn__selected': selectedDependency === 3 }"
            @click="dependencySelect(3)"
          >
            {{ $t("map.filter.private_school") }}
          </button>
          <button
            style="width: 144px"
            class="school-filter__profile__div__btn mr-2"
            :class="{ 'school-filter__profile__div__btn__selected': selectedDependency === 1 }"
            @click="dependencySelect(1)"
          >
            {{ $t("map.filter.public_school") }}
          </button>
          <button
            style="width: 144px"
            class="school-filter__profile__div__btn mr-2"
            :class="{ 'school-filter__profile__div__btn__selected': selectedDependency === 2 }"
            @click="dependencySelect(2)"
          >
            {{ $t("map.filter.mix_school") }}
          </button>
          <button
            style="width: 144px"
            class="school-filter__profile__div__btn mr-2"
            :class="{ 'school-filter__profile__div__btn__selected': selectedDependency === 4 }"
            @click="dependencySelect(4)"
          >
            {{ $t("map.filter.corporation") }}
          </button>
          <button
            style="width: 144px"
            class="school-filter__profile__div__btn mr-2"
            :class="{ 'school-filter__profile__div__btn__selected': selectedDependency === 5 }"
            @click="dependencySelect(5)"
          >
            {{ $t("map.filter.local_education") }}
          </button>
        </div>
      </div>
      <div class="school-filter__slider">
        <v-expansion-panels height="44">
          <v-expansion-panel
            v-for="(item, i) in 1"
            :key="i"
            active-class="school-filter__profile__expansion-panel"
            style="border: 1px solid #2b5bff"
          >
            <v-expansion-panel-header
              class="school-filter__profile__expansion-panel__header"
              style="color: #2b5bff"
            >
              <img
                class="mr-5"
                src="@/assets/iconsChile/filter/multimedia-btn.svg/"
                width="24"
                height="24"
                style="flex: none !important"
              />
              {{ $t("map.filter.multimedia") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="school-filter__profile__div school-filter__profile__div__flex mt-3">
                <div
                  class="school-filter__profile__div__box mr-1"
                  @click="filterSelect('box1')"
                  :class="{ 'school-filter__profile__div__box--selected': selectFilterBox1 === 1 }"
                >
                  <p
                    class="school-filter__profile__div__box__title"
                    :class="{
                      'school-filter__profile__div__box__title--selected': selectFilterBox1 === 1,
                    }"
                  >
                    {{ $t("campus-details.media.tour") }}
                  </p>
                </div>
                <div>
                  <div
                    class="school-filter__profile__div__box mr-1"
                    @click="filterSelect('box2')"
                    :class="{
                      'school-filter__profile__div__box--selected': selectFilterBox2 === 1,
                    }"
                  >
                    <p
                      class="school-filter__profile__div__box__title"
                      :class="{
                        'school-filter__profile__div__box__title--selected': selectFilterBox2 === 1,
                      }"
                    >
                      {{ $t("campus-details.media.video") }}
                    </p>
                  </div>
                </div>
                <!-- TODO pending logic in video and overflight -->
                <div
                  class="school-filter__profile__div__box"
                  @click="filterSelect('box3')"
                  :class="{ 'school-filter__profile__div__box--selected': selectFilterBox3 === 1 }"
                >
                  <p
                    class="school-filter__profile__div__box__title"
                    :class="{
                      'school-filter__profile__div__box__title--selected': selectFilterBox3 === 1,
                    }"
                  >
                    {{ $t("campus-details.media.overflight") }}
                  </p>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import BaseButton from "@/components/explorer/base/BaseButton.vue";
import PerformanceLevelSelector from "./filter_utils/PerformanceLevelSelector.vue";
import PaymentSelector from "./filter_utils/PaymentSelector.vue";
import DistanceRange from "./filter_utils/DistanceRange.vue";
import RatexSelector from "./filter_utils/RatexSelector.vue";
import CONFIG from "../../../config";

export default {
  name: "SchoolsFilter",
  components: {
    PerformanceLevelSelector,
    PaymentSelector,
    BaseButton,
    DistanceRange,
    RatexSelector,
  },
  data() {
    return {
      performanceCategoriesSelected: [],
      monthlyPaymentSelected: [],
      distanceRadiusSelected: {},
      ratexSelected: [],
      pieSelected: false,
      sepSelected: false,
      region: "",
      commune: "",
      address: "",
      grade: "",
      specialty: "",
      gender: "",
      sexTypes: [],
      lat: null,
      lng: null,
      showTooltip: false,
      defaltLat: null,
      defaltLng: null,
      communeOptions: [],
      specialtyOptions: [],
      googleAddressComponents: [],
      cancelNextUpdate: false,
      loadingBtn: false,
      selectFilter: null,
      selectFilterBox1: null,
      selectFilterBox2: null,
      selectFilterBox3: null,
      selectedVacancies: null,
      selectedDependency: null,
      paymentFilterValue: 50,
      performanceFilterValue: 50,
      distanceFilterValue: 5,
      selectedDistanceFilter: null,
      addPayment: false,
      addPerformance: false,
      selectedPaymentFilter: null,
      selectedPerformanceFilter: null,
      addDistance: false,
    };
  },
  validations: {
    commune: {
      isValid() {
        return (
          this.communeOptions.length === 0 ||
          (this.communeOptions.length !== 0 && this.commune !== "")
        );
      },
    },
    specialty: {
      isValid() {
        return (
          this.specialtyOptions.length === 0 ||
          (this.specialtyOptions.length !== 0 && this.specialty !== "")
        );
      },
    },
    sexTypes: {
      isValid() {
        return (
          this.specialtyOptions.length === 0 ||
          (this.specialtyOptions.length !== 0 && this.sexTypes.length !== 0)
        );
      },
    },
  },
  mounted() {
    if (this.isGuest) {
      this.setGuestLocation();
    }
  },
  created() {
    this.monthlyPayments.map(() => this.monthlyPaymentSelected.push({}));
    this.performanceCategories.map(() => this.performanceCategoriesSelected.push({}));
    this.ratexValues.map(() => this.ratexSelected.push({}));
  },
  methods: {
    ...mapActions({
      retrieveCampuses: "institutions/retrieveCampuses",
      setFilters: "institutions/setFilters",
      cleanFilters: "institutions/cleanFilters",
      setGuestData: "authentication/setGuestData",
      setMultimediaFilter: "filters/setMultimediaFilter",
      cleanGuestGrades: "authentication/cleanGuestGrades",
      setMapCenter: "institutions/setMapCenter",
      setTypeFilter: "filters/setTypeFilter",
      setSepFilter: "filters/setSepFilter",
      setPieFilter: "filters/setPieFilter",
      setLoginFilter: "filters/setLoginFilter",
      setPaymentFilter: "filters/setPaymentFilter",
      setPerformanceFilter: "filters/setPerformanceFilter",
      setDistanceFilter: "filters/setDistanceFilter",
      setVacnaciesFilter: "filters/setVacnaciesFilter",
      setDependencyFilter: "filters/setDependencyFilter",
      setCleanedFilters: "filters/setCleanedFilters",
    }),
    setGuestLocation() {
      const { region, commune, address, coordinate } = this.guestLocation;
      this.region = region;
      this.changeCommuneOptions(region);
      this.commune = commune;
      this.updateCommune(commune);
      this.address = address;
      this.lat = parseFloat(coordinate.lat);
      this.lng = parseFloat(coordinate.lng);
    },
    setGuestDataDefault() {
      this.grade = "";
      this.specialty = "";
      this.sexTypes = [];
      this.specialtyOptions = [];
    },
    applyFilters() {
      this.setCleanedFilters({ bool: false });
      this.setLoginFilter({ login: false });
      this.setMultimediaFilter({ multimedia: [] });
      this.setPaymentFilter({ payment: null });
      this.setPerformanceFilter({ performance: null });
      this.setSepFilter({ priority: null });
      this.setPieFilter({ priority: null });
      this.setLoginFilter({ login: false });
      this.setDistanceFilter({ distance: null });
      this.setDependencyFilter({ dependency: null });
      // If no filter has been selected, then all filter in the category are used
      const selectedBoxes = [];
      let lat;
      let lng;
      if (!this.isGuest) {
        if (this.currentStudent.address === null) {
          lat = this.legalGuardianAddress[0].address_lat;
          lng = this.legalGuardianAddress[0].address_lon;
        } else {
          lat = this.currentStudent.address[0].address_lat === null;
          lng = this.currentStudent.address[0].address_lon === null;
        }
      } else {
        // eslint-disable-next-line
        lat = this.guestLocation.coordinate.lat;
        // eslint-disable-next-line
        lng = this.guestLocation.coordinate.lng;
      }
      if (this.selectFilterBox1 || this.selectFilterBox2 || this.selectFilterBox3) {
        if (this.selectFilterBox1) {
          selectedBoxes.push("tour360");
        }
        if (this.selectFilterBox2) {
          selectedBoxes.push("video");
        }
        if (this.selectFilterBox3) {
          selectedBoxes.push("droneFlight");
        }
      }
      if (this.selectedVacancies === 1) {
        this.setVacnaciesFilter({ vacancies: true });
      } else {
        this.setVacnaciesFilter({ vacancies: null });
      }
      if (selectedBoxes.length > 0) {
        this.setMultimediaFilter({ multimedia: selectedBoxes });
      } else {
        this.setMultimediaFilter({ multimedia: [] });
      }
      if (this.addPayment) {
        this.setPaymentFilter({ payment: this.selectedPaymentFilter });
      } else {
        this.setPaymentFilter({ payment: null });
      }

      if (this.addPerformance) {
        this.setPerformanceFilter({ performance: this.selectedPerformanceFilter });
      } else {
        this.setPerformanceFilter({ performance: null });
      }
      if (this.sepSelected) {
        this.setSepFilter({ priority: true });
      } else {
        this.setSepFilter({ priority: null });
      }
      if (this.pieSelected) {
        this.setPieFilter({ priority: true });
      } else {
        this.setPieFilter({ priority: null });
      }
      if (this.addDistance) {
        this.setDistanceFilter({ distance: this.selectedDistanceFilter });
      } else {
        this.setDistanceFilter({ distance: null });
      }
      if (this.selectedDependency) {
        this.setDependencyFilter({ dependency: this.selectedDependency });
      } else {
        this.setDependencyFilter({ dependency: null });
      }
      this.$emit("ready");
    },
    clean() {
      this.pieSelected = false;
      this.sepSelected = false;
      this.setMultimediaFilter({ multimedia: [] });
      this.setPaymentFilter({ payment: null });
      this.setPerformanceFilter({ performance: null });
      this.setSepFilter({ priority: null });
      this.setPieFilter({ priority: null });
      this.setLoginFilter({ login: false });
      this.setDistanceFilter({ distance: null });
      this.setVacnaciesFilter({ vacancies: null });
      this.setDependencyFilter({ dependency: null });
      this.addPayment = false;
      this.addPerformance = false;
      this.selectedPaymentFilter = null;
      this.selectedPerformanceFilter = null;
      this.setCleanedFilters({ bool: true });
      this.cleanFilters()
        .then(() => {
          if (this.isGuest) {
            this.setGuestDataDefault();
          }
        })
        .then(() => this.$emit("ready"));
    },
    performanceCategoryArray() {
      const categoryValueArray = [];
      this.performanceCategories.forEach((category) => {
        if (category.value !== 99) {
          if (categoryValueArray.indexOf(category.value) === -1) {
            categoryValueArray.push(category.value);
          }
        }
      });
      return categoryValueArray;
    },
    performanceCategoryTag(index) {
      let tag = "";
      if (index === 0) {
        tag = ": Alto";
      } else if (index === 1) {
        tag = ": Medio";
      } else if (index === 2) {
        tag = ": Medio bajo";
      } else if (index === 3) {
        tag = ": Insuficiente";
      }
      return tag;
    },
    updateCommune(newVal) {
      this.$v.commune.$touch();
      this.address = "";
      const { region: regionSelected } = this;
      const communeSelected = this.regionOptions
        .find((region) => region.uuid === regionSelected)
        .commune_options.find((commune) => commune.uuid === newVal);
      this.lat = `${communeSelected.lat ? communeSelected.lat : 0}`;
      this.lng = `${communeSelected.lng ? communeSelected.lng : 0}`;
      this.defaltLat = `${communeSelected.lat ? communeSelected.lat : 0}`;
      this.defaltLng = `${communeSelected.lng ? communeSelected.lng : 0}`;
    },
    changeCommuneOptions(newRegion) {
      if (newRegion.length > 0) {
        this.address = "";
        const communesOrdered = this.regionOptions.find(
          (region) => region.uuid === newRegion
        ).commune_options;
        this.communeOptions = communesOrdered.sort((communeA, communeB) =>
          communeA.label.localeCompare(communeB.label)
        );
        this.commune = "";
      }
    },
    search() {
      if (!this.addressToSearchInGMaps || this.cancelNextUpdate) {
        this.cancelNextUpdate = false;
        this.loadingBtn = false;
        return null;
      }
      return axios
        .get("https://maps.googleapis.com/maps/api/geocode/json?region=cl", {
          params: { address: this.addressToSearchInGMaps, key: CONFIG.gMapKey },
        })
        .then(async (response) => {
          const { results } = response.data;
          const resultsFiltered = results.filter((result) =>
            result.address_components.some((address) => address.short_name === "CL")
          );
          if (resultsFiltered.length > 0) {
            const betterResult = resultsFiltered[0];
            this.lat = `${betterResult.geometry.location.lat}`;
            this.lng = `${betterResult.geometry.location.lng}`;
            this.cancelNextUpdate = true;
            this.$nextTick(() => {
              this.address = betterResult.formatted_address;
            });
            this.googleAddressComponents = betterResult.address_components;
            return true;
          }
          return false;
        })
        .finally(() => {
          this.loadingBtn = false;
        });
    },
    changeSpecialtyOptions(newGrade) {
      if (newGrade && newGrade.length > 0) {
        const specialtyOptions = this.gradeOptions.find(
          (grade) => grade.name === newGrade
        ).grade_uuids;
        if (specialtyOptions.length === 1) {
          this.specialty = specialtyOptions[0].uuid;
        } else {
          this.specialty = "";
        }
        this.specialtyOptions = specialtyOptions;
      }
    },
    closeFilter() {
      return this.$emit("ready");
    },
    filterSelect(box) {
      if (box === "vacancies") {
        if (this.selectedVacancies !== 1) {
          this.selectedVacancies = 1;
        } else {
          this.selectedVacancies = null;
        }
      }
      if (box === "box1") {
        if (this.selectFilterBox1 !== 1) {
          this.selectFilterBox1 = 1;
        } else {
          this.selectFilterBox1 = null;
        }
      }
      if (box === "box2") {
        if (this.selectFilterBox2 !== 1) {
          this.selectFilterBox2 = 1;
        } else {
          this.selectFilterBox2 = null;
        }
      }
      if (box === "box3") {
        if (this.selectFilterBox3 !== 1) {
          this.selectFilterBox3 = 1;
        } else {
          this.selectFilterBox3 = null;
        }
      }
    },
    dependencySelect(dependencyId) {
      if (this.selectedDependency === null) {
        this.selectedDependency = dependencyId;
        return;
      }
      if (this.selectedDependency === dependencyId) {
        this.selectedDependency = null;
        return;
      }
      this.selectedDependency = dependencyId;
      return;
    },
    prioritySelected(priority) {
      if (priority === "pie") {
        if (!this.pieSelected) {
          this.pieSelected = true;
        } else {
          this.pieSelected = false;
        }
      }
      if (priority === "sep") {
        if (!this.sepSelected) {
          this.sepSelected = true;
        } else {
          this.sepSelected = false;
        }
      }
    },
    setPerformanceSchool(index) {
      if (this.addPerformance === false) {
        this.addPerformance = true;
        let text = "";
        if (index === 1) {
          text = "Alto";
        } else if (index === 2) {
          text = "Medio";
        } else if (index === 3) {
          text = "Medio-Bajo";
        } else if (index === 4) {
          text = "Insuficiente";
        } else {
          text = "Insuficiente";
        }
        return (this.selectedPerformanceFilter = text);
      }
      let text = "";
      if (index === 1) {
        text = "Alto";
      } else if (index === 2) {
        text = "Medio";
      } else if (index === 3) {
        text = "Medio-Bajo";
      } else if (index === 4) {
        text = "Insuficiente";
      } else {
        text = "Insuficiente";
      }
      if (this.selectedPerformanceFilter === text) {
        this.addPerformance = false;
        return (this.selectedPerformanceFilter = null);
      }
      return (this.selectedPerformanceFilter = text);
    },
  },
  computed: {
    ...mapGetters({
      monthlyPayments: "options/monthlyPayments",
      performanceCategories: "options/performanceCategories",
      referenceLocation: "authentication/homeLocation",
      bounds: "institutions/cornerBounds",
      isGuest: "authentication/isGuest",
      regionOptions: "options/regions",
      gradeOptions: "options/grades",
      guestLocation: "authentication/guestLocation",
      currentStudent: "authentication/currentStudent",
      legalGuardianAddress: "authentication/legalGuardianAddress",
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    getDistanceRadius() {
      return CONFIG.distanceRadius;
    },
    getIconClassPayment() {
      let icon = "";
      if (this.paymentFilterValue === 0) {
        icon = "price-free.svg";
      } else if (this.paymentFilterValue > 0 && this.paymentFilterValue <= 40) {
        icon = "price-10-50.svg";
      } else if (this.paymentFilterValue >= 41 && this.paymentFilterValue <= 80) {
        icon = "price-50-100.svg";
      } else if (this.paymentFilterValue >= 81) {
        icon = "price-100.svg";
      } else {
        icon = "price-100.svg";
      }
      // eslint-disable-next-line
      const svg = require("@/assets/iconsChile/campusDetail/" + icon);
      return svg;
    },
    getDistanceIcon() {
      let imgFile = "";
      if (this.distanceFilterValue >= 0 && this.distanceFilterValue <= 10) {
        imgFile = "distance-person-low.svg";
      } else if (this.distanceFilterValue >= 11 && this.distanceFilterValue <= 20) {
        imgFile = "distance-person-middle.svg";
      } else if (this.distanceFilterValue >= 21 && this.distanceFilterValue <= 50) {
        imgFile = "distance-car-middle-high.svg";
      } else if (this.distanceFilterValue >= 51) {
        imgFile = "distance-car-high.svg";
      } else {
        imgFile = "distance-car-high.svg";
      }
      // eslint-disable-next-line
      const images = require("@/assets/iconsChile/campusDetail/" + imgFile);
      return images;
    },
    getDistanceText() {
      let text = "";
      if (this.distanceFilterValue >= 0 && this.distanceFilterValue <= 10) {
        text = "De 0 a 1km";
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedDistanceFilter = {
          limit: false,
          from: 0,
          to: 1,
        };
      } else if (this.distanceFilterValue >= 0 && this.distanceFilterValue <= 20) {
        text = "De 0km a 2km";
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedDistanceFilter = {
          limit: false,
          from: 0,
          to: 2,
        };
      } else if (this.distanceFilterValue >= 0 && this.distanceFilterValue <= 50) {
        text = "De 0km a 5km";
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedDistanceFilter = {
          limit: false,
          from: 0,
          to: 5,
        };
      } else if (this.distanceFilterValue >= 51) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedDistanceFilter = {
          limit: false,
          from: 0,
          to: 50,
        };
        text = "Mas de 5km";
      } else {
        text = "Mas de 5km";
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedDistanceFilter = {
          limit: false,
          from: 0,
          to: 50,
        };
      }
      return text;
    },
    getPaymentText() {
      let text = "";
      let label = "";
      if (this.paymentFilterValue === 0) {
        label = "Gratuito";
        text = label;
      } else if (this.paymentFilterValue > 0 && this.paymentFilterValue <= 40) {
        label = "$1 a $50 mil";
        text = ["$1.000 a $10.000", "$10.001 a $25.000", "$25.001 a $50.000"];
      } else if (this.paymentFilterValue >= 41 && this.paymentFilterValue <= 80) {
        label = "$50.001 a $100.000";
        text = label;
      } else if (this.paymentFilterValue >= 81) {
        label = "Más de $100.000";
        text = label;
      } else {
        label = "Más de $100.000";
        text = label;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.selectedPaymentFilter = text;
      return label;
    },
    ratexValues() {
      return [4, 3, 2, 1];
    },
    addressToSearchInGMaps() {
      const { region: regionId, commune: communeId } = this;
      if (!(communeId.length > 0) || !(regionId.length > 0)) {
        return null;
      }
      const regionValues = this.regionOptions.find((region) => region.uuid === regionId);
      const communeValues = regionValues.commune_options.find(
        (commune) => commune.uuid === communeId
      );
      if (!communeValues) {
        return null;
      }
      const regionLabel = regionValues.label;
      const communeLabel = communeValues.label;

      return `${this.address}, ${communeLabel}, ${regionLabel}`;
    },
    location() {
      if (this.communeOptions.length !== 0) {
        return {
          coordinate: {
            lat: `${this.address === "" ? this.defaltLat : this.lat}`,
            lng: `${this.address === "" ? this.defaltLng : this.lng}`,
          },
          region: this.region,
          commune: this.commune,
          address: this.address,
        };
      }
      return null;
    },
    grade_uuids() {
      return this.specialty !== "" ? [this.specialty] : null;
    },
    communeErrors() {
      const errors = [];
      if (!this.$v.commune.isValid) {
        errors.push(this.$t("map.filter.error.commune.required"));
      }
      return errors;
    },
    sexTypesErrors() {
      const errors = [];
      if (!this.$v.sexTypes.isValid) {
        errors.push(this.$t("map.filter.error.sex_type.required"));
      }
      return errors;
    },
    specialtyErrors() {
      const errors = [];
      if (!this.$v.specialty.isValid) {
        errors.push(this.$t("map.filter.error.specialty.required"));
      }
      return errors;
    },
    disabledBtn() {
      return this.loadingBtn;
    },
  },
  watch: {
    address() {
      // Timer to delay until user stop to write 3 segs to search
      this.loadingBtn = true;
      const oldTimer = this.addressTriggerTimer;
      clearTimeout(oldTimer);
      const newTimer = setTimeout(this.search, 3000);
      this.addressTriggerTimer = newTimer;
    },
    addressToSearchInGMaps() {
      const { region: regionId, commune: communeId } = this;
      if (!(communeId.length > 0) || !(regionId.length > 0)) {
        return null;
      }
      const regionValues = this.regionOptions.find((region) => region.uuid === regionId);
      const communeValues = regionValues.commune_options.find(
        (commune) => commune.uuid === communeId
      );
      if (!communeValues) {
        return null;
      }
      const regionLabel = regionValues.label;
      const communeLabel = communeValues.label;

      return `${this.address}, ${communeLabel}, ${regionLabel}`;
    },
    location() {
      if (this.communeOptions.length !== 0) {
        return {
          coordinate: {
            lat: `${this.address === "" ? this.defaltLat : this.lat}`,
            lng: `${this.address === "" ? this.defaltLng : this.lng}`,
          },
          region: this.region,
          commune: this.commune,
          address: this.address,
        };
      }
      return null;
    },
    grade_uuids() {
      return this.specialty !== "" ? [this.specialty] : null;
    },
    communeErrors() {
      const errors = [];
      if (!this.$v.commune.isValid) {
        errors.push(this.$t("map.filter.error.commune.required"));
      }
      return errors;
    },
    specialtyErrors() {
      const errors = [];
      if (!this.$v.specialty.isValid) {
        errors.push(this.$t("map.filter.error.specialty.required"));
      }
      return errors;
    },
    disabledBtn() {
      return this.loadingBtn;
    },
  },
};
</script>
<style>
.v-slider--horizontal .v-slider__track-container {
  height: 12px !important;
  background: linear-gradient(90deg, #11d3d9 0.97%, #1595e9 49.68%, #8e227f 99.19%) !important;
  border-radius: 4px;
}
.theme--light.v-slider .v-slider__track-background {
  border-radius: 4px;
}
</style>
