<template>
  <div class="meeting-organizer">
    <!-- top section -->
    <div style="height: 20%" class="relative">
      <div class="vertical-center" style="text-align: left; margin-left: 30px; margin-top: 10px">
        <div class="meeting-organizer-text bold">Agendar una reunión</div>
        <div class="meeting-organizer-text">
          {{ subtitle }}
        </div>
      </div>
    </div>
    <!-- end top section -->
    <!-- main section -->
    <div style="width: 100%; height: 65%">
      <!-- schedule picker -->
      <div class="main-section" v-if="currentStage == 0">
        <!-- calendar -->
        <div style="width: 50%; min-width: 50%">
          <CalendarPanel
            style="
              font-family: Roboto;
              background-color: #eef5ff;
              width: 100%;
              height: 100%;
              padding-left: 30px;
            "
            @select="selectDate"
            :value="currentDaySelectedValue"
            :disabledDate="
              (date) => {
                return ['1', '3', '5'].includes(date.getDay());
              }
            "
          />
        </div>
        <!-- end calendar -->
        <!-- times -->
        <transition name="fade" v-if="currentStage == 0">
          <div class="time-list-container" v-if="currentDaySelected != null">
            <span class="time-title">Mañana</span>
            <div
              v-for="(time, index) in times.early"
              :key="index"
              :class="getTimeClass(time)"
              @click="selectTime(time)"
            >
              <span class="center time"> {{ time.text }}</span>
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div class="time-list-container" v-if="currentDaySelected != null">
            <span class="time-title">Tarde</span>
            <div
              v-for="(time, index) in times.late"
              :key="index"
              :class="getTimeClass(time)"
              @click="selectTime(time)"
            >
              <span class="center time"> {{ time.text }}</span>
            </div>
          </div>
        </transition>
        <!-- end times -->
      </div>
      <!-- end schedule picker -->
      <!-- entity picker -->
      <div v-else-if="currentStage == 1" class="main-section">
        <!-- entities -->
        <div
          style="
            width: 50%;
            min-width: 50%;
            max-height: 320px;
            overflow-y: auto;
            overflow-x: hidden;
          "
        >
          <!-- entities information -->
          <div
            class="meeting-organizer-text"
            style="text-align: left; margin-left: 30px; margin-top: 10px"
            v-html="description"
          ></div>
          <!-- end entities information -->
          <!-- entities list -->
          <div style="margin-top: 10px" class="outer">
            <div
              v-for="(entity, index) in entities"
              :key="index"
              class="inner"
              @click="selectEntity(entity)"
            >
              <img :src="entity.img" class="entity-img" />
              <div class="entity-name">{{ entity.name }}</div>
            </div>
          </div>

          <!-- end entities list -->
        </div>
        <!-- end entities -->
        <!-- entity -->
        <transition name="fade">
          <div
            style="width: 50%; min-width: 50%; border-radius: 10px"
            v-if="currentEntitySelected != null"
          >
            <div
              style="
                background-color: white;
                margin-top: 10px;
                width: 80%;
                margin-left: 10%;
                height: calc(100% - 10px);
              "
            >
              <div style="padding: 20px">
                <div style="display: flex; flex-direction: row" class="relative">
                  <!-- image -->
                  <img :src="currentEntitySelected.img" class="entity-img" />
                  <!-- end image -->
                  <!-- name -->
                  <span class="vertical-center meeting-organizer-text" style="margin-left: 70px">
                    {{ currentEntitySelected.name }}
                  </span>
                  <!-- end name -->
                </div>
                <!-- meeting information -->
                <div class="meeting-organizer-text">
                  <b>Tu reunión será agendada vía zoom:</b>
                  <br />
                  <br />
                  {{ getDate() }}
                  <br />
                  {{ getYear() }}
                  <br />
                  {{ currentTimeSelected.text }}
                </div>
                <!-- end meeding information -->
              </div>
            </div>
          </div>
        </transition>
        <!-- end entity -->
      </div>
      <!-- end entity picker -->
    </div>
    <!-- end main section -->
    <!-- footer section -->
    <div style="height: 15%; position: relative">
      <!-- back button -->

      <div
        @click="currentStage -= 1"
        v-if="currentStage > 0"
        style="position: absolute; left: 10px; top: 0px"
      >
        <img
          src="@/assets/dashboard/icons/back.svg"
          style="
            margin: auto;
            margin-top: 25px;
            transform: scale(2);
            margin-left: 15px;
            cursor: pointer;
          "
        />
      </div>
      <!-- end back button -->
      <!-- continue button -->
      <div style="position: absolute; right: 10px; top: 0px">
        <div @click="continueButton()">
          <div :class="getButtonClass()">
            <span> {{ continueText }} </span>
          </div>
        </div>
      </div>

      <!-- end continue button -->
    </div>
    <!-- end footer section -->
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";

const { CalendarPanel } = DatePicker;
export default {
  name: "MeetingOrganizer",
  components: {
    CalendarPanel,
  },
  props: {
    description: {
      required: false,
      type: String,
      default: "",
    },
    entities: {
      type: Array,
      required: true,
    },
    entitySelectionText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentStage: 0,
      currentEntitySelected: null,
      currentDaySelected: null,
      currentTimeSelected: null,
      currentDaySelectedValue: new Date(NaN),
      times: {
        early: [
          { text: "8:00 AM", hour: 8, available: true },
          { text: "9:00 AM", hour: 9, available: false },
          { text: "10:00 AM", hour: 10, available: true },
          { text: "11:00 AM", hour: 11, available: true },
        ],
        late: [
          { text: "3:00 PM", hour: 15, available: true },
          { text: "4:00 PM", hour: 16, available: false },
          { text: "5:00 PM", hour: 17, available: true },
          { text: "6:00 PM", hour: 18, available: true },
        ],
      },
    };
  },
  methods: {
    getDate() {
      const options = {
        weekday: "long",
        month: "long",
        day: "numeric",
      };
      return this.currentDaySelected.toLocaleString("es-ES", options);
    },
    getYear() {
      return this.currentDaySelected.getFullYear();
    },
    selectEntity(entity) {
      if (this.currentEntitySelected == entity) {
        this.currentEntitySelected = null;
      } else {
        this.currentEntitySelected = entity;
      }
    },
    selectDate(date) {
      this.currentDaySelected = date;
      this.currentDaySelectedValue = date;
    },
    getButtonClass() {
      // Got time selected on first stage
      if (this.currentStage == 0 && this.currentTimeSelected != null) {
        return " time-button button-enabled";
      }
      // Got entity selected on second stage
      if (this.currentStage == 1 && this.currentEntitySelected != null) {
        return "time-button button-enabled";
      }
      return " time-button button-disabled";
    },
    getTimeClass(time) {
      let extraClass = "";
      if (time == this.currentTimeSelected) {
        extraClass = "time-chosen";
      } else if (time.available == false) {
        extraClass = "time-unavailable";
      } else {
        extraClass = "time-available";
      }

      return `time-container ${extraClass}`;
    },
    selectTime(time) {
      if (time.available) {
        if (this.currentTimeSelected == time) {
          this.currentTimeSelected = null;
        } else {
          this.currentTimeSelected = time;
        }
      }
    },

    continueButton() {
      // a entity is selected and I'm on the last stage
      if (this.currentStage == 1 && this.currentEntitySelected != null) {
        this.$emit("confirm");
        // a time is selected and I'm on the first stage
      } else if (this.currentStage == 0 && this.currentTimeSelected != null) {
        this.currentStage = 1;
      }
    },
  },
  computed: {
    continueText() {
      if (this.currentStage == 1 && this.currentEntitySelected != null) {
        return "Confirmar";
      }
      return "Continuar";
    },
    subtitle() {
      return this.currentStage == 0
        ? "Escoger una fecha y horario disponible"
        : this.entitySelectionText;
    },
  },
};
</script>
