<template>
  <div class="favorites">
    <div class="address-loading">
      <v-progress-circular indeterminate v-if="loadingLite === true" />
    </div>
    <div v-if="loadingLite === false">
      <div class="favorites__title">
        <h3>
          {{ $t("dashboard.favorites.title") }}
        </h3>
        <p class="favorites__title__more" @click="openExplorerFavorites">
          {{ $t("dashboard.favorites.more") }}
        </p>
      </div>
      <div class="favorites__content--empty" v-if="this.allFavoriteSchools.length === 0">
        <div class="d-flex justify-center align-center">
          <span class="favorites__content--empty--text" v-t="'dashboard.favorites.no-favorites'" />
          &nbsp;
          <img src="@/assets/dashboard/icons/like.svg" class="favorites__main__div__icon" />
        </div>
        <button
          class="favorites__content--empty--btn mt-3"
          type="button"
          @click="toExplorer()"
          v-t="'dashboard.favorites.to-explorer'"
        />
      </div>
      <v-expansion-panels
        accordion
        flat
        class="favorites__expansion-panels"
        v-model="showingPanels"
      >
        <v-expansion-panel
          v-for="(school, index) in allFavoriteSchools"
          :key="index"
          style="margin-bottom: 8px"
        >
          <v-expansion-panel-header class="favorites__main" @click="resetSelectedItems()">
            <div class="favorites__main__div">
              <img src="@/assets/dashboard/icons/like.svg" class="favorites__main__div__icon" />
              <div class="favorites__main__div__title">
                {{ school.campus_name }}
              </div>
            </div>
            <template v-slot:actions>
              <img v-if="showingPanels !== index" src="@/assets/iconsNewHaven/dashboard/add.svg" />
              <img v-else width="20" src="@/assets/dashboard/icons/close.svg" />
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="favorites__content">
            <div class="favorites__content__profile" @click="exploreCampusDetail(school)">
              <div class="favorites__content__profile__title">
                {{ $t("dashboard.favorites.profile") }}
              </div>
              <!--
              <img
                v-if="school.multimedia_options.has_tour"
                :src="require('@/assets/iconsChile/campusDetail/360-tour.svg')"
                class="favorites__content__profile__icon"
              />
              <img
                v-if="school.multimedia_options.has_tour"
                :src="require('@/assets/iconsChile/campusDetail/drone.svg')"
                class="favorites__content__profile__icon"
                width="28"
              />
              -->
            </div>
            <div v-if="false" class="favorites__content__comments">
              <div class="favorites__content__comments__text-div">
                <p class="mb-1 mr-2">.</p>
                <p class="favorites__content__comments__text-div__text">
                  {{ $t("dashboard.favorites.comments") }}
                </p>
              </div>
            </div>
            <div class="favorites__content__students__main">
              <div
                v-if="false"
                class="favorites__content__students__title__save"
                @click="onFavoriteClicked()"
              >
                {{ $t("dashboard.favorites.save") }}
              </div>
              <h4 class="favorites__content__students__title">
                {{ $t("dashboard.favorites.institution-to") }}
              </h4>
              <div class="favorites__content__students__div">
                <div
                  v-for="(student, index) in students"
                  :key="index"
                  style="cursor: auto !important"
                >
                  <div
                    @click="selectFavoriteSchool(school, student.uuid)"
                    class="favorites__content__students__div__text"
                    :class="classFilter(school, student)"
                  >
                    {{ student.name }}
                  </div>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-dialog
        v-model="campusDetailModal"
        transition="dialog-bottom-transition"
        max-width="800px"
        content-class="favorites__dialog"
      >
        <campus-detail
          v-if="campusDetailModal"
          style="z-index: 1000"
          :outsideClose="dialogClosed"
          @close="closeCampusDetail"
          :inDashboard="true"
        />
      </v-dialog>
    </div>
    <base-snackbar />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CampusDetail from "@/components/explorer/general/campus_detail/CampusDetail.vue";
import BaseSnackbar from "@/components/explorer/base/BaseSnackbar.vue";
import CONFIG from "@/config";

export default {
  name: "Profile",
  components: {
    CampusDetail,
    BaseSnackbar,
  },
  data() {
    return {
      CONFIG,
      showingPanels: null,
      allFavorites: [],
      campusDetailModal: false,
      dialogClosed: false,
      selectedSchools: [],
      selectedToAddStudentsUuid: [],
      allFavoritesFiltered: [],
      repeatedSchoolsName: [],
      repeatedSchools: [],
      activeStudents: [],
    };
  },
  updated() {
    // eslint-disable-next-line
    this.$nextTick(function () {
      this.dialogClosed = false;
    });
  },
  computed: {
    ...mapGetters({
      allFavoriteSchools: "institutions/allFavoriteSchools",
      students: "authentication/students",
      loadingLite: "institutions/loadingLite",
      isGuest: "authentication/isGuest",
      thereAreStudents: "authentication/students",
      legalGuardianUUID: "authentication/legalGuardianUUID",
    }),
  },
  methods: {
    ...mapActions({
      retrieveFavorites: "institutions/retrieveFavorites",
      retrieveCampusDetails: "institutions/retrieveCampusDetails",
      setShowCampusDetail: "institutions/setShowCampusDetail",
      stopActualVideo: "options/stopActualVideo",
      removeFavoriteByCampusUUID: "institutions/removeFavoriteByCampusUUID",
      addMultipleFavoriteByUUID: "institutions/addMultipleFavoriteByUUID",
      errorSnackbar: "snackbar/error",
      infoSnackbar: "snackbar/info",
    }),
    toExplorer() {
      this.tagMixPanel("click_dashboard_to_explorer_favorites");

      if (this.thereAreStudents.length === 0) {
        this.errorSnackbar(this.$t("dashboard.favorites.there-are-students"));
      } else {
        this.$router.push({ name: "Explorer" });
      }
    },
    openExplorerFavorites() {
      this.tagMixPanel("click_dashboard_open_explorer_favorites");

      if (this.$route.name !== "Favorites") {
        this.retrieveFavorites();
        this.$router.push({ name: "Favorites" });
      }
    },
    exploreCampusDetail(school) {
      this.tagMixPanel("click_dashboard_favourite_details");
      this.retrieveCampusDetails({ campusUuid: school.campus_uuid });
      this.campusDetailModal = true;
    },
    closeCampusDetail() {
      this.tagMixPanel("close_dashboard_favourite_details");

      this.campusDetailModal = false;
      this.stopActualVideo();
    },
    selectFavoriteSchool(school, studentUuid) {
      this.tagMixPanel("click_dashboard_favourite_item_student");

      if (this.selectedSchools.includes(school) === false) {
        this.selectedSchools.push(school);
      }
      if (this.selectedToAddStudentsUuid.includes(studentUuid) === false) {
        this.selectedToAddStudentsUuid.push(studentUuid);
      } else {
        const studentsUuidIndex = this.selectedToAddStudentsUuid.indexOf(studentUuid);
        if (studentsUuidIndex > -1) {
          this.selectedToAddStudentsUuid.splice(studentsUuidIndex, 1);
        }
      }
      const activeSchool = {};
      if (school.applicant_uuid === studentUuid) {
        activeSchool[school.name] = studentUuid;
        if (this.activeStudents.includes(activeSchool) === false) {
          this.activeStudents.push(activeSchool);
        }
      }
      this.repeatedSchools.forEach((repeatedSchool) => {
        if (school.name === repeatedSchool.name) {
          activeSchool[school.name] = studentUuid;
          if (this.activeStudents.includes(activeSchool) === false) {
            this.activeStudents.push(activeSchool);
          }
        }
      });
    },
    classFilter(school, student) {
      let isInFavorites = false;
      let isInSchool = false;
      const studentsUuids = [];
      let searchedSchool = "";
      if (this.selectedToAddStudentsUuid.includes(student.uuid)) {
        isInFavorites = true;
      }
      if (this.selectedSchools.includes(school)) {
        this.selectedSchools.forEach((selectedSchool) => {
          if (selectedSchool.name === school.name) {
            isInSchool = true;
          }
        });
      }
      this.repeatedSchools.forEach((repeatedSchool) => {
        if (school.name === repeatedSchool.name) {
          searchedSchool = school.name;
          if (studentsUuids.includes(repeatedSchool.applicant_uuid) === false) {
            studentsUuids.push(repeatedSchool.applicant_uuid);
          }
        }
      });
      if (studentsUuids.includes(student.uuid) && searchedSchool === school.name) {
        let isUnactive;
        this.activeStudents.forEach((sudentObj) => {
          if (school.name in sudentObj && Object.values(sudentObj)[0] === student.uuid) {
            isUnactive = true;
          }
        });
        if (isUnactive) {
          return "";
        }
        return "favorites__content__students__div__text--active";
        // eslint-disable-next-line
      } else if (school.applicant_uuid === student.uuid) {
        return "favorites__content__students__div__text--active";
      } else if (isInFavorites && isInSchool) {
        return "";
      }
      return "";
    },
    onFavoriteClicked() {
      this.selectedSchools.forEach((school) => {
        if (school.program_options.length < 1) {
          this.errorSnackbar(this.$t("errors.try_to_add_in_favorites_school_without_programs"));
        } else if (school.program_options.length === 1) {
          this.selectedToAddStudentsUuid.forEach((uuid) => {
            this.addMultipleFavoriteByUUID({
              programsUUIDs: [school.program_options[0].program_uuid],
              studentUuid: uuid,
            })
              .catch((error) => {
                this.error(this.$getError(error).reason);
              })
              .then(() => {
                this.infoSnackbar(this.snackbarText(school));
              });
          });
        }
      });
      this.retrieveAllFavorites();
    },
    snackbarText(school) {
      // eslint-disable-next-line
      const infoText = `Has agregado ${school.name}, ${school.program_options[0].grade}, ${school.program_options[0].gender}, ${school.program_options[0].shift}`;
      return infoText;
    },
    resetSelectedItems() {
      this.tagMixPanel("click_dashboard_favourite_item");

      this.selectedSchools = [];
      this.selectedToAddStudentsUuid = [];
      this.activeStudents = [];
    },
    tagMixPanel(tag) {
      this.$mixpanel.track(tag, {
        distinct_id: this.legalGuardianUUID,
        env: CONFIG.environment,
      });
    },
  },
};
</script>
