<template>
  <div
    class="header"
    :class="{
      'header--select': canSelectStudent,
      'header--mobile': mobile,
      'header--slim': currentRoute === 'Explorer',
    }"
  >
    <div class="d-flex justify-center mb-1">
      <img contain :src="require('@/assets/iconsChile/header/logo.svg')" />
    </div>
    <div
      class="header__row"
      :class="{
        'header__row--borderless': borderless,
        'header__row--dashboard': currentRoute === 'Dashboard',
      }"
    >
      <div class="header__row__div">
        <navigation-buttons
          @closeMenu="closeMenu"
          :mobile="mobile"
          @login="signIn"
          @signUp="signUp"
          :inDashboard="inDashboard"
          :showFirstModal="showFirstModal"
        />
      </div>
      <div class="header__footer">
        <div class="header__qa header__qa--white">
          <h5 @click="displayMimeMenu" class="header__qa__text mb-2">
            {{ $t("header.aboutMime") }}
          </h5>
        </div>
        <div class="header__qa">
          <div>
            <h5 class="header__qa__text mt-1" @click="displayMimeQa">
              {{ $t("header.qa") }}
            </h5>
            <p
              @click="openContactLink()"
              class="header__qa__text header__qa__text--bold"
              v-t="'menu.mime-qa.contact'"
            />
            <button
              v-if="!isGuest"
              class="header__footer__logout__icon mt-2 mb-2"
              @click="closeSession"
              v-t="'menu.close-sesion'"
            />
          </div>
          <img
            @click="openConsiliumLink"
            class="header__footer__consilium"
            src="@/assets/Logo_ConsiliumBots.svg"
          />
          <div v-if="false" class="d-flex mt-5">
            <div
              v-for="(locale, index) in locales"
              :key="locale.key"
              :value="locale.key"
              id="locales"
            >
              <p
                class="header__qa__text"
                :class="{ 'ml-1': index === 1 }"
                :style="$i18n.locale === locale.key ? 'font-weight: 500 !important;' : ''"
                @click="setLanguage(locale.key)"
                v-t="index === 0 ? locale.label + ' /' : locale.label"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      content-class="elevation-0"
      overlay-color="transparent"
      v-model="showLoginDialog"
      max-width="400"
    >
      <WarningModal @close="closeWarningModal" @go-register="signUp" @go-login="signIn" />
    </v-dialog>
    <v-dialog
      content-class="explorer__legend-guest elevation-0"
      :value="showMimeMenu"
      width="380"
      @click:outside="closeModal"
    >
      <mime-about-us @close="closeMimeMenu" />
    </v-dialog>
    <v-dialog
      content-class="explorer__legend-guest explorer__legend-guest--qa"
      :value="showMimeQa"
      width="340"
      @click:outside="closeModal"
    >
      <mime-qa @close="closeMimeQa" />
    </v-dialog>
    <v-dialog persistent v-model="showUserRegister" max-width="360" transition="slide-x-transition">
      <user-register
        :step="userRegisterStep"
        @closeModal="closeRegisterModal"
        @logout="logout"
        @nextStep="nextStep()"
        @resetPassword="resetPassword()"
      />
    </v-dialog>
    <v-dialog
      persistent
      v-model="showNoSavedCampusModal"
      max-width="360"
      transition="slide-x-transition"
    >
      <user-register :step="userRegisterStep" @closeModal="closeRegisterModal" @logout="logout" />
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CONFIG from "@/config";
import NavigationButtons from "@/components/explorer/NavigationButtons.vue";
import WarningModal from "../components/explorer/guest/WarninigModal.vue";
import MimeAboutUs from "./MimeAboutUs.vue";
import MimeQa from "./MimeQA.vue";
import UserRegister from "../components/dashboard/UserRegister.vue";

export default {
  name: "CustomMenu",
  components: {
    NavigationButtons,
    WarningModal,
    MimeAboutUs,
    MimeQa,
    UserRegister,
  },
  props: {
    inDashboard: {
      type: Boolean,
    },
    showAlertLogin: {
      type: Boolean,
    },
    showFirstModal: {
      type: Boolean,
      default: false,
    },
    showRegisterModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMenu: false,
      showLoginDialog: false,
      showRemoveStudentDialog: false,
      selectFilter: null,
      kindergartenGrades: [],
      basicEducationGrades: [],
      secondaryEducationGrades: [],
      type: [],
      showFilter: false,
      showlocationEditModal: false,
      mapCircle: null,
      showMapModal: false,
      showMimeMenu: false,
      showMimeQa: false,
      CONFIG,
      showLocationModal: false,
      showUserRegister: false,
      locales: [
        { key: "en", label: "Eng" },
        { key: "es", label: "Spa" },
      ],
      showNoSavedCampusModal: false,
      userRegisterStep: 1,
    };
  },
  mounted() {
    this.cleanFilters();
  },
  watch: {
    showRegisterModal: {
      handler(newVal) {
        if (newVal === true) {
          this.showUserRegister = true;
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      isGuest: "authentication/isGuest",
      legalGuardianUUID: "authentication/legalGuardianUUID",
      environment: "authentication/environment",
      currentStudent: "authentication/currentStudent",
      cornerBounds: "institutions/cornerBounds",
      preCheckStatus: "authentication/preCheckStatus",
    }),
    currentRoute() {
      return this.$route.name;
    },
    canSelectStudent() {
      return ["Explorer", "Schools", "Postulate", "Favorites", "Register Student"].includes(
        this.$route.name
      );
    },
    justLogo() {
      return ["Login", "LoginApp", "User Register", "Reset Password"].includes(this.$route.name);
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    borderless() {
      return ["Login", "LoginApp", "User Register"].includes(this.$route.name) || this.mobile;
    },
  },
  methods: {
    ...mapActions({
      setExitsInfoGuest: "authentication/setExitsInfoGuest",
      logoutSuccess: "authentication/logoutSuccess",
      setWantCreateAccount: "authentication/setWantCreateAccount",
      cleanGuestData: "authentication/cleanGuestData",
      cleanFilters: "institutions/cleanFilters",
      updateGuest: "authentication/updateGuest",
      error: "snackbar/error",
      setGuestData: "authentication/setGuestData",
      retrieveCampuses: "institutions/retrieveCampuses",
      retrieveOptions: "options/retrieveOptions",
      setLoading: "authentication/setLoading",
    }),
    tagMixPanel(tag, data) {
      this.$mixpanel.track(tag, {
        distinct_id: this.legalGuardianUUID(),
        student_id: this.currentStudent().uuid,
        env: this.environment(),
        ...data,
      });
    },
    resetPassword() {
      this.$router.push("/dashboard");
    },
    nextStep() {
      this.userRegisterStep = this.userRegisterStep + 1;
    },
    closeModal() {
      this.selectFilter = null;
      this.showLocationModal = false;
      this.showMapModal = false;
      this.showMimeMenu = false;
      this.showMimeQa = false;
    },
    closeSession() {
      this.showMenu = false;
      this.cleanGuestData();
      this.$gAuth.signOut();
      if (this.currentRoute === "Explorer") {
        this.logoutSuccess({
          callback: () => {
            const location = {
              coordinate: {
                lat: this.CONFIG.defaultLocation.lat,
                lng: this.CONFIG.defaultLocation.lng,
              },
              regionId: 24,
              municipalityName: "Santiago",
            };
            this.setGuestData({
              location,
              grades: [5],
              stage: 1,
              level: "PreKinder",
            });
          },
        });
      } else {
        this.logoutSuccess({ callback: () => this.$router.push("/") });
      }
      this.$emit("logout", this.currentRoute);
    },
    displayMimeMenu() {
      if (this.showMimeMenu === false) {
        this.showMimeMenu = true;
        this.showMenu = false;
      } else {
        this.showMimeMenu = false;
      }
      this.tagMixPanel("click_about_explorer_menu");
      this.closeMenu();
    },
    displayMimeQa() {
      if (this.showMimeQa === false) {
        this.showMimeQa = true;
        this.showMenu = false;
      } else {
        this.showMimeQa = false;
      }
      this.tagMixPanel("click_frequent_questions_menu");
      this.closeMenu();
    },
    closeMimeMenu() {
      this.showMimeMenu = false;
    },
    closeMimeQa() {
      this.showMimeQa = false;
    },
    signIn() {
      this.setWantCreateAccount({ wantCreateAccount: true });
      this.showMenu = false;
      this.showLoginDialog = false;
      this.$router.push({ name: "Login" });
      this.closeMenu();
    },
    signUp() {
      this.showMenu = false;
      this.showLoginDialog = false;
      this.showUserRegister = true;
    },
    closeRegisterModal() {
      this.showUserRegister = false;
      this.$emit("set-default");
    },
    logout() {
      this.logoutSuccess({
        callback: () => {
          const location = {
            coordinate: {
              lat: this.CONFIG.defaultLocation.lat,
              lng: this.CONFIG.defaultLocation.lng,
            },
            regionId: 24,
            municipalityName: "Santiago",
          };
          this.setGuestData({
            location,
            grades: [5],
            stage: 1,
            level: "PreKinder",
          });
        },
      });
      this.showUserRegister = false;
      this.setExitsInfoGuest({ exitsInfoGuest: false });
    },
    closeWarningModal() {
      this.showLoginDialog = false;
    },
    closeFilter() {
      this.showFilter = false;
    },
    openContactLink() {
      window.open(this.CONFIG.contactGoogleFom, "_blank");
    },
    openConsiliumLink() {
      window.open("https://www.consiliumbots.com/en/home", "_blank");
    },
    closeMenu() {
      return this.$emit("closeMenu");
    },
    setLanguage(language) {
      this.$i18n.locale = language;
      this.retrieveOptions();
    },
  },
  watch: {
    preCheckStatus: {
      handler(newVal) {
        if (newVal.found) {
          if (newVal.same_password) {
            if (newVal.has_addresses === false && newVal.has_applicants === false) {
              this.showUserRegister = true;
              this.setLoading(false);
              this.userRegisterStep = 3;
            }
            if (newVal.has_addresses && newVal.has_applicants) {
              this.setLoading(false);
            }
            if (newVal.has_addresses && !newVal.has_applicants) {
              this.showUserRegister = true;
              this.setLoading(false);
              this.userRegisterStep = 4;
            }
            if (!newVal.has_addresses && newVal.has_applicants) {
              this.showUserRegister = true;
              this.setLoading(false);
              this.userRegisterStep = 3;
            }
          }
        } else if (newVal.linkAccount) {
          this.showUserRegister = true;
          this.setLoading(false);
          this.userRegisterStep = 6;
        } else {
          if (newVal.linkAccount === false) {
            if (newVal.has_addresses === false && newVal.has_applicants === false) {
              this.showUserRegister = true;
              this.setLoading(false);
              this.userRegisterStep = 3;
            }
            if (newVal.has_addresses && newVal.has_applicants) {
              this.setLoading(false);
            }
            if (newVal.has_addresses && !newVal.has_applicants) {
              this.showUserRegister = true;
              this.setLoading(false);
              this.userRegisterStep = 4;
            }
            if (!newVal.has_addresses && newVal.has_applicants) {
              this.showUserRegister = true;
              this.setLoading(false);
              this.userRegisterStep = 3;
            }
          } else {
            this.showUserRegister = true;
            this.setLoading(false);
            this.userRegisterStep = 2;
          }
        }
      },
    },
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
