<template>
  <div>
    <v-tooltip v-model="showTooltip" class="" bottom content-class="custom-tooltip">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="panels__tooltip panels__tooltip--right"
          icon
          dark
          small
          color="#1E2D4C"
          style="margin-top: -4px !important"
        >
          <v-icon dark> mdi-information </v-icon>
        </v-btn>
      </template>
      <span v-t="'campus-details.tooltip.sportsInfo'" />
    </v-tooltip>

    <section class="campus-detail__panels__container">
      <div class="panel_container_row fill-width">
        <img
          :src="require('@/assets/iconsChile/campusDetail/sports_tennis.svg')"
          class="campus-detail__panels__image mr-3"
        />

        <div class="campus-detail__panels__title mb-2 font-weight-bold panel_container_row">
          {{ getSportCount() }}

          <div v-if="sports.length == 1" class="ml-2">
            {{ $t("campus-details.panels.onesport") }}
          </div>

          <div v-else class="ml-2">
            {{ $t("campus-details.panels.sports") }}
          </div>
        </div>
      </div>

      <hr />
      <section>
        <div
          v-for="(label, index) in sports"
          :key="index"
        >
          <p
            class="campus-detail__panels__title text-left mb-2 font-weight-medium bullet-point"
            v-if="isOther(label)"
          >
            {{ getLabel(label) }}
          </p>
        </div>
        <div v-if="otherSports">
          <p
            class="campus-detail__panels__title text-left mb-2 font-weight-medium bullet-point"
            v-for="(label, index) in otherSports"
            :key="index"
          >
            {{ getOtherLabel(label) }}
          </p>
      </div>
      </section>
      <MessageInPanel from="sports-panel" />
    </section>
  </div>
</template>
<script>
import MessageInPanel from "./MessageInPanel.vue";

export default {
  name: "Sports",
  components: {
    MessageInPanel,
  },
  data() {
    return {
      showTooltip: false,
      otherSports: false,
    };
  },
  props: {
    sports: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getLabel(label) {
      if (label.sport_label !== 'Otro') {
        return label.sport_label;
      }
    },
    isOther(label) {
      if (label.sport_label === 'Otro') {
        const otherSports = label.descrip.split(',');
        if (otherSports.length === 1) {
          this.otherSports = otherSports;
          return false;
        } else {
          this.otherSports = otherSports;
          return false;
        }
      } else {
        return true;
      }
    },
    getOtherLabel(label) {
      const trimedLabel = label.trim();
      return trimedLabel.charAt(0).toUpperCase() + trimedLabel.slice(1).toLowerCase();
    },
    getSportCount() {
      const sportsLength = this.sports.length
      const otherSports = this.sports.find(sport => sport.label === 'Otro');
      if (!otherSports) {
        return sportsLength;
      }
      const totalSports = (sportsLength - 1) + otherSports.description.split(',').length;
      return totalSports;
    }
  },
};
</script>
