<template>
  <div
    class="flow_chart"
    :style="mobile ? 'flex-direction: column;' : 'justify-content: space-between;'"
  >
    <div class="flow_chart__container" :style="mobile ? 'margin-bottom: 10px;' : ''">
      <div class="flow_chart__steps">
        <img
          v-if="currentStep === 1"
          class="flow_chart__steps__check flow_chart__steps__check--on"
          src="@/assets/iconsPalmira/student_register/icons/on-check.svg"
        />
        <img
          v-else
          class="flow_chart__steps__check"
          src="@/assets/iconsPalmira/student_register/icons/off-check.svg"
        />
        <p
          class="flow_chart__steps__title"
          :class="{ 'flow_chart__steps__title--bold': currentStep === 1 }"
        >
          {{ $t("register.flow_chart.step_1") }}
        </p>
      </div>
      <div class="flow_chart__steps">
        <img
          v-if="currentStep === 2"
          class="flow_chart__steps__check flow_chart__steps__check--on"
          src="@/assets/iconsPalmira/student_register/icons/on-check.svg"
        />
        <img
          v-else
          class="flow_chart__steps__check"
          src="@/assets/iconsPalmira/student_register/icons/off-check.svg"
        />
        <p
          class="flow_chart__steps__title"
          :class="{ 'flow_chart__steps__title--bold': currentStep === 2 }"
        >
          {{ $t("register.flow_chart.step_2") }}
        </p>
      </div>
      <div class="flow_chart__steps">
        <img
          v-if="currentStep === 3"
          class="flow_chart__steps__check flow_chart__steps__check--on"
          src="@/assets/iconsPalmira/student_register/icons/on-check.svg"
        />
        <img
          v-else
          class="flow_chart__steps__check"
          src="@/assets/iconsPalmira/student_register/icons/off-check.svg"
        />
        <p
          class="flow_chart__steps__title"
          :class="{ 'flow_chart__steps__title--bold': currentStep === 3 }"
        >
          {{ $t("register.flow_chart.step_3") }}
        </p>
      </div>
    </div>
    <div class="flow_chart__card">
      <div class="flow_chart__card__name">
        <img src="@/assets/iconsPalmira/student_register/icons/off-check.svg" />
        <p class="flow_chart__card__name__text">
          {{ nickname }}
        </p>
      </div>
      <div class="d-flex" style="height: 54px">
        <div class="flow_chart__card__bar2" :style="percentajeStyle" v-if="percentaje >= 20">
          <div class="flow_chart__card__bar2__text" :style="percentajeTextStyle">
            {{ percentaje }}%
          </div>
        </div>
        <div
          class="flow_chart__card__bar"
          :class="{ 'flow_chart__card__bar--33percent': percentaje >= 20 }"
        >
          <div v-if="percentaje < 20" class="flow_chart__card__bar__text">0 %</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FlowChart",
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
    subStep: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      totalSubSteps: "newStudentRegister/totalSubSteps",
      nickname: "newStudentRegister/nickname",
    }),
    percentaje() {
      const number = (this.subStep * 100) / this.totalSubSteps[this.currentStep - 1];
      return Number(number.toFixed(0));
    },
    percentajeStyle() {
      if (this.percentaje >= 20 && this.percentaje < 40) {
        return "width: 30%";
      }
      if (this.percentaje >= 40 && this.percentaje < 60) {
        return "width: 60%";
      }
      if (this.percentaje >= 60 && this.percentaje < 80) {
        return "width: 155%";
      }
      if (this.percentaje >= 80 && this.percentaje < 100) {
        return "width: 600%";
      }
      if (this.percentaje === 100) {
        return "width: 10000%";
      }
      return "";
    },
    percentajeTextStyle() {
      if (this.percentaje >= 20 && this.percentaje < 40) {
        return "margin-left: 31px";
      }
      if (this.percentaje >= 40 && this.percentaje < 60) {
        return "margin-left: 69px";
      }
      if (this.percentaje >= 60 && this.percentaje < 80) {
        return "margin-left: 130px";
      }
      if (this.percentaje >= 80 && this.percentaje < 100) {
        return "margin-left: 195px";
      }
      if (this.percentaje === 100) {
        return "margin-left: 230px";
      }
      return "";
    },
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
  },
};
</script>
