<template>
  <section
    v-if="testimonials.length > 0"
    class="campus-detail__in-their-words campus-detail__module mt-2"
  >
    <h3
      class="campus-detail__module__title"
      v-t="'campus-details.in_their_own_words'"
    />
    <div class="campus-detail__in-their-words__video-container mt-3">
      <div
        v-for="({ link, name }, index) in testimonials"
        :key="index"
        class="d-flex flex-column"
      >
        <template v-if="link">
          <CampusDetailVideo :media="true" :video="link" :fullScreen="true" />
          <h3 class="mt-2">{{ name }}</h3>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import CampusDetailVideo from "@/components/explorer/general/campus_detail/CampusDetailVideo.vue";

export default {
  name: "CampusDetailInTheirOwnWords",
  components: {
    CampusDetailVideo,
  },
  props: {
    testimonials: {
      type: Array,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getFullTitle(title, name) {
      if (name) {
        return name + " - " + title;
      }
      return title;
    },
  },
};
</script>
