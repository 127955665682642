<template>
  <section class="d-flex flex-row justify-evenly flex-wrap gap-3 mt-5 mb-2">
    <picture
      class="d-flex flex-row align-center"
      v-for="{ icon, url, label } in socialMedia"
      :key="url"
    >
      <img :src="icon" alt="" width="20px" />
      <a :href="url" class="ml-2 white--text" target="_blank"> {{ label }}</a>
    </picture>
  </section>
</template>

<script>
export default {
  name: "CampusDetailSocialMedia",
  props: {
    contacts: {
      type: Array,
    },
  },
  computed: {
    socialMedia() {
      const socialMediaList = [];
      const setIcon = (id) => {
        const icons = {
          /* eslint-disable global-require */
          1: require("@/assets/iconsNewHaven/campusDetail/page.svg"),
          4: require("@/assets/iconsNewHaven/campusDetail/twitter.svg"),
          3: require("@/assets/iconsNewHaven/campusDetail/facebook.svg"),
          5: require("@/assets/iconsNewHaven/campusDetail/instagram.svg"),
          // 8: require("@/assets/iconsNewHaven/campusDetail/youtube.svg"),
          /* eslint-enable global-require */
        };

        return icons[id] ?? false;
      };

      const setUrl = (webpage) => {
        return webpage ? webpage : false;
      };

      const setLabel = (id, label) => {
        const labels = {
          1: this.$t("campus-details.social_media.page"),
        };
        return labels[id] ?? label;
      };
      // eslint-disable-next-line no-restricted-syntax
      for (const { contact_label, webpage } of this.contacts) {
        // eslint-disable-next-line camelcase
        const { id, contact_name } = contact_label;
        const icon = setIcon(id);
        const url = setUrl(webpage);
        const label = setLabel(id, contact_name);
        if (url !== false) {
          socialMediaList.push({ icon, url, label });
        }
      }
      return socialMediaList;
    },
  },
};
</script>
