<template>
  <div class="fill-width simulation-result pt-4">
    <v-row :justify="mobile ? 'center' : 'end'" class="ma-0 fill-width">
      <div
        class="simulation-result__container"
        :class="{ 'simulation-result__container--wider': !mobile }"
      >
        <!--<div class="simulation-result__section">
          <p class="text text--bold text--small-01">
            {{$t('simulation.results.title')}}
          </p>
        </div>
        <div class="simulation-result__section mt-1">
          <p class="text text--small-02">
            {{$t('simulation.results.subtitle')}}
          </p>
        </div>-->
        <anual-simulation-results
          v-for="simulation in sortedSimulationData"
          :key="simulation.year"
          :simulation="simulation"
        />
      </div>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AnualSimulationResults from "./AnualSimulationResults.vue";

export default {
  name: "SimulationResults",
  components: {
    AnualSimulationResults,
  },
  data() {
    return {
      disclaimerModal: false,
      info: null,
    };
  },
  computed: {
    ...mapGetters({
      simulationMessages: "simulation/messages",
      simulationData: "simulation/simulationData",
    }),
    sortedSimulationData() {
      const simulation = this.simulationData.flat();
      return simulation.sort((a, b) => b.year - a.year);
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapActions({
      error: "snackbar/error",
      resetSimulation: "simulation/resetSimulation",
    }),
  },
};
</script>
