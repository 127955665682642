<template>
  <v-snackbar v-model="show" :color="color" :key="key" :timeout="timeout" top>
    <template #default>
      <div class="base-snackbar--text">
        {{ message }}
      </div>
    </template>
    <template v-slot:action="{ attrs }">
      <base-button outlined form v-bind="attrs" @click="show = false">
        {{ $t("snackbar.close") }}
      </base-button>
    </template>
  </v-snackbar>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { SUCCESS, INFO, WARN, ERROR } from "@/store/snackbar.module";
import BaseButton from "./BaseButton.vue";

export default {
  name: "BaseSnackbar",
  components: {
    BaseButton,
  },
  data() {
    return {
      message: "",
      key: Date.now(),
    };
  },
  computed: {
    ...mapGetters({
      content: "snackbar/content",
      timeout: "snackbar/timeout",
      level: "snackbar/level",
      storeShow: "snackbar/show",
    }),
    color() {
      const { level } = this;
      if (level === SUCCESS) return "success";
      if (level === INFO) return "primary";
      if (level === WARN) return "warning";
      if (level === ERROR) return "red";
      return "primary";
    },
    show: {
      get() {
        return this.storeShow;
      },
      set() {
        this.reset();
      },
    },
  },
  methods: {
    ...mapActions({
      reset: "snackbar/reset",
    }),
  },
  watch: {
    content: {
      handler(newval) {
        this.message = newval;
        this.key = Date.now();
      },
      immediate: true,
    },
  },
};
</script>
