var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"campus-detail__panels__container"},[_c('v-tooltip',{attrs:{"bottom":"","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"panels__tooltip panels__tooltip--right",staticStyle:{"margin-top":"-4px !important"},attrs:{"icon":"","dark":"","small":"","color":"#1E2D4C"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-information ")])],1)]}}]),model:{value:(_vm.showTooltip),callback:function ($$v) {_vm.showTooltip=$$v},expression:"showTooltip"}},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('campus-details.tooltip.students'),expression:"'campus-details.tooltip.students'"}]})]),_vm._l((_vm.getGrades),function(ref,index){
var name = ref.name;
var total = ref.total;
return _c('p',{key:index,staticClass:"campus-detail__panels__title text-left mb-2 font-weight-medium"},[_c('span',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(total)+" ")]),_c('span',{directives:[{name:"t",rawName:"v-t",value:({
        path: 'campus-details.student',
        args: { grade: name },
      }),expression:"{\n        path: 'campus-details.student',\n        args: { grade: name },\n      }"}],staticClass:"ml-1"})])}),_c('MessageInPanel',{attrs:{"from":"students-panel"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }