<script>
import { mapGetters, mapActions } from "vuex";
import utils from "@/utils/";

export default {
  name: "SchoolCardMixin",
  props: {
    campus: {
      type: Object,
      required: true,
    },
    campusUuid: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      currentStudent: "authentication/currentStudent",
      legalGuardianHome: "authentication/legalGuardianHome",
      schools: "institutions/schools",
      favoriteSchools: "institutions/favoriteSchools",
      favorites: "institutions/favorites",
      favoriteSchoolsWithRisks: "institutions/favoriteSchoolsWithRisks",
      simulationStats: "simulation/stats",
      currentSchoolLite: "institutions/currentSchoolLite",
      cornerBounds: "institutions/cornerBounds",
      environment: "authentication/environment",
      legalGuardianUUID: "authentication/legalGuardianUUID",
    }),
    distanceToCenter() {
      if (this.campus) {
        const { lat, lng } = this.legalGuardianHome;
        const { lat: latThis, lng: lngThis } = this.campus.location;
        const distance = utils.getDistanceInMi({ lat, lng }, { lat: latThis, lng: lngThis });
        return {
          distance: `${this.$t("map.distance_miles", [distance])}`,
          street: `${this.campus.address_street}`,
        };
      }
      return "";
    },
    grades() {
      // eslint-disable-next-line camelcase
      const { grades_min, grades_max } = this.campus;
      // eslint-disable-next-line camelcase
      return `${grades_min} to ${grades_max}`;
    },
    isFavorite() {
      return this.campus.isFavorite;
    },
    /** Uuid of the favorite school instance. If it isn't favorite, returns null. */
    favoriteUuids() {
      if (!this.isFavorite) {
        return null;
      }
      if (this.value) {
        const filtered = this.favoriteSchools.filter(
          (favorite) => favorite.campus === this.campusUuid && favorite.value === this.value
        );
        return filtered.map((fav) => fav.uuid);
      }
      const filtered = this.favoriteSchools.filter(
        (favorite) => favorite.campus === this.campusUuid
      );
      return filtered.map((favorite) => favorite.uuid);
    },
    /** Absolute index of this favorite in the favorites list */
    favoriteIndex() {
      if (!this.isFavorite) return null;
      return this.value
        ? this.value
        : this.favorites.map((favorite) => favorite.campus).indexOf(this.campusUuid);
    },
    favoritable() {
      return this.campus.favoritable;
    },
    editable() {
      return true;
      /*
      if (!this.isFavorite && this.favoritable) return true;
      const isDefault = this.favoriteSchools.length > 0
        ? this.favoriteSchools[this.favoriteIndex].default_school : false;
      return !isDefault;
      */
    },
    exploredByCurrentStudent(/* school */) {
      return false;
      // TODO: implement below one we get school.explored
      // const currentStudent = this.$store.getters['authentication/currentStudent'];
      // if (currentStudent.uuid) return school.explored.includes(currentStudent.uuid);
      // return school.explored && school.explored.length > 0;
    },
    getColorBySchoolLevel() {
      switch (this.campus.level) {
        case "Insuficiente":
          return "low-level";
        case "Medio/Bajo":
          return "medium-low-level";
        case "Medio":
          return "medium-level";
        default:
          return "high-level";
      }
    },
    getColorBySchoolType() {
      switch (this.campus.type) {
        case "Municipal":
          return "municipal-type";
        case "Particular Subvencionado":
          return "subsidized-private-type";
        default:
          return "private-type";
      }
    },
  },
  methods: {
    ...mapActions("snackbar", ["error", "success"]),
    ...mapActions({
      updateChooseProgramModal: "institutions/updateChooseProgramModal",
      updateCurrentSchool: "institutions/updateCurrentSchool",
      updateDetailModal: "institutions/updateDetailModal",
      exploreCampus: "institutions/exploreCampus",
      updateFavoriteSchool: "institutions/updateFavoriteSchool",
      retrieveCampusDetails: "institutions/retrieveCampusDetails",
      retrieveCampuses: "institutions/retrieveCampuses",
    }),
    explore() {
      /*
      this.retrieveCampusDetails({ campusUuid: this.campusUuid })
        .then(() => this.exploreCampus({ campus: this.campusUuid }));
      */
    },
    getSchoolImageSrc(src) {
      // eslint-disable-next-line global-require
      return src || require("@/assets/icons/school.svg");
    },
    // Listeners
    onFavoriteClicked() {
      if (this.isFavorite) {
        // eslint-disable-next-line no-underscore-dangle
        this.removeFromFavorites_();
      } else if (this.campusUuid === "025a03c0-910c-4cfd-b80d-4455a7b1ba61") {
        this.updateChooseProgramModal(true);
      } else {
        // eslint-disable-next-line no-underscore-dangle
        this.addToFavorites_();
      }
    },
    // Mixin internals
    // eslint-disable-next-line no-underscore-dangle
    addToFavorites_() {
      if (this.isFavorite) {
        return;
      }
      if (this.$gtm.enabled()) {
        // eslint-disable-next-line no-unused-expressions
        window.dataLayer?.push({
          school: this.campusUuid,
        });
        this.$gtm.trackEvent({
          event: "add_campus_to_favorite",
          category: "Add campus to favorite",
          action: "Add campus to favorite",
          label: this.campusUuid,
          value: Date.now(),
          noninteraction: false, // Optional
        });
      }
      this.institutions__addProgramToFavorites({
        student_uuid: this.currentStudent.uuid,
        campus_uuid: this.campus.uuid,
      })
        .then(() => {
          this.success(this.$t("views.schools.favorites.added"));
          this.updateFavoriteSchool({ campusUuid: this.campusUuid, newVal: true });
        })
        .catch((error) => {
          this.error(this.$getError(error).reason);
        })
        .then(() => {
          // eslint-disable-next-line no-underscore-dangle
          this.refetchFavorites_();
        });
    },
    // eslint-disable-next-line no-underscore-dangle
    /*
    removeFromFavorites_() {
      if (!this.favoriteUuids) {
        return;
      }
      for (favoriteUUID in this.favoriteUuids) {
        this.institutions__removeProgramsFromFavorites(
          favoriteUUID,
        ).then(() => {
          this.success(this.$t('views.schools.favorites.removed'));
          // eslint-disable-next-line no-underscore-dangle
          this.refetchFavorites_();
          this.updateFavoriteSchool({ campusUuid: this.campusUuid, newVal: false });
        }).catch((error) => {
          this.error(this.$getError(error).reason);
        });
      }
    },
      */
    // eslint-disable-next-line no-underscore-dangle
    refetchFavorites_() {
      if (!this.currentStudent.uuid) {
        console.warn("Attempted to fetch favorites but currentStudent is not defined");
        return;
      }
      this.retrieveCampuses({
        callback: () => {
          this.changeButtonColor("#828282", "up", this.favoriteIndex);
          this.changeButtonColor("#4F4F4F", "down", this.favoriteIndex);
        },
        bounds: this.cornerBounds,
      });
    },
    changeButtonColor(nextColor, direction, favCardIndex) {
      // Changes the color of the button for the next color
      const buttonRef = this.$refs[`move-${direction}-${favCardIndex}`];
      buttonRef.style.background = nextColor;
    },
  },
};
</script>
