<template>
  <div class="feedback-map">
    <div v-if="loading" class="feedback-map__loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </div>
    <GmapMap
      v-if="!loading"
      id="map"
      :center="{ lat: markerStudent.lat, lng: markerStudent.lng }"
      :zoom="14"
      :options="{
        disableDefaultUI: true,
        clickableIcons: false,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        scrollwheel: false,
        draggable: false,
        styles: stylesGoogleMap,
        gestureHandling: 'greedy',
      }"
      class="map"
    >
      <div>
        <!-- marker student -->
        <gmap-custom-marker
          :marker="{
            lat: markerStudent.lat !== null ? markerStudent.lat : CONFIG.defaultLocation.lat,
            lng: markerStudent.lng !== null ? markerStudent.lng : CONFIG.defaultLocation.lng,
          }"
        >
          <img :src="require(`@/assets/markers/home.svg`)" class="home-icon" />
        </gmap-custom-marker>
        <GmapCircle :center="studentHome()" :radius="2000" :visible="true" :options="mapCircle">
        </GmapCircle>
        <!-- markers applications -->
        <div>
          <gmap-custom-marker
            v-for="(institution, index) in applications"
            :key="index"
            :marker="setApplicantCenter(institution)"
          >
            <img style="z-index: 5000 !important" :src="setApplications(institution)" />
          </gmap-custom-marker>
        </div>
        <!-- markers recommendations -->
        <div v-if="admission.admission_status.id !== 1">
          <gmap-custom-marker
            v-for="(institution, index) in recommendations"
            :key="index"
            :marker="setRecommendationCenter(institution)"
          >
            <img @click="changeSelected(institution)" :src="setIconInstitution(institution)" />
          </gmap-custom-marker>
        </div>
      </div>
    </GmapMap>
    <section v-if="false" class="w-full flex justify-center bg-thirdy py-8">
      <div class="w-full max-w-desktop flex flex-col px-10 text-white font-light">
        <div
          class="w-full flex items-center"
          :class="{ 'feedback-map__examples': CONFIG.feedback === 'Palmira' }"
        >
          <picture>
            <img src="@/assets/svg/marker-student.svg" />
          </picture>
          &nbsp;
          <span class="text-white text-xs ml-2" v-t="'feedback.map.neighborhood'" />
        </div>
        <div
          class="w-full flex items-center mt-3"
          :class="{
            'feedback-map__examples feedback-map__examples--institution':
              CONFIG.feedback === 'Palmira',
          }"
        >
          <picture>
            <img src="@/assets/svg/marker-gray.svg" />
          </picture>
          &nbsp;
          <span class="text-white text-xs ml-1" v-t="'feedback.map.institutions_in_two_km'" />
        </div>
        <div
          class="w-full flex items-center mt-3"
          :class="{
            'feedback-map__examples feedback-map__examples--heart': CONFIG.feedback === 'Palmira',
          }"
        >
          <picture>
            <img src="@/assets/svg/marker-like.svg" />
          </picture>
          &nbsp;
          <span class="text-white text-xs ml-2" v-t="'feedback.map.institutions_applications'" />
        </div>
        <div
          class="w-full flex items-center mt-3"
          :class="{
            'feedback-map__examples feedback-map__examples--recommended':
              CONFIG.feedback === 'Palmira',
          }"
        >
          <picture class="mr-1" style="width: 80px">
            <img src="@/assets/svg/marker-recommendation.svg" />
          </picture>
          &nbsp;
          <span
            class="text-white text-xs -ml-2 -mt-2"
            style="text-align: left"
            v-t="'feedback.map.performance_and_copay_institutions'"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import GmapCustomMarker from "vue2-gmap-custom-marker";
import { mapGetters, mapActions } from "vuex";
import stylesGoogleMap from "../../assets/mapStyle.json";
import CONFIG from "../../config";

export default {
  name: "Map",
  components: {
    GmapCustomMarker,
  },
  props: {
    recommendations: {
      type: Array,
      default: Array,
    },
    applications: {
      type: Array,
      default: Array,
    },
    markerStudent: {
      type: Object,
      default: () => ({
        lat: CONFIG.defaultLocation.lat,
        lng: CONFIG.defaultLocation.lng,
      }),
    },
    admission: {
      type: [Object, Array],
      default: () => {},
    },
    admissionStats: {
      type: [Object, Array],
      default: () => {},
    },
    assignedSchool: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      mapCircle: {
        center: this.studentHome,
        strokeColor: "rgba(255,255,255,0.2)",
        strokeWeight: 1.5,
        fillColor: "rgba(255,255,255,0.2)",
        radius: 2000,
      },
      stylesGoogleMap,
      CONFIG,
    };
  },
  computed: {
    ...mapGetters({
      schoolList: "institutions/schoolList",
      currentSchool: "institutions/currentSchoolLite",
      loading: "loading/loading",
    }),
  },
  methods: {
    ...mapActions({
      updateCurrentSchool: "institutions/updateCurrentSchool",
    }),
    setApplicantCenter(institution) {
      const institutionUuid = institution.campus_uuid;
      let school;
      this.schoolList.forEach((i) => {
        if (i.uuid === institutionUuid) {
          school = i;
        }
      });
      const location = {
        lat: parseFloat(school.lat),
        lng: parseFloat(school.lng),
      };
      return location;
    },
    setRecommendationCenter(institution) {
      if (this.CONFIG.feedbackType === "Results") {
        const location = {
          lat: parseFloat(institution.campus_location[0].latitud),
          lng: parseFloat(institution.campus_location[0].longitud),
        };
        return location;
      }
      if (this.CONFIG.feedbackType !== "Results") {
        const location = {
          lat: institution.location.lat,
          lng: institution.location.lon,
        };
        return location;
      }
      return "";
    },
    setIconInstitution(institution) {
      if (this.currentSchool.uuid === undefined) {
        if (institution.uuid === this.recommendations[0].uuid) {
          this.updateCurrentSchool({ uuid: institution.uuid });
          // eslint-disable-next-line global-require
          return require("@/assets/svg/marker-recommendation-2km.svg");
        }
      }
      if (this.CONFIG.feedbackType === "Results" && institution.uuid === this.currentSchool.uuid) {
        // eslint-disable-next-line global-require
        return require("@/assets/svg/marker-recommendation-2km.svg");
      }
      if (this.CONFIG.feedbackType !== "Results") {
        if (
          (institution.payment === "Gratuito" || institution.payment === "$1.000 a $10.000") &&
          (institution.performance[0].qualitycateglevel.qualitycateglevel_name === "A+" ||
            institution.performance[0].qualitycateglevel.qualitycateglevel_name === "A")
        ) {
          // eslint-disable-next-line global-require
          return require("@/assets/svg/marker-recommendation.svg");
        }
        // eslint-disable-next-line global-require
        return require("@/assets/svg/marker-gray.svg");
      }
      // eslint-disable-next-line global-require
      return require("@/assets/svg/marker-blue.svg");
    },
    setApplications(institution) {
      if (this.admission.admission_status.id === 1) {
        if (this.CONFIG.feedbackType === "Results") {
          if (this.assignedSchool === institution.campus_name) {
            // eslint-disable-next-line global-require
            return require("@/assets/svg/marker-assigned.svg");
          }
          // eslint-disable-next-line global-require
          return require("@/assets/svg/marker-white.svg");
        }
        // eslint-disable-next-line global-require
        return require("@/assets/svg/marker-like.svg");
      }
      // eslint-disable-next-line global-require
      return require("@/assets/svg/marker-white.svg");
    },
    changeSelected(institution) {
      if (institution.uuid) {
        this.updateCurrentSchool({ uuid: institution.uuid });
      }
    },
    studentHome() {
      if (this.markerStudent.lat !== null || this.markerStudent.lat !== undefined) {
        const location = {
          lat: this.CONFIG.defaultLocation.lat,
          lng: this.CONFIG.defaultLocation.lng,
        };
        return location;
      }
      return this.markerStudent;
    },
  },
};
</script>
