<template>
  <div class="explorer">
    <div v-if="mounted" class="explorer__map-container">
      <!-- Start explorer in guest -->
      <div
        v-if="isGuest && !exitsInfoGuest"
        class="w-full h-full absolute z-50 explorer__start"
      >
        <StartExplorer />
      </div>

      <marker-map :guestLoaded="guestLoaded" @signUp="signUp" />
    </div>
    <div v-else class="explorer__map-container">
      <map-placeholder />
    </div>
    <div
      v-if="hasPriority && showInfoMarker"
      class="explorer__legend-container white--text"
    >
      <map-legend />
    </div>
    <v-dialog
      v-if="false"
      content-class="explorer__legend-guest elevation-0"
      persistent
      width="360"
    >
      <map-modal v-if="false" @ready="levelModalSet" @login="signIn" />
      <!-- <map-modal-level
        @back="backToMapModal"
        @ready="levelModalClose"
        v-show="showLevelModal" /> -->
      <map-modal-location
        @back="backToMapModal"
        @ready="setGuest"
        v-if="showLocationModal"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MarkerMap from "@/components/explorer/map/MarkerMap.vue";
import StartExplorer from "@/components/explorer/map/StartExplorer.vue";
import MapLegend from "@/components/explorer/map/MapLegend.vue";
import MapModal from "@/components/explorer/guest/MapModal.vue";
import MapModalLocation from "@/components/explorer/guest/MapModalLocation.vue";
import MapPlaceholder from "@/components/explorer/guest/MapPlaceholder.vue";
import CONFIG from "@/config";

export default {
  name: "Explorer",
  components: {
    MarkerMap,
    MapLegend,
    MapModal,
    MapPlaceholder,
    MapModalLocation,
    StartExplorer,
  },
  data() {
    return {
      mounted: false,
      showLevelModal: false,
      showLocationModal: false,
      showGreetingsModal: false,
      guestGradeSelection: "",
      guestLevel: "",
      showOrHideMapModal: false,
      guestLoaded: false,
      CONFIG,
      // rerenderAux: false,
    };
  },
  props: {
    logout: {
      type: Boolean,
      default: false,
    },
    rerender: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      exitsInfoGuest: "authentication/exitsInfoGuest",
      studentList: "authentication/students",
      isGuest: "authentication/isGuest",
      currentSchool: "institutions/currentSchoolLite",
      showInfoMarker: "institutions/showInfoMarker",
      guestLocationSet: "authentication/guestLocationSet",
      showMapModal: "authentication/showMapModal",
      legalGuardian: "authentication/legalGuardian",
    }),
    hasPriority() {
      const priorities = this.currentSchool?.student_priorities;
      if (priorities) {
        const values = Object.keys(priorities).map((key) => priorities[key]);
        return values.includes(true);
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      setWantCreateAccount: "authentication/setWantCreateAccount",
      setMapModal: "authentication/setMapModal",
      setGuestData: "authentication/setGuestData",
      cleanGuestData: "authentication/cleanGuestData",
      setMultimediaFilter: "filters/setMultimediaFilter",
      setTypeFilter: "filters/setMultimediaFilter",
      error: "snackbar/error",
      retrieveLiteAllSchoolUUIDs: "institutions/retrieveLiteAllSchoolUUIDs",
      updateCurrentSchool: "institutions/updateCurrentSchool",
    }),
    signUp() {
      this.$emit("signUp");
    },
    levelModalSet() {
      this.showGreetingsModal = false;
      /*
      this.showLevelModal = true;
      */
      this.showLocationModal = true;
    },
    levelModalClose(grade, level) {
      this.guestGradeSelection = grade;
      this.guestLevel = level;
      this.showLevelModal = false;
      this.showLocationModal = true;
    },
    backToMapModal(modalName) {
      if (modalName === "level") {
        this.showLevelModal = false;
        this.showGreetingsModal = true;
      }
      if (modalName === "location") {
        this.showLocationModal = false;
        this.showLevelModal = true;
      }
    },
    setGuest() {
      const location = {
        coordinate: {
          lat: this.CONFIG.defaultLocation.lat,
          lng: this.CONFIG.defaultLocation.lng,
        },
        regionId: 24,
        municipalityName: "Santiago",
      };
      // eslint-disable-next-line
      this.setGuestData({
        location,
        grades: [5],
        stage: 1,
        level: "Nivel de Transición 1 / PreKinder",
      });
      this.guestLoaded = true;
    },
    continueExplorer() {
      this.showOrHideMapModal = false;
      this.showLocationModal = false;
      this.mounted = true;
    },
    signIn() {
      this.setWantCreateAccount({ wantCreateAccount: true });
      this.setMapModal({ show: false });
      this.showOrHideMapModal = false;
      this.$router.push({ name: "Login" });
    },
    render() {
      if (!this.isGuest) {
        this.cleanGuestData();
        this.showOrHideMapModal = false;
        this.mounted = true;
        this.setMapModal({ show: true });
      } else if (this.legalGuardian.guestToken !== "") {
        this.showOrHideMapModal = false;
        this.mounted = true;
        this.setMapModal({ show: true });
      } else {
        this.showOrHideMapModal = true;
        this.showGreetingsModal = true;
        this.mounted = true;
        this.setMapModal({ show: true });
      }
    },
    async openDigitalProfileFromUrl() {
      const campusCode = this.$route.params.code ?? "no url";
      if (campusCode !== "no url") {
        await this.updateCurrentSchool({ uuid: campusCode, fromUrl: true });
      }
      // await this.updateCurrentSchool({ uuid: campusUuid });
      //   this.$store.commit("institutions/setShowCampusDetail", true);
    },
  },
  async mounted() {
    this.retrieveLiteAllSchoolUUIDs();
    this.render();
    if (this.isGuest) {
      this.setGuest();
    }
    await this.openDigitalProfileFromUrl();
  },
  watch: {
    logout() {
      if (this.logout === true) {
        this.showOrHideMapModal = true;
        this.showGreetingsModal = true;
        this.$emit("logoutSucces");
      }
    },
    rerender: {
      handler(newVal) {
        if (newVal > 0) {
          this.render();
        }
      },
    },
  },
};
</script>
