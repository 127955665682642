var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navigation d-flex justify-center"},[_c('div',{staticClass:"navigation-bar",class:{ 'navigation-bar--mobile': _vm.mobile }},[_c('img',{staticClass:"header-menu__logo__img",attrs:{"contain":"","src":require("@/assets/iconsChile/header/mime-side-logo.svg")}}),(_vm.currentRoute !== 'Dashboard' && !_vm.isGuest)?_c('div',{staticClass:"navigation-bar__button navigation-bar__button--align",on:{"click":function($event){return _vm.goToPath('Dashboard')}}},[_c('img',{staticClass:"navigation-bar__toExplorer",attrs:{"src":_vm.$i18n.locale === 'en'
            ? require('@/assets/iconsBase/navigation/toDashboard-en.svg')
            : require('@/assets/iconsChile/navigation/toDashboard.svg')}})]):_vm._e(),(_vm.currentRoute === 'Dashboard')?_c('img',{staticClass:"navigation-bar__toExplorer",staticStyle:{"margin-right":"10px","margin-top":"10px"},attrs:{"src":_vm.$i18n.locale === 'en'
          ? require('@/assets/iconsBase/navigation/toExplorerEn.svg')
          : require('@/assets/iconsBase/navigation/toExplorerEsp.svg')},on:{"click":function($event){return _vm.goToPath('Explorer')}}}):_vm._e()]),_c('div',{staticClass:"navigation-bar mt-1",class:{ 'navigation-bar--mobile': _vm.mobile }},[(_vm.currentRoute !== 'Dashboard')?_c('div',{staticClass:"navigation-bar__button",class:{ 'navigation-bar__button--align': _vm.isSelected('Explorer') },on:{"click":function($event){return _vm.goToPath('Explorer')}}},[_c('img',{attrs:{"src":_vm.isSelected('Explorer')
            ? require('@/assets/iconsChile/navigation/toExplorerOn.svg')
            : require('@/assets/iconsChile/navigation/toExplorerOff.svg')}})]):_vm._e(),_c('calendar',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentRoute === 'Dashboard'),expression:"currentRoute === 'Dashboard'"}]}),(_vm.currentRoute !== 'Dashboard')?_c('div',{staticClass:"navigation-bar__button",class:{ 'navigation-bar__button--align': _vm.isSelected('Favorites') },on:{"click":function($event){return _vm.goToPath('Favorites')}}},[_c('img',{attrs:{"src":_vm.isSelected('Favorites')
            ? require('@/assets/iconsChile/navigation/toSavedCampusesOn.svg')
            : require('@/assets/iconsChile/navigation/toSavedCampusesOff.svg')}})]):_vm._e(),(_vm.simulationOn && _vm.currentRoute !== 'Dashboard')?_c('div',{staticClass:"navigation-bar__button",class:{ 'navigation-bar__button--align': _vm.isSelected('Postulate') },on:{"click":function($event){return _vm.goToPath('Postulate')}}},[_c('img',{attrs:{"src":_vm.isSelected('Postulate')
            ? require('@/assets/iconsChile/navigation/toSimulateOn.svg')
            : require('@/assets/iconsChile/navigation/toSimulateOff.svg')}})]):_vm._e()],1),_c('v-dialog',{staticStyle:{"margin-left":"150px"},attrs:{"content-class":"elevation-0","overlay-color":"transparent","max-width":"400"},model:{value:(_vm.showLoginDialog),callback:function ($$v) {_vm.showLoginDialog=$$v},expression:"showLoginDialog"}},[_c('WarninigModal',{attrs:{"hasFavorites":_vm.thereAreFavorites,"pathTo":_vm.pathTo},on:{"close":_vm.closeWarningModal,"go-register":_vm.signUp,"go-login":_vm.signIn}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }