<template>
  <section class="container-admission" :class="{ 'p-3': !isMobile, 'ml-1': isMobile }">
    <v-tooltip bottom content-class="custom-tooltip">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="panels__tooltip panels__tooltip--right"
          icon
          dark
          small
          color="#1E2D4C"
          style="left: 350px !important"
        >
          <v-icon dark> mdi-information </v-icon>
        </v-btn>
      </template>
      <span v-t="'campus-details.tooltip.teamInfo'" />
    </v-tooltip>

    <div class="campus-detail__panels__admission mt-3" v-for="(item, index) in 2" :key="index">
      <!-- Tittle process -->
      <button type="button" class="w-full" @click="openTypeAdmission(index)">
        <p
          class="campus-detail__panels__title campus-detail__panels__title--dark-blue small-line-height"
          v-t="index === 0 ? 'campus-details.admission.process' : 'campus-details.admission.title'"
        />
        <p
          class="campus-detail__panels__title campus-detail__panels__title--dark-blue small-line-height font-weight-medium mt-1"
          v-t="
            index === 0
              ? 'campus-details.admission.date-process'
              : 'campus-details.admission.immediate_process'
          "
        />
      </button>

      <template v-if="showPanel(index)">
        <div class="d-flex flex-row mt-5">
          <span
            class="campus-detail__panels__title small-line-height"
            v-t="'campus-details.admission.title_system'"
          />

          <!-- Type system -->
          <span
            class="campus-detail__panels__title ml-2 font-weight-medium"
            v-if="hasSAE"
            v-t="'campus-details.admission.centralized'"
          />
          <span
            class="campus-detail__panels__title ml-2 font-weight-medium"
            v-else
            v-t="'campus-details.admission.own'"
          />
        </div>

        <!-- TODO -->
        <p v-if="false" class="mt-2 campus-detail__panels__title font-weight-medium">
          {{
            "Texto explicativo Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore."
          }}
        </p>

        <!-- img SAE -->
        <div v-if="hasSAE" class="d-flex flex-row mt-5">
          <img src="@/assets/explorer/campus_detail/SAE_logo.svg" alt="sae" width="75px" />
          <div class="ml-3 d-flex flex-column justify-center align-start">
            <p
              class="campus-detail__panels__title small-line-height mb-2"
              v-t="'campus-details.admission.sae'"
            />
            <p
              class="campus-detail__panels__title font-weight-medium"
              v-t="'campus-details.admission.school_system'"
            />
            <p
              class="campus-detail__panels__title font-weight-medium"
              v-t="'campus-details.admission.ministery'"
            />
          </div>
        </div>

        <!-- Date -->
        <div
          v-if="typePanel === 'regularAdmission'"
          class="mt-5 campus-detail__panels__admission-date campus-detail__panels__admission-container"
        >
          <div class="w-1/2" :class="{ 'ml-16': !isMobile }">
            <img src="@/assets/explorer/campus_detail/postulation_period_icon.svg" alt="calendar" />
            <p
              class="mt-2 campus-detail__panels__title small-line-height"
              v-t="'campus-details.admission.applicant_period'"
            />
          </div>

          <div
            class="w-1/2 d-flex"
            :class="{ 'justify-center': isMobile, 'justify-start': !isMobile }"
          >
            <!-- Start date admission -->
            <div class="ml-5 campus-detail__panels__admission-date__container">
              <span class="green-dot mb-2" />
              <p
                class="campus-detail__panels__title campus-detail__panels__title--dark-blue small-line-height font-weight-regular subtitle-2"
              >
                {{ startDay !== "00" ? startDay : "__" }}
              </p>
              <p
                v-if="startMonth !== 'NAN'"
                class="mt-1 campus-detail__panels__title campus-detail__panels__title--dark-blue small-line-height font-weight-regular subtitle-2"
              >
                {{ startMonth }}
              </p>
            </div>
            <!-- End date admission -->
            <div class="ml-2 campus-detail__panels__admission-date__container">
              <span class="red-dot mb-2" />
              <p
                class="campus-detail__panels__title campus-detail__panels__title--dark-blue small-line-height font-weight-regular subtitle-2"
              >
                {{ endDay !== "00" ? endDay : "__" }}
              </p>
              <p
                v-if="endMonth !== 'NAN'"
                class="mt-1 campus-detail__panels__title campus-detail__panels__title--dark-blue small-line-height font-weight-regular subtitle-2"
              >
                {{ endMonth }}
              </p>
            </div>
          </div>
        </div>

        <!-- Entry grade -->
        <div
          v-if="typePanel === 'regularAdmission'"
          class="campus-detail__panels__admission-container d-flex flex-row w-full mt-3 px-3 py-8 align-center"
        >
          <div class="w-1/6" :class="{ 'ml-20': !isMobile, 'w-3/6': isMobile }">
            <img src="@/assets/explorer/campus_detail/face_icon.svg" alt="kid" width="28px" />
          </div>
          <div class="w-auto">
            <p
              class="campus-detail__panels__title small-line-height"
              v-t="'campus-details.admission.entry_grade'"
            />
            <p class="mt-3 campus-detail__panels__title medium-line-height font-weight-light title">
              {{ minGrade }}
            </p>
          </div>
        </div>

        <hr class="mt-5 campus-detail__panels__admission-divisor" />

        <!-- Required Tests -->
        <template v-if="getRequiredTests.length > 0">
          <p
            class="mt-5 campus-detail__panels__title small-line-height mb-2"
            v-t="'campus-details.admission.required_tests'"
          />
          <div class="d-flex flex-row w-full flex-wrap justify-between align-center">
            <p
              class="mt-2 p-4 d-flex justify-center align-center campus-detail__panels__admission-container campus-detail__panels__title font-weight-medium"
              style="width: 49%"
              v-for="{ description, order } in getRequiredTests"
              :key="order"
            >
              {{ description }}
            </p>
          </div>
        </template>

        <!-- Required Documents -->
        <template v-if="getRequiredDocuments.length > 0">
          <p
            class="mt-5 campus-detail__panels__title small-line-height mb-2"
            v-t="'campus-details.admission.required_tests'"
          />
          <div class="d-flex flex-column w-full flex-wrap justify-center align-center">
            <p
              class="mt-2 p-4 w-full campus-detail__panels__admission-container campus-detail__panels__title font-weight-medium"
              v-for="{ description, order } in getRequiredDocuments"
              :key="order"
            >
              <img src="@/assets/icons/ballot.svg" alt="ballot" class="mr-2" />
              {{ description }}
            </p>
          </div>
        </template>

        <!-- Priority school -->
        <template v-if="schoolPriorities.length > 0 && typePanel === 'regularAdmission'">
          <p
            class="mt-5 campus-detail__panels__title small-line-height"
            v-t="'campus-details.admission.priority_school'"
          />

          <div
            class="d-flex flex-row mt-4 w-full pb-1 pl-2 campus-detail__panels__admission-divisor--blue"
            v-for="{ priority, image } in schoolPriorities"
            :key="priority"
          >
            <img :src="getImageurl(image)" alt="priority" class="mr-5" />
            <p class="campus-detail__panels__title ml-2 font-weight-medium">
              {{ priority }}
            </p>
          </div>
        </template>

        <!-- Click to login -->
        <button
          v-if="isGuest"
          type="button"
          @click="clickLogin"
          class="log-in-button w-full mt-5 d-flex flex-row justify-center align-center gap-1"
        >
          <p v-t="'campus-details.admission.login_text'" />
          <div>
            <img src="@/assets/explorer/campus_detail/log-in_box.svg" alt="logIn" />
            <p class="-mt-7 pl-2" v-t="'campus-details.admission.login.one'" />
            <p class="pl-2" v-t="'campus-details.admission.login.two'" />
          </div>
        </button>

        <!-- Link admission -->
        <button
          @click="toLinkAdmission"
          class="link-admission w-full p-4 mt-5 subtitle-2"
          v-if="linkAdmission"
          type="button"
        >
          <span
            v-t="{
              path: 'campus-details.admission.admission_in',
              args: { link: linkAdmission },
            }"
          />
        </button>

        <!-- Send message to institution -->
        <button
          type="button"
          @click="openContact('contact-institution')"
          class="message-institution-button d-flex flex-row justify-center align-center p-4 mt-3"
        >
          <img class="ml-3" src="@/assets/explorer/campus_detail/email_icon.svg" alt="" />
          <p
            class="campus-detail__panels__title--white text-left font-weight-light subtitle-2 ml-3"
            v-t="'campus-details.admission.send_message'"
          />
        </button>

        <!-- historic admission -->
        <template v-if="hasSAE && !isGuest && typePanel === 'regularAdmission'">
          <img
            class="ml-3 mt-5"
            src="@/assets/explorer/campus_detail/hand_icon.svg"
            alt="admission"
          />
          <p
            class="mt-2 ml-3 campus-detail__panels__title small-line-height mb-2"
            v-t="'campus-details.admission.history'"
          />

          <p
            class="campus-detail__panels__title text-left mb-3 font-weight-medium ml-3 mt-2 clicked"
            v-t="{
              path: 'campus-details.admission.historical_results',
              args: { year: '2021' },
            }"
          />

          <div
            v-for="({ total, image, description }, index) in admissionHistory"
            :key="index"
            class="d-flex flex-row admission-historic"
            :class="getClassBackground(index)"
          >
            <div>
              <img :src="getImageurl(image)" alt="chair" />
            </div>
            <p class="campus-detail__panels__title font-weight-medium">
              <span class="font-weight-bold mr-2">{{ total }}</span>
              {{ description }}
            </p>
          </div>
        </template>
      </template>
    </div>
  </section>
</template>
<script>
import { mapActions } from "vuex";
import utils from "@/utils/";

export default {
  name: "Admission",
  data() {
    return {
      startDay: "00",
      startMonth: "NAN",
      endDay: "00",
      endMonth: "NAN",
      hasSAE: null,
      firstClicked: false,
      secondClicked: true,
      firstYearAttributeList: [],
      secondYearAttributeList: [],
      schoolPriorities: [],
      userPriorities: [],
      admissionHistory: null,
      defaultSchoolPriorities: null,
      typePanel: null,
    };
  },
  props: {
    isSAE: {
      required: true,
      type: Number,
    },
    minGrade: {
      type: String,
      default: "",
    },
    schoolPriority: {
      type: Array,
    },
    campus: {
      type: Object,
    },
    isGuest: {
      type: Boolean,
    },
    requiredTests: {
      type: Array,
      default: () => [],
    },
    requiredDocuments: {
      type: Array,
      default: () => [],
    },
    linkAdmission: {
      type: String,
      defaul: "",
    },
  },
  mounted() {
    // set SAE
    this.hasSAE = this.isSAE === 1;
    this.setDataSimulation();
    const splitedStartDate =
      this.campus.campus_admission_system[0].application_date_start.split("-");
    this.startDay = splitedStartDate[2].split(" ")[0];
    this.startMonth = utils.getMonthInString(splitedStartDate[1], "es");
    const splitedEndDate = this.campus.campus_admission_system[0].application_date_end.split("-");
    this.endDay = splitedEndDate[2].split(" ")[0];
    this.endMonth = utils.getMonthInString(splitedEndDate[1], "es");
    const prioritiesDict = this.campus.campus_priority;
    // eslint-disable-next-line no-restricted-syntax
    this.defaultSchoolPriorities = {
      1: {
        priority: "Proceso de selección al azar",
        image: "6",
      },
      2: {
        priority: "Hermano(a) en el establecimiento",
        image: "7",
      },
      3: {
        priority: "Apoderado(a) es ex alumno(a)",
        image: "8",
      },
      4: {
        priority: "Condición de vulnerabilidad socio-económica",
        image: "9",
      },
      5: {
        priority: "Puntaje prueba de admisión",
        image: "6",
      },
      6: {
        priority: "Orden de inscripción",
        image: "6",
      },
      7: {
        priority: "Programa de Integración Escolar (PIE)",
        image: "8",
      },
      8: {
        id: "8",
        priority: "Alto rendimiento",
        image: "8",
      },
      9: {
        id: "9",
        priority: "Estudiantes prioritarios (SEP)",
        image: "7",
      },
      10: {
        id: "10",
        priority: "Padre/madre funcionario/a",
        image: "6",
      },
      11: {
        id: "11",
        priority: "Ex-alumno/a",
        image: "8",
      },
    };
    prioritiesDict.forEach((priority) => {
      if (this.campus.sector_label.id === 1 || this.campus.sector_label.id === 2) {
        if (priority.priority_label.id !== 3 && priority.priority_label.id !== 6) {
          this.schoolPriorities.push(this.defaultSchoolPriorities[priority.priority_label.id]);
        }
      } else {
        this.schoolPriorities.push(this.defaultSchoolPriorities[priority.priority_label.id]);
      }
    });
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false;
    },
    getRequiredTests() {
      let requiredTests = this.requiredTests ?? [];

      if (requiredTests.length > 0) {
        requiredTests = requiredTests.sort((testA, testB) => testA.order - testB.order);
      }

      return requiredTests;
    },
    getRequiredDocuments() {
      let requiredDocuments = this.requiredDocuments ?? [];

      if (requiredDocuments.length > 0) {
        requiredDocuments = requiredDocuments.sort((testA, testB) => testA.order - testB.order);
      }

      return requiredDocuments;
    },
  },
  methods: {
    ...mapActions({
      setGoToFormContactFrom: "contactForm/setGoToFormContactFrom",
    }),
    showPanel(index) {
      if (index === 0 && this.typePanel === "regularAdmission") {
        return true;
      }
      if (index === 1 && this.typePanel === "immediateAdmission") {
        return true;
      }
      return false;
    },
    openTypeAdmission(index) {
      const panel = index === 0 ? "regularAdmission" : "immediateAdmission";

      if (panel === this.typePanel) {
        this.typePanel = null;
      } else {
        this.typePanel = panel;
      }
    },
    setDataSimulation() {
      if (!this.isGuest) {
        // eslint-disable-next-line prefer-object-spread
        this.admissionHistory = Object.assign({}, this.$t("campus-details.admission.simulation"));
        this.admissionHistory.vacancies.total = this.campus?.simulation?.vacancies;
        this.admissionHistory.assigned_higher.total = this.campus?.simulation?.assigned_higher;
        this.admissionHistory.assigned_equal.total = this.campus?.simulation?.assigned_equal;
        this.admissionHistory.assigned_lower.total = this.campus?.simulation?.assigned_lower;
        this.admissionHistory.non_assigned.total = this.campus?.simulation?.nonassigned;
      }
    },
    getImageurl(id) {
      const image = String(id);
      // images postulaciones historicas
      if (image === "1") {
        return require("@/assets/explorer/campus_detail/chair_dark_blue_icon.svg");
      }
      if (image === "2") {
        return require("@/assets/explorer/campus_detail/chair_white_icon.svg");
      }
      if (image === "3") {
        return require("@/assets/explorer/campus_detail/chair_orange_icon.svg");
      }
      if (image === "4") {
        return require("@/assets/explorer/campus_detail/chair_blue_icon.svg");
      }
      if (image === "5") {
        return require("@/assets/explorer/campus_detail/person_red_icon.svg");
      }
      // images priorities
      if (image === "6") {
        return require("@/assets/icons/cookie.svg");
      }
      if (image === "7") {
        return require("@/assets/icons/group.svg");
      }
      if (image === "8") {
        return require("@/assets/icons/graduation_gap.svg");
      }
      return require("@/assets/icons/money.svg");
    },
    getClassBackground(name) {
      const isBackgroundLight = name === "assigned_higher" || name === "assigned_lower";
      return isBackgroundLight ? "admission-historic__light" : "";
    },
    clickLogin() {
      this.$emit("closeDialog");
      this.$router.push({ name: "Login" });
    },
    openContact(contactForm) {
      this.setGoToFormContactFrom({ from: contactForm });
      document.getElementById("contact-us").scrollIntoView({ behavior: "smooth" });
    },
    toLinkAdmission() {
      window.open(this.linkAdmission, "_blank");
    },
  },
};
</script>

<style scoped>
.admission-historic {
  width: 100%;
  padding: 1rem;
  background-color: #deebff;
}

.admission-historic > div {
  width: 40px;
}
.admission-historic > p {
  width: calc(100% - 40px);
}

.admission-historic__light {
  background-color: #eef5ff;
}
.container-admission {
  width: 100%;
  /* margin: auto; */
}
.small-line-height {
  line-height: 15px;
}
.medium-line-height {
  line-height: 25px;
}
.button-box {
  height: 41px;
  background: #ffffff;
  box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  justify-content: center;
  margin-bottom: 1rem;
}
.date-box {
  background: #ffffff;
  border-radius: 4px;
  width: 58.13px;
  height: 76px;
  padding-top: 8px;
}
.green-dot {
  background: #11d3d9;
  height: 8px;
  width: 8px;
  border-radius: 50%;
}
.red-dot {
  background: #fc2451;
  height: 8px;
  width: 8px;
  border-radius: 50%;
}
.clicked {
  font-weight: 900 !important;
  text-decoration: underline;
}
.even {
  background: #deebff;
  width: 100%;
  height: 50px;
}
.odd {
  background: #eef5ff;
  width: 100%;
  height: 50px;
}
.postulation-information {
  width: 100%;
}
.center {
  position: relative;
}
.left-text-login {
  width: 50%;
  margin: auto 0 auto 0;
}
.log-in-button {
  background-color: #deebff;
  border-radius: 4px;
  border-radius: 4px;
  padding: 1rem;
}

.log-in-button > p {
  width: calc(100% - 100px);
  max-width: 250px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 0.69rem;
  line-height: 16px;
  color: #0d22a8;
}

.log-in-button > div {
  width: 98px;
  height: 75px;
  box-shadow: -4px 4px 8px rgba(23, 32, 234, 0.2);
  background: linear-gradient(180deg, #426cfb 0%, #9f81f3 139.26%);
  border-radius: 4px;
  position: relative;
  color: white;
}

.log-in-button > div > p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
  color: white;
}
.message-institution-button {
  width: 100%;
  font-size: 0.8rem;
  line-height: 22px;
  letter-spacing: 0.05em;
  background: linear-gradient(180deg, #426cfb 0%, #9f81f3 139.26%);
  box-shadow: -4px 4px 8px rgba(23, 32, 234, 0.2);
  border-radius: 4px;
}
.contact-mime-disclaimer {
  font-size: 0.8rem;
  padding: 1rem;
  margin: 0.5rem -2.25rem -1.25rem -1.75rem;
  background: #4002f0;
}
.underline-mime {
  color: #f097a6;
  text-decoration: underline;
}

.link-admission {
  color: white;
  background: #2b5bff;
  box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.05em;
  word-break: break-all;
}
</style>
