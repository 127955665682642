var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"student-menu",class:{
    'student-menu--guest': _vm.isGuest,
    'student-menu--mobile': _vm.mobile,
  }},[_c('div',{staticClass:"student-menu__div"},[_c('div',{staticClass:"d-flex justify-start align-center"},[_c('div',{staticClass:"student-menu__div__main"},[_c('div',{staticClass:"d-flex align-center"},[(_vm.isGuest)?_c('button',{directives:[{name:"t",rawName:"v-t",value:('header.guest'),expression:"'header.guest'"}],staticClass:"student-menu__div__main__select-guest",attrs:{"height":"21","color":"#2b5bff","id":"student-select"}}):_vm._e(),(!_vm.isGuest && !_vm.mobile)?_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isGuest),expression:"!isGuest"}],staticClass:"student-menu__div__main__select",attrs:{"id":"student-select","hide-details":"","items":_vm.studentList,"value":_vm.currentStudent,"item-text":"name","item-value":"uuid","solo":"","dense":"","height":"31","color":"#2b5bff","menu-props":{ bottom: true, offsetY: true }},on:{"change":_vm.changeCurrentStudent},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"student-menu__div__main__text"},[_vm._v(" "+_vm._s(item.name.split(" ", 1)[0])+" ")])]}}],null,false,2957401378)}):_vm._e(),(false)?_c('div',{staticClass:"d-flex align-center mt-2 mr-2"},[(_vm.hasStudents)?_c('img',{staticClass:"student-menu__div__main__imgs",attrs:{"src":require("@/assets/iconsPalmira/navigation/edit2.png")},on:{"click":_vm.editStudent}}):_vm._e(),(_vm.hasStudents)?_c('img',{staticClass:"student-menu__div__main__imgs",attrs:{"src":require("@/assets/iconsPalmira/navigation/add.png")},on:{"click":_vm.addStudent}}):_vm._e(),(_vm.canSelectStudent)?_c('img',{staticClass:"student-menu__div__main__imgs",staticStyle:{"margin-bottom":"2px"},attrs:{"src":require("@/assets/iconsPalmira/navigation/delete.png")},on:{"click":_vm.openRemoveStudentDialog}}):_vm._e()]):_vm._e(),_c('div',{staticClass:"student-menu__filter ml-1",class:{
              'student-menu__filter--guest': _vm.isGuest,
              'student-menu__filter--mobile': _vm.mobile,
            }},[(!_vm.showMobileSearchBar)?_c('map-filter'):_vm._e()],1)],1)]),_c('button',{staticClass:"student-menu__div__more",class:{
          'student-menu__div__more--selected': _vm.showMore,
          'student-menu__div__more--mobile': _vm.mobile,
        },attrs:{"type":"button"},on:{"click":function($event){return _vm.openFilters()}}},[_vm._v(" "+_vm._s(_vm.$t("header.filters"))+" ")])]),_c('div',{staticClass:"student-menu__main"},[(!_vm.mobile || _vm.showMobileSearchBar)?_c('search-in-all-schools',{attrs:{"mobileWidth":_vm.showMobileSearchBar}}):_vm._e(),(_vm.mobile)?_c('v-icon',{staticClass:"ml-1",attrs:{"medium":"","color":_vm.showMobileSearchBar ? 'red' : 'white'},on:{"click":_vm.showMobileSearch}},[_vm._v(" mdi-magnify ")]):_vm._e()],1)]),_c('v-dialog',{attrs:{"content-class":"remove-student-modal","max-width":"400"},model:{value:(_vm.showRemoveStudentDialog),callback:function ($$v) {_vm.showRemoveStudentDialog=$$v},expression:"showRemoveStudentDialog"}},[_c('remove-student-modal',{on:{"remove":_vm.removeStudent,"cancel":_vm.closeRemoveStudentDialog}})],1),_c('v-dialog',{attrs:{"content-class":"dashborad-modal-flow","overlay-color":"transparent","max-width":"380px"},model:{value:(_vm.showStudentRegisterModal),callback:function ($$v) {_vm.showStudentRegisterModal=$$v},expression:"showStudentRegisterModal"}},[_c('add-student',{on:{"closeModal":_vm.closeAddStudentModal}})],1),_c('v-dialog',{attrs:{"width":"360"},on:{"click:outside":_vm.closeFilter},model:{value:(_vm.showMore),callback:function ($$v) {_vm.showMore=$$v},expression:"showMore"}},[_c('SchoolsFilter',{on:{"ready":_vm.closeFilter}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }