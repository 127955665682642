<template>
  <div>
    <div class="popup-container text-blue-1">
      <!-- close button -->
      <button class="popup-close" @click="closeButton()">
        <img src="../../../assets/dashboard/icons/close_cross.svg" alt="close" />
      </button>
      <!-- title -->
      <img
        class="popup-title"
        src="../../../assets/dashboard/icons/school_community_icon.svg"
        alt="sige"
      />
      <!-- text -->
      <div>
        <p class="popup-text">{{ schoolcommunityText }}</p>
      </div>
      <!-- button -->
      <button class="popup-button" @click="clickButton()">
        <div class="popup-button-text">Ir a Comunidad Escolar</div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SchoolCommunity",
  props: { schoolcommunityText: { required: true, type: String } },
  methods: {
    clickButton() {
      window.open("https://www.comunidadescolar.cl/");
    },
    closeButton() {
      this.$emit("closePopup");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Roboto");
@import url("https://fonts.googleapis.com/css?family=Montserrat");
.popup-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #eef5ff;
  width: 720px;
  height: 352px;
  margin: auto;
  z-index: 101;
}
.popup-title {
  position: absolute;
  font-size: 40px;
  top: 30px;
  left: 50px;
}
.popup-text {
  position: absolute;
  width: 87%;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 35px;
  color: #2b5bff;
  letter-spacing: 0.05em;
}
.popup-close {
  position: absolute;
  right: 2.5%;
  top: 8%;
  width: 5%;
}
.popup-button {
  position: absolute;
  bottom: 20px;
  right: 32px;
  width: 150px;
  height: 50px;
  text-align: center;
  background: #ffffff;
  box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.popup-button-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 130px;
  font-size: 12px;
  color: #2b5bff;
}
</style>
