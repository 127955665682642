<template>
  <div class="fill-width fill-height" style="border-radius: 4px" id="player">
    <vimeo-player
      v-if="isVimeoVideo(video)"
      ref="player"
      :video-id="video"
      @ready="onReady"
      @play="play"
      :options="{
        responsive: true,
        playsinline: isFullscreen,
        title: false,
      }"
    ></vimeo-player>
    <youtube v-else :video-id="videoId(video)" class="youtubeVideo" />
    <!-- <div id="video"></div> -->
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "CampusDetailVideo",
  props: {
    video: {
      type: String,
      required: false,
    },
    media: {
      type: Boolean,
      default: false,
    },
    modalClosed: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      videoTest: "",
      playerReady: false,
    };
  },
  methods: {
    ...mapActions({
      setActualVideo: "options/setActualVideo",
      stopActualVideo: "options/stopActualVideo",
    }),
    videoId(link) {
      return this.$youtube.getIdFromUrl(link);
    },
    isVimeoVideo(link) {
      console.log(link.includes("vimeo"));
      return link.includes("vimeo");
    },
    onReady() {
      this.playerReady = true;
    },
    play() {
      if (this.mobile) {
        this.$refs.player.play();
        const { player } = this.$refs.player;
        player.getIframe().then((iframe) => {
          // eslint-disable-next-line
          var requestFullScreen =
            iframe.requestFullScreen ||
            iframe.mozRequestFullScreen ||
            iframe.webkitRequestFullScreen;
          if (requestFullScreen) {
            requestFullScreen.bind(iframe)();
          }
        });
      }
    },
    pause() {
      this.$refs.player.pause();
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isFullscreen() {
      if (this.mobile) {
        return false;
      }
      return true;
    },
  },
};
</script>
