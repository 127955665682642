<template>
  <v-expansion-panels accordion flat v-model="showingPanels2" style="justify-content: flex-end">
    <v-expansion-panel class="institutions-info__select">
      <v-expansion-panel-header
        expand-icon=""
        class="institutions-info__header"
        :class="{ 'institutions-info__header--border': showingPanels2 !== null }"
        height="31"
        @click="rightItems()"
      >
        <div class="d-flex justify-center align-center" v-if="selectedInfo2 === null">
          <img class="mb-1" src="@/assets/iconsNewHaven/icons/schools.svg" />
          <strong
            class="institutions-info__select__text institutions-info__select__text--strong ml-1"
          >
            {{ visibleSchoolsStart() }}
          </strong>
          <p class="institutions-info__select__text ml-1" v-t="$t('map.info.schools')" />
        </div>
        <div v-else class="d-flex justify-center align-center">
          <img
            class="mb-1"
            v-if="selectedInfo2.name === 'Establecimientos'"
            :src="require('@/assets/iconsNewHaven/icons/schools.svg')"
          />
          <img
            class="mb-1"
            v-if="selectedInfo2.name === 'destacados'"
            :src="require('@/assets/icons/filters.svg')"
          />
          <strong
            class="institutions-info__select__text institutions-info__select__text--strong ml-1"
          >
            {{ selectedInfoLength(selectedInfo2) }}
          </strong>
          <p class="institutions-info__select__text ml-1" v-t="selectedInfo2.name" />
        </div>
        <template v-slot:actions>
          <v-icon class="ml-1" color="white"> mdi-menu-down </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="institution-info-slot">
          <div
            class="d-flex mb-2"
            v-for="(item, i) in rightItems()"
            :key="i"
            @click="selectInfoRight(item)"
          >
            <img
              class="mb-1"
              v-if="item.name === 'Establecimientos'"
              :src="require('@/assets/iconsNewHaven/icons/schools.svg')"
            />
            <img
              class="mb-1"
              v-if="item.name === 'destacados'"
              :src="require('@/assets/icons/filters.svg')"
            />
            <strong
              class="institutions-info__select__text institutions-info__select__text--strong ml-1"
            >
              {{ item.lengthValue }}
            </strong>
            <div
              style="font-weight: 300"
              class="institutions-info__select__text ml-1"
              v-t="item.name"
            />
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "SelectLeftBar",
  components: {},
  props: {
    map: {
      default: null,
    },
    filterNumber: {
      default: null,
    },
  },
  data() {
    return {
      selectedInfo2: null,
      showingPanels2: null,
    };
  },
  methods: {
    rightItems() {
      const rightItems = [
        {
          lengthValue: this.visibleSchools,
          name: "Establecimientos",
        },
        {
          lengthValue: this.setFilterNumber,
          name: "destacados",
        },
      ];
      return rightItems;
    },
    selectInfoRight(index) {
      this.showingPanels2 = null;
      this.selectedInfo2 = index;
    },
    selectedInfoLength(info) {
      if (info.name === "Establecimientos") {
        return this.visibleSchools;
      }
      return this.setFilterNumber;
    },
    visibleSchoolsStart() {
      let count = 0;
      if (this.map) {
        this.schools.forEach((s) => {
          const { lat } = s.location;
          const lng = s.location.lon;
          if (this.map.getBounds() !== undefined) {
            if (this.map.getBounds().contains({ lat, lng })) {
              count += 1;
            }
          }
        });
      }
      return count;
    },
  },
  computed: {
    ...mapGetters({
      schools: "institutions/schools",
      favorites: "institutions/favorites",
      cleanedFilters: "filters/cleanedFilters",
    }),
    favoritesLength() {
      return this.favorites.length;
    },
    visibleSchools() {
      let count = 0;
      if (this.map) {
        this.schools.forEach((s) => {
          const { lat } = s.location;
          const lng = s.location.lon;
          if (this.map.getBounds().contains({ lat, lng })) {
            count += 1;
          }
        });
      }
      return count;
    },
    setFilterNumber() {
      if (this.cleanedFilters === false) {
        return this.filterNumber;
      }
      return "0";
    },
    exploredLength() {
      let exploredLength = 0;
      this.schools.forEach((school) => {
        if (school.isExplored) {
          exploredLength += 1;
        }
      });
      return exploredLength;
    },
    vacanciesLength() {
      let vacanciesLength = 0;
      this.schools.forEach((school) => {
        /* eslint no-underscore-dangle: ["error", { "allow": ["_calculated_vacancies"] }] */
        if (school.programs[0]._calculated_vacancies) {
          vacanciesLength += 1;
        }
      });
      return vacanciesLength;
    },
  },
};
</script>
