<script>
import { Pie } from "vue-chartjs";

export default {
  name: "PieChart",
  extends: Pie,
  props: {
    datacollection: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {
          legend: {
            display: false,
          },
          fullWidth: true,
          tooltipFontSize: 10,
          tooltips: {
            enabled: true,
            callbacks: {
              label(tooltipItems, data) {
                const i = tooltipItems.index;
                return ` ${Number(data.datasets[0].data[i]).toFixed(1)} %`;
              },
            },
          },
        };
      },
    },
  },
  mounted() {
    this.renderChart(this.datacollection, this.options);
  },
};
</script>
