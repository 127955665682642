<template>
  <div class="favorite-list">
    <div class="favorite-list__container">
      <div v-for="(campus, i) in favoriteSchoolsDetails" :key="i">
        <favorite-card
          class="mt-5"
          :campus="campus"
          :campus-uuid="campus.uuid"
          :program="campus.programs"
          :ratex="ratexIndexes ? ratexIndexes[i] : null"
          :index="i"
        />
      </div>
      <div v-if="favoriteCampuses.length < 1" class="fill-width ma-5">
        {{ $t("views.schools.favorites.empty-list") }}
      </div>
    </div>
  </div>
</template>

<script>
import FavoriteCard from "./FavoriteCard.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "FavoriteList",
  components: {
    FavoriteCard,
  },
  data() {
    return {
      favoriteCampusesList: [],
    };
  },
  props: {
    favoriteCampuses: {
      type: Array,
      required: true,
    },
    ratexIndexes: {
      type: [Array, Boolean],
      required: true,
    },
  },
  mounted() {
    this.favoriteCampuses.forEach((program) => {
      this.favoriteCampusesList.push(program.campus);
    });
    // pending promise cycle
    const getFavoritesPromise = this.getFavoriteSchoolsDetails(this.favoriteCampusesList);
  },
  computed: {
    ...mapGetters({
      favoriteSchoolsDetails: "institutions/favoriteSchoolsDetails",
    }),
  },
  methods: {
    ...mapActions({
      getFavoriteSchoolsDetails: "institutions/getFavoriteSchoolsDetails",
    }),
  },
};
</script>
