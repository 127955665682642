<template>
  <v-container class="map-modal-container">
    <v-row no-gutters class="map-modal" justify="center">
      <!-- BACKGROUND ICONS-->
      <div class="map-modal__icon1" />
      <div class="map-modal__icon2" />
      <div class="map-modal__icon3" />
      <div class="map-modal__icon4" />
      <div class="map-modal__icon5" />
      <div class="map-modal__icon6" />
      <div class="map-modal__div" id="data-form">
        <div class="map-modal__div map-modal__div--text">
          {{ $t("explorer.map_modal.greeting") }}
        </div>
        <div class="map-modal__div map-modal__div--text2">
          <strong>{{ $t("explorer.login_modal.welcome") }}</strong>
          {{ $t("explorer.login_modal.info") }}
        </div>
        <div class="map-modal__login__btn justify-center" @click="goLogin">
          {{ $t("explorer.map_modal.login_btn") }}
        </div>
        <div class="d-inline-flex justify-center mt-5 fill-width">
          <base-button class="map-modal__guest__btn" :loading="loadingBtn" @click="toLevel">
            {{ $t("explorer.map_modal.as_guest") }}
          </base-button>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import BaseButton from "@/components/explorer/base/BaseButton.vue";

export default {
  name: "MapModal",
  components: {
    BaseButton,
  },
  data() {
    return {
      loadingBtn: false,
    };
  },
  computed: {
    ...mapGetters({
      regionOptions: "options/regions",
    }),
    disabledBtn() {
      return this.$v.$invalid || this.commune.length === 0 || this.loadingBtn;
    },
  },
  methods: {
    ...mapActions({
      setGuestData: "authentication/setGuestData",
    }),
    tagMixPanel(tag, data) {
      const { legalGuardianUUID, environment } = this;
      this.$mixpanel.track(tag, {
        distinct_id: legalGuardianUUID,
        env: environment,
        ...data,
      });
    },
    toLevel() {
      this.tagMixPanel("click_to-guest_in_modal_map");
      this.$emit("ready");
    },
    goLogin() {
      this.tagMixPanel("click_to-login_in_modal_map");
      this.$emit("login");
    },
  },
};
</script>
