<template>
  <div
    class="campus-detail__panels__container campus-detail__panels__container__3 panel_container_column fill-width"
  >
    <section class="campus-detail__panels__container__white mb-4">
      <span
        v-t="'campus-details.current_performance.highlighted_title'"
        class="campus-detail__panels__title text-left font-weight-medium"
      />
    </section>
    <div>
      <div class="panel_container_row">
        <img
          v-if="getNationalScores()"
          class="panel_container_row__abs_img panel_container_row__abs_img--v3"
          :src="require('@/assets/iconsChile/campusDetail/green_star.svg')"
        />
        <span
          v-if="getNationalScores()"
          class="panel_container_row--text panel_container_row--text__turqoise d-flex align-center"
        >
          {{ getNationalScores() }}
        </span>

        <span
          v-if="getNationalScores()"
          class="campus-detail__panels__performance-title--turqoise-achievments pl-0"
        >
          {{ $t("campus-details.current_performance.national_score") }}
        </span>
      </div>
      <div
        v-if="
          campusDetail.achievement_campus !== undefined &&
          campusDetail.achievement_campus.length > 0
        "
      >
        <div
          class="panel_container_row"
          v-for="(achievement, index) in campusDetail.achievement_campus.slice(1)"
          :key="index"
        >
          <img
            class="panel_container_row__abs_img panel_container_row__abs_img--v4"
            :src="require('@/assets/iconsChile/campusDetail/green_star.svg')"
          />
          <span
            class="panel_container_row__white campus-detail__panels__title text-left mb-2 font-weight-medium campus-detail__panels__container__item"
          >
            {{ achievement.description }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Achievements",
  computed: {
    ...mapGetters({
      campusDetail: "institutions/campusDetails",
    }),
  },
  methods: {
    getNationalScores() {
      const studentsInUniversity = this.campusDetail.higheredstats_campus.find(
        (stats) => stats.highered_stats === 3
      );
      if (studentsInUniversity !== undefined) {
        return studentsInUniversity.number;
      }
      return false;
    },
  },
};
</script>
