import { MapElementFactory } from "vue2-google-maps";

export default MapElementFactory({
  name: "directionsRenderer",
  ctr() {
    return window.google.maps.DirectionsRenderer;
  },
  events: [],
  mappedProps: {},
  props: {
    origin: { type: [Object, Array] },
    destination: { type: [Object, Array] },
    travelMode: { type: String },
    map: { type: Object },
    render: { type: Object },
  },
  afterCreate(directionsRenderer) {
    const directionsService = new window.google.maps.DirectionsService();
    const rendererOptions = {
      suppressMarkers: true,
      polylineOptions: {
        strokeColor: "#11d3d9",
        strokeOpacity: 0.8,
        strokeWeight: 5,
      },
    };
    if (this.render.request) {
      directionsRenderer.setOptions(rendererOptions);
      directionsRenderer.setDirections(this.render);
    } else {
      const { origin, destination, travelMode } = this;
      directionsService.route(
        {
          origin,
          destination,
          travelMode,
        },
        (response, status) => {
          if (status !== "OK") return;
          directionsRenderer.setOptions(rendererOptions);
          directionsRenderer.setDirections(response);
          this.$emit("response-directions", response);
        }
      );
    }
  },
});
