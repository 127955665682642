<template>
  <div class="container-loading feedback-padding-x">
    <picture class="p-0 top-0 container-loading__img feedback-max-width mt-3">
      <img src="@/assets/svg/logo-ministry.svg" alt="ministerio de educación" />
      <img src="@/assets/feedback/logo_sae.png" alt="sae" width="80px" />
    </picture>

    <div class="LoaderBalls">
      <div class="LoaderBalls__item" v-for="index in 3" :key="index" />
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingPage",
};
</script>

<style lang="scss">
.container-loading {
  box-sizing: border-box;
  background-color: #e5e5e5;
  width: 100% !important;
  height: 100% !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__img {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.LoaderBalls {
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__item {
    $anim-drt: 0.4s;
    $anim-ease: cubic-bezier(0.6, 0.05, 0.15, 0.95);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #3a0ac0;

    &:nth-child(1) {
      animation: bouncing $anim-drt alternate infinite $anim-ease;
    }

    &:nth-child(2) {
      animation: bouncing $anim-drt calc($anim-drt/4) alternate infinite
        $anim-ease backwards;
    }

    &:nth-child(3) {
      animation: bouncing $anim-drt calc($anim-drt/2) alternate infinite
        $anim-ease backwards;
    }
  }
}

@keyframes bouncing {
  0% {
    transform: translate3d(0, 10px, 0) scale(1.2, 0.85);
  }

  100% {
    transform: translate3d(0, -20px, 0) scale(0.9, 1.1);
  }
}
</style>
