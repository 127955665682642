<template>
  <!-- eslint-disable vue/require-v-for-key -->
  <section v-if="pridePoints.length > 0" class="campus-detail__pride__point">
    <template
      v-for="(
        { pridepoint_description, pridepoint_title, images_pridepoint }, index
      ) in sortPridePoints"
    >
      <div v-if="pridepoint_title" class="w-full mt-1">
        <h3 class="campus-detail__pride__point--subtitle">
          {{ pridepoint_title }}
        </h3>
      </div>

      <p
        class="campus-detail__pride__point--text mt-1"
        :class="{ 'mt-0 mb-3': !exitsImagesPridePoint(images_pridepoint) }"
      >
        {{ pridepoint_description }}
      </p>
      <!-- eslint-disable-next-line vue/valid-v-for -->
      <v-carousel
        v-if="exitsImagesPridePoint(images_pridepoint)"
        hide-delimiter-background
        show-arrows-on-hover
        class="mt-2 mb-5 campus-detail__pride__point--carrousel"
      >
        <v-carousel-item
          v-for="{ image, index } in images_pridepoint"
          :key="index"
          reverse-transition="fade-transition"
          transition="fade-transition"
        >
          <picture>
            <img class="campus-detail__pride__point--image" :src="image" />
          </picture>
        </v-carousel-item>
      </v-carousel>

      <hr class="mb-5" v-if="showDivisor(index)" />
    </template>
  </section>
</template>

<script>
export default {
  name: "PridePoints",
  data() {
    return {
      sortPridePoints: Array,
    };
  },
  props: {
    pridePoints: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.sortPridePoints = [...this.pridePoints];
    this.sortPridePoints.sort((a, b) => a.pridepoint.order - b.pridepoint.order);
  },
  methods: {
    showDivisor(index) {
      return index + 1 !== this.sortPridePoints.length;
    },
  },
  computed: {
    exitsImagesPridePoint() {
      return (images) => {
        if (images.length > 0) {
          return true;
        }
        return false;
      };
    },
  },
};
</script>
