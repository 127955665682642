<template>
  <div
    class="section"
    :style="{
      '--height': getHeight(),
      'background-color': background,
      'z-index': 1,
      '--width': width,
      '--padding': fullSpace ? '0px' : '10px',
    }"
  >
    <!--<div @click="toggle" >
        {{title}}
    </div>-->
    <div
      @click="toggle()"
      v-if="toggableDirection"
      style="cursor: pointer; width: 100%; position: relative; z-index: 3"
    >
      <div v-html="title"></div>
      <div style="float: right; position: absolute; top: 0; right: 5px; top: 10px" v-if="!mobile">
        <img src="../../assets/dashboard/icons/down_arrow.svg" />
      </div>
    </div>
    <div
      v-else
      style="white-space: nowrap; z-index: 3"
      :style="{
        width: fullSpace ? 'auto' : '60%',
        position: fullSpace ? 'absolute' : 'relative',
        'background-color': fullSpace ? 'rgba(255,255,255,0.7)' : 'transparent',
        'border-radius': '5px',
        margin: fullSpace ? '10px' : '0px',
        padding: fullSpace ? '5px 10px 5px 10px' : '0px',
      }"
    >
      <div v-html="title"></div>
    </div>

    <div
      v-if="showContents"
      class="section-content"
      :style="{ position: 'relative', 'z-index': 2, '--height': height, 'max-width': '100%' }"
    >
      <div
        style="width: 100%; max-width: 100%"
        :style="{ height: fullSpace ? '100%' : 'calc(100% - 30px)' }"
      >
        <DashboardMap v-if="name == 'dashboardmap'" :role="role" />
        <DFM v-if="name == 'dfm'" :role="role" />
        <Analytics v-else-if="name == 'analytics'" :role="role" />
        <DigitalProfile v-else-if="name == 'digitalprofile'" :role="role" />
        <Profile
          v-else-if="name == 'profile'"
          :role="role"
          :showStudentModals="showStudentModals"
        />
        <Notifications v-else-if="name == 'notifications'" :role="role" />
        <Tools2 v-else-if="name == 'tools'" :role="role" />
        <Favorites v-else-if="name == 'favorites'" :role="role" />
        <Messages v-else-if="name == 'messages'" :role="role" />
        <Applications v-else-if="name == 'applications'" :role="role" />
        <Admissions v-else-if="name == 'admissions'" :role="role" />
        <SchoolList v-else-if="name == 'schoollist'" :role="role" />
        <Simulation style="z-index: 1 !important" v-else-if="name == 'simulation'" :role="role" />
        <Graph v-else-if="name == 'graph'" />
        <ReportList v-else-if="name == 'report-list'" />
      </div>
    </div>
  </div>
</template>
<script>
import DFM from "./DFM.vue";
import DigitalProfile from "./DigitalProfile.vue";
import Profile from "./Profile.vue";
import Analytics from "./Analytics.vue";
import Applications from "./Applications.vue";
import Favorites from "./Favorites.vue";
import Reports from "./Reports.vue";
import Notifications from "./Notifications.vue";
import Tools2 from "./Tools2.vue";
import DashboardMap from "./DashboardMap.vue";
import Messages from "./Messages.vue";
import Admissions from "./Admissions.vue";
import SchoolList from "./SchoolList.vue";
import Simulation from "./Simulation.vue";
import Graph from "./Graph.vue";
import ReportList from "./ReportList.vue";
export default {
  name: "Section",
  components: {
    Admissions,
    DashboardMap,
    Tools2,
    Notifications,
    Reports,
    Favorites,
    Applications,
    Analytics,
    Profile,
    DigitalProfile,
    DFM,
    Messages,
    SchoolList,
    Simulation,
    Graph,
    ReportList,
  },
  props: {
    role: {
      /**
       * user role.
       */
      required: true,
      type: String,
    },
    showStudentModals: {
      required: true,
      type: Boolean,
    },
    /*
    schoolList: {
      required: true,
      type: Array,
    },
    */
    width: {
      required: false,
      type: String,
      default: "",
    },
    height: {
      required: false,
      type: String,
      default: "48%",
    },
    title: {
      required: true,
      type: String,
    },
    currentSection: {
      required: false,
      type: String,
    },
    toggableDirection: {
      required: false,
      default: null,
    },
    name: {
      required: true,
      type: String,
    },
    background: {
      required: false,
      type: String,
      default: "#DEEBFF",
    },
    fullSpace: {
      required: false,
      type: Boolean,
      default: false,
    },
    header: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  methods: {
    toggle() {
      if (this.toggableDirection) {
        this.$emit("toggle", this.name);
      }
    },

    getHeight() {
      if (this.toggableDirection) {
        if (this.showContents) {
          return this.height;
        }
        return "40px";
      }
      return this.height;
    },
  },

  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    showContents() {
      if (this.toggableDirection) {
        return this.currentSection === this.name;
      }
      return true;
    },
  },
};
</script>
<style>
.section {
  background-color: #deebff;
  display: block;
  position: relative;
  text-align: left;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  color: #0d22a8;
  min-height: var(--height);
  height: var(--height);
  overflow: hidden;
  padding: var(--padding);
  border-radius: 5px;
  min-width: var(--width);
  width: var(--width);
  max-width: var(--width);
}

.section-content {
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  height: 100%;
}
</style>
