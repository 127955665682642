var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"identification-flow-chart",class:{ 'identification-flow-chart--general': true }},[_c('div',[_c('div',{staticClass:"identification-flow-chart__card"},[(false)?_c('div',{staticClass:"identification-flow-chart__card__div"},[((_vm.identificationComplete || _vm.locationComplete) && !_vm.isGuest)?_c('div',{staticClass:"identification-flow-chart__card__div__bar2",class:{
            'identification-flow-chart__card__div__bar2--66percent':
              _vm.identificationComplete && _vm.locationComplete,
            'identification-flow-chart__card__div__bar2--100percent':
              _vm.identificationComplete && _vm.locationComplete && _vm.contactComplete,
          }},[_c('div',{staticClass:"identification-flow-chart__card__div__bar2__text",class:{
              'identification-flow-chart__card__div__bar2__text--general':
                _vm.identificationComplete || _vm.locationComplete,
              'identification-flow-chart__card__div__bar2__text--general--66percent':
                _vm.identificationComplete && _vm.locationComplete,
              'identification-flow-chart__card__div__bar2__text--100percent':
                _vm.identificationComplete && _vm.locationComplete && _vm.contactComplete,
            }},[_vm._v(" "+_vm._s(_vm.percentageNumber())+" ")])]):_vm._e(),((!_vm.identificationComplete && !_vm.locationComplete && !_vm.contactComplete) || _vm.isGuest)?_c('div',{staticClass:"identification-flow-chart__card__div__bar",class:{
            'identification-flow-chart__card__div__bar--33percent':
              (_vm.identificationComplete || _vm.locationComplete) && !_vm.isGuest,
            'identification-flow-chart__card__div__bar--66percent--general':
              _vm.identificationComplete && _vm.locationComplete && !_vm.isGuest,
            'identification-flow-chart__card__div__bar--90percent': _vm.currentStep === 19,
          }},[_c('div',{staticClass:"identification-flow-chart__card__div__bar__text"},[_vm._v("0 %")])]):_vm._e()]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }