<template>
  <section>
    <v-btn
      @click="setShowApplications()"
      class="
        feedback-applied-school__button
        mb-1
        feedback-applied-school__button-no-border
      "
      depressed
      color="#3A0AC0"
      v-t="'feedback.applications.applications_to_date'"
    />
    <v-expand-transition>
      <div class="feedback-applications-to-date py-5" v-show="showApplications">
        <div
          v-for="(
            { text, image, customClassText, classImg, customClass }, index
          ) in getApplications()"
          :key="index"
        >
          <picture :class="customClass" v-if="image !== undefined">
            <img :src="image" alt="chair" :class="classImg ? 'ml-1' : ''" />
          </picture>

          <span
            :class="{
              'feedback-applications-to-date__margin': image === undefined,
              'ml-2': customClassText,
            }"
          >
            {{ text }}
          </span>
        </div>
        <!-- <p v-t="'feedback.applications.applications_message'" /> -->
      </div>
    </v-expand-transition>
  </section>
</template>

<script>
export default {
  name: "ApplicationsToDate",
  data() {
    return {
      showApplications: false,
    };
  },
  props: {
    school: {
      type: Object,
      default: () => {},
    },
    applicantId: {
      type: Number,
    },
  },
  methods: {
    setShowApplications() {
      this.showApplications = !this.showApplications;

      if (this.showApplications) {
        this.$mixpanel.track("fbin_open_sim", {
          student_id: this.applicantId,
          campus_code: this.school.campus_code,
          institution_code: this.school.institution_code,
          ranking: this.school.rank,
          dataSchool: this.school,
        });
      }
    },
    getApplications() {
      const { school } = this;
      const aplicationsTotal =
        school?.assigned_higher +
        school?.assigned_equal +
        school?.assigned_lower +
        school?.nonassigned;

      let applications = [
        {
          text: this.$t("feedback.applications.applications_total", {
            total: aplicationsTotal,
          }),
          show: true,
          image: require("@/assets/feedback/person.svg"),
          customClassText: false,
        },
        {
          text: this.$t("feedback.applications.applications_available", {
            total: school?.vacancies,
          }),
          image: require("@/assets/feedback/chair-black.svg"),
          show: true,
          customClassText: false,
        },
        // {
        //   text: this.$t("feedback.applications.applications_message_alert"),
        //   image: undefined,
        //   show: +school?.vacancies < +aplicationsTotal,
        //   customClassText: false,
        // },
        {
          text: this.$t("feedback.applications.applications_with_priority", {
            total: school?.assigned_higher,
          }),
          image: require("@/assets/feedback/chair-gray.svg"),
          show: true,
          customClass: "-ml-1",
          customClassText: false,
        },
        {
          text: this.$t("feedback.applications.applications_same_priority", {
            total: school?.assigned_equal,
          }),
          image: require("@/assets/feedback/chair-blue.svg"),
          show: true,
          customClassText: true,
          classImg: true,
        },
      ];

      applications = applications.filter((item) => item.show === true);
      return applications;
    },
  },
};
</script>
