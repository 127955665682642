<template>
  <div @click="explore">
    <div :size="iconSize" :class="getIconClass()" v-if="currentZoom > 13">
      <div
        class="marker-category-price"
        :class="{ 'marker-category-price--small': currentZoom >= 14 }"
      >
        <div v-if="currentZoom >= 17" class="marker-category-price__inner_div">
          <img
            v-if="performance !== 'no-data' && performance"
            width="20"
            height="20"
            class="mr-1"
            :src="getIconClassPerformance"
          />
          <img
            width="20"
            height="20"
            v-if="payment && payment !== 'Sin Información'"
            :src="getIconClassPayment"
          />
        </div>
        <div
          class="marker-category-multimedia__not__highlighted"
          :class="{
            'marker-category-multimedia__not__highlighted--small':
              currentZoom >= 14,
          }"
        >
          <div class="marker-category-multimedia__inner_div">
            <img
              v-if="hasDroneFlight && currentZoom > 14"
              class="mr-1"
              :src="require('@/assets/iconsChile/campusDetail/drone.svg')"
              width="20"
              height="20"
            />
            <img
              v-if="has360Tour && currentZoom > 14"
              class="mr-1"
              :src="require('@/assets/iconsChile/campusDetail/360-tour.svg')"
              width="20"
              height="20"
            />
            <div
              class="marker-category-multimedia__small"
              v-if="has360Tour && currentZoom <= 14"
            >
              {{ $t("markers.360") }}
            </div>
          </div>
        </div>
      </div>
      <div class="marker-icon-div" v-if="currentZoom > 14">
        <div
          class="marker-icon-div__dot"
          :class="{ 'marker-icon-div__dot--selected': selected }"
        />
      </div>
      <div v-else class="marker-icon-div">
        <div
          v-if="currentZoom > 14"
          class="marker-icon-div__dot"
          :class="{ 'marker-icon-div__dot--selected': selected }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import utils from "@/utils/";

export default {
  name: "MarkerIcon",
  props: {
    explored: {
      type: Boolean,
      default: false,
    },
    exploredMapCard: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    siblingPriority: {
      type: Boolean,
      default: false,
    },
    neighborhoodPriority: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    fromMapCard: {
      type: Boolean,
      default: false,
    },
    location: {
      type: Object,
    },
    size: {
      type: Number,
      default: 0,
    },
    uuid: {
      type: String,
      default: "",
    },
    hasVacancies: {
      type: Number,
    },
    hasSep: {
      type: Boolean,
      default: false,
    },
    hasPie: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    currentZoom: {
      type: Number,
      default: 0,
    },
    performance: {
      type: [String, Object],
      default: "",
    },
    payment: {
      type: String,
      default: "",
    },
    has360Tour: {
      type: Boolean,
      default: false,
    },
    hasVideo: {
      type: Boolean,
      default: false,
    },
    hasDroneFlight: {
      type: Boolean,
      default: false,
    },
    school: {
      type: Object,
    },
    dependency: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      treatment: "authentication/treatment",
      isGuest: "authentication/isGuest",
      currentSchool: "institutions/currentSchoolLite",
      currentStudent: "authentication/currentStudent",
      typeFilter: "filters/typeFilter",
      multimediaFilter: "filters/multimediaFilter",
      sepFilter: "filters/sepFilter",
      pieFilter: "filters/pieFilter",
      distanceFilter: "filters/distanceFilter",
      loginFilter: "filters/loginFilter",
      paymentFilter: "filters/paymentFilter",
      performanceFilter: "filters/performanceFilter",
      guestLevel: "authentication/guestLevel",
      legalGuardianUUID: "authentication/legalGuardianUUID",
      homeLocation: "authentication/homeLocation",
      vacanciesFilter: "filters/vacanciesFilter",
      dependencyFilter: "filters/dependencyFilter",
      exploredCampuses: "institutions/exploredCampuses",
      exploredSchoolCards: "institutions/exploredSchoolCards",
      environment: "authentication/environment",
    }),
    iconSize() {
      if (this.size) return this.size;
      if (this.small) return 30;
      return 60;
    },
    getIconClassPayment() {
      let icon = "";
      if (!this.isGuest) {
        if (this.hasSep) {
          icon = "price-free.svg";
        } else if (this.payment === "Gratuito") {
          icon = "price-free.svg";
        } else if (
          this.payment === "$1.000 a $10.000" ||
          this.payment === "$1.000 a $50.000"
        ) {
          icon = "price-10-50.svg";
        } else if (
          this.payment === "10.001 a $100.000" ||
          this.payment === "$50.001 a $100.000"
        ) {
          icon = "price-50-100.svg";
        } else if (this.payment === "Más De $100.000") {
          icon = "price-100.svg";
        } else {
          icon = "price-100.svg";
        }
      } else if (this.payment === "Gratuito") {
        icon = "price-free.svg";
      } else if (
        this.payment === "$1.000 a $10.000" ||
        this.payment === "$1.000 a $50.000"
      ) {
        icon = "price-10-50.svg";
      } else if (
        this.payment === "10.001 a $100.000" ||
        this.payment === "$50.001 a $100.000"
      ) {
        icon = "price-50-100.svg";
      } else if (this.payment === "Más De $100.000") {
        icon = "price-100.svg";
      } else {
        icon = "price-100.svg";
      }
      // eslint-disable-next-line
      const svg = require("@/assets/iconsChile/campusDetail/" + icon);
      return svg;
    },
    getIconClassPerformance() {
      let icon;
      if (this.performance === "") {
        icon = "performance-insufficient.svg";
      } else if (this.performance === "Alto") {
        icon = "performance-high.svg";
      } else if (this.performance === "Medio") {
        icon = "performance-middle.svg";
      } else if (this.performance === "Medio-Bajo") {
        icon = "performance-middle-low.svg";
      } else if (this.performance === "Insuficiente") {
        icon = "performance-insufficient.svg";
      }
      // eslint-disable-next-line
      const svg = require("@/assets/iconsChile/campusDetail/" + icon);
      return svg;
    },
  },
  data() {
    return {
      svgCategory: "",
      svgPerformance: "",
    };
  },
  methods: {
    ...mapActions({
      exploreSchoolCard: "institutions/exploreSchoolCard",
      setShowMapCard: "institutions/setShowMapCard",
      updateCurrentSchool: "institutions/updateCurrentSchool",
      setLoadingSchoolCard: "institutions/setLoadingSchoolCard",
      changeShowInfoMarkerStatus: "institutions/changeShowInfoMarkerStatus",
    }),
    getIconClass() {
      if (
        this.multimediaFilter.length === 0 &&
        this.sepFilter === null &&
        this.paymentFilter === null &&
        this.performanceFilter === null &&
        this.sepFilter === null &&
        this.pieFilter === null &&
        this.distanceFilter === null &&
        this.vacanciesFilter === null &&
        this.dependencyFilter === null
      ) {
        return this.isNotHighlighted();
      }
      if (
        this.is360FilterSet() &&
        this.isVideoFilterSet() &&
        this.isDroneFilterSet() &&
        this.isPaymentSet() &&
        this.isPerformanceSet() &&
        this.isSepSet() &&
        this.isPieSet() &&
        this.isDistanceSet() &&
        this.isVacanciesSet() &&
        this.isDependencySet()
      ) {
        const unExploredHighlighted = document.getElementsByClassName(
          "marker--unexplored--highlighted"
        ).length;
        const exploredHighlighted = document.getElementsByClassName(
          "marker--explored--highlighted"
        ).length;
        const mapExploredHighlighted = document.getElementsByClassName(
          "marker--unexplored--mapexplored__highlighted"
        ).length;
        const smallIconHighlighted = document.getElementsByClassName(
          "marker--smallIconHighlighted"
        ).length;
        const highlightedSchools =
          unExploredHighlighted +
          exploredHighlighted +
          mapExploredHighlighted +
          smallIconHighlighted;
        this.$emit("isHighlighted", highlightedSchools);
        return this.isHighlighted();
      }
      return this.isNotHighlighted();
    },
    isRecommended(uuid) {
      return (
        uuid && this.recommendedSchools.some((element) => element.uuid === uuid)
      );
    },
    multimediaOptions() {
      const multimediaOptions = {};
      multimediaOptions.has_video = this.hasVideo;
      multimediaOptions.has_tour = this.has360Tour;
      multimediaOptions.has_drone_flight = this.hasDroneFlight;
      return multimediaOptions;
    },
    explore() {
      this.changeShowInfoMarkerStatus({ status: false }).then(() => {
        this.setLoadingSchoolCard(true);
        this.$mixpanel.track("click_school_pin", {
          distinct_id: this.legalGuardianUUID,
          student_id: this.currentStudent.uuid,
          school_id: this.school.uuid,
          env: this.environment,
        });
        if (!this.exploredSchoolCards.includes(this.uuid)) {
          this.setShowMapCard(this.uuid);
          this.exploreSchoolCard({ campus: this.school });
          this.updateCurrentSchool({ uuid: this.uuid });
        } else {
          this.updateCurrentSchool({ uuid: this.uuid });
        }
      });
    },
    isNotHighlighted() {
      if (this.currentZoom > 14) {
        return this.isExplored();
      }
      if (this.currentZoom <= 14) {
        return this.isSmallIcon();
      }
    },
    isHighlighted() {
      if (this.currentZoom > 14) {
        return this.isExploredHihighlighted(
          this.explored,
          this.exploredMapCard
        );
      }
      return this.isSmallIconHihighlighted();
    },
    isHighlightedMultimedia() {
      let highlightedClass;

      const filterObject = {
        // eslint-disable-next-line
        has_drone_flight: this.multimediaFilter.includes("droneFlight"),
        // eslint-disable-next-line
        has_video: this.multimediaFilter.includes("video"),
        // eslint-disable-next-line
        has_tour: this.multimediaFilter.includes("tour360"),
      };
      let isHighlighted = true;
      Object.keys(filterObject).forEach((key) => {
        if (filterObject[key] !== this.multimediaOptions()[key]) {
          isHighlighted = false;
        }
      });
      if (isHighlighted) {
        if (this.currentZoom > 14) {
          highlightedClass = this.isExploredHihighlighted(
            explored,
            exploredMapCard
          );
        } else {
          highlightedClass = this.isSmallIconHihighlighted();
        }
      }
      if (highlightedClass) {
        return highlightedClass;
      }
      if (this.currentZoom > 14) {
        return this.isExplored();
      }
      return this.isSmallIcon();
    },
    isHihighlightedType(type) {
      let highlightedClass;
      this.typeFilter.forEach((typeFilter) => {
        if (type === typeFilter) {
          if (this.currentZoom > 14) {
            highlightedClass = this.isExploredHihighlighted(
              explored,
              exploredMapCard
            );
          } else {
            highlightedClass = this.isSmallIconHihighlighted();
          }
        }
      });
      return highlightedClass;
    },
    isHighlightedPayment() {
      let highlightedClass;
      if (this.payment === this.paymentFilter) {
        if (this.currentZoom > 14) {
          highlightedClass = this.isExploredHihighlighted(
            this.explored,
            this.exploredMapCard
          );
        } else {
          highlightedClass = this.isSmallIconHihighlighted();
        }
      }
      return highlightedClass;
    },
    isExplored() {
      if (
        this.exploredCampuses.includes(this.uuid) &&
        this.exploredSchoolCards.includes(this.uuid)
      ) {
        return "marker--explored";
      }
      if (
        this.exploredCampuses.includes(this.uuid) &&
        !this.exploredSchoolCards.includes(this.uuid)
      ) {
        return "marker--explored";
      }
      if (
        !this.exploredCampuses.includes(this.uuid) &&
        !this.exploredSchoolCards.includes(this.uuid)
      ) {
        return "marker--unexplored";
      }
      if (
        !this.exploredCampuses.includes(this.uuid) &&
        this.exploredSchoolCards.includes(this.uuid)
      ) {
        return "marker--unexplored--mapexplored";
      }
      return "marker--unexplored";
    },
    isExploredHihighlighted() {
      if (
        this.exploredCampuses.includes(this.uuid) &&
        !this.exploredSchoolCards.includes(this.uuid)
      ) {
        return "marker--explored--highlighted";
      }
      if (
        this.exploredCampuses.includes(this.uuid) &&
        this.exploredSchoolCards.includes(this.uuid)
      ) {
        return "marker--explored--highlighted";
      }
      if (
        !this.exploredCampuses.includes(this.uuid) &&
        !this.exploredSchoolCards.includes(this.uuid)
      ) {
        return "marker--unexplored--highlighted";
      }
      if (
        !this.exploredCampuses.includes(this.uuid) &&
        this.exploredSchoolCards.includes(this.uuid)
      ) {
        return "marker--unexplored--mapexplored__highlighted";
      }
      return "marker--unexplored--highlighted";
    },
    isSmallIcon() {
      return "marker--smallIcon";
    },
    isSmallVacancieIcon() {
      return "marker--smallVacancieIcon";
    },
    isSmallIconHihighlighted() {
      return "marker--smallIconHighlighted";
    },
    is360FilterSet() {
      const tourFilter = this.multimediaFilter.find(
        (filter) => filter === "tour360"
      );
      if (tourFilter === undefined) {
        return true;
      }
      if (tourFilter !== undefined && this.has360Tour) {
        return true;
      }
      return false;
    },
    isVideoFilterSet() {
      const videoFilter = this.multimediaFilter.find(
        (filter) => filter === "video"
      );
      if (videoFilter === undefined) {
        return true;
      }
      if (videoFilter !== undefined && this.hasVideo) {
        return true;
      }
      return false;
    },
    isDroneFilterSet() {
      const droneFilter = this.multimediaFilter.find(
        (filter) => filter === "droneFlight"
      );
      if (droneFilter === undefined) {
        return true;
      }
      if (droneFilter !== undefined && this.hasDroneFlight) {
        return true;
      }
      return false;
    },
    isPaymentSet() {
      if (this.paymentFilter === null) {
        return true;
      }
      if (typeof this.paymentFilter !== "string") {
        if (this.paymentFilter.includes(this.payment)) {
          return true;
        }
      }
      if (this.paymentFilter === this.payment) {
        return true;
      }
      return false;
    },
    isPerformanceSet() {
      if (this.performanceFilter === null) {
        return true;
      }
      if (
        this.performanceFilter !== null &&
        this.performanceFilter === this.performance
      ) {
        return true;
      }
      return false;
    },
    isSepSet() {
      if (this.sepFilter === null) {
        return true;
      }
      if (this.sepFilter !== null && this.sepFilter && this.hasSep) {
        return true;
      }
      return false;
    },
    isVacanciesSet() {
      if (this.vacanciesFilter === null) {
        return true;
      }
      if (this.vacanciesFilter !== null && this.hasVacancies > 0) {
        return true;
      }
      return false;
    },
    isPieSet() {
      if (this.pieFilter === null) {
        return true;
      }
      if (this.pieFilter !== null && this.pieFilter && this.hasPie) {
        return true;
      }
      return false;
    },
    isDependencySet() {
      if (this.dependencyFilter === null) {
        return true;
      }
      if (
        this.dependencyFilter !== null &&
        this.dependencyFilter === this.dependency
      ) {
        return true;
      }
      return false;
    },
    isDistanceSet() {
      if (this.distanceFilter === null) {
        return true;
      }
      const campusLocation = {
        lat: this.location.lat,
        lng: this.location.lon,
      };
      const distanceToSchool = utils.getDistanceInKm(
        campusLocation,
        this.homeLocation
      );
      if (this.distanceFilter.limit) {
        if (distanceToSchool >= 5) {
          return true;
        }
      } else {
        if (
          distanceToSchool >= this.distanceFilter.from &&
          distanceToSchool < this.distanceFilter.to
        ) {
          return true;
        }
        if (this.distanceFilter.to === 50) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
