<template>
  <div class="notifications section-content">
    <!--<h3 class="notifications__title">
      {{ $t("dashboard.notifications.title") }}
    </h3>-->
    <div v-for="({ type, text }, i) in myNotifications" :key="i" class="notifications__container">
      <div class="notifications__container_img">
        <img
          v-if="type === 0"
          src="@/assets/dashboard/icons/red-point.svg"
          style="margin-right: 5px"
        />
        <img v-else src="@/assets/dashboard/icons/green-point.svg" style="margin-right: 5px" />
      </div>
      <div class="notifications__container_text">
        <span>
          {{ text }}
        </span>
        <!--<a
          class="notifications__container_text"
          v-t="'dashboard.notifications.link'"
          v-if="type === 1"
          href="https://pages.services/newhavenmagnetschools.com/expo-registration/"
        />-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import json from './notifications.json';

export default {
  name: "Notifications",
  data() {
    return {
      allNotifications: {
        general: [
          {
            text: this.$t("dashboard.notifications.text1"),
            type: 0,
          },
          {
            text: this.$t("dashboard.notifications.text2"),
            type: 1,
          },
          {
            text: this.$t("dashboard.notifications.text3"),
            type: 2,
          },
        ],
      },
      myNotifications: [],
    };
  },
  mounted() {
    // Note: if field type in json is 0 => circle is red; if type is 1 => circle is green.
    // eslint-disable-next-line no-prototype-builtins
    if (this.allNotifications.hasOwnProperty("general")) {
      this.myNotifications = this.allNotifications.general;
    }
    // eslint-disable-next-line no-prototype-builtins
    if (this.allNotifications.hasOwnProperty(this.legalGuardianUUID)) {
      this.myNotifications = this.myNotifications.concat(
        this.allNotifications[this.legalGuardianUUID]
      );
    }
  },
  computed: {
    ...mapGetters({
      legalGuardianUUID: "authentication/legalGuardianUUID",
    }),
  },
};
</script>
