<template>
  <v-container
    class="identification-container"
    :style="stepAux === 5 ? 'padding: 0px !important;' : mobile ? 'padding: 25px !important;' : ''"
  >
    <div v-if="stepAux !== 5" class="identification__profile_title">
      {{ $t("register.identification.title") }}
    </div>
    <div v-if="stepAux !== 5" class="identification__flow_chart-container">
      <flow-chart :currentStep="1" :subStep="totalStepsComplete" />
    </div>
    <student-name
      v-if="stepAux === 1"
      @nextStep="continueStep"
      @skipStep="skipStep"
      @backStep="backStep"
    />
    <user-relationship
      v-if="stepAux === 2"
      @nextStep="continueStep"
      @skipStep="skipStep"
      @backStep="backStep"
    />
    <user-relationship-specification
      v-if="showUserRelationshipExtraStep"
      @nextStep="continueStep"
      @skipStep="skipStep"
      @backStep="backStep"
    />
    <nationality
      v-if="stepAux === 4"
      @nextStep="continueStep"
      @skipStep="skipStep"
      @backStep="backStep"
    />
    <explain-view
      v-if="showDocumentInformation && stepAux === 5"
      @nextStep="continueStep"
      @skipStep="skipStep"
      @backStep="backStep"
      :btnMsg="$t('register.identification.explain_view.btn_msg')"
      :title="$t('register.identification.explain_view.title')"
      :subtitle="$t('register.identification.explain_view.subtitle')"
    />
    <document-number
      v-if="showDocumentInformation && stepAux === 6"
      @nextStep="continueStep"
      @skipStep="skipStep"
      @backStep="backStep"
    />
    <document-file
      v-if="showDocumentInformation && stepAux === 7"
      @nextStep="continueStep"
      @skipStep="skipStep"
      @backStep="backStep"
    />
    <basic-information
      v-if="stepAux === 8"
      @nextStep="continueStep"
      @skipStep="skipStep"
      @backStep="backStep"
    />
    <grade-of-interest
      v-if="stepAux === 9"
      @nextStep="continueStep"
      @skipStep="skipStep"
      @backStep="backStep"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import StudentName from "./identification/StudentName.vue";
import GradeOfInterest from "./identification/GradeOfInterest.vue";
import UserRelationship from "./identification/UserRelationship.vue";
import Nationality from "./identification/Nationality.vue";
import ExplainView from "./ExplainView.vue";
import DocumentNumber from "./identification/DocumentNumber.vue";
import DocumentFile from "./identification/DocumentFile.vue";
import BasicInformation from "./identification/BasicInformation.vue";
import UserRelationshipSpecification from "./identification/UserRelationshipSpecification.vue";
import FlowChart from "./FlowChart.vue";

export default {
  name: "Identification",
  components: {
    StudentName,
    GradeOfInterest,
    Nationality,
    BasicInformation,
    UserRelationship,
    UserRelationshipSpecification,
    ExplainView,
    DocumentNumber,
    DocumentFile,
    FlowChart,
  },
  data() {
    return {
      stepAux: 1,
      showUserRelationshipExtraStep: false,
      showDocumentInformation: false,
      lastCompleteStep: 1,
      totalStepsComplete: 0,
      skip: false,
    };
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
  },
  mounted() {
    this.stepAux = this.step;
    const index = 0;
    this.lastCompleteStep = this.currentSubSteps[index];
    this.totalStepsComplete = this.totalSubStepsComplete[index];
    if (this.step === 3) {
      this.showUserRelationshipExtraStep = true;
    }
    if (this.step === 5 || this.step === 6 || this.step === 7) {
      this.showDocumentInformation = true;
    }
  },
  methods: {
    ...mapActions({
      setCurrentSubSteps: "newStudentRegister/setCurrentSubSteps",
      setTotalSubStepsComplete: "newStudentRegister/setTotalSubStepsComplete",
    }),
    nextStep() {
      this.showUserRelationshipExtraStep = false;
      this.showDocumentInformation = false;
      if (this.stepAux < 9) {
        this.stepAux += 1;
        if (this.stepAux === 3) {
          if (!this.userRelationship.main_relationship) {
            this.showUserRelationshipExtraStep = true;
          } else {
            this.stepAux += 1;
          }
        }
        if (this.stepAux === 5 || this.stepAux === 6 || this.stepAux === 7) {
          /* CASO SIN DOCUMENTO O QUE SE SALTARON PREGUNTA DE LA
          NACIONALIDAD (para evitar inconsistencia de datos) */
          if (this.documentId === 10 || this.documentId === -1) {
            this.stepAux += 3;
          } else {
            this.showDocumentInformation = true;
          }
        }
        this.setCurrentSubSteps({ step: 1, subStep: this.lastCompleteStep });
        this.setTotalSubStepsComplete({ step: 1, subStep: this.totalStepsComplete });
      } else {
        this.setCurrentSubSteps({ step: 1, subStep: this.lastCompleteStep });
        this.setTotalSubStepsComplete({ step: 1, subStep: this.totalStepsComplete });
        this.$emit("nextRegisterStep");
      }
    },
    continueStep() {
      this.totalStepsComplete += 1;
      if (this.stepAux === 2) {
        if (this.userRelationship.main_relationship) {
          this.totalStepsComplete += 1;
        }
      }
      if (this.stepAux === 4) {
        // CASO SIN DOCUMENTO
        if (this.documentId === 10) {
          this.totalStepsComplete += 3;
        }
      }
      if (!this.skip) {
        this.lastCompleteStep = this.stepAux;
      }
      if (this.skip && this.lastCompleteStep === this.stepAux) {
        this.skip = false;
      }
      this.nextStep();
    },
    skipStep() {
      if (!this.skip) {
        this.lastCompleteStep = this.stepAux;
        this.skip = true;
      }
      this.nextStep();
    },
    backStep() {
      this.totalStepsComplete -= 1;
      this.showUserRelationshipExtraStep = false;
      this.showDocumentInformation = false;
      if (this.stepAux > 1) {
        this.stepAux -= 1;
        if (this.stepAux === 3) {
          if (!this.userRelationship.main_relationship) {
            this.showUserRelationshipExtraStep = true;
          } else {
            this.stepAux -= 1;
            this.totalStepsComplete -= 1;
          }
        }
        if (this.stepAux === 5 || this.stepAux === 6 || this.stepAux === 7) {
          /* CASO SIN DOCUMENTO O QUE SE SALTARON PREGUNTA DE LA
          NACIONALIDAD (para evitar inconsistencia de datos) */
          if (this.documentId === 10 || this.documentId === -1) {
            this.stepAux -= 3;
            this.totalStepsComplete -= 3;
          } else {
            this.showDocumentInformation = true;
          }
        }
        this.setCurrentSubSteps({ step: 1, subStep: this.lastCompleteStep });
      } else {
        this.$emit("backRegisterStep");
      }
    },
  },
  computed: {
    ...mapGetters({
      userRelationship: "newStudentRegister/userRelationship",
      currentSubSteps: "newStudentRegister/currentSubSteps",
      totalSubStepsComplete: "newStudentRegister/totalSubStepsComplete",
      document: "newStudentRegister/document",
    }),
    documentId() {
      if ("id" in this.document) {
        return this.document.id;
      }
      return -1;
    },
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
  },
};
</script>
