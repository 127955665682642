<template>
  <div class="simulation-detail bg-white rounded-md" :class="{ 'ml-4': mobile }">
    <div class="simulation-detail__section">
      <div class="simulation-detail__rank">
        <p class="text text--white text--bold">{{ detailData.campus.program_ranking }}</p>
      </div>
      <div class="simulation-detail__name-container">
        <p class="text text--small-01 text--word-break simulation-detail__name">
          {{ campusName }}
        </p>
        <!-- <p
          class="
            text text--small-01 text--word-break
            simulation-detail__program-shift
          " 
        >
          {{ programShift }}
        </p> -->
      </div>
      <div class="simulation-detail__avatar-container">
        <favorite-button
          :isFavorite="detailData.campus.isFavorite"
          class="simulation-detail__avatar-container__fav"
          float
          inSimulateView
        />
        <v-avatar tile size="81">
          <v-img :src="getSchoolImageSrc(detailData.campus)"> </v-img>
        </v-avatar>
      </div>
    </div>
    <div class="simulation-detail__section simulation-detail__section--probability pl-2">
      <p class="text text--small-01 mr-2">
        {{ $t("simulation.results.detail.subtitle") }} {{ year }}:
      </p>
      <div class="mr-1">
        <pie-chart :datacollection="datacollection" :options="miniChartOptions" />
      </div>
      <p class="text text--small-01">{{ formatRatex(detailData.ratex) }}%</p>
    </div>
    <div class="fill-width px-2">
      <div class="d-flex flex-column">
        <div class="simulation-detail__legend mt-0 pt-0">
          <div class="simulation-detail__legend__icon-container mr-4">
            <img
              :src="require(`@/assets/iconsChile/simulation/ChairWhite.svg`)"
              class="fill-width"
              height="32"
            />
          </div>
          <p class="text text--small-01">
            {{ detailData.vacancies }}
            {{ $t("campus-details.admission.vacants.vacancies") }}
          </p>
        </div>
        <div class="simulation-detail__legend">
          <div class="simulation-detail__legend__icon-container mr-4">
            <img
              :src="require(`@/assets/iconsChile/simulation/ChairGray.svg`)"
              class="fill-width"
            />
            <img
              :src="require(`@/assets/iconsChile/simulation/GrayLine.svg`)"
              class="simulation-detail__legend__icon-container__line"
            />
          </div>
          <p class="text text--small-01">
            {{ detailData.assigned_higher }}
            {{ $t("simulation.results.detail.priority_higher") }}
          </p>
        </div>
        <div class="simulation-detail__legend">
          <div class="simulation-detail__legend__icon-container mr-4">
            <img :src="require(`@/assets/iconsChile/simulation/ChairRed.svg`)" class="fill-width" />
          </div>
          <p class="text text--small-01">
            {{ detailData.assigned_equal }}
            {{ $t("simulation.results.detail.priority_equal") }}
          </p>
        </div>
        <div class="simulation-detail__legend" v-if="false">
          <div class="simulation-detail__legend__icon-container mr-4">
            <img
              :src="require(`@/assets/iconsChile/simulation/DarkerBlueChair.svg`)"
              class="fill-width width-fix-chair"
            />
          </div>
          <p class="text text--small-01">
            {{ detailData.assigned_lower }}
            {{ $t("simulation.results.detail.priority_lower") }}
          </p>
        </div>
        <div class="simulation-detail__legend">
          <div class="simulation-detail__legend__icon__img mr-4">
            <img
              :src="require(`@/assets/iconsChile/simulation/GrayPerson.svg`)"
              class="fill-width"
            />
          </div>
          <p class="simulation-detail__legend__icon-container__text">
            {{ detailData.nonassigned }} {{ $t("simulation.results.detail.no_assigned") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FavoriteButton from "@/components/explorer/favorite/FavoriteButton.vue";
import PieChart from "./PieChart.vue";

export default {
  name: "SimulationDetail",
  components: {
    FavoriteButton,
    PieChart,
  },
  props: {
    year: {
      type: Number,
      required: true,
    },
    detailData: {
      type: Object,
      required: true,
    },
    datacollection: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      miniChartOptions: {
        legend: {
          display: false,
        },
        fullWidth: true,
        tooltips: {
          enabled: false,
        },
      },
      showingDetail: null,
    };
  },
  mounted() {
    if (this.detailData.rank === 1) this.showingDetail = 0;
  },
  computed: {
    available() {
      const total = this.detailData.stats.seats - this.detailData.stats.n_assigned;
      const nRejected = this.detailData.stats.n_considered - total;

      return nRejected < 0 ? 0 : nRejected;
    },
    vacancies() {
      const total = this.detailData.stats.seats;
      return total;
    },
    /* eslint-disable camelcase */
    programLabel() {
      const thisProgram = this.detailData.programUUID;
      const { program } = this.detailData;
      if (program && thisProgram) {
        return `${this.detailData.campus.campus_name}, ${program.grade_name},${program.gender}, ${program.shift_label}`;
      }
      return this.detailData.campus.campus_name;
    },
    campusName() {
      return this.detailData.campus.campus_name;
    },
    programShift() {
      const { program } = this.detailData;
      return `${program.grade_name}, ${program.gender}, ${program.shift_label}`;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  /* eslint-enable camelcase */
  methods: {
    getSchoolImageSrc(campus) {
      let imglink = "";
      if (campus.image_thumb.length > 0) {
        imglink = campus.image_thumb[0].image_link;
        return imglink;
      }
      // eslint-disable-next-line global-require
      return require("@/assets/icons/school.svg");
    },
    formatRatex(ratex) {
      return Math.floor(ratex * 100);
    },
    openLink() {
      window.open(
        "https://www.newhavenmagnetschools.com/index.php/whats-the-process/neighborhood-sibling-preferences",
        "_blank"
      );
    },
  },
};
</script>
