<template>
  <div>
    <v-tooltip
      v-model="showTooltip"
      class=""
      bottom
      content-class="custom-tooltip"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="panels__tooltip panels__tooltip--right"
          icon
          dark
          small
          color="#1E2D4C"
          style="margin-top: -4px !important"
        >
          <v-icon dark> mdi-information </v-icon>
        </v-btn>
      </template>
      <span v-t="'campus-details.tooltip.teamInfo'" />
    </v-tooltip>

    <section class="campus-detail__panels__container__row">
      <section class="campus-detail__panels__container__item">
        <template v-if="true">
          <h3 class="campus-detail__panels__title text-left mb-2 mt-2">
            {{ $t("campus-details.panels.teachers.teachers") }}
          </h3>

          <p
            class="
              campus-detail__panels__title
              text-left
              mb-2
              font-weight-medium
            "
          >
            {{ team.total_teacher_label_2 }}
          </p>
        </template>
      </section>
      <section class="campus-detail__panels__container__item">
        <template v-if="true">
          <h3 class="campus-detail__panels__title text-left mb-2 mt-2">
            {{ $t("campus-details.panels.teachers.masters") }}
          </h3>

          <p
            class="
              campus-detail__panels__title
              text-left
              mb-2
              font-weight-medium
            "
          >
            {{ team.total_teacher_label_3 }}
          </p>
        </template>
      </section>
    </section>

    <section v-if="others.length > 0" class="campus-detail__panels__container">
      <picture class="pl-2 mt-3 mb-4 d-flex flex-row align-center">
        <img src="@/assets/icons/person-vector.svg" width="20px" />
        <img class="ml-1" src="@/assets/icons/person-vector.svg" width="20px" />
        <h3
          class="campus-detail__panels__title ml-2"
          v-t="'campus-details.team.support'"
        />
      </picture>

      <p
        class="
          campus-detail__panels__title
          text-left
          mb-2
          font-weight-medium
          ml-5
        "
        v-for="({ support_label, quantity }, index) in others"
        :key="index"
      >
        {{ quantity > 0 ? quantity : "" }} {{ support_label.support_name }}
      </p>
    </section>

    <section
      v-if="false"
      class="
        campus-detail__panels__container campus-detail__panels__container__blue
      "
    >
      <template v-if="false">
        <p
          class="
            campus-detail__panels__title campus-detail__panels__title--white
            text-left
            mb-2
          "
        >
          Highlight
        </p>

        <span
          class="
            campus-detail__panels__title campus-detail__panels__title--white
            text-left
            mb-2
            font-weight-medium
          "
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
          rutrum quis velit sit at. Turpis turpis egestas commodo amet quisque.
        </span>
      </template>
    </section>
  </div>
</template>
<script>
export default {
  name: "Teachers",
  data() {
    return {
      showTooltip: false,
    };
  },
  components: {},
  props: {
    team: {
      type: Object,
    },
    others: {
      type: Array,
    },
  },
  computed: {
    showTeam() {
      return (
        this.hasTeachers ||
        this.hasTeachersHighEducation ||
        this.others.length > 0
      );
    },
    hasTeachers() {
      return this.team.total_teacher_label_2 > 0;
    },
    hasTeachersHighEducation() {
      return this.team.total_teacher_label_3 > 0;
    },
    showDivisor() {
      const hasDataTeachers = this.hasTeachers || this.hasTeachersHighEducation;
      return this.others.length > 0 && hasDataTeachers;
    },
  },
};
</script>
