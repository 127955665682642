var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mime-qa"},[_c('button',{staticClass:"mt-5",attrs:{"type":"button"},on:{"click":_vm.closeMenu}},[_c('v-icon',{on:{"click":_vm.closeMenu}},[_vm._v(" $vuetify.icons.closeMapCard ")])],1),_c('h1',{directives:[{name:"t",rawName:"v-t",value:('menu.QA'),expression:"'menu.QA'"}],staticClass:"mime-qa__title text-left ml-6 mt-5"}),_c('v-expansion-panels',{staticClass:"fill-width pb-3",attrs:{"accordion":"","flat":""}},_vm._l((_vm.$t(
        'menu.mime-qa.questions'
      )),function(ref,index){
      var question = ref.question;
      var answer = ref.answer;
      var answerTwo = ref.answerTwo;
      var showContact = ref.showContact;
      var showLink = ref.showLink;
      var list = ref.list;
return _c('v-expansion-panel',{key:index,staticClass:"fill-width"},[_c('v-expansion-panel-header',{staticClass:"fill-width"},[_c('p',{staticClass:"mime-qa__question"},[_vm._v(_vm._s(question))])]),_c('v-expansion-panel-content',[_c('p',{staticClass:"mime-qa__text"},[_vm._v(_vm._s(answer))]),(showContact)?_c('div',{staticClass:"mime-qa__contact"},[_c('button',{staticClass:"mime-qa__contact__btn",on:{"click":function($event){return _vm.openContactLink()}}},[_vm._v(" "+_vm._s(_vm.$t("campus-details.pride_point.contact"))+" ")])]):_vm._e(),_c('ul',{staticClass:"mime-qa__list"},_vm._l((list),function(item,index){return _c('li',{key:index,staticClass:"mime-qa__list__li"},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('a',{staticClass:"mime-qa__text",attrs:{"href":showLink,"target":"_blank"}},[_vm._v(" "+_vm._s(showLink)+" ")]),(answerTwo)?_c('p',{staticClass:"mime-qa__text"},[_vm._v(" "+_vm._s(answerTwo)+" ")]):_vm._e()])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }