<template>
  <div
    class="video-container"
    :style="{
      '--width': `${width}px`,
      '--height': `${height}px`,
    }"
  >
    <vimeo-player
      ref="player"
      class="video-player"
      :video-id="videoID"
      :autoplay="true"
      :style="{
        '--width': `${width}px`,
        '--height': `${height}px`,
        '--vertical-offset': verticalOffset,
        '--horizontal-offset': horizontalOffset,
      }"
      :player-height="`${actualHeight}px`"
      :player-width="`${actualWidth}px`"
      @playing="onPlaying"
      :options="options"
    >
    </vimeo-player>
  </div>
</template>
<script>
export default {
  name: "CenteredVideo",
  data() {
    return {
      playing: false,
      options: {
        transparent: 0,
        controls: 0,
        portrait: true,
        muted: true,
        background: true,
        volume: 0,
      },
    };
  },
  mounted() {
    this.options = {
      ...this.options,
      width: `${this.actualWidth}px`,
      ...this.extraOptions,
    };
  },
  props: {
    height: {
      required: true,
      type: Number,
    },
    width: {
      required: true,
      type: Number,
    },
    videoID: {
      required: true,
      type: String,
    },
    extraOptions: {
      required: false,
      type: Object,
    },
  },
  methods: {
    onPlaying() {
      this.$emit("onPlaying");
    },
    play() {
      this.$refs.player.play();
    },
    pause() {
      this.$refs.player.pause();
    },
  },
  computed: {
    actualHeight() {
      if ((this.width * 9) / 16 < this.height) {
        return this.height;
      }
      return (this.width * 9) / 16;
    },
    actualWidth() {
      if ((this.height * 16) / 9 < this.width) {
        return this.width;
      }
      return (this.height * 16) / 9;
    },
    horizontalOffset() {
      return `${-Math.abs(this.width - this.actualWidth) / 2}px`;
    },
    verticalOffset() {
      return `${-Math.abs(this.height - this.actualHeight) / 2}px`;
    },
  },
};
</script>
