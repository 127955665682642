<template>
  <div class="fill-width">
    <div v-if="loading">
      <v-progress-circular indeterminate />
    </div>
    <div v-if="!loading">
      <v-row
        no-gutters
        v-for="(campus, index) in campuses"
        :key="`${campus.uuid}`"
        justify="center"
        class="mb-3"
      >
        <presimulated-card
          :campus="campus"
          :index="index"
          :campusUuid="campus.uuid"
          :program="campus.program"
          :ranking="campus.rank"
          :totalCards="campuses.length"
          :showRanking="showRanking"
          :showButtons="showButtons"
        />
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PresimulatedCard from "./PresimulatedCard.vue";

export default {
  name: "PresimulatedSchoolList",
  components: {
    PresimulatedCard,
  },
  props: {
    campuses: {
      type: Array,
      default: () => [],
    },
    simulated: {
      type: Boolean,
      default: false,
    },
    showRanking: {
      type: Boolean,
      default: true,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      loading: "simulation/loading",
    }),
  },
};
</script>
