<template>
  <span class="campus-detail__panels__message">
    {{ $t("campus-details.message_in_panel") }}
    <button @click="goToContactUs" type="button" v-t="'campus-details.message_in_panel_contact'" />
  </span>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "MessageInPanel",
  props: {
    from: {
      type: String,
    },
  },
  methods: {
    ...mapActions({
      setGoToFormContactFrom: "contactForm/setGoToFormContactFrom",
    }),
    goToContactUs() {
      document.getElementById("contact-us").scrollIntoView({ behavior: "smooth" });
      this.setGoToFormContactFrom({ from: this.from });
    },
  },
};
</script>
