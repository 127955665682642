<template>
  <div class="favorite-card">
    <school-card
      :campus="campus"
      :program="program"
      :isInFavorites="true"
      :ratex="ratex"
      :index="index"
      class="mr-5"
    />
    <div v-if="false" class="mr-5 mt-1" @click="trackingFavoriteCardDetails">
      <v-expansion-panels
        accordion
        flat
        dense
        class="favorite-card__expansion pb-5"
        v-model="showingDetail"
      >
        <v-expansion-panel>
          <v-expansion-panel-header
            v-if="showingDetail !== 0"
            class="favorite-card__expansion__header"
          >
            <div class="d-inline-flex fill-width align-center">
              <p class="text text--small-01">
                {{ $t("favorite.card.show-more-text") }}
              </p>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="ma-0 pa-0 fill-width" style="position: relative">
            <div class="fill-width favorite-card__expansion__content">
              <div class="favorite-card__expansion__content__info">
                <div class="favorite-card__expansion__content__info__box mr-2">
                  <div
                    v-if="campus.features.sep"
                    class="favorite-card__expansion__content__info__box__dot"
                  />
                </div>
                <p class="text text--small-01">
                  {{ $t("favorite.card.sep-title") }}
                </p>
              </div>
              <div class="favorite-card__expansion__content__info">
                <div class="favorite-card__expansion__content__info__box mr-2">
                  <div
                    v-if="campus.features.pie"
                    class="favorite-card__expansion__content__info__box__dot"
                  />
                </div>
                <p class="text text--small-01">
                  {{ $t("favorite.card.pie-title") }}
                </p>
              </div>
              <div class="favorite-card__expansion__content__info">
                <div class="favorite-card__expansion__content__info__box mr-2">
                  <div
                    v-if="campus.features.languages"
                    class="favorite-card__expansion__content__info__box__dot"
                  />
                </div>
                <p class="text text--small-01">
                  {{ $t("favorite.card.language-title") }}
                </p>
              </div>
              <div class="favorite-card__expansion__content__info">
                <div class="favorite-card__expansion__content__info__box mr-2">
                  <div
                    v-if="campus.features.internet"
                    class="favorite-card__expansion__content__info__box__dot"
                  />
                </div>
                <p class="text text--small-01">
                  {{ $t("favorite.card.internet-title") }}
                </p>
              </div>
              <div class="favorite-card__expansion__content__info">
                <div class="favorite-card__expansion__content__info__box mr-2">
                  <div
                    v-if="campus.features.sports"
                    class="favorite-card__expansion__content__info__box__dot"
                  />
                </div>
                <p class="text text--small-01">
                  {{ $t("favorite.card.sports-title") }}
                </p>
              </div>
              <div class="favorite-card__expansion__content__info">
                <div class="favorite-card__expansion__content__info__box mr-2">
                  <div
                    v-if="campus.features.extra_programmatic"
                    class="favorite-card__expansion__content__info__box__dot"
                  />
                </div>
                <p class="text text--small-01">
                  {{ $t("favorite.card.extraprogrammatic-title") }}
                </p>
              </div>
            </div>
            <div class="favorite-card__expansion__icon" @click="showingDetail = null">
              <v-icon size="24">mdi-chevron-up</v-icon>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SchoolCard from "@/components/explorer/general/SchoolCard.vue";

export default {
  name: "FavoriteCard",
  components: {
    SchoolCard,
  },
  props: {
    campus: {
      type: Object,
      required: true,
    },
    program: {
      type: Number,
      default: -1,
    },
    ratex: {
      type: Number,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      showingDetail: null,
      isOpen: false,
    };
  },
  methods: {
    ...mapGetters({
      legalGuardianUUID: "authentication/legalGuardianUUID",
      environment: "authentication/environment",
    }),
    trackingFavoriteCardDetails() {
      if (this.showingDetail === 0) {
        this.$mixpanel.track("click_favorites_detail", {
          distinct_id: this.legalGuardianUUID(),
          student_id: this.currentStudent.uuid,
          school_id: this.campus.uuid,
          env: this.environment(),
        });
      }
    },
  },
};
</script>
