<template>
  <v-card>
    <div class="remove-student-modal__main-container">
      <img :src="require('@/assets/iconsChile/simulation/ExclamationMark.svg')" />
      <v-row class="mb-8" no-gutters justify="center">
        <p class="text remove-student-modal__title">
          {{ $t("student_register.remove_dialog_title", [currentStudent.name]) }}
        </p>
      </v-row>
      <div class="my-6 remove-student-modal-btn_container">
        <base-button class="remove-btn mr-2" @click="$emit('remove')">
          {{ $t("student_register.remove") }}
        </base-button>
        <base-button class="cancel-btn" @click="$emit('cancel')">
          {{ $t("student_register.cancel") }}
        </base-button>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import BaseButton from "@/components/explorer/base/BaseButton.vue";

export default {
  name: "RemoveStudentModal",
  components: {
    BaseButton,
  },
  computed: {
    ...mapGetters({
      currentStudent: "authentication/currentStudent",
    }),
  },
};
</script>
