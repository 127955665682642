<template>
  <v-dialog
    max-width="700"
    max-height="515"
    overlay-color="black"
    v-model="showModal"
    @click:outside="toggleModal(null)"
  >
    <!-- main modal -->
    <div
      class="relative flex-row"
      style="display: flex; flex-direction: row"
      v-if="currentModal == 'information'"
    >
      <!-- x button -->
      <img
        v-show="videoLoaded"
        src="@/assets/dashboard/icons/close.svg"
        class="absolute"
        style="top: 10px; right: 10px; z-index: 101"
        @click="toggleModal(null)"
      />
      <!-- end x button -->
      <!-- video -->
      <CenteredVideo
        v-show="videoLoaded"
        :width="405"
        :height="515"
        videoID="315932378"
        @onPlaying="onPlaying"
      />
      <!-- end video -->
      <!-- content -->
      <div
        v-if="videoLoaded"
        style="width: 315px; height: 515px; background-color: #eef5ff"
        class="relative"
      >
        <!-- information -->
        <div style="padding: 30px" class="left dfm-text information-container">
          <br />
          <br />
          <p class="dfm-text big" style="font-size: 20px">Realizar un perfil digital</p>
          <br />
          <p class="dfm-text" style="font-weight: normal">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet
            luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim
            praesent elementum facilisis leo.
          </p>
          <br />
          <p class="dfm-text bold">
            Los costos de realizar un perfil digital con un equipo profesional, son tipicamente
            menor que ${precio}
          </p>
        </div>
        <!-- end information -->
        <!-- button -->
        <div class="dfm-open-button" @click="toggleModal('embassadors')">
          Quiero agendar una hora
        </div>
        <!-- end button -->
      </div>
      <!-- end content -->
    </div>
    <!-- end main modal -->
    <!-- selection modal -->
    <div
      v-if="currentModal == 'embassadors'"
      @click="toggleModal(null)"
      style="
        background-color: transparent;
        width: 1000px;
        overflow-y: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      "
    >
      <div
        style="
          margin-left: 10%;
          width: 900px;
          height: 600px;
          max-height: 600px;
          max-width: 900px;
          position: relative;
          overflow: hidden;
          border-radius: 10px;
        "
      >
        <iframe
          style="margin-left: -50px; margin-top: -66px"
          src="https://calendly.com/d/cp2-q7g-knp/ejemplo-1?embed_type=PopupWidget"
          width="1000"
          height="700"
          scrolling="no"
          frameborder="0"
        ></iframe>
      </div>

      <!--<vue-calendly
    style="background-color:green;margin:0px !important;padding:0px !important;overflow-y:hidden"
    @click="log()"
    @click.native="log()"
      :scrolling="false"
      :height="700"
      :width="500"
      url="https://calendly.com/d/cp2-q7g-knp/ejemplo-1"
       />-->
    </div>
    <!-- end selection modal -->
  </v-dialog>
</template>
<script>
import "vue2-datepicker/index.css";
import CenteredVideo from "./utils/CenteredVideo.vue";
import MeetingOrganizer from "./utils/MeetingOrganizer.vue";

export default {
  name: "DigitalProfile",
  props: {
    currentModal: {
      required: true,
    },
  },
  data() {
    return {
      videoLoaded: false,
      producers: [
        {
          name: "Carolina y equipo",
          img: "https://media.istockphoto.com/photos/behind-the-scene-cameraman-and-assistant-shooting-film-with-camera-picture-id982746520?k=20&m=982746520&s=612x612&w=0&h=yuWPP59JSwqyRmwRrxZfACTs84hVjn-gXBdaf5BYMnU=",
        },
        {
          name: "Productora Norte",
          img: "https://media.istockphoto.com/photos/man-hands-holding-movie-clapper-picture-id892375260?k=20&m=892375260&s=612x612&w=0&h=eeJHLpmA2Me1QTeBFryXamjuBImzyaZKTgAq9aW5uDw=",
        },
        {
          name: "Productora 2017",
          img: "https://media.istockphoto.com/photos/video-camera-in-film-or-movie-production-on-tripod-and-professional-picture-id1135569876?k=20&m=1135569876&s=612x612&w=0&h=ffhEwuRC9CjplUXwq-PAjcD2SMdTo7GrHQmTUWyPPU0=",
        },
      ],
    };
  },
  components: {
    CenteredVideo,
    MeetingOrganizer,
  },
  computed: {
    showModal() {
      return this.currentModal != null;
    },
  },
  methods: {
    toggleModal(modal) {
      if (modal == null) {
        this.videoLoaded = false;
      }
      this.$emit("setModal", modal);
    },

    onPlaying() {
      if (this.videoLoaded == false) {
        this.videoLoaded = true;
      }
    },
  },
};
</script>
<style>
.dfm-text {
  font-family: Roboto;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #0d22a8;
}
</style>
