<template>
  <v-row no-gutters class="payment-selector d-flex my-3" align="center" justify="start">
    <div
      v-ripple:ripple="{ center: true }"
      style="cursor: pointer"
      class="mr-2 d-flex align-center justify-center filter-checkbox"
      @click="changeIsSelected()"
    >
      <h1 v-if="isSelected">•</h1>
    </div>
    <img class="payment-selector__icon" :src="iconSelection(index)" />
    <span class="ml-2">
      {{ ": " + tag }}
    </span>
  </v-row>
</template>

<script>
export default {
  name: "Payment",
  props: {
    tag: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isSelected: false,
    };
  },
  computed: {
    getClassByCampusPayment() {
      const classes = {
        1: "free-container",
        2: "cheap-container",
        3: "medium-container",
        4: "expensive-container",
        99: "private-container",
      };
      return classes[this.index.toString()];
    },
    getAvatarTagByIndex() {
      const avatars = {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        99: "NA",
      };
      return avatars[this.index.toString()];
    },
    payload() {
      return {
        isSelected: this.isSelected,
      };
    },
  },
  methods: {
    changeIsSelected() {
      this.isSelected = !this.isSelected;
    },
    iconSelection(index) {
      let imgFile = "";
      if (index === 0) {
        imgFile = "price-free.svg";
      } else if (index === 1) {
        imgFile = "price-10-50.svg";
      } else if (index === 2) {
        imgFile = "price-50-100.svg";
      } else if (index === 3) {
        imgFile = "price-100.svg";
      } else {
        imgFile = "price-100.svg";
      }
      // eslint-disable-next-line
      const images = require("@/assets/iconsChile/campusDetail/" + imgFile);
      return images;
    },
  },
  watch: {
    payload: {
      deep: true,
      immediate: true,
      handler(newPayload) {
        this.$emit("input", { ...this.value, ...newPayload });
      },
    },
  },
};
</script>
