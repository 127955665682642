<template>
  <div class="explain-view-modal">
    <img
      src="@/assets/iconsPalmira/student_register/view-background.svg"
      class="explain-view-modal__background"
    />
    <div class="mb-8">
      <h1 class="explain-view-modal__title">{{ title }}</h1>
      <p class="explain-view-modal__content">{{ subtitle }}</p>
    </div>
    <div class="explain-view-btn__container">
      <v-btn icon @click="backMethod">
        <v-img src="@/assets/iconsPalmira/student_register/icons/back.svg" />
      </v-btn>
      <base-button class="btn-continue" @click="continueMethod">
        {{ btnMsg }}
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/explorer/base/BaseButton.vue";

export default {
  name: "ExplainView",
  components: {
    BaseButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    btnMsg: {
      type: String,
      required: true,
    },
  },
  methods: {
    continueMethod() {
      this.$emit("nextStep");
    },
    backMethod() {
      this.$emit("backStep");
    },
  },
};
</script>
