<template>
  <div class="identification-flow-chart" :class="{ 'identification-flow-chart--general': true }">
    <div>
      <div class="identification-flow-chart__card">
        <!--<div class="identification-flow-chart__card__name">
          <p
            class="identification-flow-chart__card__name__text"
            v-t="userUsername ? userUsername : $t('dashboard.profile.name-title') ">
          </p>
        </div>-->
        <div class="identification-flow-chart__card__div" v-if="false">
          <div
            class="identification-flow-chart__card__div__bar2"
            :class="{
              'identification-flow-chart__card__div__bar2--66percent':
                identificationComplete && locationComplete,
              'identification-flow-chart__card__div__bar2--100percent':
                identificationComplete && locationComplete && contactComplete,
            }"
            v-if="(identificationComplete || locationComplete) && !isGuest"
          >
            <div
              class="identification-flow-chart__card__div__bar2__text"
              :class="{
                'identification-flow-chart__card__div__bar2__text--general':
                  identificationComplete || locationComplete,
                'identification-flow-chart__card__div__bar2__text--general--66percent':
                  identificationComplete && locationComplete,
                'identification-flow-chart__card__div__bar2__text--100percent':
                  identificationComplete && locationComplete && contactComplete,
              }"
            >
              {{ percentageNumber() }}
            </div>
          </div>
          <div
            v-if="(!identificationComplete && !locationComplete && !contactComplete) || isGuest"
            class="identification-flow-chart__card__div__bar"
            :class="{
              'identification-flow-chart__card__div__bar--33percent':
                (identificationComplete || locationComplete) && !isGuest,
              'identification-flow-chart__card__div__bar--66percent--general':
                identificationComplete && locationComplete && !isGuest,
              'identification-flow-chart__card__div__bar--90percent': currentStep === 19,
            }"
          >
            <div class="identification-flow-chart__card__div__bar__text">0 %</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "IdentificationFlowChart",
  components: {},
  data() {
    return {
      password: null,
    };
  },
  computed: {
    ...mapGetters({
      currentStep: "userRegister/currentStep",
      identificationComplete: "userRegister/identificationComplete",
      locationComplete: "userRegister/locationComplete",
      contactComplete: "userRegister/contactComplete",
      userUsername: "authentication/userUsername",
      isGuest: "authentication/isGuest",
    }),
  },
  methods: {
    ...mapActions({}),
    // eslint-disable-next-line consistent-return
    percentageNumber() {
      if (
        (this.identificationComplete && !this.locationComplete) ||
        (!this.identificationComplete && this.locationComplete)
      ) {
        return "33%";
      }
      if (
        (this.identificationComplete && this.locationComplete && !this.contactComplete) ||
        (this.identificationComplete && !this.locationComplete && this.contactComplete) ||
        (!this.identificationComplete && this.locationComplete && this.contactComplete)
      ) {
        return "66%";
      }
      if (this.identificationComplete && this.locationComplete && this.contactComplete) {
        return "100%";
      }
    },
  },
};
</script>
