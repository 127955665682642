var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('feedback.applications.applications_to_date'),expression:"'feedback.applications.applications_to_date'"}],staticClass:"\n      feedback-applied-school__button\n      mb-1\n      feedback-applied-school__button-no-border\n    ",attrs:{"depressed":"","color":"#3A0AC0"},on:{"click":function($event){return _vm.setShowApplications()}}}),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showApplications),expression:"showApplications"}],staticClass:"feedback-applications-to-date py-5"},_vm._l((_vm.getApplications()),function(ref,index){
var text = ref.text;
var image = ref.image;
var customClassText = ref.customClassText;
var classImg = ref.classImg;
var customClass = ref.customClass;
return _c('div',{key:index},[(image !== undefined)?_c('picture',{class:customClass},[_c('img',{class:classImg ? 'ml-1' : '',attrs:{"src":image,"alt":"chair"}})]):_vm._e(),_c('span',{class:{
            'feedback-applications-to-date__margin': image === undefined,
            'ml-2': customClassText,
          }},[_vm._v(" "+_vm._s(text)+" ")])])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }