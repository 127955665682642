<template>
  <div class="schoollist-container">
    <div @click="goFavorites" class="schoollist-button clickable d-flex flex-row">
      <img src="@/assets/dashboard/icons/schoollist/administrate_icon.svg" alt="" />
      Administrar mi lista
    </div>
    <!-- container schools -->
    <div class="schoollist-schools-container">
      <!-- each school -->
      <div class="schoollist-school" v-for="(school, index) in favoriteSchoolsDetails" :key="index">
        <div class="schoollist-school-text">
          <div class="font-weight-bold">{{ school.campus_name }}</div>
          <!-- <div>{{school.sector.sector_name}}</div> -->
          <u @click="exploreCampusDetail(school)" class="font-weight-bold clickable">Ver perfil</u>
        </div>
        <picture class="schoollist-school-image">
          <!-- style="border-radius: 50%; margin: 7%; width: 107px; height: 107px;" -->
          <img
            :src="
              school.image_thumb.length > 0
                ? school.image_thumb[0].image_link
                : require('@/assets/icons/school.svg')
            "
          />
        </picture>
      </div>
    </div>

    <!-- modal campus detail / digital profile -->
    <v-dialog
      v-model="campusDetailModal"
      transition="dialog-bottom-transition"
      max-width="800px"
      content-class="favorites__dialog"
    >
      <campus-detail
        v-if="campusDetailModal"
        style="z-index: 1000"
        :outsideClose="dialogClosed"
        @close="closeCampusDetail"
        :inDashboard="true"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CampusDetail from "@/components/explorer/general/campus_detail/CampusDetail.vue";

export default {
  name: "SchoolList",
  components: {
    CampusDetail,
  },
  data() {
    return {
      campusDetailModal: false,
      dialogClosed: false,
    };
  },
  computed: {
    ...mapGetters({
      favoriteSchoolsDetails: "institutions/favoriteSchoolsDetails",
    }),
  },
  methods: {
    goFavorites() {
      this.$router.push("/favorites");
    },
    ...mapActions({
      getFavoriteSchoolsDetails: "institutions/getFavoriteSchoolsDetails",
      retrieveCampusDetails: "institutions/retrieveCampusDetails",
    }),
    exploreCampusDetail(school) {
      // this.tagMixPanel('click_dashboard_favourite_details');

      this.retrieveCampusDetails({ campusUuid: school.uuid });
      this.campusDetailModal = true;
    },
    closeCampusDetail() {
      // this.tagMixPanel('close_dashboard_favourite_details');
      this.campusDetailModal = false;
      this.stopActualVideo();
    },
  },
};
</script>

<style scoped>
.schoollist-container {
  height: 100%;
  width: 100%;
}
.schoollist-button {
  background: #2b5bff;
  box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  line-height: 97%;
  color: #deebff;
  height: 50px;
  width: calc(100% + 20px);
  position: absolute;
  bottom: 10px;
  left: -10px;
  justify-content: center;
  align-items: center;
  gap: 4%;
  padding: 1%;
}
.schoollist-schools-container {
  width: calc(100% - 0.1px);
  height: calc(100% - 32px);
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.schoollist-school {
  padding: 1rem 1.5rem;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  /* height: 125px; */
  display: flex;
  flex-direction: row;
}
.schoollist-school-image {
  width: 75px;
  height: 75px;
  border-radius: 50%;
}

.schoollist-school-image > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.schoollist-school-text {
  width: calc(100% - 80px);
  font-family: Roboto;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.07em;
  color: #0d22a8;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
