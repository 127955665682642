<template>
  <div id="navbar" class="campus-detail__navbar">
    <v-tabs
      fixed-tabs
      background-color="white"
      color="#4002F0"
      light
      show-arrows
      v-model="tabActive"
    >
      <v-tab
        v-for="({ name, element }, index) in navbar"
        :key="index"
        @click="scrollTo(element)"
        :href="`#${element}`"
        class="campus-detail__navbar--tab"
      >
        {{ name }}
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
export default {
  name: "CampusDetailNavbar",
  props: {
    isMobile: { type: Boolean },
    exitsInTheirOwnWords: { required: true },
    exitsVirtualTour: { required: true },
    exitsVideoDrone: { required: true },
    exitsInfrastructure: { required: true },
    exitsSports: { required: true },
    exitsExtracurriculars: { required: true },

    members: { type: Array },
    team: { type: Object },
    others: { type: Array },
  },
  data() {
    return {
      navbar: [],
      tabActive: "summary",
      swiperOption: {},
    };
  },
  created() {
    this.setNavbar();
  },
  mounted() {
    window.addEventListener("wheel", this.handleScroll);
    window.addEventListener("touchmove", this.handleScroll);
    window.addEventListener("touchstart", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("wheel", this.handleScroll);
    window.removeEventListener("touchmove", this.handleScroll);
    window.removeEventListener("touchstart", this.handleScroll);
  },
  /**
  computed: {
    showTeam() {
      return (
        this.hasTeachers
        || this.hasTeachersHighEducation
        || this.others.length > 0
      );
    },

    students() {
      const lastYear = Math.max(...this.members.map((member) => member.year));

      const filterStudentsWithLastyear = this.members.filter(
        ({ year }) => year === lastYear,
      );

      const totalStudents = filterStudentsWithLastyear
        .map((students) => students.total_members)
        .reduce((prev, curr) => prev + curr, 0);

      return totalStudents;
    },
    hasTeachers() {
      return this.team.total_teacher_label_2 > 0;
    },
    hasTeachersHighEducation() {
      return this.team.total_teacher_label_3 > 0;
    },
    showDivisor() {
      const hasDataTeachers = this.hasTeachers || this.hasTeachersHighEducation;
      return this.others.length > 0 && hasDataTeachers;
    },
  },
  */
  methods: {
    handleScroll() {
      this.navbar.forEach(({ element }) => {
        const target = document.getElementById(element);
        const topTarget = target.getBoundingClientRect().top;
        if (topTarget < 350 && topTarget < 370) {
          this.tabActive = element;
        }
      });
    },
    setNavbar() {
      this.navbar = [
        {
          name: this.$t("campus-details.navbar.summary"),
          element: "summary",
          enable: true,
        },
        {
          name: this.$t("campus-details.navbar.educational_focus"),
          element: "educational-focus",
          enable: true,
        },
        {
          name: this.$t("campus-details.navbar.in_their_own_words"),
          element: "in-their-own-words",
          enable: this.exitsInTheirOwnWords.length > 0,
        },
        {
          name: this.$t("campus-details.navbar.360_virtual"),
          element: "360-virtual",
          enable: this.exitsVirtualTour !== "",
        },
        {
          name: this.$t("campus-details.navbar.drone_footage"),
          element: "drone-footage",
          enable: this.exitsVideoDrone !== "",
        },
        {
          name: this.$t("campus-details.navbar.travel_time"),
          element: "travel-time",
          enable: true,
        },
        {
          name: this.$t("campus-details.navbar.application_and_enrollment"),
          element: "admission",
          enable: true,
        },
        {
          name: this.$t("campus-details.navbar.teachers"),
          element: "team",
          enable: true,
        },
        {
          name: this.$t("campus-details.navbar.students"),
          element: "students",
          enable: true,
        },
        {
          name: this.$t("campus-details.navbar.infrastructure"),
          element: "infrastructure",
          enable: this.exitsInfrastructure.length > 0,
        },
        {
          name: this.$t("campus-details.navbar.sports"),
          element: "sports",
          enable: this.exitsSports.length > 0,
        },
        {
          name: this.$t("campus-details.navbar.extracurriculars"),
          element: "extracurriculars",
          enable: this.exitsExtracurriculars.length > 0,
        },
        {
          name: this.$t("campus-details.navbar.price"),
          element: "price",
          enable: true,
        },
        {
          name: this.$t("campus-details.navbar.performance"),
          element: "performance",
          enable: true,
        },
        {
          name: this.$t("campus-details.navbar.vacancies"),
          element: "vacancy",
          enable: true,
        },
        // {
        //   name: this.$t('campus-details.navbar.language'),
        //   element: 'panels',
        //   enable: false,
        // },
      ];
      this.navbar = this.navbar.filter((tab) => tab.enable);
    },
    scrollTo(element) {
      document.getElementById(element).scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
