<template>
  <v-container
    class="location-container"
    :style="stepAux === 2 ? 'padding: 0px !important;' : mobile ? 'padding: 25px !important;' : ''"
  >
    <div v-if="stepAux !== 2" class="location__profile_title">
      {{ $t("register.location.title") }}
    </div>
    <div v-if="stepAux !== 2" class="location__flow_chart-container">
      <flow-chart :currentStep="2" :subStep="totalStepsComplete" />
    </div>
    <address-information
      v-if="stepAux === 1"
      @nextStep="continueStep"
      @skipStep="skipStep"
      @backStep="backStep"
    />
    <explain-view
      v-if="stepAux === 2"
      @nextStep="continueStep"
      @skipStep="skipStep"
      @backStep="backStep"
      :btnMsg="$t('register.location.explain_view.btn_msg')"
      :title="$t('register.location.explain_view.title')"
      :subtitle="$t('register.location.explain_view.subtitle')"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ExplainView from "./ExplainView.vue";
import AddressInformation from "./location/AddressInformation.vue";
import FlowChart from "./FlowChart.vue";

export default {
  name: "Identification",
  components: {
    AddressInformation,
    ExplainView,
    FlowChart,
  },
  data() {
    return {
      stepAux: 1,
      lastCompleteStep: 1,
      totalStepsComplete: 0,
      skip: false,
    };
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
  },
  mounted() {
    this.stepAux = this.step;
    const index = 1;
    this.lastCompleteStep = this.currentSubSteps[index];
    this.totalStepsComplete = this.totalSubStepsComplete[index];
  },
  methods: {
    ...mapActions({
      setCurrentSubSteps: "newStudentRegister/setCurrentSubSteps",
      setTotalSubStepsComplete: "newStudentRegister/setTotalSubStepsComplete",
    }),
    nextStep() {
      if (this.stepAux < 2) {
        this.stepAux += 1;
        this.setCurrentSubSteps({ step: 2, subStep: this.lastCompleteStep });
        this.setTotalSubStepsComplete({ step: 2, subStep: this.totalStepsComplete });
      } else {
        this.setCurrentSubSteps({ step: 2, subStep: this.lastCompleteStep });
        this.setTotalSubStepsComplete({ step: 2, subStep: this.totalStepsComplete });
        this.$emit("nextRegisterStep");
      }
    },
    continueStep() {
      this.totalStepsComplete += 1;
      if (!this.skip) {
        this.lastCompleteStep = this.stepAux;
      }
      if (this.skip && this.lastCompleteStep === this.stepAux) {
        this.skip = false;
      }
      this.nextStep();
    },
    skipStep() {
      if (!this.skip) {
        this.lastCompleteStep = this.stepAux;
        this.skip = true;
      }
      this.nextStep();
    },
    backStep() {
      this.totalStepsComplete -= 1;
      if (this.stepAux > 1) {
        this.stepAux -= 1;
        this.setCurrentSubSteps({ step: 2, subStep: this.lastCompleteStep });
      } else {
        this.$emit("backRegisterStep");
      }
    },
  },
  computed: {
    ...mapGetters({
      currentSubSteps: "newStudentRegister/currentSubSteps",
      totalSubStepsComplete: "newStudentRegister/totalSubStepsComplete",
    }),
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
  },
};
</script>
