<template>
  <section class="full-width d-flex flex-column feedback-bg-gray align-center py-10">
    <div class="w-full feedback-max-width feedback-padding-x">
      <div class="w-full d-flex items-start flex-column mb-2">
        <h1
          v-t="'feedback.important_dates.title'"
          class="feedback-title-bg-white feedback-bg-gray"
        />
      </div>

      <v-timeline align-top dense>
        <v-timeline-item v-for="(text, index) in lineTime" :key="index" color="#15E3CE" small>
          <div class="feedback-important-dates" v-html="text"></div>
        </v-timeline-item>
      </v-timeline>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeedbackImportantDates',
  props: {
    treatment: {
      type: Number,
      required: true,
    },
  },
  computed: {
    lineTime() {
      return this.treatment === 1
        ? this.$t('feedback.important_dates.assigned')
        : this.$t('feedback.important_dates.no-assigned');
    },
  },
};
</script>
