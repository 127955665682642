<template>
  <div class="user-relationship-specification_container">
    <div class="user-relationship-specification_title_container">
      <p class="user-relationship-specification_title">
        {{
          $t(
            "register.identification.user_relationship_spec.title",
            userRelationship.need_specification
              ? [otherRelationship]
              : [userRelationship.relationship_name]
          )
        }}
      </p>
    </div>
    <div class="user-relationship-specification_subtitle">
      {{ $t("register.identification.user_relationship_spec.subtitle") }}
    </div>
    <v-container class="user-relationship-specification_question_container">
      <div
        :class="
          mobile
            ? 'user-relationship-specification_question-mobile'
            : 'user-relationship-specification_question'
        "
        :style="
          isMother
            ? 'background: #2D10B4; margin-right: 5px;'
            : 'background: white; margin-right: 5px;'
        "
        @click="changeOpt(true)"
      >
        <p :style="isMother ? 'color: white;' : 'color: #2D10B4;'">
          {{ $t("register.identification.user_relationship_spec.first_opt") }}
        </p>
      </div>
      <div
        :class="
          mobile
            ? 'user-relationship-specification_question-mobile'
            : 'user-relationship-specification_question'
        "
        :style="
          isMother === false
            ? 'background: #2D10B4; margin-left: 5px;'
            : 'background: white; margin-left: 5px;'
        "
        @click="changeOpt(false)"
      >
        <p :style="isMother === false ? 'color: white;' : 'color: #2D10B4;'">
          {{ $t("register.identification.user_relationship_spec.second_opt") }}
        </p>
      </div>
    </v-container>
    <div v-show="isMother">
      <v-container
        class="user-relationship-specification_nationality_container"
        :style="mobile ? 'flex-direction: column' : ''"
      >
        <div class="nationality_question_container">
          <div class="nationality_question_title_container">
            <p class="nationality_question_title">
              {{ $t("register.identification.user_relationship_spec.nationality") }}
            </p>
          </div>
          <v-select
            class="custom-select custom-select nationality_question"
            :background-color="userNationalityMother !== null ? '#2D10B4' : '#FFFFFF'"
            :dark="userNationalityMother !== null ? true : false"
            :items="nationalities"
            item-text="nationality_name"
            item-value="id"
            v-model="userNationalityMother"
            outlined
          />
        </div>
        <div class="document_question_container">
          <div class="document_question_title_container">
            <p class="document_question_title">
              {{ $t("register.identification.user_relationship_spec.document_type") }}
            </p>
          </div>
          <v-select
            class="custom-select custom-select document_question"
            :background-color="userTypeOfDocumentMother !== null ? '#2D10B4' : '#FFFFFF'"
            :dark="userTypeOfDocumentMother !== null ? true : false"
            :items="typeOfDocumentsMother"
            item-text="identification_type_name"
            item-value="id"
            v-model="userTypeOfDocumentMother"
            outlined
          />
        </div>
      </v-container>
      <div v-if="documentMotherId !== 10" class="user-relationship-specification_ndoc_container">
        <div class="ndocument_title_container">
          <p class="ndocument_title">
            {{ $t("register.identification.user_relationship_spec.n_document") }}
          </p>
        </div>
        <div
          class="user-relationship-specification_ndoc_subtitle"
          v-if="
            documentMotherId === 1 ||
            documentMotherId === 2 ||
            documentMotherId === 3 ||
            documentMotherId === 4 ||
            documentMotherId === 5 ||
            documentMotherId === 6
          "
        >
          {{ $t("register.identification.user_relationship_spec.n_document_info") }}
        </div>
        <input
          v-model="userNDocumentMother"
          type="text"
          placeholder="1 2 3 4 5 6 7 8 9 0"
          class="register__content__input__div__input ndocument_input"
          :class="{
            'register__content__input__div__input--selected': userNDocumentMother.length > 0,
          }"
          @input="$v.userNDocumentMother.$touch()"
          @blur="$v.userNDocumentMother.$touch()"
          :disabled="documentMotherId === 10 || documentMotherId === -1"
          @keydown="
            documentMotherId === 1 ||
            documentMotherId === 2 ||
            documentMotherId === 3 ||
            documentMotherId === 5 ||
            documentMotherId === 6
              ? isNumber
              : ''
          "
          v-mask="
            documentMotherId === 1
              ? '##########'
              : documentMotherId === 2
              ? '###########'
              : documentMotherId === 3
              ? '##########'
              : documentMotherId === 5
              ? '###############'
              : documentMotherId === 6
              ? '#######'
              : ''
          "
        />
        <div class="ndocument_error" v-for="(error, i) in userNDocumentErrorsMother" :key="i">
          {{ error }}
        </div>
      </div>
      <v-container
        class="names_questions_container"
        :style="mobile ? 'flex-direction: column' : ''"
      >
        <div class="first-name_container">
          <div class="first-name_title_container">
            <p class="first-name_title">
              {{ $t("register.identification.user_relationship_spec.first_name") }}
            </p>
          </div>
          <input
            v-model="firstNameMother"
            type="text"
            class="register__content__input__div__input first-name_input"
            :class="{
              'register__content__input__div__input--selected': firstNameMother.length > 0,
            }"
          />
        </div>
        <div class="second-name_container" :style="mobile ? 'margin-top: 10px' : ''">
          <div class="second-name_title_container">
            <p class="second-name_title">
              {{ $t("register.identification.user_relationship_spec.second_name") }}
            </p>
          </div>
          <input
            v-model="secondNameMother"
            type="text"
            class="register__content__input__div__input second-name_input"
            :class="{
              'register__content__input__div__input--selected': secondNameMother.length > 0,
            }"
          />
        </div>
      </v-container>
      <v-container
        class="lastnames_questions_container"
        :style="mobile ? 'flex-direction: column;' : ''"
      >
        <div class="first-lastname_container">
          <div class="first-lastname_title_container">
            <p class="first-lastname_title">
              {{ $t("register.identification.user_relationship_spec.father_lastname") }}
            </p>
          </div>
          <input
            v-model="fatherLastNameMother"
            type="text"
            class="register__content__input__div__input first-lastname_input"
            :class="{
              'register__content__input__div__input--selected': fatherLastNameMother.length > 0,
            }"
          />
        </div>
        <div class="second-lastname_container" :style="mobile ? 'margin-top: 10px;' : ''">
          <div class="second-lastname_title_container">
            <p class="second-lastname_title">
              {{ $t("register.identification.user_relationship_spec.mother_lastname") }}
            </p>
          </div>
          <input
            v-model="motherLastNameMother"
            type="text"
            class="register__content__input__div__input second-lastname_input"
            :class="{
              'register__content__input__div__input--selected': motherLastNameMother.length > 0,
            }"
          />
        </div>
      </v-container>
    </div>
    <div v-show="isMother === false">
      <v-container
        class="user-relationship-specification_nationality_container"
        :style="mobile ? 'flex-direction: column' : ''"
      >
        <div class="nationality_question_container">
          <div class="nationality_question_title_container">
            <p class="nationality_question_title">
              {{ $t("register.identification.user_relationship_spec.nationality") }}
            </p>
          </div>
          <v-select
            class="custom-select custom-select nationality_question"
            :background-color="userNationalityFather !== null ? '#2D10B4' : '#FFFFFF'"
            :dark="userNationalityFather !== null ? true : false"
            :items="nationalities"
            item-text="nationality_name"
            item-value="id"
            v-model="userNationalityFather"
            outlined
          />
        </div>
        <div class="document_question_container">
          <div class="document_question_title_container">
            <p class="document_question_title">
              {{ $t("register.identification.user_relationship_spec.document_type") }}
            </p>
          </div>
          <v-select
            class="custom-select custom-select document_question"
            :background-color="userTypeOfDocumentFather !== null ? '#2D10B4' : '#FFFFFF'"
            :dark="userTypeOfDocumentFather !== null ? true : false"
            :items="typeOfDocumentsFather"
            item-text="identification_type_name"
            item-value="id"
            v-model="userTypeOfDocumentFather"
            outlined
          />
        </div>
      </v-container>
      <div v-if="documentFatherId !== 10" class="user-relationship-specification_ndoc_container">
        <div class="ndocument_title_container">
          <p class="ndocument_title">
            {{ $t("register.identification.user_relationship_spec.n_document") }}
          </p>
        </div>
        <div
          class="user-relationship-specification_ndoc_subtitle"
          v-if="
            documentFatherId === 1 ||
            documentFatherId === 2 ||
            documentFatherId === 3 ||
            documentFatherId === 4 ||
            documentFatherId === 5 ||
            documentFatherId === 6
          "
        >
          {{ $t("register.identification.user_relationship_spec.n_document_info") }}
        </div>
        <input
          v-model="userNDocumentFather"
          type="text"
          placeholder="1 2 3 4 5 6 7 8 9 0"
          class="register__content__input__div__input ndocument_input"
          :class="{
            'register__content__input__div__input--selected': userNDocumentFather.length > 0,
          }"
          @input="$v.userNDocumentFather.$touch()"
          @blur="$v.userNDocumentFather.$touch()"
          :disabled="documentFatherId === 10 || documentFatherId === -1"
          @keydown="
            documentFatherId === 1 ||
            documentFatherId === 2 ||
            documentFatherId === 3 ||
            documentFatherId === 5 ||
            documentFatherId === 6
              ? isNumber
              : ''
          "
          v-mask="
            documentFatherId === 1
              ? '##########'
              : documentFatherId === 2
              ? '###########'
              : documentFatherId === 3
              ? '##########'
              : documentFatherId === 5
              ? '###############'
              : documentFatherId === 6
              ? '#######'
              : ''
          "
        />
        <div class="ndocument_error" v-for="(error, i) in userNDocumentErrorsFather" :key="i">
          {{ error }}
        </div>
      </div>
      <v-container
        class="names_questions_container"
        :style="mobile ? 'flex-direction: column' : ''"
      >
        <div class="first-name_container">
          <div class="first-name_title_container">
            <p class="first-name_title">
              {{ $t("register.identification.user_relationship_spec.first_name") }}
            </p>
          </div>
          <input
            v-model="firstNameFather"
            type="text"
            class="register__content__input__div__input first-name_input"
            :class="{
              'register__content__input__div__input--selected': firstNameFather.length > 0,
            }"
          />
        </div>
        <div class="second-name_container" :style="mobile ? 'margin-top: 10px;' : ''">
          <div class="second-name_title_container">
            <p class="second-name_title">
              {{ $t("register.identification.user_relationship_spec.second_name") }}
            </p>
          </div>
          <input
            v-model="secondNameFather"
            type="text"
            class="register__content__input__div__input second-name_input"
            :class="{
              'register__content__input__div__input--selected': secondNameFather.length > 0,
            }"
          />
        </div>
      </v-container>
      <v-container
        class="lastnames_questions_container"
        :style="mobile ? 'flex-direction: column' : ''"
      >
        <div class="first-lastname_container">
          <div class="first-lastname_title_container">
            <p class="first-lastname_title">
              {{ $t("register.identification.user_relationship_spec.father_lastname") }}
            </p>
          </div>
          <input
            v-model="fatherLastNameFather"
            type="text"
            class="register__content__input__div__input first-lastname_input"
            :class="{
              'register__content__input__div__input--selected': fatherLastNameFather.length > 0,
            }"
          />
        </div>
        <div class="second-lastname_container" :style="mobile ? 'margin-top: 10px;' : ''">
          <div class="second-lastname_title_container">
            <p class="second-lastname_title">
              {{ $t("register.identification.user_relationship_spec.mother_lastname") }}
            </p>
          </div>
          <input
            v-model="motherLastNameFather"
            type="text"
            class="register__content__input__div__input second-lastname_input"
            :class="{
              'register__content__input__div__input--selected': motherLastNameFather.length > 0,
            }"
          />
        </div>
      </v-container>
    </div>
    <div class="btn_container">
      <v-btn icon @click="backMethod">
        <v-img src="@/assets/iconsPalmira/student_register/icons/back.svg" />
      </v-btn>
      <base-button v-if="isDisabled" class="btn-skip" @click="skipStep">
        {{ $t("register.identification.user_relationship_spec.skip_btn") }}
      </base-button>
      <base-button v-else class="btn-continue" @click="continueMethod">
        {{ $t("register.identification.user_relationship_spec.continue_btn") }}
      </base-button>
    </div>
    <v-dialog
      content-class="elevation-0"
      overlay-color="transparent"
      v-model="showSkipModal"
      max-width="370"
    >
      <warning-modal
        @skip="skipConfirmMethod"
        @complete="completeMethod"
        :title="$t('register.identification.user_relationship_spec.warning_modal.title')"
        :text="$t('register.identification.user_relationship_spec.warning_modal.text')"
        color="#FC010A"
        skipModal
      />
    </v-dialog>
  </div>
</template>

<script>
import { maxLength, minLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/explorer/base/BaseButton.vue";
import WarningModal from "../WarningModal.vue";

export default {
  name: "UserRelationshipSpecification",
  components: {
    BaseButton,
    WarningModal,
  },
  data() {
    return {
      showSkipModal: false,
      isMother: null,
      userTypeOfDocumentMother: null,
      userNationalityMother: 1,
      userNDocumentMother: "",
      firstUpdateMother: true,
      firstNameMother: "",
      secondNameMother: "",
      fatherLastNameMother: "",
      motherLastNameMother: "",
      userTypeOfDocumentFather: null,
      userNationalityFather: 1,
      userNDocumentFather: "",
      firstUpdateFather: true,
      firstNameFather: "",
      secondNameFather: "",
      fatherLastNameFather: "",
      motherLastNameFather: "",
    };
  },
  mounted() {
    this.userNDocumentFather = this.userNDocumentStoreFather;
    const { id: fatherUuid, nationality: fatherNat } = this.userDocumentStoreFather;
    if (fatherUuid) {
      this.userNationalityFather = fatherNat.id;
      this.userTypeOfDocumentFather = fatherUuid;
      this.isMother = false;
    } else {
      this.userTypeOfDocumentFather = this.typeOfDocumentsFather[0].id;
      this.firstUpdateFather = false;
    }
    this.userNDocumentMother = this.userNDocumentStoreMother;
    const { id, nationality } = this.userDocumentStoreMother;
    if (id) {
      this.userNationalityMother = nationality.id;
      this.userTypeOfDocumentMother = id;
      this.isMother = true;
    } else {
      this.userTypeOfDocumentMother = this.typeOfDocumentsMother[0].id;
      this.firstUpdateMother = false;
    }
    this.firstNameFather = this.firstNameFatherStore;
    this.secondNameFather = this.secondNameFatherStore;
    this.fatherLastNameFather = this.fatherLastNameFatherStore;
    this.motherLastNameFather = this.motherLastNameFatherStore;
    this.firstNameMother = this.firstNameMotherStore;
    this.secondNameMother = this.secondNameMotherStore;
    this.fatherLastNameMother = this.fatherLastNameMotherStore;
    this.motherLastNameMother = this.motherLastNameMotherStore;
  },
  validations: {
    userNDocumentMother: {
      minLength(userNDocumentMother) {
        if (this.documentMotherId === 1) {
          return minLength(10)(userNDocumentMother);
        }
        if (this.documentMotherId === 2) {
          return minLength(10)(userNDocumentMother);
        }
        if (this.documentMotherId === 5) {
          return minLength(15)(userNDocumentMother);
        }
        if (this.documentMotherId === 6) {
          return minLength(7)(userNDocumentMother);
        }
        return true;
      },
      maxLength(userNDocumentMother) {
        if (this.documentMotherId === 1) {
          return maxLength(10)(userNDocumentMother);
        }
        if (this.documentMotherId === 2) {
          return maxLength(11)(userNDocumentMother);
        }
        if (this.documentMotherId === 3) {
          return maxLength(10)(userNDocumentMother);
        }
        if (this.documentMotherId === 5) {
          return maxLength(15)(userNDocumentMother);
        }
        if (this.documentMotherId === 6) {
          return maxLength(7)(userNDocumentMother);
        }
        return true;
      },
    },
    userNDocumentFather: {
      minLength(userNDocumentFather) {
        if (this.documentFatherId === 1) {
          return minLength(10)(userNDocumentFather);
        }
        if (this.documentFatherId === 2) {
          return minLength(10)(userNDocumentFather);
        }
        if (this.documentFatherId === 5) {
          return minLength(15)(userNDocumentFather);
        }
        if (this.documentFatherId === 6) {
          return minLength(7)(userNDocumentFather);
        }
        return true;
      },
      maxLength(userNDocumentFather) {
        if (this.documentFatherId === 1) {
          return maxLength(10)(userNDocumentFather);
        }
        if (this.documentFatherId === 2) {
          return maxLength(11)(userNDocumentFather);
        }
        if (this.documentFatherId === 3) {
          return maxLength(10)(userNDocumentFather);
        }
        if (this.documentFatherId === 5) {
          return maxLength(15)(userNDocumentFather);
        }
        if (this.documentFatherId === 6) {
          return maxLength(7)(userNDocumentFather);
        }
        return true;
      },
    },
  },
  methods: {
    ...mapActions({
      setIdentificationStepThree: "newStudentRegister/setIdentificationStepThree",
    }),
    continueMethod() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.error(this.$t("views.register.error.invalid_form"));
      } else {
        const data = {
          nDocumentMother: this.userNDocumentMother,
          nDocumentFather: this.userNDocumentFather,
          documentMother: this.documentMother,
          documentFather: this.documentFather,
          firstNameFather: this.firstNameFather,
          secondNameFather: this.secondNameFather,
          fatherLastNameFather: this.fatherLastNameFather,
          motherLastNameFather: this.motherLastNameFather,
          firstNameMother: this.firstNameMother,
          secondNameMother: this.secondNameMother,
          fatherLastNameMother: this.fatherLastNameMother,
          motherLastNameMother: this.motherLastNameMother,
        };
        this.setIdentificationStepThree(data);
        this.$emit("nextStep");
      }
    },
    // eslint-disable-next-line consistent-return
    isNumber(evt) {
      const evtAux = evt || window.event;
      const charCode = evtAux.which ? evtAux.which : evtAux.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evtAux.preventDefault();
      } else {
        return true;
      }
    },
    backMethod() {
      this.$emit("backStep");
    },
    skipStep() {
      this.showSkipModal = true;
    },
    skipConfirmMethod() {
      this.$emit("skipStep");
    },
    completeMethod() {
      this.showSkipModal = false;
    },
    changeOpt(value) {
      this.isMother = value;
    },
  },
  computed: {
    ...mapGetters({
      types: "options/documentTypes",
      nationalities: "options/nationalities",
      userRelationship: "newStudentRegister/userRelationship",
      userDocumentStoreMother: "newStudentRegister/userDocumentMother",
      userDocumentStoreFather: "newStudentRegister/userDocumentFather",
      userNDocumentStoreMother: "newStudentRegister/userNDocumentMother",
      userNDocumentStoreFather: "newStudentRegister/userNDocumentFather",
      otherRelationship: "newStudentRegister/otherRelationship",
      firstNameFatherStore: "newStudentRegister/firstNameFather",
      secondNameFatherStore: "newStudentRegister/secondNameFather",
      fatherLastNameFatherStore: "newStudentRegister/fatherLastNameFather",
      motherLastNameFatherStore: "newStudentRegister/motherLastNameFather",
      firstNameMotherStore: "newStudentRegister/firstNameMother",
      secondNameMotherStore: "newStudentRegister/secondNameMother",
      fatherLastNameMotherStore: "newStudentRegister/fatherLastNameMother",
      motherLastNameMotherStore: "newStudentRegister/motherLastNameMother",
    }),
    motherCondition() {
      return (
        this.userNDocumentMother !== "" ||
        this.firstNameMother !== "" ||
        this.secondNameMother !== "" ||
        this.fatherLastNameMother !== "" ||
        this.motherLastNameMother !== ""
      );
    },
    fatherCondition() {
      return (
        this.userNDocumentFather !== "" ||
        this.firstNameFather !== "" ||
        this.secondNameFather !== "" ||
        this.fatherLastNameFather !== "" ||
        this.motherLastNameFather !== ""
      );
    },
    isDisabled() {
      return (
        (!this.motherCondition && !this.fatherCondition) ||
        (this.fatherCondition && this.userNDocumentErrorsFather.length > 0) ||
        (this.motherCondition && this.userNDocumentErrorsMother.length > 0)
      );
    },
    typeOfDocumentsMother() {
      const documents = this.types[this.userNationalityMother];
      return documents;
    },
    userNDocumentErrorsMother() {
      const errors = [];
      if (!this.$v.userNDocumentMother.$dirty) {
        return errors;
      }
      if (!this.$v.userNDocumentMother.minLength || !this.$v.userNDocumentMother.maxLength) {
        errors.push(this.$t("register.identification.user_relationship_spec.errors.length"));
      }
      return errors;
    },
    documentMother() {
      if (this.userTypeOfDocumentMother === null) {
        return null;
      }
      const opt = this.typeOfDocumentsMother.find(
        (elem) => elem.id === this.userTypeOfDocumentMother
      );
      return opt;
    },
    typeOfDocumentsFather() {
      const documents = this.types[this.userNationalityFather];
      return documents;
    },
    userNDocumentErrorsFather() {
      const errors = [];
      if (!this.$v.userNDocumentFather.$dirty) {
        return errors;
      }
      if (!this.$v.userNDocumentFather.minLength || !this.$v.userNDocumentFather.maxLength) {
        errors.push(this.$t("register.identification.user_relationship_spec.errors.length"));
      }
      return errors;
    },
    documentFather() {
      if (this.userTypeOfDocumentFather === null) {
        return null;
      }
      const opt = this.typeOfDocumentsFather.find(
        (elem) => elem.id === this.userTypeOfDocumentFather
      );
      return opt;
    },
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
    documentFatherId() {
      if (this.documentFather) {
        return this.documentFather.id;
      }
      return -1;
    },
    documentMotherId() {
      if (this.documentMother) {
        return this.documentMother.id;
      }
      return -1;
    },
  },
  watch: {
    typeOfDocumentsMother: {
      handler() {
        if (!this.firstUpdateMother) {
          this.userTypeOfDocumentMother = this.typeOfDocumentsMother[0].id;
        }
      },
    },
    userTypeOfDocumentMother: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal) {
          if (!this.firstUpdateMother) {
            this.userNDocumentMother = "";
          } else {
            this.firstUpdateMother = false;
          }
        }
      },
    },
    typeOfDocumentsFather: {
      handler() {
        if (!this.firstUpdateFather) {
          this.userTypeOfDocumentFather = this.typeOfDocumentsFather[0].id;
        }
      },
    },
    userTypeOfDocumentFather: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal) {
          if (!this.firstUpdateFather) {
            this.userNDocumentFather = "";
          } else {
            this.firstUpdateFather = false;
          }
        }
      },
    },
  },
};
</script>
