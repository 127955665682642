<template>
  <div class="section-content" style="display: flex; flex-direction: column; gap: 10px">
    <div style="width: calc(100% - 0.1px); display: flex; flex-direction: row; gap: 10px">
      <div class="analytics-module"><div class="analytics-content">Graph 1</div></div>
      <div class="analytics-module"><div class="analytics-content">Graph 2</div></div>
    </div>
    <div style="width: calc(100% - 0.1px); display: flex; flex-direction: row; gap: 10px">
      <div class="analytics-module"><div class="analytics-content">Graph 3</div></div>
      <div class="analytics-module"><div class="analytics-content">Graph 4</div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Analytics",
};
</script>
<style>
.analytics-module {
  width: 50%;
}
.analytics-content {
  background-color: #2b5bff;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}
</style>
