<template>
  <div class="notifications">
    <div
      v-for="({ type, text }, i) in myNotifications.general"
      :key="i"
      class="notifications__container"
    >
      <div class="notifications__container_img">
        <img
          v-if="type === 0"
          src="@/assets/dashboard/icons/red-point.svg"
          style="margin-right: 5px"
        />
        <img v-else src="@/assets/dashboard/icons/green-point.svg" style="margin-right: 5px" />
      </div>
      <div class="notifications__container_text">
        <span>
          {{ text }}
        </span>
        <!--<a
          class="notifications__container_text"
          v-t="'dashboard.notifications.link'"
          v-if="type === 1"
          href="https://pages.services/newhavenmagnetschools.com/expo-registration/"
        />-->
      </div>
    </div>
    <!--<div class="single-message-container">
        <div class="single-message-content">
            <div style="display:flex;flex-direction:row;">
                <img class="single-messages-icon" src="../../assets/dashboard/icons/message_incomplete.svg" />
                <span class="single-message-text" >10/15</span>
            </div>
          <span class="single-message-category">Tu comunidad</span>
        </div>
      </div>
      <div class="single-message-container">
        <div class="single-message-content">
            <div style="display:flex;flex-direction:row;">
                <img class="single-messages-icon" src="../../assets/dashboard/icons/message_incomplete.svg" />
                <span class="single-message-text" >10/10</span>
            </div>
        <span class="single-message-feedback">¡Buen trabajo! No tienes mails pendientes </span>
          <span class="single-message-category">Tu comunidad</span>
        </div>
      </div>
      <div class="single-message-container">
        <div class="single-message-content">
            <div style="display:flex;flex-direction:row;">
                <img class="single-messages-icon" src="../../assets/dashboard/icons/message_incomplete.svg" />
                <span class="single-message-text" >10/15</span>
            </div>
          <span class="single-message-feedback">blablablabla</span>
          <span class="single-message-category">Tu comunidad</span>
        </div>
      </div>-->

    <!-- Title
        <div >
            <p class="messages-title">Mensajería</p>
        </div>
        Etiquetas
        <div>
            Etiquetas
        </div>
         Messages

        <div v-for="(message, index) in messages" :key="index">
            <div class="messages-message-container">
            <span class="messages-message-type">{{message.messagertype}}</span>
            <span class="messages-message-title">{{message.title}}</span>
            <span class="messages-message-content">{{message.content}}</span>
            </div>
        </div>

        <!--<div class="admissions-button">
            <button @click="goMessages">
                Administrar mensajes
            </button>
        </div>-->
  </div>
</template>
<script>
export default {
  name: "Messages",
  methods: {
    goMessages() {
      this.$router.push("/messages");
    },
  },
  data() {
    return {
      myNotifications: {
        general: [
          {
            text: this.$t("dashboard.notifications.text1"),
            type: 0,
          },
          {
            text: this.$t("dashboard.notifications.text2"),
            type: 1,
          },
          {
            text: this.$t("dashboard.notifications.text3"),
            type: 2,
          },
        ],
      },
    };
  },
};
</script>
<style scoped>
.messages-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.messages-message-container {
  background: #96a8d8;
  width: 90%;
  height: 30px;
}
.messages-container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}
.single-message-container {
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.single-message-content {
  height: 100%;
  border-radius: 5px;
  background-color: #96a8d8;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  width: 100%;
  text-align: right;
  padding: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-wrap: nowrap;
}
.single-message-feedback {
  text-align: left;
  align-self: flex-end;
  color: #4002f0;
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  left: 12px;
  bottom: 24px;
}

.single-message-category {
  text-align: left;
  align-self: flex-end;
  color: white;
  font-size: 10px;
  position: absolute;
  left: 12px;
  bottom: 5px;
}
.single-messages-icon {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 10px;
  top: 5px;
}
.single-message-text {
  line-height: 32px;
  margin-left: 50px;
  font-size: 20px;
}
</style>
