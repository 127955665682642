var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"campus-detail__panels",attrs:{"id":"panels"}},[_c('v-expansion-panels',{attrs:{"accordion":"","flat":""}},_vm._l((_vm.panels),function(ref,index){
var name = ref.name;
var icon = ref.icon;
var title = ref.title;
var iconSize = ref.iconSize;
var isEnable = ref.isEnable;
return _c('v-expansion-panel',{key:index,staticClass:"mt-2 campus-detail__panels--disabled",style:(index === 0 ? 'background-color: #2B5BFF;' : ''),attrs:{"id":name,"disabled":!isEnable}},[_c('v-expansion-panel-header',{staticClass:"pb-0 px-0 pt-0",on:{"click":function($event){return _vm.trackMixPanel(name)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.isAdmissionPanel(name) ? 'white' : '#2B5BFF'}},[_vm._v(" $expand ")])]},proxy:true}],null,true)},[_c('picture',{staticClass:"d-flex align-center"},[_c('img',{class:{ 'campus-detail__panels__image-disable': !isEnable },attrs:{"src":icon,"width":iconSize}}),_c('h3',{staticClass:"campus-detail__panels__title ml-6",class:{
              'campus-detail__panels__title--white': _vm.isAdmissionPanel(name),
              'campus-detail__panels__title--gray': !isEnable,
            }},[_vm._v(" "+_vm._s(title)+" ")])])]),_c('v-expansion-panel-content',[(name === 'admission')?_c('Admission',{attrs:{"isSAE":_vm.campusDetail.campus_admission_system[0].admission_system_label.id,"minGrade":_vm.campusDetail.grade_min,"schoolPriority":_vm.campusDetail.campus_priority,"campus":_vm.campusDetail,"isGuest":_vm.isGuest,"requiredTests":_vm.campusDetail.required_tests,"requiredDocuments":_vm.campusDetail.required_documents,"linkAdmission":_vm.getLinkAdmission},on:{"closeDialog":_vm.goBack}}):_vm._e(),(name === 'team')?_c('Teachers',{attrs:{"team":_vm.campusDetail.campus_stats,"others":_vm.campusDetail.support_campus}}):_vm._e(),(name === 'students')?_c('Students',{attrs:{"members":_vm.campusDetail.institutionmember_campus}}):_vm._e(),(name === 'infrastructure')?_c('Infrastructure',{attrs:{"infrastructures":_vm.campusDetail.infraestructure_campus}}):_vm._e(),(name === 'sports')?_c('Sports',{attrs:{"sports":_vm.campusDetail.sport_campus}}):_vm._e(),(name === 'extracurriculars')?_c('Extracurriculars',{attrs:{"extracurriculars":_vm.campusDetail.extraactivity_campus}}):_vm._e(),(name === 'language')?_c('Language',{attrs:{"languages":_vm.campusDetail.language_campus}}):_vm._e(),(name === 'price')?_c('Price'):_vm._e(),(name === 'performance')?_c('Performance'):_vm._e(),(name === 'vacancy')?_c('Vacancies'):_vm._e(),(name === 'teachers')?_c('Teachers'):_vm._e()],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }