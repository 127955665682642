<template>
  <v-main class="main-container">
    <v-container fluid class="ma-0 pa-0 fill-height">
      <v-row no-gutters class="main-conatiner__row">
        <v-col
          cols="12"
          :md="showRightCol ? 7 : 12"
          :class="{ 'special-background': backgroundImage }"
        >
          <div class="main-layout-container" :class="{ 'main-layout-container--mobile': mobile }">
            <!--    SIDE OF THE HEADER    -->
            <mobile-explorer-header
              v-if="mobile && showNavigation && (exitsInfoGuest || !this.isGuest)"
              @toggleMenu="toggleMenu"
            />

            <v-dialog
              v-model="showNavigationBar"
              max-width="150"
              transition="slide-x-transition"
              content-class="main-conatiner__row__dialog"
              @click:outside="defaultState"
            >
              <!--  slim side bar hide    -->
              <custom-menu
                @logout="resetExplorer"
                @closeSession="closeSession"
                :inDashboard="false"
                @closeMenu="closeMainMenu"
                :showRegisterModal="showRegisterModal"
                @set-default="defaultState"
              />
            </v-dialog>
            <custom-menu
              @logout="resetExplorer"
              @closeSession="closeSession"
              :inDashboard="false"
              v-if="showMenu"
              @closeMenu="closeMainMenu"
              :showRegisterModal="showRegisterModal"
              @set-default="defaultState"
            />
            <!--  ROUTER VIEW    -->
            <div class="main-layout-container__body fill-width" id="body">
              <transition name="fade" mode="out-in">
                <keep-alive include="Explorer">
                  <router-view
                    :logout="logout"
                    :key="$route.fullPath"
                    @logoutSucces="logoutOk"
                    @signUp="setNavigationBar"
                  />
                </keep-alive>
              </transition>
            </div>
          </div>
        </v-col>
        <div v-show="this.$vuetify.breakpoint.width > 1500">
          <img class="main-layout-image" v-if="showRightCol" :src="imageSelection()" />
        </div>
      </v-row>
      <v-dialog
        content-class="elevation-0"
        overlay-color="transparent"
        v-model="showLoginDialog"
        max-width="400"
      >
        <WarningModal @close="closeWarningModal" @go-register="signUp" @go-login="signIn" />
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import WarningModal from "../components/explorer/guest/WarninigModal.vue";
import CONFIG from "@/config";
import CustomMenu from "./CustomMenu.vue";
import MobileExplorerHeader from "./MobileExplorerHeader.vue";

export default {
  name: "MainLayout",
  components: {
    CustomMenu,
    MobileExplorerHeader,
    WarningModal,
  },
  data() {
    return {
      showNavigationBar: false,
      logout: false,
      showAlert: false,
      showLoginDialog: false,
      CONFIG,
      showRegisterModal: false,
    };
  },
  props: {
    useLoginBackground: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      exitsInfoGuest: "authentication/exitsInfoGuest",
      isGuest: "authentication/isGuest",
      cornerBounds: "institutions/cornerBounds",
    }),
    showMenu() {
      const isMobileAndRouteLogin = !this.mobile && this.currentRoute !== "Login";
      const canShowMenu = isMobileAndRouteLogin && (this.exitsInfoGuest || !this.isGuest);
      return canShowMenu;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    noHeader() {
      return this.$route.meta.noHeader;
    },
    noMobileAppBar() {
      return this.$route.meta.noMobileAppBar;
    },
    showRightCol() {
      const routes = ["User Register"];
      return this.$vuetify.breakpoint.mdAndUp && routes.includes(this.$route.name);
    },
    backgroundImage() {
      return ["Login", "LoginApp", "User Register"].includes(this.$route.name);
    },
    showNavigation() {
      return !["Detail", "Login", "LoginApp", "Register Student", "User Register"].includes(
        this.$route.name
      );
    },
    currentRoute() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions({
      cleanGuestData: 'authentication/cleanGuestData',
      logoutSuccess: 'authentication/logoutSuccess',
      setGuestData: 'authentication/setGuestData',
      retrieveCampuses: 'institutions/retrieveCampuses',
    }),
    imageSelection() {
      let imageSelected = Math.floor(Math.random() * 4) + 1;
      // eslint-disable-next-line
      imageSelected = imageSelected.toString() + ".jpg";
      // eslint-disable-next-line
      const images = require("@/assets/login-photo-" + imageSelected);
      return images;
    },
    toggleMenu() {
      this.showNavigationBar = !this.showNavigationBar;
    },
    defaultState() {
      this.showRegisterModal = false;
    },
    showCustomMenu() {
      if (this.mobile) {
        return true;
      }
      return false;
    },
    goToPath(path) {
      if (this.currentRoute !== path) {
        if (path === "Explorer") {
          const auxPath = "/explorer";
          this.$router.push(auxPath);
        }
        if (path === "Postulate") {
          const auxPath = "/postulate";
          this.$router.push(auxPath);
        }
        if (path === "Favorites") {
          if (!this.isGuest) {
            const auxPath = "/favorites";
            this.$router.push(auxPath);
          } else {
            this.showLoginDialog = true;
          }
        }
      }
    },
    openConsiliumLink() {
      window.open("https://www.consiliumbots.com/en/home", "_blank");
    },
    closeMainMenu() {
      this.showNavigationBar = false;
    },
    closeSession() {
      this.cleanGuestData();
      this.$gAuth.signOut()
      if (this.currentRoute === 'Explorer') {
        this.logoutSuccess({
          callback: () => {
            const location = {
              coordinate: {
                lat: this.CONFIG.defaultLocation.lat,
                lng: this.CONFIG.defaultLocation.lng,
              },
              regionId: 24,
              municipalityName: "Santiago",
            };
            this.setGuestData({
              location,
              grades: [5],
              stage: 1,
              level: "PreKinder",
            });
          },
        });
      } else {
        this.logoutSuccess({ callback: () => this.$router.push("/") });
      }
      this.showNavigationBar = false;
      this.logout = true;
    },
    resetExplorer() {
      this.showNavigationBar = false;
      this.logout = true;
    },
    logoutOk() {
      this.logout = false;
    },
    closeWarningModal() {
      this.showLoginDialog = false;
    },
    setNavigationBar() {
      if (this.mobile) {
        this.showNavigationBar = true;
      }
      this.showRegisterModal = false;
      this.signUp();
    },
    signUp() {
      setTimeout(() => {
        this.showRegisterModal = true;
      }, 100);
    },
    signIn() {
      this.showLoginDialog = false;
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
