<template>
  <div class="admissions-container">
    <!-- stats -->
    <div class="admissions-stats">
      <div class="admissions-stats-text">
        <p class="admissions-stats-text">
          &nbsp; &nbsp; &nbsp; &nbsp;
          <img src="../../assets/dashboard/icons/admissionsandpostulations/face_icon.svg" alt="" />x
          blabla
        </p>
        <br />
        <p class="admissions-stats-text">
          &nbsp; &nbsp; &nbsp; &nbsp;
          <img
            class="admissions-icon"
            src="../../assets/dashboard/icons/admissionsandpostulations/person_icon.svg"
            alt=""
          />x blabla
        </p>
        <br />
        <p class="admissions-stats-text">
          &nbsp; &nbsp; &nbsp; &nbsp;
          <img src="../../assets/dashboard/icons/admissionsandpostulations/list_icon.svg" alt="" />
          x blabla
        </p>
        <br />
        <p class="admissions-stats-text">
          &nbsp; &nbsp; &nbsp; &nbsp;
          <img src="../../assets/dashboard/icons/admissionsandpostulations/mail_icon.svg" alt="" />
          x blabla
        </p>
        <br />
        <p class="admissions-stats-text">
          &nbsp; &nbsp; &nbsp; &nbsp;
          <img src="../../assets/dashboard/icons/admissionsandpostulations/mail_icon.svg" alt="" />
          x blabla
        </p>
        <br />
      </div>
    </div>
    <!-- line -->
    <div>
      <img
        class="admissions-line"
        src="../../assets/dashboard/icons/admissionsandpostulations/separation_line.svg"
      />
    </div>
    <!-- switch -->
    <div class="admissions-blue-box">
      <div class="admissions-flex">
        <span class="admissions-stats-text">Estoy aceptando postulaciones laborales</span>
        <v-switch
          v-model="toggleAcceptPostulations"
          inset
          dense
          color="#2B5BFF"
          class="admissions-toggle"
        >
        </v-switch>
      </div>
    </div>
    <br />
    <!-- button -->
    <div class="admissions-button">
      <button @click="$router.push('/admissions_and_postulations')">
        Administrar admisiones y postulaciones
      </button>
    </div>
    <!-- full screen view -->
  </div>
</template>

<script>
export default {
  name: "Admissions",
  components: {},
  data() {
    return {
      toggleAdministrate: false,
      toggleAcceptPostulations: false,
    };
  },
};
</script>

<style scoped>
.admissions-container {
  width: 360px;
  height: 331px;
}

.admissions-title {
  position: relative;
  right: 67px;
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 12px;
  line-height: 22px;
  color: #0d22a8;
}
.admissions-stats-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 17px;
  letter-spacing: 0.07em;
  color: #0d22a8;
}
.admissions-icon {
  width: 20px;
  height: 20px;
}
.admissions-line {
  margin: auto;
}
.admissions-blue-box {
  position: relative;
  top: 10px;
  background: #eef5ff;
}
.admissions-flex {
  display: flex;
  position: relative;
  top: 10px;
  left: 20px;
}
.admissions-toggle {
  width: 40px;
  height: 30px;
  position: relative;
  left: 30px;
  top: -23px;
  transform: scale(0.8);
}
</style>
