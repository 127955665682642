<template>
  <div class="reports section-content" :class="{ 'reports--guest': isGuest }">
    <h3 v-if="!isGuest" class="reports__title">
      {{ $t("dashboard.reports.title") }}
    </h3>
    <h3 v-else class="reports__title" :class="{ 'reports__title--guest': isGuest }">
      {{ $t("dashboard.reports.guest-info-title") }}
    </h3>
    <div v-if="hasApplicattions()">
      <div class="reports-main">
        <div class="reports-main__div-1">
          <img
            src="@/assets/iconsPalmira/reports/background-image1.svg"
            class="reports-main__div-1__image1"
          />
          <img
            src="@/assets/iconsPalmira/reports/background-image2.svg"
            class="reports-main__div-1__image2"
          />
          <img
            src="@/assets/iconsPalmira/reports/background-image3.svg"
            class="reports-main__div-1__image3"
          />
          <h4 class="reports-main__div-1__title">
            {{ $t("dashboard.reports.results") }}
          </h4>
        </div>
      </div>
      <div class="reports__link-btns" @click="showFeedback">
        <p class="reports__link-btns__text">
          {{
            CONFIG.feedbackType === "Results"
              ? $t("dashboard.reports.results-date")
              : $t("dashboard.reports.reports-date")
          }}
        </p>
      </div>
    </div>
    <div v-else class="mt-4">
      <h5 class="reports__info__title">
        {{ $t("dashboard.reports.guest-info-content-title") }}
      </h5>
      <p class="reports__info__text">
        {{ $t("dashboard.reports.guest-info-content") }}
      </p>
    </div>
    <v-dialog
      v-model="showFeedbackModal"
      content-class="dashborad-modal-feedback"
      max-width="360px"
    >
      <feedback class="reports__feedback" style="height: 90vh" />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Feedback from "../../views/feedback/Feedback.vue";
import CONFIG from "../../config";

export default {
  name: "Reports",
  components: {
    Feedback,
  },
  data() {
    return {
      showFeedbackModal: false,
      CONFIG,
    };
  },
  computed: {
    ...mapGetters({
      legalGuardianUuid: "authentication/legalGuardianUUID",
      isGuest: "authentication/isGuest",
      students: "authentication/students",
    }),
  },
  methods: {
    ...mapActions({}),
    showFeedback() {
      this.$mixpanel.track("open_feedback", {
        distinct_id: this.legalGuardianUUID,
        env: CONFIG.environment,
      });
      if (this.showFeedbackModal === false) {
        this.showFeedbackModal = true;
      } else {
        this.showFeedbackModal = false;
      }
    },
    hasApplicattions() {
      if (this.isGuest) {
        return false;
      }
      let hasApplications = false;
      this.students.forEach((student) => {
        if (student.has_application === true) {
          hasApplications = true;
        }
      });
      return hasApplications;
    },
  },
};
</script>
