<template>
  <section
    class="campus-detail__panels__container panel_container_row__white"
    :class="!hasVacancies ? 'panel_container_row--dashed' : ''"
    v-if="loaded"
  >
    <v-tooltip bottom content-class="custom-tooltip">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="panels__tooltip panels__tooltip--right"
          icon
          dark
          small
          color="#1E2D4C"
          style="margin-top: -4px !important"
        >
          <v-icon dark> mdi-information </v-icon>
        </v-btn>
      </template>
      <span v-t="'campus-details.tooltip.vacancies'" />
    </v-tooltip>
    <div v-if="hasVacancies">
      <div class="text-left">
        <div class="text-left dot dot_green ml-2" style="float: left" />
        <span style="color: #2b5bff" class="ml-2">
          {{ $t("campus-details.vacancies.yes-vacancies") }}
        </span>
      </div>
      <br />
      <div class="text-left">
        <div class="text-left dot dot_red ml-2" style="float: left" />
        <span style="color: #2b5bff" class="ml-2">
          {{ $t("campus-details.vacancies.no-vacancies") }}
        </span>
      </div>

      <br />

      <div v-for="(value, key, i) in vacancies" :key="i" class="text-left">
        <span
          class="campus-detail__panels__title text-left mb-2 font-weight-medium"
          style="margin-left: 10px"
        >
          <b>{{ key }}</b>
        </span>
        <div class="panel_container_row panel_container_row_wrap panel_container_row_start">
          <div
            class="panel_container_row_item panel_container_row_item_grey panel_container_row--text panel_container_column"
            v-for="(vacancy, j) in vacancies[key]"
            :key="j"
          >
            <div :class="getDotClass(vacancy.state)"></div>
            <span class="panel_container_row--text__medium">{{ vacancy.shortened }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="panel_container_row">
      Aún no tenemos información sobre las vacantes disponibles en este establecimiento
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Vacancies",
  data() {
    return {
      loaded: false,
      vacancies: {},
    };
  },

  mounted() {
    this.campusDetail.vacancies.forEach((vacancy) => {
      if (!(this.getStage(vacancy.grade_id) in this.vacancies)) {
        this.vacancies[this.getStage(vacancy.grade_id)] = {};
      }
      // if program has day / evening / night programs and one of them has vacancies,
      // then it has vacancies overall. we overwrite in case we find vacancies.
      if (vacancy.grade_id in this.vacancies[this.getStage(vacancy.grade_id)]) {
        if (this.vacancies[this.getStage(vacancy.grade_id)][vacancy.grade_id].vacancies == 0) {
          this.setVacancies(vacancy);
        }
      } else {
        this.setVacancies(vacancy);
      }
    });
    this.loaded = true;
  },
  computed: {
    ...mapGetters({
      campusDetail: "institutions/campusDetails",
      currentStudent: "authentication/currentStudent",
      isGuest: "authentication/isGuest",
      gradeOptions: "options/grades",
    }),
  },
  methods: {
    setVacancies(vacancy) {
      this.vacancies[this.getStage(vacancy.grade_id)][vacancy.grade_id] = {};
      this.vacancies[this.getStage(vacancy.grade_id)][vacancy.grade_id].vacancies =
        vacancy.regular_vacancies;
      this.vacancies[this.getStage(vacancy.grade_id)][vacancy.grade_id].shortened =
        this.getShortenedGrade(vacancy.grade_id);
      this.vacancies[this.getStage(vacancy.grade_id)][vacancy.grade_id].state =
        vacancy.regular_vacancies > 0 ? 1 : 0;
    },
    hasVacancies() {
      return this.vacancies != {};
    },
    getDotClass(state) {
      if (state == 0) {
        return "dot";
      }
      if (state == -1) {
        return "dot dot_grey";
      }
      if (state == 1) {
        return "dot dot_green";
      }
    },
    // Obtenido de https://stackoverflow.com/questions/9083037/convert-a-number-into-a-roman-numeral-in-javascript
    romanize(num) {
      const lookup = {
        M: 1000,
        CM: 900,
        D: 500,
        CD: 400,
        C: 100,
        XC: 90,
        L: 50,
        XL: 40,
        X: 10,
        IX: 9,
        V: 5,
        IV: 4,
        I: 1,
      };
      let roman = "";
      let i;
      for (i in lookup) {
        while (num >= lookup[i]) {
          roman += i;
          num -= lookup[i];
        }
      }
      return roman;
    },
    getGradeState() {},
    getStage(gradeId) {
      if (gradeId < 5) {
        return "Parvularia";
      }
      if (gradeId >= 5 && gradeId <= 6) {
        return "Preescolar";
      }
      if (gradeId <= 14) {
        return "Básica";
      }
      if (gradeId <= 18) {
        return "Media";
      }
      return null;
    },
    getShortenedGrade(gradeId) {
      // id 5 = prekinder, id 6 = kinder
      if (gradeId === 1) {
        return "SME"
      }
      if (gradeId === 2) {
        return "SMA"
      }
      if (gradeId === 3) {
        return "NME"
      }
      if (gradeId === 4) {
        return "NMA"
      }
      if (gradeId === 5) {
        return "PK";
      }
      if (gradeId == 6) {
        return "K";
      }
      if (gradeId <= 14) {
        return `${gradeId - 6}°B`;
      }
      if (gradeId <= 18) {
        return `${this.romanize(gradeId - 14)}°M`;
      }
    },
  },
};
</script>
