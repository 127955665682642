<template>
  <div class="fill-height campus-detail__photos">
    <v-carousel cycle hide-delimiters>
      <v-carousel-item v-for="({ image_link }, index) in filterPhotos" :key="index">
        <div class="fill-height" style="max-width: 800px">
          <v-img style="max-width: 800px" :src="image_link" />
        </div>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>
<script>
export default {
  name: "CampusDetailPhotos",
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  computed: {
    filterPhotos() {
      let images = [...this.images];
      images = images.filter(
        // eslint-disable-next-line camelcase
        ({ image_name }) => image_name !== "Thumbnail" && image_name !== "main"
      );
      return images;
    },
  },
};
</script>
