<template>
  <section class="full-width d-flex flex-column feedback-bg-gray items-center">
    <div
      class="w-full d-flex flex-column feedback-max-width feedback-padding-x"
    >
      <!-- Logos -->
      <picture class="d-flex full-width justify-space-between pt-3">
        <img
          src="@/assets/svg/logo-ministry.svg"
          alt="ministerio de educación"
        />
        <img src="@/assets/feedback/logo_sae.png" alt="sae" width="80px" />
      </picture>
      <!-- Welcome -->
      <h1
        class="feedback-title-purple mt-20"
        v-t="'feedback.title_resume'"
      />

      <!-- information -->
      <p class="feedback__text-welcome mt-6" v-t="'feedback.welcome_results'" />
    </div>

    <!-- Applications to -->
    <div class="feedback-padding-x w-full">
      <v-expansion-panels class="my-7" multiple>
        <v-expansion-panel
          v-for="(student, index) in feedbackApplication.students"
          :key="index"
          class="mt-3 d-flex flex-column justify-center align-center"
        >
          <v-expansion-panel-header
            disable-icon-rotate
            expand-icon=""
            class="feedback-container-expansion feedback-max-width"
          >
            <template v-slot="{ open }">
              <div
                @click="setMixPanel(student, open)"
                class="feedback-container-panel"
              >
                <div>
                  <p
                    v-t="{
                      path: 'feedback.application_to',
                      args: { name: getStudentName(student.applicant_firstname) },
                    }"
                  />
                  <!-- * ! Add validation to message -->
                  <p
                    v-show="showAlertMessage(student.api_response.unmatched)"
                    class="mt-2"
                    v-t="'feedback.warning_message'"
                  />
                </div>
                <div class="d-flex justify-center align-center">
                  <span class="feedback-container-panel__close" v-if="open">
                    <v-icon color="white" size="15">mdi-close</v-icon>
                  </span>
                  <span
                    class="feedback-container-panel__open"
                    v-else
                    v-t="'feedback.open'"
                  />
                </div>
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="mt-10 px-0 w-full">
            <div class="feedback-container-panel__content">
              <SchoolsYouApplied
                :applicantId="student.applicant"
                :schools="student.api_response.portfolio"
                :treatment="getTreatment(student.api_response.intervention)"
                :noAdmission="student.api_response.noAdmission"
                :admission="student.api_response.admission"
              />
              <FeedbackImportantDates  :treatment="getTreatment(student.api_response.intervention)"/>
              <FeedbackIndications :treatment="getTreatment(student.api_response.intervention)" />
              <!-- <ProbabilityAlert
                :unmatched="student.api_response.unmatched"
                v-if="showProbabilityAlert(student.api_response.unmatched)"
              />
              <RecommendationsMessage
                v-if="student.totalRecommendations.length !== 0"
                @show-recommendations="setShowRecommendations(index, student)"
                :studentName="student.applicant_firstname"
                :totalRecommendations="student.totalRecommendations.length"
                :totalSchoolsForComuna="student.schoolsAtLocations"
              />
              <div :key="reload">
                <FeedbackMap
                  :recommendations="student.totalRecommendations"
                  :favorites="student.api_response.portfolio"
                  :addresses="{
                    location: student.locationDefault,
                  }"
                  v-if="showRecommendations[index]"
                />
                <SchoolsRecommendations
                  :applicantId="student.applicant"
                  :grades="student.grades"
                  :addresses="{
                    location: student.locationDefault,
                  }"
                  :recommendations="setRecommendations(student.recommendation)"
                  v-if="showRecommendations[index]"
                />
              </div> -->
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <FeedbackEnd
      :uuid="feedbackApplication.pk"
      :firstname="feedbackApplication.firstname"
    />
  </section>
</template>

<script>
import SchoolsYouApplied from '@/components/feedback/SchoolsYouApplied.vue';
// import ProbabilityAlert from '@/components/feedback/ProbabilityAlert.vue';
// import RecommendationsMessage from '@/components/feedback/RecommendationsMessage.vue';
// import FeedbackMap from '@/components/feedback/FeedbackMap.vue';
// import SchoolsRecommendations from '@/components/feedback/SchoolsRecommendations.vue';
import FeedbackEnd from '@/components/feedback/FeedbackEnd.vue';
import { mapGetters } from 'vuex';
import FeedbackImportantDates from './FeedbackImportantDates.vue';
import FeedbackIndications from './FeedbackIndications.vue';

export default {
  name: 'FeedbackWelcome',
  components: {
    SchoolsYouApplied,
    // ProbabilityAlert,
    // RecommendationsMessage,
    // FeedbackMap,
    FeedbackEnd,
    // SchoolsRecommendations,
    FeedbackImportantDates,
    FeedbackIndications,
  },
  data() {
    return {
      showRecommendations: [],
      reload: 0,
    };
  },
  mounted() {
    this.showRecommendations = Array.from(
      this.feedbackApplication.students,
      () => false,
    );

    this.$mixpanel.track('fbin_open_feedback', {
      uuid: this.feedbackApplication.pk,
      firstname: this.feedbackApplication.firstname,
    });
  },
  computed: {
    ...mapGetters({
      feedbackApplication: 'feedback/feedbackApplication',
    }),
  },
  methods: {
    getTreatment(intervention) {
      return intervention?.treatment_id ?? 2;
    },
    setRecommendations(recommendations) {
      return recommendations?.length > 12 ? recommendations.slice(0, 12) : recommendations;
    },
    setMixPanel({ applicant, uuid, api_response }, isOpen) {
      const nameTag = !isOpen ? 'fbin_open_details' : 'fbin_close_details';
      this.$mixpanel.track(nameTag, {
        student_uuid: uuid,
        student_id: applicant,
        risK: api_response?.unmatched,
      });
    },
    getStudentName(name) {
      return name[0].toUpperCase() + name.substring(1);
    },
    setShowRecommendations(index, student) {
      const listCampusCode = student.recommendation.map(
        (school) => school.campus_code,
      );
      this.$mixpanel.track('fbin_more_options', {
        student_uuid: student.uuid,
        student_id: student.applicant,
        recommendations: listCampusCode,
        totalSchoolsForComuna: student?.schoolsAtLocations ?? 0,
        totalRecommendations: student?.totalRecommendations.length ?? 0,
      });
      this.showRecommendations[index] = true;
      this.reload += 1;
    },
    showAlertMessage(unmatched) {
      // return unmatched > 0.7;
      return false;
    },
    showProbabilityAlert(unmatched) {
      return unmatched > 0.3;
    },
  },
};
</script>
<style lang="scss" scoped>
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent !important;
  border-radius: 10px !important;
}
.v-expansion-panel::before {
  box-shadow: none !important;
}
.v-expansion-panel-header {
  padding: 0 !important;
}

.theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
  border: none !important;
}

.v-expansion-panel-content {
  width: 100% !important;
  padding: 0 !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 0px 0px !important;
}
</style>
