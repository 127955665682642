<template>
  <div class="document-file_container">
    <div class="document-file_title">
      {{ $t("register.identification.document_file.title") }}
    </div>
    <div class="document-file_subtitle">
      {{ $t("register.identification.document_file.subtitle") }}
    </div>
    <div class="document-file_question_container" :style="mobile ? 'flex-direction: column;' : ''">
      <div class="document-file_question_title_container">
        <div class="document-file_question_info">
          {{ nationalityInfo }}
        </div>
        <div class="document-file_question_title">
          <p class="document-file_question">
            {{ $t("register.identification.document_file.file_title") }}
          </p>
        </div>
        <v-file-input v-show="false" v-model="temporaryFile" id="fileUpload" />
        <div
          v-show="documentFile.length > 0"
          v-for="(doc, i) in documentFile"
          :key="i"
          class="document-file_files"
        >
          <img
            style="cursor: pointer"
            width="20px"
            src="@/assets/iconsPalmira/student_register/icons/close.svg"
            @click="remove(i)"
          />
          <div class="document-file_filename">
            {{ doc.name }}
          </div>
        </div>
        <div class="document-file_btn_container">
          <base-button class="document-file_btn" @click="chooseFiles">
            {{
              documentFile.length > 0
                ? $t("register.identification.document_file.upload_other")
                : $t("register.identification.document_file.upload_file")
            }}
          </base-button>
        </div>
      </div>
      <div v-if="false" class="document-file_img" :style="mobile ? 'margin-top: 10px;' : ''">
        <v-img max-width="156" src="@/assets/iconsPalmira/student_register/complementary-img.svg" />
      </div>
    </div>
    <div class="btn_container">
      <v-btn icon @click="backMethod">
        <v-img src="@/assets/iconsPalmira/student_register/icons/back.svg" />
      </v-btn>
      <base-button v-if="isDisabled" class="btn-skip" @click="skipStep">
        {{ $t("register.identification.document_file.skip_btn") }}
      </base-button>
      <base-button v-else class="btn-continue" @click="continueMethod">
        {{ $t("register.identification.document_file.continue_btn") }}
      </base-button>
    </div>
    <v-dialog
      content-class="elevation-0"
      overlay-color="transparent"
      v-model="showSkipModal"
      max-width="370"
    >
      <warning-modal
        @skip="skipConfirmMethod"
        @complete="completeMethod"
        :title="$t('register.identification.document_file.warning_modal.title')"
        :text="$t('register.identification.document_file.warning_modal.text')"
        color="#FC010A"
        skipModal
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/explorer/base/BaseButton.vue";
import WarningModal from "../WarningModal.vue";

export default {
  name: "DocumentFile",
  components: {
    BaseButton,
    WarningModal,
  },
  data() {
    return {
      documentFile: [],
      temporaryFile: null,
      showSkipModal: false,
      changeFile: false,
    };
  },
  mounted() {
    this.documentFile = this.file;
  },
  methods: {
    ...mapActions({
      setIdentificationStepSeven: "newStudentRegister/setIdentificationStepSeven",
      setFile: "newStudentRegister/setFile",
    }),
    formatFiles() {
      this.documentFile.forEach((elem, i) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const image = e.target.result.split(",")[1];
          const data = {
            imageB64: image,
            imageName: elem.name,
            imageNumber: i,
          };
          this.setIdentificationStepSeven(data);
        };
        reader.readAsDataURL(elem);
      });
    },
    continueMethod() {
      if (this.changeFile) {
        this.formatFiles();
        this.setFile(this.documentFile);
      }
      this.$emit("nextStep");
    },
    backMethod() {
      this.$emit("backStep");
    },
    chooseFiles() {
      document.getElementById("fileUpload").click();
    },
    remove(index) {
      this.documentFile.splice(index, 1);
    },
    skipStep() {
      this.showSkipModal = true;
    },
    skipConfirmMethod() {
      this.$emit("skipStep");
    },
    completeMethod() {
      this.showSkipModal = false;
    },
  },
  computed: {
    ...mapGetters({
      document: "newStudentRegister/document",
      file: "newStudentRegister/file",
    }),
    isDisabled() {
      // return this.documentFile.length === 0;
      return false;
    },
    nationalityInfo() {
      if ("nationality" in this.document) {
        return this.document.nationality.id === 1
          ? `${this.$t("register.identification.document_file.nationality_info.colombian")} | ${
              this.document.identification_type_name
            }`
          : `${this.$t("register.identification.document_file.nationality_info.foreign")} | ${
              this.document.identification_type_name
            }`;
      }
      return this.$t("register.identification.document_file.nationality_info.without");
    },
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
  },
  watch: {
    temporaryFile: {
      handler(newVal) {
        // this.documentFile.push(newVal);
        this.changeFile = true;
        this.documentFile = [newVal];
      },
    },
  },
};
</script>
