<template>
  <v-dialog
    max-width="700"
    max-height="1015"
    overlay-color="black"
    v-model="showModal"
    @click:outside="toggleModal(null)"
  >
    <!-- main modal -->
    <div
      class="relative flex-row"
      style="display: flex; flex-direction: row"
      v-if="currentModal == 'information'"
    >
      <!-- x button -->
      <img
        src="@/assets/dashboard/icons/close.svg"
        class="absolute"
        v-show="videoLoaded"
        style="top: 10px; right: 10px; z-index: 101"
        @click="toggleModal(null)"
      />
      <!-- end x button -->
      <!-- video -->
      <CenteredVideo
        :width="405"
        :height="515"
        videoID="559170153"
        @onPlaying="onPlaying"
        v-show="videoLoaded"
      />
      <!-- end video -->
      <!-- content -->
      <div
        v-if="videoLoaded"
        style="width: 315px; height: 515px; background-color: #eef5ff"
        class="relative"
      >
        <img
          src="@/assets/dashboard/icons/dfm-logo.svg"
          class="absolute"
          style="top: 30px; right: 0px"
        />
        <!-- information -->
        <div style="padding: 30px; margin-top: 100px" class="left information-container">
          <br />
          <br />
          <p class="dfm-text big" style="font-size: 20px">Objetivo</p>
          <br />
          <p class="dfm-text" style="font-weight: normal">
            Entregar información sobre los beneficios de la educación a estudiantes matriculados en
            las escuelas de Chile para fomentar la reflexión informada sobre las opciones
            disponibles al finalizar la educación media.
          </p>
          <br />
          <p class="dfm-text bold">
            Si quieres informarte más y participar, primero tienes que tomar una hora con un
            embajador/a de DFM
          </p>
        </div>
        <!-- end information -->
        <!-- button -->
        <div class="dfm-open-button" @click="toggleModal('embassadors')">
          Quiero reunirme con un embajador
        </div>
        <!-- end button -->
      </div>
      <!-- end content -->
    </div>
    <!-- end main modal -->
    <!-- selection modal -->
    <div
      v-if="currentModal == 'embassadors'"
      @click="toggleModal(null)"
      style="
        background-color: transparent;
        width: 1000px;
        overflow-y: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      "
    >
      <div
        style="
          margin-left: 10%;
          width: 900px;
          height: 600px;
          max-height: 600px;
          max-width: 900px;
          position: relative;
          overflow: hidden;
          border-radius: 10px;
        "
      >
        <iframe
          style="margin-left: -50px; margin-top: -66px"
          src="https://calendly.com/d/cp2-q7g-knp/ejemplo-1?embed_type=PopupWidget"
          width="1000"
          height="700"
          scrolling="no"
          frameborder="0"
        ></iframe>
      </div>
    </div>

    <!-- end selection modal -->
  </v-dialog>
</template>
<script>
import "vue2-datepicker/index.css";
import CenteredVideo from "./utils/CenteredVideo.vue";
import MeetingOrganizer from "./utils/MeetingOrganizer.vue";

export default {
  name: "DFMModal",

  props: {
    currentModal: {
      required: true,
    },
  },
  data() {
    return {
      videoLoaded: false,
      embassadors: [
        {
          name: "Jorge Barros",
          img: "https://media.istockphoto.com/photos/close-up-portrait-of-young-smiling-handsome-man-in-blue-polo-shirt-picture-id1090878494?k=20&m=1090878494&s=612x612&w=0&h=mhokplLdIWXnj4zUEfkLz-Ehctuw7TprMbgG-aa2Op0=",
        },
        {
          name: "Ema Pérez",
          img: "https://image.shutterstock.com/image-photo/beautiful-african-american-woman-smiling-260nw-402466177.jpg",
        },
        {
          name: "Javiera González",
          img: "https://image.shutterstock.com/image-photo/close-headshot-portrait-picture-smiling-260nw-1733598437.jpg",
        },
        {
          name: "Pedro Gutiérrez",
          img: "https://media.istockphoto.com/photos/success-happens-the-moment-you-believe-it-will-picture-id1262964438?k=20&m=1262964438&s=170667a&w=0&h=kIiDxeON3EuKjJSMKOTAK1VBCCWOevZCb-WoufRh1GU=",
        },
        {
          name: "Manuel Sánchez",
          img: "https://image.shutterstock.com/image-photo/good-mood-profile-picture-joyful-260nw-1849648105.jpg",
        },
        {
          name: "Francisca López",
          img: "https://image.shutterstock.com/image-photo/beautiful-brunette-girl-isolated-on-260nw-78647974.jpg",
        },
      ],
    };
  },
  components: {
    CenteredVideo,
    MeetingOrganizer,
  },
  methods: {
    toggleModal(modal) {
      if (modal == null) {
        this.videoLoaded = false;
      }
      this.$emit("setModal", modal);
    },

    onPlaying() {
      if (this.videoLoaded == false) {
        this.videoLoaded = true;
      }
    },
  },
  computed: {
    showModal() {
      return this.currentModal != null;
    },
  },
};
</script>
<style>
.dfm-text {
  font-family: Roboto;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #0d22a8;
}
</style>
