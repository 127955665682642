<template>
  <div class="disability-file_container">
    <div class="disability-file_title">
      {{ $t("register.priority.disability_file.title") }}
    </div>
    <div class="disability-file_title_container">
      <p class="required-field disability-file_title">
        {{ $t("register.priority.disability_file.file_title") }}
      </p>
    </div>
    <v-file-input v-show="false" v-model="temporaryFile" id="fileUpload" />
    <div
      v-show="documentFile.length > 0"
      v-for="(document, i) in documentFile"
      :key="i"
      class="disability-file_files"
    >
      <img
        style="cursor: pointer"
        src="@/assets/iconsPalmira/student_register/icons/close.svg"
        @click="remove(i)"
      />
      <div class="disability-file_filename">
        {{ document.name }}
      </div>
    </div>
    <div class="disability-file_btn_container">
      <base-button class="disability-file_btn" @click="chooseFiles">
        {{
          documentFile.length > 0
            ? $t("register.priority.disability_file.upload_other")
            : $t("register.priority.disability_file.upload_file")
        }}
      </base-button>
    </div>
    <div class="btn_container">
      <v-btn icon @click="backMethod">
        <v-img src="@/assets/iconsPalmira/student_register/icons/back.svg" />
      </v-btn>
      <base-button v-if="isDisabled" class="btn-skip" @click="skipStep">
        {{ $t("register.priority.disability_file.skip_btn") }}
      </base-button>
      <base-button v-else class="btn-continue" @click="continueMethod">
        {{ $t("register.priority.disability_file.continue_btn") }}
      </base-button>
    </div>
    <v-dialog
      content-class="elevation-0"
      overlay-color="transparent"
      v-model="showSkipModal"
      max-width="370"
    >
      <warning-modal
        @skip="skipConfirmMethod"
        @complete="completeMethod"
        :title="$t('register.priority.disability_file.warning_modal.title')"
        :text="$t('register.priority.disability_file.warning_modal.text')"
        color="#FC010A"
        skipModal
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/explorer/base/BaseButton.vue";
import WarningModal from "../WarningModal.vue";

export default {
  name: "DisabilityFile",
  components: {
    BaseButton,
    WarningModal,
  },
  data() {
    return {
      documentFile: [],
      temporaryFile: null,
      showSkipModal: false,
      changeFile: false,
    };
  },
  mounted() {
    this.documentFile = this.fileStore;
  },
  methods: {
    ...mapActions({
      setPriorityStepSix: "newStudentRegister/setPriorityStepSix",
      setDisabilityFile: "newStudentRegister/setDisabilityFile",
    }),
    formatFiles() {
      this.documentFile.forEach((elem, i) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const image = e.target.result.split(",")[1];
          const data = {
            imageB64: image,
            imageName: elem.name,
            imageNumber: i,
          };
          this.setPriorityStepSix(data);
        };
        reader.readAsDataURL(elem);
      });
    },
    continueMethod() {
      if (this.changeFile) {
        this.formatFiles();
        this.setDisabilityFile(this.documentFile);
      }
      this.$emit("nextStep");
    },
    backMethod() {
      this.$emit("backStep");
    },
    chooseFiles() {
      document.getElementById("fileUpload").click();
    },
    remove(index) {
      this.documentFile.splice(index, 1);
    },
    skipStep() {
      this.showSkipModal = true;
    },
    skipConfirmMethod() {
      this.$emit("skipStep");
    },
    completeMethod() {
      this.showSkipModal = false;
    },
  },
  computed: {
    ...mapGetters({
      fileStore: "newStudentRegister/disabilityFile",
    }),
    isDisabled() {
      return this.documentFile.length === 0;
    },
  },
  watch: {
    temporaryFile: {
      handler(newVal) {
        // this.documentFile.push(newVal);
        this.changeFile = true;
        this.documentFile = [newVal];
      },
    },
  },
};
</script>
