<template>
  <section class="feedback-map-info">
    <div class="feedback-map-info__content">
      <div>
        <h1
          class="feedback-map-info__content__title"
          style="width: 148px"
          v-t="'feedback.map_info.title1'"
        />
        <h1
          class="feedback-map-info__content__title"
          v-t="
            admission.admission_status.id === 1
              ? 'feedback.map_info.title2'
              : 'feedback.map_info.title3'
          "
          style="width: 180px"
        />
      </div>
    </div>
    <div class="feedback-map-info__content__info">
      <div
        class="feedback-map-info__content__info__div mb-4"
        v-if="admission.admission_status.id === 1"
      >
        <div
          class="feedback-map-info__content__info__div__circle feedback-map-info__content__info__div__circle--assigned"
        />
        <p class="feedback-map-info__content__info__div__text" v-t="'feedback.map_info.assigned'" />
      </div>
      <div class="feedback-map-info__content__info__div mb-4">
        <div
          class="feedback-map-info__content__info__div__circle feedback-map-info__content__info__div__circle--not-assigned"
        />
        <p
          class="feedback-map-info__content__info__div__text"
          v-t="
            admission.admission_status.id === 1
              ? 'feedback.map_info.not-assigned'
              : 'feedback.map_info.application'
          "
        />
      </div>
      <div
        class="feedback-map-info__content__info__div mb-4"
        v-if="admission.admission_status.id !== 1"
      >
        <div
          class="feedback-map-info__content__info__div__circle feedback-map-info__content__info__div__circle--recommendation"
        />
        <p
          class="feedback-map-info__content__info__div__text"
          v-t="'feedback.map_info.recommendation'"
        />
      </div>
      <div class="feedback-map-info__content__info__div" v-if="admission.admission_status.id !== 1">
        <div
          class="feedback-map-info__content__info__div__circle feedback-map-info__content__info__div__circle--selected"
        />
        <p class="feedback-map-info__content__info__div__text" v-t="'feedback.map_info.selected'" />
      </div>
    </div>
  </section>
</template>

<script>
import CONFIG from "../../config";

export default {
  name: "ApplicationMapInfo",
  props: {
    admission: {
      type: [Object, Array],
      default: () => {},
    },
  },
  data() {
    return {
      CONFIG,
    };
  },
  computed: {},
};
</script>
