var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"w-full flex justify-center bg-primary py-8",class:{ 'feedback-other-recommendation': _vm.CONFIG.feedback === 'Palmira' }},[_c('div',{staticClass:"w-full max-w-desktop flex flex-col md:items-center"},[_c('h1',{staticClass:"bg-white rounded-md px-1 text-gray text-xl",class:{ 'feedback-other-recommendation__title': _vm.CONFIG.feedback === 'Palmira' }},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('feedback.other_alternatives.title_one_name'),expression:"'feedback.other_alternatives.title_one_name'"}],class:{
          'feedback-other-recommendation__title__text': _vm.CONFIG.feedback === 'Palmira',
        }}),(_vm.CONFIG.feedback !== 'Palmira')?_c('span',{directives:[{name:"t",rawName:"v-t",value:('feedback.other_alternatives.title_bold'),expression:"'feedback.other_alternatives.title_bold'"}],staticStyle:{"font-weight":"bold"}}):_vm._e(),_c('span',{directives:[{name:"t",rawName:"v-t",value:({
          path: 'feedback.other_alternatives.title_two_name',
          args: { nameStudent: _vm.name },
        }),expression:"{\n          path: 'feedback.other_alternatives.title_two_name',\n          args: { nameStudent: name },\n        }"}],class:{
          'feedback-other-recommendation__title__text feedback-other-recommendation__title__text--inline':
            _vm.CONFIG.feedback === 'Palmira',
        }})]),_c('div',{staticClass:"flex flex-col justify-center"},[_vm._m(0),_c('label',{directives:[{name:"t",rawName:"v-t",value:({
          path: 'feedback.other_alternatives.institutions_applications',
          args: { institutions: _vm.totalApplications },
        }),expression:"{\n          path: 'feedback.other_alternatives.institutions_applications',\n          args: { institutions: totalApplications },\n        }"}],staticClass:"text-white text-xs text-center font-normal",class:{ 'px-10': _vm.CONFIG.feedback === 'Palmira' }}),_vm._m(1),_c('label',{directives:[{name:"t",rawName:"v-t",value:({
          path: 'feedback.other_alternatives.institutions_in_two_km',
          args: { institutions: _vm.institutionsAroud2km },
        }),expression:"{\n          path: 'feedback.other_alternatives.institutions_in_two_km',\n          args: { institutions: institutionsAroud2km },\n        }"}],staticClass:"text-white text-xs text-center font-normal",class:{ 'px-10': _vm.CONFIG.feedback === 'Palmira' }}),_vm._m(2),_c('label',{directives:[{name:"t",rawName:"v-t",value:({
          path: 'feedback.other_alternatives.letter_performance_institutions',
          args: { institutions: _vm.institutionsCopayLowHighPerformance },
        }),expression:"{\n          path: 'feedback.other_alternatives.letter_performance_institutions',\n          args: { institutions: institutionsCopayLowHighPerformance },\n        }"}],staticClass:"text-white text-xs text-center font-normal",class:{ 'px-10': _vm.CONFIG.feedback === 'Palmira' }})])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('picture',{staticClass:"w-full flex justify-center mt-8"},[_c('img',{attrs:{"src":require("@/assets/svg/total-marker.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('picture',{staticClass:"w-full flex justify-center mt-8"},[_c('img',{attrs:{"src":require("@/assets/svg/marker-2km.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('picture',{staticClass:"w-full flex justify-center mt-8"},[_c('img',{attrs:{"src":require("@/assets/svg/marker-performance.svg")}})])}]

export { render, staticRenderFns }