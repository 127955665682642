<template>
  <div>
    <div class="d-flex flex-column justify-center align-center" v-if="mobile">
      <div class="favorite-list-title">
        {{ $t("favorite.title") }}
      </div>
      <button
        class="favorite-list-download-button mt-2"
        v-show="isDownloadable"
        @click="generateReport"
      >
        <img src="@/assets/icons/save_alt.svg" />
        <div>
          {{ $t("favorite.categories.download-btn-text") }}
        </div>
      </button>
    </div>
    <div class="d-flex">
      <v-col v-if="!mobile" cols="3" class="favorite-list-left-col-container d-flex">
        <div class="favorite-list-left-col">
          <div class="favorite-list-left-col-content">
            <div class="favorite-list-title">
              {{ $t("favorite.title") }}
            </div>
            <div class="favorite-list-info">
              {{ $t("favorite.subtitle") }}
            </div>
            <div class="favorite-list-icon-container">
              <div class="favorite-list-icon-text ml-4">
                {{ $t("favorite.categories.payment") }}
              </div>
              <div class="favorite-list-icon-div mr-8">
                <img
                  class="favorite-list-icon"
                  src="@/assets/iconsChile/campusDetail/price-free.svg"
                />
                <img
                  class="favorite-list-icon"
                  src="@/assets/iconsChile/campusDetail/price-10-50.svg"
                />
                <img
                  class="favorite-list-icon"
                  src="@/assets/iconsChile/campusDetail/price-50-100.svg"
                />
                <img
                  class="favorite-list-icon"
                  src="@/assets/iconsChile/campusDetail/price-100.svg"
                />
              </div>
            </div>
            <div class="favorite-list-icon-container">
              <div class="favorite-list-icon-text ml-4">
                {{ $t("favorite.categories.performance") }}
              </div>
              <div class="favorite-list-icon-div mr-8">
                <img
                  class="favorite-list-icon"
                  src="@/assets/iconsChile/campusDetail/performance-high.svg"
                />
                <img
                  class="favorite-list-icon"
                  src="@/assets/iconsChile/campusDetail/performance-middle.svg"
                />
                <img
                  class="favorite-list-icon"
                  src="@/assets/iconsChile/campusDetail/performance-middle-low.svg"
                />
                <img
                  class="favorite-list-icon"
                  src="@/assets/iconsChile/campusDetail/performance-insufficient.svg"
                />
              </div>
            </div>
            <div class="favorite-list-icon-container">
              <div class="favorite-list-icon-text ml-4">
                {{ $t("favorite.categories.distance") }}
              </div>
              <div class="favorite-list-icon-div mr-8">
                <img
                  class="favorite-list-icon"
                  src="@/assets/iconsChile/campusDetail/distance-person-low.svg"
                />
                <img
                  class="favorite-list-icon"
                  src="@/assets/iconsChile/campusDetail/distance-person-middle.svg"
                />
                <img
                  class="favorite-list-icon"
                  src="@/assets/iconsChile/campusDetail/distance-car-middle-high.svg"
                />
                <img
                  class="favorite-list-icon"
                  src="@/assets/iconsChile/campusDetail/distance-car-high.svg"
                />
              </div>
            </div>
            <div v-if="false" class="favorite-list-icon-container">
              <div class="favorite-list-icon-text ml-4">
                {{ $t("favorite.categories.admision") }}
              </div>
              <div class="favorite-list-icon-div mr-8">
                <img class="favorite-list-icon" src="@/assets/icons/admission-high.svg" />
                <img class="favorite-list-icon" src="@/assets/icons/admission-middle.svg" />
                <img class="favorite-list-icon" src="@/assets/icons/admission-middle-low.svg" />
                <img class="favorite-list-icon" src="@/assets/icons/admission-low.svg" />
              </div>
            </div>
          </div>
          <div v-if="false" class="favorite-list-download-button-container mb-5">
            <div class="favorite-list-download-text">
              {{ $t("favorite.categories.reference-text") }}
            </div>
            <button
              class="favorite-list-download-button"
              v-show="isDownloadable"
              @click="generateReport"
            >
              <img src="@/assets/icons/save_alt.svg" />
              <div>
                {{ $t("favorite.categories.download-btn-text") }}
              </div>
            </button>
          </div>
          <div v-if="loaded">
            <vue-html2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="true"
              :preview-modal="false"
              :paginate-elements-by-height="1200"
              :filename="'Resumen de ' + currentStudent.name"
              :pdf-quality="2"
              :manual-pagination="true"
              pdf-format="a4"
              pdf-orientation="portrait"
              ref="favoritesDownload"
            >
              <section slot="pdf-content">
                <favoritesDownload :ratexIndexes="ratexIds" />
              </section>
            </vue-html2pdf>
          </div>
        </div>
      </v-col>
      <v-col>
        <div class="favorites__loader" v-if="!loaded">
          <v-progress-circular size="50" indeterminate />
        </div>
        <favorite-list
          v-if="loaded"
          :favoriteCampuses="favoriteCampuses"
          :ratexIndexes="ratexIds"
        />
      </v-col>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueHtml2pdf from "vue-html2pdf";
import FavoriteList from "@/components/explorer/favorite/FavoriteList.vue";
import FavoritesDownload from "@/components/explorer/downloadable/summaryCampusFavorites.vue";
import feedbackService from "@/services/feedback.service";

export default {
  name: "Favorites",
  components: {
    VueHtml2pdf,
    FavoriteList,
    FavoritesDownload,
  },
  data() {
    return {
      ratexIds: [],
      loaded: false,
    };
  },
  computed: {
    ...mapGetters({
      currentStudent: "authentication/currentStudent",
      favoriteCampuses: "institutions/favoriteSchools",
      loadingFavorites: "institutions/loadingFavorites",
      legalGuardianUUID: "authentication/legalGuardianUUID",
    }),
    showCols() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    isDownloadable() {
      let respone = false;
      if (this.favoriteCampuses.length !== 0) {
        respone = true;
      }
      return respone;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapActions({}),
    generateReport() {
      this.$refs.favoritesDownload.generatePdf();
      this.$mixpanel.track("favorites_download", {
        distinct_id: this.legalGuardianUUID,
        env: this.environment,
      });
    },
    testingRatex() {
      const { uuid } = this.currentStudent;
      return feedbackService.risk(uuid);
    },
  },
  created() {
    try {
      const arrayratex = [];
      this.testingRatex().then((response) => {
        if (!response.data[0].error) {
          response.data[0].api_response.portfolio.forEach((programa) => {
            arrayratex.push(programa.ratex);
          });
          this.ratexIds = arrayratex;
          this.loaded = true;
          return;
        }
        this.ratexIds = false;
        this.loaded = true;
      });
    } catch (e) {
      this.ratexIds = false;
      this.loaded = true;
    }
  },
};
</script>
