<template>
  <v-row no-gutters class="">
    <v-col v-if="showCols" cols="3" class="mt-10">
      <div class="simulate__text-container">
        <div class="title-container pt-5">
          <p class="text text--large-01 pb-2">{{ sectionTitle }}</p>
        </div>
        <div></div>
        <div class="simulate__show__more">
          <v-expansion-panels
            accordion
            flat
            class="ma-0 py-0 pl-0 fill-width"
            v-model="showMoreSimulateInfo"
          >
            <v-expansion-panel class="ma-0 pa-0 fill-width">
              <v-expansion-panel-header class="fill-width">
                <template v-slot:actions>
                  <span>
                    {{
                      $t(
                        `${
                          showMoreSimulateInfo === 0
                            ? "desktop.simulation.closeShowMore"
                            : "desktop.simulation.showMore"
                        }`
                      )
                    }}
                    &nbsp;
                  </span>
                  <v-icon v-if="showMoreSimulateInfo === 0" color="#F90E1F" size="18">
                    mdi-close-circle
                  </v-icon>
                  <v-icon color="#0E0466" v-else size="18"> mdi-plus-circle </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="p-0">
                <p class="simulate__show__more__text">
                  {{ $t("desktop.simulation.showMoreText") }}
                </p>
                <p class="simulate__show__more__text mt-3">
                  {{ instructionText }}
                </p>
                <p class="simulate__show__more__text mt-3">{{ sectionText }}</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div
          class="simulate__show__more"
          v-if="showMoreSimulateInfo === 0"
          :class="{ 'pb-5': showMoreSimulateInfo === 0 }"
        >
          <v-expansion-panels
            accordion
            flat
            class="ma-0 py-0 pl-0 fill-width"
            v-model="showMoreSimulateInfoSAE"
          >
            <v-expansion-panel class="ma-0 pa-0 fill-width">
              <v-expansion-panel-header class="fill-width">
                <template v-slot:actions>
                  <span>
                    {{
                      $t(
                        `${
                          showMoreSimulateInfoSAE === 0
                            ? "desktop.simulation.closeShowMore"
                            : "desktop.simulation.showMoreSAE"
                        }`
                      )
                    }}
                    &nbsp;
                  </span>
                  <v-icon v-if="showMoreSimulateInfoSAE === 0" color="#F90E1F" size="18">
                    mdi-close-circle
                  </v-icon>
                  <v-icon color="#0E0466" v-else size="18"> mdi-plus-circle </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p class="simulate__show__more__text">
                  {{ $t("desktop.simulation.informationSAEText") }}
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div v-if="showResults">
          <p class="text mt-5">{{ $t("desktop.simulation.explanation3") }}</p>
        </div>
      </div>
    </v-col>
    <v-col :cols="showCols ? 9 : 12">
      <div class="fill-width simulate">
        <div v-if="!showResults" class="fill-width">
          <div class="simulate__header" v-show="this.$vuetify.breakpoint.smAndDown">
            <div class="title-container" style="margin-top: 57px">
              <p class="simulate__header__title">{{ sectionTitle }}</p>
            </div>
            <div class="simulate__header__content">
              {{ sectionText }}
            </div>
            <div class="simulate__show__more">
              <v-expansion-panels
                accordion
                flat
                class="ma-0 py-0 pl-0 fill-width"
                v-model="showMoreSimulateInfo"
              >
                <v-expansion-panel class="ma-0 pa-0 fill-width">
                  <v-expansion-panel-header class="fill-width">
                    <template v-slot:actions>
                      <span>
                        {{
                          $t(
                            `${
                              showMoreSimulateInfo === 0
                                ? "desktop.simulation.closeShowMore"
                                : "desktop.simulation.showMore"
                            }`
                          )
                        }}
                        &nbsp;
                      </span>
                      <v-icon v-if="showMoreSimulateInfo === 0" color="#F90E1F" size="18">
                        mdi-close-circle
                      </v-icon>
                      <v-icon color="#0E0466" v-else size="18"> mdi-plus-circle </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p class="simulate__show__more__text">
                      {{ $t("desktop.simulation.showMoreText") }}
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <div
              class="simulate__show__more"
              v-if="showMoreSimulateInfo === 0"
              :class="{ 'pb-5': showMoreSimulateInfo === 0 }"
            >
              <v-expansion-panels
                accordion
                flat
                class="ma-0 py-0 pl-0 fill-width"
                v-model="showMoreSimulateInfoSAE"
              >
                <v-expansion-panel class="ma-0 pa-0 fill-width">
                  <v-expansion-panel-header class="fill-width">
                    <template v-slot:actions>
                      <span>
                        {{
                          $t(
                            `${
                              showMoreSimulateInfoSAE === 0
                                ? "desktop.simulation.closeShowMore"
                                : "desktop.simulation.showMoreSAE"
                            }`
                          )
                        }}
                        &nbsp;
                      </span>
                      <v-icon v-if="showMoreSimulateInfoSAE === 0" color="#F90E1F" size="18">
                        mdi-close-circle
                      </v-icon>
                      <v-icon color="#0E0466" v-else size="18"> mdi-plus-circle </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p class="simulate__show__more__text">
                      {{ $t("desktop.simulation.informationSAEText") }}
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <div v-if="!showResults">
              <p class="text text--link text--underline clickable mt-5" @click="openLink">
                {{ linkText }}
              </p>
            </div>
            <div v-if="showResults">
              <p class="text mt-5">
                {{ $t("desktop.simulation.explanation3") }}
              </p>
            </div>
            <v-row no-gutters justify="center" class="mt-3">
              <base-button
                v-show="this.$vuetify.breakpoint.mdAndDown"
                class="simulate__header__btn"
                @click="openSimulateInfoPopup"
              >
                {{ $t("simulation.simulate.simulate-button") }}
              </base-button>
            </v-row>
          </div>
          <v-row no-gutters justify="center" class="mt-6 mb-0">
            <p class="simulate__text" v-t="'simulation.simulate.text'" />
          </v-row>
          <v-row no-gutters justify="center" class="mt-1">
            <base-button
              v-show="this.$vuetify.breakpoint.mdAndUp"
              class="simulate__btn"
              @click="openSimulateInfoPopup"
            >
              {{ $t("simulation.simulate.simulate-button") }}
            </base-button>
          </v-row>
          <v-row v-if="!loadingFavorites" no-gutters class="mt-6">
            <div v-if="favoriteSchools.length < 1" class="fill-width ma-5">
              {{ $t("views.schools.favorites.empty-list") }}
            </div>
            <presimulated-school-list :simulated="simulated" :campuses="institutionsList" />
          </v-row>
          <div v-if="loadingFavorites">
            <v-progress-circular size="50" class="mt-10" indeterminate />
          </div>
          <template v-if="institutionsDontSimulate.length > 0 && !loadingFavorites">
            <hr class="simulate__hr" />
            <v-row no-gutters justify="center" class="mt-7 mb-0">
              <p
                class="simulate__text"
                v-t="{
                  path: 'simulation.dont-simulate',
                  args: { name: currentStudent.name },
                }"
              />
            </v-row>
            <v-row no-gutters class="mt-3">
              <presimulated-school-list
                :campuses="institutionsDontSimulate"
                :showRanking="false"
                :showButtons="false"
              />
            </v-row>
          </template>
        </div>
        <div v-else class="fill-width">
          <div class="simulate__header" v-show="this.$vuetify.breakpoint.smAndDown">
            <div class="title-container" style="margin-top: 57px">
              <p class="simulate__header__content__footer__text__title">
                {{ sectionTitle }}
              </p>
            </div>
            <div>
              <p class="simulate__header__content">{{ sectionText }}</p>
            </div>
            <div>
              <p class="simulate__header__content">{{ sectionText2 }}</p>
            </div>
            <div>
              <div class="simulate__header__content__footer__text">
                {{ sectionText3 }}
                <strong>
                  {{ this.$t("simulation.results.detail-btn.show") }}
                </strong>
                {{ sectionText4 }}
              </div>
            </div>
          </div>
          <simulation-results />
        </div>
      </div>
    </v-col>
    <v-dialog
      content-class="about-simulation"
      v-model="simulateInfoCardModal"
      transition="dialog-bottom-transition"
    >
      <about-simulation
        @simulate="onSimulateClick"
        @close="simulateInfoCardModal = false"
        :schools-to-remove="schoolsToRemove"
        :can-simulate="canSimulate"
      />
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/explorer/base/BaseButton.vue";
import PresimulatedSchoolList from "@/components/explorer/simulate/PresimulatedSchoolList.vue";
import SimulationResults from "@/components/explorer/simulate/SimulationResults.vue";
import AboutSimulation from "@/components/explorer/simulate/AboutSimulation.vue";

export default {
  name: "Simulate",
  components: {
    BaseButton,
    PresimulatedSchoolList,
    SimulationResults,
    AboutSimulation,
  },
  data() {
    return {
      showResults: false,
      simulateInfoCardModal: false,
      showMoreSimulateInfo: null,
      showMoreSimulateInfoSAE: null,
    };
  },
  mounted() {
    const { grades } = this.currentStudent;
    const favoriteCampusesList = [];
    this.favoriteSchools.forEach((program) => {
      favoriteCampusesList.push(program.campus);
    });
    this.getFavoriteSchoolsDetails(favoriteCampusesList).then(() => {
      setTimeout(() => {
        this.maploaded = true;
        const institutionsDetails = this.favoriteSchoolsDetails;
        const simulateInstitutions = {
          institutions: institutionsDetails,
          interestedGrade: grades[0],
        };
        this.setInstitutionsList(simulateInstitutions);
      }, 1000);
    });
  },
  computed: {
    ...mapGetters({
      favoriteSchools: "institutions/favoriteSchools",
      favoriteSchoolUUIDs: "institutions/favoriteSchoolUUIDs",
      simulated: "simulation/simulated",
      currentStudent: "authentication/currentStudent",
      showSimulateInfo: "simulation/showSimulateInfo",
      institutionsList: "simulation/institutionsList",
      institutionsDontSimulate: "simulation/institutionsDontSimulate",
      favoriteSchoolsDetails: "institutions/favoriteSchoolsDetails",
      loadingFavorites: "institutions/loadingFavorites",
    }),
    defaultSchool() {
      return this.favoriteSchools[0];
    },
    showCols() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    sectionTitle() {
      return this.showResults
        ? this.$t("desktop.simulation.title2")
        : this.$t("desktop.simulation.title");
    },
    sectionText() {
      const { name } = this.currentStudent;
      return this.showResults
        ? this.$t("desktop.simulation.explanation1", [name])
        : this.$t("desktop.simulation.text");
    },
    sectionText2() {
      const { name } = this.currentStudent;
      return this.showResults
        ? this.$t("desktop.simulation.explanation2", [name])
        : this.$t("desktop.simulation.text");
    },
    sectionText3() {
      const { name } = this.currentStudent;
      return this.showResults
        ? this.$t("simulation.results.explanation3", [name])
        : this.$t("desktop.simulation.text");
    },
    sectionText4() {
      const { name } = this.currentStudent;
      return this.showResults
        ? this.$t("simulation.results.explanation4", [name])
        : this.$t("desktop.simulation.text");
    },

    linkText() {
      return this.$t("desktop.simulation.link-text");
    },
    instructionText() {
      const { name } = this.currentStudent;
      return this.showResults
        ? this.$t("desktop.simulation.explanation2", [name])
        : this.$t("desktop.simulation.instruction");
    },
    canSimulate() {
      // return modal, if institutions equals private
      // return !!this.favoriteSchools.reduce((acc, current) => acc || current.in_sae, false);
      return true;
    },
    schoolsToRemove() {
      return this.favoriteSchools.filter((element) => !element.in_sae);
    },
  },
  methods: {
    ...mapActions({
      simulate: "simulation/simulationSendingInstitutions",
      setInstitutionsList: "simulation/setInstitutionsList",
      getFavoriteSchoolsDetails: "institutions/getFavoriteSchoolsDetails",
    }),
    ...mapGetters({
      legalGuardianUUID: "authentication/legalGuardianUUID",
      environment: "authentication/environment",
    }),
    async onSimulateClick() {
      this.simulateInfoCardModal = false;
      this.$mixpanel.track("click_simulate_button", {
        distinct_id: this.legalGuardianUUID(),
        student_id: this.currentStudent.uuid,
        env: this.environment(),
      });
      if (this.$hj) {
        this.$hj("identify", this.legalGuardianUUID(), {
          click_simulate_button: true,
        });
      }
      if (this.simulated) {
        await this.simulate({
          callback: () => {
            this.showResults = true;
          },
        });
        this.showResults = true;
      } else {
        await this.simulate({
          callback: () => {
            this.showResults = true;
          },
        });
        this.showResults = true;
        // .catch(() => show snackbar );
      }
    },
    openSimulateInfoPopup() {
      this.simulateInfoCardModal = true;
    },
    openLink() {
      window.open(
        "https://www.newhavenmagnetschools.com/index.php/whats-the-process/the-application-process",
        "_blank"
      );
    },
    goBack() {
      this.showResults = false;
    },
  },
};
</script>
