<template>
  <section class="start-explorer">
    <!-- header -->
    <img
      class="top-0 absolute left-0 ml-2"
      src="@/assets/icons/mineduc-white.svg"
      width="130px"
      alt="mineduc"
    />
    <img
      class="top-0 absolute left-0 mt-7 ml-2"
      src="@/assets/icons/mime-white.svg"
      width="100px"
      alt="mime"
    />
    <!-- body -->
    <div class="start-explorer__container">
      <h1 class="start-explorer__title" v-t="'start_explorer.title'" />

      <template v-if="!isSelectedGuestMode">
        <v-btn class="start-explorer__button mt-5" type="button" @click="goToLogin">
          <img class="mr-2" src="@/assets/icons/fingerprint.svg" alt="finger" />
          {{ $t("start_explorer.explorer_with_user") }}
        </v-btn>

        <v-btn
          class="start-explorer__button mt-2"
          type="button"
          @click="isSelectedGuestMode = true"
        >
          <img class="mr-2" src="@/assets/icons/select_guest.svg" alt="guest" />
          {{ $t("start_explorer.explorer_guest") }}
        </v-btn>
      </template>

      <template v-else>
        <v-autocomplete
          class="start-explorer__autocomplete mt-5"
          hide-details
          :items="municipalities"
          v-model="municipality"
          item-text="name"
          return-object
          height="31"
          color="white"
          dark
          :label="$t('start_explorer.select_commune')"
          :no-data-text="$t('errors.no-data')"
          :menu-props="{ bottom: true, offsetY: true }"
        >
        </v-autocomplete>
        <!-- Select current location -->
        <v-btn class="start-explorer__button mt-5" type="button" @click="getCurrentLocation">
          <img class="mr-2" src="@/assets/icons/my_location.svg" alt="location" />
          {{ $t("start_explorer.current_location") }}
        </v-btn>
        <p
          v-if="errorGetCurrentLocation"
          class="start-explorer__error"
          v-t="'start_explorer.error_current_location'"
        />
      </template>
    </div>
    <!-- footer -->
    <img
      class="bottom-0 absolute left-0 mb-7 ml-1"
      src="@/assets/png/consiliumbots-white.png"
      width="150px"
      alt="ConsiliumBots"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "StartExplorer",
  data() {
    return {
      municipality: null,
      isSelectedGuestMode: false,
      errorGetCurrentLocation: false,
      userLocation: {},
    };
  },

  watch: {
    municipality(value) {
      // eslint-disable-next-line camelcase
      const { default_coordinate, name } = value;
      this.userLocation = {
        coordinate: {
          lat: default_coordinate.lat,
          lng: default_coordinate.lon,
        },
        municipalityName: name,
      };
      this.sendLocation(this.userLocation);
    },
  },
  computed: {
    ...mapGetters({
      municipalities: "options/municipalities",
      guestGrades: "authentication/guestGrades",
      guestLevel: "authentication/guestLevel",
      guestStage: "authentication/guestStage",
      gradeOptions: "options/grades",
      cornerBounds: "institutions/cornerBounds",
    }),
  },
  methods: {
    ...mapActions({
      setExitsInfoGuest: "authentication/setExitsInfoGuest",
      setGuestData: "authentication/setGuestData",
      retrieveCampuses: "institutions/retrieveCampuses",
    }),
    goToLogin() {
      this.$router.push({ name: "Login" });
    },
    async getCurrentLocation() {
      await this.$getLocation({})
        .then((coordinates) => {
          const { lat, lng } = coordinates;
          this.userLocation = {
            coordinate: { lat, lng },
            municipalityName: "Santiago",
          };
          this.errorGetCurrentLocation = false;
        })
        .catch((error) => {
          this.errorGetCurrentLocation = true;
          console.error(error);
        });

      this.sendLocation(this.userLocation);
    },
    async sendLocation(location) {
      let level = this.gradeOptions.find((grade) => grade.id === this.guestGrades[0]).grade_name;
      if (this.guestGrades.length > 1) {
        level += ` + (${this.guestGrades.length - 1})`;
      }

      await this.setGuestData({
        location,
        grades: this.guestGrades,
        stage: this.guestStage,
        level,
      });
      // this.retrieveCampuses({ callback: null, bounds: this.cornerBounds });
      // console.log('entry');
      this.setExitsInfoGuest({ exitsInfoGuest: true });
    },
  },
};
</script>
