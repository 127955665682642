<template>
  <div class="fill-width mt-5">
    <div
      class="simulation-result__section pb-5"
      :class="{
        'simulation-result__section--desktop ': !mobile,
        'ml-1': mobile,
      }"
    >
      <v-row no-gutters :justify="mobile ? 'center' : 'start'" :class="{ 'fill-width': !mobile }">
        <div
          :class="{ 'simulation-result__simulate-btn--desktop': !mobile }"
          class="simulation-result__simulate-title text--light"
        >
          {{ $t("simulation.results.btn") }} {{ simulation.year }}
        </div>
      </v-row>
      <div
        :class="{
          'd-inline-flex align-start justify-space-between': !mobile,
          'pr-10': !mobile,
        }"
      >
        <div
          :class="{
            'simulation-result__chart-section--desktop pa-3 pt-4': !mobile,
          }"
        >
          <simulation-chart
            v-if="simulation && simulation.portfolio"
            :sortedChartData="sortedData"
            :unmatched="simulation.unmatched"
            :colors="colors"
          />
        </div>
        <div
          class="simulation-result__probability mt-2 d-flex flex-row px-10"
          :class="{
            'simulation-result__probability--low': formatRatex(1 - simulation.unmatched) < 30,
            'simulation-result__probability--desktop': !mobile,
          }"
          v-if="Object.keys(simulation).includes('unmatched')"
        >
          <v-icon
            v-if="formatRatex(1 - simulation.unmatched) < 30"
            color="white"
            size="15"
            class="mb-2"
          >
            mdi-alert
          </v-icon>
          <p
            class="text text--small-01 text-center d-inline"
            style="max-width: 200px"
            :class="{
              'text--white': formatRatex(1 - simulation.unmatched) < 30,
            }"
            v-html="getProbability(simulation.year, simulation.unmatched)"
          />
        </div>
        <div v-else class="pb-1">
          <p class="text text--small-01 text--center">
            {{ $t("simulation.results.no-data") }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="fill-width mb-10"
      :class="{
        'desktop-expansion-panel': !mobile,
        'ml-1 mobile-expansion-panel': mobile,
      }"
      v-if="Object.keys(simulation).includes('unmatched')"
    >
      <v-expansion-panels accordion flat class="fill-width" v-model="showingDetail">
        <v-expansion-panel class="fill-width">
          <v-expansion-panel-header
            class="fill-width anual-header bg-gray"
            :class="{ 'custom-border': showingDetail }"
            @click="trackingSimulateInfo"
          >
            <template v-slot:actions>
              <div class="fill-width d-flex align-center pb-3">
                <p
                  v-if="showingDetail === 0"
                  class="text--light text--white"
                  :class="{ 'text--small-01': mobile }"
                >
                  {{ $t("simulation.results.detail-btn.hide") }} &nbsp;
                </p>
                <p v-else class="text--light text--white" :class="{ 'text--small-01': mobile }">
                  {{ $t("simulation.results.detail-btn.show") }} &nbsp;
                </p>
                <img
                  v-if="showingDetail !== 0"
                  src="@/assets/iconsChile/more.svg"
                  class="school-card__info__icon"
                />
                <img
                  v-else
                  width="20"
                  class="campus-detail__section__header__div__icon__close"
                  src="@/assets/icons/close.svg"
                />
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            :class="{ 'pa-6 pl-10': !mobile }"
            :style="`width: ${mobile ? '90' : '100'}%; background-color: #fbf9f9`"
          >
            <simulation-detail
              v-for="element in sortedData"
              :key="element.programUUID"
              :year="simulation.year"
              :detailData="element"
              :datacollection="getDataCollection(element.rank)"
              class="mb-3"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div v-else class="pb-4">
      <hr />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SimulationChart from "./SimulationChart.vue";
import SimulationDetail from "./SimulationDetail.vue";

export default {
  name: "AnualSimulationResults",
  components: {
    SimulationChart,
    SimulationDetail,
  },
  props: {
    simulation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showingDetail: [0],
      colors: ["#3684EF", "#8CC2EE", "#80E6BA", "#EB3C46", "#9280B5", "#FFFFFF"],
    };
  },
  computed: {
    ...mapGetters({
      currentStudent: "authentication/currentStudent",
    }),
    sortedData() {
      // const { simulation } = this;
      // console.log(simulation)
      const sortRanking = [...this.simulation.portfolio.slice(0)];
      //   // eslint-disable-next-line prefer-arrow-callback
      const sortData = sortRanking.sort(
        (a, b) => a.campus.program_ranking - b.campus.program_ranking
      );
      return sortData;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    ...mapGetters({
      legalGuardianUUID: "authentication/legalGuardianUUID",
      environment: "authentication/environment",
    }),
    formatRatex(ratex) {
      return Math.floor(ratex * 100);
    },
    getDataCollection(rank) {
      const data = this.sortedData.map((element) => element.ratex);
      const backgroundColor = this.sortedData.map((element, index) => {
        if (element.rank === rank) {
          return index < 4 ? this.colors[index] : this.colors[4];
        }
        return "#F2F2F2";
      });

      data.push(this.simulation.unmatched);
      backgroundColor.push("#FFFFFF");

      const dataset = {
        data,
        backgroundColor,
        borderWidth: 0,
      };
      return { datasets: [dataset] };
    },
    getColor(index) {
      return index < 4 ? this.colors[index] : this.colors[4];
    },
    getProbability(year, unmatched) {
      const prob = this.formatRatex(1 - unmatched);
      return `${this.$t("simulation.results.probability")} ${year} <b>${prob}%</b>`;
    },
    trackingSimulateInfo() {
      const d = new Date();
      const year = d.getFullYear();
      if (this.simulation.year === year - 1 && this.showingDetail !== 0) {
        this.$mixpanel.track(`click_${year - 1}_simulate_info`, {
          distinct_id: this.legalGuardianUUID(),
          student_id: this.currentStudent.uuid,
          env: this.environment(),
        });
      } else if (this.simulation.year === year - 2 && this.showingDetail !== 0) {
        this.$mixpanel.track(`click_${year - 2}_simulate_info`, {
          distinct_id: this.legalGuardianUUID(),
          student_id: this.currentStudent.uuid,
          env: this.environment(),
        });
      }
    },
  },
};
</script>
