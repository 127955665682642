<template>
  <div class="distance-range mb-3">
    <div
      class="d-inline-flex fill-width align-center justify-content-center mt-2"
      v-for="(radius, index) in radiusList"
      :key="`radio-button-${index}`"
    >
      <input
        class="mr-2"
        type="radio"
        v-model="indexSelected"
        :id="index"
        :name="index"
        :value="index"
      />
      <img class="distance-range__icon" :src="iconSelection(index)" />
      <span class="ml-2" v-if="index === 0">{{
        $t("map.filter.distance-from") + radius + "Km"
      }}</span>
      <span class="ml-2" v-else>{{ $t("map.filter.distance-until") + radius + "Km" }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "DistanceRange",
  props: {
    value: {
      type: Object,
      required: true,
    },
    radiusList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      indexSelected: 0,
    };
  },
  methods: {
    changeIsSelected() {
      this.isSelected = !this.isSelected;
    },
    iconSelection(index) {
      let imgFile = "";
      if (index === 0) {
        imgFile = "distance-person-low.svg";
      } else if (index === 1) {
        imgFile = "distance-person-middle.svg";
      } else if (index === 2) {
        imgFile = "distance-car-middle-high.svg";
      } else if (index === 3) {
        imgFile = "distance-car-high.svg";
      } else {
        imgFile = "distance-car-high.svg";
      }
      // eslint-disable-next-line
      const images = require("@/assets/iconsChile/campusDetail/" + imgFile);
      return images;
    },
  },
  computed: {
    payload() {
      return {
        radiusSelected: this.radiusList[this.indexSelected],
      };
    },
  },
  watch: {
    payload: {
      deep: true,
      immediate: true,
      handler(newPayload) {
        this.$emit("input", { ...this.value, ...newPayload });
      },
    },
  },
};
</script>
