var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"feedback-map-info"},[_c('div',{staticClass:"feedback-map-info__content"},[_c('div',[_c('h1',{directives:[{name:"t",rawName:"v-t",value:('feedback.map_info.title1'),expression:"'feedback.map_info.title1'"}],staticClass:"feedback-map-info__content__title",staticStyle:{"width":"148px"}}),_c('h1',{directives:[{name:"t",rawName:"v-t",value:(
          _vm.admission.admission_status.id === 1
            ? 'feedback.map_info.title2'
            : 'feedback.map_info.title3'
        ),expression:"\n          admission.admission_status.id === 1\n            ? 'feedback.map_info.title2'\n            : 'feedback.map_info.title3'\n        "}],staticClass:"feedback-map-info__content__title",staticStyle:{"width":"180px"}})])]),_c('div',{staticClass:"feedback-map-info__content__info"},[(_vm.admission.admission_status.id === 1)?_c('div',{staticClass:"feedback-map-info__content__info__div mb-4"},[_c('div',{staticClass:"feedback-map-info__content__info__div__circle feedback-map-info__content__info__div__circle--assigned"}),_c('p',{directives:[{name:"t",rawName:"v-t",value:('feedback.map_info.assigned'),expression:"'feedback.map_info.assigned'"}],staticClass:"feedback-map-info__content__info__div__text"})]):_vm._e(),_c('div',{staticClass:"feedback-map-info__content__info__div mb-4"},[_c('div',{staticClass:"feedback-map-info__content__info__div__circle feedback-map-info__content__info__div__circle--not-assigned"}),_c('p',{directives:[{name:"t",rawName:"v-t",value:(
          _vm.admission.admission_status.id === 1
            ? 'feedback.map_info.not-assigned'
            : 'feedback.map_info.application'
        ),expression:"\n          admission.admission_status.id === 1\n            ? 'feedback.map_info.not-assigned'\n            : 'feedback.map_info.application'\n        "}],staticClass:"feedback-map-info__content__info__div__text"})]),(_vm.admission.admission_status.id !== 1)?_c('div',{staticClass:"feedback-map-info__content__info__div mb-4"},[_c('div',{staticClass:"feedback-map-info__content__info__div__circle feedback-map-info__content__info__div__circle--recommendation"}),_c('p',{directives:[{name:"t",rawName:"v-t",value:('feedback.map_info.recommendation'),expression:"'feedback.map_info.recommendation'"}],staticClass:"feedback-map-info__content__info__div__text"})]):_vm._e(),(_vm.admission.admission_status.id !== 1)?_c('div',{staticClass:"feedback-map-info__content__info__div"},[_c('div',{staticClass:"feedback-map-info__content__info__div__circle feedback-map-info__content__info__div__circle--selected"}),_c('p',{directives:[{name:"t",rawName:"v-t",value:('feedback.map_info.selected'),expression:"'feedback.map_info.selected'"}],staticClass:"feedback-map-info__content__info__div__text"})]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }