<template>
  <div>
    <div v-if="loadingSchoolCard" class="skeleton-school-card">
      <v-skeleton-loader
        class="pt-2"
        height="244"
        v-bind="attrs"
        type="list-item-avatar, divider, image"
      ></v-skeleton-loader>
    </div>
    <div
      v-if="!loadingSchoolCard"
      class="school-card"
      :class="isGuest ? 'school-card--guest' : ''"
    >
      <div class="school-card__main">
        <div class="school-card__main__text-container" @click="explore(false)">
          <p class="text school-card__main__name">
            {{ campusNameShort(campus.campus_name, 25) }}
          </p>
          <div class="school-card__main__label text-left">
            {{ getSector }}
            <span class="">|</span>
            {{ getGrades }}
          </div>
          <div
            v-if="hasVacancies()"
            class="school-card__available d-flex align-center justify-start"
          >
            <img
              class="mr-2"
              width="10"
              height="10"
              src="@/assets/iconsChile/filter/white-face.svg"
            />
            {{ this.$t("campus-details.vacancies.with-vacancies") }}
          </div>
          <!-- <div v-if="isInFavorites"> -->
          <!-- <p class="school-card__main__label">
            {{ this.calculateLabelProgram(this.returnProgram()) }}
          </p> -->
          <!-- </div>
        <div v-else>
          <p class="school-card__main__label">
            {{ campus.grades_info.grade_min }} -
            {{ campus.grades_info.grade_max }}
          </p>
        </div> -->
        </div>
        <div class="school-card__main__image-container">
          <v-avatar size="107" tile class="school-card__main__image">
            <v-img
              style="border-top-right-radius: 5px"
              :src="
                campus.image_thumb.length > 0
                  ? campus.image_thumb[0].image_link
                  : require('@/assets/icons/school.svg')
              "
            >
            </v-img>
            <!--
          <v-img
            style="border-top-right-radius: 5px"
            :src="require('@/assets/login-photo-1.jpg')"
            >
          </v-img>
          -->
          </v-avatar>
          <div class="school-card__main__fav">
            <!-- HARDCODE CAMPUS CODE NOT SHOWING 2 CASES -->
            <favorite-button
              :isFavorite="campus.isFavorite"
              class="school-card__main__fav__btn"
              float
              opaque
              @click-favorite="onFavoriteClicked()"
            />
          </div>
          <div
            :class="`school-card__main__priorities ${
              isGuest ? 'school-card__guest' : ''
            }`"
            v-if="false"
          >
            <div
              v-if="
                studentHasPriorities && getStudentPriorities.has_sae_priority
              "
              class="school-card__main__priorities__priority"
            >
              <v-icon size="17"> $prioritySEP </v-icon>
            </div>
            <div
              v-if="
                studentHasPriorities &&
                getStudentPriorities.has_sibling_priority
              "
              class="school-card__main__priorities__priority"
            >
              <v-icon size="17"> $prioritySibling </v-icon>
            </div>
            <div
              v-if="getOtherPriorities.length > 0"
              class="school-card__main__priorities__priority"
            >
              <v-icon size="17"> $priorityOther </v-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="school-card__info">
        <div class="school-card__info__container" v-if="loadingLite">
          <v-progress-circular indeterminate />
        </div>
        <div class="school-card__info__container d-flex" v-if="!loadingLite">
          <div
            class="
              school-card__info__container school-card__info__container--c1
            "
            :class="{ 'school-card__info__container--no-info': false }"
          >
            <div>
              <v-tooltip
                v-model="showTooltipPrice"
                class=""
                bottom
                content-class="custom-tooltip"
              >
                <template v-slot:activator="{ on, attrs }">
                  <button
                    type="button"
                    v-bind="attrs"
                    v-on="on"
                    @click="showTooltipPrice = !showTooltipPrice"
                    class="school-card__info__container__title w-100"
                  >
                    {{ $t("campus-details.price") }}
                  </button>
                </template>
                <span>{{ $t("campus-details.tooltip.price") }}</span>
              </v-tooltip>

              <div
                class="
                  school-card__info__container__inner_div
                  school-card__info__container__inner_div--v3
                "
                :class="campusPriceBackgroundColor(campus.payment.default)"
              >
                <img
                  v-show="campus.payment.default !== 'Sin Información'"
                  :src="iconPriceSelection(campus.payment.default)"
                  class="school-card__info__icon"
                  :class="classPriceSelection(campus.payment.default)"
                />
                <p
                  v-if="campus.payment.default !== 'Sin Información'"
                  class="school-card__info__container__text"
                  :class="isGuest ? '' : ''"
                >
                  {{ campus.payment.default }}
                </p>
                <p v-else class="school-card__info__container__text">
                  {{ $t("map.card.no-info") }}
                </p>
              </div>
            </div>
          </div>

          <div class="school-card__info__container">
            <div>
              <v-tooltip
                v-model="showTooltipPerformance"
                class=""
                bottom
                content-class="custom-tooltip"
              >
                <template v-slot:activator="{ on, attrs }">
                  <button
                    type="button"
                    v-bind="attrs"
                    v-on="on"
                    @click="showTooltipPerformance = !showTooltipPerformance"
                    class="school-card__info__container__title w-100"
                  >
                    {{ $t("campus-details.performance") }}
                  </button>
                </template>
                <span>{{ $t("campus-details.tooltip.performance") }}</span>
              </v-tooltip>

              <div
                class="
                  school-card__info__container__inner_div
                  school-card__info__container__inner_div--v2
                "
                :class="
                  campusPerformanceBackgroundColor(getPerformanceCategory)
                "
              >
                <img
                  v-if="getPerformanceCategory.value !== -1"
                  :src="iconPerformanceSelection(getPerformanceCategory)"
                  class="school-card__info__icon"
                  :class="isGuest ? '' : ''"
                />
                <div
                  class="school-card__info__container__text"
                  :class="{
                    'school-card__info__container__text--no-info':
                      getPerformanceCategory.value === -1,
                  }"
                >
                  <span>
                    {{ getPerformanceName(getPerformanceCategory) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              school-card__info__container school-card__info__container--c3
            "
            :class="{ 'school-card__info__container--no-info': !distance }"
          >
            <div>
              <v-tooltip
                v-model="showTooltipDistance"
                class=""
                bottom
                content-class="custom-tooltip"
              >
                <template v-slot:activator="{ on, attrs }">
                  <button
                    type="button"
                    v-bind="attrs"
                    v-on="on"
                    @click="showTooltipDistance = !showTooltipDistance"
                    class="school-card__info__container__title w-100"
                  >
                    {{ $t("campus-details.distance-km") }}
                  </button>
                </template>
                <span>{{ $t("campus-details.tooltip.distance-km") }}</span>
              </v-tooltip>

              <div
                class="
                  school-card__info__container__inner_div
                  school-card__info__container__inner_div--v3
                "
                :class="campusCarDistanceBackgroundColor(distance)"
              >
                <img
                  :src="iconDistanceSelection(distance)"
                  class="
                    school-card__info__icon school-card__info__icon--distance
                  "
                />
                <div>
                  <p class="school-card__info__container__text">
                    {{ distance }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            class="
              school-card__info__container school-card__info__container--c4
              mr-2
            "
            :class="{ 'school-card__info__container--no-info': true }"
          >
            <div>
              <button
                class="
                  school-card__info__container__title
                  school-card__info__container__title--v2
                  w-100
                "
              >
                {{ $t("campus-details.admision") }}
                <!-- <template v-if="getIsSAE">
                  {{ $t("campus-details.text-sae") }}
                </template> -->
              </button>

              <div
                class="
                  school-card__info__container__inner_div
                  school-card__info__container__inner_div--v2
                "
                v-if="isInFavorites"
                :style="campusRatexBackgroundColor(ratex)"
              >
                <img
                  v-show="hasRatex()"
                  :src="iconRatexSelection(ratex)"
                  class="school-card__info__icon"
                />
                <p
                  v-if="hasRatex()"
                  class="school-card__info__container__text"
                  :class="[
                    { 'school-card__info__container--no-info': true },
                    isGuest ? 'school-card__info__container__text--guest' : '',
                  ]"
                >
                  {{ getRatexConfidence(ratex) }}
                </p>
                <p
                  v-else
                  class="
                    school-card__info__container__text
                    school-card__info__container__text--no-info
                  "
                  :class="[
                    { 'school-card__info__container--no-info': true },
                    isGuest ? 'school-card__info__container__text--guest' : '',
                  ]"
                >
                  <span v-if="getIsSAE">
                    {{ $t("campus-details.admision").toUpperCase() }}
                    {{ $t("campus-details.text-sae").toUpperCase() }}
                  </span>
                  <span v-else>
                    {{ $t("campus-details.no-ratex").toUpperCase() }}
                  </span>
                </p>
              </div>
              <div
                class="
                  school-card__info__container__inner_div
                  school-card__info__container__inner_div--v2
                "
                :style="campusRatexBackgroundColor(reratex)"
                v-else
              >
                <div v-if="ratexLoader && !isGuest">
                  <v-progress-circular indeterminate />
                </div>
                <div
                  class="d-flex align-center justify-center flex-column"
                  v-if="!ratexLoader || isGuest"
                >
                  <img
                    v-show="hasRatex()"
                    :src="iconRatexSelection(reratex)"
                    class="school-card__info__icon"
                  />
                  <p
                    v-if="hasRatex() && !isGuest"
                    class="
                      school-card__info__container__text
                      school-card__info__container__text--ratex
                    "
                    :class="[
                      isGuest
                        ? 'school-card__info__container__text--guest'
                        : '',
                    ]"
                  >
                    {{ getRatexConfidence(this.reratex) }}
                  </p>
                  <!-- <div v-if="hasRatex()" class="school-card__info__year__widget">
                  {{ getLastYear }}
                </div> -->
                  <p
                    v-if="isGuest || !hasRatex()"
                    class="
                      school-card__info__container__text
                      school-card__info__container__text--no-info
                    "
                    :class="[{ 'school-card__info__container--no-info': true }]"
                  >
                    <span v-if="getIsSAE">
                      {{ $t("campus-details.admision").toUpperCase() }}
                      {{ $t("campus-details.text-sae").toUpperCase() }}
                    </span>
                    <span v-else>
                      {{ $t("campus-details.no-ratex").toUpperCase() }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isGuest" class="school-card__guest__popup">
        <img
          class="mr-2"
          src="@/assets/icons/exclamation-circle.svg"
          width="25px"
        />
        <p class="school-card__guest__popup__text">
          <button
            @click="toLogin"
            type="button"
            v-t="'campus-details.card-popup-guest.login'"
            class="mr-1"
          />
          {{ $t("campus-details.card-popup-guest.text") }}
        </p>
      </div>
      <div
        @click="explore(true)"
        class="school-card__show__profile"
        :class="{
          'school-card__show__profile--blur': !campus.grades_info.grade_min,
        }"
      >
        <div class="school-card__profile__container">
          <div class="school-card__show__profile__text mr-1">
            {{ $t("map.card.show-profile") }}
          </div>
          <div class="school-card__show__profile__icon">
            <img
              v-show="campus.multimedia_options.has_tour"
              :src="require('@/assets/iconsChile/campusDetail/360-tour.svg')"
              class="school-card__info__icon mr-1"
            />
            <div
              class="school-card__show__profile__line"
              v-show="
                campus.multimedia_options.has_tour &&
                campus.multimedia_options.has_drone_flight
              "
            >
              |
            </div>
            <img
              v-show="campus.multimedia_options.has_drone_flight"
              :src="require('@/assets/iconsChile/campusDetail/drone.svg')"
              class="school-card__info__icon ml-1"
              width="28"
            />
          </div>
        </div>
      </div>
      <v-dialog
        content-class="elevation-0"
        overlay-color="transparent"
        v-model="showLoginDialog"
        max-width="400"
      >
        <WarninigModal
          @close="closeWarningModal"
          :hasFavorites="thereAreFavorites"
          :inSchoolCard="true"
          @go-register="signUp"
        />
      </v-dialog>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import FavoriteButton from "@/components/explorer/favorite/FavoriteButton.vue";
import utils from "@/utils/";
import geoLocatorService from "@/services/geoLocator.service";
import feedbackService from "@/services/feedback.service";
import simulationService from "@/services/simulation.services";
import WarninigModal from "../guest/WarninigModal.vue";

export default {
  name: "SchoolCard",
  components: {
    FavoriteButton,
    WarninigModal,
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  props: {
    program: {
      type: [Number, String],
      default: "",
    },
    campus: {
      type: Object,
      required: true,
    },
    isInFavorites: {
      type: Boolean,
      default: false,
    },
    ratex: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dataWalk: "N/A",
      dataBus: "N/A",
      dataCar: "N/A",
      reratex: null,
      isOpen: false,
      src: "",
      range1Color: "#11D3D9 !important",
      range2Color: "#0071BC !important",
      range3Color: "#4139B4 !important",
      range4Color: "#6D3165 !important",
      range5Color: "#bdbdbd !important",
      showTooltipPrice: false,
      showTooltipPerformance: false,
      showTooltipDistance: false,
      showTooltipAdmision: false,
      ratexLoader: false,
      showLoginDialog: false,
      attrs: {
        class: "mb-6",
        boilerplate: false,
        elevation: 2,
      },
    };
  },
  created() {
    window.addEventListener("beforeunload", () => {
      this.$emit("close");
    });
  },
  computed: {
    ...mapGetters({
      legalGuardianUUID: "authentication/legalGuardianUUID",
      environment: "authentication/environment",
      currentStudent: "authentication/currentStudent",
      isGuest: "authentication/isGuest",
      homeLocation: "authentication/homeLocation",
      campusDetail: "institutions/campusDetails",
      favoriteSchools: "institutions/favoriteSchools",
      guestLevel: "authentication/guestLevel",
      guestStage: "authentication/guestStage",
      grades: "options/grades",
      gradeLabels: "options/gradeLabels",
      gradeTracks: "options/gradeTracks",
      gradeTrackLabels: "options/gradeTrackLabels",
      schools: "institutions/schools",
      loadingLite: "institutions/loadingLite",
      loadingSchoolCard: "institutions/loadingSchoolCard",
      guestGrades: "authentication/guestGrades",
    }),
    getIsSAE() {
      // id = 1 SAE, id = 2 Independiente
      if (this.campus.campus_admission_system[0].admission_system.id === 1) {
        return true;
      }
      return false;
    },
    getGrades() {
      const indexMinGrade = this.campus.grades_info.grade_min.indexOf("G");
      const indexMaxGrade = this.campus.grades_info.grade_max.indexOf("G");
      let formatMaxGrade = "";
      let formatMinGrade = "";

      if (indexMaxGrade !== -1) {
        formatMaxGrade = this.campus.grades_info.grade_max.slice(
          0,
          indexMaxGrade - 1
        );
      } else {
        formatMaxGrade = this.campus.grades_info.grade_max;
      }

      if (indexMinGrade !== -1) {
        formatMinGrade = this.campus.grades_info.grade_min.slice(
          0,
          indexMaxGrade - 1
        );
      } else {
        formatMinGrade = this.campus.grades_info.grade_min;
      }

      return `${formatMinGrade.split(" ")[0]} - ${formatMaxGrade}`;
    },
    studentHasPriorities() {
      return this.campus.student_priorities !== undefined;
    },

    thereAreFavorites() {
      return this.favoriteSchools.length > 0;
    },
    getLastYear() {
      let date = Date.now();
      date = new Date(date);
      // eslint-disable-next-line
      return date.getFullYear() - 1;
    },
    getSector() {
      const { sector } = this.campus;
      if (sector === null) {
        return "";
      }
      if (sector.sector_name === null || sector.sector_name === undefined) {
        return "";
      }
      return sector.sector_name;
    },
    getStudentPriorities() {
      return this.campus.student_priorities;
    },
    getOtherPriorities() {
      const unusedKeys = ["has_sae_priority", "has_sibling_priority"];
      const prioritiesAsArray = Object.entries(this.campus.student_priorities);
      const priorities = prioritiesAsArray.filter(([, value]) => value);
      const keys = priorities.map((prior) => prior[0]);
      for (let i = 0; i < unusedKeys.length; i += 1) {
        const indexToDelete = keys.indexOf(unusedKeys[i]);
        if (indexToDelete > -1) {
          keys.splice(indexToDelete, 1);
        }
      }
      return keys
        .map((key) => this.$t(`campus-details.priorities.${key}`))
        .join(", ");
    },
    getRatexText() {
      if (!this.isGuest) {
        if (this.campus.ratex) {
          return this.$t(`map.card.ratex.${this.campus.ratex.toString()}`);
        }
        if (this.campus.type === "Particular Pagado") {
          return this.$t("map.card.own-admission");
        }
        return this.$t("map.card.no-info");
      }
      if (this.isGuest) {
        if (this.campus.type === "Particular Pagado") {
          return this.$t("map.card.own-admission");
        }
        return this.$t("map.card.sae-admision");
      }
      return this.$t("map.card.no-info");
    },

    distance() {
      const campusLocation = {
        lat: this.campus.location.lat,
        lng: this.campus.location.lon,
      };
      let homeLocation;
      if (this.homeLocation) {
        homeLocation = this.homeLocation;
      }
      if (campusLocation && homeLocation) {
        const distance = utils.getDistanceInKm(campusLocation, homeLocation);
        return `${distance} km`;
      }
      return null;
    },
    getBasicPerformanceCategory() {
      const performance = this.campus.performance_set.find(
        (performanceSet) =>
          performanceSet.qualitycategory_label.stage_label === 2
      );
      if (performance !== undefined) {
        return performance.qualitycategory_level_label.id;
      }
      return { value: -1, title: this.$t("map.card.no-info") };
    },
    getMiddlePerformanceCategory() {
      const performance = this.campus.performance_set.find(
        (performanceSet) =>
          performanceSet.qualitycategory_label.stage_label === 3
      );
      if (performance !== undefined) {
        return performance.qualitycategory_level_label.id;
      }
      return { value: -1, title: this.$t("map.card.no-info") };
    },
    getPerformanceCategory() {
      if (!this.isGuest && this.currentStudent.stages[0] !== 3) {
        return this.getBasicPerformanceCategory;
        // eslint-disable-next-line
      } else if (!this.isGuest && this.currentStudent.stages[0] === 3) {
        return this.getMiddlePerformanceCategory;
      }
      if (this.isGuest) {
        if (this.guestStage !== 3) {
          return this.getBasicPerformanceCategory.value !== -1
            ? this.getBasicPerformanceCategory
            : this.getMiddlePerformanceCategory;
          // eslint-disable-next-line
        } else if (this.guestStage === 3) {
          return this.getMiddlePerformanceCategory.value !== -1
            ? this.getMiddlePerformanceCategory
            : this.getBasicPerformanceCategory;
        }
        return { value: -1, title: this.$t("map.card.no-info") };
      }
      return { value: -1, title: this.$t("map.card.no-info") };
    },
  },
  methods: {
    ...mapActions({
      openChooseProgramModal: "institutions/openChooseProgramModal",
      removeFavoriteByUUID: "institutions/removeFavoriteByUUID",
      removeFavoriteByCampusUUID: "institutions/removeFavoriteByCampusUUID",
      openCampusDetail: "institutions/openCampusDetail",
      exploreCampus: "institutions/exploreCampus",
      retrieveCampusDetails: "institutions/retrieveCampusDetails",
      setShowCampusDetail: "institutions/setShowCampusDetail",
      addMultipleFavoriteByUUID: "institutions/addMultipleFavoriteByUUID",
      errorSnackbar: "snackbar/error",
      infoSnackbar: "snackbar/info",
      success: "snackbar/success",
      setLoadingLite: "institutions/setLoadingLite",
      getFavoriteSchoolsDetails: "institutions/getFavoriteSchoolsDetails",
      updateCampusDetailFavorite: "institutions/updateCampusDetailFavorite",
      setUpdateCampusDetails: "institutions/setUpdateCampusDetails",
    }),
    toLogin() {
      this.$router.push({ name: "Login" });
    },
    hasVacancies() {
      let vacancies = false;
      this.campus.programs.forEach((program) => {
        if (program._calculated_vacancies > 0) {
          vacancies = true;
        }
      });
      return vacancies;
    },
    closeWarningModal() {
      this.showLoginDialog = false;
    },
    signUp() {
      this.$emit("go-register");
    },
    hasRatex() {
      if (!this.isInFavorites) {
        if (this.reratex !== null) {
          return true;
        }
        return false;
      }
      if (this.ratex !== null) {
        return true;
      }
      return false;
    },
    iconPriceSelection(campusPrice) {
      let imgFile = "";
      // if (this.campus.features.sep && this.campus.student_priorities.has_sae_priority) {
      //  imgFile = 'price-free.svg';
      // } else
      if (campusPrice === "Gratuito") {
        imgFile = "price-free.svg";
      } else if (
        campusPrice === "$1.000 a $10.000" ||
        campusPrice === "$1.000 a $50.000" ||
        campusPrice === "$25.001 a $50.000"
      ) {
        imgFile = "price-10-50.svg";
      } else if (
        campusPrice === "10.001 a $100.000" ||
        campusPrice === "$50.001 a $100.000"
      ) {
        imgFile = "price-50-100.svg";
      } else if (campusPrice === "Más De $100.000") {
        imgFile = "price-100.svg";
      } else {
        imgFile = "price-100.svg";
      }
      // eslint-disable-next-line
      const images = require("@/assets/iconsChile/campusDetail/" + imgFile);
      return images;
    },
    classPriceSelection(campusPrice) {
      let classPrice = "school-card__info__icon";
      // if (this.campus.features.sep && this.campus.student_priorities.has_sae_priority) {
      //  classPrice = 'school-card__info__icon--free';
      // } else
      if (campusPrice === "Más de $100.000") {
        classPrice = "school-card__info__icon--middle-price";
      } else if (
        campusPrice === "$50.001 a $100.000" ||
        campusPrice === "$1.000 a $50.000"
      ) {
        classPrice = "school-card__info__icon--middle-price";
      } else if (campusPrice === "Gratuito") {
        classPrice = "school-card__info__icon--free";
      }
      return classPrice;
    },
    iconPerformanceSelection(campusPerformance) {
      let imgFile = "";
      if (campusPerformance === 4) {
        imgFile = "performance-high.svg";
      } else if (campusPerformance === 3) {
        imgFile = "performance-middle.svg";
      } else if (campusPerformance === 2) {
        imgFile = "performance-middle-low.svg";
      } else if (campusPerformance === 1) {
        imgFile = "performance-insufficient.svg";
      } else {
        imgFile = null;
      }
      if (imgFile) {
        // eslint-disable-next-line
        const images = require("@/assets/iconsChile/campusDetail/" + imgFile);
        return images;
      }
    },
    iconDistanceSelection(distance) {
      let finalDistance = distance.split(" ");
      finalDistance = parseFloat(finalDistance[0]);
      let imgFile = "";
      if (finalDistance <= 1) {
        imgFile = "distance-person-low.svg";
      } else if (finalDistance <= 2) {
        imgFile = "distance-person-middle.svg";
      } else if (finalDistance <= 5) {
        imgFile = "distance-car-middle-high.svg";
      } else if (finalDistance <= 20) {
        imgFile = "distance-car-high.svg";
      } else {
        imgFile = "distance-car-high.svg";
      }
      // eslint-disable-next-line
      const images = require("@/assets/iconsChile/campusDetail/" + imgFile);
      return images;
    },
    campusPriceBackgroundColor(campusPrice) {
      let classPrice = "school-card__info__container__inner_div--low";
      /**
      if (this.campus.features.sep && this.campus.student_priorities.has_sae_priority) {
        classPrice =  'school-card__info__container__inner_div--low';
      } else
      * */ if (campusPrice === "Gratuito") {
        classPrice = "school-card__info__container__inner_div--low";
      } else if (
        campusPrice === "$1.000 a $10.000" ||
        campusPrice === "$1.000 a $50.000" ||
        campusPrice === "$25.001 a $50.000"
      ) {
        classPrice = "school-card__info__container__inner_div--mid";
      } else if (
        campusPrice === "10.001 a $100.000" ||
        campusPrice === "$50.001 a $100.000"
      ) {
        classPrice = "school-card__info__container__inner_div--midlow";
      } else if (campusPrice === "Más de $100.000") {
        classPrice = "school-card__info__container__inner_div--high";
      } else {
        classPrice = "school-card__info__container__inner_div--high";
      }

      return classPrice;
    },
    campusPerformanceBackgroundColor(campusPerformance) {
      if (!campusPerformance) {
        classPrice = "school-card__info__container__inner_div--low";
        return classPrice;
      }

      const performance = campusPerformance;

      let classPrice = "school-card__info__container__inner_div--low";
      if (performance === 4) {
        classPrice = "school-card__info__container__inner_div--low";
      } else if (performance === 3) {
        classPrice = "school-card__info__container__inner_div--mid";
      } else if (performance === 2) {
        classPrice = "school-card__info__container__inner_div--midlow";
      } else if (performance === 1) {
        classPrice = "school-card__info__container__inner_div--high";
      } else {
        classPrice = "";
      }

      return classPrice;
    },
    campusCarDistanceBackgroundColor(distance) {
      let classPrice = "school-card__info__container__inner_div--low";

      let finalDistance = distance.split(" ");
      finalDistance = parseFloat(finalDistance[0]);
      if (finalDistance <= 1) {
        classPrice = "school-card__info__container__inner_div--low";
      } else if (finalDistance <= 2) {
        classPrice = "school-card__info__container__inner_div--mid";
      } else if (finalDistance <= 5) {
        classPrice = "school-card__info__container__inner_div--midlow";
      } else if (finalDistance > 5) {
        classPrice = "school-card__info__container__inner_div--high";
      } else {
        classPrice = "school-card__info__container__inner_div--high";
      }
      return classPrice;
    },
    campusRatexBackgroundColor(campusRatex) {
      let color = "";
      if (campusRatex > 0.75) {
        color = this.range1Color;
      } else if (campusRatex > 0.5 && campusRatex < 0.75) {
        color = this.range2Color;
      } else if (campusRatex > 0.25 && campusRatex < 0.5) {
        color = this.range3Color;
      } else if (campusRatex < 0.25 && campusRatex !== null) {
        color = this.range4Color;
      } else if (campusRatex === null) {
        color = this.range5Color;
      }
      const style = {
        "background-color": color,
      };
      return style;
    },
    testingRatex() {
      const { uuid } = this.currentStudent;
      return feedbackService.risk(uuid);
    },
    retrieveTravelTimes(campusCode, campuslat, campuslon, lat, lon) {
      return geoLocatorService.retrieveTimes(
        campusCode,
        campuslat,
        campuslon,
        lat,
        lon
      );
    },
    getProgramLabel() {
      if (this.currentStudent.current_enrollment === null) {
        return `${this.gradeLabels[this.currentStudent.grades[0] - 1]}`;
      }
      return `${
        this.gradeLabels[this.currentStudent.current_enrollment.grade_label - 1]
      }`;
    },
    retrieveRatexByGrade(campusCode, studentId, grade) {
      const payload = {
        campuses: [campusCode],
        interest_grade: grade,
        applicant_id: studentId,
      };
      return simulationService
        .simulationSendingInstitutions(payload)
        .then((response) => response);
    },
    campusNameShort(s, limit) {
      if (s.length > limit) {
        return s.substring(0, limit).concat("...");
      }
      return s;
    },
    getRatexPercent(rtx) {
      const percent = rtx * 100;
      return `${(Math.round(percent * 100) / 100).toFixed(2)}`;
    },
    getRatexConfidence(rtx) {
      const percent = rtx * 100;

      if (percent < 10) {
        return this.$t("map.card.less-than-10");
      }
      if (percent > 90) {
        return this.$t("map.card.greater-than-90");
      }
      return `${(Math.round(percent * 100) / 100).toFixed(0)}%`;
    },
    // Deprecated program propr without data
    returnProgram() {
      const programId = this.program;
      let toreturn = null;
      this.campus.program_campus.forEach((programa) => {
        if (programId === programa.id) {
          toreturn = programa;
        }
      });
      return toreturn;
    },
    returnProgramEnrolledStudent() {
      let toreturn = null;
      this.campus.program_campus.forEach((programa) => {
        if (
          this.currentStudent.currentEnrollment ===
          programa.gradetrack.gradetrack_name
        ) {
          toreturn = programa;
        }
      });
      return toreturn;
    },
    calculateLabelProgram(programa) {
      return `${programa.gradetrack.gradetrack_name}, ${programa.gender.gender_name}, ${programa.shift_label.shift_name}, ${programa.gradetrack.specialty_label.specialty_name}`;
    },
    onFavoriteClicked() {
      if (this.isGuest) {
        this.showLoginDialog = true;
        return;
      }
      if (this.campus.isFavorite) {
        const programsIds = [];
        let favoriteProgram;
        if (this.campus.programs.length > 0) {
          this.campus.programs.forEach((program) => {
            programsIds.push(program.id);
          });
          this.favoriteSchools.forEach((school) => {
            programsIds.forEach((id) => {
              if (id === school.program) {
                favoriteProgram = school.id;
              }
            });
          });
        }
        this.removeFavoriteByUUID({ programId: favoriteProgram })
          .then(() => {
            // this.infoSnackbar(this.$t('favorites.removed'));
            this.$mixpanel.track("remove_favorite_school_from_map", {
              distinct_id: this.legalGuardianUUID,
              student_id: this.currentStudent.uuid,
              school_id: this.campus.uuid,
              env: this.environment,
            });
            setTimeout(() => {
              this.updateCampusDetailFavorite(false);
            }, 500);
          })
          .then(() => {
            if (this.isInFavorites) {
              const updatedPrograms = [];
              this.favoriteSchools.forEach((program) => {
                if (favoriteProgram !== program.id) {
                  updatedPrograms.push(program.campus);
                }
              });
              this.getFavoriteSchoolsDetails(updatedPrograms);
            }
          });
      } else if (this.searchGrade().isUnique) {
        this.addMultipleFavoriteByUUID({
          programsUUIDs: [this.searchGrade().uuid],
        })
          .then(() => {
            // this.success(this.$t('favorites.added'));
            this.$mixpanel.track("favorite_school_from_map", {
              distinct_id: this.legalGuardianUUID,
              student_id: this.currentStudent.uuid,
              school_id: this.campus.uuid,
              env: this.environment,
            });
            setTimeout(() => {
              this.updateCampusDetailFavorite(true);
            }, 500);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.openChooseProgramModal({
          programsOptions: this.searchGrade().totalDegrees,
        });
      }
    },
    searchGrade() {
      const { grades } = this.currentStudent;
      const { programs } = this.campus;
      const totalDegrees = programs.filter(
        (program) => program.grade.id === grades[0]
      );
      return {
        isUnique: totalDegrees.length === 1,
        uuid: totalDegrees[0].uuid,
        totalDegrees,
      };
    },
    snackbarText() {
      // eslint-disable-next-line
      const infoText = `Has agregado ${this.campus.name}, ${this.campus.program_options[0].grade}, ${this.campus.program_options[0].gender}, ${this.campus.program_options[0].shift}`;
      return infoText;
    },
    async explore(openBtn) {
      if (this.isInFavorites) {
        this.retrieveCampusDetails({ campusUuid: this.campus.uuid });
      }
      this.$mixpanel.identify(this.legalGuardianUUID, {
        open_school_profile: true,
      });
      if (openBtn) {
        this.$mixpanel.track("open_school_profile2", {
          distinct_id: this.legalGuardianUUID,
          student_id: this.currentStudent.uuid,
          school_id: this.campus.uuid,
          env: this.environment,
        });
      } else {
        this.$mixpanel.track("open_school_profile", {
          distinct_id: this.legalGuardianUUID,
          student_id: this.currentStudent.uuid,
          school_id: this.campus.uuid,
          env: this.environment,
        });
      }
      this.setShowCampusDetail(this.campus.uuid);
      this.exploreCampus({ campus: this.campus });
    },
    yearDivAlign(campusRatex) {
      let ratexClass = "school-card__info__icon__year";
      if (campusRatex === 3) {
        ratexClass = "school-card__info__icon__year--middle-low";
      } else if (campusRatex === 2) {
        ratexClass = "school-card__info__icon__year--middle";
      } else if (campusRatex === 4) {
        ratexClass = "school-card__info__icon__year--low";
      }
      if (this.isInFavorites) {
        // eslint-disable-next-line
        ratexClass = ratexClass + "--fav";
      }
      return ratexClass;
    },
    iconRatexSelection(campusRatex) {
      let imgFile = "";
      if (campusRatex > 0.75) {
        imgFile = "admission-high.svg";
      } else if (campusRatex > 0.5 && campusRatex < 0.75) {
        imgFile = "admission-middle.svg";
      } else if (campusRatex > 0.25 && campusRatex < 0.5) {
        imgFile = "admission-middle-low.svg";
      } else if (campusRatex < 0.25) {
        imgFile = "admission-low.svg";
      } else {
        imgFile = "admission-low.svg";
      }
      // eslint-disable-next-line
      const images = require("@/assets/icons/" + imgFile);
      return images;
    },
    getPerformanceName(perfomanceValue) {
      let performanceName = "";
      if (perfomanceValue === 4) {
        performanceName = "Alto";
      } else if (perfomanceValue === 3) {
        performanceName = "Medio";
      } else if (perfomanceValue === 2) {
        performanceName = "Medio-Bajo";
      } else if (perfomanceValue === 1) {
        performanceName = "Bajo";
      } else {
        performanceName = "SIN INFORMACIÓN";
      }
      return performanceName;
    },
  },
  watch: {
    loadingSchoolCard: {
      async handler(newVal) {
        if (newVal === false) {
          this.ratexLoader = true;
          const { lat } = this.homeLocation;
          const lon = this.homeLocation.lng;
          const campuslat = this.campus.location.lat;
          const campuslon = this.campus.location.lon;

          if (!this.isInFavorites && !this.isGuest) {
            let studentGrade;
            if (this.currentStudent.grades.length >= 0) {
              // eslint-disable-next-line prefer-destructuring
              studentGrade = this.currentStudent.grades[0];
            } else {
              studentGrade = this.currentStudent.current_enrollment.grade_label;
            }
            try {
              await this.retrieveRatexByGrade(
                this.campus.campus_code,
                this.currentStudent.id,
                studentGrade
              ).then((response) => {
                this.ratexLoader = false;
                if (!response.data[0].error) {
                  if (response.data[0].api_response.portfolio.length !== 0) {
                    this.reratex =
                      response.data[0].api_response.portfolio[0].ratex;
                    this.setUpdateCampusDetails(
                      response.data[0].api_response.portfolio[0]
                    );
                  }
                }
              });
            } catch (e) {
              this.ratexLoader = false;
              console.error(e);
            }
          }

          try {
            this.retrieveTravelTimes(
              this.campus.campus_code,
              campuslat,
              campuslon,
              lat,
              lon
            ).then((response) => {
              if (response.data.walkDuration) {
                this.dataWalk = response.data.walkDuration;
              }
              if (response.data.driveDuration) {
                this.dataCar = response.data.driveDuration;
              }
              if (response.data.busDuration) {
                this.dataBus = response.data.busDuration;
              }
            });
          } catch (e) {
            console.error(e);
          }
        }
      },
    },
  },
};
</script>
