<template>
  <div class="public-institution-info_container">
    <div class="public-institution-info_title">
      {{ $t("register.priority.public_institution_info.title") }}
    </div>
    <v-row
      v-for="(sibling, i) in siblings"
      :key="i"
      class="ma-0 pa-3"
      no-gutters
      :style="i % 2 === 0 ? 'background: #FFF9F7;' : 'background: #FFFFFF;'"
    >
      <v-col
        v-if="sibling.identification_number === ''"
        cols="8"
        class="public-institution-info_siblings-name"
      >
        {{ sibling.first_name }} {{ sibling.first_lastname }} {{ sibling.other_lastname }}
      </v-col>
      <v-col v-else cols="8" class="public-institution-info_siblings-id">
        {{ sibling.identification_number }}
      </v-col>
      <v-col cols="4" @click="remove(i)" class="public-institution-info_siblings-delete">
        {{ $t("register.priority.public_institution_info.remove_btn") }}
      </v-col>
    </v-row>
    <div class="public-institution-info_question_container">
      <p class="required-field public-institution-info_question">
        {{ $t("register.priority.public_institution_info.questions.institution") }}
      </p>
    </div>
    <v-autocomplete
      class="institution_input"
      :style="mobile ? 'width: 250px;' : ''"
      :background-color="institution !== null ? '#2D10B4' : '#FFFFFF'"
      :dark="institution !== null ? true : false"
      :items="schoolList"
      v-model="institution"
      item-text="institution_name"
      item-value="id"
      hide-details
      :no-data-text="$t('views.schools.no-data-text')"
    />
    <v-container class="nationality_container" :style="mobile ? 'flex-direction: column;' : ''">
      <div class="nationality_question_container">
        <div class="required-field nationality_question">
          {{ $t("register.priority.public_institution_info.questions.nationality") }}
        </div>
        <v-select
          class="custom-select custom-select nationality_input"
          :background-color="nationality !== null ? '#2D10B4' : '#FFFFFF'"
          :dark="nationality !== null ? true : false"
          outlined
          :items="nationalities"
          item-text="nationality_name"
          item-value="id"
          hide-details
          v-model="nationality"
        />
      </div>
      <div class="document_question_container" :style="mobile ? 'padding-top: 10px;' : ''">
        <div class="required-field document_question">
          {{ $t("register.priority.public_institution_info.questions.document_type") }}
        </div>
        <v-select
          class="custom-select custom-select document_input"
          :background-color="typeOfDocument !== null ? '#2D10B4' : '#FFFFFF'"
          :dark="typeOfDocument !== null ? true : false"
          :items="typeOfDocuments"
          outlined
          item-text="identification_type_name"
          item-value="id"
          hide-details
          v-model="typeOfDocument"
        />
      </div>
    </v-container>
    <div
      v-if="typeOfDocument && documentId !== 10 && nationality !== null && institution"
      class="ndocument_question_container"
    >
      <div class="ndocument_question_title_container">
        <p class="required-field ndocument_question_title">
          {{ $t("register.priority.public_institution_info.questions.n_document") }}
        </p>
      </div>
      <div
        class="ndocument_question_subtitle"
        v-if="
          documentId === 1 ||
          documentId === 2 ||
          documentId === 3 ||
          documentId === 4 ||
          documentId === 5 ||
          documentId === 6
        "
      >
        {{ $t("register.priority.public_institution_info.questions.n_document_info") }}
      </div>
      <input
        v-model="nDocument"
        type="text"
        placeholder="1 2 3 4 5 6 7 8 9 0"
        class="register__content__input__div__input ndocument_question"
        :class="{ 'register__content__input__div__input--selected': nDocument.length > 0 }"
        @input="$v.nDocument.$touch()"
        @blur="$v.nDocument.$touch()"
        @keydown="
          documentId === 1 ||
          documentId === 2 ||
          documentId === 3 ||
          documentId === 5 ||
          documentId === 6
            ? isNumber
            : ''
        "
        v-mask="
          documentId === 1
            ? '##########'
            : documentId === 2
            ? '###########'
            : documentId === 3
            ? '##########'
            : documentId === 5
            ? '###############'
            : documentId === 6
            ? '#######'
            : ''
        "
      />
      <div class="ndocument_question-errors" v-for="(error, i) in nDocumentErrors" :key="i">
        {{ error }}
      </div>
    </div>
    <v-container
      v-show="typeOfDocument && documentId === 10 && nationality !== null && institution"
      class="names_questions_container"
      :style="mobile ? 'flex-direction: column;' : ''"
    >
      <div class="first-name_container">
        <div class="first-name_title required-field">
          {{ $t("register.priority.public_institution_info.questions.first_name") }}
        </div>
        <input
          v-model="firstName"
          type="text"
          class="register__content__input__div__input first-name_input"
          :class="{ 'register__content__input__div__input--selected': firstName.length > 0 }"
        />
      </div>
      <div class="second-name_container" :style="mobile ? 'padding-top: 10px;' : ''">
        <div class="second-name_title">
          {{ $t("register.priority.public_institution_info.questions.second_name") }}
        </div>
        <input
          v-model="secondName"
          type="text"
          class="register__content__input__div__input second-name_input"
          :class="{ 'register__content__input__div__input--selected': secondName.length > 0 }"
        />
      </div>
    </v-container>
    <v-container
      v-show="typeOfDocument && documentId === 10 && nationality !== null && institution"
      class="lastnames_questions_container"
      :style="mobile ? 'flex-direction: column;' : ''"
    >
      <div class="first-lastname_container">
        <div class="first-lastname_title required-field">
          {{ $t("register.priority.public_institution_info.questions.father_lastname") }}
        </div>
        <input
          v-model="fatherLastName"
          type="text"
          class="register__content__input__div__input first-lastname_input"
          :class="{ 'register__content__input__div__input--selected': fatherLastName.length > 0 }"
        />
      </div>
      <div class="second-lastname_container" :style="mobile ? 'padding-top: 10px;' : ''">
        <div class="second-lastname_title required-field">
          {{ $t("register.priority.public_institution_info.questions.mother_lastname") }}
        </div>
        <input
          v-model="motherLastName"
          type="text"
          class="register__content__input__div__input second-lastname_input"
          :class="{ 'register__content__input__div__input--selected': motherLastName.length > 0 }"
        />
      </div>
    </v-container>
    <div v-show="typeOfDocument && nationality !== null && institution">
      <div class="question-opt_title_container">
        <p class="required-field question-opt_title">
          {{ $t("register.priority.public_institution_info.questions.add_sibling") }}
        </p>
      </div>
      <v-container class="question-opt_container">
        <div
          :class="mobile ? 'question-opt-mobile' : 'question-opt'"
          :style="
            opt
              ? 'background: #2D10B4; margin-right: 5px;'
              : 'background: white; margin-right: 5px;'
          "
          @click="changeOpt(true)"
        >
          <p :style="opt ? 'color: white;' : 'color: #2D10B4;'">
            {{ $t("register.priority.public_institution_info.questions.yes_opt") }}
          </p>
        </div>
        <div
          :class="mobile ? 'question-opt-mobile' : 'question-opt'"
          :style="
            opt === false
              ? 'background: #2D10B4; margin-left: 5px;'
              : 'background: white; margin-left: 5px;'
          "
          @click="changeOpt(false)"
        >
          <p :style="opt === false ? 'color: white;' : 'color: #2D10B4;'">
            {{ $t("register.priority.public_institution_info.questions.no_opt") }}
          </p>
        </div>
      </v-container>
    </div>
    <div class="btn_container">
      <v-btn icon @click="backMethod">
        <v-img src="@/assets/iconsPalmira/student_register/icons/back.svg" />
      </v-btn>
      <base-button v-if="isDisabled" class="btn-skip" @click="skipStep">
        {{ $t("register.priority.public_institution_info.skip_btn") }}
      </base-button>
      <base-button v-else class="btn-continue" @click="continueMethod">
        {{ $t("register.priority.public_institution_info.continue_btn") }}
      </base-button>
    </div>
    <v-dialog
      content-class="elevation-0"
      overlay-color="transparent"
      v-model="showSkipModal"
      max-width="370"
    >
      <warning-modal
        @skip="skipConfirmMethod"
        @complete="completeMethod"
        :title="$t('register.priority.public_institution_info.warning_modal.title')"
        :text="$t('register.priority.public_institution_info.warning_modal.text')"
        color="#FC010A"
        skipModal
      />
    </v-dialog>
  </div>
</template>

<script>
import { maxLength, minLength, requiredIf } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/explorer/base/BaseButton.vue";
import WarningModal from "../WarningModal.vue";

export default {
  name: "PublicInstitutionInfo",
  components: {
    BaseButton,
    WarningModal,
  },
  data() {
    return {
      firstName: "",
      secondName: "",
      fatherLastName: "",
      motherLastName: "",
      institution: null,
      typeOfDocument: null,
      nationality: null,
      nDocument: "",
      showSkipModal: false,
      opt: null,
      siblingsExistsPreviously: false,
    };
  },
  mounted() {
    this.siblingsExistsPreviously = this.siblings.length > 0;
    this.institutionsInclude();
  },
  validations: {
    nDocument: {
      required: requiredIf(function useNDocument() {
        return this.documentId !== 10;
      }),
      minLength(nDocument) {
        if (this.documentId === 1) {
          return minLength(10)(nDocument);
        }
        if (this.documentId === 2) {
          return minLength(10)(nDocument);
        }
        if (this.documentId === 5) {
          return minLength(15)(nDocument);
        }
        if (this.documentId === 6) {
          return minLength(7)(nDocument);
        }
        return true;
      },
      maxLength(nDocument) {
        if (this.documentId === 1) {
          return maxLength(10)(nDocument);
        }
        if (this.documentId === 2) {
          return maxLength(11)(nDocument);
        }
        if (this.documentId === 3) {
          return maxLength(10)(nDocument);
        }
        if (this.documentId === 5) {
          return maxLength(15)(nDocument);
        }
        if (this.documentId === 6) {
          return maxLength(7)(nDocument);
        }
        return true;
      },
    },
  },
  methods: {
    ...mapActions({
      setNewSibling: "newStudentRegister/setNewSibling",
      removeSibling: "newStudentRegister/removeSibling",
      saveSiblings: "newStudentRegister/setPriorityStepTwo",
      institutionsInclude: "institutions/retrieveInstitutionsWithIncluded",
    }),
    resetBasicInfo() {
      this.firstName = "";
      this.secondName = "";
      this.fatherLastName = "";
      this.motherLastName = "";
      this.nDocument = "";
      this.opt = null;
    },
    setDefault() {
      this.firstName = "";
      this.secondName = "";
      this.fatherLastName = "";
      this.motherLastName = "";
      this.institution = null;
      this.typeOfDocument = null;
      this.nationality = null;
      this.nDocument = "";
      this.showSkipModal = false;
      this.opt = null;
    },
    // eslint-disable-next-line consistent-return
    isNumber(evt) {
      const evtAux = evt || window.event;
      const charCode = evtAux.which ? evtAux.which : evtAux.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evtAux.preventDefault();
      } else {
        return true;
      }
    },
    continueMethod() {
      if (!this.uncompleteInfo) {
        const newSibling = {
          institution_id: this.institution,
          nationality_id: this.nationality,
          identification_type_id: this.document.id,
          first_name: this.firstName,
          other_name: this.secondName,
          first_lastname: this.fatherLastName,
          other_lastname: this.motherLastName,
          identification_number: this.nDocument,
        };
        this.setNewSibling(newSibling);
      }
      if (this.opt) {
        this.setDefault();
      } else {
        this.saveSiblings({ callback: () => this.$emit("nextStep") });
      }
    },
    backMethod() {
      this.$emit("backStep");
    },
    schoolName(school) {
      return `${school.name}`;
    },
    skipStep() {
      this.showSkipModal = true;
    },
    skipConfirmMethod() {
      this.$emit("skipStep");
    },
    completeMethod() {
      this.showSkipModal = false;
    },
    changeOpt(value) {
      this.opt = value;
    },
    remove(index) {
      this.removeSibling(index);
    },
  },
  computed: {
    ...mapGetters({
      types: "options/documentTypes",
      siblings: "newStudentRegister/siblings",
      schoolList: "institutions/institutionList",
      nationalities: "options/nationalities",
    }),
    uncompleteInfo() {
      return (
        !this.institution ||
        !this.typeOfDocument ||
        this.nationality === null ||
        this.opt === null ||
        (this.typeOfDocument !== 10 &&
          (this.nDocument === "" || (this.nDocument !== "" && this.nDocumentErrors.length > 0))) ||
        (this.typeOfDocument === 10 &&
          (this.firstName === "" || this.fatherLastName === "" || this.motherLastName === ""))
      );
    },
    isDisabled() {
      return !(this.siblingsExistsPreviously || this.siblings.length > 0) && this.uncompleteInfo;
    },
    typeOfDocuments() {
      if (this.nationality === null) {
        return [];
      }
      const documents = this.types[this.nationality];
      return documents;
    },
    nDocumentErrors() {
      const errors = [];
      if (!this.$v.nDocument.$dirty) {
        return errors;
      }
      if (!this.$v.nDocument.required) {
        errors.push(this.$t("register.priority.public_institution_info.errors.required"));
      }
      if (!this.$v.nDocument.minLength || !this.$v.nDocument.maxLength) {
        errors.push(this.$t("register.priority.public_institution_info.errors.length"));
      }
      return errors;
    },
    document() {
      if (this.typeOfDocument) {
        return this.typeOfDocuments.find((elem) => elem.id === this.typeOfDocument);
      }
      return null;
    },
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
    documentId() {
      if (this.document) {
        return this.document.id;
      }
      return -1;
    },
  },
  watch: {
    nationality: {
      handler() {
        this.resetBasicInfo();
        this.typeOfDocument = null;
      },
    },
    typeOfDocument: {
      handler() {
        this.resetBasicInfo();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-input__slot {
  border-radius: 0px;
  height: 50px !important;
}
::v-deep .v-select__slot input {
  padding-left: 10px;
}
</style>
