<template>
  <v-row no-gutters class="performance-level d-flex my-3" align="center" justify="start">
    <div
      v-ripple:ripple="{ center: true }"
      style="cursor: pointer"
      class="mr-2 d-flex align-center justify-center filter-checkbox"
      @click="changeIsSelected()"
    >
      <h1 v-if="isSelected">•</h1>
    </div>
    <img class="performance-level__icon" :src="iconSelection(index)" />
    <span class="pl-2">
      {{ tag }}
    </span>
  </v-row>
</template>

<script>
export default {
  name: "PerformanceLevel",
  props: {
    tag: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isSelected: false,
    };
  },
  methods: {
    changeIsSelected() {
      this.isSelected = !this.isSelected;
    },
    iconSelection(index) {
      let imgFile = "";
      if (index === 0) {
        imgFile = "performance-high.svg";
      } else if (index === 1) {
        imgFile = "performance-middle.svg";
      } else if (index === 2) {
        imgFile = "performance-middle-low.svg";
      } else if (index === 3) {
        imgFile = "performance-insufficient.svg";
      } else {
        imgFile = "performance-insufficient.svg";
      }
      // eslint-disable-next-line
      const images = require("@/assets/iconsChile/campusDetail/" + imgFile);
      return images;
    },
  },
  computed: {
    payload() {
      return { isSelected: this.isSelected };
    },
  },
  watch: {
    payload: {
      deep: true,
      immediate: true,
      handler(newPayload) {
        this.$emit("input", { ...this.value, ...newPayload });
      },
    },
  },
};
</script>
