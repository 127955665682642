<template>
  <div style="z-index: 2; height: 100%; min-height: 100%">
    <DFMModal :currentModal="currentModal" @setModal="setModal" />
    <!-- module -->
    <div style="position: relative; max-height: 100%; min-height: 100%; height: 100%">
      <img
        src="@/assets/dashboard/icons/dfm-side.svg"
        style="position: absolute; right: -10px; top: -32px; width: 40%; max-width: 150px"
      />
      <img
        src="@/assets/dashboard/dfm-app.png"
        style="
          position: absolute;
          left: 0px;
          top: 0px;
          width: 50%;
          object-fit: cover;
          max-height: 303px;
          object-position: 0% 0%;
        "
      />
    </div>
    <div
      @click="setModal('information')"
      style="
        padding: 10px 15px 10px 15px;
        border-radius: 10px;
        background-color: #2b5bff;
        color: white;
        position: absolute;
        bottom: 30px;
        right: 0px;
        cursor: pointer;
      "
    >
      Conoce <b>DFM</b>
    </div>
    <!-- end module -->
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import CenteredVideo from "./modals/utils/CenteredVideo.vue";
import MeetingOrganizer from "./modals/utils/MeetingOrganizer.vue";
import DFMModal from "./modals/DFMModal.vue";

export default {
  name: "ModuleDFM",
  data() {
    return {
      currentModal: null,
    };
  },
  components: {
    CenteredVideo,
    MeetingOrganizer,
    DFMModal,
  },
  methods: {
    setModal(modal) {
      this.currentModal = modal;
    },

    onPlaying() {
      if (this.videoLoaded == false) {
        this.videoLoaded = true;
      }
    },
  },
  computed: {
    showModal() {
      return this.currentModal != null;
    },
  },
};
</script>
<style>
.dfm-text {
  font-family: Roboto;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #0d22a8;
}
</style>
