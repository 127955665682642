<template>
  <v-container
    class="priority-container"
    :style="stepAux === 7 ? 'padding: 0px !important;' : mobile ? 'padding: 25px !important;' : ''"
  >
    <div v-if="stepAux !== 7" class="priority__profile_title">
      {{ $t("register.priority.title") }}
    </div>
    <div v-if="stepAux !== 7" class="priority__flow_chart-container">
      <flow-chart :currentStep="3" :subStep="totalStepsComplete" />
    </div>
    <public-institution
      v-if="stepAux === 1"
      @nextStep="continueStep"
      @skipStep="skipStep"
      @backStep="backStep"
    />
    <public-institution-info
      v-if="showPublicInstitutionInfoStep"
      @nextStep="continueStep"
      @skipStep="skipStep"
      @backStep="backStep"
    />
    <cdi-or-icbf
      v-if="stepAux === 3 && priorityDetected !== null"
      :priorityDetected="priorityDetected"
      @nextStep="continueStep"
      @skipStep="skipStep"
      @backStep="backStep"
    />
    <victim-of-armed-conflict
      v-if="stepAux === 4"
      @nextStep="continueStep"
      @skipStep="skipStep"
      @backStep="backStep"
    />
    <disability
      v-if="stepAux === 5"
      @nextStep="continueStep"
      @skipStep="skipStep"
      @backStep="backStep"
    />
    <disability-file
      v-if="showDisabilityFileStep"
      @nextStep="continueStep"
      @skipStep="skipStep"
      @backStep="backStep"
    />
    <explain-view
      v-if="stepAux === 7"
      @nextStep="continueStep"
      @skipStep="skipStep"
      @backStep="backStep"
      :btnMsg="$t('register.priority.explain_view.btn_msg')"
      :title="$t('register.priority.explain_view.title')"
      :subtitle="$t('register.priority.explain_view.subtitle')"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import services from "@/services";
import PublicInstitution from "./priority/PublicInstitution.vue";
import PublicInstitutionInfo from "./priority/PublicInstitutionInfo.vue";
import CdiOrIcbf from "./priority/CdiOrIcbf.vue";
import VictimOfArmedConflict from "./priority/VictimOfArmedConflict.vue";
import Disability from "./priority/Disability.vue";
import DisabilityFile from "./priority/DisabilityFile.vue";
import FlowChart from "./FlowChart.vue";
import ExplainView from "./ExplainView.vue";

export default {
  name: "Priority",
  components: {
    PublicInstitution,
    PublicInstitutionInfo,
    CdiOrIcbf,
    VictimOfArmedConflict,
    Disability,
    DisabilityFile,
    FlowChart,
    ExplainView,
  },
  data() {
    return {
      stepAux: 1,
      showPublicInstitutionInfoStep: false,
      showDisabilityFileStep: false,
      lastCompleteStep: 1,
      totalStepsComplete: 0,
      skip: false,
      priorityDetected: null,
    };
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
  },
  async mounted() {
    this.stepAux = this.step;
    const index = 2;
    this.lastCompleteStep = this.currentSubSteps[index];
    this.totalStepsComplete = this.totalSubStepsComplete[index];
    if (this.step === 2) {
      this.showPublicInstitutionInfoStep = true;
    }
    if (this.step === 6) {
      this.showDisabilityFileStep = true;
    }
    if (this.nDocument !== "") {
      await services.studentRegistrationService
        .patchStep12Pre(this.nDocument)
        .then(() => {
          this.priorityDetected = true;
        })
        .catch(() => {
          this.priorityDetected = false;
        });
    } else {
      this.priorityDetected = false;
    }
  },
  methods: {
    ...mapActions({
      setCurrentSubSteps: "newStudentRegister/setCurrentSubSteps",
      setTotalSubStepsComplete: "newStudentRegister/setTotalSubStepsComplete",
    }),
    nextStep() {
      this.showPublicInstitutionInfoStep = false;
      this.showDisabilityFileStep = false;
      if (this.stepAux < 7) {
        this.stepAux += 1;
        if (this.stepAux === 2) {
          if (this.publicInstitutionPriority) {
            this.showPublicInstitutionInfoStep = true;
          } else {
            this.stepAux += 1;
          }
        }
        if (this.stepAux === 6) {
          if (this.disabilityPriority) {
            this.showDisabilityFileStep = true;
          } else {
            this.stepAux += 1;
          }
        }
        this.setCurrentSubSteps({ step: 3, subStep: this.lastCompleteStep });
        this.setTotalSubStepsComplete({ step: 3, subStep: this.totalStepsComplete });
      } else {
        this.setCurrentSubSteps({ step: 3, subStep: this.lastCompleteStep });
        this.setTotalSubStepsComplete({ step: 3, subStep: this.totalStepsComplete });
        this.$emit("nextRegisterStep");
      }
    },
    continueStep() {
      this.totalStepsComplete += 1;
      if (this.stepAux === 1) {
        if (!this.publicInstitutionPriority) {
          this.totalStepsComplete += 1;
        }
      }
      if (this.stepAux === 5) {
        if (!this.disabilityPriority) {
          this.totalStepsComplete += 1;
        }
      }
      if (!this.skip) {
        this.lastCompleteStep = this.stepAux;
      }
      if (this.skip && this.lastCompleteStep === this.stepAux) {
        this.skip = false;
      }
      this.nextStep();
    },
    skipStep() {
      if (!this.skip) {
        this.lastCompleteStep = this.stepAux;
        this.skip = true;
      }
      this.nextStep();
    },
    backStep() {
      this.totalStepsComplete -= 1;
      this.showPublicInstitutionInfoStep = false;
      this.showDisabilityFileStep = false;
      if (this.stepAux > 1) {
        this.stepAux -= 1;
        if (this.stepAux === 2) {
          if (this.publicInstitutionPriority) {
            this.showPublicInstitutionInfoStep = true;
          } else {
            this.stepAux -= 1;
            this.totalStepsComplete -= 1;
          }
        }
        if (this.stepAux === 6) {
          if (this.disabilityPriority) {
            this.showDisabilityFileStep = true;
          } else {
            this.stepAux -= 1;
            this.totalStepsComplete -= 1;
          }
        }
        this.setCurrentSubSteps({ step: 3, subStep: this.lastCompleteStep });
      } else {
        this.$emit("backRegisterStep");
      }
    },
  },
  computed: {
    ...mapGetters({
      publicInstitutionPriority: "newStudentRegister/publicInstitutionPriority",
      disabilityPriority: "newStudentRegister/disabilityPriority",
      currentSubSteps: "newStudentRegister/currentSubSteps",
      totalSubStepsComplete: "newStudentRegister/totalSubStepsComplete",
      nDocument: "newStudentRegister/nDocument",
    }),
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
  },
};
</script>
