<template>
  <div class="map-filter">
    <div class="map-filter__div">
      <button
        type="button"
        v-if="location && location !== 'no-info'"
        class="map-filter__div__level mr-1"
        @click="showLevelFilter('location')"
        :class="{
          'map-filter__div__level--selected': selectFilter === 0,
          'map-filter__div__level--location': mobile,
        }"
      >
        {{ !isGuestClickFirstTime.location && isGuest ? $t("desktop.header.location") : location }}
      </button>
      <button
        type="button"
        v-if="getLevelName"
        class="map-filter__div__level mr-1"
        @click="showLevelFilter('level')"
        :class="{
          'map-filter__div__level--selected': selectFilter === 1,
          'map-filter__div__level--mobile': mobile,
        }"
      >
        {{ !isGuestClickFirstTime.grade && isGuest ? $t("desktop.header.grade") : getLevelName }}
      </button>
      <button
        type="button"
        v-if="false"
        class="map-filter__div__type mr-1"
        @click="showLevelFilter('type')"
        :class="{
          'map-filter__div__type--selected': selectFilter === 2,
          'map-filter__div__type--mobile': mobile,
        }"
      >
        {{ $t("header.type") }}
      </button>
      <button
        v-if="mobile"
        type="button"
        class="map-filter__div__more"
        @click="openFilters()"
        :class="{
          'map-filter__div__more--selected': selectFilter === 3,
          'map-filter__div__more--mobile': mobile,
        }"
      >
        {{ $t("header.filters") }}
      </button>
    </div>
    <!-- <div v-if="selectFilter === 1" class="map-filter__div__level-options">
      <div v-for="(level, index) in getGradeLevel" :key="index">
        <div
          @click="changeLevel(level)"
          :class="
            level === guestLevel
              ? 'map-filter__div__level-options__btn__selected'
              : 'map-filter__div__level-options__btn'
          "
        >
          {{ level }}
        </div>
      </div>
    </div> -->
    <div v-if="selectFilter === 2" class="map-filter__div__type-options">
      <div v-if="selectFilter !== null">
        <div
          style="display: flex; justify-content: start"
          v-for="(option, index) in getType"
          :key="index"
        >
          <div class="map-filter__div__type-options__option">
            <input
              type="checkbox"
              class="map-filter__div__type-options__option__input"
              :value="option"
              v-model="type"
            />
            <label class="map-filter__div__type-options__option__text" :for="option">
              {{ option }}
            </label>
          </div>
        </div>
        <div
          v-if="selectFilter !== null && selectFilter === 2"
          class="map-filter__div__type-options__apply"
          @click="applyFilters"
        >
          Ver
        </div>
      </div>
    </div>
    <v-dialog @click:outside="closeFilter" width="360" v-model="showFilter">
      <schools-filter @ready="closeFilter" />
    </v-dialog>
    <v-dialog
      content-class="explorer__location"
      width="650"
      @click:outside="closeModal"
      v-model="showModal"
    >
      <button @click="closeModal" type="button" class="explorer__button--close mr-2">
        <img src="@/assets/icons/close.svg" width="20px" />
      </button>

      <SearchLocation
        v-if="selectFilter === 0 && isGuest"
        @selected-location="setLocation"
        @selected-country="setCountry"
        @selected-addressDetail="setAddressDetailGuest"
        :userRegister="true"
        class="pa-5"
      />

      <Grade
        v-else-if="selectFilter === 1 && isGuest"
        @selected-grade="setGrade"
        :userRegister="true"
        :mapFilter="true"
        :gradesGuest="guestGrades"
        :gradesOfInterest="true"
        :oneGrade="true"
        :student="false"
      />
      <div v-if="selectFilter === 0 && !isGuest" class="pa-5">
        <ConfirmLocation :editModal="true" @locationInfo="setLocation" :isInMapFilter="true" />
      </div>
      <div v-if="selectFilter === 1 && !isGuest" class="pa-5">
        <div class="user_register_container__grade-title">
          <h1
            v-t="'user_register.register_grade.edit_title'"
            class="user_register_container__grade-title__text"
          />
          <img src="@/assets/iconsNewHaven/user_register/icons/face.svg" />
        </div>
        <Grade
          :confirmStudent="true"
          @selected-grade="setGrade"
          :userRegister="false"
          :editCurrentStudent="true"
          :gradesOfStudent="currentStudent.grades[0]"
          :oneGrade="true"
          :gradesOfInterest="true"
          :student="currentStudent"
        />

        <!-- <confirm-students
          @setStudents="setStudent"
          :editStudents="true"
          :editCurrentStudent="true"
        /> -->
      </div>
      <div class="d-flex align-center justify-space-between">
        <v-btn icon @click="closeModal">
          <img width="8" src="@/assets/iconsNewHaven/user_register/icons/blue-back.svg" />
        </v-btn>
        <button
          type="button"
          v-t="'header.send'"
          class="explorer__button mt-3 mr-2"
          :class="{ 'explorer__button--active': locationDone || selectedGradeDone }"
          @click="sendFilter()"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import schoolsFilter from "@/components/explorer/map/SchoolsFilter.vue";
// import MapModalLocation from '@/components/explorer/guest/MapModalLocation.vue';
import SearchLocation from "@/components/shared/SearchLocation.vue";
import Grade from "@/components/shared/Grade.vue";
import ConfirmLocation from "@/components/authentication/user_register/steps/ConfirmLocation.vue";
// import ConfirmStudents from '@/components/authentication/user_register/steps/ConfirmStudents.vue';

export default {
  name: "MapFilter",
  components: {
    schoolsFilter,
    SearchLocation,
    Grade,
    ConfirmLocation,
    // ConfirmStudents,
    // MapModalLocation,
  },
  data() {
    return {
      selectedLocation: null,
      selectedCountry: null,
      selectedGrade: null,
      selectedStage: null,
      selectFilter: null,
      showModal: false,
      showLocationModal: false,
      showGradeModal: false,
      showFilter: false,
      kindergartenGrades: [],
      basicEducationGrades: [],
      secondaryEducationGrades: [],
      type: [],
      locationDone: false,
      studentsForm: null,
      studentsDone: null,
      selectedGradeDone: null,
      adddressDetailGuest: null,
    };
  },
  computed: {
    ...mapGetters({
      guestStudentUUID: "authentication/guestStudentUUID",
      isGuestClickFirstTime: "authentication/isGuestClickFirstTime",
      currentStudent: "authentication/currentStudent",
      isGuest: "authentication/isGuest",
      grades: "userRegister/grades",
      guestLocation: "authentication/guestLocation",
      guestGrades: "authentication/guestGrades",
      guestLevel: "authentication/guestLevel",
      guestStage: "authentication/guestStage",
      gradeOptions: "options/grades",
      cornerBounds: "institutions/cornerBounds",
      legalGuardianAddress: "authentication/legalGuardianAddress",
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    location() {
      if (this.isGuest) {
        return this.guestLocation.address_details ?? this.guestLocation.municipalityName;
      }
      if (this.currentStudent.address !== undefined) {
        if (this.currentStudent.address !== null) {
          if (this.currentStudent.address.length > 0) {
            const longAddress = this.currentStudent.address[0].address_details;
            return this.formatedAddress(longAddress);
          }
        }
      }
      if (this.legalGuardianAddress !== null) {
        if (this.legalGuardianAddress.length !== undefined) {
          if (this.legalGuardianAddress[0].address_street) {
            return this.formatedAddress(this.legalGuardianAddress[0].address_street);
          }
          if (this.legalGuardianAddress[0].address_details) {
            return this.formatedAddress(this.legalGuardianAddress[0].address_details);
          }
        }
      }
      return "Santiago";
    },
    getGradeLevel() {
      return ["Parvularia", "Básica", "Media"];
    },
    getKindergartenGradeOptions() {
      const kindergartenGradeOptions = [];
      this.gradeOptions.forEach((grade) => {
        if (grade.name === "PreKinder") {
          kindergartenGradeOptions.push(grade.name);
        }
        if (grade.name === "Kinder") {
          kindergartenGradeOptions.push(grade.name);
        }
      });
      return kindergartenGradeOptions;
    },
    getSecondaryEducation() {
      const secondaryEducationGradeOptions = [];
      this.gradeOptions.forEach((grade) => {
        if (grade.name === "1o Medio") {
          secondaryEducationGradeOptions.push(grade.name);
        }
        if (grade.name === "2o Medio") {
          secondaryEducationGradeOptions.push(grade.name);
        }
        if (grade.name === "3o Medio") {
          secondaryEducationGradeOptions.push(grade.name);
        }
        if (grade.name === "4o Medio") {
          secondaryEducationGradeOptions.push(grade.name);
        }
      });
      return secondaryEducationGradeOptions;
    },
    getType() {
      return ["Particular Pagado", "Particular Subvencionado", "Público"];
    },
    getLevelName() {
      if (this.isGuest) {
        const splitedGradeName = this.splitLevelName(
          this.guestLevel,
          this.guestStage,
          this.guestGrades[0]
        );
        if (splitedGradeName) {
          return splitedGradeName;
        }
        return "";
      }
      if (this.currentStudent.grades !== undefined) {
        if (this.currentStudent.grades.length > 0) {
          let gradeName = this.gradeOptions.find(
            (grade) => grade.id === this.currentStudent.grades[0]
          ).grade_name;
          const splitedGradeName = this.splitLevelName(
            gradeName,
            this.currentStudent.stages[0],
            this.currentStudent.grades[0]
          );
          if (splitedGradeName) {
            return splitedGradeName;
          }
          return gradeName;
        }
      }
      if (this.currentStudent.current_enrollment !== undefined) {
        if (this.currentStudent.current_enrollment.grade_label !== null) {
          const gradeName = this.gradeOptions.find(
            (grade) => grade.id === this.currentStudent.current_enrollment.grade_label
          ).grade_name;
          return gradeName.split("/")[0].trim();
        }
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      cleanGuestData: "authentication/cleanGuestData",
      setIsGuestClickFirstTime: "authentication/setIsGuestClickFirstTime",
      setGuestData: "authentication/setGuestData",
      updateGuest: "authentication/updateGuest",
      retrieveCampuses: "institutions/retrieveCampuses",
      setTypeFilter: "filters/setTypeFilter",
      setFilters: "institutions/setFilters",
      setMultimediaFilter: "filters/setMultimediaFilter",
      cleanFilters: "institutions/cleanFilters",
      setPriorityFilter: "filters/setPriorityFilter",
      error: "snackbar/error",
      setLoginFilter: "filters/setLoginFilter",
      setStateGrades: "userRegister/setGrades",
      setFastRegisterLocation: "userRegister/setFastRegisterLocation",
      setStudentLocation: "newStudentRegister/setStudentLocation",
      setFastRegisterGrade: "userRegister/setFastRegisterGrade",
      setAddress: "authentication/setAddress",
      setInterestedGrades: "newStudentRegister/setInterestedGrades",
      setMapCenter: "institutions/setMapCenter",
    }),
    async sendFilter() {
      this.studentsDone = true;
      this.locationDone = true;
      switch (this.selectFilter) {
        case 0:
          await this.sendLocation();
          break;
        case 1:
          this.sendGrade();
          break;
        default:
          break;
      }
    },
    splitLevelName(gradeName, stage, grade) {
      if (stage === 1) {
        if (grade === 1) {
          return gradeName.split("/")[0].trim();
        }
        if (grade === 5 || grade === 6) {
          return gradeName.split("/")[1].trim();
        }
      }
      if (stage === 2) {
        if (grade === 7 || grade === 11 || grade === 13) {
          return gradeName.split("/")[0].trim();
        }
      }
      if (stage === 3) {
        if (grade === 15 || grade === 17 || grade === 18) {
          return gradeName.split("/")[0].trim();
        }
      }
      if (stage === 4) {
        if (grade === 1) {
          return gradeName.split("/")[1].trim();
        }
        if (grade === 7 || grade === 11 || grade === 13) {
          return gradeName.split("/")[0].trim();
        }
      }
      if (stage === 5) {
        if (grade === 7 || grade === 11 || grade === 13) {
          return gradeName.split("/")[1].trim();
        }
        if (grade === 15 || grade === 17 || grade === 18) {
          return gradeName.split("/")[1].trim();
        }
      }
      return gradeName.split("/")[0].trim();
    },
    formatedAddress(addressDetails) {
      if (addressDetails) {
        let indexComma = addressDetails.indexOf(',');
        const commaList = [];
        // eslint-disable-next-line no-unused-vars
        let count = 0;
  
        while (indexComma !== -1) {
          count += 1;
          indexComma = addressDetails.indexOf(',', indexComma += 1);
          commaList.push(indexComma);
        }
        return addressDetails.slice(0, commaList[0]);
      }
      return '';
    },
    setGrade(grade, stage) {
      this.selectedGrade = [grade];
      if (stage !== undefined) {
        this.selectedStage = [stage];
      }
      this.selectedGradeDone = true;
    },
    setStudent(studentForm, confirmed) {
      this.studentsForm = studentForm;
      this.studentsDone = confirmed;
    },
    sendGrade() {
      if (this.isGuest) {
        // update guest user
        const level = this.gradeOptions.find(
          (grade) => grade.id === this.selectedGrade[0]
        ).grade_name;
        // set data
        const stage = this.selectedStage[0];
        this.setGuestData({
          location: JSON.parse(JSON.stringify(this.guestLocation)),
          grades: this.selectedGrade,
          stage: stage,
          level,
        }).then(() => {
          this.retrieveCampuses({
            bounds: this.cornerBounds,
            showFilters: false,
          });
        });
        if (this.selectedGrade === null) {
          this.error(this.$t("header.error_grade"));
          return;
        }
        this.selectedGrade = null;
        this.selectedGradeDone = null;
        if (!this.isGuestClickFirstTime.grade) {
          this.setIsGuestClickFirstTime({ property: "grade" });
        }
      } else {
        let firstName;
        if (this.currentStudent.first_name === null) {
          firstName = this.currentStudent.name.split(" ")[0];
        } else {
          firstName = this.currentStudent.first_name;
        }
        let lastName;
        if (this.currentStudent.first_lastname === null) {
          lastName = "";
        } else {
          lastName = this.currentStudent.first_lastname;
        }
        let currentSchool;
        if (this.currentStudent.current_enrollment.id === null) {
          currentSchool = null;
        } else {
          currentSchool = this.currentStudent.current_enrollment.id;
        }
        let gradeId;
        if (this.currentStudent.current_enrollment.grade_label !== null) {
          gradeId = this.gradeOptions.find(
            (grade) => grade.id === this.currentStudent.current_enrollment.grade_label
          ).id;
        } else {
          gradeId = null;
        }
        const gradePayload = {
          uuid: this.currentStudent.uuid,
          first_name: firstName,
          last_name: lastName,
          current_school: currentSchool,
          current_grade: gradeId,
          interest_grades: this.selectedGrade,
          interest_stages: this.selectedStage,
        };
        this.setInterestedGrades(gradePayload);
      }
      this.closeModal();
    },
    setLocation(location) {
      this.selectedLocation = location;
      this.locationDone = true;
    },
    setCountry(country) {
      this.selectedCountry = country;
    },
    setAddressDetailGuest(addressDetail) {
      this.adddressDetailGuest = addressDetail;
    },
    async sendLocation() {
      if (this.isGuest) {
        const location = {
          coordinate: this.selectedLocation,
          addressDetail: this.adddressDetailGuest,
          municipalityName: this.selectedCountry ?? "Santiago",
          regionId: 24, // TODO: Remove hard code
        };
        let level = this.gradeOptions.find((grade) => grade.id === this.guestGrades[0]).grade_name;
        if (this.guestGrades.length > 1) {
          level += ` + (${this.guestGrades.length - 1})`;
        }
        await this.setGuestData({
          location,
          grades: this.guestGrades,
          stage: this.guestStage,
          level,
        });

        if (!this.isGuestClickFirstTime.location) {
          this.setIsGuestClickFirstTime({ property: "location" });
        }
      }
      if (!this.isGuest) {
        this.selectedLocation.uuid = this.currentStudent.uuid;
        this.setStudentLocation({ location: this.selectedLocation });
        this.setMapCenter({
          lat: this.selectedLocation.coordinates.lat,
          lng: this.selectedLocation.coordinates.lng,
        });
      }
      if (this.selectedLocation === null) {
        this.error(this.$t("header.error_location"));
        return;
      }
      this.closeModal();
    },
    updateGuestLocation(location) {
      this.updateGuest({
        location,
        callback: () => this.closeModal(),
        errorCallback: () => this.error(this.$t("explorer.map_modal.error.update")),
      });
    },
    closeModal() {
      this.locationDone = null;
      this.selectedGradeDone = null;
      this.selectFilter = null;
      this.showModal = false;
    },
    showLevelFilter(option) {
      if (this.isGuest) {
        if (option === "location") {
          if (this.selectFilter !== 0) {
            this.selectFilter = 0;
            this.showModal = true;
          } else {
            this.selectFilter = null;
          }
        }
        if (option === "level") {
          if (this.selectFilter !== 1) {
            this.selectFilter = 1;
            this.showModal = true;
          } else {
            this.selectFilter = null;
          }
        }
        if (option === "type") {
          if (this.selectFilter !== 2) {
            this.selectFilter = 2;
          } else {
            this.selectFilter = null;
          }
        }
        if (option === "more") {
          if (this.selectFilter !== 3) {
            this.selectFilter = 3;
          } else {
            this.selectFilter = null;
          }
        }
      } else {
        if (option === "location") {
          if (this.selectFilter !== 0) {
            this.selectFilter = 0;
            this.showModal = true;
            return;
          }
          this.selectFilter = null;
        }
        if (option === "level") {
          if (this.selectFilter !== 1) {
            this.selectFilter = 1;
            this.showModal = true;
            return;
          }
          this.selectFilter = null;
        }
        // eslint-disable-next-line
        if (option === "more") {
          if (this.selectFilter !== 3) {
            this.selectFilter = 3;
          } else {
            this.selectFilter = null;
          }
        } else if (option === "type") {
          if (this.selectFilter !== 2) {
            this.selectFilter = 2;
          } else {
            this.selectFilter = null;
          }
        }
      }
    },
    openFilters() {
      if (this.showFilter === false) {
        this.selectFilter = 3;
        this.showFilter = true;
      } else {
        this.showFilter = false;
      }
    },
    changeLevel(level) {
      if (this.isGuest) {
        let gradeFinale = [];
        if (level === "Parvularia") {
          const gradeSelected = this.gradeOptions.find(
            (grade) => grade.name === "Kinder"
          ).grade_uuids;
          if (gradeSelected.length >= 1) {
            gradeSelected.forEach((grade) => {
              gradeFinale.push(grade.uuid);
            });
          } else {
            gradeFinale = [];
          }
        } else if (level === "Básica") {
          const gradeSelected = this.gradeOptions.find(
            (grade) => grade.name === "8o Básico"
          ).grade_uuids;
          if (gradeSelected.length >= 1) {
            gradeSelected.forEach((grade) => {
              gradeFinale.push(grade.uuid);
            });
          } else {
            gradeFinale = [];
          }
        } else if (level === "Media") {
          const gradeSelected = this.gradeOptions.find(
            (grade) => grade.name === "4o Medio"
          ).grade_uuids;
          if (gradeSelected.length >= 1) {
            gradeSelected.forEach((grade) => {
              gradeFinale.push(grade.uuid);
            });
          } else {
            gradeFinale = [];
          }
        }
        const location = JSON.parse(JSON.stringify(this.guestLocation));
        this.cleanGuestData().then(() => {
          // eslint-disable-next-line
          this.setGuestData({
            location,
            grades: gradeFinale,
            stage: -1,
            level,
          });
          this.updateGuest({
            grades: gradeFinale,
            errorCallback: () => this.error(this.$t("explorer.map_modal.error.update")),
          });
        });
      }
    },
    getBasicEducation(cycle) {
      const basicEducationOptions = [];
      this.gradeOptions.forEach((grade) => {
        if (cycle === "firstCycle") {
          if (grade.name === "1o Básico") {
            basicEducationOptions.push(grade.name);
          }
          if (grade.name === "2o Básico") {
            basicEducationOptions.push(grade.name);
          }
          if (grade.name === "3o Básico") {
            basicEducationOptions.push(grade.name);
          }
          if (grade.name === "4o Básico") {
            basicEducationOptions.push(grade.name);
          }
        }
        if (cycle === "secondCycle") {
          if (grade.name === "5o Básico") {
            basicEducationOptions.push(grade.name);
          }
          if (grade.name === "6o Básico") {
            basicEducationOptions.push(grade.name);
          }
          if (grade.name === "7o Básico") {
            basicEducationOptions.push(grade.name);
          }
          if (grade.name === "8o Básico") {
            basicEducationOptions.push(grade.name);
          }
        }
      });
      return basicEducationOptions;
    },
    applyFilters() {
      // If no filter has been selected, then all filter in the category are used
      // eslint-disable-next-line
      this.setLoginFilter({ login: false });
      let showType;
      let lat;
      let lng;
      const performanceCategories = [];
      const monthlyPayments = [];
      if (!this.isGuest) {
        lat = this.currentStudent.studentreferencelocation_set[0].coordinate.lat;
        lng = this.currentStudent.studentreferencelocation_set[0].coordinate.lng;
      } else {
        lat = this.guestLocation.coordinate.lat;
        lng = this.guestLocation.coordinate.lng;
      }
      if (this.type.length > 0) {
        this.setMultimediaFilter({ multimedia: [] });
        this.setPriorityFilter({ priority: [] });
        this.setTypeFilter({ type: this.type });
        showType = true;
      } else {
        this.setTypeFilter({ type: [] });
        showType = false;
      }
      const filtersToApply = {
        performance_categories: performanceCategories,
        monthly_payments: monthlyPayments,
        distance_radius: 2,
        // eslint-disable-next-line
        center: { lat: lat, lng: lng },
        has_pie: false,
        has_sep: false,
        show_school_type: showType,
        all_performance_categories: true,
        all_monthly_payments: true,
      };

      this.selectFilter = null;
      this.setFilters({ filters: filtersToApply })
        .then(() =>
          this.retrieveCampuses({
            bounds: this.cornerBounds,
            showFilters: true,
          })
        )
        .then(() => this.$emit("close_and_filter"));
    },
    closeFilter() {
      this.selectFilter = -1;
      this.showFilter = false;
    },
  },
};
</script>
