<template>
  <div class="fill-height">
    <!-- <div ref="legend" class="pr-2 pt-2">
      <map-legend :show-high-school-zones="showHighSchoolZones"/>
    </div> -->
    <div v-show="maploaded && !isGuest" ref="disclaimer" class="pl-2 pr-4 pb-4">
      <!-- <map-disclaimer :class="$vuetify.breakpoint.width < 1024 ? 'mb-0' : 'mb-2'"/> -->
    </div>
    <div class="d-flex fill-height fill-width" style="position: relative; overflow-y: hidden">
      <GmapMap
        v-if="lat && lng"
        ref="mapRef"
        :center="getMapCenter"
        :zoom="zoom"
        :class="mobile ? 'vue-map-container-mobile' : 'vue-map-container'"
        :options="{
          disableDefaultUI: true,
          clickableIcons: false,
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          styles: mapStyle,
          gestureHandling: 'greedy',
          zoomControl: true,
        }"
        @idle="onDrag"
        @zoom_changed="closeDialogSchool"
      >
        <div v-if="schools.length > 0">
          <gmap-custom-marker
            :id="`id-markermap-${school.uuid}`"
            v-for="(school, i) in schools"
            :key="i"
            :marker="{
              lat: school.location.lat,
              lng: school.location.lon,
            }"
            alignment="center"
            :class="{
              highlight: currentSchool.uuid === school.uuid && showInfoMarker,
              'super-highlight':
                traslucentDialog && currentSchool.uuid === school.uuid && showInfoMarker,
            }"
          >
            <div :ref="`school-${school.uuid}`">
              <div class="d-inline-flex justify-center align-center marker">
                <!-- :performance="school.performance_set[0].qualitycategory_level_label.qualitycategory_level_name"
                  :payment="school.payment.default" -->
                <marker-icon
                  :uuid="school.uuid"
                  :explored="school.isExplored"
                  :exploredMapCard="school.isExploredMapCard"
                  :type="null"
                  :selected="campusUuid === school.uuid && showInfoMarker"
                  :currentZoom="currentZoom"
                  :has360Tour="school.multimedia_options.has_tour"
                  :hasVideo="school.multimedia_options.has_video"
                  :hasSep="school.has_sep"
                  :hasPie="school.has_pie"
                  :hasDroneFlight="school.multimedia_options.has_drone_flight"
                  :school="school"
                  :performance="perfomance(school)"
                  :payment="payment(school)"
                  :location="school.location"
                  :hasVacancies="setVacancies(school)"
                  :dependency="school.sector.id"
                  @isHighlighted="setFiltersApplied"
                />
                <v-avatar
                  v-if="currentZoom > 14 && school.isFavorite"
                  color="#454444"
                  size="24"
                  :class="{ 'no-tour': !school.has_tour }"
                  class="fav-container"
                >
                  <img src="@/assets/svg/save-institution.svg" />
                </v-avatar>
                <!-- TODO: refactorizar esto cuando esté definido correctamente el filtro --->
                <!--              <v-avatar
                  v-if="school.shouldHighlight"
                  color="#FFAA06"
                  size="27"
                  class="should-highlight">
                  <v-icon size="13">mdi-magnify</v-icon>
                </v-avatar>-->
              </div>
            </div>
          </gmap-custom-marker>
        </div>
        <gmap-custom-marker v-if="showMarker" :marker="homeLocation" alignment="top">
          <img :src="require(`@/assets/markers/home.svg`)" class="home-icon" />
        </gmap-custom-marker>
        <!--      <gmap-custom-marker
          v-if="showSelectedZone"
          :marker="selectedZone.location">
          <div class="px-4 py-2 zone-label">{{ selectedZone.name }}</div>
        </gmap-custom-marker>
-->

        <!--REGIONES-->

        <div v-if="currentZoom < 11">
          <gmap-polygon
            v-for="(shape, i) in testShapes"
            :key="`polygon-${i}`"
            :paths="shape"
            :options="polygon.polygonOptions"
            @click="currentZoom < 15 ? PolygonMouseClick(i, 11) : ''"
            @mouseover="changePolygonMouseOver(i, 1)"
            @mouseout="changePolygonMouseOut(i)"
            ref="google_map_polygon"
          >
          </gmap-polygon>
        </div>

        <!--COMUNAS-->

        <div v-if="currentZoom >= 11 && currentZoom < 14">
          <gmap-polygon
            v-for="(shape, i) in regionShapes"
            :key="`polygon-${i}`"
            :paths="shape"
            :options="polygon.polygonOptions"
            @click="currentZoom < 14 ? PolygonMouseClick(i, 14) : ''"
            @mouseover="changePolygonMouseOver(i, 0)"
            @mouseout="changePolygonMouseOut(i)"
            ref="google_map_polygon"
          >
          </gmap-polygon>
        </div>
      </GmapMap>
      <div
        class="d-flex justify-center fill-width"
        style="position: absolute; bottom: 0px; pointer-events: none"
        :class="{ 'school-card-map-div': mobile }"
      >
        <school-map-card
          :campus="currentSchool"
          :show="showInfoMarker"
          :index="schoolIndex"
          :campus-uuid="campusUuid"
          @close="closeDialogSchool"
          :lat="infoMarkerCenterLat"
          :lng="infoMarkerCenterLng"
          style="pointer-events: auto"
          @signUp="signUp"
        />
      </div>

      <div
        class="d-flex justify-center fill-width"
        style="position: absolute; pointer-events: none"
        :style="mobile ? 'bottom: 75px;' : 'bottom: 30px;'"
        v-if="showDistrictCard && currentZoom < 14"
      >
        <district-map-card
          :zoom="currentZoom"
          :name="this.currentDistrictName"
          :schools="currentDistrictSchools"
          :vacancies="currentDistrictVacancies"
        >
        </district-map-card>
      </div>

      <div
        class="institutions-info"
        :class="{ 'institutions-info--mobile': mobile, 'institutions-info--guest': isGuest }"
      >
        <select-left-bar v-if="exitsInfoGuest || !isGuest" />
        <select-right-bar
          v-if="exitsInfoGuest || !isGuest"
          :filterNumber="filtersApplied"
          :map="map"
        />
      </div>

      <student-menu v-if="!mobile && (exitsInfoGuest || !isGuest)" />
    </div>
    <v-btn
      @click="centerMap"
      depressed
      class="absolute bottom-0 right-0 mb-28 z-10 button-my-location"
      type="button"
    >
      <img src="@/assets/icons/my_location.svg" width="27px" alt="location" />
    </v-btn>
    <v-dialog
      content-class="dashborad-modal-flow"
      overlay-color="transparent"
      v-model="showEditLocation"
      max-width="380px"
    >
      <div>
        <confirm-location
          class="pa-5"
          @locationInfo="setFastLocation"
          :editModal="true"
          :isInMainMap="true"
        />
        <div class="btn_container pa-5">
          <v-btn icon @click="closeModal">
            <img width="8" src="@/assets/iconsNewHaven/user_register/icons/blue-back.svg" />
          </v-btn>
          <base-button
            class="btn-continue"
            :class="{ 'btn-continue--active': location }"
            @click="setLocation"
            :disabled="!locationDone"
          >
            {{ $t("user_register.register_code.btn") }}
          </base-button>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import GmapCustomMarker from "vue2-gmap-custom-marker";
import utils from "@/utils/";
import MarkerIcon from "@/components/explorer/general/MarkerIcon.vue";
import StudentMenu from "@/components/explorer/map/StudentMenu.vue";
// import MapDisclaimer from './MapDisclaimer.vue';
// import ElementaryZones from '@/assets/ElementaryZones.json';
// import HighschoolZones from '@/assets/HighschoolZones.json';

// Perdón por esto :(
import ElementaryJSON from "@/assets/regionesChile.json";
import region1 from "@/assets/chileShapes/1.json";
import region2 from "@/assets/chileShapes/2.json";
import region3 from "@/assets/chileShapes/3.json";
import region4 from "@/assets/chileShapes/4.json";
import region5 from "@/assets/chileShapes/5.json";
import region6 from "@/assets/chileShapes/6.json";
import region7 from "@/assets/chileShapes/7.json";
import region8 from "@/assets/chileShapes/8.json";
import region9 from "@/assets/chileShapes/9.json";
import region10 from "@/assets/chileShapes/10.json";
import region11 from "@/assets/chileShapes/11.json";
import region12 from "@/assets/chileShapes/12.json";
import region13 from "@/assets/chileShapes/13.json";
import region14 from "@/assets/chileShapes/14.json";
import region15 from "@/assets/chileShapes/15.json";
import region16 from "@/assets/chileShapes/16.json";

import BaseButton from "@/components/explorer/base/BaseButton.vue";
import DistrictMapCard from "./DistrictCard.vue";
import SelectRightBar from "./SelectRightBar.vue";
import SelectLeftBar from "./SelectLeftBar.vue";
import SchoolMapCard from "./SchoolMapCard.vue";
import mapStyle from "../../../assets/mapStyle.json";
import ConfirmLocation from "../../authentication/user_register/steps/ConfirmLocation.vue";

export default {
  name: "MarkerMap",
  components: {
    GmapCustomMarker,
    // MapDisclaimer,
    SchoolMapCard,
    MarkerIcon,
    StudentMenu,
    SelectLeftBar,
    SelectRightBar,
    DistrictMapCard,
    BaseButton,
    ConfirmLocation,
    // MapShapes
  },
  data() {
    return {
      showEditLocation: false,
      traslucentDialog: false,
      zoom: 0,
      lat: null,
      lng: null,
      google: {},
      geocoder: null,
      schoolIndex: 0,
      campusUuid: "",
      locationDone: false,
      location: null,
      mapCircle: null,
      mapStyle,
      map: null,
      originalCenter: null,
      currentMapCenter: null,
      dragTimer: 0,
      minZoom: 14,
      show: false,
      showFilter: false,
      infoMarkerCenterLat: "",
      infoMarkerCenterLng: "",
      maploaded: false,
      isMapMounted: false,
      lastTimer: 0,
      bounds: null,
      testShapes: null,
      regionShapes: null,
      showDistrictCard: false,
      currentDistrictName: "null",
      currentDistrictSchools: 0,
      currentDistrictVacancies: 0,
      filtersApplied: null,
      districtJSONS: [
        region1,
        region2,
        region3,
        region4,
        region5,
        region6,
        region7,
        region8,
        region9,
        region10,
        region11,
        region12,
        region13,
        region14,
        region15,
        region16,
      ],
      polygon: {
        polygonNames: [],
        polygonShapes: ElementaryJSON.features,
        polygonOptions: {
          strokeColor: "#61f7c1",
          fillOpacity: 0.0,
          zIndex: 2,
        },
        polygonOptions2: {
          strokeColor: "#ffffff",
          fillOpacity: 0.1,
          fillColor: "#ffffff",
          zIndex: 1000,
        },
      },
    };
  },
  props: {
    guestLoaded: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    // Controls the marker icon beat
    setInterval(() => {
      this.show = !this.show;
    }, 600);
  },
  activated() {
    if (this.isMapMounted) {
      const lng = parseFloat(this.homeLocation.lng);
      const lat = parseFloat(this.homeLocation.lat);
      if (lat && lng) {
        this.setMapCenter({ lat, lng });
        this.lat = lat;
        this.lng = lng;
      }
    }
  },
  mounted() {
    this.zoom = this.exitsInfoGuest ? 17 : 16;
    if (this.legalGuardianAddress !== null) {
      if (
        this.legalGuardianAddress[0].address_lat === null &&
        this.legalGuardianAddress[0].address_lon === null
      ) {
        this.showEditLocation = true;
      }
    }
    if ((!this.students || this.students.length < 1) && !this.isGuest) {
      this.$router.push({ name: "Register Student" });
    } else {
      this.$gmapApiPromiseLazy()
        .then((google) => {
          this.google = google;
          this.geocoder = new google.maps.Geocoder();
          // return this.geocode(this.geocoderData);
        })
        .then(() => {
          const lng = parseFloat(this.homeLocation.lng);
          const lat = parseFloat(this.homeLocation.lat);
          this.setMapCenter({ lat, lng });
          this.lat = lat;
          this.lng = lng;
        })
        .finally(() => {
          this.isMapMounted = true;
          this.$refs.mapRef.$mapPromise.then((map) => {
            const { disclaimer, filterButton, login } = this.$refs;
            // map.controls[this.google.maps.ControlPosition.RIGHT_TOP].push(legend);
            map.controls[this.google.maps.ControlPosition.LEFT_BOTTOM].push(disclaimer);
            map.controls[this.google.maps.ControlPosition.RIGHT_TOP].push(filterButton);
            if (this.isGuest) {
              map.controls[this.google.maps.ControlPosition.LEFT_TOP].push(login);
            }
            this.map = map;
            // When the map is loaded, campus data is retrieved
            this.google.maps.event.addListenerOnce(map, "idle", () => {
              this.setMapCorners(map);
              /**
               * TODO #1 https://github.com/ConsiliumBots/cb-explorer-chile-back/projects/1
               * Set circle in non Artificial legalguardian
               */
              // this.drawMapCircle(this.distanceRadius);
            });
            setTimeout(() => {
              this.maploaded = true;
              if (this.filtersApplied > 0) {
                this.setCleanedFilters({ bool: false });
              }
            }, 2000);
          });
        });
    }
  },
  computed: {
    ...mapGetters({
      exitsInfoGuest: "authentication/exitsInfoGuest",
      mapCenter: "institutions/mapCenter",
      currentSchool: "institutions/currentSchoolLite",
      distanceRadius: "institutions/distanceRadius",
      schools: "institutions/schools",
      cornerBounds: "institutions/cornerBounds",
      favorites: "institutions/favorites",
      homeLocation: "authentication/homeLocation",
      students: "authentication/students",
      showInfoMarker: "institutions/showInfoMarker",
      isGuest: "authentication/isGuest",
      artificial: "authentication/artificial",
      thereAreFiltersApplied: "institutions/thereAreFiltersApplied",
      currentStudent: "authentication/currentStudent",
      treatment: "authentication/treatment",
      legalGuardianUUID: "authentication/legalGuardianUUID",
      legalGuardianAddress: "authentication/legalGuardianAddress",
      environment: "authentication/environment",
      token: "authentication/token",
      guestStage: "authentication/guestStage",
      guestGrades: "authentication/guestGrades",
    }),
    // ...mapGetters('authentication', ['geocoderData', 'currentStudent', 'home',
    // 'studentList', 'legalGuardianAddress']),
    getMapCenter() {
      const centerGuest = { lat: -33.436927001, lng: -70.650272 };
      return this.exitsInfoGuest ? this.mapCenter : centerGuest;
    },
    showHighSchoolZones() {
      return this.currentStudent.grade >= 9;
    },
    centersType() {
      if (this.showHighSchoolZones) {
        return this.centers.highschool;
      }
      return this.centers.elementary;
    },
    currentZoom() {
      return this.map ? this.map.getZoom() : null;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    showMarker() {
      if (this.isGuest) {
        const defaultLocation = JSON.stringify({
          lat: -33.447487,
          lng: -70.673676,
        });
        return JSON.stringify(this.homeLocation) !== defaultLocation;
      }
      return true;
    },
  },
  methods: {
    ...mapActions({
      setMapCenter: "institutions/setMapCenter",
      updateCurrentSchool: "institutions/updateCurrentSchool",
      setCornerBounds: "institutions/setCornerBounds",
      retrieveCampuses: "institutions/retrieveCampuses",
      setExploredSchoolCards: "institutions/setExploredSchoolCards",
      getStudents: "authentication/getStudents",
      info: "snackbar/infoWithTimeout",
      reset: "snackbar/reset",
      changeShowInfoMarkerStatus: "institutions/changeShowInfoMarkerStatus",
      setWantCreateAccount: "authentication/setWantCreateAccount",
      setFastRegisterLocation: "userRegister/setFastRegisterLocation",
      getUserDetails: "authentication/getUserDetails",
      setCleanedFilters: "filters/setCleanedFilters",
    }),
    setFiltersApplied(evt) {
      this.filtersApplied = evt;
    },
    setFastLocation(location) {
      this.location = location;
      this.locationDone = true;
    },
    closeModal() {
      this.showEditLocation = false;
      this.getStudents({});
    },
    setLocation() {
      const { location } = this;
      this.setFastRegisterLocation({ location }).then(() => {
        this.showEditLocation = false;
      });
      this.getUserDetails({
        legalGuardianUUID: this.legalGuardianUUID,
        token: this.token,
        location: location.addressDetail,
      });
    },
    setMapCorners(map) {
      try {
        const bounds = map.getBounds();
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();
        const northWest = new this.google.maps.LatLng(northEast.lat(), southWest.lng());
        const southEast = new this.google.maps.LatLng(southWest.lat(), northEast.lng());
        // !! TODO Chile dejar 0.08
        let offset;

        if (this.currentZoom === 14) {
          offset = 0;
        } else {
          offset = 0.03 * (map.getZoom() / 17);
        }
        const cornerBounds = [
          { lat: northWest.lat() + offset, lng: northWest.lng() - offset },
          { lat: northEast.lat() + offset, lng: northEast.lng() + offset },
          { lat: southEast.lat() - offset, lng: southEast.lng() + offset },
          { lat: southWest.lat() - offset, lng: southWest.lng() - offset },
        ];
        this.bounds = cornerBounds;
        this.originalCenter = map.getCenter();
        this.currentMapCenter = map.getCenter();
        this.setCornerBounds({ cornerBounds });
        if (this.currentZoom === 14) {
          this.retrieveCampuses({
            callback: null,
            bounds: cornerBounds,
            smallIcons: true,
          });
        }
        if (this.currentZoom !== 14) {
          this.retrieveCampuses({
            callback: null,
            bounds: cornerBounds,
          });
        }
      } catch {
        this.retrieveCampuses({});
      }
    },
    signUp() {
      this.$emit("signUp");
    },
    setVacancies(school) {
      let program = 0;
      if (this.isGuest) {
        if (this.guestGrades.length > 0) {
          program = school.programs.find((program) => program.grade.id === this.guestGrades[0]);
        }
        if (program !== undefined) {
          return program._calculated_vacancies;
        }
        return 0;
      }
      if (this.currentStudent) {
        if (this.currentStudent.grades) {
          if (this.currentStudent.grades.length > 0) {
            program = school.programs.find(
              (program) => program.grade.id === this.currentStudent.grades[0]
            );
          } else if (this.currentStudent.current_enrollment !== null) {
            program = school.programs.find(
              (program) => program.grade.id === this.currentStudent.current_enrollment.grade_label
            );
          }
        }
      }
      if (program !== undefined) {
        return program._calculated_vacancies;
      }
      return 0;
    },
    perfomance(school) {
      if (school.performance_set !== null && school.performance_set.length > 0) {
        if (
          this.currentStudent.current_enrollment !== undefined &&
          this.currentStudent.current_enrollment !== null
        ) {
          let performance;
          if (school.performance_set.length > 0) {
            if (this.currentStudent.stages[0] !== 3) {
              performance = school.performance_set.find(
                (performance) => performance.qualitycategory_label.stage_label === 2
              );
            }
            if (this.currentStudent.stages[0] === 3) {
              performance = school.performance_set.find(
                (performance) => performance.qualitycategory_label.stage_label === 3
              );
            }
            if (performance !== undefined) {
              return performance.qualitycategory_level_label.qualitycategory_level_name;
            }
            ("no-data");
          }
        }
        if (this.isGuest) {
          let performance;
          if (school.performance_set.length > 0) {
            if (this.guestStage !== 3) {
              performance = school.performance_set.find(
                (performance) => performance.qualitycategory_label.stage_label === 2
              );
            }
            if (this.guestStage === 3) {
              performance = school.performance_set.find(
                (performance) => performance.qualitycategory_label.stage_label === 3
              );
            }
            if (performance !== undefined) {
              return performance.qualitycategory_level_label.qualitycategory_level_name;
            }
            ("no-data");
          }
        }
      }
      return "no-data";
    },
    payment(school) {
      return school.payment.default;
    },
    PolygonMouseClick(i, zoom) {
      // console.log("Este es el poligono " + i)
      // const numberOfSchools = this.schoolsInPolygon(
      //  this.$refs.google_map_polygon[i].$polygonObject);
      // const lat = this.getCenter(this.$refs.google_map_polygon[i].$polygonObject).lat();
      // const lng = this.getCenter(this.$refs.google_map_polygon[i].$polygonObject).lng();
      if (this.$refs.mapRef) {
        this.$refs.mapRef.$mapObject.setCenter(
          this.getCenter(this.$refs.google_map_polygon[i].$polygonObject)
        );
        this.$refs.mapRef.$mapObject.setZoom(zoom);
      }
    },
    changePolygonMouseOver(i, region) {
      // SHOW CARD
      this.showDistrictCard = true;
      // COLOR
      this.$refs.google_map_polygon[i].$polygonObject.setOptions(this.polygon.polygonOptions2);
      // DATA

      // DADO EL TAMAÑO DE LOS DATOS, ESTO HAY QUE SACARLO DE UNA LISTA PRE-CARGADA O VA A EXPLOTAR

      /*
      const numberOfSchools = this.schoolsInPolygon(
        this.$refs.google_map_polygon[i].$polygonObject,
      );
      const vacanciesSchools = this.vacanciesSchoolsInPolygon(
        this.$refs.google_map_polygon[i].$polygonObject,
      );

      this.currentDistrictSchools = numberOfSchools;
      this.currentDistrictVacancies = vacanciesSchools;
      */

      if (region) {
        this.currentDistrictName = this.polygon.polygonNames[i];
      } else {
        this.currentDistrictName = this.polygon.polygonNames[i + this.testShapes.length];
      }

      // LOGS
      // console.log("En este poligono hay ")
      // console.log( numberOfSchools+ " escuelas")
    },
    changePolygonMouseOut(i) {
      // SHOW CARD
      this.showDistrictCard = false;
      this.$refs.google_map_polygon[i].$polygonObject.setOptions(this.polygon.polygonOptions);
    },
    closeDialogSchool() {
      this.changeShowInfoMarkerStatus({ status: false });
    },
    // Based on https://github.com/tparkin/Google-Maps-Point-in-Polygon/blob/master/maps.google.polygon.containsLatLng.js
    /* eslint-disable */
    getBounds(polygon) {
      const bounds = new google.maps.LatLngBounds();
      const paths = polygon.getPaths();
      let path;
      let p;
      let i;
      for (p = 0; p < paths.getLength(); p += 1) {
        path = paths.getAt(p);
        for (i = 0; i < path.getLength(); i += 1) {
          bounds.extend(path.getAt(i));
        }
      }
      return bounds;
    },
    getCenter(polygon) {
      return this.getBounds(polygon).getCenter();
    },
    containsLatLng(polygon, latLng) {
      // Exclude points outside of bounds as there is no way they are in the poly
      let inPoly = false;
      let p;
      let path;
      let numPoints;
      let i;
      let j;
      let vertex1;
      let vertex2;
      const lat = latLng.lat;
      const lng = latLng.lng;
      // Raycast point in polygon method
      const numPaths = polygon.getPaths().getLength();
      for (p = 0; p < numPaths; p += 1) {
        path = polygon.getPaths().getAt(p);
        numPoints = path.getLength();
        j = numPoints - 1;
        for (i = 0; i < numPoints; i += 1) {
          vertex1 = path.getAt(i);
          vertex2 = path.getAt(j);
          if (
            (vertex1.lng() < lng && vertex2.lng() >= lng) ||
            (vertex2.lng() < lng && vertex1.lng() >= lng)
          ) {
            if (
              vertex1.lat() +
                ((lng - vertex1.lng()) / (vertex2.lng() - vertex1.lng())) *
                  (vertex2.lat() - vertex1.lat()) <
              lat
            ) {
              inPoly = !inPoly;
            }
          }
          j = i;
        }
      }
      return inPoly;
    },

    showPolygon(i) {
      if (this.map.getBounds() !== undefined) {
        if (
          this.map
            .getBounds()
            .contains(this.getCenter(this.$refs.google_map_polygon[i].$polygonObject))
        ) {
          return true;
        }
        return false;
      }
      return false;
    },
    /* eslint-enable */
    vacanciesSchoolsInPolygon(polygon) {
      let vacanciesLength = 0;
      if (this.map) {
        this.schools.forEach((s) => {
          const { lat } = s.location;
          const lng = s.location.lon;
          // console.log(this.containsLatLng(polygon,{lat, lng}))
          if (this.containsLatLng(polygon, { lat, lng })) {
            // eslint-disable-next-line
            if (s.programs[0]._calculated_vacancies) {
              vacanciesLength += 1;
            }
          }
        });
      }
      return vacanciesLength;
    },
    geocode(geocoderData) {
      return new Promise((resolve, reject) => {
        this.geocoder.geocode(geocoderData, (address, status) => {
          if (status === "OK") {
            const { lat, lng } = address[0].geometry.location;
            resolve({ lat: lat(), lng: lng() });
          } else reject(status);
        });
      });
    },
    exploredByCurrentStudent() {
      return false;
      // if (this.currentStudent.uuid) return school.explored.includes(this.currentStudent.uuid);
      // return school.explored && school.explored.length > 0;
    },
    recalculateCorners() {
      if (this.shouldRecalculateCorners()) {
        this.setMapCorners(this.map);
      }
    },
    shouldRecalculateCorners() {
      if (!this.cornerBounds) {
        return true;
      }
      const northEast = this.cornerBounds[1];
      const southWest = this.cornerBounds[3];
      const bounds = this.map.getBounds();
      // Check if on dragging, bounds with offset touches the border of map
      const touchLeftBorder = bounds.getSouthWest().lng() <= southWest.lng;
      const touchRightBorder = bounds.getNorthEast().lng() >= northEast.lng;
      const touchTopBorder = bounds.getNorthEast().lat() >= northEast.lat;
      const touchBottomBorder = bounds.getSouthWest().lat() <= southWest.lat;
      return (
        (touchLeftBorder || touchRightBorder || touchTopBorder || touchBottomBorder) &&
        this.map.getZoom() >= this.minZoom
      );
    },
    onDrag() {
      this.currentMapCenter = this.map.getCenter();
    },
    isSchoolUnderDialog(schoolUUID) {
      if (!this.showInfoMarker) return false;
      const { showInfoMarker } = this.$refs;
      // Apparently, when you set refs inside a v-for, they are returned inside an array
      const marker = this.$refs[`school-${schoolUUID}`][0];
      const boundingRect = marker.getBoundingClientRect();
      const { left, right, top, bottom } = boundingRect;
      if (document.elementsFromPoint(left, top).includes(showInfoMarker.$el)) return true;
      if (document.elementsFromPoint(right, top).includes(showInfoMarker.$el)) return true;
      if (document.elementsFromPoint(left, bottom).includes(showInfoMarker.$el)) return true;
      return document.elementsFromPoint(right, bottom).includes(showInfoMarker.$el);
    },
    createZones(zones, zoneType, map) {
      zones.features.forEach((zone) => {
        const coordinates = [];
        zone.geometry.coordinates[0].forEach((coordinate) => {
          coordinates.push({
            lat: coordinate[1],
            lng: coordinate[0],
          });
        });
        const borderColor = "#FCBABA";
        const polygon = new this.google.maps.Polygon({
          map,
          paths: coordinates,
          strokeColor: borderColor,
          strokeOpacity: 0.8,
          strokeWeight: 1.4,
          fillColor: borderColor,
          fillOpacity: 0,
          draggable: false,
          geodesic: true,
          zoneName:
            zoneType === "highschool" ? zone.properties.high_name : zone.properties.elem_name,
        });
        const index = this.zones[zoneType].push(polygon);
        this.google.maps.event.addListener(polygon, "mouseout", () => {
          this.zones[zoneType].forEach((zonePoly) => {
            zonePoly.setOptions({ fillOpacity: 0 });
          });
          this.showSelectedZone = false;
        });
        this.google.maps.event.addListener(polygon, "click", (e) => {
          if (!this.showSelectedZone) {
            this.selectedZone.location = e.latLng;
            this.selectedZone.name = polygon.zoneName;
            this.zones[zoneType][index - 1].setOptions({ fillOpacity: 0.2 });
            this.showSelectedZone = true;
          } else if (this.showSelectedZone && this.selectedZone.name === polygon.zoneName) {
            this.zones[zoneType][index - 1].setOptions({ fillOpacity: 0 });
            this.showSelectedZone = false;
          } else {
            this.selectedZone.location = e.latLng;
            this.selectedZone.name = polygon.zoneName;
            this.zones[zoneType][index - 1].setOptions({ fillOpacity: 0.2 });
            this.showSelectedZone = true;
          }
        });
      }, this);
    },
    /**
     * TODO #1 https://github.com/ConsiliumBots/cb-explorer-chile-back/projects/1
     * Set circle in non Artificial legalguardian
     */
    drawMapCircle() {
      /* Draw map circle only if it changes its position */
      if (this.mapCircle) {
        if (
          this.mapCircle.getCenter().lat() !== parseFloat(this.homeLocation.lat) &&
          this.mapCircle.getCenter().lng() !== parseFloat(this.homeLocation.lng)
        ) {
          this.mapCircle.setMap(null);
          this.setMapOrWait();
        }
      } else {
        this.setMapOrWait();
      }
    },

    loadAllMapShapes() {
      this.drawMapShapes(1);
      this.drawMapShapes(0);
    },
    /**
    drawMapShapes() {
      this.$refs.mapRef.$mapObject.data.addGeoJson(ElementaryJSON);
      this.$refs.mapRef.$mapObject.data.setStyle(function (feature) {
          var color = feature.getProperty('fillColor');
          return {
              strokeColor: "#ffffff",
              strokeWeight: 1
          };
      });
      console.log("data es")
      console.log(this.$refs.mapRef.$mapObject.data)
    },
    * */

    drawMapShapes(region) {
      const shapeList = [];

      if (region == 1) {
        for (let i = 0; i < this.polygon.polygonShapes.length; i += 1) {
          const shapeStructure = [[]];
          const poligono = this.polygon.polygonShapes[i];
          if (poligono.geometry.type == "Polygon") {
            for (let j = 0; j < poligono.geometry.coordinates[0].length; j += 1) {
              // Dado que el archivo de shapes viene "malo", es necesario procesar los datos
              const dott = {
                lng: poligono.geometry.coordinates[0][j][0],
                lat: poligono.geometry.coordinates[0][j][1],
              };
              shapeStructure[0].push(dott);
            }
            shapeList.push(shapeStructure);
            this.polygon.polygonNames.push(poligono.properties.Region);
          } else if (poligono.geometry.type == "MultiPolygon") {
            for (let j = 0; j < poligono.geometry.coordinates.length; j += 1) {
              const shapeStructure = [[]];

              for (let k = 0; k < poligono.geometry.coordinates[j][0].length; k += 1) {
                const dott = {
                  lng: poligono.geometry.coordinates[j][0][k][0],
                  lat: poligono.geometry.coordinates[j][0][k][1],
                  name: poligono.properties.Region,
                };
                shapeStructure[0].push(dott);
              }

              // Dado que el archivo de shapes viene "malo", es necesario procesar los datos
              shapeList.push(shapeStructure);
              this.polygon.polygonNames.push(poligono.properties.Region);
              // multiShapeList.push(shapeStructure)
            }
          }
        }

        this.testShapes = shapeList;
      } else {
        for (let d = 0; d < this.districtJSONS.length; d += 1) {
          const region = this.districtJSONS[d];
          for (let i = 0; i < region.features.length; i += 1) {
            const shapeStructure = [[]];
            const poligono = region.features[i];
            if (poligono.geometry.type == "Polygon") {
              for (let j = 0; j < poligono.geometry.coordinates[0].length; j += 1) {
                // Dado que el archivo de shapes viene "malo", es necesario procesar los datos
                const dott = {
                  lng: poligono.geometry.coordinates[0][j][0],
                  lat: poligono.geometry.coordinates[0][j][1],
                };
                shapeStructure[0].push(dott);
              }
              shapeList.push(shapeStructure);
              if (poligono.properties.Comuna) {
                this.polygon.polygonNames.push(poligono.properties.Comuna);
              } else {
                this.polygon.polygonNames.push(poligono.properties.NOM_COMUNA);
              }
            } else if (poligono.geometry.type == "MultiPolygon") {
              for (let j = 0; j < poligono.geometry.coordinates.length; j += 1) {
                const shapeStructure = [[]];

                for (let k = 0; k < poligono.geometry.coordinates[j][0].length; k += 1) {
                  const dott = {
                    lng: poligono.geometry.coordinates[j][0][k][0],
                    lat: poligono.geometry.coordinates[j][0][k][1],
                    name: poligono.properties.NOM_COMUNA,
                  };
                  shapeStructure[0].push(dott);
                }

                // Dado que el archivo de shapes viene "malo", es necesario procesar los datos
                shapeList.push(shapeStructure);
                if (poligono.properties.Comuna) {
                  this.polygon.polygonNames.push(poligono.properties.Comuna);
                } else {
                  this.polygon.polygonNames.push(poligono.properties.NOM_COMUNA);
                }
                // multiShapeList.push(shapeStructure)
              }
            }
          }
        }
        this.regionShapes = shapeList;
      }
    },

    setMapOrWait() {
      if (this.$refs.mapRef) {
        this.$refs.mapRef.$mapPromise.then((map) => {
          const lat = parseFloat(this.homeLocation.lat);
          const lng = parseFloat(this.homeLocation.lng);
          // eslint-disable-next-line
          if (lat && lng) {
            map.setCenter({ lat: lat, lng: lng });
          }
          // const { lat: latCenter, lng: lngCenter } = this.homeLocation;
          // const center = { lat: parseFloat(latCenter), lng: parseFloat(lngCenter) };
          /*
          if (this.treatment > 0) {
            this.mapCircle = new this.google.maps.Circle({
              strokeWeight: 0,
              strokeColor: '#FFFFFF',
              fillColor: '#CCF0FB',
              fillOpacity: 0.2,
              zIndex: 1,
              map,
              center,
              radius: 2000,
            });
          } else {
            this.mapCircle = new this.google.maps.Circle({
              strokeWeight: 0,
              strokeColor: '#FFFFFF',
              fillColor: '#CCF0FB',
              fillOpacity: 0.2,
              zIndex: 1,
              map,
              center,
              radius: this.distanceRadius * 1000,
            });
          }
          */
        });
        this.closeFilter();
      } else {
        clearTimeout(this.lastTimer);
        // eslint-disable-next-line
        this.lastTimer = setTimeout(() => {
          // this.drawMapCircle();
          this.loadAllMapShapes();
        }, 3000);
      }
    },
    // selectZonesLayer(showHighSchoolZones) {
    //   if (this.$refs.mapRef) {
    //     if (!showHighSchoolZones) {
    //       this.zones.highschool.forEach((zone) => zone.setMap(null));
    //       this.$refs.mapRef.$mapPromise.then((map) => {
    //         this.zones.elementary.forEach((zone) => zone.setMap(map));
    //       });
    //     } else {
    //       this.zones.elementary.forEach((zone) => zone.setMap(null));
    //       this.$refs.mapRef.$mapPromise.then((map) => {
    //         this.zones.highschool.forEach((zone) => zone.setMap(map));
    //       });
    //     }
    //   }
    // },
    closeFilter() {
      this.showFilter = false;
    },
    signIn() {
      /* TODO: ver que tiene que hacer */
      this.setWantCreateAccount({ wantCreateAccount: true });
      this.showMenu = false;
      this.$router.push({ name: "Login" });
    },
    showSpecialIcon(school) {
      const distanceToHome = utils.getDistanceInMeters(school.location, this.homeLocation);
      if (distanceToHome <= this.distanceRadius * 1000) {
        return (
          this.artificial &&
          this.thereAreFiltersApplied &&
          school.payment === "Gratuito" &&
          school.performance === "Alto"
        );
      }
      return false;
    },
    centerMap() {
      this.lat = parseFloat(this.homeLocation.lat);
      this.lng = parseFloat(this.homeLocation.lng);
      const { lat, lng } = this;
      this.setMapCenter({ lat, lng });
      if (lat && lng) {
        this.map.setCenter({ lat, lng });
        this.currentMapCenter = this.map.getCenter();
      }
    },
  },
  watch: {
    // homeCoordinates: {
    //   handler(newValue) {
    //     if (this.$refs.mapRef) {
    //       this.$refs.mapRef.$mapPromise.then((map) => {
    //         map.panTo(newValue);
    //       });
    //     }
    //   },
    // },
    // showHighSchoolZones: {
    //   handler(newValue) {
    //     this.showSelectedZone = false;
    //     this.selectZonesLayer(newValue);
    //   },
    //   immediate: true,
    // },
    exitsInfoGuest(value) {
      this.zoom = value ? 17 : 16;
    },
    currentMapCenter() {
      const calculateOffset = (zoom) => Math.exp(5.6333 - 0.7154 * zoom) * 1.5;
      const offset = calculateOffset(this.map.getZoom());
      const lat = this.map.getCenter().lat() - offset;
      const lng = this.map.getCenter().lng();
      this.infoMarkerCenterLat = `${lat}`;
      this.infoMarkerCenterLng = `${lng}`;
      const oldTimer = this.dragTimer;
      clearTimeout(oldTimer);
      // Wait 1 seconds after dragging and then recalculates bounds
      this.dragTimer = setTimeout(this.recalculateCorners, 0);
    },
    currentZoom: {
      handler(newZoom) {
        if (this.currentZoom) {
          if (newZoom < this.minZoom) {
          } else {
            this.reset();
          }
        }
      },
    },
    guestLoaded() {
      setTimeout(() => {
        this.retrieveCampuses({
          callback: null,
          bounds: this.bounds,
        });
      }, 1000);
    },
    distanceRadius: {
      handler() {
        this.drawMapCircle();
      },
      immediate: true,
    },
  },
};
</script>
<style scoped>
.no-uppercase {
  text-transform: none;
}
.v-sheet.v-list:not(.v-sheet--outlined) {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%),
    0px 0px 0px 0px rgb(0 0 0 / 12%);
  background: rgb(255, 255, 255, 0.6);
  padding: 0;
}
.v-application .primary--text {
  color: transparent !important;
}
</style>
