<template>
  <div
    class="student-menu"
    :class="{
      'student-menu--guest': isGuest,
      'student-menu--mobile': mobile,
    }"
  >
    <div class="student-menu__div">
      <div class="d-flex justify-start align-center">
        <div class="student-menu__div__main">
          <div class="d-flex align-center">
            <button
              v-if="isGuest"
              height="21"
              color="#2b5bff"
              id="student-select"
              class="student-menu__div__main__select-guest"
              v-t="'header.guest'"
            ></button>
            <v-select
              v-if="!isGuest && !mobile"
              id="student-select"
              class="student-menu__div__main__select"
              hide-details
              :items="studentList"
              :value="currentStudent"
              item-text="name"
              item-value="uuid"
              @change="changeCurrentStudent"
              solo
              dense
              height="31"
              color="#2b5bff"
              v-show="!isGuest"
              :menu-props="{ bottom: true, offsetY: true }"
            >
              <template v-slot:selection="{ item }">
                <span class="student-menu__div__main__text">
                  {{ item.name.split(" ", 1)[0] }}
                </span>
              </template>
            </v-select>
            <div v-if="false" class="d-flex align-center mt-2 mr-2">
              <img
                v-if="hasStudents"
                class="student-menu__div__main__imgs"
                @click="editStudent"
                src="@/assets/iconsPalmira/navigation/edit2.png"
              />
              <img
                v-if="hasStudents"
                class="student-menu__div__main__imgs"
                @click="addStudent"
                src="@/assets/iconsPalmira/navigation/add.png"
              />

              <img
                v-if="canSelectStudent"
                class="student-menu__div__main__imgs"
                style="margin-bottom: 2px"
                @click="openRemoveStudentDialog"
                src="@/assets/iconsPalmira/navigation/delete.png"
              />
            </div>
            <div
              class="student-menu__filter ml-1"
              :class="{
                'student-menu__filter--guest': isGuest,
                'student-menu__filter--mobile': mobile,
              }"
            >
              <map-filter v-if="!showMobileSearchBar" />
            </div>
          </div>
        </div>
        <button
          type="button"
          class="student-menu__div__more"
          @click="openFilters()"
          :class="{
            'student-menu__div__more--selected': showMore,
            'student-menu__div__more--mobile': mobile,
          }"
        >
          {{ $t("header.filters") }}
        </button>
      </div>
      <div class="student-menu__main">
        <search-in-all-schools
          v-if="!mobile || showMobileSearchBar"
          :mobileWidth="showMobileSearchBar"
        />
        <v-icon
          v-if="mobile"
          medium
          :color="showMobileSearchBar ? 'red' : 'white'"
          class="ml-1"
          @click="showMobileSearch"
        >
          mdi-magnify
        </v-icon>
      </div>
    </div>
    <v-dialog
      content-class="remove-student-modal"
      v-model="showRemoveStudentDialog"
      max-width="400"
    >
      <remove-student-modal @remove="removeStudent" @cancel="closeRemoveStudentDialog" />
    </v-dialog>
    <v-dialog
      content-class="dashborad-modal-flow"
      overlay-color="transparent"
      v-model="showStudentRegisterModal"
      max-width="380px"
    >
      <add-student @closeModal="closeAddStudentModal" />
    </v-dialog>
    <v-dialog @click:outside="closeFilter" width="360" v-model="showMore">
      <SchoolsFilter @ready="closeFilter" />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SearchInAllSchools from "@/components/explorer/map/SearchInAllSchools.vue";
import RemoveStudentModal from "@/components/student_register/RemoveStudentModal.vue";
import MapFilter from "@/components/explorer/map/MapFilter.vue";
import AddStudent from "../../shared/AddStudent.vue";
import SchoolsFilter from "@/components/explorer/map/SchoolsFilter.vue";

export default {
  name: "StudentMenu",
  components: {
    SearchInAllSchools,
    RemoveStudentModal,
    MapFilter,
    AddStudent,
    SchoolsFilter,
  },
  data() {
    return {
      editingOrAddingStudent: false,
      showRemoveStudentDialog: false,
      showStudentRegisterModal: false,
      showMobileSearchBar: false,
      step: null,
      subStep: null,
      showMore: false,
    };
  },
  computed: {
    ...mapGetters({
      studentList: "authentication/students",
      currentStudent: "authentication/currentStudent",
      isGuest: "authentication/isGuest",
      artificial: "authentication/artificial",
      cornerBounds: "institutions/cornerBounds",
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    hasStudents() {
      return this.studentList.length > 0;
    },
    canSelectStudent() {
      return ["Explorer", "Schools", "Postulate", "Favorites", "Register Student"].includes(
        this.$route.name
      );
    },
  },
  methods: {
    ...mapActions({
      setCurrentStudent: "authentication/setCurrentStudent",
      retrieveCampuses: "institutions/retrieveCampuses",
      retrieveFavorites: "institutions/retrieveFavorites",
      raiseError: "snackbar/error",
      setWantCreateAccount: "authentication/setWantCreateAccount",
      deleteStudent: "authentication/deleteStudent",
      retrieveExploredCampuses: "institutions/retrieveExploredCampuses",
      retrieveExploredSchoolCard: "institutions/retrieveExploredSchoolCard",
      error: "snackbar/error",
      resetNewStudentStore: "newStudentRegister/resetStore",
      reloadStudents: "authentication/getStudents",
      getStudentData: "newStudentRegister/getStudentData",
      setSteps: "newStudentRegister/setSteps",
      setCurrentStep: "userRegister/setCurrentStep",
    }),
    changeCurrentStudent(studentUUID) {
      const student = this.studentList.find((st) => st.uuid === studentUUID);
      this.setCurrentStudent({ student, hotjar: this.$hj })
        .then(() => this.retrieveFavorites())
        .then(() => this.retrieveExploredCampuses())
        .then(() => this.retrieveExploredSchoolCard())
        .then(() => this.retrieveCampuses({ callback: null, bounds: this.cornerBounds }))
        .catch((error) => this.raiseError(error));
    },
    editStudent() {
      this.getStudentData({
        uuid: this.currentStudent.uuid,
        callback: (data) => {
          this.step = data.last_step_complete;
          this.subStep = data.last_substep_complete;
          this.showStudentRegisterModal = true;
        },
      });
    },
    openFilters() {
      if (this.showMore === false) {
        this.showMore = true;
      } else {
        this.showMore = false;
      }
    },
    closeFilter() {
      this.showMore = false;
    },
    openRemoveStudentDialog() {
      this.showRemoveStudentDialog = true;
    },
    closeRemoveStudentDialog() {
      this.showRemoveStudentDialog = false;
    },
    removeStudent() {
      this.closeRemoveStudentDialog();
      const student = this.deleteStudent({
        uuid: this.currentStudent.uuid,
      });
      if (student) {
        this.changeCurrentStudent(student.uuid);
      } else {
        this.addStudent();
      }
    },
    addStudent() {
      this.showMenu = false;
      if (!this.isGuest) {
        this.resetNewStudentStore({
          callback: () => {
            this.step = 1;
            this.subStep = 1;
            this.showStudentRegisterModal = true;
          },
        });
      } else {
        this.showLoginDialog = true;
      }
    },
    closeAddStudentModal() {
      this.reloadStudents({
        callback: () => {
          this.showStudentRegisterModal = false;
        },
      });
    },
    showMobileSearch() {
      if (this.showMobileSearchBar) {
        this.showMobileSearchBar = false;
        return this.showMobileSearchBar;
      }
      this.showMobileSearchBar = true;
      return this.showMobileSearchBar;
    },
  },
  watch: {
    "$i18n.locale": {
      handler(locale) {
        localStorage.setItem("locale-explorer", locale);
      },
    },
    $route: {
      handler(to) {
        const editingOrAddingStudent = to.path === "/register_student";
        this.editingOrAddingStudent = editingOrAddingStudent;
      },
      immediate: true,
    },
    showStudentRegisterModal: {
      handler(newValue) {
        if (newValue === false) {
          this.setSteps({
            callback: () =>
              this.reloadStudents({
                callback: () => this.resetNewStudentStore({}),
              }),
          });
        }
      },
    },
  },
};
</script>

<style>
.v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
  min-height: 31px;
}
</style>
