<template>
  <div>
    <section style="font-family: Roboto" class="pdf-item">
      <div class="pdf-header">
        <div class="pdf-mime-section">
          <div class="pdf-mime-logos">
            <img src="@/assets/mime-long.svg" class="pdf-mime-logo" />
          </div>
          <div class="pdf-minstry-logo-div">
            <img src="@/assets/logo.png" class="pdf-minstry-logo" />
            <div class="pdf-date">
              {{ todayDate }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="pdf-section-step2-title mt-4">
          {{ $t("downlodable-pdf.title") + studentName }}
        </div>
        <div class="pdf-section-step2-registration">
          <div v-if="false" class="pdf-section-step2-cotainer-1">
            <div class="pdf-section-step2-inner-div">
              <div class="pdf-section-step2-dot">.</div>
              <div>{{ $t("downlodable-pdf.enrollment-title") }}</div>
            </div>
            <div class="pdf-section-step2-data">{{ previouslyEnrolledName }}</div>
            <div class="pdf-section-step2-inner-div">
              <div class="pdf-section-step2-dot">.</div>
              <div>{{ $t("downlodable-pdf.priority.neep-title") }}</div>
            </div>
            <div class="pdf-section-step2-data">{{ neepPriority }}</div>
            <div class="pdf-section-step2-inner-div">
              <div class="pdf-section-step2-dot">.</div>
              <div>{{ $t("downlodable-pdf.priority.prioritized-title") }}</div>
            </div>
            <div class="pdf-section-step2-data">{{ saePriority }}</div>
            <div class="pdf-section-step2-inner-div">
              <div class="pdf-section-step2-dot">.</div>
              <div>{{ $t("downlodable-pdf.priority.comeFromPublicEducation-title") }}</div>
            </div>
            <div class="pdf-section-step2-data">{{ previouslyEnrolled }}</div>
          </div>
          <div v-if="false" class="pdf-section-step2-cotainer-2">
            <div class="pdf-section-step2-inner-div">
              <div class="pdf-section-step2-dot">.</div>
              <div>{{ $t("downlodable-pdf.priority.parentsWorkInPublicEducation-title") }}</div>
            </div>
            <div class="pdf-section-step2-data">{{ parentsInSchool }}</div>
            <div class="pdf-section-step2-inner-div">
              <div class="pdf-section-step2-dot">.</div>
              <div>{{ $t("downlodable-pdf.priority.hasSiblingInPublicEducation-title") }}</div>
            </div>
            <div class="pdf-section-step2-data">{{ siblingPriority }}</div>
            <div v-show="siblingPriority === 'Si'" class="pdf-section-step2-data"></div>
          </div>
        </div>
      </div>
      <div v-if="false" class="pdf-summary-section">
        <div class="pdf-summary-section-title">{{ $t("downlodable-pdf.summary.title") }}</div>
        <div class="pdf-summary-section-content">
          <div>{{ nearbyInstitutionsTotal }}</div>
          <div class="pdf-summary-data">
            {{ $t("downlodable-pdf.summary.establishments") }}
          </div>
        </div>
        <div class="pdf-summary-section-content">
          <div>{{ institutionsCategoryHighOrMiddleFree }}</div>
          <div class="pdf-summary-data">
            {{ $t("downlodable-pdf.summary.free-perfomance-text") }}
          </div>
        </div>
        <div class="pdf-summary-section-content">
          <div>{{ sepInstitutions }}</div>
          <div class="pdf-summary-data-finale">
            {{ $t("downlodable-pdf.summary.seep-text") }}
          </div>
        </div>
      </div>
      <div class="pdf-example-section">
        <div class="pdf-example-section-content">
          <div class="pdf-example-section-title">
            {{ $t("downlodable-pdf.example.title") }}
          </div>
          <div class="pdf-example-section-info">
            {{ $t("downlodable-pdf.example.text2") }}
          </div>
        </div>
        <div class="pdf-example-section-info-div">
          <div class="pdf-example-section-info">
            <strong class="pdf-example-section-dot">.</strong>
            <div style="margin-left: 5px; margin-top: 5px">
              {{ $t("downlodable-pdf.example.payment") }}
              <strong class="pdf-example-section-range1">
                {{ $t("downlodable-pdf.example.payment-range1") }}
              </strong>
              /
              <strong class="pdf-example-section-range2">
                {{ $t("downlodable-pdf.example.payment-range2") }}
              </strong>
              /
              <strong class="pdf-example-section-range3">
                {{ $t("downlodable-pdf.example.payment-range3") }}
              </strong>
              /
              <strong class="pdf-example-section-range4">
                {{ $t("downlodable-pdf.example.payment-range4") }}
              </strong>
            </div>
          </div>
          <div class="pdf-example-section-info-examples">
            <strong class="pdf-example-section-dot">.</strong>
            <div style="margin-left: 5px; margin-top: 4px">
              {{ $t("downlodable-pdf.example.performance") }}
              <strong class="pdf-example-section-range1">
                {{ $t("downlodable-pdf.example.performance-range1") }}
              </strong>
              /
              <strong class="pdf-example-section-range2">
                {{ $t("downlodable-pdf.example.performance-range2") }}
              </strong>
              /
              <strong class="pdf-example-section-range3">
                {{ $t("downlodable-pdf.example.performance-range3") }}
              </strong>
              /
              <strong class="pdf-example-section-range4">
                {{ $t("downlodable-pdf.example.performance-range4") }}
              </strong>
            </div>
          </div>
          <div class="pdf-example-section-info-examples">
            <strong class="pdf-example-section-dot">.</strong>
            <div style="margin-left: 5px; margin-top: 4px">
              {{ $t("downlodable-pdf.example.distance") }}
              <strong class="pdf-example-section-range1">
                {{ $t("downlodable-pdf.example.distance-range1") }}
              </strong>
              /
              <strong class="pdf-example-section-range2">
                {{ $t("downlodable-pdf.example.distance-range2") }}
              </strong>
              /
              <strong class="pdf-example-section-range3">
                {{ $t("downlodable-pdf.example.distance-range3") }}
              </strong>
              /
              <strong class="pdf-example-section-range4">
                {{ $t("downlodable-pdf.example.distance-range4") }}
              </strong>
              /
            </div>
          </div>
          <div class="pdf-example-section-info-examples">
            <strong class="pdf-example-section-dot">.</strong>
            <div style="margin-left: 5px; margin-top: 4px">
              {{ $t("downlodable-pdf.example.ratex") }}
              <strong class="pdf-example-section-range1">
                {{ $t("downlodable-pdf.example.ratex-range1") }}
              </strong>
              /
              <strong class="pdf-example-section-range2">
                {{ $t("downlodable-pdf.example.ratex-range2") }}
              </strong>
              /
              <strong class="pdf-example-section-range3">
                {{ $t("downlodable-pdf.example.ratex-range3") }}
              </strong>
              /
              <strong class="pdf-example-section-range4">
                {{ $t("downlodable-pdf.example.ratex-range4") }}
              </strong>
            </div>
          </div>
        </div>
      </div>
      <div v-if="false" class="pdf-school-section-table-features-titles">
        <div class="pdf-school-section-features-div">
          {{ $t("downlodable-pdf.table-content.priority-title") }}
        </div>
        <div class="pdf-school-section-features-div">
          {{ $t("downlodable-pdf.table-content.professional-technician") }}
        </div>
        <div class="pdf-school-section-features-div">
          {{ $t("downlodable-pdf.table-content.student-title") }}
        </div>
      </div>
      <div class="pdf-schools-section" v-for="(school, index) in favoriteList1" :key="school.uuid">
        <div class="pdf-schools-section-content">
          <div class="pdf-schools-section-content-info">
            <div class="pdf-schools-section-content-info-div">
              <div class="pdf-schools-section-index">{{ index + 1 }}</div>
              <div>
                <div>
                  <strong>{{ school.campus_name }}</strong>
                </div>
                <div class="pdf-schools-section-content-details">
                  <div style="margin-right: 1px">{{ school.sector.sector_name }}</div>
                  /
                  <div style="margin: 0 1px">{{ school.programs[0].gender.gender_name }}</div>
                  /
                  <div style="margin-left: 1px">{{ schoolGrades(school.uuid) }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="pdf-schools-section-content-table">
            <div
              class="pdf-school-section-table-info"
              :style="schoolPriceBackgroundColor(school.payment.default)"
            >
              <div class="pdf-school-section-logo">
                <div class="pdf-school-section-div" v-show="school.payment.default == 'Gratuito'">
                  <img
                    src="@/assets/iconsChile/campusDetail/price-free.svg"
                    class="pdf-school-section-logo-free"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="school.payment.default == '$1.000 a $50.000'"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/price-10-50.svg"
                    class="pdf-school-section-logo-price"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="school.payment.default == '$50.001 a $100.000'"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/price-50-100.svg"
                    class="pdf-school-section-logo-price"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="school.payment.default == 'Más de $100.000'"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/price-100.svg"
                    class="pdf-school-section-logo-price"
                  />
                </div>
              </div>
              {{ school.payment.default }}
            </div>
            <div
              class="pdf-school-section-table-info"
              :style="schoolPerformanceBackgroundColor(school.performance_set[0])"
            >
              <div class="pdf-school-section-logo">
                <div
                  class="pdf-school-section-div"
                  v-show="hasPerformance(school.performance_set[0]) == 'Alto'"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/performance-high.svg"
                    class="pdf-school-section-logo-arrows"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="hasPerformance(school.performance_set[0]) == 'Medio'"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/performance-middle.svg"
                    class="pdf-school-section-logo-arrows-svg"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="hasPerformance(school.performance_set[0]) == 'Medio-Bajo'"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/performance-middle-low.svg"
                    class="pdf-school-section-logo-arrows-svg"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="hasPerformance(school.performance_set[0]) == 'Insuficiente'"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/performance-insufficient.svg"
                    class="pdf-school-section-logo-arrows-svg"
                  />
                </div>
              </div>
              <div v-if="hasLevel(school.performance_set[0])">
                {{ school.performance_set[0].qualitycateglevel.qualitycateglevel_name }}
              </div>
              <div v-else>N/A</div>
            </div>
            <div
              class="pdf-school-section-table-info"
              :style="schoolCarDistanceBackgroundColor(school)"
            >
              <div class="pdf-school-section-logo">
                <div class="pdf-school-section-div" v-show="distanceNumber(distance(school)) <= 1">
                  <img
                    src="@/assets/iconsChile/campusDetail/distance-person-low.svg"
                    class="pdf-school-section-logo-distance"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="
                    distanceNumber(distance(school)) <= 2 && distanceNumber(distance(school)) > 1
                  "
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/distance-person-middle.svg"
                    class="pdf-school-section-logo-distance"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="
                    distanceNumber(distance(school)) <= 5 && distanceNumber(distance(school)) > 2
                  "
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/distance-car-middle-high.svg"
                    class="pdf-school-section-logo-distance"
                  />
                </div>
                <div class="pdf-school-section-div" v-show="distanceNumber(distance(school)) > 5">
                  <img
                    src="@/assets/iconsChile/campusDetail/distance-car-high.svg"
                    class="pdf-school-section-logo-distance"
                  />
                </div>
              </div>
              {{ distance(school) }}
            </div>
            <div
              v-if="true"
              class="pdf-school-section-table-info"
              :style="schoolRatexBackgroundColor(ratexIndexes[index])"
            >
              <div class="pdf-school-section-logo">
                <div class="pdf-school-section-div" v-show="ratexIndexes[index] > 0.75">
                  <img
                    src="@/assets/iconsChile/campusDetail/admission-high.svg"
                    class="pdf-school-section-logo-pie-chart"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="ratexIndexes[index] > 0.5 && ratexIndexes[index] < 0.75"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/admission-middle.svg"
                    class="pdf-school-section-logo-pie-chart"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="ratexIndexes[index] > 0.25 && ratexIndexes[index] < 0.5"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/admission-middle-low.svg"
                    class="pdf-school-section-logo-pie-chart"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="ratexIndexes[index] < 0.25 && ratexIndexes[index] !== null"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/admission-low.svg"
                    class="pdf-school-section-logo-pie-chart"
                  />
                </div>
              </div>
              <div v-if="hasRatex(ratexIndexes[index])">
                {{ Math.round(ratexIndexes[index] * 100) + "%" }}
              </div>
              <div v-else>N/A</div>
            </div>
            <div v-if="false" class="pdf-school-section-table-features"></div>
            <div v-if="false" class="pdf-school-section-table-features"></div>
            <div v-if="false" class="pdf-school-section-table-features"></div>
          </div>
        </div>
      </div>
      <div
        :class="favoriteList1.length >= 9 ? 'html2pdf__page-break' : ''"
        style="margin-bottom: 40px"
      ></div>
      <div
        class="pdf-schools-section"
        v-show="favoriteList2"
        v-for="(school, index) in favoriteList2"
        :key="school.uuid"
      >
        <div class="pdf-schools-section-content">
          <div class="pdf-schools-section-content-info">
            <div class="pdf-schools-section-content-info-div">
              <div class="pdf-schools-section-index">{{ index + 10 }}</div>
              <div>
                <div>
                  <strong>{{ school.campus_name }}</strong>
                </div>
                <div class="pdf-schools-section-content-details">
                  <div style="margin-right: 1px">{{ school.sector.sector_name }}</div>
                  /
                  <div style="margin: 0 1px">{{ school.programs[0].gender.gender_name }}</div>
                  /
                  <div style="margin-left: 1px">{{ schoolGrades(school.uuid) }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="pdf-schools-section-content-table">
            <div
              class="pdf-school-section-table-info"
              :style="schoolPriceBackgroundColor(school.payment.default)"
            >
              <div class="pdf-school-section-logo">
                <div class="pdf-school-section-div" v-show="school.payment.default == 'Gratuito'">
                  <img
                    src="@/assets/iconsChile/campusDetail/price-free.svg"
                    class="pdf-school-section-logo-free"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="school.payment.default == '$1.000 a $50.000'"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/price-10-50.svg"
                    class="pdf-school-section-logo-price"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="school.payment.default == '$50.001 a $100.000'"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/price-50-100.svg"
                    class="pdf-school-section-logo-price"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="school.payment.default == 'Más de $100.000'"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/price-100.svg"
                    class="pdf-school-section-logo-price"
                  />
                </div>
              </div>
              {{ school.payment.default }}
            </div>
            <div
              class="pdf-school-section-table-info"
              :style="schoolPerformanceBackgroundColor(school.performance_set[0])"
            >
              <div class="pdf-school-section-logo">
                <div
                  class="pdf-school-section-div"
                  v-show="hasPerformance(school.performance_set[0]) == 'Alto'"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/performance-high.svg"
                    class="pdf-school-section-logo-arrows"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="hasPerformance(school.performance_set[0]) == 'Medio'"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/performance-middle.svg"
                    class="pdf-school-section-logo-arrows-svg"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="hasPerformance(school.performance_set[0]) == 'Medio-Bajo'"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/performance-middle-low.svg"
                    class="pdf-school-section-logo-arrows-svg"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="hasPerformance(school.performance_set[0]) == 'Insuficiente'"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/performance-insufficient.svg"
                    class="pdf-school-section-logo-arrows-svg"
                  />
                </div>
              </div>
              <div v-if="hasLevel(school.performance_set[0])">
                {{ school.performance_set[0].qualitycateglevel.qualitycateglevel_name }}
              </div>
              <div v-else>N/A</div>
            </div>
            <div
              class="pdf-school-section-table-info"
              :style="schoolCarDistanceBackgroundColor(school)"
            >
              <div class="pdf-school-section-logo">
                <div class="pdf-school-section-div" v-show="distanceNumber(distance(school)) <= 1">
                  <img
                    src="@/assets/iconsChile/campusDetail/distance-person-low.svg"
                    class="pdf-school-section-logo-distance"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="
                    distanceNumber(distance(school)) <= 2 && distanceNumber(distance(school)) > 1
                  "
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/distance-person-middle.svg"
                    class="pdf-school-section-logo-distance"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="
                    distanceNumber(distance(school)) <= 5 && distanceNumber(distance(school)) > 2
                  "
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/distance-car-middle-high.svg"
                    class="pdf-school-section-logo-distance"
                  />
                </div>
                <div class="pdf-school-section-div" v-show="distanceNumber(distance(school)) > 5">
                  <img
                    src="@/assets/iconsChile/campusDetail/distance-car-high.svg"
                    class="pdf-school-section-logo-distance"
                  />
                </div>
              </div>
              {{ distance(school) }}
            </div>
            <div
              v-if="true"
              class="pdf-school-section-table-info"
              :style="schoolRatexBackgroundColor(ratexIndexes[index + 8])"
            >
              <div class="pdf-school-section-logo">
                <div class="pdf-school-section-div" v-show="ratexIndexes[index + 9] > 0.75">
                  <img
                    src="@/assets/iconsChile/campusDetail/admission-high.svg"
                    class="pdf-school-section-logo-pie-chart"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="ratexIndexes[index + 9] > 0.5 && ratexIndexes[index + 9] < 0.75"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/admission-middle.svg"
                    class="pdf-school-section-logo-pie-chart"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="ratexIndexes[index + 9] > 0.25 && ratexIndexes[index + 9] < 0.5"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/admission-middle-low.svg"
                    class="pdf-school-section-logo-pie-chart"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="ratexIndexes[index + 9] < 0.25 && ratexIndexes[index + 9] !== null"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/admission-low.svg"
                    class="pdf-school-section-logo-pie-chart"
                  />
                </div>
              </div>
              <div v-if="hasRatex(ratexIndexes[index])">
                {{ Math.round(ratexIndexes[index] * 100) + "%" }}
              </div>
              <div v-else>N/A</div>
            </div>
            <div v-if="false" class="pdf-school-section-table-features"></div>
            <div v-if="false" class="pdf-school-section-table-features"></div>
            <div v-if="false" class="pdf-school-section-table-features"></div>
          </div>
        </div>
      </div>
      <div
        :class="favoriteList2.length >= 9 ? 'html2pdf__page-break' : ''"
        style="margin-bottom: 50px"
      ></div>
      <div
        class="pdf-schools-section"
        v-show="favoriteList3"
        v-for="(school, index) in favoriteList3"
        :key="school.uuid"
      >
        <div class="pdf-schools-section-content">
          <div class="pdf-schools-section-content-info">
            <div class="pdf-schools-section-content-info-div">
              <div class="pdf-schools-section-index">{{ index + 19 }}</div>
              <div>
                <div>
                  <strong>{{ school.campus_name }}</strong>
                </div>
                <div class="pdf-schools-section-content-details">
                  <div style="margin-right: 1px">{{ school.sector.sector_name }}</div>
                  /
                  <div style="margin: 0 1px">{{ school.programs[0].gender.gender_name }}</div>
                  /
                  <div style="margin-left: 1px">{{ schoolGrades(school.uuid) }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="pdf-schools-section-content-table">
            <div
              class="pdf-school-section-table-info"
              :style="schoolPriceBackgroundColor(school.payment.default)"
            >
              <div class="pdf-school-section-logo">
                <div class="pdf-school-section-div" v-show="school.payment.default == 'Gratuito'">
                  <img
                    src="@/assets/iconsChile/campusDetail/price-free.svg"
                    class="pdf-school-section-logo-free"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="school.payment.default == '$1.000 a $50.000'"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/price-10-50.svg"
                    class="pdf-school-section-logo-price"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="school.payment.default == '$50.001 a $100.000'"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/price-50-100.svg"
                    class="pdf-school-section-logo-price"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="school.payment.default == 'Más de $100.000'"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/price-100.svg"
                    class="pdf-school-section-logo-price"
                  />
                </div>
              </div>
              {{ school.payment.default }}
            </div>
            <div
              class="pdf-school-section-table-info"
              :style="schoolPerformanceBackgroundColor(school.performance_set[0])"
            >
              <div class="pdf-school-section-logo">
                <div
                  class="pdf-school-section-div"
                  v-show="hasPerformance(school.performance_set[0]) == 'Alto'"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/performance-high.svg"
                    class="pdf-school-section-logo-arrows"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="hasPerformance(school.performance_set[0]) == 'Medio'"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/performance-middle.svg"
                    class="pdf-school-section-logo-arrows-svg"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="hasPerformance(school.performance_set[0]) == 'Medio-Bajo'"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/performance-middle-low.svg"
                    class="pdf-school-section-logo-arrows-svg"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="hasPerformance(school.performance_set[0]) == 'Insuficiente'"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/performance-insufficient.svg"
                    class="pdf-school-section-logo-arrows-svg"
                  />
                </div>
              </div>
              <div v-if="hasLevel(school.performance_set[0])">
                {{ school.performance_set[0].qualitycateglevel.qualitycateglevel_name }}
              </div>
              <div v-else>N/A</div>
            </div>
            <div
              class="pdf-school-section-table-info"
              :style="schoolCarDistanceBackgroundColor(school)"
            >
              <div class="pdf-school-section-logo">
                <div class="pdf-school-section-div" v-show="distanceNumber(distance(school)) <= 1">
                  <img
                    src="@/assets/iconsChile/campusDetail/distance-person-low.svg"
                    class="pdf-school-section-logo-distance"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="
                    distanceNumber(distance(school)) <= 2 && distanceNumber(distance(school)) > 1
                  "
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/distance-person-middle.svg"
                    class="pdf-school-section-logo-distance"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="
                    distanceNumber(distance(school)) <= 5 && distanceNumber(distance(school)) > 2
                  "
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/distance-car-middle-high.svg"
                    class="pdf-school-section-logo-distance"
                  />
                </div>
                <div class="pdf-school-section-div" v-show="distanceNumber(distance(school)) > 5">
                  <img
                    src="@/assets/iconsChile/campusDetail/distance-car-high.svg"
                    class="pdf-school-section-logo-distance"
                  />
                </div>
              </div>
              {{ distance(school) }}
            </div>
            <div
              v-if="true"
              class="pdf-school-section-table-info"
              :style="schoolRatexBackgroundColor(ratexIndexes[index + 18])"
            >
              <div class="pdf-school-section-logo">
                <div class="pdf-school-section-div" v-show="ratexIndexes[index + 18] > 0.75">
                  <img
                    src="@/assets/iconsChile/campusDetail/admission-high.svg"
                    class="pdf-school-section-logo-pie-chart"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="ratexIndexes[index + 18] > 0.5 && ratexIndexes[index + 18] < 0.75"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/admission-middle.svg"
                    class="pdf-school-section-logo-pie-chart"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="ratexIndexes[index + 18] > 0.25 && ratexIndexes[index + 18] < 0.5"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/admission-middle-low.svg"
                    class="pdf-school-section-logo-pie-chart"
                  />
                </div>
                <div
                  class="pdf-school-section-div"
                  v-show="ratexIndexes[index + 18] < 0.25 && ratexIndexes[index + 18] !== null"
                >
                  <img
                    src="@/assets/iconsChile/campusDetail/admission-low.svg"
                    class="pdf-school-section-logo-pie-chart"
                  />
                </div>
              </div>
              <div v-if="hasRatex(ratexIndexes[index])">
                {{ Math.round(ratexIndexes[index] * 100) + "%" }}
              </div>
              <div v-else>N/A</div>
            </div>
            <div v-if="false" class="pdf-school-section-table-features"></div>
            <div v-if="false" class="pdf-school-section-table-features"></div>
            <div v-if="false" class="pdf-school-section-table-features"></div>
          </div>
        </div>
      </div>
      <div
        :class="favoriteList3.length >= 9 ? 'html2pdf__page-break' : ''"
        style="margin-bottom: 50px"
      ></div>
      <div
        class="pdf-schools-section"
        v-show="favoriteList4"
        v-for="(school, index) in favoriteList4"
        :key="school.uuid"
      >
        <div class="pdf-schools-section-content">
          <div class="pdf-schools-section-content-info">
            <div class="pdf-schools-section-content-info-div">
              <div class="pdf-schools-section-index">{{ index + 28 }}</div>
              <div>
                <div>
                  <strong>{{ school.name }}</strong>
                </div>
                <div class="pdf-schools-section-content-details">
                  <div style="margin-right: 1px">{{ school.basic_school_info.type }}</div>
                  /
                  <div style="margin: 0 1px">{{ school.program_sex_type }}</div>
                  /
                  <div style="margin-left: 1px">{{ schoolGrades(school.uuid) }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="pdf-schools-section-content-table">
            <div
              class="pdf-school-section-table-info"
              :style="schoolPriceBackgroundColor(school.price)"
            >
              <div class="pdf-school-section-logo">
                <div class="pdf-school-section-div" v-show="school.price == 'Gratuito'">
                  <img
                    src="@/assets/iconsChile/campusDetail/price-free.svg"
                    class="pdf-school-section-logo-free"
                  />
                </div>
                <div class="pdf-school-section-div" v-show="school.price == '$1.000 a $50.000'">
                  <img
                    src="@/assets/iconsChile/campusDetail/price-10-50.svg"
                    class="pdf-school-section-logo-price"
                  />
                </div>
                <div class="pdf-school-section-div" v-show="school.price == '$50.001 a $100.000'">
                  <img
                    src="@/assets/iconsChile/campusDetail/price-50-100.svg"
                    class="pdf-school-section-logo-price"
                  />
                </div>
                <div class="pdf-school-section-div" v-show="school.price == 'Más de $100.000'">
                  <img
                    src="@/assets/iconsChile/campusDetail/price-100.svg"
                    class="pdf-school-section-logo-price"
                  />
                </div>
              </div>
              {{ school.price }}
            </div>
            <div
              class="pdf-school-section-table-info"
              :style="schoolPerformanceBackgroundColor(school.level)"
            >
              <div class="pdf-school-section-logo">
                <div class="pdf-school-section-div" v-show="school.level == 'Alto'">
                  <img
                    src="@/assets/iconsChile/campusDetail/performance-high.svg"
                    class="pdf-school-section-logo-arrows"
                  />
                </div>
                <div class="pdf-school-section-div" v-show="school.level == 'Medio'">
                  <img
                    src="@/assets/iconsChile/campusDetail/performance-middle.svg"
                    class="pdf-school-section-logo-arrows-svg"
                  />
                </div>
                <div class="pdf-school-section-div" v-show="school.level == 'Medio-Bajo'">
                  <img
                    src="@/assets/iconsChile/campusDetail/performance-middle-low.svg"
                    class="pdf-school-section-logo-arrows-svg"
                  />
                </div>
                <div class="pdf-school-section-div" v-show="school.level == 'Insuficiente'">
                  <img
                    src="@/assets/iconsChile/campusDetail/performance-insufficient.svg"
                    class="pdf-school-section-logo-arrows-svg"
                  />
                </div>
              </div>
              {{ school.level }}
            </div>
            <div
              class="pdf-school-section-table-info"
              :style="schoolCarDistanceBackgroundColor(school)"
            >
              <div class="pdf-school-section-logo">
                <div class="pdf-school-section-div" v-show="school.level == 'Alto'">
                  <img
                    src="@/assets/iconsChile/campusDetail/distance-person-low.svg"
                    class="pdf-school-section-logo-distance"
                  />
                </div>
                <div class="pdf-school-section-div" v-show="school.level == 'Medio'">
                  <img
                    src="@/assets/iconsChile/campusDetail/distance-person-middle.svg"
                    class="pdf-school-section-logo-distance"
                  />
                </div>
                <div class="pdf-school-section-div" v-show="school.level == 'Medio-Bajo'">
                  <img
                    src="@/assets/iconsChile/campusDetail/distance-car-middle-high.svg"
                    class="pdf-school-section-logo-distance"
                  />
                </div>
                <div class="pdf-school-section-div" v-show="school.level == 'Insuficiente'">
                  <img
                    src="@/assets/iconsChile/campusDetail/distance-car-high.svg"
                    class="pdf-school-section-logo-distance"
                  />
                </div>
              </div>
              {{ distance(school) }}
            </div>
            <div
              class="pdf-school-section-table-info"
              :style="schoolRatexBackgroundColor(school.ratex)"
            >
              <div class="pdf-school-section-logo">
                <div class="pdf-school-section-div" v-show="school.ratex == 4">
                  <img
                    src="@/assets/iconsChile/campusDetail/admission-high.svg"
                    class="pdf-school-section-logo-pie-chart"
                  />
                </div>
                <div class="pdf-school-section-div" v-show="school.ratex == 3">
                  <img
                    src="@/assets/iconsChile/campusDetail/admission-middle.svg"
                    class="pdf-school-section-logo-pie-chart"
                  />
                </div>
                <div class="pdf-school-section-div" v-show="school.ratex == 2">
                  <img
                    src="@/assets/iconsChile/campusDetail/admission-middle-low.svg"
                    class="pdf-school-section-logo-pie-chart"
                  />
                </div>
                <div class="pdf-school-section-div" v-show="school.ratex == 1">
                  <img
                    src="@/assets/iconsChile/campusDetail/admission-low.svg"
                    class="pdf-school-section-logo-pie-chart"
                  />
                </div>
              </div>
              {{ schoolRatexNames(school.ratex) }}
            </div>
            <div v-if="false" class="pdf-school-section-table-features"></div>
            <div v-if="false" class="pdf-school-section-table-features"></div>
            <div v-if="false" class="pdf-school-section-table-features"></div>
          </div>
        </div>
      </div>
      <div class="pdf-footer">
        <img src="@/assets/Logo_ConsiliumBots.svg" class="pdf-footer-section-logo" />
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import utils from "@/utils/";

export default {
  name: "summaryCampusFavorites",
  data() {
    return {
      institutionsNearby: "",
      previouslyEnrolledName: "",
      previouslyEnrolled: "",
      neepPriority: "",
      saePriority: "",
      parentsInSchool: "",
      siblingPriority: "",
      range1Color: "#11D3D9",
      range2Color: "#0071BC",
      range3Color: "#4139B4",
      range4Color: "#6D3165",
      range5Color: "#BDBDBD",
      favoriteList1: [],
      favoriteList2: [],
      favoriteList3: [],
      favoriteList4: [],
    };
  },
  props: {
    ratexIndexes: {
      type: [Array, Boolean],
      required: true,
    },
  },
  mounted() {
    /*
    if (this.favoriteSchools.length >= 1) {
      const priorities = this.favoriteSchools[0].student_priorities;
      // eslint-disable-next-line
      priorities.enrolled ? this.previouslyEnrolledName = this.student.default_school : this.previouslyEnrolledName = 'No matriculado';
      // eslint-disable-next-line
      priorities.has_neep_priority ? this.neepPriority = 'Si' : this.neepPriority = 'No';
      // eslint-disable-next-line
      priorities.has_sae_priority ? this.saePriority = 'Si' : this.saePriority = 'No';
      // eslint-disable-next-line
      priorities.come_from_public ? this.previouslyEnrolled = 'Si' : this.previouslyEnrolled = 'No';
      // eslint-disable-next-line
      priorities.has_parents_in_school ? this.parentsInSchool = 'Si' : this.parentsInSchool = 'No';
      // eslint-disable-next-line
      priorities.has_sibling_priority ? this.siblingPriority = 'Si' : this.siblingPriority = 'No';
    }
    */
    this.favoriteSchools.forEach((school, index) => {
      if (index <= 8) {
        this.favoriteList1.push(school);
      } else if (index > 8 && index <= 16) {
        this.favoriteList2.push(school);
      } else if (index > 16 && index <= 24) {
        this.favoriteList3.push(school);
      }
    });
  },
  computed: {
    ...mapGetters({
      homeLocation: "authentication/homeLocation",
      student: "authentication/currentStudent",
      favoriteSchools: "institutions/favoriteSchoolsDetails",
    }),

    todayDate() {
      let date = Date.now();
      date = new Date(date);
      // eslint-disable-next-line
      date = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
      return date;
    },
    studentName() {
      return this.student.name;
    },
    nearbyInstitutionsTotal() {
      return this.institutionsNearby.length;
    },
    institutionsCategoryHighOrMiddleFree() {
      const categoryHighOrMiddleFree = [];
      if (this.institutionsNearby) {
        this.institutionsNearby.forEach((institution) => {
          if (
            institution.monthly_payment === "Gratuito" &&
            (institution.performance_category === "Alto" ||
              institution.performance_category === "Medio")
          ) {
            categoryHighOrMiddleFree.push(institution);
          }
        });
      }
      return categoryHighOrMiddleFree.length;
    },
    sepInstitutions() {
      const sepArray = [];
      if (this.institutionsNearby) {
        this.institutionsNearby.forEach((institution) => {
          if (institution.sep === "True") {
            sepArray.push(institution);
          }
        });
      }
      return sepArray.length;
    },
  },
  methods: {
    hasRatex(ratex) {
      if (ratex >= 0) {
        return true;
      }
      return false;
    },
    schoolGrades(idSchool) {
      let gradeRange = "";
      this.favoriteSchools.forEach((institution) => {
        if (institution.uuid === idSchool) {
          // eslint-disable-next-line
          gradeRange =
            "Desde " +
            institution.grades_info.grade_min +
            " a " +
            institution.grades_info.grade_max +
            ".";
        }
      });
      return gradeRange;
    },
    hasPerformance(performance) {
      if (performance) {
        return performance.qualitycateglevel.qualitycateglevel_name;
      }
      return false;
    },
    hasLevel(performance) {
      if (performance) {
        return true;
      }
      return false;
    },
    schoolPriceBackgroundColor(schoolPrice) {
      let color = "";
      if (schoolPrice === "Gratuito") {
        color = this.range1Color;
      } else if (schoolPrice === "$1.000 a $10.000" || schoolPrice === "$1.000 a $50.000") {
        color = this.range2Color;
      } else if (schoolPrice === "10.001 a $100.000" || schoolPrice === "$50.001 a $100.000") {
        color = this.range3Color;
      } else if (schoolPrice === "Más de $100.000") {
        color = this.range4Color;
      } else {
        color = this.range5Color;
      }
      const style = {
        "background-color": color,
      };
      return style;
    },
    schoolPerformanceBackgroundColor(schoolPerformance) {
      let color = "";
      if (schoolPerformance) {
        const schoolPerformanceId = schoolPerformance.qualitycateglevel.id;
        if (schoolPerformanceId === 4) {
          color = this.range1Color;
        } else if (schoolPerformanceId === 3) {
          color = this.range2Color;
        } else if (schoolPerformanceId === 2) {
          color = this.range3Color;
        } else if (schoolPerformanceId === 1) {
          color = this.range4Color;
        }
      } else {
        color = this.range5Color;
      }
      const style = {
        "background-color": color,
      };
      return style;
    },
    schoolCarDistanceBackgroundColor(school) {
      const distance = this.distance(school);
      let finalDistance = distance.split(" ");
      finalDistance = parseFloat(finalDistance[0]);
      let color = "";
      if (finalDistance <= 1) {
        color = this.range1Color;
      } else if (finalDistance <= 2) {
        color = this.range2Color;
      } else if (finalDistance <= 5) {
        color = this.range3Color;
      } else if (finalDistance > 5) {
        color = this.range4Color;
      } else {
        color = this.range5Color;
      }
      const style = {
        "background-color": color,
      };
      return style;
    },
    schoolRatexBackgroundColor(schoolRatex) {
      let color = "";
      if (schoolRatex > 0.75) {
        color = this.range1Color;
      } else if (schoolRatex > 0.5 && schoolRatex <= 0.75) {
        color = this.range2Color;
      } else if (schoolRatex > 0.25 && schoolRatex <= 0.5) {
        color = this.range3Color;
      } else if (schoolRatex < 0.25 && schoolRatex >= 0) {
        color = this.range4Color;
      } else {
        color = this.range5Color;
      }
      const style = {
        "background-color": color,
      };

      return style;
    },
    distance(campus) {
      const campusLocation = {
        lat: campus.location.lat,
        lng: campus.location.lon,
      };
      if (campusLocation && this.homeLocation) {
        const distance = utils.getDistanceInKm(campusLocation, this.homeLocation);
        return `${distance} kms`;
      }
      return null;
    },
    distanceNumber(distance) {
      let finalDistance = distance.split(" ");
      finalDistance = parseFloat(finalDistance[0]);
      return finalDistance;
    },
  },
};
</script>
