<template>
  <v-btn
    :icon="buttonInCampusDetail ? false : true"
    :disabled="disabled"
    :right="right"
    :class="customClass"
    @click.stop="onClick"
  >
    <template v-if="buttonInCampusDetail">
      <img :src="styleButton.icon" width="30px" />
      <span class="ml-1"> {{ styleButton.text }} </span>
    </template>

    <template v-else>
      <img :src="styleButton.icon" width="40px" height="40px" />
    </template>
    <!--
    <template v-else>
      <v-icon :size="inSimulateView ? 11 : 16">
        {{ isFavorite ? "$heartFilled" : "$outlinedHeart" }}
      </v-icon>
    </template>
    
    <v-dialog
      content-class="elevation-0 mx-12"
      overlay-color="transparent"
      v-model="showLoginDialog"
      max-width="400"
    >
      <login-modal
        @close="closeLoginModal"
        @go-register="signUp"
        @go-login="signIn"
      />
    </v-dialog>
    -->
  </v-btn>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "FavoriteButton",
  props: {
    buttonInCampusDetail: {
      type: Boolean,
      default: false,
    },
    isFavorite: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    smallWindow: {
      type: Boolean,
      default: false,
    },
    float: {
      type: Boolean,
      default: false,
    },
    inSimulateView: {
      type: Boolean,
      default: false,
    },
    opaque: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showLoginDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      isGuest: "authentication/isGuest",
    }),
    customClass() {
      let newStyle = "favorite-button-campus-detail";
      if (this.isFavorite && this.buttonInCampusDetail) {
        newStyle = "favorite-button-campus-detail favorite-button-campus-detail--selected";
      }
      const calculatedClass = [
        {
          "fav-small-window": this.smallWindow,
          "over-picture": this.float,
        },
      ];
      const inSimulateViewClass = this.inSimulateView
        ? "favorite-button favorite-button--simulated"
        : "favorite-button";
      if (inSimulateViewClass) {
        calculatedClass.push(inSimulateViewClass);
      }
      if (this.opaque) calculatedClass.push("favorite-button--opaque");

      return this.buttonInCampusDetail ? newStyle : calculatedClass;
    },
    styleButton() {
      let style = {
        // eslint-disable-next-line global-require
        icon: require("@/assets/iconsChile/campusDetail/button2.svg"),
        text: this.$t("campus-details.favorite.add"),
      };
      if (this.isFavorite && !this.buttonInCampusDetail) {
        style = {
          // eslint-disable-next-line global-require
          icon: require("@/assets/iconsChile/campusDetail/button1.png"),
          text: this.$t("campus-details.favorite.add"),
        };
      }
      if (this.isFavorite && this.buttonInCampusDetail) {
        style = {
          // eslint-disable-next-line global-require
          icon: require("@/assets/iconsChile/campusDetail/list_selected.svg"),
          text: this.$t("campus-details.favorite.added"),
        };
      }
      if (!this.isFavorite && this.buttonInCampusDetail) {
        style = {
          // eslint-disable-next-line global-require
          icon: require("@/assets/iconsChile/campusDetail/list_add.svg"),
          text: this.$t("campus-details.favorite.add"),
        };
      }

      return style;
    },
  },
  methods: {
    ...mapActions({
      setWantCreateAccount: "authentication/setWantCreateAccount",
    }),
    onClick() {
      this.$emit("click-favorite");
    },
  },
};
</script>
