var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"video-container",style:({
    '--width': (_vm.width + "px"),
    '--height': (_vm.height + "px"),
  })},[_c('vimeo-player',{ref:"player",staticClass:"video-player",style:({
      '--width': (_vm.width + "px"),
      '--height': (_vm.height + "px"),
      '--vertical-offset': _vm.verticalOffset,
      '--horizontal-offset': _vm.horizontalOffset,
    }),attrs:{"video-id":_vm.videoID,"autoplay":true,"player-height":(_vm.actualHeight + "px"),"player-width":(_vm.actualWidth + "px"),"options":_vm.options},on:{"playing":_vm.onPlaying}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }