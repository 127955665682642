<template>
  <section class="campus-detail__travel-time campus-detail__module mt-2">
    <h3 class="campus-detail__module__title ml-2" v-t="'campus-details.travel_time.title'" />

    <div class="campus-detail__travel-time__from mt-4 py-3 px-5">
      <h3
        class="campus-detail__module__title text-white"
        v-t="
          isGuest ? 'campus-details.travel_time.from' : 'campus-details.travel_time.from_location'
        "
      />

      <div v-if="isGuest" class="d-flex flex-row justify-start align-center my-2">
        <img class="mr-2" src="@/assets/icons/exclamation-circle-white.svg" width="25px" />
        <p class="campus-detail__module__title font-weight-medium text-white">
          <button
            @click="toLogin"
            type="button"
            v-t="'campus-details.travel_time.message_guest.login'"
            class="mr-1 campus-detail__travel-time__border"
          />
          {{ $t("campus-details.travel_time.message_guest.text") }}
        </p>
      </div>

      <p v-else class="campus-detail__module__title font-weight-medium text-white">
        {{ getInfoUser.address }}
      </p>
    </div>

    <div class="campus-detail__module__title font-weight-medium mt-7">
      {{ $t("campus-details.travel_time.how_do") }}
      <span class="campus-detail__module__title font-weight-medium">
        {{ institution.name }}
      </span>
    </div>

    <p
      v-t="'campus-details.travel_time.select_option'"
      class="campus-detail__module__title font-weight-light text-caption mb-2"
    />

    <div class="travel-time__radio--button">
      <template v-for="{ name, time, icon } in listTravelTime">
        <!-- eslint-disable-next-line vue/require-v-for-key -->
        <input
          v-model="selectedTravelTime"
          type="radio"
          :id="name"
          :value="name"
          :disabled="isGuest"
        />
        <!-- eslint-disable-next-line vue/require-v-for-key -->
        <label class="mt-2" :for="name">
          <img :src="icon" />
          <span class="mt-1">
            {{ time }}
          </span>
        </label>
      </template>
    </div>

    <GmapMap
      v-if="!isGuest"
      ref="mapRef"
      :zoom="zoom"
      :center="institution.location"
      class="campus-detail__travel-time__map mt-4"
      :options="{
        disableDefaultUI: true,
        clickableIcons: false,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        styles: mapStyle,
        gestureHandling: 'greedy',
      }"
    >
      <gmap-custom-marker :marker="getInfoUser.location" alignment="top">
        <img src="@/assets/icons/home-icon.svg" width="30px" />
      </gmap-custom-marker>

      <gmap-custom-marker :marker="institution.location" alignment="top">
        <img src="@/assets/icons/institution-icon.svg" width="30px" />
      </gmap-custom-marker>

      <DirectionsRenderer
        v-if="selectedTravelTime"
        :map="$refs"
        :key="keyDirectionsRenderer"
        :travelMode="selectedTravelTime"
        :origin="getInfoUser.location"
        :destination="institution.location"
        :render="listTravelTime[`${selectedTravelTime.toLowerCase()}`].render"
        @response-directions="getResponseDirections"
      />
    </GmapMap>

    <picture class="d-flex flex-row align-middle mt-5">
      <img src="@/assets/icons/exclamation-purple.svg" width="20px" alt="exclamation" />
      <p
        class="campus-detail__travel-time__disclaimer ml-1"
        v-t="'campus-details.travel_time.disclaimer'"
      />
    </picture>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import GmapCustomMarker from "vue2-gmap-custom-marker";
import mapStyle from "@/assets/mapStyle.json";
import DirectionsRenderer from "@/utils/DirectionsRenderer";

export default {
  name: "TravelTime",
  components: {
    DirectionsRenderer,
    GmapCustomMarker,
  },
  props: {
    institution: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedTravelTime: null,
      keyDirectionsRenderer: 0,
      listTravelTime: [],
      mapStyle,
      zoom: 13,
    };
  },
  created() {
    this.setListTravelTime();
  },
  watch: {
    selectedTravelTime() {
      this.keyDirectionsRenderer += 1;
    },
  },
  computed: {
    ...mapGetters({
      homeLocation: "authentication/homeLocation",
      currentStudent: "authentication/currentStudent",
      isGuest: "authentication/isGuest",
    }),
    getInfoUser() {
      const info = {};
      const hasAddress = this.currentStudent.address !== null;
      info.address = hasAddress ? this.currentStudent.address[0].address_details : "";
      info.location = {
        lat: this.homeLocation.lat,
        lng: this.homeLocation.lng,
      };
      return info;
    },
  },
  methods: {
    setListTravelTime() {
      this.listTravelTime = {
        transit: {
          name: "TRANSIT",
          icon: require("@/assets/icons/bus.svg"),
          time: "",
          render: {},
        },
        driving: {
          name: "DRIVING",
          icon: require("@/assets/icons/car.svg"),
          time: "",
          render: {},
        },
        walking: {
          name: "WALKING",
          icon: require("@/assets/icons/walk.svg"),
          time: "",
          render: {},
        },
      };
    },
    getResponseDirections($event) {
      const { routes } = $event;
      const duration = routes[0].legs[0].duration.text;
      const travel = this.selectedTravelTime.toLowerCase();
      // eslint-disable-next-line dot-notation
      this.listTravelTime[`${travel}`].render = $event;
      this.listTravelTime[`${travel}`].time = duration;
    },
    toLogin() {
      this.$router.push({ name: "Login" });
      this.$emit("close-modal");
    },
  },
};
</script>
