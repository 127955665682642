<template>
  <div class="victim-of-armed-conflict_container">
    <div class="victim-of-armed-conflict_title_container">
      <p class="required-field victim-of-armed-conflict_title">
        {{ $t("register.priority.armed_conflict.title") }}
      </p>
    </div>
    <v-container class="victim-of-armed-conflict_question_container">
      <div
        :class="
          mobile ? 'victim-of-armed-conflict_question-mobile' : 'victim-of-armed-conflict_question'
        "
        :style="
          opt ? 'background: #2D10B4; margin-right: 5px;' : 'background: white; margin-right: 5px;'
        "
        @click="changeOpt(true)"
      >
        <p :style="opt ? 'color: white;' : 'color: #2D10B4;'">
          {{ $t("register.priority.armed_conflict.yes_opt") }}
        </p>
      </div>
      <div
        :class="
          mobile ? 'victim-of-armed-conflict_question-mobile' : 'victim-of-armed-conflict_question'
        "
        :style="
          opt === false
            ? 'background: #2D10B4; margin-left: 5px;'
            : 'background: white; margin-left: 5px;'
        "
        @click="changeOpt(false)"
      >
        <p :style="opt === false ? 'color: white;' : 'color: #2D10B4;'">
          {{ $t("register.priority.armed_conflict.no_opt") }}
        </p>
      </div>
    </v-container>
    <div v-show="opt" class="victim-of-armed-conflict_category_container">
      <div class="victim-of-armed-conflict_category_title_container">
        <p class="required-field victim-of-armed-conflict_category_title">
          {{ $t("register.priority.armed_conflict.category") }}
        </p>
      </div>
      <v-select
        class="custom-select custom-select victim-of-armed-conflict_category"
        :background-color="category !== null ? '#2D10B4' : '#FFFFFF'"
        :dark="category !== null ? true : false"
        outlined
        :items="options"
        item-text="category_name"
        item-value="uuid"
        hide-details
        v-model="category"
      />
    </div>
    <div class="btn_container">
      <v-btn icon @click="backMethod">
        <v-img src="@/assets/iconsPalmira/student_register/icons/back.svg" />
      </v-btn>
      <base-button v-if="isDisabled" class="btn-skip" @click="skipStep">
        {{ $t("register.priority.armed_conflict.skip_btn") }}
      </base-button>
      <base-button v-else class="btn-continue" @click="continueMethod">
        {{ $t("register.priority.armed_conflict.continue_btn") }}
      </base-button>
    </div>
    <v-dialog
      content-class="elevation-0"
      overlay-color="transparent"
      v-model="showSkipModal"
      max-width="370"
    >
      <warning-modal
        @skip="skipConfirmMethod"
        @complete="completeMethod"
        :title="$t('register.priority.armed_conflict.warning_modal.title')"
        :text="$t('register.priority.armed_conflict.warning_modal.text')"
        color="#FC010A"
        skipModal
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/explorer/base/BaseButton.vue";
import WarningModal from "../WarningModal.vue";

export default {
  name: "VictimOfArmedConflict",
  components: {
    BaseButton,
    WarningModal,
  },
  data() {
    return {
      opt: null,
      category: null,
      showSkipModal: false,
      firstUpdate: true,
    };
  },
  mounted() {
    this.opt = this.optionStore;
    this.category = this.categoryStore;
  },
  methods: {
    ...mapActions({
      setPriorityStepFour: "newStudentRegister/setPriorityStepFour",
    }),
    continueMethod() {
      const data = {
        opt: this.opt,
        category: this.category,
      };
      this.setPriorityStepFour(data);
      this.$emit("nextStep");
    },
    backMethod() {
      this.$emit("backStep");
    },
    changeOpt(value) {
      this.opt = value;
    },
    skipStep() {
      this.showSkipModal = true;
    },
    skipConfirmMethod() {
      this.$emit("skipStep");
    },
    completeMethod() {
      this.showSkipModal = false;
    },
  },
  computed: {
    ...mapGetters({
      optionStore: "newStudentRegister/victimOfArmedConflictPriority",
      categoryStore: "newStudentRegister/victimOfArmedConflictCategory",
      options: "options/armedConflictTypes",
    }),
    isDisabled() {
      return this.opt === null || (this.opt && this.category === null);
    },
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
  },
  watch: {
    opt: {
      handler(newVal) {
        if (newVal) {
          if (!this.firstUpdate) {
            this.category = null;
          } else {
            this.firstUpdate = false;
          }
        }
      },
    },
  },
};
</script>
