<template>
  <div id="panels" class="campus-detail__panels">
    <v-expansion-panels accordion flat>
      <v-expansion-panel
        v-for="({ name, icon, title, iconSize, isEnable }, index) in panels"
        :key="index"
        :id="name"
        :disabled="!isEnable"
        class="mt-2 campus-detail__panels--disabled"
        :style="index === 0 ? 'background-color: #2B5BFF;' : ''"
      >
        <v-expansion-panel-header @click="trackMixPanel(name)" class="pb-0 px-0 pt-0">
          <picture class="d-flex align-center">
            <img
              :src="icon"
              :width="iconSize"
              :class="{ 'campus-detail__panels__image-disable': !isEnable }"
            />
            <h3
              class="campus-detail__panels__title ml-6"
              :class="{
                'campus-detail__panels__title--white': isAdmissionPanel(name),
                'campus-detail__panels__title--gray': !isEnable,
              }"
            >
              {{ title }}
            </h3>
          </picture>
          <template v-slot:actions>
            <v-icon :color="isAdmissionPanel(name) ? 'white' : '#2B5BFF'"> $expand </v-icon>
          </template>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <!-- ADMISSION -->
          <Admission
            v-if="name === 'admission'"
            :isSAE="campusDetail.campus_admission_system[0].admission_system_label.id"
            :minGrade="campusDetail.grade_min"
            :schoolPriority="campusDetail.campus_priority"
            :campus="campusDetail"
            :isGuest="isGuest"
            :requiredTests="campusDetail.required_tests"
            :requiredDocuments="campusDetail.required_documents"
            :linkAdmission="getLinkAdmission"
            @closeDialog="goBack"
          />
          <!-- :isSAE="campusDetail.campus_admission_system[0].admission_system_label.id"
          :isRegistered="!isGuest"
            :vacancies="campusDetail.institutionmember_campus" -->

          <!-- LEADERSHIP
          <Leadership
            v-if="name === 'leadership'"
            :contacts="campusDetail.institutioncontact_campus"
          />
          -->
          <!-- TEAM -->
          <Teachers
            v-if="name === 'team'"
            :team="campusDetail.campus_stats"
            :others="campusDetail.support_campus"
          />

          <!-- STUDENTS -->
          <Students v-if="name === 'students'" :members="campusDetail.institutionmember_campus" />

          <!-- INFRASTRUCTURE -->
          <Infrastructure
            v-if="name === 'infrastructure'"
            :infrastructures="campusDetail.infraestructure_campus"
          />

          <!-- SPORTS -->
          <Sports v-if="name === 'sports'" :sports="campusDetail.sport_campus" />

          <!-- EXTRACURRICULAR -->
          <Extracurriculars
            v-if="name === 'extracurriculars'"
            :extracurriculars="campusDetail.extraactivity_campus"
          />
          <Language v-if="name === 'language'" :languages="campusDetail.language_campus" />
          <!-- Price and costs  -->
          <Price v-if="name === 'price'" />

          <!-- Price and costs  -->

          <Performance v-if="name === 'performance'" />

          <!-- Price and costs  -->
          <Vacancies v-if="name === 'vacancy'" />

          <!-- Price and costs  -->
          <Teachers v-if="name === 'teachers'" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Infrastructure from "@/components/explorer/general/campus_detail/panels/Infrastructure.vue";
import Sports from "@/components/explorer/general/campus_detail/panels/Sports.vue";
import Students from "@/components/explorer/general/campus_detail/panels/Students.vue";
import Extracurriculars from "@/components/explorer/general/campus_detail/panels/Extracurriculars.vue";
import Language from "@/components/explorer/general/campus_detail/panels/Language.vue";
import Admission from "@/components/explorer/general/campus_detail/panels/Admission.vue";
// import Leadership from '@/components/explorer/general/campus_detail/panels/Leadership.vue';
import Price from "@/components/explorer/general/campus_detail/panels/Price.vue";
import Performance from "@/components/explorer/general/campus_detail/panels/Performance.vue";
import Vacancies from "@/components/explorer/general/campus_detail/panels/Vacancies.vue";
import Teachers from "@/components/explorer/general/campus_detail/panels/Teachers.vue";

export default {
  name: "CampusDetailPanels",
  components: {
    Infrastructure,
    Sports,
    Extracurriculars,
    Language,
    // Team,
    Students,
    Admission,
    // Leadership,
    Price,
    Performance,
    Vacancies,
    Teachers,
  },
  data() {
    return {
      panels: [],
    };
  },
  created() {
    this.setPanelsCampusDetail();
  },
  computed: {
    ...mapGetters({
      campusDetail: "institutions/campusDetails",
      currentStudent: "authentication/currentStudent",
      isGuest: "authentication/isGuest",
      gradeOptions: "options/grades",
    }),
    getLinkAdmission() {
      const link = this.campusDetail?.campus_admission_system[0]?.application_in_process_link ?? "";
      return link;
    },
    hasTeachers() {
      // eslint-disable-next-line camelcase
      const { campus_stats, support_campus } = this.campusDetail;
      const teachers = campus_stats.total_teacher_label_2 > 0;
      const teachersHighEducation = campus_stats.total_teacher_label_3 > 0;
      const teachersSupport = support_campus.length > 0;

      const exitsTeachers = teachers || teachersHighEducation || teachersSupport;
      return exitsTeachers;
    },
    hasStudents() {
      return this.campusDetail.institutionmember_campus.length > 0;
    },
  },
  methods: {
    ...mapGetters({
      legalGuardianUUID: "authentication/legalGuardianUUID",
      environment: "authentication/environment",
    }),
    goBack() {
      this.$emit("goBack");
    },
    isAdmissionPanel(panelName) {
      return panelName === "admission";
    },
    setPanelsCampusDetail() {
      this.panels = [
        {
          name: "admission",
          title: this.$t("campus-details.admission-process"),
          icon: require("@/assets/iconsNewHaven/campusDetail/process-icon.svg"),
          iconSize: "35px",
          isEnable: true,
        },
        /*
        {
          name: 'leadership',
          title: this.$t('campus-details.leadership'),
          icon: require('@/assets/iconsNewHaven/campusDetail/star.svg'),
          iconSize: '30px',
          isEnable: true,
        },
        */
        {
          name: "team",
          title: this.$t("campus-details.team.title"),
          icon: require("@/assets/iconsNewHaven/campusDetail/teachers-icon.svg"),
          iconSize: "25px",
          isEnable: this.hasTeachers,
        },
        {
          name: "students",
          title: this.$t("campus-details.students"),
          icon: require("@/assets/iconsNewHaven/campusDetail/student.svg"),
          iconSize: "30px",
          isEnable: this.hasStudents,
        },
        {
          name: "infrastructure",
          title: this.$t("campus-details.infrastructure"),
          icon: require("@/assets/iconsNewHaven/campusDetail/infrastructure-icon.svg"),
          iconSize: "35px",
          isEnable: this.campusDetail.infraestructure_campus.length > 0,
        },
        {
          name: "sports",
          title: this.$t("campus-details.activities.sports"),
          icon: require("@/assets/iconsNewHaven/campusDetail/sports-icon.svg"),
          iconSize: "30px",
          isEnable: this.campusDetail.sport_campus.length > 0,
        },
        {
          name: "extracurriculars",
          title: this.$t("campus-details.activities.extra_programmatic"),
          icon: require("@/assets/iconsNewHaven/campusDetail/extracurricular-icon.svg"),
          iconSize: "30px",
          isEnable: this.campusDetail.extraactivity_campus.length > 0,
        },
        {
          name: "language",
          title: this.$t("campus-details.languages.title"),
          icon: require("@/assets/iconsNewHaven/campusDetail/language-icon.svg"),
          iconSize: "30px",
          isEnable: this.campusDetail.language_campus.length > 0,
        },
        {
          name: "price",
          title: "Costos",
          icon: require("@/assets/iconsChile/campusDetail/price_icon.svg"),
          iconSize: "30px",
          isEnable: this.campusDetail.payment_campus.length > 0,
        },
        {
          name: "performance",
          title: "Rendimiento Académico",
          icon: require("@/assets/iconsChile/campusDetail/performance_icon.svg"),
          iconSize: "30px",
          isEnable: true,
        },
        {
          name: "vacancy",
          title: "Vacantes",
          icon: require("@/assets/iconsChile/campusDetail/chair_icon.svg"),
          iconSize: "30px",
          isEnable: true,
        },
      ];

      // this.panels = this.panels.filter((panel) => panel.isEnable);
    },
    trackMixPanel(nameTrack) {
      if (nameTrack === this.showingPanel) {
        this.showingPanel = "";
      } else {
        const track = `click_school_${nameTrack}`;

        this.$mixpanel.track(track, {
          distinct_id: this.legalGuardianUUID(),
          student_id: this.currentStudent.uuid,
          school_id: this.campusDetail.uuid,
          env: this.environment(),
        });
        this.showingPanel = nameTrack;
      }
    },
  },
};
</script>
