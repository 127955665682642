<template>
  <div class="disability_container">
    <div class="disability_title_container">
      <p class="required-field disability_title">
        {{ $t("register.priority.disability.title") }}
      </p>
    </div>
    <v-container class="disability_question_container">
      <div
        :class="mobile ? 'disability_question-mobile' : 'disability_question'"
        :style="
          opt ? 'background: #2D10B4; margin-right: 5px;;' : 'background: white; margin-right: 5px;'
        "
        @click="changeOpt(true)"
      >
        <p :style="opt ? 'color: white;' : 'color: #2D10B4;'">
          {{ $t("register.priority.disability.yes_opt") }}
        </p>
      </div>
      <div
        :class="mobile ? 'disability_question-mobile' : 'disability_question'"
        :style="
          opt === false
            ? 'background: #2D10B4; margin-left: 5px;'
            : 'background: white; margin-left: 5px;'
        "
        @click="changeOpt(false)"
      >
        <p :style="opt === false ? 'color: white;' : 'color: #2D10B4;'">
          {{ $t("register.priority.disability.no_opt") }}
        </p>
      </div>
    </v-container>
    <div v-show="opt" class="disability_category_container">
      <div class="disability_category_title_container">
        <p class="required-field disability_category_title">
          {{ $t("register.priority.disability.category") }}
        </p>
      </div>
      <v-select
        class="custom-select custom-select disability_category"
        :background-color="disability !== null ? '#2D10B4' : '#FFFFFF'"
        :dark="disability !== null ? true : false"
        outlined
        :items="options"
        item-text="disability_name"
        item-value="uuid"
        hide-details
        v-model="disability"
      />
    </div>
    <div class="btn_container">
      <v-btn icon @click="backMethod">
        <v-img src="@/assets/iconsPalmira/student_register/icons/back.svg" />
      </v-btn>
      <base-button v-if="isDisabled" class="btn-skip" @click="skipStep">
        {{ $t("register.priority.disability.skip_btn") }}
      </base-button>
      <base-button v-else class="btn-continue" @click="continueMethod">
        {{ $t("register.priority.disability.continue_btn") }}
      </base-button>
    </div>
    <v-dialog
      content-class="elevation-0"
      overlay-color="transparent"
      v-model="showSkipModal"
      max-width="370"
    >
      <warning-modal
        @skip="skipConfirmMethod"
        @complete="completeMethod"
        :title="$t('register.priority.disability.warning_modal.title')"
        :text="$t('register.priority.disability.warning_modal.text')"
        color="#FC010A"
        skipModal
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/explorer/base/BaseButton.vue";
import WarningModal from "../WarningModal.vue";

export default {
  name: "Disability",
  components: {
    BaseButton,
    WarningModal,
  },
  data() {
    return {
      opt: null,
      disability: null,
      firstUpdate: true,
      showSkipModal: false,
    };
  },
  mounted() {
    this.opt = this.optionStore;
    this.disability = this.disabilityStore;
  },
  methods: {
    ...mapActions({
      setPriorityStepFive: "newStudentRegister/setPriorityStepFive",
    }),
    continueMethod() {
      const data = {
        opt: this.opt,
        disability: this.disability,
      };
      this.setPriorityStepFive(data);
      this.$emit("nextStep");
    },
    backMethod() {
      this.$emit("backStep");
    },
    changeOpt(value) {
      this.opt = value;
    },
    skipStep() {
      this.showSkipModal = true;
    },
    skipConfirmMethod() {
      this.$emit("skipStep");
    },
    completeMethod() {
      this.showSkipModal = false;
    },
  },
  computed: {
    ...mapGetters({
      optionStore: "newStudentRegister/disabilityPriority",
      disabilityStore: "newStudentRegister/disabilityCategory",
      options: "options/disabilities",
    }),
    isDisabled() {
      return this.opt === null || (this.opt && this.disability === null);
    },
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
  },
  watch: {
    opt: {
      handler(newVal) {
        if (newVal) {
          if (!this.firstUpdate) {
            this.disability = null;
          } else {
            this.firstUpdate = false;
          }
        }
      },
    },
  },
};
</script>
