<template>
  <div style="height: 100%; width: 100%; max-width: 100%; min-width: 100%">
    <DigitalProfileModal :currentModal="currentModal" @setModal="setModal" />
    <!-- module -->
    <div style="height: calc(100% - 50px); max-width: 100%" ref="digitalProfile">
      <CenteredVideo
        class="half-video"
        v-if="videoWidth && videoHeight"
        :width="videoWidth"
        :height="videoHeight"
        videoID="559170153"
        :extraOptions="{ muted: true }"
      />

      <CenteredVideo
        class="half-video"
        v-if="videoWidth && videoHeight"
        :width="videoWidth"
        :height="videoHeight"
        videoID="559170153"
        :extraOptions="{ muted: true }"
      />
    </div>

    <div
      @click="setModal('information')"
      style="
        width: 100%;
        padding: 10px 15px 10px 15px;
        border-radius: 10px;
        background-color: #2b5bff;
        color: white;
        position: absolute;
        bottom: 30px;
        right: 0px;
        cursor: pointer;
        text-align: center;
      "
    >
      Quiero armar mi perfil digital
    </div>

    <!-- end module -->
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import CenteredVideo from "./modals/utils/CenteredVideo.vue";
import MeetingOrganizer from "./modals/utils/MeetingOrganizer.vue";
import DigitalProfileModal from "./modals/DigitalProfileModal.vue";

export default {
  name: "DigitalProfile",
  data() {
    return {
      videoWidth: false,
      videoHeight: false,
      currentModal: null,
      videoLoaded: false,
      producers: [
        {
          name: "Carolina y equipo",
          img: "https://media.istockphoto.com/photos/behind-the-scene-cameraman-and-assistant-shooting-film-with-camera-picture-id982746520?k=20&m=982746520&s=612x612&w=0&h=yuWPP59JSwqyRmwRrxZfACTs84hVjn-gXBdaf5BYMnU=",
        },
        {
          name: "Productora Norte",
          img: "https://media.istockphoto.com/photos/man-hands-holding-movie-clapper-picture-id892375260?k=20&m=892375260&s=612x612&w=0&h=eeJHLpmA2Me1QTeBFryXamjuBImzyaZKTgAq9aW5uDw=",
        },
        {
          name: "Productora 2017",
          img: "https://media.istockphoto.com/photos/video-camera-in-film-or-movie-production-on-tripod-and-professional-picture-id1135569876?k=20&m=1135569876&s=612x612&w=0&h=ffhEwuRC9CjplUXwq-PAjcD2SMdTo7GrHQmTUWyPPU0=",
        },
      ],
    };
  },
  components: {
    CenteredVideo,
    MeetingOrganizer,
    DigitalProfileModal,
  },
  computed: {
    showModal() {
      return this.currentModal != null;
    },
  },
  mounted() {
    this.getVideoWidth();
    this.getVideoHeight();
  },
  methods: {
    setModal(modal) {
      this.currentModal = modal;
    },
    getVideoWidth() {
      this.$nextTick(() => {
        this.videoWidth = this.$refs.digitalProfile.clientWidth / 2;
      });
    },
    getVideoHeight() {
      this.$nextTick(() => {
        this.videoHeight = this.$refs.digitalProfile.clientHeight;
      });
    },
  },
};
</script>
<style>
.dfm-text {
  font-family: Roboto;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #0d22a8;
}
.half-video {
  height: 100%;
  padding: 10px;

  width: 50%;
  max-width: 50%;
  float: left;
}
</style>
