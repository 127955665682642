<template>
  <div class="document-number_container">
    <div class="document-number_title">
      {{ $t("register.identification.document_number.title") }}
    </div>
    <div class="document-number_subtitle">
      {{ $t("register.identification.document_number.subtitle") }}
    </div>
    <div
      class="document-number_question_container"
      :style="mobile ? 'flex-direction: column;' : ''"
    >
      <div class="document-number_input_container">
        <div class="document-number_input_info">
          {{ nationalityInfo }}
        </div>
        <div class="document-number_input_nuip_subtitle" v-if="documentId === 1">
          {{ $t("register.identification.document_number.n_document_nuip") }}
        </div>
        <div class="document-number_input_title_container">
          <p class="required-field document-number_input_title">
            {{ $t("register.identification.document_number.n_document") }}
          </p>
        </div>
        <div
          class="document-number_input_subtitle"
          v-if="documentId === 1 || documentId === 5 || documentId === 6"
        >
          {{ $t("register.identification.document_number.n_document_info") }}
        </div>
        <input
          v-model="nDocument"
          type="text"
          placeholder="1 2 3 4 5 6 7 8 9 0"
          class="register__content__input__div__input document-number_input"
          :class="{ 'register__content__input__div__input--selected': nDocument.length > 0 }"
          @input="$v.nDocument.$touch()"
          @blur="$v.nDocument.$touch()"
          :disabled="documentId === 10"
          @keydown="documentId === 1 || documentId === 5 || documentId === 6 ? isNumber : ''"
          v-mask="
            documentId === 1
              ? '##########'
              : documentId === 5
              ? '###############'
              : documentId === 6
              ? '#######'
              : ''
          "
        />
        <div class="document-number_input_error" v-for="(error, i) in nDocumentErrors" :key="i">
          {{ error }}
        </div>
      </div>
      <div v-if="false" class="document-number_img" :style="mobile ? 'margin-top: 10px;' : ''">
        <v-img max-width="156" src="@/assets/iconsPalmira/student_register/complementary-img.svg" />
      </div>
    </div>
    <div class="btn_container">
      <v-btn icon @click="backMethod">
        <v-img src="@/assets/iconsPalmira/student_register/icons/back.svg" />
      </v-btn>
      <base-button v-if="isDisabled" class="btn-skip" @click="skipStep">
        {{ $t("register.identification.document_number.skip_btn") }}
      </base-button>
      <base-button v-else class="btn-continue" @click="continueMethod">
        {{ $t("register.identification.document_number.continue_btn") }}
      </base-button>
    </div>
    <v-dialog
      content-class="elevation-0"
      overlay-color="transparent"
      v-model="showSkipModal"
      max-width="370"
    >
      <warning-modal
        @skip="skipConfirmMethod"
        @complete="completeMethod"
        :title="$t('register.identification.document_number.warning_modal.title')"
        :text="$t('register.identification.document_number.warning_modal.text')"
        color="#FC010A"
        skipModal
      />
    </v-dialog>
    <v-dialog
      content-class="dashborad-modal-id-warning"
      overlay-color="transparent"
      v-model="showModalIdWarning"
      max-width="370"
    >
      <warning-modal
        :numberModal="true"
        @close="closeIdNumberModal"
        :title="selectText()"
        :text="$t('registrationFlow.warnings.id_warning_text')"
        color="#FC010A"
        skipModal
      />
    </v-dialog>
  </div>
</template>

<script>
import { maxLength, minLength, requiredIf } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/explorer/base/BaseButton.vue";
import WarningModal from "../WarningModal.vue";

export default {
  name: "DocumentNumber",
  components: {
    BaseButton,
    WarningModal,
  },
  data() {
    return {
      nDocument: "",
      showSkipModal: false,
      showModalIdWarning: false,
    };
  },
  mounted() {
    this.nDocument = this.nDocumentStore;
    this.setShowIdNumberModal(false);
  },
  validations: {
    nDocument: {
      required: requiredIf(function useNDocument() {
        return this.documentId !== 10;
      }),
      minLength(nDocument) {
        if (this.documentId === 1) {
          return minLength(10)(nDocument);
        }
        if (this.documentId === 5) {
          return minLength(15)(nDocument);
        }
        if (this.documentId === 6) {
          return minLength(7)(nDocument);
        }
        return true;
      },
      maxLength(nDocument) {
        if (this.documentId === 1) {
          return maxLength(10)(nDocument);
        }
        if (this.documentId === 5) {
          return maxLength(15)(nDocument);
        }
        if (this.documentId === 6) {
          return maxLength(7)(nDocument);
        }
        return true;
      },
    },
  },
  methods: {
    ...mapActions({
      setIdentificationStepSix: "newStudentRegister/setIdentificationStepSix",
      setShowIdNumberModal: "newStudentRegister/setShowIdNumberModal",
    }),
    continueMethod() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.error(this.$t("views.register.error.invalid_form"));
      } else {
        const data = {
          nDocument: this.nDocument,
        };
        this.setIdentificationStepSix(data);
        // eslint-disable-next-line consistent-return
        setTimeout(() => {
          if (this.showIdNumberModal === false) {
            return this.$emit("nextStep");
          }
          if (this.showIdNumberModal === true) {
            this.showModalIdWarning = true;
          }
        }, 1000);
      }
    },
    // eslint-disable-next-line consistent-return
    isNumber(evt) {
      const evtAux = evt || window.event;
      const charCode = evtAux.which ? evtAux.which : evtAux.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evtAux.preventDefault();
      } else {
        return true;
      }
    },
    backMethod() {
      this.$emit("backStep");
    },
    skipStep() {
      this.showSkipModal = true;
    },
    skipConfirmMethod() {
      this.$emit("skipStep");
    },
    completeMethod() {
      this.showSkipModal = false;
    },
    closeIdNumberModal() {
      this.showModalIdWarning = false;
    },
    idNumberErrorText() {
      // [es el teléfono celular terminado en XXXXX1234 / es un correo electrónico]
    },
    selectText() {
      if (this.idNumberResponse) {
        if (this.idNumberResponse.actual_owner === "email") {
          return "Este documento de identidad ya se encuentra registrado por un acudiente cuyo medio de contacto es un correo electrónico";
        }
        if (this.idNumberResponse.actual_owner === "phone") {
          return `Este documento de identidad ya se encuentra registrado por un acudiente cuyo medio de contacto es el teléfono celular terminado en ${this.idNumberResponse.msg}`;
        }
        return "";
      }
      return "";
    },
  },
  computed: {
    ...mapGetters({
      document: "newStudentRegister/document",
      nDocumentStore: "newStudentRegister/nDocument",
      currentSubSteps: "newStudentRegister/currentSubSteps",
      showIdNumberModal: "newStudentRegister/showIdNumberModal",
      idNumberResponse: "newStudentRegister/idNumberResponse",
    }),
    isDisabled() {
      return (
        this.nDocument === "" &&
        (this.documentId !== 10 || (this.nDocumentErrors.length > 0 && this.nDocument !== ""))
      );
    },
    nDocumentErrors() {
      const errors = [];
      if (!this.$v.nDocument.$dirty) {
        return errors;
      }
      if (!this.$v.nDocument.required) {
        errors.push(this.$t("register.identification.document_number.errors.required"));
      }
      if (!this.$v.nDocument.minLength || !this.$v.nDocument.maxLength) {
        errors.push(this.$t("register.identification.document_number.errors.length"));
      }
      return errors;
    },
    nationalityInfo() {
      if ("nationality" in this.document) {
        return `${this.document.nationality.nationality_name} | ${this.document.identification_type_name}`;
      }
      return this.$t("register.identification.document_number.nationality_info.without");
    },
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
    documentId() {
      if ("id" in this.document) {
        return this.document.id;
      }
      return -1;
    },
  },
};
</script>
