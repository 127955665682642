<template>
  <section class="campus-detail__header">
    <div class="campus-detail__header__information">
      <h3 class="campus-detail__header__title">{{ nameSchool }}</h3>
      <p class="campus-detail__header__text">{{ typeSchool }}</p>
      <p class="campus-detail__header__text">{{ gradesSchool }}</p>
    </div>

    <div class="campus-detail__header__stats campus-detail__header__stats__bg">
      <picture>
        <img class="mr-1" src="@/assets/iconsChile/campusDetail/view.svg" width="23px" />
        <p class="campus-detail__header__title">
          {{ views }}
          <span class="ml-1 campus-detail__header__text" v-t="'campus-details.views'" />
        </p>
      </picture>
      <picture
        v-if="hasVacancies"
        style="align-items: center;"
      >
        <img class="mr-2" src="@/assets/iconsChile/chair-admissions.svg" width="20px" />
        <div class="campus-detail__header__title d-flex justify-start align-top">
          <span :class="{ 'campus-detail__header--blur': isGuest }">
            {{ getStats }}
          </span>
          <span
            class="ml-1 campus-detail__header__text"
            v-t="{
              path: 'campus-details.postulations',
              args: { grade: getGrade },
            }"
          />
        </div>
      </picture>
      <button
        class="campus-detail__applications mt-2"
        type="button"
        @click="scrollTo()"
        :disabled="!hasVacancies"
        v-t="
          hasVacancies ? 'campus-details.applications.open' : 'campus-details.applications.closed'
        "
      />
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CampusDetailHeader",
  data() {
    return {
      noMatchGrade: false,
    };
  },
  props: {
    nameSchool: {
      type: String,
      default: "",
    },
    typeSchool: {
      type: String,
      default: "",
    },
    gradesSchool: {
      type: String,
      default: "",
    },
    views: {
      type: Number,
      default: 0,
    },
    applications: {
      type: Number,
      default: 0,
    },
    risk: {
      type: Object,
      default: () => {},
    },
    vacancies: {
      type: Array,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      isGuest: "authentication/isGuest",
      grades: "options/grades",
      currentStudent: "authentication/currentStudent",
      guestGrades: "authentication/guestGrades",
      guestStage: "authentication/guestStage"
    }),
    hasVacancies() {
      let vacancies = false;
      this.vacancies.forEach((program) => {
        if (program._calculated_vacancies > 0) {
          vacancies = true;
        }
      });
      return vacancies;
    },
    getStats() {
      if (this.isGuest) {
        const searchProgram = this.vacancies.find(
          (program) => program.grade_id === this.guestGrades[0]
        );
        if (searchProgram !== undefined) {
          this.noMatchGrade = false;
          return searchProgram._calculated_vacancies;
        } else {
          this.noMatchGrade = true;
          return this.vacancies[0]._calculated_vacancies;
        }
      }

      if (this.currentStudent) {
        const searchProgram = this.vacancies.find(
          (program) => program.grade_id === this.currentStudent.grades[0]
        );
        if (searchProgram !== undefined) {
          this.noMatchGrade = false;
          return searchProgram._calculated_vacancies;
        } else {
          this.noMatchGrade = true;
          return this.vacancies[0]._calculated_vacancies;
        }
      }
      return 0;
    },
    getGrade() {
      if (this.isGuest) {
        if (this.noMatchGrade) {
          return this.vacancies[0].grade_name;
        } else {
          const gradeId = this.guestGrades[0];
          const gradeLabel = this.grades.find((grade) => grade.id === gradeId);
          const gradeName = this.splitLevelName(gradeLabel.grade_name, this.guestStage, gradeId)
          return gradeName ?? "Grade";
        }
      }
      const gradeId = this.currentStudent.grades[0] ?? 0;
      const stageId = this.currentStudent.stages[0] ?? 0;
      const gradeLabel = this.grades.find((grade) => grade.id === gradeId);
      const gradeName = this.splitLevelName(gradeLabel.grade_name, stageId, gradeId)
      return gradeName ?? "Grade";
    },
    isApplicationsOpen() {
      const open = true;
      return open;
    },
  },
  methods: {
    toLogin() {
      this.$emit("close-modal");
      this.$router.push({ name: "Login" });
    },
    scrollTo() {
      document.getElementById("vacancy").scrollIntoView({ behavior: "smooth" });
    },
    splitLevelName(gradeName, stage, grade) {
      if (stage === 1) {
        if (grade === 1) {
          return gradeName.split("/")[0].trim();
        }
        if (grade === 5 || grade === 6) {
          return gradeName.split("/")[1].trim();
        }
      }
      if (stage === 2) {
        if (grade === 7 || grade === 11 || grade === 13) {
          return gradeName.split("/")[0].trim();
        }
      }
      if (stage === 3) {
        if (grade === 15 || grade === 17 || grade === 18) {
          return gradeName.split("/")[0].trim();
        }
      }
      if (stage === 4) {
        if (grade === 1) {
          return gradeName.split("/")[1].trim();
        }
        if (grade === 7 || grade === 11 || grade === 13) {
          return gradeName.split("/")[0].trim();
        }
      }
      if (stage === 5) {
        if (grade === 7 || grade === 11 || grade === 13) {
          return gradeName.split("/")[1].trim();
        }
        if (grade === 15 || grade === 17 || grade === 18) {
          return gradeName.split("/")[1].trim();
        }
      }
      return gradeName.split("/")[0].trim();
    },
  },
};
</script>
