<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
<template>
  <transition name="fade">
    <div v-if="show" class="school-info-container">
      <!-- Close icon -->
      <v-icon @click="closeSchoolCard(campus)" class="close-button">
        $vuetify.icons.closeMapCard
      </v-icon>
      <div class="school-map-casrd">
        <school-card
          @go-register="signUp(campus)"
          :campus="campus"
          @explore="explore('school_card_map')"
          @close="closeSchoolCard(campus)"
          :program="-1"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
import SchoolCard from "@/components/explorer/general/SchoolCard.vue";

export default {
  name: "SchoolMapCard",
  components: {
    SchoolCard,
  },
  props: {
    campus: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    lat: {
      type: String,
      required: true,
    },
    lng: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapGetters({
      legalGuardianUUID: "authentication/legalGuardianUUID",
      environment: "authentication/environment",
      currentStudent: "authentication/currentStudent",
    }),
    closeSchoolCard(campus) {
      this.$mixpanel.track("close_school_card", {
        distinct_id: this.legalGuardianUUID,
        student_id: this.currentStudent().uuid,
        school_id: campus.uuid,
        env: this.environment(),
      });
      return this.$emit("close", campus);
    },
    signUp(campus) {
      this.closeSchoolCard(campus);
      this.$emit("signUp");
    },
  },
  computed: {
    location() {
      const { lat, lng } = this;
      return { lat, lng };
    },
  },
};
</script>
