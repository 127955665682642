<template>
  <div>
    <v-tooltip v-model="showTooltip" class="" bottom content-class="custom-tooltip">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="panels__tooltip panels__tooltip--right"
          icon
          dark
          small
          color="#1E2D4C"
          style="margin-top: -4px !important"
        >
          <v-icon dark> mdi-information </v-icon>
        </v-btn>
      </template>
      <span v-t="'campus-details.tooltip.price'" />
    </v-tooltip>

    <section
      class="campus-detail__panels__container mt-2 mb-2"
      v-if="!campusDetail.sector_label.sector_name == 'Privado'"
    >
      <template v-if="campusDetail.sector_label.sector_name == 'Público'">
        <span class="campus-detail__panels__title text-left mb-2 font-weight-medium">
          {{ $t("campus-details.panels.publico1") }}
          <b>{{ $t("campus-details.panels.publico2") }}</b>
          {{ $t("campus-details.panels.publico3") }}
        </span>
      </template>

      <template v-else-if="campusDetail.sector_label.sector_name == 'Particular Subvencionado'">
        <span class="campus-detail__panels__title text-left mb-2 font-weight-medium">
          {{ $t("campus-details.panels.particular_sub1") }}
          <b>{{ $t("campus-details.panels.particular_sub2") }}</b>
          {{ $t("campus-details.panels.particular_sub3") }}
        </span>
      </template>
    </section>

    <div>
      <section v-if="currentSchool.has_sep" class="campus-detail__panels__container mt-2 mb-2">
        <template>
          <span class="campus-detail__panels__title text-left mb-2 font-weight-medium">
            Este establecimiento tiene convenio <b> SEP</b>
            <!--, averigua si aplica para ti -->
          </span>

          <!--<div class="w-full d-flex flex-row justify-center align-center">
            <button
                class="panels__btn panels__btn__white w-auto px-4 mt-2"
                v-t="'Iniciar sesión para contenido personalizado'"
                @click="toLogin"
              />
          </div>-->
        </template>
      </section>

      <!--
        No sabemos la prioridad del estudiante actual.
         <section
        class="campus-detail__panels__container campus-detail__panels__container__blue  mt-2 mb-2"
        v-if="sep && student_priority"
      >
        <template>

          <div class="panel_container_row">

          <div class="d-flex flex-row mb-3 text-left"  >
              <span class="campus-detail__about__text campus-detail__about__text__bg--v2 font-normal text-left">
                SEP
                </span>
          </div>
          <img
          v-if="false"
            :src="require('@/assets/iconsChile/campusDetail/sep.png')"
            class="school-card__info__icon"
          />

          <p class="campus-detail__panels__title campus-detail__panels__title--white
          text-left ml-2 mb-2 ">
            Este establecimiento es gratuito para ti      </p>

          </div>

          <span class="campus-detail__panels__title campus-detail__panels__title--white text-left mb-2 font-weight-medium">
            Este establecimiento tiene convenio <b>SEP</b>, reportaste que {currentStudent.name} es prioritario. Eso significa que no tienes que pagar un copago.
          </span>

        </template>
      </section>-->
    </div>
    <section class="campus-detail__panels__container__row mt-2 mb-2">
      <section class="campus-detail__panels__container__item">
        <template v-if="true">
          <h3 class="campus-detail__panels__title text-left mb-2 mt-2">
            {{ $t("campus-details.panels.monthly") }}
          </h3>

          <p class="campus-detail__panels__title text-left mb-2 font-weight-medium">
            {{ getMonthlyFee() }}
          </p>
          <img
            v-if="campusDetail.payment !== 'Sin Información'"
            :src="iconPriceSelection(campusDetail.payment)"
            class="school-card__info__icon"
          />
        </template>
      </section>
      <section class="campus-detail__panels__container__item">
        <template v-if="campusDetail.payment_campus.length > 0">
          <h3 class="campus-detail__panels__title text-left mb-2 mt-2">
            {{ $t("campus-details.panels.yearly_fee") }}
          </h3>

          <p class="campus-detail__panels__title text-left mb-2 font-weight-medium">
            {{ getYearlyFee() }}
          </p>
        </template>
      </section>
    </section>

    <section class="campus-detail__panels__container">
      <template v-if="campusDetail.payment_campus.length > 0">
        <h3 class="campus-detail__panels__title text-left mb-2 mt-2">
          {{ $t("campus-details.panels.scholarships") }}
        </h3>

        <p class="campus-detail__panels__title text-left mb-2 font-weight-medium">
          {{ campusDetail.payment_campus[0].num_scholarships }}
        </p>
      </template>
    </section>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Price",
  data() {
    return {
      showTooltip: false,
      student_priority: false,
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: "institutions/campusDetails",
      currentSchool: "institutions/currentSchoolLite",
    }),
  },
  methods: {
    getMonthlyFee() {
      return this.campusDetail.payment_campus[0].payment_category_label.payment_category_name;
    },
    getYearlyFee() {
      return this.campusDetail.payment_campus[0].tuition_category_label.tuition_category_name;
    },
    iconPriceSelection(campusPrice) {
      let imgFile = "";
      //if (this.campus.features.sep && this.campus.student_priorities.has_sae_priority) {
      //  imgFile = 'price-free.svg';
      //} else
      if (campusPrice === "Gratuito") {
        imgFile = "price-free.svg";
      } else if (campusPrice === "$1.000 a $10.000" || campusPrice === "$1.000 a $50.000") {
        imgFile = "price-10-50.svg";
      } else if (campusPrice === "10.001 a $100.000" || campusPrice === "$50.001 a $100.000") {
        imgFile = "price-50-100.svg";
      } else if (campusPrice === "Más De $100.000") {
        imgFile = "price-100.svg";
      } else {
        imgFile = "price-100.svg";
      }
      const image = require("@/assets/iconsChile/campusDetail/" + imgFile);
      return image;
    },
    toLogin() {
      this.$emit("close-modal");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
