<template>
  <section class="mime-qa">
    <!-- Close button -->
    <button class="mt-5" @click="closeMenu" type="button">
      <v-icon @click="closeMenu"> $vuetify.icons.closeMapCard </v-icon>
    </button>

    <!-- Title -->
    <h1 class="mime-qa__title text-left ml-6 mt-5" v-t="'menu.QA'" />

    <v-expansion-panels accordion flat class="fill-width pb-3">
      <v-expansion-panel
        v-for="({ question, answer, answerTwo, showContact, showLink, list }, index) in $t(
          'menu.mime-qa.questions'
        )"
        :key="index"
        class="fill-width"
      >
        <v-expansion-panel-header class="fill-width">
          <!-- Question -->
          <p class="mime-qa__question">{{ question }}</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- Answer -->
          <p class="mime-qa__text">{{ answer }}</p>

          <div v-if="showContact" class="mime-qa__contact">
            <button @click="openContactLink()" class="mime-qa__contact__btn">
              {{ $t("campus-details.pride_point.contact") }}
            </button>
          </div>

          <ul class="mime-qa__list">
            <li class="mime-qa__list__li" v-for="(item, index) in list" :key="index">
              {{ item }}
            </li>
          </ul>

          <a :href="showLink" class="mime-qa__text" target="_blank">
            {{ showLink }}
          </a>

          <p v-if="answerTwo" class="mime-qa__text">
            {{ answerTwo }}
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </section>
</template>

<script>
import CONFIG from "@/config";

export default {
  name: "MimeQA",
  methods: {
    closeMenu() {
      this.$emit("close");
    },
    openContactLink() {
      window.open(CONFIG.contactGoogleFom, "_blank");
    },
  },
};
</script>
