<template>
  <v-card class="pa-6" color="#F2F2F2">
    <v-row no-gutters>
      <v-col cols="10">
        <v-card-title>
          {{ $t("choose-program.title") }}
        </v-card-title>
      </v-col>
      <v-col cols="2">
        <!-- Close icon -->
        <v-icon x-large @click="onCloseClick"> $vuetify.icons.closeFilter </v-icon>
      </v-col>
    </v-row>
    <v-divider class="mx-4" />
    <div class="py-3">
      <v-row no-gutters class="d-flex pl-4 pb-3" align="center" justify="start">
        <p class="font-weight-regular" v-html="$t('choose-program.text')"></p>
      </v-row>
    </div>
    <div class="agreement pb-3">
      <v-row no-gutters class="d-flex py-3 pl-4" align="center" justify="start">
        <span class="text-decoration-underline font-weight-bold">
          {{ $t("choose-program.subtitle") }}
        </span>
      </v-row>
      <v-row
        no-gutters
        class="agreement d-flex mx-4 my-3"
        align="center"
        justify="start"
        v-for="(program, index) in programsOptions"
        :key="`radio_button_program_${program.uuid}`"
      >
        <div class="d-flex mx-4 my-3">
          <div
            v-ripple:ripple="{ center: true }"
            class="mr-2 d-flex align-center justify-center filter-checkbox"
            @click="clickOnRatioIndex(index)"
          >
            <h1 v-if="programRatio[index]">•</h1>
          </div>
          <span>{{ calculateLabelProgram(program) }}</span>
        </div>
      </v-row>
    </div>
    <v-row no-gutters class="mt-2 d-flex align-center justify-center">
      <base-button
        :disabled="selectedProgramUUIDs.length < 1"
        class="btn--primary-01 py-4 px-8 mr-3"
        @click="addToFavorites"
      >
        {{ $t("choose-program.confirm") }}
      </base-button>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/explorer/base/BaseButton.vue";

export default {
  name: "ChooseProgramModal",
  components: {
    BaseButton,
  },
  data() {
    return {
      programRatio: [],
    };
  },
  created() {
    const newAnswers = newVal.map(() => false);
    this.programRatio = newAnswers;
  },
  computed: {
    ...mapGetters({
      programsOptions: "institutions/programsOptions",
      currentStudent: "authentication/currentStudent",
      isInCampus: "institutions/isInCampus",
    }),
    selectedProgramUUIDs() {
      const selectedProgramUUIDs = [];
      this.programRatio.map((bool, index) => {
        if (bool) {
          selectedProgramUUIDs.push(this.programsOptions[index].uuid);
        }
        return null;
      });
      return selectedProgramUUIDs;
    },
  },
  methods: {
    ...mapActions({
      closeChooseProgramModal: "institutions/closeChooseProgramModal",
      addMultipleFavoriteByUUID: "institutions/addMultipleFavoriteByUUID",
      success: "snackbar/success",
      updateCampusDetailFavorite: "institutions/updateCampusDetailFavorite",
    }),
    onCloseClick() {
      this.closeChooseProgramModal();
    },
    filteredProgramOptions() {},
    addToFavorites() {
      this.addMultipleFavoriteByUUID({
        programsUUIDs: this.selectedProgramUUIDs,
      })
        .catch((error) => {
          this.error(this.$getError(error).reason);
        })
        .then(() => {
          this.updateCampusDetailFavorite(true);
          this.closeChooseProgramModal();
        });
    },
    // eslint-disable-next-line
    calculateLabelProgram({ grade, gender, shift, gradetrack, shift_label }) {
      if (this.isInCampus === undefined || this.isInCampus === null) {
        let gradeName;
        let genderName;
        let shiftName;
        let specialityName;
        if (this.$i18n.locale === "es") {
          gradeName = grade.grade_name_es;
          genderName = gender.gender_name_es;
          shiftName = shift.shift_name_es;
          specialityName = gradetrack.specialty_label.specialty_name_es;
        } else if (this.$i18n.locale === "en") {
          gradeName = grade.grade_name_en;
          genderName = gender.gender_name_en;
          shiftName = shift.shift_name_en;
          specialityName = gradetrack.specialty_label.specialty_name_en;
        }
        return `${gradeName}, ${genderName}, ${shiftName}, ${specialityName}`;
      }
      const gradeTrackString = gradetrack.gradetrack_name.split(" ");
      gradeTrackString.splice(2, 0, gender.gender_name);
      gradeTrackString.splice(4, 0, shift_label.shift_name);
      let fullLabel;
      gradeTrackString.forEach((el, index) => {
        if (index === 0) {
          fullLabel = el;
        } else if (index === 1) {
          fullLabel = fullLabel + " " + el;
        } else if (el === "-") {
        } else if (index === 6) {
          fullLabel = fullLabel + " " + el;
        } else {
          fullLabel = fullLabel + ", " + el;
        }
      });
      return fullLabel;
    },
    clickOnRatioIndex(index) {
      let favoriteProgram = this.programRatio;
      const newAnswers = favoriteProgram.map(() => false);
      newAnswers[index] = true;
      this.programRatio = [...newAnswers];
    },
  },
  watch: {
    programsOptions: {
      handler(newVal) {
        const newAnswers = newVal.map(() => false);
        this.programRatio = newAnswers;
      },
      immediate: true,
    },
  },
};
</script>
