<template>
  <div class="campus-detail__about">
    <h1 class="campus-detail__about__title" v-t="'campus-details.about-campus'" />
    <p class="campus-detail__about__text mb-5">
      {{ textAbout }}
    </p>

    <template v-if="showFullInfo">
      <div v-if="false">
        <picture
          class="d-flex flex-row mb-3"
          v-for="{ name, icon, id, active } in agreementsList"
          :key="id"
        >
          <img :src="icon" class="mr-2" width="25px" />
          <span
            class="campus-detail__about__text font-normal m-0 p-0"
            :class="{ 'campus-detail__about__text--blue': !active }"
          >
            {{ name }}
          </span>
        </picture>
      </div>
      <CampusDetailSocialMedia :contacts="contacts" />
      <hr />

      <picture class="d-flex flex-column w-full mb-3">
        <div class="d-flex flex-row justify-between mb-3">
          <img src="@/assets/iconsChile/campusDetail/location_mark.svg" width="20px" />

          <span class="campus-detail__about__text font-normal text-right mr-8">
            {{ campusStreet }}
          </span>
        </div>
      </picture>
    </template>
    <div class="campus-detail__about__divider">
      <hr />
      <button
        type="button"
        @click="showFullInfo = !showFullInfo"
        class="ml-3"
        :class="{ 'campus-detail__about__rotate': showFullInfo }"
      >
        <img src="@/assets/icons/arrow-down-circle.svg" width="25px" />
      </button>
    </div>

    <div class="d-flex flex-column w-full justify-between mb-3">
      <div
        class="d-flex flex-row align-center justify-space-between mb-3"
        v-if="hasPie() || hasSep()"
      >
        <span class="campus-detail__about__text font-normal">
          {{ $t("campus-details.covenants") }}
        </span>
        <div class="d-flex flex-row mb-3 text-left mr-10">
          <span
            class="campus-detail__about__text campus-detail__about__text__bg--v4 font-normal text-left"
            v-if="hasPie()"
          >
            {{ isMobile ? "PIE" : $t("campus-details.pie") }}
          </span>

          <span
            v-if="hasSep()"
            class="campus-detail__about__text campus-detail__about__text__bg--v6 font-normal text-left"
          >
            {{ isMobile ? "SEP" : $t("campus-details.sep") }}
          </span>
        </div>
      </div>
      <hr v-if="hasPie() || hasSep()" />
    </div>

    <picture class="d-flex w-full justify-between">
      <span class="campus-detail__about__text font-normal">
        {{ $t("campus-details.about-title-formation") }}
      </span>
      <div class="d-flex align-center" :class="{ 'flex-column mt-0': mobile }">
        <div
          class="d-flex flex-row justify-end mb-3 ml-1"
          v-for="(eduCode, index) in educationCode"
          :key="index"
        >
          <span
            v-if="isAdultModality(eduCode)"
            class="campus-detail__about__text campus-detail__about__text__bg--v1 font-normal"
          >
            {{ $t("campus-details.about-adult-modality") }}
          </span>
          <span
            v-if="hasEducationalCode(eduCode)"
            class="campus-detail__about__text campus-detail__about__text__bg--v3 font-normal"
            :class="{
              'campus-detail__about__text__bg--v2': eduCode >= 300 && eduCode <= 399,
              'campus-detail__about__text__bg--v4': eduCode >= 200 && eduCode <= 299,
              'campus-detail__about__text__bg--v5': eduCode >= 900 && eduCode <= 999,
            }"
          >
            {{ getModalityLabel(eduCode) }}
          </span>
        </div>
      </div>
    </picture>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CampusDetailSocialMedia from "@/components/explorer/general/campus_detail/CampusDetailSocialMedia.vue";

export default {
  name: "CampusDetailAbout",
  data() {
    return {
      showFullInfo: true,
    };
  },
  components: {
    CampusDetailSocialMedia,
  },
  props: {
    about: {
      type: String,
      default: "",
    },
    agreements: {
      type: Array,
    },
    contacts: {
      type: Array,
    },
    educationCode: {
      type: Array,
    },
    campusStreet: {
      type: String,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      agreementsLabels: "options/agreementsLabels",
    }),
    textAbout() {
      let text = this.about;
      if (!this.showFullInfo) {
        return `${text.substring(0, 300)}...`;
      }
      if (text.length === 0) {
        text = this.$t("campus-details.no-about-info");
      }
      return text;
    },
    agreementsList() {
      const agreements = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const agreement of this.agreementsLabels) {
        agreements.push(this.setAgreement(agreement));
      }
      agreements.sort((a, b) => a.id - b.id);
      return agreements;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    setAgreement(agreement) {
      // eslint-disable-next-line camelcase
      const { id, agreement_name } = agreement;
      const agrementExist = this.searchAgreement(id);
      let formattedAgreement = {};

      formattedAgreement = {
        id,
        name: agreement_name,
        active: agrementExist,
      };

      switch (id) {
        case 1:
          formattedAgreement.icon = agrementExist
            ? require("@/assets/iconsNewHaven/campusDetail/before-on.svg")
            : require("@/assets/iconsNewHaven/campusDetail/before-off.svg");
          break;
        case 2:
          formattedAgreement.icon = agrementExist
            ? require("@/assets/iconsNewHaven/campusDetail/after-on.svg")
            : require("@/assets/iconsNewHaven/campusDetail/after-off.svg");
          break;
        case 3:
          formattedAgreement.icon = agrementExist
            ? require("@/assets/iconsNewHaven/campusDetail/bussing-on.svg")
            : require("@/assets/iconsNewHaven/campusDetail/bussing-off.svg");
          break;
        case 4:
          formattedAgreement.icon = agrementExist
            ? require("@/assets/iconsNewHaven/campusDetail/bilingual-on.svg")
            : require("@/assets/iconsNewHaven/campusDetail/bilingual-off.svg");
          break;
        case 5:
          formattedAgreement.icon = agrementExist
            ? require("@/assets/iconsNewHaven/campusDetail/uniform-on.svg")
            : require("@/assets/iconsNewHaven/campusDetail/uniform-off.svg");
          break;
        case 6:
          formattedAgreement.icon = agrementExist
            ? require("@/assets/iconsNewHaven/campusDetail/neighborhood-on.svg")
            : require("@/assets/iconsNewHaven/campusDetail/neighborhood-off.svg");
          break;
        default:
          break;
      }
      return formattedAgreement;
    },
    searchAgreement(id) {
      let agrementExist = false;
      // eslint-disable-next-line no-restricted-syntax
      for (const agrement of this.agreements) {
        if (agrement.agreement_label.id === id) {
          agrementExist = true;
          break;
        }
      }
      return agrementExist;
    },
    hasPie() {
      if (this.agreements.length === 0) {
        return false;
      }
      const agreement = this.agreements.find((agreement) => agreement.agreement_label.id === 2);
      if (agreement) {
        return true;
      }
      return false;
    },
    hasSep() {
      if (this.agreements.length === 0) {
        return false;
      }
      const agreement = this.agreements.find((agreement) => agreement.agreement_label.id === 1);
      if (agreement) {
        return true;
      }
      return false;
    },
    isAdultModality(eduCode) {
      if (
        (eduCode >= 160 && eduCode <= 167) ||
        (eduCode >= 360 && eduCode <= 366) ||
        (eduCode >= 460 && eduCode <= 463) ||
        (eduCode >= 560 && eduCode <= 563) ||
        (eduCode >= 660 && eduCode <= 663) ||
        (eduCode >= 760 && eduCode <= 763) ||
        (eduCode >= 860 && eduCode <= 863) ||
        (eduCode >= 960 && eduCode <= 963)
      ) {
        return true;
      }
      return false;
    },
    hasEducationalCode(eduCode) {
      if (
        (eduCode >= 400 && eduCode <= 899) ||
        (eduCode >= 300 && eduCode <= 399) ||
        (eduCode >= 200 && eduCode <= 299) ||
        (eduCode >= 900 && eduCode <= 999)
      ) {
        return true;
      }
      return false;
    },
    getModalityLabel(eduCode) {
      if (eduCode >= 400 && eduCode <= 899) {
        return this.$t("campus-details.about-tp-modality");
      }
      if (eduCode >= 300 && eduCode <= 399) {
        return this.$t("campus-details.about-hc-modality");
      }
      if (eduCode >= 200 && eduCode <= 299) {
        return this.$t("campus-details.about-se-modality");
      }
      if (eduCode >= 900 && eduCode <= 999) {
        return this.$t("campus-details.about-artistic-modality");
      }
    },
  },
};
</script>
