<template>
  <section v-if="videoDrone" class="campus-detail__video-drone campus-detail__module mt-2">
    <picture class="d-flex flex-row align-center">
      <img src="@/assets/svg/drone.svg" width="25px" />
      <h3 class="campus-detail__module__title ml-2" v-t="'campus-details.drone_footage'" />
    </picture>
    <div class="mt-4">
      <CampusDetailVideo :media="true" :video="videoDrone" :fullscreen="true" />
    </div>
  </section>
</template>

<script>
import CampusDetailVideo from "@/components/explorer/general/campus_detail/CampusDetailVideo.vue";

export default {
  name: "CampusDetailDrone",
  components: {
    CampusDetailVideo,
  },
  props: {
    videoDrone: {
      type: String,
      default: "",
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
