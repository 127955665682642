<template>
  <div class="mobile-explorer-header">
    <div class="d-flex">
      <img
        class="mr-3"
        style="cursor: pointer"
        @click="$emit('toggleMenu')"
        src="@/assets/iconsNewHaven/navigation/menu.svg"
      />
      <div class="mobile-explorer-header__students" v-if="!isGuest">
        <div class="d-flex align-center">
          <v-select
            class="mobile-explorer-header__students__select"
            hide-details
            :items="studentList"
            :value="currentStudent"
            item-text="name"
            item-value="uuid"
            @change="changeCurrentStudent"
            solo
            dense
            height="31"
            color="#2b5bff"
            v-show="!isGuest"
            :menu-props="{ bottom: true, offsetY: true }"
          >
            <template v-slot:selection="{ item }">
              <span class="mobile-explorer-header__students__select__text">
                {{ item.name.split("", 1)[0] }}
              </span>
            </template>
          </v-select>
          <div class="mt-2 d-flex" v-if="false">
            <img
              v-if="hasStudents"
              class="mobile-explorer-header__students__imgs"
              @click="editStudent"
              src="@/assets/iconsPalmira/navigation/edit2.png"
            />
            <img
              v-if="hasStudents"
              class="mobile-explorer-header__students__imgs"
              @click="addStudent"
              src="@/assets/iconsPalmira/navigation/add.png"
            />

            <img
              v-if="canSelectStudent"
              @click="openRemoveStudentDialog"
              class="mobile-explorer-header__students__imgs"
              style="margin-bottom: 2px"
              src="@/assets/iconsPalmira/navigation/delete.png"
            />
          </div>
        </div>
      </div>
      <div v-if="isGuest" class="mobile-explorer-header__guest">G</div>
    </div>
    <div>
      <div class="d-flex align-center">
        <search-in-all-schools v-if="showMobileSearchBar" />
        <map-filter v-if="!showMobileSearchBar" />
        <v-icon
          v-if="mobile"
          medium
          :color="showMobileSearchBar ? 'red' : 'white'"
          class="ml-2"
          @click="showMobileSearch"
        >
          mdi-magnify
        </v-icon>
      </div>
    </div>
    <v-dialog
      content-class="remove-student-modal"
      v-model="showRemoveStudentDialog"
      max-width="400"
    >
      <remove-student-modal @remove="removeStudent" @cancel="closeRemoveStudentDialog" />
    </v-dialog>
    <v-dialog
      content-class="elevation-1"
      overlay-color="transparent"
      v-model="showStudentRegisterModal"
      max-width="600px"
    >
      <register
        v-if="showStudentRegisterModal && step !== null && subStep !== null"
        :step="step"
        :sectionStep="subStep"
        @closeModal="closeAddStudentModal"
      />
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import RemoveStudentModal from "@/components/student_register/RemoveStudentModal.vue";
import MapFilter from "@/components/explorer/map/MapFilter.vue";
import Register from "../components/student_register/Register.vue";
import SearchInAllSchools from "../components/explorer/map/SearchInAllSchools.vue";

export default {
  name: "MobileExplorerHeader",
  components: {
    RemoveStudentModal,
    Register,
    SearchInAllSchools,
    MapFilter,
  },
  props: {},
  data() {
    return {
      editingOrAddingStudent: false,
      showRemoveStudentDialog: false,
      showStudentRegisterModal: false,
      step: null,
      subStep: null,
      showMobileSearchBar: false,
    };
  },
  computed: {
    ...mapGetters({
      studentList: "authentication/students",
      currentStudent: "authentication/currentStudent",
      isGuest: "authentication/isGuest",
      artificial: "authentication/artificial",
      cornerBounds: "institutions/cornerBounds",
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    hasStudents() {
      return this.studentList.length > 0;
    },
    canSelectStudent() {
      return ["Explorer", "Schools", "Postulate", "Favorites", "Register Student"].includes(
        this.$route.name
      );
    },
  },
  methods: {
    ...mapActions({
      setCurrentStudent: "authentication/setCurrentStudent",
      retrieveCampuses: "institutions/retrieveCampuses",
      retrieveFavorites: "institutions/retrieveFavorites",
      raiseError: "snackbar/error",
      setWantCreateAccount: "authentication/setWantCreateAccount",
      deleteStudent: "authentication/deleteStudent",
      retrieveExploredCampuses: "institutions/retrieveExploredCampuses",
      retrieveExploredSchoolCard: "institutions/retrieveExploredSchoolCard",
      error: "snackbar/error",
      resetNewStudentStore: "newStudentRegister/resetStore",
      reloadStudents: "authentication/getStudents",
      getStudentData: "newStudentRegister/getStudentData",
      setSteps: "newStudentRegister/setSteps",
      setCurrentStep: "userRegister/setCurrentStep",
    }),
    goToPath(name) {
      this.$router.push(name);
    },
    changeCurrentStudent(studentUUID) {
      const student = this.studentList.find((st) => st.uuid === studentUUID);
      this.setCurrentStudent({ student, hotjar: this.$hj })
        .then(() => this.retrieveFavorites())
        .then(() => this.retrieveExploredCampuses())
        .then(() => this.retrieveExploredSchoolCard())
        .then(() => this.retrieveCampuses({ callback: null, bounds: this.cornerBounds }))
        .catch((error) => this.raiseError(error));
    },
    editStudent() {
      this.getStudentData({
        uuid: this.currentStudent.uuid,
        callback: (data) => {
          this.step = data.last_step_complete;
          this.subStep = data.last_substep_complete;
          this.showStudentRegisterModal = true;
        },
      });
    },
    openRemoveStudentDialog() {
      this.showRemoveStudentDialog = true;
    },
    closeRemoveStudentDialog() {
      this.showRemoveStudentDialog = false;
    },
    removeStudent() {
      this.closeRemoveStudentDialog();
      const student = this.deleteStudent({
        uuid: this.currentStudent.uuid,
      });
      if (student) {
        this.changeCurrentStudent(student.uuid);
      } else {
        this.addStudent();
      }
    },
    addStudent() {
      this.showMenu = false;
      if (!this.isGuest) {
        this.resetNewStudentStore({
          callback: () => {
            this.step = 1;
            this.subStep = 1;
            this.showStudentRegisterModal = true;
          },
        });
      } else {
        this.showLoginDialog = true;
      }
    },
    closeAddStudentModal() {
      this.reloadStudents({
        callback: () => {
          this.showStudentRegisterModal = false;
        },
      });
    },
    showMobileSearch() {
      if (this.showMobileSearchBar) {
        this.showMobileSearchBar = false;
        return this.showMobileSearchBar;
      }
      this.showMobileSearchBar = true;
      return this.showMobileSearchBar;
    },
  },
  watch: {
    $route: {
      handler(to) {
        const editingOrAddingStudent = to.path === "/register_student";
        this.editingOrAddingStudent = editingOrAddingStudent;
      },
      immediate: true,
    },
    showStudentRegisterModal: {
      handler(newValue) {
        if (newValue === false) {
          this.setSteps({
            callback: () =>
              this.reloadStudents({
                callback: () => this.resetNewStudentStore({}),
              }),
          });
        }
      },
    },
  },
};
</script>
