<template>
  <div class="navigation d-flex justify-center">
    <div class="navigation-bar" :class="{ 'navigation-bar--mobile': mobile }">
      <img
        contain
        class="header-menu__logo__img"
        src="@/assets/iconsChile/header/mime-side-logo.svg"
      />
      <div
        v-if="currentRoute !== 'Dashboard' && !isGuest"
        class="navigation-bar__button navigation-bar__button--align"
        @click="goToPath('Dashboard')"
      >
        <img
          class="navigation-bar__toExplorer"
          :src="
            $i18n.locale === 'en'
              ? require('@/assets/iconsBase/navigation/toDashboard-en.svg')
              : require('@/assets/iconsChile/navigation/toDashboard.svg')
          "
        />
      </div>
      <img
        v-if="currentRoute === 'Dashboard'"
        @click="goToPath('Explorer')"
        class="navigation-bar__toExplorer"
        style="margin-right: 10px; margin-top: 10px"
        :src="
          $i18n.locale === 'en'
            ? require('@/assets/iconsBase/navigation/toExplorerEn.svg')
            : require('@/assets/iconsBase/navigation/toExplorerEsp.svg')
        "
      />
      <!-- <div
        v-if="isGuest"
        class="navigation-bar__button navigation-bar__button--align"
        @click="$emit('signUp')"
      >
        <img
          :src="
            $i18n.locale === 'es'
              ? require('@/assets/iconsChile/navigation/toRegister.svg')
              : require('@/assets/iconsBase/navigation/register-en.svg')
          "
        />
      </div> -->
    </div>
    <div class="navigation-bar mt-1" :class="{ 'navigation-bar--mobile': mobile }">
      <div
        v-if="currentRoute !== 'Dashboard'"
        class="navigation-bar__button"
        :class="{ 'navigation-bar__button--align': isSelected('Explorer') }"
        @click="goToPath('Explorer')"
      >
        <img
          :src="
            isSelected('Explorer')
              ? require('@/assets/iconsChile/navigation/toExplorerOn.svg')
              : require('@/assets/iconsChile/navigation/toExplorerOff.svg')
          "
        />
      </div>
      <calendar v-show="currentRoute === 'Dashboard'" />
      <div
        v-if="currentRoute !== 'Dashboard'"
        class="navigation-bar__button"
        :class="{ 'navigation-bar__button--align': isSelected('Favorites') }"
        @click="goToPath('Favorites')"
      >
        <img
          :src="
            isSelected('Favorites')
              ? require('@/assets/iconsChile/navigation/toSavedCampusesOn.svg')
              : require('@/assets/iconsChile/navigation/toSavedCampusesOff.svg')
          "
        />
      </div>
      <div
        v-if="simulationOn && currentRoute !== 'Dashboard'"
        class="navigation-bar__button"
        :class="{ 'navigation-bar__button--align': isSelected('Postulate') }"
        @click="goToPath('Postulate')"
      >
        <img
          :src="
            isSelected('Postulate')
              ? require('@/assets/iconsChile/navigation/toSimulateOn.svg')
              : require('@/assets/iconsChile/navigation/toSimulateOff.svg')
          "
        />
      </div>
      <!-- <button v-if="isGuest" class="header__login mb-4" @click="signIn">
        {{ $t("map.login") }}
      </button> -->
    </div>
    <v-dialog
      content-class="elevation-0"
      overlay-color="transparent"
      v-model="showLoginDialog"
      max-width="400"
      style="margin-left: 150px"
    >
      <WarninigModal
        @close="closeWarningModal"
        @go-register="signUp"
        @go-login="signIn"
        :hasFavorites="thereAreFavorites"
        :pathTo="pathTo"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import WarninigModal from "./guest/WarninigModal.vue";
import CONFIG from "../../config";
import Calendar from "../dashboard/Calendar.vue";

export default {
  name: "NavigationButtons",
  components: {
    WarninigModal,
    Calendar,
  },
  props: {
    mobile: {
      type: Boolean,
      default: true,
    },
    inDashboard: {
      type: Boolean,
      defaule: false,
    },
  },
  data() {
    return {
      showLoginDialog: false,
      CONFIG,
      pathTo: null,
    };
  },
  methods: {
    ...mapActions({
      setWantCreateAccount: "authentication/setWantCreateAccount",
      setCurrentStep: "userRegister/setCurrentStep",
      warn: "snackbar/warn",
      homeLocation: "authentication/homeLocation",
    }),
    goToPath(name) {
      let path = "";
      switch (name) {
        case "Explorer":
          this.pathTo = name;
          if ((this.students.length === 0 || this.homeLocation === null) && !this.isGuest) {
            this.warn(this.$t("errors.noStudentError"));
          } else {
            this.$emit("closeMenu");
            path = "/explorer";
          }
          this.$mixpanel.track("click_explorer_menu", {
            distinct_id: this.legalGuardianUUID,
            student_id: this.currentStudent.uuid,
            env: CONFIG.environment,
          });
          break;
        case "Favorites":
          this.pathTo = name;
          if (this.isGuest || !this.thereAreFavorites) {
            this.showLoginDialog = true;
            return;
          }
          this.$mixpanel.track("click_favorites_menu", {
            distinct_id: this.legalGuardianUUID,
            student_id: this.currentStudent.uuid,
            env: CONFIG.environment,
          });
          this.$emit("closeMenu");

          path = "/favorites";
          break;
        case "Dashboard":
          this.pathTo = name;
          this.$mixpanel.track("click_dashboard_menu", {
            distinct_id: this.legalGuardianUUID,
            student_id: this.currentStudent.uuid,
            env: CONFIG.environment,
          });
          this.$emit("closeMenu");
          path = "/dashboard";
          break;
        case "Postulate":
          this.pathTo = name;
          if (this.isGuest || !this.thereAreFavorites) {
            this.showLoginDialog = true;
            return;
          }
          this.$mixpanel.track("click_postulate_menu", {
            distinct_id: this.legalGuardianUUID,
            student_id: this.currentStudent.uuid,
            env: CONFIG.environment,
          });
          path = "/postulate";
          this.$emit("closeMenu");
          break;
        default:
          break;
      }
      if (this.currentRoute !== name) {
        this.$router.push(path);
      }
    },
    isSelected(routeName) {
      const { currentRoute } = this;
      if (currentRoute === `${routeName}`) {
        return true;
      }
      return false;
    },
    signUp() {
      this.showLoginDialog = false;
      this.$emit("closeMenu");
      this.$emit("signUp");
    },
    signIn() {
      this.showLoginDialog = false;
      this.$emit("login");
    },
    closeWarningModal() {
      this.showLoginDialog = false;
    },
  },
  computed: {
    ...mapGetters({
      favoriteSchools: "institutions/favoriteSchools",
      isGuest: "authentication/isGuest",
      language: "authentication/language",
      legalGuardianUUID: "authentication/legalGuardianUUID",
      environment: "authentication/environment",
      currentStudent: "authentication/currentStudent",
      students: "authentication/students",
    }),
    thereAreFavorites() {
      return this.favoriteSchools.length > 0;
    },
    iconExplore() {
      return this.currentRoute === "Explorer" ? "$exploreFilled" : "$exploreOutlined";
    },
    iconFavorites() {
      return this.currentRoute === "Favorites" ? "$heartFilled" : "$outlinedHeart";
    },
    iconSimulate() {
      return this.currentRoute === "Postulate" ? "$filledClickHand" : "$outlinedClickHand";
    },
    labelExplore() {
      return this.mobile ? this.$t("mobile.app-bar.explorer") : this.$t("desktop.app-bar.explorer");
    },
    labelFavorites() {
      return this.mobile
        ? this.$t("mobile.app-bar.favorites")
        : this.$t("desktop.app-bar.favorites");
    },
    labelSimulate() {
      return this.mobile ? this.$t("mobile.app-bar.simulate") : this.$t("desktop.app-bar.simulate");
    },
    simulationOn() {
      return CONFIG.simulationOn;
    },
    currentRoute() {
      return this.$route.name;
    },
  },
};
</script>
