<template>
  <div class="user-relationship_container">
    <div
      class="user-relationship_question_container"
      :style="mobile ? 'flex-direction: column;' : ''"
    >
      <div class="user-relationship_relation_container">
        <div class="user-relationship_relation_title_container">
          <p class="required-field user-relationship_relation_title">
            {{ $t("register.identification.user_relationship.title") }}
          </p>
        </div>
        <v-select
          class="custom-select custom-select user-relationship_relation"
          :background-color="relationship !== null ? '#2D10B4' : '#FFFFFF'"
          :dark="relationship !== null ? true : false"
          :items="options"
          outlined
          item-text="relationship_name"
          item-value="id"
          v-model="relationship"
        />
      </div>
      <div class="user-relationship_other_container" v-show="showOtherRelationship">
        <div class="user-relationship_other_title_container">
          <p class="required-field user-relationship_other_title">
            {{ $t("register.identification.user_relationship.other_relation") }}
          </p>
        </div>
        <input
          v-model="other"
          type="text"
          class="register__content__input__div__input user-relationship_other_input"
          :class="{ 'register__content__input__div__input--selected': other.length > 0 }"
        />
      </div>
    </div>
    <div class="btn_container">
      <v-btn icon @click="backMethod">
        <v-img src="@/assets/iconsPalmira/student_register/icons/back.svg" />
      </v-btn>
      <base-button v-if="isDisabled" class="btn-skip" @click="skipStep">
        {{ $t("register.identification.user_relationship.skip_btn") }}
      </base-button>
      <base-button v-else class="btn-continue" @click="continueMethod">
        {{ $t("register.identification.user_relationship.continue_btn") }}
      </base-button>
    </div>
    <v-dialog
      content-class="elevation-0"
      overlay-color="transparent"
      v-model="showSkipModal"
      max-width="370"
    >
      <warning-modal
        @skip="skipConfirmMethod"
        @complete="completeMethod"
        :title="$t('register.identification.user_relationship.warning_modal.title')"
        :text="$t('register.identification.user_relationship.warning_modal.text')"
        color="#FC010A"
        skipModal
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/explorer/base/BaseButton.vue";
import WarningModal from "../WarningModal.vue";

export default {
  name: "UserRelationship",
  components: {
    BaseButton,
    WarningModal,
  },
  data() {
    return {
      relationship: null,
      other: "",
      firstUpdate: true,
      showSkipModal: false,
    };
  },
  mounted() {
    this.other = this.otherRelationship;
    const { id } = this.userRelationshipStore;
    if (id) {
      this.relationship = id;
    } else {
      this.firstUpdate = false;
    }
  },
  methods: {
    ...mapActions({
      setIdentificationStepTwo: "newStudentRegister/setIdentificationStepTwo",
    }),
    continueMethod() {
      const data = {
        relationship: this.relationshipObj,
        other: this.other,
      };
      this.setIdentificationStepTwo(data);
      this.$emit("nextStep");
    },
    backMethod() {
      this.$emit("backStep");
    },
    skipStep() {
      this.showSkipModal = true;
    },
    skipConfirmMethod() {
      this.$emit("skipStep");
    },
    completeMethod() {
      this.showSkipModal = false;
    },
  },
  computed: {
    ...mapGetters({
      options: "options/relationshipOptions",
      userRelationshipStore: "newStudentRegister/userRelationship",
      otherRelationship: "newStudentRegister/otherRelationship",
    }),
    isDisabled() {
      return this.relationship === null || (this.showOtherRelationship && this.other === "");
    },
    relationshipObj() {
      if (this.relationship === null) {
        return {};
      }
      return this.options.find((relation) => relation.id === this.relationship);
    },
    showOtherRelationship() {
      if (this.relationship === null) {
        return false;
      }
      const opt = this.options.find((relation) => relation.id === this.relationship);
      return opt.need_specification;
    },
    mobile() {
      // return this.$vuetify.breakpoint.xsAndDown;
      return this.$vuetify.breakpoint.width < 630;
    },
  },
  watch: {
    relationship: {
      handler(newVal) {
        if (newVal) {
          if (!this.firstUpdate) {
            this.other = "";
          } else {
            this.firstUpdate = false;
          }
        }
      },
    },
  },
};
</script>
