<template>
  <div class="feedback-introduction">
    <div v-if="CONFIG.feedback !== 'Palmira'">
      <picture class="w-full flex justify-center mb-10">
        <img src="@/assets/svg/logo-ministry.svg" />
      </picture>
      <picture class="flex flex-col justify-center mb-10">
        <img src="@/assets/svg/mimp.svg" />
        <img src="@/assets/svg/mimp-text.svg" />
      </picture>
    </div>
    <div class="feedback-introduction__header" v-if="CONFIG.feedback === 'Palmira'">
      <img
        src="@/assets/iconsPalmira/logos/palmira-white-logo.png"
        class="feedback-introduction__header__logo"
      />
      <img
        src="@/assets/iconsPalmira/logos/consilium-white.png"
        class="feedback-introduction__header__consilium-logo"
      />
    </div>
    <div
      class="w-full max-w-desktop flex flex-col px-10 box-border mb-7"
      v-if="CONFIG.feedback !== 'Palmira'"
    >
      <span v-t="'feedback.introduction.welcome'" />
      <p class="text-xxl">{{ userUsername }} !</p>
      <p class="text-sm" v-t="'feedback.introduction.text'" />
    </div>
    <div
      class="feedback-introduction__content"
      v-if="CONFIG.feedback === 'Palmira' && CONFIG.feedbackType === 'Recommendations'"
    >
      <span class="feedback-introduction__content__text" v-t="'feedback.introduction.welcome'" />
      <p class="feedback-introduction__content__text">{{ userUsername }} !</p>
      <p
        class="feedback-introduction__content__text mt-5"
        v-t="'feedback.introduction.palmira_text'"
      />
    </div>
    <div
      class="feedback-introduction__content"
      v-if="CONFIG.feedback === 'Palmira' && CONFIG.feedbackType === 'Results'"
      :class="{ 'feedback-introduction__content--results': CONFIG.feedbackType === 'Results' }"
    >
      <h1
        class="feedback-introduction__content__result__title"
        v-t="'feedback.introduction.results_title'"
      />
      <div class="feedback-introduction__content__result__div">
        <img src="@/assets/iconsPalmira/reports/hands.svg" />
        <p
          class="feedback-introduction__content__text ml-2"
          v-t="'feedback.introduction.results_text'"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CONFIG from "../../config";

export default {
  name: "Introduction",
  setup() {
    const { name } = this;

    return {
      name,
    };
  },
  data() {
    return {
      CONFIG,
    };
  },
  computed: {
    ...mapGetters({
      userUsername: "authentication/userUsername",
    }),
  },
};
</script>
