=<template>
  <div class="campus-detail__panels__container campus-detail__panels__container__3 pl-5 pr-5">
    <section class="campus-detail__panels__container__white mt-2 mb-2">
      <template>
        <div class="campus-detail__panels__performance-title text-left mb-2">
          {{ $t("campus-details.current_performance.simce_tooltip") }}
        </div>
      </template>
    </section>
    <div class="fill-width">
      <section
        v-if="false"
        class="campus-detail__panels__container campus-detail__panels__container__blue mt-2 mb-2 ml-0"
      >
        <template>
          <span
            class="campus-detail__panels__performance-title campus-detail__panels__performance-title--white text-left"
          >
            {{ $t("campus-details.current_performance.simce_highscore") }}
          </span>
        </template>
      </section>

      <div
        class="panel_container_row panel_container_row_wrap performance mt-5 mb-5"
        :style="mobile ? 'justify-content: space-between;' : 'justify-content: space-around;'"
      >
        <button
          class="panel_button__color panel_button__color__text"
          :class="
            this.currentGrade == 10
              ? 'panel_button__color__salmon'
              : 'panel_button__color__deactivated'
          "
          v-t="'4°'"
          @click="choose_grade(10)"
          v-if="hasSimce(10)"
        />
        <button
          class="panel_button__color panel_button__color__text"
          :class="
            this.currentGrade == 12
              ? 'panel_button__color__green'
              : 'panel_button__color__deactivated'
          "
          v-t="'6°'"
          @click="choose_grade(12)"
          v-if="hasSimce(12)"
        />

        <button
          class="panel_button__color panel_button__color__text"
          :class="
            this.currentGrade == 14
              ? 'panel_button__color__orange'
              : 'panel_button__color__deactivated'
          "
          v-t="'8°'"
          @click="choose_grade(14)"
          v-if="hasSimce(14)"
        />

        <button
          class="panel_button__color panel_button__color__text mr-0"
          v-t="'II°'"
          :class="{
            panel_button__color__grey: this.currentGrade == 16,
            panel_button__color__deactivated: this.currentGrade != 16,
            'mt-3': this.$vuetify.breakpoint.width < 416,
          }"
          @click="choose_grade(16)"
          v-if="hasSimce(16)"
        />
      </div>
    </div>
    <h3 class="panels__title panels__title__container text-left mb-2 mt-2">
      {{ $t("campus-details.current_performance.average_performance") }}
    </h3>
    <section class="panel_container_column fill-width panel_container_row--scroll mt-3">
      <div class="panel_container_row panel_container_row--no-margin">
        <div class="panels_table_zero"></div>
        <div class="panels_table_label_top mr-2">
          <span>{{ $t("campus-details.panels.language.title") }}</span>
        </div>
        <div class="panels_table_label_top">
          <span>{{ $t("campus-details.panels.math") }}</span>
        </div>
      </div>
      <div v-for="(score, index) in simceScores" :key="index">
        <div
          class="panel_container_row"
          v-if="getLanguageScore(score) !== '' && getMathsScore(score) !== ''"
        >
          <div class="panels_table_label_lateral mr-2">
            <span>{{ Object.keys(score)[0] }}</span>
          </div>
          <div class="panels_table_label_score mr-2">
            <span> {{ getLanguageScore(score) }}</span>
          </div>
          <div class="panels_table_label_score mr-2">
            <span>{{ getMathsScore(score) }}</span>
          </div>
        </div>
      </div>
    </section>
    <section
      v-if="getPercentile() !== null"
    >
      <h3 class="panels__title panels__title__container text-left mb-2 mt-2">
        {{ $t("campus-details.current_performance.average_neighborhood") }}
      </h3>
      <p
        v-t="'campus-details.current_performance.neighborhood_performance_title'"
        class="campus-detail__panels__performance-title text-left mb-2 ml-1"
      />
      <div class="panel_container_row fill-width justify-center mb-0">
        <div class="d-flex align-center" width="50">
          <img
            class="panel_container_row__abs_img ml-0"
            :src="require('@/assets/iconsChile/campusDetail/face_icon.svg')"
          />
          <span
            class="panel_container_row__white panel_container_row--text panel_container_row--text__salmon campus-detail__panels__container__item"
          >
            {{ getPercentile() }}
          </span>
        </div>
      </div>
      <span
        class="campus-detail__panels__performance-title--salmon text-left d-flex align-center mb-5"
      >
        {{ $t("campus-details.current_performance.simce_neighbor_1") }}
        {{ getPercentile(true) + "%" }}
        {{ $t("campus-details.current_performance.simce_neighbor_2") }}.
      </span>
    </section>

    <WidgetPercent v-if="false" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import WidgetPercent from "@/components/explorer/general/campus_detail/panels/mini_panels/WidgetPercent.vue";
export default {
  name: "Simce",
  components: {
    WidgetPercent,
  },
  data() {
    return {
      currentGrade: 10,
      simceScores: [],
      simceGradeList: [],
      simceTotalScores: [],
    };
  },
  mounted() {
    const simceSortedList = [];
    const gradeList = [];
    const simceTotalScores = [];
    this.campusDetails.testscore_campus.forEach((score) => {
      if (score.testscore_category.testscore_variable_label < 5) {
        simceTotalScores.push(score);
        if (score.grade_label && !gradeList.includes(score.grade_label)) {
          gradeList.push(score.grade_label);
        }
        const newScore = {};
        const key = score.year;
        newScore[key] = [];
        newScore[key].push(score);
        if (simceSortedList.length === 0) {
          simceSortedList.push(newScore);
        }
        const isYearInArray = simceSortedList.find(
          (scoreInfo) => Object.keys(scoreInfo)[0] === key.toString()
        );
        if (isYearInArray !== undefined) {
          simceSortedList.forEach((list) => {
            if (Object.keys(list)[0] === key.toString()) {
              list[key].push(score);
            }
          });
        } else {
          simceSortedList.push(newScore);
        }
      }
    });
    this.currentGrade = gradeList[0];
    this.simceScores = simceSortedList;
    this.simceTotalScores = simceTotalScores;
  },
  computed: {
    ...mapGetters({
      campusDetails: "institutions/campusDetails",
    }),
    mobile() {
      return this.$vuetify.breakpoint.width < 630;
    },
  },
  methods: {
    choose_grade(grade) {
      this.currentGrade = grade;
    },
    getLanguageScore(score) {
      const scoreArray = score[Object.keys(score)[0]];
      const filteredScores = scoreArray.filter(
        (scoreInfo) => scoreInfo.grade_label === this.currentGrade
      );
      const languageScore = filteredScores.find(
        (scoreInfo) => scoreInfo.testscore_category.testscore_area_label === 2
      );
      if (languageScore !== undefined) {
        return languageScore.mean;
      }
      return "";
    },
    getMathsScore(score) {
      const scoreArray = score[Object.keys(score)[0]];
      const filteredScores = scoreArray.filter(
        (scoreInfo) => scoreInfo.grade_label === this.currentGrade
      );
      const mathScore = filteredScores.find(
        (scoreInfo) => scoreInfo.testscore_category.testscore_area_label === 1
      );
      if (mathScore !== undefined) {
        return mathScore.mean;
      }
      return "";
    },
    getPercentile(withoutPerc) {
      let gradeSortedList = [];
      this.simceTotalScores.forEach((score) => {
        if (
          score.grade_label === this.currentGrade &&
          score.testscore_category.testscore_area_label === 1
        ) {
          gradeSortedList.push(score);
        }
      });
      gradeSortedList.sort((a, b) => b.year - a.year);
      const percentil = gradeSortedList[0].pct_mean_subd2;
      if (withoutPerc) {
        return percentil;
      }
      if (percentil) {
        return `${percentil}%`;
      }
      return null;
    },
    hasSimce(grade) {
      let hasSimce = false;
      this.simceScores.forEach((year) => {
        year[Object.keys(year)].forEach((score) => {
          if (score.grade_label === grade) {
            hasSimce = true;
          }
        });
      });
      return hasSimce;
    },
  },
};
</script>
