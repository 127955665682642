<template>
  <div class="calendar">
    <div
      class="calendar__information__container"
      :style="moreDates ? 'overflow: hidden visible;' : 'overflow: hidden;'"
    >
      <div class="calendar__title" v-t="'dashboard.calendar.title'">
        {{ $t("en.dashboard.layout.calendar.title") }}
      </div>
      <div v-for="(key, i) in Object.keys(information)" :key="i" class="calendar__container">
        <div class="py-0 my-0 calendar__container_date">
          <div class="calendar__container_img">
            <img
              src="@/assets/dashboard/icons/red-point.svg"
              class="calendar__container_img-date_icon"
            />
          </div>
          <div class="calendar__container_text-img">
            <span>
              {{ key }}
            </span>
          </div>
        </div>
        <div
          v-for="(info, j) in information[key]"
          :key="j"
          class="my-0 py-0 calendar__container_general"
        >
          <div class="calendar__container_img">
            <img
              v-if="!info.general"
              src="@/assets/dashboard/icons/alert.svg"
              class="calendar__container_img-text_icon"
            />
          </div>
          <div
            :class="
              info.general ? 'calendar__container_text-general' : 'calendar__container_text-user'
            "
          >
            <span v-html="info.text" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="false" class="calendar-btn__container">
      <v-btn @click="showDates" class="calendar-btn" elevation="0">
        <p class="calendar-btn__text">
          {{
            !moreDates
              ? $t("en.dashboard.layout.calendar.more_dates")
              : $t("en.dashboard.layout.calendar.less_dates")
          }}
        </p>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import BaseButton from '@/components/explorer/base/BaseButton.vue';

export default {
  name: "Calendar",
  components: {
    // BaseButton,
  },
  data() {
    return {
      moreDates: false,
      myInformation: [],
      monthNames: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEPT",
        "OCT",
        "NOV",
        "DEC",
      ],
      allInformation: {
        // TODO: Change to read from API
        general: [
          {
            // year, month (0-11), day
            date: new Date(2022, 6, 12),
            text: this.$t("dashboard.calendar.text3"),
          },
          {
            date: new Date(2022, 6, 11),
            text: this.$t("dashboard.calendar.text2"),
          },
          {
            date: new Date(2022, 2, 2),
            text: this.$t("dashboard.calendar.text1"),
          },
        ],
      },
    };
  },
  mounted() {
    // eslint-disable-next-line no-prototype-builtins
    if (this.allInformation.hasOwnProperty("general")) {
      this.myInformation = this.allInformation.general;
      // eslint-disable-next-line no-param-reassign
      this.myInformation.forEach((elem) => {
        elem.general = true;
      });
    }
    // eslint-disable-next-line no-prototype-builtins
    if (this.allInformation.hasOwnProperty(this.legalGuardianUUID)) {
      this.myInformation = this.myInformation.concat(this.allInformation[this.legalGuardianUUID]);
    }
    this.myInformation.sort(this.sortByObjectDate);
  },
  computed: {
    ...mapGetters({
      legalGuardianUUID: "authentication/legalGuardianUUID",
    }),
    information() {
      const newInfo = {};
      this.myInformation.forEach((e) => {
        const date = `${this.getDateDay(e.date)} ${this.getDateMonth(e.date)}`;
        if (Object.prototype.hasOwnProperty.call(newInfo, date)) {
          newInfo[date].push(e);
        } else {
          newInfo[date] = [e];
        }
      });
      return newInfo;
    },
  },
  methods: {
    showDates() {
      // TODO: Functionality to be defined
      this.moreDates = !this.moreDates;
    },
    sortByObjectDate(a, b) {
      const timeA = a.date.setHours(0).valueOf();
      const timeB = b.date.setHours(0).valueOf();
      if (timeA < timeB) return -1;
      if (timeA > timeB) return 1;
      return 0;
    },
    getDateDay(date) {
      return ("0" + date.getDate()).slice(-2);
    },
    getDateMonth(date) {
      return date.toLocaleString("default", { month: "long" }).substring(0, 3).toUpperCase();
    },
  },
};
</script>
