<template>
  <div class="w-full">
    <div v-for="(student, index) in students" :key="index">
      <div
        class="w-full flex justify-center"
        :class="{ 'feedback-postulation': CONFIG.feedback === 'Palmira' }"
      >
        <div class="w-full max-w-desktop px-10 my-3">
          <button
            type="button"
            class="bg-white w-full flex flex-col rounded-xl py-3 px-3"
            :class="{ 'feedback-postulation__btn': CONFIG.feedback === 'Palmira' }"
            @click="openCard(index)"
          >
            <div class="w-full flex justify-between align-center">
              <div class="w-4/5 flex flex-col ml-1 pl-1">
                <label
                  class="text-left cursor-pointer"
                  style="width: 200px"
                  v-t="{
                    path: 'feedback.postulation.button',
                    args: setName(student),
                  }"
                />
                <!-- v-if="isRisk(application.ratex && !student.showCard) " -->
                <label
                  v-if="false"
                  class="w-full font-semibold text-xss text-red text-left"
                  v-t="'feedback.postulation.have_message'"
                />
              </div>
              <div class="w-1/5 flex justify-end items-center">
                <picture v-if="showDetail === index">
                  <img src="@/assets/icons/close.svg" style="width: 20px" />
                </picture>
                <label
                  v-else
                  class="h-6 bg-primary rounded-full text-xs text-white px-3 py-1 cursor-pointer"
                  v-t="'feedback.postulation.open'"
                />
              </div>
            </div>
          </button>
          <!-- v-if="isRisk(application.ratex && student.showCard)" -->
          <div
            v-if="false"
            class="bg-white rounded-xl mt-2 py-5 px-6 text-center shadow-message slideInDown"
          >
            <p
              v-t="'feedback.postulation.have_message'"
              class="text-red text-xs font-semibold mb-5"
            />
            <button
              @click="goToMessage(student.applicant_uuid)"
              class="bg-white text-primary text-xs border border-primary hover:bg-primary hover:text-white hover:border-white rounded-full px-6 py-2"
              v-t="'feedback.postulation.go_message'"
            />
          </div>
        </div>
      </div>
      <PostulationDetail
        v-if="showDetail === index"
        :riskAlert="setRisk(student)"
        :institutions="setInstitutions(student)"
        :institutionStats="CONFIG.feedbackType === 'Results' ? institutionAssignation(student) : []"
        :recommendations="setRecommendations(student)"
        :admission="student.admission"
        :nameStudent="setStudentName(student)"
        :legalGuardianUuid="legalGuardianUuid"
        :studentId="setStudentUuid(student)"
        :stats="CONFIG.feedbackType === 'Results' ? student.stats : []"
        :admissionStats="student.admission_simulation_info"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PostulationDetail from "./PostulationDetail.vue";
import CONFIG from "../../config";

export default {
  name: "Postulation",
  components: {
    PostulationDetail,
  },
  data() {
    return {
      CONFIG,
      showDetail: -1,
      students: null,
    };
  },
  mounted() {
    if (this.CONFIG.feedbackType === "Results") {
      this.students = this.results;
    } else {
      this.students = this.applications;
    }
    return this.students;
  },
  computed: {
    ...mapGetters({
      legalGuardianUuid: "authentication/legalGuardianUUID",
      applications: "feedback/apllications",
      results: "feedback/results",
      recommendations: "feedback/recommendations",
    }),
    isRisk() {
      return (admissionProbabiliy) => {
        if (+admissionProbabiliy <= 0.7) return true;
        return false;
      };
    },
  },

  methods: {
    ...mapActions({
      actionOpenCardStudent: "feedback/actionOpenCardStudent",
    }),
    goToMessage(uuid) {
      this.$mixpanel.track("view_risk_module", {
        distinct_id: this.legalGuardianUuid,
        studentId: uuid,
        env: process.env.VUE_APP_ENV,
      });
      document.getElementById("risk").scrollIntoView({ behavior: "smooth" });
    },
    openCard(index) {
      if (!this.students[index].showCard || this.students[index].showCard === undefined) {
        this.$mixpanel.track("open_student_application", {
          distinct_id: this.legalGuardianUuid,
          student_id: this.students[index].applicant_uuid,
        });
      }
      if (this.showDetail === -1) {
        this.showDetail = index;
        return;
      }
      this.showDetail = -1;
    },
    setRecommendations(student) {
      if (this.CONFIG.feedbackType !== "Results") {
        const applicantId = student.applicant;
        let recommendationSet;
        this.recommendations.forEach((recommendation) => {
          if (recommendation.applicant_id === applicantId) {
            recommendationSet = recommendation;
          }
        });
        return recommendationSet;
      }

      const recommendations = student.recomendations;
      return recommendations;
    },
    institutionAssignation(student) {
      const institutionStats = student.simulation[0].api_response.portfolio;
      return institutionStats;
    },
    setName(student) {
      if (this.CONFIG.feedbackType !== "Results") {
        return { nameStudent: student.applicant_firstname };
      }
      return { nameStudent: student.applicant.first_name };
    },
    setInstitutions(student) {
      if (this.CONFIG.feedbackType !== "Results") {
        return student.api_response.portfolio;
      }
      return student.campuses;
    },
    setStudentName(student) {
      if (this.CONFIG.feedbackType !== "Results") {
        return student.applicant_firstname;
      }
      return student.applicant.first_name;
    },
    setStudentUuid(student) {
      if (this.CONFIG.feedbackType !== "Results") {
        return student.applicant_uuid;
      }
      return student.applicant.uuid;
    },
    setRisk(student) {
      if (this.CONFIG.feedbackType !== "Results") {
        return student.api_response.portfolio[0].ratex;
      }
      return 1;
    },
  },
};
</script>
