<template>
  <div class="campus-detail__panels__container campus-detail__panels__container__3 pl-5 pr-5">
    <span
      class="campus-detail__panels__performance-title text-left mb-2"
      v-t="'campus-details.current_performance.achivments_title'"
    />
    <div class="d-flex flex-wrap fill-width justify-center">
      <div
        v-for="(year, index) in yearList"
        :key="index"
        class="panel_container_row mb-3"
        :class="{
          'mt-0': index === 3,
        }"
      >
        <button
          v-if="hasPsuScore(year)"
          class="panel_button__color panel_button__color__text"
          :class="{
            'panel_button__color__salmon mt-3': year === '2018',
            'panel_button__color__green mt-3': year === '2019',
            'panel_button__color__orange mt-3': year === '2020',
            'panel_button__color__grey mt-3':
              year === '2021' &&
              Object.keys(psuScores).length === 4 &&
              $vuetify.breakpoint.width > 432,
            'panel_button__color__grey mt-0':
              year === '2021' &&
              Object.keys(psuScores).length === 4 &&
              $vuetify.breakpoint.width < 432,
            panel_button__color__deactivated: current_chosen !== year,
            'mr-0': Object.keys(psuScores).length === 1,
            'mt-2':
              $vuetify.breakpoint.width < 432 && Object.keys(psuScores).length === 4 && index === 4,
          }"
          v-t="year"
          @click="chooseYear(year)"
        />
      </div>
    </div>
    <section
      class="flex-row panel_container_column fill-width panel_container_row--scroll mt-3"
      :class="{ 'mb-0': !mobile }"
      v-if="!mobile"
    >
      <div
        v-for="(numberList, index) in yearList.length"
        :key="index"
        class="panel_container_row panel_container_row--no-margin mr-2"
      >
        <div
          v-if="getPsuScore(numberList)"
          class="panel_container_row--no-margin--text"
          v-t="getPsuLabel(numberList)"
        />
      </div>
    </section>
    <div class="panel_container_column fill-width panel_container_row--scroll">
      <div class="d-flex">
        <div v-if="mobile">
          <div
            v-for="(numberList, index) in yearList.length"
            :key="index"
            class="panel_container_row"
          >
            <div
              v-if="getPsuScore(numberList)"
              class="panel_container_row--no-margin--text d-flex justify-center align-center"
              v-t="getPsuLabel(numberList)"
              style="height: 56px; border-radius: 4px 0px 0px 4px"
            />
          </div>
        </div>
        <div :class="mobile ? 'flex-column' : 'flex-row'">
          <div
            v-for="(numberList, index) in yearList.length"
            :key="index"
            class="panel_container_row mr-2"
          >
            <div
              v-if="getPsuScore(numberList)"
              class="panels_table_label_score"
              :style="mobile ? 'border-radius: 0px 4px 4px 0px;' : ''"
            >
              <span>{{ getPsuScore(numberList) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section v-if="false" class="campus-detail__panels__container">
      <template>
        <h3 class="panels__title panels__title__container text-left mb-2 mt-2">
          {{ $t("campus-details.current_performance.average_neighborhood") }}
        </h3>

        <p
          v-t="'campus-details.current_performance.achivments_title'"
          class="campus-detail__panels__title text-left mb-2 font-weight-medium"
        />
      </template>
    </section>

    <hr v-if="false" />

    <div v-if="false" class="panel_container_row">
      <img
        class="panel_container_row__abs_img"
        :src="require('@/assets/iconsChile/campusDetail/face_icon.svg')"
      />
      <span
        class="panels__container__item1 panel_container_row--text panel_container_row--text__salmon graph_panel__fg1"
      >
        XX%
      </span>

      <span
        class="panel_container_row--text__salmon panel_container_row--text__medium panels__container__item text-left panel_container_column font-weight-medium"
      >
        {{ $t("campus-details.current_performance.simce_neighbor_1") }}
        50%
        {{ $t("campus-details.current_performance.simce_neighbor_2") }}
      </span>
    </div>

    <WidgetPercent v-if="false" />

    <hr v-if="false" />

    <div v-if="false" class="panel_container_row">
      <img
        class="panel_container_row__abs_img"
        :src="require('@/assets/iconsChile/campusDetail/face_icon_dark_green.svg')"
      />
      <span
        class="panel_container_row__white panel_container_row--text panel_container_row--text__green campus-detail__panels__container__item"
      >
        XX%
      </span>

      <span
        class="campus-detail__panels__title panel_container_row--text__green campus-detail__panels__title campus-detail__panels__container__item text-left mb-2 font-weight-medium"
      >
        {{ $t("campus-details.current_performance.simce_neighbor_1") }}
        10%
        {{ $t("campus-details.current_performance.simce_neighbor_2") }}
      </span>
    </div>

    <WidgetPercent v-if="false" />

    <hr v-if="false" />

    <h3
      v-if="false"
      v-t="'campus-details.current_performance.achivments_title'"
      class="panels__title panels__title__container text-left mb-2 mt-2"
    />
    <section class="campus-detail__panels__container__white" v-if="false">
      <template v-if="true">
        <span
          v-t="'campus-details.current_performance.achivments_title'"
          class="campus-detail__panels__title text-left mb-2 font-weight-medium"
        />
      </template>
    </section>

    <section v-if="false" class="campus-detail__panels__container__white">
      <template v-if="true">
        <span
          v-t="'campus-details.current_performance.achivments_title'"
          class="campus-detail__panels__title text-left mb-2 font-weight-medium"
        />
      </template>
    </section>

    <section class="campus-detail__panels__container__white" v-if="false">
      <template v-if="true">
        <span
          v-t="'campus-details.current_performance.achivments_title'"
          class="campus-detail__panels__title text-left mb-2 font-weight-medium"
        />
      </template>
    </section>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import WidgetPercent from "@/components/explorer/general/campus_detail/panels/mini_panels/WidgetPercent.vue";
import moment from "moment";

export default {
  name: "PSU",
  components: {
    WidgetPercent,
  },
  data() {
    return {
      current_chosen: "2021",
      psuScores: {},
      yearList: [],
    };
  },
  mounted() {
    this.yearList = [
      (moment().year() - 4).toString(),
      (moment().year() - 3).toString(),
      (moment().year() - 2).toString(),
      (moment().year() - 1).toString(),
    ];
    let newScore = [];
    this.campusDetails.testscore_campus.forEach((score) => {
      if (score.testscore_category.testscore_variable_label === 5) {
        const key = score.year;
        if (newScore[key] === undefined) {
          newScore[key] = [];
        }
        if (score) {
          newScore[key].push(score);
        }
      }
    });
    this.psuScores = newScore;
  },
  computed: {
    ...mapGetters({
      campusDetails: "institutions/campusDetails",
    }),
    mobile() {
      return this.$vuetify.breakpoint.width < 630;
    },
  },
  methods: {
    chooseYear(grade) {
      this.current_chosen = grade;
    },
    getPsuScore(subject) {
      // 1 = maths, 2 = language
      const yearChossen = parseInt(this.current_chosen, 10);
      const psuScore = this.psuScores[yearChossen].find(
        (score) =>
          score.testscore_category.testscore_area_label === subject &&
          score.testscore_category.testscore_variable_label === 5
      );
      if (psuScore) {
        return Math.round(psuScore.mean);
      }
      return null;
    },
    getPsuLabel(numberList) {
      if (numberList === 1) {
        return "campus-details.panels.math";
      }
      if (numberList == 2) {
        return "campus-details.panels.language.title";
      }
      if (numberList === 3) {
        return "campus-details.panels.history";
      }
      if (numberList === 4) {
        return "campus-details.panels.science";
      }
      return "";
    },
    hasPsuScore(year) {
      const yearChossen = parseInt(year);
      const hasScore = this.psuScores[yearChossen];
      if (hasScore) {
        return true;
      }
      return false;
    },
  },
};
</script>
